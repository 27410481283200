import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { SportsPhysiotherapyContext } from '../../ContextAPI/SportsPhysiotherapyContext'
import ChartLeftMenu from './components/ChartLeftMenu';
import Anamnese from './components/Anamnese';
import Medicamentos from './components/Medicamentos';
import Alimentacao from './components/Alimentacao';
import Sono from './components/Sono';
import Algometria from './components/Algometria';
import Treino from './components/Treino';
import TestesFuncionais from './components/TestesFuncionais';
import TestesFuncionais2 from './components/TestesFuncionais2';
import TestesFuncionaisDeTronco from './components/TestesFuncionaisDeTronco';
import Cait from './components/Cait';
import Lysholm from './components/Lysholm';
import NeckDisabilityIndex from './components/NeckDisabilityIndex';
import IndiceOswestryDeIncapacidade from './components/IndiceOswestryDeIncapacidade';
import Spadi from './components/Spadi';
import Womac from './components/Womac';
import VisaA from './components/VisaA';
import VisaP from './components/VisaP';
import Eroe from './components/Eroe';
import EscalaDeSonolenciaDeEpworth from './components/EscalaDeSonolenciaDeEpworth';
import EscalaTampaDeCinesiofobia from './components/EscalaTampaDeCinesiofobia';
import IndiceDeSensibilizacaoCentral from './components/IndiceDeSensibilizacaoCentral';
import Aofas from './components/Aofas';
import Faam from './components/Faam';
import Lefs from './components/Lefs';
import Psqi from './components/Psqi';
import DiagnosticoFisioterapeutico from './components/DiagnosticoFisioterapeutico';

import DadosPessoais from '../Atendimento/Components/DadosPessoais';
import CircularLoad from '../../Components/Load';
import annexIcon from '../../Assets/Images/annexIcon.svg'
import { useStyles } from './style';
import conexao from '../../Services/api'
import { StateGlobal } from '../../ContextAPI/context';
import { TextField } from '@material-ui/core';

export default function FisioterapiaEsportiva(props) {
  const classes = useStyles()

  const { observationStep } = StateGlobal();
  const { chartComponentsIndex, sportsPhysiotherapyChartAnswers } = useContext(SportsPhysiotherapyContext)

  const [observationTextFieldValue, setObservationTextFieldValue] = useState('')
  const [observations, setObservations] = useState([])
  const [validaMensagem, setValidaMensagem] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false)
  const [formID , setFormID] = useState('')
  const [patientID , setPatientID] = useState('')

  const chartComponents= [
    {id: Math.random(), component: <Anamnese  />, name: 'ANAMNESE'},
    {id: Math.random(), component: <Medicamentos/>, name: 'MEDICAMENTOS'},
    {id: Math.random(), component: <Alimentacao/>, name: 'ALIMENTAÇÃO'},
    {id: Math.random(), component: <Sono/>, name: 'SONO'},
    {id: Math.random(), component: <Treino/>, name: 'TREINO'},
    {id: Math.random(), component: <Algometria/>, name: 'ALGOMETRIA (LIMIAR DE DOR POR PRESSÃO)'},
    {id: Math.random(), component: <TestesFuncionais/>, name: 'TESTES FUNCIONAIS MMII'},
    {id: Math.random(), component: <TestesFuncionais2/>, name: 'TESTES FUNCIONAIS MMSS'},
    {id: Math.random(), component: <TestesFuncionaisDeTronco/>, name: 'TESTES FUNCIONAIS DE TRONCO'},
    {id: Math.random(), component: <Cait/>, name: 'CAIT'},
    {id: Math.random(), component: <Lysholm/>, name: 'LYSHOLM'},
    {id: Math.random(), component: <NeckDisabilityIndex/>, name: 'NDI - NECK DISABILITY INDEX - BR'},
    {id: Math.random(), component: <IndiceOswestryDeIncapacidade/>, name: 'ÍNDICE OSWESTRY DE INCAPACIDADE'},
    {id: Math.random(), component: <Spadi/>, name: 'SPADI'},
    {id: Math.random(), component: <Womac/>, name: 'WOMAC'},
    {id: Math.random(), component: <VisaA/>, name: 'VISA - A'},
    {id: Math.random(), component: <VisaP/>, name: 'VISA - P'},
    {id: Math.random(), component: <Eroe/>, name: 'EROE'},
    {id: Math.random(), component: <EscalaDeSonolenciaDeEpworth/>, name: 'ESCALA DE SONOLÊNCIA DE EPWORTH'},
    {id: Math.random(), component: <EscalaTampaDeCinesiofobia/>, name: 'ESCALA TAMPA DE CINESIOFOBIA'},
    {id: Math.random(), component: <IndiceDeSensibilizacaoCentral/>, name: 'INDICE DE SENSIBILIZAÇÃO CENTRAL'},
    {id: Math.random(), component: <Aofas/>, name: 'AOFAS'},
    {id: Math.random(), component: <Faam/>, name: 'FAAM'},
    {id: Math.random(), component: <Lefs/>, name: 'LEFS - LOWER EXTREMITY FUNCTIONAL SCALE - (LEFS) BRASIL'},
    {id: Math.random(), component: <Psqi/>, name: 'PSQI'},
    {id: Math.random(), component: <DiagnosticoFisioterapeutico/>, name: 'DIAGNÓSTICO FISIOTERAPÊUTICO'},
  ]
  
  // useEffect(() => {
  //   getUserObservations()
  // }, [])

  useEffect(() => {
    console.log('FISIO ESPORTIVA (props)')
    console.log(props)
    setFormID(props.formID)
    setPatientID(props.patientID)
  }, [props])

  useEffect(() => {
    getUserObservations()
  }, [formID])

  async function getUserObservations() {
    // const res = await conexao.get(`/historic/getall/${localStorage.getItem('idEdicaoRP')}`, {
    if (formID) {
      const res = await conexao.get(`/historic/getall/${formID}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
      })
      setObservations(res.data.data)
    }
    
  }
  
  async function handleObservation() {
    if ( observationTextFieldValue === '' ) {
      setValidaMensagem(true)
      return
    } 
    setValidaMensagem(false)
    
    setIsLoading(true)
    await conexao.post(`historic/create`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }, 
      form_id: localStorage.getItem('idEdicaoRP'),
      observation: observationTextFieldValue
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then(async res => {
      if(uploadedFileName) {
        uploadedFileName.forEach(async (file, idx) => {
          let formData = new FormData();
          formData.append(`file`, file);

          const fileUrl = await conexao.post('/upload', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
  
          await conexao.post(`file/create`, {
            historic_id: res.data.data.id,
            url: fileUrl.data,
            file_name: file.name 
          })

          getUserObservations()
        })
      }
      
      getUserObservations()
      setObservationTextFieldValue('')
      setUploadedFileName('')
    })
    setIsLoading(false)
  }
  
  function handleAnnex(event) {
    let files = Object.values(event.target.files)

    let fileNameField = document.getElementById('file-name');
    fileNameField.textContent = files.map((file) => file.name);

    setUploadedFileName(files)
  }

  async function donwloadFile(fileUrl, fileName) {
    conexao({
      url: `/download/path`,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        caminho: fileUrl,
        name: fileName
      },
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL
        .createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  function alterarData(data) {
    const initialDate = data.split(' ')
    const time = initialDate[1].split('.')[0].split(':')
    const date = initialDate[0].split('-').reverse()
    const formatedDate = `${date[0]}/${date[1]}/${date[2]}`
    const formatedTime = `${time[0]}:${time[1]}`

    return `${formatedDate} ${formatedTime}`
  }

  return(
    <>
      <div className="d-flex">
        <Grid item xs={3}>
          <Paper 
            className={
              clsx(
                classes.paper,
                classes.colorPaperWhite
              )
            }
          >
            <ChartLeftMenu arrChartComponents={chartComponents} formID={props.formID}/>
          </Paper>
        </Grid>
        
        <Grid item xs={9}>
          <DadosPessoais patientID={patientID}/>

          <section className={classes.chart}>
            <div className={classes.chart__title}>
              <div>
                <h3>Fisioterapia Esportiva</h3>
                <h5>Seção: {
                    observationStep ?  'Observações' 
                    : chartComponents[chartComponentsIndex]?.name
                  }
                </h5>
              </div>

              <Link style={{ textDecoration: 'none'}} to='/pdf-esportivo'>
                <Button color="primary">
                  { load === true ?
                    <div className="div-load-lista-modelos">
                      <CircularLoad />
                    </div>
                    :
                    <>
                      <GetAppIcon />
                      Baixar PDF
                    </> 
                  }
                </Button>
              </Link>   
            </div>
            {observationStep ? (
              <>
                {isLoading ? (
                  <div className="div-load-lista-modelos" style={{ height: '65vh' }}> <CircularLoad/> </div>
                ) : (
                  <>
                    <div>
                      {observations.map((item) => (
                        <div key={item.id} className={classes.observationCard}>
                          <p className={classes.observationData}>{alterarData(item.created_at)}</p>
                          <p className={classes.observationText}>
                            {item.observation}
                          </p>
                          {item.files.length !== 0 && <p className={classes.observationAnnex}>Anexos:</p>}
                          <span
                            className={classes.observationFile}
                          >
                            {item.files.map((file) => (
                              <span 
                                onClick={() => donwloadFile(file.url, file.file_name)}
                                style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '0', marginRight: '11px' }}
                              >
                                {file.file_name && 
                                  <img style={{ width: '16px', marginRight: '3px' }} src={annexIcon} alt="annex icon" />
                                }
                                {file.file_name}
                              </span>
                            ))}
                          </span>
                        </div>
                      ))}
                    </div>

                    <div className={classes.divEnvolveForm}>
                      <p style={{ fontSize: '1.3rem'}}>Observação:</p>

                      <TextField
                        id="outlined-multiline-static"
                        label="Digite aqui"
                        multiline
                        rows={5}
                        required
                        variant="outlined"
                        value={observationTextFieldValue}
                        onChange={(e) => setObservationTextFieldValue(e.target.value)}
                        error={validaMensagem ? true:false}
                        helperText={validaMensagem ? 'Campo obrigatório' : ''}
                        autoFocus={false}
                      />

                      <p style={{ fontSize: '1.3rem'}}>Anexar documentos</p>

                      <div className={classes.annexDiv}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p id="file-name">Selecionar arquivo</p>

                          <input type="file" name='files[]' id="file-input" multiple onChange={handleAnnex} />
                          <label for="file-input">ANEXAR</label>
                        </div>

                        <Button 
                          variant="contained" 
                          className={clsx('color-primary', classes.btnVizualizar)}  
                          color="primary" 
                          onClick={handleObservation}
                        >
                          SALVAR
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className={classes.chart__content}>
                {chartComponents[chartComponentsIndex].component}
              </div>
            )}
          </section>
        </Grid>
      </div>
    </>
  )
}