import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'


function FormataTelefone(props) {
    const { inputRef, onChange, ...other } = props;
   
    const [number, setNumber] = useState('')
              
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          setNumber(values.value);
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        // format={props.value > 10 ? '(##) # ####-####' :'(##) ####-####'}
        format={props.value ? props.value.length > 10 ? '(##) # ####-####' :'(##) ####-#####' : number.length > 10 ? '(##) # ####-####' :'(##) ####-#####'}
      />
    );
  }
  
  FormataTelefone.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

export default function InputTelefone({id , title ,required ,edit}) {
    const [telefone, setTelefone] = useState('');
    const [error, setError] = useState(false)

    const { Answer ,setAnswer ,respEdit , setMontagemProntuario ,MontagemProntuario , setNameProntuario, NameProntuario, PosProntuario ,setpos , respView, validation, setValidation,
      attValidation, setAttValidation, attEncerraError} = StateGlobal();

    const handleChangeInput = event => {
        const { value } = event.target;
        Answer.map((item, index) => {
            if (item.question_id === id) {
                setTelefone(value)
                Answer[index].answer = value
                
                if(required){
                  if( !Answer[index].answer ){
                    setError(true)
                  }
                  else{
                  setError(false)
                  }
                }
            }
        })
          const array  = MontagemProntuario
  
          array[PosProntuario].montagem.map((item , index)=>{
              if(item.tempId === id){

                  item.answer = value
              }
  
          })
  
          setMontagemProntuario(array)
          
  
   
          
      };
      const handleBlur = event =>{
        Answer.map((item, index) => {
          if (item.question_id === id) {
                          
              if(required){
                if( !Answer[index].answer ){
                  setError(true)
                }
                else{
                setError(false)
                }
              }
          }
      })
      }

      useEffect(() => {
        if(validation) {
      
          validation.forEach((step, index)=>{
            step.components.forEach((comp, i)=>{        
             
              if(comp.id == id){
       
                setError(validation[index].components[i].error)
                  
              }
            })
          })
        }
    
      }, [attEncerraError])
    
    
      useEffect(()=>{
        if(validation) {
      
          validation.forEach((step, index)=>{
            step.components.forEach((comp, i)=>{        
             
              if(comp.id == id){
       
                validation[index].components[i].error = error 
                
                  
              }
              })
          })
          setAttValidation(!attValidation)
        }
    
      }, [error])
  
      useEffect(()=>{
        setTelefone(edit)
      },[id])
  

    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setTelefone(item.answer)
    //         }
    //     })
    // }, [id])

    // useEffect(()=>{

    //   Answer.map((item,index)=>{
    //     if(item.question_id === id){  
    //       if(item.answer ===  ''){                 
    //         setTelefone(edit)
    //       }else{
    //         setTelefone(item.answer)

    //       }
    //     }
    //   })      

    // },[edit])
    
  return (
    <div>
        <p className="titulo-form">{title}</p>
        <TextField 
            className="mb-4"
            value={telefone}
            onChange={(e)=>{handleChangeInput(e)}}
            onBlur={(e)=>{handleBlur(e)}}
            type="tel"
            name="telefone"
            required={required}
            error={error}
            disabled={respView}
            variant="outlined"
            //disabled = {respEdit}
            InputProps={{
                inputComponent: FormataTelefone,
            }}
        />
        <div style={{ color: 'red', width: '100%', marginTop: '-15px', fontSize: '15px' }}>
              { error === true ? '* Preencha o campo' : ''}  
        </div>
    </div>
  );
}
