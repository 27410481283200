import React, { useContext, useState } from 'react'

import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function TestesFuncionais({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  const [normalizedDistances, setNormalizedDistances] = useState({})

  const navicularSeatedValue = () => {
    let scoreDom = 0
    let scoreNdom = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.dom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreDom += int
      }
    })

    Object.values(sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.ndom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreNdom += int
      }
    })

    scoreDom = scoreDom ? (scoreDom / 3 ).toFixed(2) : 0
    scoreNdom = scoreNdom ? (scoreNdom / 3 ).toFixed(2) : 0
    
    return { scoreDom, scoreNdom }
  }

  const navicularFootValue = () => {
    let scoreDom = 0
    let scoreNdom = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.dom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreDom += int
      }
    })

    Object.values(sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.ndom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreNdom += int
      }
    })

    scoreDom = scoreDom ? (scoreDom / 3 ).toFixed(2) : 0
    scoreNdom = scoreNdom ? (scoreNdom / 3 ).toFixed(2) : 0
    
    return { scoreDom, scoreNdom }
  }

  const hopTests = (test) => {
    let scoreDom = 0
    let scoreNdom = 0

    Object.values(sportsPhysiotherapyChartAnswers.funcionalTests[test].dom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreDom += int
      }
    })

    Object.values(sportsPhysiotherapyChartAnswers.funcionalTests[test].ndom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreNdom += int
      }
    })
    
    scoreDom = scoreDom / 3
    scoreNdom = scoreNdom / 3

    return (100 - ((scoreNdom / scoreDom) * 100)) || 0;
  }

  const normalizedDistanceDomScore = () => {
    let functionalTests = sportsPhysiotherapyChartAnswers.funcionalTests
    let scoreDA = 0
    let scorePL = 0
    let scorePM = 0
    let proposedScoreDOM = 0

    Object.values(functionalTests.previousDistance.dom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreDA += int
      }
    })

    Object.values(functionalTests.sidePostero.dom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scorePL += int
      }
    })

    Object.values(functionalTests.medialPostero.dom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scorePM += int
      }
    })

    scoreDA = scoreDA / 3
    scorePL = scorePL / 3
    scorePM = scorePM / 3
    
    scoreDA = scoreDA ? ((functionalTests.mmiiDom * 100) / scoreDA).toFixed(2) : '' 
    scorePL = scorePL ? ((functionalTests.mmiiDom * 100) / scorePL).toFixed(2) : '' 
    scorePM = scorePM ? ((functionalTests.mmiiDom * 100) / scorePM).toFixed(2) : ''
    
    proposedScoreDOM = scoreDA && scorePL && scorePM 
      ? (((functionalTests.mmiiDom * 100) * 3) / (parseInt(scoreDA) + parseInt(scorePL) + parseInt(scorePM))).toFixed(2) 
      : 0

    return { scoreDA, scorePL, scorePM, proposedScoreDOM } 
  }

  const normalizedDistanceNdomScore = () => {
    let functionalTests = sportsPhysiotherapyChartAnswers.funcionalTests
    let scoreDA = 0
    let scorePL = 0
    let scorePM = 0
    let proposedScoreNDOM = 0

    Object.values(functionalTests.previousDistance.ndom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scoreDA += int
      }
    })

    Object.values(functionalTests.sidePostero.ndom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scorePL += int
      }
    })

    Object.values(functionalTests.medialPostero.ndom).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        scorePM += int
      }
    })

    scoreDA = scoreDA / 3
    scorePL = scorePL / 3
    scorePM = scorePM / 3
    
    scoreDA = scoreDA ? ((functionalTests.mmiiNdom * 100) / scoreDA).toFixed(2) : '' 
    scorePL = scorePL ? ((functionalTests.mmiiNdom * 100) / scorePL).toFixed(2) : '' 
    scorePM = scorePM ? ((functionalTests.mmiiNdom * 100) / scorePM).toFixed(2) : ''
    
    proposedScoreNDOM = scoreDA && scorePL && scorePM 
      ? (((functionalTests.mmiiNdom * 100) * 3) / (parseInt(scoreDA) + parseInt(scorePL) + parseInt(scorePM))).toFixed(2) 
      : 0

    return { scoreDA, scorePL, scorePM, proposedScoreNDOM } 
  }

  const tugTest = () => {
    let score = 0
  
    Object.values(sportsPhysiotherapyChartAnswers.funcionalTests.tugTest).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return score ? (score / 3).toFixed(2) : 0;
  }
  
  return (
    <div>
      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          SINGLE HOP TEST
        </p>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="tel" 
              inputmode="numeric"
              pattern="[0-9]*"
              placeholder='1º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.singleHopTest.dom.singleHopTestDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    singleHopTest: {
                      ...state.funcionalTests.singleHopTest,
                      dom: {
                        ...state.funcionalTests.singleHopTest.dom,
                        singleHopTestDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.singleHopTest.dom.singleHopTestDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    singleHopTest: {
                      ...state.funcionalTests.singleHopTest,
                      dom: {
                        ...state.funcionalTests.singleHopTest.dom,
                        singleHopTestDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.singleHopTest.dom.singleHopTestDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    singleHopTest: {
                      ...state.funcionalTests.singleHopTest,
                      dom: {
                        ...state.funcionalTests.singleHopTest.dom,
                        singleHopTestDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.singleHopTest.ndom.singleHopTestNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    singleHopTest: {
                      ...state.funcionalTests.singleHopTest,
                      ndom: {
                        ...state.funcionalTests.singleHopTest.ndom,
                        singleHopTestNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.singleHopTest.ndom.singleHopTestNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    singleHopTest: {
                      ...state.funcionalTests.singleHopTest,
                      ndom: {
                        ...state.funcionalTests.singleHopTest.ndom,
                        singleHopTestNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.singleHopTest.ndom.singleHopTestNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    singleHopTest: {
                      ...state.funcionalTests.singleHopTest,
                      ndom: {
                        ...state.funcionalTests.singleHopTest.ndom,
                        singleHopTestNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {hopTests('singleHopTest').toFixed(2)}%</p>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          TRIPLE HOP TEST
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tripleHopTest.dom.tripleHopTestDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tripleHopTest: {
                      ...state.funcionalTests.tripleHopTest,
                      dom: {
                        ...state.funcionalTests.tripleHopTest.dom,
                        tripleHopTestDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tripleHopTest.dom.tripleHopTestDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tripleHopTest: {
                      ...state.funcionalTests.tripleHopTest,
                      dom: {
                        ...state.funcionalTests.tripleHopTest.dom,
                        tripleHopTestDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tripleHopTest.dom.tripleHopTestDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tripleHopTest: {
                      ...state.funcionalTests.tripleHopTest,
                      dom: {
                        ...state.funcionalTests.tripleHopTest.dom,
                        tripleHopTestDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tripleHopTest.ndom.tripleHopTestNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tripleHopTest: {
                      ...state.funcionalTests.tripleHopTest,
                      ndom: {
                        ...state.funcionalTests.tripleHopTest.ndom,
                        tripleHopTestNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tripleHopTest.ndom.tripleHopTestNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tripleHopTest: {
                      ...state.funcionalTests.tripleHopTest,
                      ndom: {
                        ...state.funcionalTests.tripleHopTest.ndom,
                        tripleHopTestNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tripleHopTest.ndom.tripleHopTestNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tripleHopTest: {
                      ...state.funcionalTests.tripleHopTest,
                      ndom: {
                        ...state.funcionalTests.tripleHopTest.ndom,
                        tripleHopTestNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {hopTests('tripleHopTest').toFixed(2)}%</p>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          TIMED HOP TEST
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.timedHopTest.dom.timedHopTestDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    timedHopTest: {
                      ...state.funcionalTests.timedHopTest,
                      dom: {
                        ...state.funcionalTests.timedHopTest.dom,
                        timedHopTestDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.timedHopTest.dom.timedHopTestDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    timedHopTest: {
                      ...state.funcionalTests.timedHopTest,
                      dom: {
                        ...state.funcionalTests.timedHopTest.dom,
                        timedHopTestDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.timedHopTest.dom.timedHopTestDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    timedHopTest: {
                      ...state.funcionalTests.timedHopTest,
                      dom: {
                        ...state.funcionalTests.timedHopTest.dom,
                        timedHopTestDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.timedHopTest.ndom.timedHopTestNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    timedHopTest: {
                      ...state.funcionalTests.timedHopTest,
                      ndom: {
                        ...state.funcionalTests.timedHopTest.ndom,
                        timedHopTestNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.timedHopTest.ndom.timedHopTestNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    timedHopTest: {
                      ...state.funcionalTests.timedHopTest,
                      ndom: {
                        ...state.funcionalTests.timedHopTest.ndom,
                        timedHopTestNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.timedHopTest.ndom.timedHopTestNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    timedHopTest: {
                      ...state.funcionalTests.timedHopTest,
                      ndom: {
                        ...state.funcionalTests.timedHopTest.ndom,
                        timedHopTestNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {hopTests('timedHopTest').toFixed(2)}%</p>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          LUNGE TEST
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.lungeTest.dom.lungeTestDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    lungeTest: {
                      ...state.funcionalTests.lungeTest,
                      dom: {
                        ...state.funcionalTests.lungeTest.dom,
                        lungeTestDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">graus</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.lungeTest.dom.lungeTestDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    lungeTest: {
                      ...state.funcionalTests.lungeTest,
                      dom: {
                        ...state.funcionalTests.lungeTest.dom,
                        lungeTestDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">graus</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.lungeTest.dom.lungeTestDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    lungeTest: {
                      ...state.funcionalTests.lungeTest,
                      dom: {
                        ...state.funcionalTests.lungeTest.dom,
                        lungeTestDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">graus</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.lungeTest.ndom.lungeTestNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    lungeTest: {
                      ...state.funcionalTests.lungeTest,
                      ndom: {
                        ...state.funcionalTests.lungeTest.ndom,
                        lungeTestNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">graus</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.lungeTest.ndom.lungeTestNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    lungeTest: {
                      ...state.funcionalTests.lungeTest,
                      ndom: {
                        ...state.funcionalTests.lungeTest.ndom,
                        lungeTestNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">graus</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º.NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.lungeTest.ndom.lungeTestNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    lungeTest: {
                      ...state.funcionalTests.lungeTest,
                      ndom: {
                        ...state.funcionalTests.lungeTest.ndom,
                        lungeTestNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">graus</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {hopTests('lungeTest').toFixed(2)}%</p>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          FORÇA MUSCULAR DE EXTENSORES DE QUADRIL
        </p>

        <p className='titulo-form'>Número de repetições:</p>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='DOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.hipExtendersRepetitonsDom}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    hipExtendersRepetitonsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">reps</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='NDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.hipExtendersRepetitonsNdom}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    hipExtendersRepetitonsNdom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">reps</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>      
      </article>

      <Grid style={{ marginTop: '35px' }} container spacing={1} alignItems='center'>
        <Grid item xs={4}>
          <p className="titulo-form">MMII - DISTÂNCIA EIAS - MALÉOLO LATERAL:</p>
        </Grid>
        <Grid item xs={4}>
          <p className="titulo-form">DOM:</p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            value={sportsPhysiotherapyChartAnswers.funcionalTests.mmiiDom}
            disabled={sportsPhysiotherapyView}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers( state => ({
                ...state,
                funcionalTests: {
                  ...state.funcionalTests,
                  mmiiDom: e.target.value
                }
              }))
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p className="titulo-form">NDOM:</p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            value={sportsPhysiotherapyChartAnswers.funcionalTests.mmiiNdom}
            disabled={sportsPhysiotherapyView}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers( state => ({
                ...state,
                funcionalTests: {
                  ...state.funcionalTests,
                  mmiiNdom: e.target.value
                }
              }))
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <article>
        <p 
          className="titulo-form"
          style={{ marginBottom: '5px' }}
        >
          LQYBTest
        </p>

        <p className='titulo-form'>Perna DOM:</p>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Distância Anterior (DA) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.previousDistance.dom.lqybtPreviousDistanceDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    previousDistance: {
                      ...state.funcionalTests.previousDistance,
                      dom: {
                        ...state.funcionalTests.previousDistance.dom,
                        lqybtPreviousDistanceDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Distância Anterior (DA) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.previousDistance.dom.lqybtPreviousDistanceDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    previousDistance: {
                      ...state.funcionalTests.previousDistance,
                      dom: {
                        ...state.funcionalTests.previousDistance.dom,
                        lqybtPreviousDistanceDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Distância Anterior (DA) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.previousDistance.dom.lqybtPreviousDistanceDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    previousDistance: {
                      ...state.funcionalTests.previousDistance,
                      dom: {
                        ...state.funcionalTests.previousDistance.dom,
                        lqybtPreviousDistanceDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>
        
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Póstero Lateral (PL) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.sidePostero.dom.lqybtSidePosteroDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    sidePostero: {
                      ...state.funcionalTests.sidePostero,
                      dom: {
                        ...state.funcionalTests.sidePostero.dom,
                        lqybtSidePosteroDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Póstero Lateral (PL) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.sidePostero.dom.lqybtSidePosteroDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    sidePostero: {
                      ...state.funcionalTests.sidePostero,
                      dom: {
                        ...state.funcionalTests.sidePostero.dom,
                        lqybtSidePosteroDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Póstero Lateral (PL) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.sidePostero.dom.lqybtSidePosteroDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    sidePostero: {
                      ...state.funcionalTests.sidePostero,
                      dom: {
                        ...state.funcionalTests.sidePostero.dom,
                        lqybtSidePosteroDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '4px' }}
              placeholder='Póstero Medial (PM) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.medialPostero.dom.lqybtMedialPosteroDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    medialPostero: {
                      ...state.funcionalTests.medialPostero,
                      dom: {
                        ...state.funcionalTests.medialPostero.dom,
                        lqybtMedialPosteroDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '4px' }}
              placeholder='Póstero Medial (PM) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.medialPostero.dom.lqybtMedialPosteroDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    medialPostero: {
                      ...state.funcionalTests.medialPostero,
                      dom: {
                        ...state.funcionalTests.medialPostero.dom,
                        lqybtMedialPosteroDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '4px' }}
              placeholder='Póstero Medial (PM) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.medialPostero.dom.lqybtMedialPosteroDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    medialPostero: {
                      ...state.funcionalTests.medialPostero,
                      dom: {
                        ...state.funcionalTests.medialPostero.dom,
                        lqybtMedialPosteroDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <p className='titulo-form' style={{ marginTop: '15px' }}>Perna NDOM:</p>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Distância Anterior (DA) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.previousDistance.ndom.lqybtPreviousDistanceNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    previousDistance: {
                      ...state.funcionalTests.previousDistance,
                      ndom: {
                        ...state.funcionalTests.previousDistance.ndom,
                        lqybtPreviousDistanceNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Distância Anterior (DA) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.previousDistance.ndom.lqybtPreviousDistanceNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    previousDistance: {
                      ...state.funcionalTests.previousDistance,
                      ndom: {
                        ...state.funcionalTests.previousDistance.ndom,
                        lqybtPreviousDistanceNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Distância Anterior (DA) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.previousDistance.ndom.lqybtPreviousDistanceNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    previousDistance: {
                      ...state.funcionalTests.previousDistance,
                      ndom: {
                        ...state.funcionalTests.previousDistance.ndom,
                        lqybtPreviousDistanceNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Póstero Lateral (PL) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.sidePostero.ndom.lqybtSidePosteroNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    sidePostero: {
                      ...state.funcionalTests.sidePostero,
                      ndom: {
                        ...state.funcionalTests.sidePostero.ndom,
                        lqybtSidePosteroNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Póstero Lateral (PL) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.sidePostero.ndom.lqybtSidePosteroNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    sidePostero: {
                      ...state.funcionalTests.sidePostero,
                      ndom: {
                        ...state.funcionalTests.sidePostero.ndom,
                        lqybtSidePosteroNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              style={{ marginBottom: '4px' }}
              fullWidth
              placeholder='Póstero Lateral (PL) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.sidePostero.ndom.lqybtSidePosteroNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    sidePostero: {
                      ...state.funcionalTests.sidePostero,
                      ndom: {
                        ...state.funcionalTests.sidePostero.ndom,
                        lqybtSidePosteroNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '4px' }}
              placeholder='Póstero Medial (PM) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.medialPostero.ndom.lqybtMedialPosteroNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    medialPostero: {
                      ...state.funcionalTests.medialPostero,
                      ndom: {
                        ...state.funcionalTests.medialPostero.ndom,
                        lqybtMedialPosteroNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '4px' }}
              placeholder='Póstero Medial (PM) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.medialPostero.ndom.lqybtMedialPosteroNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    medialPostero: {
                      ...state.funcionalTests.medialPostero,
                      ndom: {
                        ...state.funcionalTests.medialPostero.ndom,
                        lqybtMedialPosteroNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '4px' }}
              placeholder='Póstero Medial (PM) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.medialPostero.ndom.lqybtMedialPosteroNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    medialPostero: {
                      ...state.funcionalTests.medialPostero,
                      ndom: {
                        ...state.funcionalTests.medialPostero.ndom,
                        lqybtMedialPosteroNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          Distância Normalizada DOM
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='DA'
              value={normalizedDistanceDomScore().scoreDA}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='PL'
              value={normalizedDistanceDomScore().scorePL}
              disabled
            />
          </Grid>
 
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='PM'
              value={normalizedDistanceDomScore().scorePM}
              disabled
            />
          </Grid>
        </Grid>

        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          Distância Normalizada NDOM
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='DA'
              value={normalizedDistanceNdomScore().scoreDA}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='PL'
              value={normalizedDistanceNdomScore().scorePL}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='PM'
              value={normalizedDistanceNdomScore().scorePM}
              disabled
            />
          </Grid>
        </Grid>
      </article>
      
      <article>
        <Grid container spacing={6}>
          <Grid item>
            <p style={{ marginBottom: 0 }} className={classes.score}>Pontuação Proposta</p>
            <p style={{ fontWeight: '500', marginTop: 0}}>
              LSI - Escore: DOM: {normalizedDistanceDomScore().proposedScoreDOM} - NDOM: {normalizedDistanceNdomScore().proposedScoreNDOM}
            </p>
          </Grid>
          <Grid item>
            <p style={{ marginBottom: 0 }} className={classes.score}>Assimetria Absoluta</p>
            <p style={{ fontWeight: '500', margin: 0}}>
              LSI - Escore: 
              DA: {
                normalizedDistanceDomScore().scoreDA - normalizedDistanceNdomScore().scoreDA
              }&nbsp;
              PL: {
                normalizedDistanceDomScore().scorePL - normalizedDistanceNdomScore().scorePL
              }&nbsp;
              PM: {
                normalizedDistanceDomScore().scorePM - normalizedDistanceNdomScore().scorePM
              }
            </p>
          </Grid>
        </Grid>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          DROP NAVICULAR
        </p>

        <p className='titulo-form'>Sentado (DOM)</p>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='DOM 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.dom.dropNavicularSeatedDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropSeated: {
                      ...state.funcionalTests.dropSeated,
                      dom: {
                        ...state.funcionalTests.dropSeated.dom,
                        dropNavicularSeatedDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='DOM 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.dom.dropNavicularSeatedDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropSeated: {
                      ...state.funcionalTests.dropSeated,
                      dom: {
                        ...state.funcionalTests.dropSeated.dom,
                        dropNavicularSeatedDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='DOM 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.dom.dropNavicularSeatedDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropSeated: {
                      ...state.funcionalTests.dropSeated,
                      dom: {
                        ...state.funcionalTests.dropSeated.dom,
                        dropNavicularSeatedDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='Media'
              value={navicularSeatedValue().scoreDom}
              disabled
              InputProps={{
                endAdornment: <InputAdornment position="end">Media</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        

        <p className='titulo-form'>Sentado (NDOM)</p>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='NDOM 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.ndom.dropNavicularSeatedNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropSeated: {
                      ...state.funcionalTests.dropSeated,
                      ndom: {
                        ...state.funcionalTests.dropSeated.ndom,
                        dropNavicularSeatedNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='NDOM 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.ndom.dropNavicularSeatedNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropSeated: {
                      ...state.funcionalTests.dropSeated,
                      ndom: {
                        ...state.funcionalTests.dropSeated.ndom,
                        dropNavicularSeatedNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='NDOM 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropSeated.ndom.dropNavicularSeatedNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropSeated: {
                      ...state.funcionalTests.dropSeated,
                      ndom: {
                        ...state.funcionalTests.dropSeated.ndom,
                        dropNavicularSeatedNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
          <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='Media'
              value={navicularSeatedValue().scoreNdom}
              disabled
              InputProps={{
                endAdornment: <InputAdornment position="end">Media</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Pé (DOM)</p>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='DOM 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.dom.dropNavicularFootDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropFoot: {
                      ...state.funcionalTests.dropFoot,
                      dom: {
                        ...state.funcionalTests.dropFoot.dom,
                        dropNavicularFootDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='DOM 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.dom.dropNavicularFootDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropFoot: {
                      ...state.funcionalTests.dropFoot,
                      dom: {
                        ...state.funcionalTests.dropFoot.dom,
                        dropNavicularFootDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='DOM 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.dom.dropNavicularFootDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropFoot: {
                      ...state.funcionalTests.dropFoot,
                      dom: {
                        ...state.funcionalTests.dropFoot.dom,
                        dropNavicularFootDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='Media'
              value={navicularFootValue().scoreDom}
              disabled
              InputProps={{
                endAdornment: <InputAdornment position="end">Media</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className='titulo-form'>Pé (NDOM)</p>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='NDOM 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.ndom.dropNavicularFootNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropFoot: {
                      ...state.funcionalTests.dropFoot,
                      ndom: {
                        ...state.funcionalTests.dropFoot.ndom,
                        dropNavicularFootNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='NDOM 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.ndom.dropNavicularFootNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropFoot: {
                      ...state.funcionalTests.dropFoot,
                      ndom: {
                        ...state.funcionalTests.dropFoot.ndom,
                        dropNavicularFootNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='NDOM 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.dropFoot.ndom.dropNavicularFootNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    dropFoot: {
                      ...state.funcionalTests.dropFoot,
                      ndom: {
                        ...state.funcionalTests.dropFoot.ndom,
                        dropNavicularFootNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
          <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='Media'
              value={navicularFootValue().scoreNdom}
              disabled
              InputProps={{
                endAdornment: <InputAdornment position="end">Media</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        {/* <p className={classes.score}>LSI - Escore:</p> */}
      </article>

      {/* Descomentar quando solicitado pelo cliente */}
      {/* <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 5px 0' }}
        >
          TESTE DE CAMINHA DE 6 MINUTOS (TC6')
        </p>

        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='TC6`'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.walkTestTC6}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    walkTestTC6: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='PAi'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.walkTestPAi}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    walkTestPAi: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='PAf'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.walkTestPAf}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    walkTestPAf: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='BORGi/f'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.walkTestBORGi}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    walkTestBORGi: e.target.value
                  }
                }))
              }}
            />
          </Grid>
        </Grid>
      </article> */}

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 5px 0' }}
        >
          TIME UP AND GO TEST (TUG)
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tugTest.tugTest1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tugTest: {
                      ...state.funcionalTests.tugTest,
                      tugTest1: e.target.value
                    },
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tugTest.tugTest2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tugTest: {
                      ...state.funcionalTests.tugTest,
                      tugTest2: e.target.value
                    },
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests.tugTest.tugTest3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests: {
                    ...state.funcionalTests,
                    tugTest: {
                      ...state.funcionalTests.tugTest,
                      tugTest3: e.target.value
                    },
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">seg</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>Media: {tugTest()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}