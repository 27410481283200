import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { toPng } from "html-to-image";
import { StateGlobal } from "../../../../../ContextAPI/context";

export default function Grafico({ title, value }) {
  console.log(value);

  const [data, setData] = useState([]);
  // const [listImgBase64, setListImgBase64] = useState([])
  const { listaImgBase64, setListaImgBase64 } = StateGlobal();
  const Name_Scale_type = localStorage.getItem("Name_Scale_type");

  const ref = useRef({ HTMLDivElement });

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    let arr = [];

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        // link.download = 'my-image-name.png'
        link.href = dataUrl;
        setListaImgBase64((oldstate) => [
          ...oldstate,
          { link: link.href, title: title, value: value },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  async function transform() {
    let data = await value?.map((item, index) => {
      if (item["[-1]"] && item["[-2]"] && item["[-3]"]) {
        return {
          3: Number(item[3]),
          2: Number(item[2]),
          1: Number(item[1]), //  Paciente: Number(item.month),
          0: Number(item[0]),
          "-1": Number(item["[-1]"]),
          "-2": Number(item["[-2]"]),
          "-3": Number(item["[-3]"]),
          Paciente: Number(item?.value),
          meses: Number(item.month),
        };
      } else {
        return {
          "3%": Number(item["3%"]),
          "15%": Number(item["15%"]),
          "50%": Number(item["50%"]), //  Paciente: Number(item.month),
          "85%": Number(item["85%"]),
          "97%": Number(item["97%"]),
          Paciente: Number(item?.value),
          meses: Number(item.month),
        };
      }
    });

    setData(data);
  }

  useEffect(() => {
    transform();
    setTimeout(() => {
      onButtonClick();
    }, [2000]);
  }, []);

  return (
    <div className="Grade-Container-Graficos2" ref={ref}>
      <div className="Title-Grafico2">
        <p>
          {title}
          {" - "}{" "}
          {!value[0]["[-1]"] && !value[0]["[-2]"] && !value[0]["[-3]"]
            ? "Percentil"
            : "Z-Score"}{" "}
          {" - "}
          {Name_Scale_type}
        </p>
      </div>
      <div className="Grade-width">
        <div className="Container-Informações-Grafico">
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "-60px",
            }}
          >
            {!value[0]["[-1]"] && !value[0]["[-2]"] && !value[0]["[-3]"] ? (
              <>
                <LineChart
                  width={800}
                  height={400}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="meses" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}

                  <Line
                    type="monotone"
                    dataKey="3%"
                    stroke="red"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="15%"
                    stroke="orange"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="50%"
                    stroke="green"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="85%"
                    stroke="orange"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="97%"
                    stroke="red"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="Paciente"
                    strokeWidth={2}
                    dot={{ stroke: 'Blue', strokeWidth: 2 }}
                    stroke="#ffffff00"
                    activeDot={{ r: 5 }}
                    fill={true}
                  />
                </LineChart>
              </>
            ) : (
              <>
                <LineChart
                  width={800}
                  height={400}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="meses" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}

                  <Line
                    type="monotone"
                    dataKey="3"
                    stroke="black"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="2"
                    stroke="red"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="1"
                    stroke="orange"
                    strokeWidth={1}
                    dot={false}
                  />

                  <Line
                    type="monotone"
                    dataKey="0"
                    stroke="green"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="-1"
                    stroke="orange"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="-2"
                    stroke="red"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="-3"
                    stroke="black"
                    strokeWidth={1}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="Paciente"
                    strokeWidth={1}
                    dot={{ stroke: 'Blue', strokeWidth: 2 }}
                    stroke="#ffffff00"
                    activeDot={{ r: 5 }}
                    fill={true}
                  />
                </LineChart>
              </>
            )}

            {/* <Line type="monotone" dataKey="Paciente" stroke="blue" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
