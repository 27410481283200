import React, { useContext, useRef, useEffect, useState } from 'react'

import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { SportsPhysiotherapyContext } from '../../../../../../ContextAPI/SportsPhysiotherapyContext'
import { useStyles } from './style'

export default function TesteClinico() {
  const classes = useStyles();

  const {
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  // useEffect(() => {
  //   let neerEl = neer.current
    
  //   return () => {
  //     setSportsPhysiotherapyChartAnswers(state => ({
  //       ...state,
  //       algometry: {
  //         ...state.algometry,
  //         neer: neerEl.value
  //       }
  //     }))
  //   }
  // }, [setSportsPhysiotherapyChartAnswers])

  return (
    <article>
        <h2
          className="titulo-form"
          style={{ marginTop: '15px' }}
        >
          Teste Clínico
        </h2>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">NEER</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.neer}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  neer: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">HAWKING</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.hawking}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  hawking: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">ARCO DOLOROSO</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.arcodoloroso}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  arcodoloroso: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">THOMAS</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.thomas}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  thomas: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">ENCURTAMENTO GRANDE DORSAL</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.encurtamento}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  encurtamento: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">LASEGUE</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.lasegue}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  lasegue: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">SPURLING</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.spurling}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  spurling: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">SHIFT LATERAL À DIREITO</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.shiftlateral}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  shiftlateral: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">SHIFT LATERAL À ESQUERDO</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.shiftlateralEsquerdo}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  shiftlateralEsquerdo: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">THOMPSON</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.thompson}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  thompson: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">GAVETA ANTERIOR</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.gaveta}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  gaveta: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">GAVETA POSTERIOR</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.gavetaPosterior}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  gavetaPosterior: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">PATRICK-FABERE</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.patrick}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  patrick: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">TRENDELEMBURG</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.trendele}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  trendele: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">DISCINESE ESCAPULAR</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.discinese}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  discinese: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">+ OUTROS*</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.algometry.outros}
            onChange={(e) => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                algometry: {
                  ...state.algometry,
                  outros: e.target.value
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl>

        {/* <FormControl component="div" className={classes.cait__formControl}>
          <FormLabel component="legend">Quando corro em superfícies irregulares</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={sportsPhysiotherapyChartAnswers.cait.anklePain.runOnUnevenSurfaces}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                cait: {
                  ...state.cait,
                  anklePain: {
                    ...state.cait.anklePain,
                    runOnUnevenSurfaces: e.target.value
                  }
                }
              }))
            }}
          >
            <FormControlLabel value="Direito" disabled={sportsPhysiotherapyView} control={<Radio />} label="Direito" />
            <FormControlLabel value="Esquerdo" disabled={sportsPhysiotherapyView} control={<Radio />} label="Esquerdo" />
          </RadioGroup>
        </FormControl> */}
      </article>
  )
}