import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { StateGlobal } from '../../../ContextAPI/context'
import DadosPessoais from '../Components/DadosPessoais'
import InputTextoCurto from './InputsBasicos/InputTextoCurto';
import InputTextoLongo from './InputsBasicos/InputTextoLongo'
import InputTelefone from './InputsBasicos/InputTelefone'
import InputNumerico from './InputsBasicos/InputNumerico'
import InputEmail from './InputsBasicos/InputEmail'
import InputSelect from './InputsBasicos/InputSelect'
import InputData from './InputsBasicos/InputData'
import InputCheckbox from './InputsBasicos/InputCheckbox'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputLabel from './InputsBasicos/InputLabel'
import InputImagem from './InputsEspeciais/InputImagem';
import InputEscalaDor from './InputsEspeciais/InputEscalaDor';
import InputEscala from './InputsEspeciais/InputEscala';
import InputTabela from './InputsEspeciais/InputTabela';
import InputOdontologico from './InputsEspeciais/InputOdontologico'
import InputDesenvolvimentoMotor from './InputsEspeciais/InputDesenvolvimentoMotor'
import InputOsteopatia from './InputsEspeciais/InputOsteopatia'
import InputCurvaDeCrescimento from './InputsEspeciais/InputCurvaDeCrescimento'
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import conexao from '../../../Services/api' 
import CircularLoad from '../../../Components/Load';
import clsx from 'clsx';
import annexIcon from '../../../Assets/Images/annexIcon.svg';
import { saveAs } from 'file-saver';



const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo: {
    marginTop: '8.25rem',
    height: '87vh',
    padding: '2rem'
  },
  envolveDadosPaciente: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 6px 10px 0px #0000000F'
  },
  divEnvolveFotoDados: {
    display: 'flex'
  },
  divEnvolveTextos: {
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      color: '#8a8a8a'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    fontSize: '2rem'
  },
  tituloName: {
    fontSize: '1.3em',
    color: '#27b8d2 !important',
    marginBottom: '.3rem !important',
    fontWeight: '600'
  },
  tituloEtapa: {
    fontSize: '2em',
    color: '#757575 !important',
    marginBottom: '.6rem !important',
    fontWeight: '600',
    marginTop: '2rem'
  },
  filtroData: {
    display: 'flex',
  },
  filtroResulo: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '2rem',
    height: '48vh',
    overflow: 'auto'
  },
  pSelecionarData: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  textField: {
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  table: {
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '2rem',
  },
  subTituloEtapa: {
    fontSize: '1.2em',
    color: '#757575 !important',
    marginBottom: '1rem !important',
    fontWeight: '500',
    marginTop: '0rem'
  },
  divEnvolveTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    backgroundColor: '#F8F8F8',
    boxShadow: '0px 6px 10px 0px #0000000f',
    padding: '2rem',
    paddingTop: 0
  },
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    backgroundColor: 'white',
    minHeight: '3vh',
    borderRadius: '4px',
    padding: '2rem',
    paddingTop: 0
  },
  localInputs: {
    paddingTop: '30px'
  },
  divButtonSalvar: {
    '& button': {
      marginRight: '1rem'
    },
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2rem',
    marginRight: '1rem'
  },
  containerProntuario: {
    minHeight: '87vh',
    background: '#F8F8F8',
    borderRadius: '4px',
    paddingBottom: '30px',
    marginTop: '20px',
    width: '95%'
  },
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    backgroundColor: 'white',
    boxShadow: '0px 6px 10px 0px #0000000f',
    borderRadius: '4px',
    padding: '2rem',
    paddingTop: 0
  },
  observationCard: {
    padding: '1.5rem',
    marginTop: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0px 6px 10px 0px #0000000F'

  },
  observationData: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: '#27B8D2',
    margin: '0 0 20px 0'
  },
  observationText: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
    textAlign: 'justify'
  },
  observationAnnex: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: '#27B8D2',
    marginBottom: '0'
  },
  observationFile: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: '#E7366A',
    textDecoration: 'underline',
    cursor: 'pointer',

    '& * + span': {
      marginLeft: '13px'
    },
  },
  divEnvolveForm:{
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: '1.5rem',
    boxShadow: '0px 6px 10px 0px #0000000f',
    marginTop: '50px',

    '& p': {
      margin: '0 0 10px 0',
      fontWeight: 500,
      fontSize: '22px',
      color: '#27B8D2'
    },

    '& > *': {
      marginBottom: theme.spacing(3),
      width: '100%',
    },

    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F8F8F8',
      boxShadow: '0px 6px 10px 0px #0000000f',
    }
  },
  annexDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& input#file-input + label': {
      padding: '6px 16px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#27B8D2',
      color: '#FFFFFF',
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.75',
      display: 'block',
      textAlign: 'center',
      marginTop: '15px',
      marginBottom: '15px',
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

      '&:hover': {
        cursor: 'pointer'
      }
    },

    '& p':{
      padding: '6px 16px',
      margin: '0',
      marginRight: '13px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#F8F8F8',
      color: '#CCCCCC',
      fontSize: '19px',
      boxShadow: '0px 6px 10px 0px #0000000f',
      width: '420px',
      overflow: 'hidden',
      height: '37px'
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(data, hora, prontuario) {
  return { data, hora, prontuario };
}

const rows = [
  createData('24/03/2021', '11:24', ''),
];

export default function Prontuario(props) {
  const classes = useStyles();

  const [error, setError] = useState()
  const [observationTextFieldValue, setObservationTextFieldValue] = useState('')
  const [observations, setObservations] = useState([])
  const [validaMensagem, setValidaMensagem] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [ load , setLoad] = useState(false)
  const [ formID , setFormID] = useState('')
  const [ patientID , setPatientID] = useState('')

  // const idForm = localStorage.getItem("idEdicaoRP")

  const { 
    MontagemProntuario, 
    PosProntuario, 
    setPosProntuario, 
    respEdit, 
    NameProntuario, 
    idProntuario, 
    validation, 
    attValidation, 
    attEncerraError, 
    setAttEncerraError,
    observationStep,
    formPatientID
  } = StateGlobal();
  
  async function print(){
    setLoad(true)

    try{  
      // const resp = await conexao.post(`/form/list/${66}`)
      const id = idProntuario
      // const response = await conexao.get(`/form/patient/print/${localStorage.getItem('Montagemd')}`, {responseType: 'arraybuffer'})
      const response = await conexao.get(`/form/patient/print/${formID}`, {responseType: 'arraybuffer'})
        var FileSaver = require('file-saver');
        var file = new Blob([response.data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);
        FileSaver.saveAs(fileURL)
      
        setLoad(false)
    }catch(error){
    }
  }

  useEffect(()=>{
    let erro = 0

    if(validation.length) {
      validation.forEach(item =>{
        erro = item.components.findIndex(val => val.error == true)

        setError(erro)
      })
    }
  },[attValidation])
  
  useEffect(() => {
    // console.log('PRONTUARIO')
    // console.log(props)
    // getUserObservations()
  }, [])

  useEffect(() => {
    console.log('PRONTUARIO (props)')
    // console.log(props)
    setFormID(props.formID)
    console.log(props.formID)
    setPatientID(props.patientID)

  }, [props])

  useEffect(() => {
    getUserObservations()
  }, [formID])

  async function getUserObservations(id) {
    // const res = await conexao.get(`/historic/getall/${localStorage.getItem('idEdicaoRP')}`, {
    if (formID) {
      const res = await conexao.get(`/historic/getall/${formID}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
      })
      setObservations(res.data.data)
    }
    
  }
  
  async function handleObservation() {
    if ( observationTextFieldValue === '' ) {
      setValidaMensagem(true)
      return
    } 
    setValidaMensagem(false)
    
    setIsLoading(true)
    await conexao.post(`historic/create`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }, 
      // form_id: localStorage.getItem('idEdicaoRP'),
      form_id: formID,
      observation: observationTextFieldValue
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then(async res => {
      if(uploadedFileName) {
        uploadedFileName.forEach(async (file, idx) => {
          let formData = new FormData();
          formData.append(`file`, file);

          const fileUrl = await conexao.post('/upload', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
  
          await conexao.post(`file/create`, {
            historic_id: res.data.data.id,
            url: fileUrl.data,
            file_name: file.name 
          })

          getUserObservations()
        })
      }
      
      getUserObservations()
      setObservationTextFieldValue('')
      setUploadedFileName('')
    })
    setIsLoading(false)
  }
  
  function handleAnnex(event) {
    let files = Object.values(event.target.files)

    let fileNameField = document.getElementById('file-name');
    fileNameField.textContent = files.map((file) => file.name);

    setUploadedFileName(files)
  }

  async function donwloadFile(fileUrl, fileName) {
    conexao({
      url: `/download/path`,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        caminho: fileUrl,
        name: fileName
      },
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL
        .createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  function alterarData(data) {
    const initialDate = data.split(' ')
    const time = initialDate[1].split('.')[0].split(':')
    const date = initialDate[0].split('-').reverse()
    const formatedDate = `${date[0]}/${date[1]}/${date[2]}`
    const formatedTime = `${time[0]}:${time[1]}`

    return `${formatedDate} ${formatedTime}`
  }

  return (
    <div className={classes.envolveResumo}>
      <ThemeProvider theme={theme}>
        <div>
          <DadosPessoais patientID={patientID}/>
        </div>
        <>
      
        {error && error !== -1 ? 
          !respEdit ? 
          <Alert variant="filled" severity="error">
            Há campos inválidos no prontuário
          </Alert> : ''
          : ''
        }
        </>
        <div className={classes.containerProntuario}>
          <div className={classes.divEnvolveTitle}>
            <div>
              <h3 className={classes.tituloEtapa}>{NameProntuario ? NameProntuario : ''}</h3>
              <h5
                className={classes.subTituloEtapa}
              >
                Seção: {
                  observationStep ?  'Observações' 
                  : MontagemProntuario[0] ? MontagemProntuario[PosProntuario].titulosessao 
                  : ''
                }
              </h5>
            </div>
            <div>
              <Button onClick={()=>{print()}} color="primary">
                {load === true ?  <div className="div-load-lista-modelos"> <CircularLoad/> </div> : <> <GetAppIcon /> Baixar PDF </> }
                
              </Button>
            </div>
          </div>
          {observationStep ? (
            <>
              {isLoading ? (
                <div className="div-load-lista-modelos" style={{ height: '65vh' }}> <CircularLoad/> </div>
              ) : (
                <>
                  <div>
                    {observations ? observations.map((item) => (
                      <div key={item.id} className={classes.observationCard}>
                        <p className={classes.observationData}>{alterarData(item.created_at)}</p>
                        <p className={classes.observationText}>
                          {item.observation}
                        </p>
                        {item.files.length !== 0 && <p className={classes.observationAnnex}>Anexos:</p>}
                        <span
                          className={classes.observationFile}
                        >
                          {item.files.map((file) => (
                            <a href={file.url} target="_blank" rel="noreferrer">                          
                              {file.file_name && 
                                <img style={{ width: '16px', marginRight: '3px' }} src={annexIcon} alt="annex icon" />
                              }
                              {file.file_name}
                            </a>    
                          ))}
                        </span>
                      </div>
                    )) : '' }
                  </div>

                  <div className={classes.divEnvolveForm}>
                    <p style={{ fontSize: '1.3rem'}}>Observação:</p>

                    <TextField
                      id="outlined-multiline-static"
                      label="Digite aqui"
                      multiline
                      rows={5}
                      required
                      variant="outlined"
                      value={observationTextFieldValue}
                      onChange={(e) => setObservationTextFieldValue(e.target.value)}
                      error={validaMensagem ? true:false}
                      helperText={validaMensagem ? 'Campo obrigatório' : ''}
                      autoFocus={false}
                    />

                    <p style={{ fontSize: '1.3rem'}}>Anexar documentos</p>

                    <div className={classes.annexDiv}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p id="file-name">Selecionar arquivo</p>

                        <input type="file" name='files[]' id="file-input" multiple onChange={handleAnnex} />
                        <label for="file-input">ANEXAR</label>
                      </div>

                      <Button 
                        variant="contained" 
                        className={clsx('color-primary', classes.btnVizualizar)}  
                        color="primary" 
                        onClick={handleObservation}
                      >
                        SALVAR
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <form onSubmit={(event) => { event.preventDefault() }} className={classes.root} autoComplete="off">
              <div className={classes.localInputs}>
                {
                /* <InputImagem/>
                  <InputEscala/>
                  <InputTabela/> */
                }
                <div>
                  { //CARREGA OS COMPONENTES COM SUAS RESPECTIVAS PROPIEDADES PARA EXIBICAO DE RESPOSTA OU PARA RESNPONDER  ANSEWR  RESPONSAVEL PELO EDIT
                    MontagemProntuario ? MontagemProntuario[PosProntuario].montagem.map((item, index) => {
                      if (item.type === 'textocurto') {
                        return (
                          <div className={classes.root}>
                            {/* importante para nao bugar os imputs de cadastro de respota item.answer === undefined*/}
                            <InputTextoCurto title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'textolongo') {
                        return (
                          <div className={classes.root}>

                            <InputTextoLongo title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'telefone') {
                        return (
                          <div className={classes.root}>
                            <InputTelefone title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'data') {
                        return (
                          <div className={classes.root}>
                            <InputData title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'selecao') {
                        return (
                          <div className={classes.root}>
                            <InputSelect title={item.title} id={item.tempId} data={item} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'email') {
                        return (
                          <div className={classes.root}>
                            <InputEmail title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'numerico') {
                        return (
                          <div className={classes.root}>
                            <InputNumerico title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'check') {
                        return (
                            <div className={classes.root}>
                              <InputCheckbox title={item.title} id={item.tempId} data={item} required={item.required === "0" ? false : true} />
                            </div>
                        )
                      }
                      if (item.type === 'escala') {
                        return (
                          <div className={classes.root}>
                            <InputEscala title={item.title} id={item.tempId} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'image') {
                    
                        return (
                          <div className={classes.root}>
                            <InputImagem title={item.title} id={item.tempId} required={item.required === "0" ? false : true} answer={item.answer} imageID={`imgProntuario-${index}`}/>
                          </div>
                        )
                      }
                      if (item.type === 'tabela') {
                        return (
                          <div className={classes.root}>
                            <InputTabela title={item.title} id={item.tempId} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }
                      if (item.type === 'escaladedor' || item.type === 'cranianovistainferior' || item.type === 'musculoesqueletico' || item.type === 'visceral' || item.type === 'cranianovistaanterior' || item.type === 'cranianovistaposterior' || item.type === 'cranianovistalateraldireita') {
                    
                        return (

                          // ***********************************************USAR EDIT FAZER ONCHAGE EXTERNO**********************************************************
                          <div className={classes.root}>
                            <InputEscalaDor title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                          </div>
                        )
                      }
                      if (item.type === 'label') {
                        return (
                          <div className={classes.root}>
                            <InputLabel title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} />
                          </div>
                        )
                      }

                      //NOVOS COMPONENTES ESPECIAIS
                      //           'odontologico'
                      //   'osteopatiapediatrica'
                      // 'desenvolvimentomotor'
                      // 'curvadecrescimento'

                      if (item.type === 'odontologico') {
                        return (
                          <div className={classes.root}>
                            <InputOdontologico title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                          </div>
                        )
                      }

                      if (item.type === 'desenvolvimentomotor') {
                        return (
                          <div className={classes.root}>
                            <InputDesenvolvimentoMotor title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                          </div>
                        )
                      }

                      if (item.type === 'osteopatiapediatrica') {
                  
                        return (
                          <div className={classes.root}>
                            <InputOsteopatia title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                          </div>
                        )
                      
                      }
                      
                      if (item.type === 'curvadecrescimento') {
                  
                        return (
                          <div className={classes.root}>
                            <InputCurvaDeCrescimento title={item.title} id={item.tempId} edit={item.answer === undefined ? '' : item.answer} required={item.required === "0" ? false : true} options={item.options} />
                          </div>
                        )
                      }

                    })


                      : ''



                  }

                </div>
              </div>
              <div className={classes.divButtonSalvar}>
                {PosProntuario >= 1 &&
                  <Button 
                    onClick={() => {
                      if (PosProntuario >= 1) {
                        setPosProntuario(PosProntuario - 1)
                      }
                    }} 
                    type="submit"
                    variant="contained"
                  >
                    <ArrowBackIcon /> Anterior
                  </Button>
                }

                {MontagemProntuario.length > 1 && MontagemProntuario.length != PosProntuario + 1 ?
                  <Button onClick={() => {
                    if (PosProntuario < MontagemProntuario.length - 1) {

                      setPosProntuario(PosProntuario + 1)
                      
                      setAttEncerraError(!attEncerraError)
                    }

                  }} type="submit" variant="contained" color="primary">
                    Próximo <ArrowForwardIcon />
                  </Button>

                  : ''}

              </div>
            </form>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}