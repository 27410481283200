import React, { useState, useRef, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Cronometro from './Cronometro'
import { StateGlobal } from '../../../ContextAPI/context'
import SaveIcon from '@material-ui/icons/Save';
import StopIcon from '@material-ui/icons/Stop';
import SecaoLateral from './secoes'
import conexao from '../../../Services/api'
import Modal from '../Components/Modal'


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  envolveProntuario: {
    backgroundColor: '#f9f9f9',
    padding: '1rem',
    
    borderRadius: '6px',
  },
  titleProntuario: {
    color: '#777777',
    fontSize: '1.5em'
  },
  btnInicarAtendimento: {
    width: '100%',
    marginBottom: '.6rem'
  },
  btnInicarAtendimentoSec: {
    width: '100%',
    marginBottom: '.6rem'
  },
  btnDuracao: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divSecao: {
    backgroundColor: '#ffffff',
    margin: 0,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  titleSecao: {
    color: '#27b8d2',
    fontSize: '1.1em',
    padding: '.7rem'
  },
  btnfinalizar: {
    width: '100%',
    marginBottom: '1rem'
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

export default function ProntuarioLateralEsquerda(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false)

  const { 
    pos, 
    setPos, 
    MontagemProntuario, 
    PosProntuario, 
    setPosProntuario, 
    Answer, 
    respEdit, 
    setRespEdit, 
    setAnswer, 
    respView, 
    setRespView, 
    validaProntuario, 
    setvalidaProntuario, 
    NameProntuario, 
    setNameProntuario, 
    validation, 
    attValidation, 
    setAttValidation, 
    attEncerraError, 
    setAttEncerraError,
    setObservationStep,
    setLocation
  } = StateGlobal();

  const [openModal, setOpenModal] = useState(false)
  const [ocultarDuracao, setHasHideDuration] = useState(false)
  const [NewAnswerTemp, setNewAnswerTemp] = useState()
  const [oldAnswer, setOldAnswer] = useState([])
  const [load, setLoad] = useState(false);
  const [modalInfos, setModalInfos] = useState({
    title: '',
    description: ''
  })
  const [modalType, setModalType] = useState()
  const [finishType, setFinishType] = useState(false)
  
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [saveTimer, setSaveTimer] = useState();
  const [ loginType ] = useState(localStorage.getItem('loginType'))
  const [formID, setFormID] = useState('');
  const formIDRef = useRef(formID);
  
  useEffect(() => {
    if (pos === 2) {
      setOpen(!open)

      if (!respView) {

        var interval = setInterval(() => {
          console.log('SALVAMENTO_AUTOMATICO')
          console.log(formIDRef)
          save(true, false)
        }, 10000);

        setSaveTimer(interval)
      }
      else {
        clearInterval(saveTimer)
      }
    } else {
      console.log('Teste PLE')
      console.log(props.formID)
      setRespEdit(false)
      setRespView(false)
      clearInterval(saveTimer)
    }
    setObservationStep(false)
  }, [pos])

  useEffect(() => {
    return () => {
      setRespEdit(false)
      setRespView(false)

      setPos(0)
      setPosProntuario(0)
      setOpenModal(false)
      setObservationStep(false)
    }
  }, [])

  useEffect(() => {
    console.log('Teste PLE 2')
    console.log(props.formID)
    if (props.formID) {
      setFormID(props.formID)
      formIDRef.current = props.formID;
    }
  }, [props])

  function alert(message, type)
  {
    setMessage(message)
    setTypeAlert(type)
  }

  function closemodal() {
    setOpenModal(false)
  }

  function back() {
    if (!respEdit) {
      var count = 0

      Answer.map((item) => {
        if (item.answer == '' || item.answer == null || item.answer.length < 1 ) count = count + 1
      })
      
      // if (count > 0) conexao.delete('/form/patient/' + localStorage.getItem('answeredFormID'))
      if (count > 0) conexao.delete('/form/patient/' + formID)
    } 

    setRespEdit(false)
    setRespView(false)

    setPos(0)
    setPosProntuario(0)
    setOpenModal(false)
    setObservationStep(false)

    console.log('!!! RELOAD !!!!')
    window.location.reload()
  }

  function save(auto = false, finish = false) { //EDICAO DE RESPOSTAS
    var NewAnswer = []
    Answer.map((item) => {
      // console.log(item.question_type)
      switch(item.question_type) {
        
        case 'check':
          if(item.answer) {
            NewAnswer.push(item)
          }
        break;
  
        case 'selecao':
          if(item.answer) {
            NewAnswer.push(item)
          }
        break;
  
        case 'osteopatiapediatrica':
          item.answer.map((a) => {
            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })
            } else {
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
            }
          })
        break;
  
        case 'curvadecrescimento':
          item.answer.map((a) => {
            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })
            } else {
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
            }
          })
        break;
  
        case 'desenvolvimentomotor':
          item.answer.map((a) => {
            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })
            } else {
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.value })
            }
          })
        break;
  
        case 'odontologico':
          item.answer.map((a) => {
            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })
            } else {
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.answer })
            }
          })
        break;
  
        case 'musculoesqueletico':
        case 'visceral':
        case 'cranianovistaanterior':
        case'cranianovistaposterior':
        case'cranianovistalateraldireita':
          item.answer.map((a) => {
            if (a.form_step_question_option_id) {//importante colocado para quando o cliente nao alterar nada e mesmo assim editar o prontuario
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.form_step_question_option_id, answer: a.text })
            } else {
              NewAnswer.push({ question_id: item.question_id, question_type: item.question_type, form_step_question_option_id: a.id, answer: a.escala })
            }
          })
        break;

        case 'textocurto':
        case 'textolongo':
        case 'data':
        case 'numerico':
        case 'telefone':
        case 'email':
        case 'label':
        case 'image':
          if(item.answer) {
            NewAnswer.push(item)
          }
        break;
      }
    })

    let error = false 
    let erro = 0
    let erroCheck = true
    let arrErro = []

    validation.forEach((item, index) => {
      item.components.forEach((comp, i) => {
        Answer.forEach((ans) => {
          if(ans.question_id === comp.id) {  
            switch(comp.type) {
              case 'check':
                comp.options.forEach((opt) => {
                  if(ans.id_check === opt.value) {
                    if(ans.answer) {
                      erroCheck = false
                    }
                  }
                })

                if(erroCheck) {
                  validation[index].components[i].error = true
                  setAttValidation(!attValidation)
                  setAttEncerraError(!attEncerraError)
                } else {
                  validation[index].components[i].error = false
                  setAttValidation(!attValidation)
                  setAttEncerraError(!attEncerraError)
                }
              break;

              default:
                if(!ans.answer) {
                  validation[index].components[i].error = true
                  setAttValidation(!attValidation)
                  setAttEncerraError(!attEncerraError)
                } else if(!ans.answer[0]){
                  validation[index].components[i].error = true
                  setAttValidation(!attValidation)
                  setAttEncerraError(!attEncerraError)
                }
            }  
          }
        })
      })

      erro = item.components.findIndex(val => val.error == true)
      arrErro.push(item.components.findIndex(val => val.error == true))
    })
    
    arrErro.forEach(err => {
      if(err >= 0) {
        error = true
      } else (
        error = false
      )
    });
    
    if(!error) {
      if (!auto)
        setLoad(true);

      // console.log('SAVE')
      // console.log(formID);
      conexao.put('/form/patient/answer/update/' + formIDRef.current, { answers: NewAnswer }).then((res) => {
        if (!auto) {
          // setLoad(false);
          setTypeAlert('success');

          if (finish)
            setMessage('Seu atendimento foi finalizado com sucesso');
          else
            setMessage('Seu atendimento foi salvo com sucesso');

            setLoad(false);
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
            setMessage('');
            setOpenModal(false)
          
            if(localStorage.getItem('iniciarAtendimento') != 'null'){

              conexao.put(`schedule/status/${localStorage.getItem('iniciarAtendimento')}`,{"status": "REALIZADO"})
              .then(() => {
                localStorage.setItem('iniciarAtendimento', 'null')
                if(finish)
                  window.location.href = '/agenda'
              })
              
            } else{
              if (finish)
                window.location.href = '/agenda'
            }
           
          // PosProntuario(0)
        }
      }).catch((error) => {
        setTypeAlert('error');

        if (finish)
          setMessage('Erro ao finalizar o atendimento');
        else
          setMessage('Erro ao salvar o atendimento');
        
        setTimeout(() => {
          setLoad(false);
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
          setMessage('');
          setOpenModal(false)
        }, 3000);

      })
    }
  }

  function hasHideDuration() {
    setHasHideDuration(!ocultarDuracao)
  }

  return (
    <div className="">
      <ThemeProvider theme={theme}>
        <div className={classes.envolveProntuario}>
          <div>
            <h1 className={classes.titleProntuario}>Prontuários</h1>
            {(pos === 0 && localStorage.getItem('iniciarAtendimento') != 'null') ?
              <Button 
                className={clsx(classes.btnInicarAtendimento, "color-primary")} 
                disabled={pos === 1 ? true : false} 
                variant="contained" 
                color={'primary'} 
                onClick={() => { setPos(1); console.log('TESTE') }}
              >
                <PlayArrowIcon />Iniciar atendimento
              </Button>
            : ''}
            {(pos === 2 && !respView) ?
              <>
                <Button 
                  className={clsx(classes.btnInicarAtendimento, "color-primary")} 
                  disabled={pos === 1 ? true : false} 
                  variant="contained" 
                  color={'primary'} 
                  onClick={() => {
                    setOpenModal(true)
                    setModalType('finish')
                    setModalInfos({
                      title: 'Clinic Plus - Atendimentos',
                      description: 'Tem certeza que deseja finalizar esse atendimento?'
                    })
                  }}
                >
                  Finalizar Atendimento
                </Button>

                <Button 
                  className={clsx(classes.btnInicarAtendimento, "color-primary")} 
                  disabled={pos === 1 ? true : false} 
                  variant="contained" 
                  color={'primary'} 
                  // onClick={() => { save(false) }}
                  onClick={() => {
                    if(!respView) {
                      setOpenModal(true)
                      setModalType('save')
                      setModalInfos({
                        title: 'Clinic Plus - Atendimentos',
                        description: 'Tem certeza que deseja salvar esse atendimento?'
                      })
                    } else {
                      setRespEdit(false)
                      setPos(0)
                    }
                  }}
                >
                  Salvar
                </Button>
              </>
            : ''}

            {pos === 2 &&
              <Button 
              className={clsx(classes.btnInicarAtendimentoSec, "color-secondary")} 
              variant="contained" 
              color="secondary" 
              onClick={() => {
                if(!respView) {
                  setOpenModal(true)
                  setModalType('back')
                  setModalInfos({
                    title: 'Clinic Plus - Atendimentos',
                    description: 'Tem certeza que deseja sair desse atendimento?'
                  })
                } else {
                  setRespEdit(false)
                  setPos(0)
                }
              }}
              >
                Voltar
              </Button>
            }
          </div>
          <div className={classes.btnDuracao}>

            {pos === 2 && respEdit === false ?
              <>
                <Button color="default" onClick={hasHideDuration}>
                  <QueryBuilderIcon /> {!ocultarDuracao ? 'Ocultar duração' : 'Exibir duração'}
                </Button>
                {!ocultarDuracao ?
                  <Cronometro op={open} />
                  : ''}
              </>

              : ''}
          </div>
          <div>
            {pos === 1 && 
              <div 
                className={classes.divSecao} 
                onClick={() => { setPos(0); setPosProntuario(0) }}
              >
                <h2 className={classes.titleSecao}>Resumo</h2>
              </div>
            }

            {pos === 2 &&
              MontagemProntuario.map((item, index) => {
                return (
                  <div 
                    onClick={() => {
                      setPosProntuario(index)
                      setObservationStep(false)
                    }}
                  >
                    <SecaoLateral data={item} error={validation}/>
                  </div>
                )
              })
            }

            {respEdit && 
              <div 
                style={{ boxShadow: '0px 6px 10px 0px #0000000F' }}
                className={classes.divSecao}
              >
                <h2
                  className={classes.titleSecao} 
                  onClick={() => {
                    save(true)
                    setObservationStep(true)
                  }}
                >
                  Observações
                </h2>
              </div>
            }
          </div>
        </div>
      </ThemeProvider>

      <Modal 
        desc={modalInfos.description} 
        titulo={modalInfos.title} 
        typeAlert={typeAlert} 
        message={message} 
        modalType={modalType}
        load={load} 
        save={save} 
        back={back} 
        open={openModal} 
        close={closemodal} 
      />
    </div>
  );
}