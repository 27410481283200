import React, { useState, useContext, useEffect } from 'react'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import { StateGlobal } from '../../../../ContextAPI/context';
import StepsLeftMenu from './components/StepsLeftMenu';
import Cronometro from '../../../Atendimento/ProntuariosLateralEsquerda/Cronometro'
import Modal from '../../../Atendimento/Components/Modal'
import { useStyles } from './style'

import api from '../../../../Services/api'

import Button from '@material-ui/core/Button';
import StopIcon from '@material-ui/icons/Stop';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

export default function ChartLeftMenu(props) {
  const classes = useStyles()

  const { setObservationStep } = StateGlobal();
  const { 
    setChartComponentsIndex,
    sportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView 
  } = useContext(SportsPhysiotherapyContext)

  const [modalInfos, setModalInfos] = useState({
    title: '',
    description: ''
  })
  const [hasHideDuration, setHasHideDuration] = useState(false)
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [modalType, setModalType] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [load, setLoad] = useState(false);
  const [data, setData] = useState();
  const [formID, setFormID] = useState();
  const [arrChartComponents, setArrChartComponents] = useState();

  useEffect(() => {
      if (!sportsPhysiotherapyView) {
        setInterval(() => {
          console.log('SALVAMENTO_AUTOMATICO ChartLeftMenu')
          console.log(data)
          save(false, false)
        }, 10000);
      }
  }, [])

  useEffect(() => {
      if (!sportsPhysiotherapyView) {
        setData(sportsPhysiotherapyChartAnswers)
      }
  }, [sportsPhysiotherapyChartAnswers])

  useEffect(() => {
    console.log('CHART_LEFT_MENU')
    console.log(props)
    setFormID(props.formID)
    setArrChartComponents(props.arrChartComponents)
  }, [props])

  function handleHideDuration() {
    setHasHideDuration(state => !state)
  }

  async function save(auto = false, finish = false) {
    console.log(data)
    try {
      const payload = {
        patient_id: localStorage.getItem('patientID'),
        data: data,
        title: 'Fisioterapia Esportiva'
      }

      // await api.post('/form/createinfo', payload)
      // await api.put('/form/patient/updateinfo/'+localStorage.getItem('answeredFormId'), payload).then((res) => {
      await api.put('/form/patient/updateinfo/'+formID, payload).then((res) => {
        if (!auto) {
          // setLoad(false);
          setTypeAlert('success');

          if (finish)
            setMessage('Seu atendimento foi finalizado com sucesso');
          else
            setMessage('Seu atendimento foi salvo com sucesso');

          setTimeout(() => {
            setLoad(false);
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
            setMessage('');
            setOpenModal(false)
            if (finish)
              window.location.href = '/atendimento'
          }, 3000);
        }
      }).catch((error) => {
        setLoad(false);
        setTypeAlert('erro');
        setMessage('Erro ao salvar informações!');

        // setTimeout(() => {
        //   //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
        //   setMessage('');
        //   closemodal();
        //   window.location.reload()
        // }, 3000);
      })
    } catch(err) {
      console.log(err)
    }
  }
  // async function encerra() {
  //   try {
  //     const payload = {
  //       patient_id: localStorage.getItem('patientID'),
  //       data: sportsPhysiotherapyChartAnswers,
  //       title: 'Fisioterapia Esportiva'
  //     }

  //     await api.post('/form/createinfo', payload)

  //     setOpenModal(false)
  //     window.location.href = '/atendimento'
  //   } catch(err) {
  //     console.log(err)
  //   }
  // }

  function back() {
    setOpenModal(false)
    window.location.href = '/atendimento'

    // setObservationStep(false) => integrar observações

    //verificar se tem alguma pergunta não respondida, se tiver exclui o prontuario e manda para '/atendimentos'
  }

  return (
    <>
      <div className={classes.chart}>
        <div className={classes.chart__header}>
          <h1>Prontuário</h1>
          {!sportsPhysiotherapyView && 
            <Button 
              className='color-primary' 
              variant="contained"
              onClick={() => {
                console.log(sportsPhysiotherapyChartAnswers.informations)
                setOpenModal(true)
                setModalType('finish')
                setModalInfos({
                  title: 'Clinic Plus - Atendimentos',
                  description: 'Deseja salvar os dados do atendimento?'
                })
              }} 
            >
              <StopIcon />
              Finalizar Atendimento
            </Button>
          }

          <Button 
            color="secondary" 
            variant="contained"
            onClick={() => {
              setOpenModal(true)
              setModalType('back')
              setModalInfos({
                title: 'Clinic Plus - Atendimentos',
                description: 'Tem certeza que deseja sair desse atendimento?'
              })
            }} 
          >
            Voltar
          </Button>
        </div>

        {!sportsPhysiotherapyView &&
          <div className={classes.chart__durationTimer}>
            <Button
              color="default"
              onClick={handleHideDuration}
            >
              <QueryBuilderIcon />
              {!hasHideDuration ? 'Ocultar duração' : 'Exibir duração'}
            </Button>

            {!hasHideDuration && <Cronometro op={true} />}
          </div>
        }

        { arrChartComponents ? arrChartComponents.map((item, index) => (
          <StepsLeftMenu
            key={item.id}
            stepName={item.name}
            handleStep={() => {
              setChartComponentsIndex(index)
              setObservationStep(false)
            }}
          />
        )) : ''}

        {sportsPhysiotherapyView && 
          <div 
            style={{ boxShadow: '0px 6px 10px 0px #0000000F' }}
            className={classes.divSecao}
          >
            <h2
              className={classes.titleSecao} 
              onClick={() => setObservationStep(true)}
            >
              Observações
            </h2>
          </div>
        }
      </div>
      
      <Modal 
        desc={modalInfos.description} 
        titulo={modalInfos.title} 
        typeAlert={typeAlert} 
        message={message} 
        load={load} 
        modalType={modalType}
        back={back}
        save={save} 
        open={openModal} 
        close={() => setOpenModal(false)} 
      />
    </>
  )
}