import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import '../../../css/NovoModelo.css';
import { StateGlobal } from '../../../../../../ContextAPI/context';
import CategoryIcon from '@material-ui/icons/Category';
import CardDesenvolvimentoMotor from '../../../SecaoInput/Components/CardDesenvolvimentoMotor'

export default function ButtonsFerramentas({ index, id }) {

    const {
        montagemstate,
        setMontagem,
        att,
        setAtt,
        idComponentAtual, 
        viewProntuario
    } = StateGlobal();




    var array = []


    function getdata(e) {
     
    }


//           'odontologico'
//   'osteopatiapediatrica'
// 'desenvolvimentomotor'
// 'curvadecrescimento'


    return (
        <div className="NovoModelo-Ferramentas">
            <div className="circle-icon">
                <CategoryIcon/>
            </div>
            <p className="titulo-ferramenta-btn" style={{display:'flex' , textAlign:'center' , justifyContent:'center'}}>Desenvolvimento Motor</p>
            {!viewProntuario ? 
            <div onClick={() => {

                array = montagemstate
     
                array.push({ type: "desenvolvimentomotor", component: <CardDesenvolvimentoMotor getData={getdata} tempId={id} />, tempId: id, title: '', required: false, idsessao: idComponentAtual })
                setMontagem(array)

                setAtt(!att)

            }} className="envolve-btn-add-ferramentas">
                <IconButton className="btn-add-ferramenta">
                    <AddIcon/>
                </IconButton>
            </div>
            :''}
        </div>
    );
}