import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Sono({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  return (
    <div>
      <Grid
        container
        spacing={1}
        className={classes.sleep}
      >
        <Grid item xs={9}>
          <p className="titulo-form">Sono:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.sleep.sleepHealth}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                sleep: {
                  ...state.sleep,
                  sleepHealth: e.target.value
                }
              }))
            }}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
          >   
            <option value="" selected>...</option>
            <option value="great">Ótimo</option>
            <option value="good">Bom</option>
            <option value="moderate">Moderado</option>
            <option value="regular">Regular</option>
            <option value="bad">Ruim</option>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <p className="titulo-form">Horas por noite:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.sleep.hoursANight}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                sleep: {
                  ...state.sleep,
                  hoursANight: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >   
            <option value="" selected>...</option>
            <option value="1">1h</option>
            <option value="2">2h</option>
            <option value="3">3h</option>
            <option value="4">4h</option>
            <option value="5">5h</option>
            <option value="6">6h</option>
            <option value="7">7h</option>
            <option value="8">8h</option>
            <option value="9">9h</option>
            <option value="10">10h</option>
            <option value="11">11h</option>
            <option value="12">12h</option>
          </TextField>
        </Grid>

      </Grid>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}