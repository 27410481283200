import React, { useEffect, useState } from 'react';
import './css/style.css';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import conexao from '../../../Services/api'

const useStyles = makeStyles((theme) => ({
    form: {
      width: '35%',
      border: 'none'
    },
    legend: {
      width: '130px'
    },
    subHeader: {
      boxShadow: '0px 8px 18px rgb(25 32 56 / 8%)',
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid #DDDDDD',
      minHeight: '80px',
      padding: '10px 20px',
      position: 'fixed',
      top: '64px',
      width: '100%',
      textAlign: 'right',
      zIndex: 99
    }
}));

const theme = createTheme({
    palette: {
      secondary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
      primary: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#ffffff',
        contrastText: '#8F8F8F',
      }
    },
  }, ptBR);

export default function SubHeader() {
    
    const classes = useStyles();
    const [doctorsList, setDoctorsList] = useState([])
    const [selectedDoctor, setSelectedDoctor] = useState([])

    useEffect(() => {
  
      // Mesma coisa para a informação do plano...
      if (localStorage.getItem('planExpired'))
        getPlanData()

      // Se, por algum acaso eu não tenho a lista de profissionais da clínica, aqui eu tento pegar dnovo
      if (localStorage.getItem('clinicId') && (!localStorage.getItem('clinicDoctors')))
        getDoctors()
      else
        setDoctorsList(JSON.parse(localStorage.getItem('clinicDoctors')));

      // localStorage.setItem('selectedDoctor', 'null');
      // localStorage.setItem('selectedUser', 'null');

      // A linha abaixo forçava a busca dos mádicos... pra debugar
      // getDoctors()

      setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
    }, [])

    function changeDoctor(item) {
      console.log('CHANGE DOCTOR')
      console.log(item)
      // setSelectedDoctor(item.id);
      localStorage.setItem('selectedDoctor', (item) ? item.id : 'null');
      localStorage.setItem('doctorId', (item) ? item.id : 'null'); //Compatibilidade com código antigo
      localStorage.setItem('selectedUser', (item) ? item.user.id : 'null');
      localStorage.setItem('doctorName', (item) ? item.user.name : 'null');

      conexao.get(`/schedule/type/list/${localStorage.getItem('selectedUser')}`).then((res) => {
        localStorage.setItem('doctorScheduleTypes', JSON.stringify(res.data.data))
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        localStorage.setItem('doctorScheduleTypes', '')
        window.location.reload();
      })
    }
    
    async function getDoctors() {
      var url = '';
      if (localStorage.getItem('profileId') == 2) // CLINICA
        url = `/clinic/byid/${localStorage.getItem('clinicId')}`
      else if (localStorage.getItem('profileId') == 4) // ATENDENTE
        url = `/employee/byid/${localStorage.getItem('employeeId')}`
  
      await conexao.get(url)
        .then((res) => {
          var result = res.data.data;  
          if (result[0])
          {
            localStorage.setItem('clinicDoctors', JSON.stringify(result[0].doctors))
            setDoctorsList(result[0].doctors);
          }          
        })
        .catch((e) => {})
    }

    async function getPlanData()
    {
      var url = ''
        
      if (localStorage.getItem('clinicId') != null) {
        url = `/clinic/plan/get/${localStorage.getItem('clinicId')}`
      }
      else {
        url = `/doctor/plan/get/${localStorage.getItem('selectedDoctor')}`
      }
  
      const response = await conexao.get(url).then((res) => {  
        const dataAlert = response.data.data[0]
  
        let expirationDate = new Date((dataAlert.expired_at).replace(/\s/, 'T')+'Z')
        let today = new Date()
  
        let remain = expirationDate - today
        remain = remain / 1000 / 60 / 60 /24
        remain = remain.toFixed(0)
  
        localStorage.setItem('planExpired', remain <= 0)
      }).catch((error) => {
  
      })
    }

    return (
      <div className={classes.subHeader}>
        <div>
          <FormControl variant="outlined" className={classes.formControl} style={{width: 350}}>
            <InputLabel id="demo-simple-select-outlined-la bel" style={{width:'auto', backgroundColor: '#fafafa'}}>
              {localStorage.getItem('loginType') == 'EMPLOYEE' ? <span>Todos</span> : <span>Clínica</span>}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              name='selected-doctor'
              id="demo-simple-select-outlined"
              value={selectedDoctor ? selectedDoctor : ''}
              label="Doutores"
              className={classes.SelectProcedimento}
            >
              <MenuItem value="0" key="0" onClick={()=>{changeDoctor(0)}}>{localStorage.getItem('loginType') == 'EMPLOYEE' ? <em>Todos</em> : <em>Clínica</em>}</MenuItem>
              {doctorsList ? doctorsList.map((item) => {
                return (
                  <MenuItem value={item.id} key={item.id} onClick={()=>{changeDoctor(item)}}>{item.user.name}</MenuItem>
                )
              }) : ''}
            </Select>
          </FormControl>
        </div>
      </div>
    );
}