import React, { useContext, useEffect, useRef } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Spadi({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView,
  } = useContext(SportsPhysiotherapyContext);

  const painScaleArr = [0,1,2,3,4,5,6,7,8,9,10]

  function handleDisabilityScaleSelects(e) {
    const { name, value } = e.target

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      spadi: {
        ...state.spadi,
        disabilityScale: {
          ...state.spadi.disabilityScale,
          [name]: value
        }
      }
    }))
  }

  function handlePainScaleSelects(e) {
    const { name, value } = e.target

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      spadi: {
        ...state.spadi,
        painScale: {
          ...state.spadi.painScale,
          [name]: value
        }
      }
    }))
  }

  function getDisabilityScaleScore(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.spadi.disabilityScale).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })
    
    return score ? ((5*100) / score).toFixed(2) : 0;
  }

  function getPainScaleScore(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.spadi.painScale).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })
    
    return score ? ((8*100) / score).toFixed(2) : 0;
  }

  function getTotalScore(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.spadi.painScale).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    Object.values(sportsPhysiotherapyChartAnswers.spadi.disabilityScale).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })
    
    return score ? ((13*100) / score).toFixed(2) : 0;
  }

  return (
    <div>
      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          ESCALA DE INCAPACIDADE:
        </p>

        <p className={classes.text}>
          Os números ao lado de cada item representam o grau de dificuldade que você teve ao fazer aquela atividade. O Número zero (0) representa “sem dificuldade” e o número dez (10) representa “Não conseguiu fazer”. Por favor, indique o número que melhor descreve quanta dificuldade você teve para fazer cada uma das atividades durante a semana passada.
          daria para sua dificuldade.
        </p>
      </article>

      <div>
        <p 
          className="titulo-form"
          style={{ marginTop: '20px' }}
        >
          1. Qual intensidade da sua dor quando foi a pior na semana passada?
        </p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          select
          rows={3}
          type="text"
          name="intensityOfYourPain1"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.spadi.disabilityScale.intensityOfYourPain1}
          onChange={e => handleDisabilityScaleSelects(e)}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>

          {painScaleArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </TextField>
      </div>

      <div>
        <p 
          className="titulo-form"
          style={{ marginTop: '20px' }}
        >
          2. Durante a semana passada, qual a gravidade da sua dor?
        </p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          select
          rows={3}
          type="text"
          disabled={sportsPhysiotherapyView}
          name="severityOfYourPain"
          value={sportsPhysiotherapyChartAnswers.spadi.disabilityScale.severityOfYourPain}
          onChange={e => handleDisabilityScaleSelects(e)}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>

          {painScaleArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </TextField>
      </div>

      <div>
        <p 
          className="titulo-form"
          style={{ marginTop: '20px' }}
        >
          3. Quando você se deitou em cima do braço afetado? 
        </p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          select
          rows={3}
          type="text"
          name="layOnTheArm1"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.spadi.disabilityScale.layOnTheArm1}
          onChange={e => handleDisabilityScaleSelects(e)}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>

          {painScaleArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </TextField>
      </div>

      <div>
        <p 
          className="titulo-form"
          style={{ marginTop: '20px' }}
        >
          4. quando tentou pegar algo em uma prateleira alta com o braço afetado? 
        </p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          select
          rows={3}
          type="text"
          name="putSomethingHighDhelf1"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.spadi.disabilityScale.putSomethingHighDhelf1}
          onChange={e => handleDisabilityScaleSelects(e)}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>

          {painScaleArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </TextField>
      </div>

      <div>
        <p 
          className="titulo-form"
          style={{ marginTop: '20px' }}
        >
          5. Quando tentou empurrar algo com o bracço afetado?  
        </p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          select
          rows={3}
          type="text"
          name="triedToPushSomething1"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.spadi.disabilityScale.triedToPushSomething1}
          onChange={e => handleDisabilityScaleSelects(e)}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>

          {painScaleArr.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </TextField>

        <p className={classes.score}>Escore INCAPACIDADE: {getDisabilityScaleScore()}</p>
      </div>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 5px 0' }}
        >
          ESCALA DE DOR:
        </p>

        <p className={classes.text}>
         Os números ao lado de cada item representam quanta dor você sente em cada situação. O número zero (0) representa “sem dor” e o número dez (10) representa “Pior dor”. Por favor, indique o número que melhor descreve quanta dor você sentiu a durante a semana passada em cada uma das seguintes situações. Se você não teve oportunidade de fazer uma das atividades na semana passada, por favor, tente estimar qual número você daria para sua dor.
        </p>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '20px' }}
          >
            1. Qual intensidade da sua dor quando foi a pior na semana passada?
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="intensityOfYourPain2"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.intensityOfYourPain2}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '10px' }}
          >
            2. Quando se deitou em cima do braço afetado? 
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="layOnTheArm2"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.layOnTheArm2}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '10px' }}
          >
            3. Quando tentou pegar algo em uma prateleira alta com o braço afetado? 
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="triedToCatchSomething"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.triedToCatchSomething}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '10px' }}
          >
            4. Quando tentou tocar a parte de trás do pescoço com o braço afetado? 
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="touchTheBackOfTheNeck"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.touchTheBackOfTheNeck}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '10px' }}
          >
            5. Quanto tentou empurrar algo com o braço afetado?  
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="triedToPushSomething2"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.triedToPushSomething2}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '10px' }}
          >
            6. Coloar algo em uma prateleira alta com o braço afetado?  
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="putSomethingHighDhelf2"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.putSomethingHighDhelf2}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '10px' }}
          >
            7. Carregar um objeto pesado de 5kg (saco grande de arroz) com o braço afetado?  
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="carryRice"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.carryRice}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div>
          <p 
            className="titulo-form"
            style={{ marginTop: '10px' }}
          >
            8. Retirar algo do seu bolso de trás com o braço afetado?  
          </p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            select
            rows={3}
            type="text"
            name="takeOutOfPocket"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.spadi.painScale.takeOutOfPocket}
            onChange={e => handlePainScaleSelects(e)}
            SelectProps={{
              native: true,
            }}
          >
            <option value="" selected>...</option>

            {painScaleArr.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </TextField>
        </div>

        <div className={classes.divScore}>
          <p className={classes.score}>Escore DOR: {getPainScaleScore()}</p>
          <p className={classes.score}>Escore TOTAL: {getTotalScore()}</p> 
        </div>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}