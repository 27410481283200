import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import Atendentes from './Atendentes'
import ModalAtendente from './Modal/ModalAtendente'
import conexao from '../../Services/api'

import { StateGlobal } from '../../ContextAPI/context'

import './Listagem.css';
import '../../App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },

}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabPesquisa(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function ListagemModelosSalvos() {

  const classes = useStyles();
  const [dataarr, setDataarr] = useState();
  const [atendentes, setAtendentes] = useState([]);
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [updateList, setUpdateList] = React.useState(false);
  const {
    idEditProntuario,
    setIdEditProntuario,
    setTituloSessaoAtual,
    setMontagem,
    setSessaostate,
  } = StateGlobal();
  
  const novoModelo = () => {
    setIdEditProntuario(false)
    setMontagem([])
    setSessaostate([])
    setTituloSessaoAtual('Título da seção prontuário')

    history.push('/novo-modelo');
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function close() {
    setUpdateList(true)
    setTimeout(() => {
      setUpdateList(false)
    }, 1000);

    setOpen(false)
  }

  return (
    <div className={localStorage.getItem('containerClass')}>
      <div className="card">
        <div className="card_header">
          <h1>Atendentes</h1>
          <Button variant="contained" color="primary" className="Color-btn-addModelo color-primary" onClick={()=> setOpen(true)}>
            <AddIcon /> Novo Atendente                      
          </Button>
        </div>
        <div className="padding-lateral app-bar-modelos-pront">
          <AppBar position="static">                
          </AppBar>

          <Atendentes update={updateList}/>

        </div>
        <ModalAtendente className="Color-btn-addModelo" open={open} close={close}   />  
      </div>         
    </div>
  );
}