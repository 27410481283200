import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cait__formControl: {
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiFormLabel-root': {
      marginRight: '50px',
      fontWeight: '500',
      color: '#000000DE',

      '& .Mui-focused': {
        color: '#000000DE',
      }
    },

    '& .MuiFormGroup-root': {
      flexDirection: 'row',

      '& .MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgb(0 239 245 / 4%);'
      },

      '& .Mui-checked': {
        color: '#27B8D2',

        '&:hover': {
          backgroundColor: 'rgb(0 239 245 / 4%);'
        }
      },
    }
  },
}));