import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CircularLoad from '../Load';
import DialogContentText from '@material-ui/core/DialogContentText';

const theme = createTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

export default function ModalExcluir(props) { 
    const [load, setLoad] = useState(props.load);
    const [openModalSair, setOpenModalSair] = React.useState(props.open);

    useEffect(() => {
        setLoad(props.load);
        setOpenModalSair(props.open);
    }, [props])


    function handleSair(value){
        props.handleModal(value)
    }

    return(
            <div>
                <ThemeProvider theme={theme}>
                <Dialog
                    open={openModalSair}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Sair</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deseja deslogar da plataforma?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleSair(false)} color="default">
                        Não
                    </Button>
                    <Button onClick={()=>handleSair(true)} className="color-primary" variant="contained" color="primary" autoFocus>
                        {!load ? 'Sim' : <CircularLoad/>}
                    </Button>
                    </DialogActions>
                </Dialog>
                </ThemeProvider>
            </div>
     );
}