import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import '../css/Planos.css';
import FormControl from '@material-ui/core/FormControl';
import 'react-credit-cards-2/es/styles-compiled.css';
import Cards from 'react-credit-cards-2';
import TextField from '@material-ui/core/TextField';
import { mask,unMask } from 'remask'
import PropTypes from 'prop-types';
import api from '../../../Services/api'
import NumberFormat from 'react-number-format';
import axios from 'axios';
import CircularLoad from '../../../Components/Load';
import Alert from '@material-ui/lab/Alert';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { validaCpfCnpj } from '../../Agenda/Modais/Validate';
import conexao from '../../../Services/api'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  },
  formInput:{
    width: '100%',
    marginBottom: '1rem',
    backgroundColor: 'white'
  },
  formInputM:{
    width: '49%',
    marginRight: '.5rem',
    marginBottom: '1rem',
    backgroundColor: 'white'
  },
  formInputMm:{
    width: '49%',
    marginBottom: '1rem',
    backgroundColor: 'white'
  },
  formRow:{
    width: '95%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formInputD: {
    width: '95%',
    padding: 0
},
divEndereco:{
  marginBottom: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '95%',
},
formInputN: {
  width: '28% !important',
  backgroundColor: 'white'
},
formInputR: {
width: '69% !important',
backgroundColor: 'white'
},
}));

const theme = createTheme({
    palette: {
      secondary: {
        light: '#E7366A',
        main: '#E7366A',
        dark: '#D93364',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  });

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      isNumericString
        format='#####-###'
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function FormataCelular(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      isNumericString
      format='(##) # ####-####'
    />
  );
}

FormataCelular.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
  

export default function ContratarPlanos() {
  const classes = useStyles();
  const [periodo,setPeriodo] = useState('mensal');
  const [cvc, setCvc] = React.useState('');
  const History = useHistory();
  const [expiry, setExpiry] = React.useState('');
  const [focus, setFocus] = React.useState('');
  const [name, setName] = React.useState('NOME DO TÍTULAR');
  const [number, setNumber] = React.useState('');
  const [document, setDocument] = React.useState('');
  const [CEP, setCEP] = React.useState('');
  const [endereco, setEndereco] = React.useState('');
  const [numeroCasa, setNumeroCasa] = React.useState('');
  const [bairro, setBairro] = React.useState('');
  const [cidade, setCidade] = React.useState('');
  const [estado, setEstado] = React.useState('');
  const [phone, setPhone] = useState('')
  const [load, setLoad] = useState(false)
  const [planId, setPlanId] = React.useState('');
  const [recurrence, setRecurrence] = React.useState(30);
  const [planType, setPlanType] = React.useState('');
  const [selectedPlan, setSelectedPlan] = React.useState('');
  const [cupom, setCupom] = React.useState('');
  const [verificaVazioNumber, setVerificaVazioNumber] = React.useState(false);
  const [verificaVazioDocument, setVerificaVazioDocument] = React.useState(false);
  const [verificaVazioCEP, setVerificaVazioCEP] = React.useState(false);
  const [verificaVazioEndereco, setVerificaVazioEndereco] = React.useState(false);
  const [verificaVazioCidade, setVerificaVazioCidade] = React.useState(false);
  const [verificaVazioBairro, setVerificaVazioBairro] = React.useState(false);
  const [verificaVazioNumeroCasa, setVerificaVazioNumeroCasa] = React.useState(false);
  const [verificaVazioEstado, setVerificaVazioEstado] = React.useState(false);
  const [verificaVazioName, setVerificaVazioName] = React.useState(false);
  const [verificaVazioExpiry, setVerificaVazioExpiry] = React.useState(false);
  const [cepfsm, setCepSm] = React.useState('');
  const [documentSm, setDocumentSm] = React.useState('');
  const [verificaVazioCvc, setVerificaVazioCvc] = React.useState(false);
  const [verificaVazioPhone, setVerificaVazioPhone] = React.useState(false);
  const [bandeiraCard, setBandeiraCard] = React.useState('');
  const [typeAlert, setTypeAlert] = React.useState('');
  const [validaCPF, setValidaCPF] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);

  const [planMonthValue, setPlanMonthValue] = React.useState([0, '79,90', '139,90', '269,90']);
  const [planYearValue, setPlanYearValue] = React.useState([0, '850', '1499,9', '2899,9']);
  const [planNames, setPlanNames] = React.useState(['', 'Plano Individual', 'Plano Clínica', 'Plano Clínica Plus']);
  const [loginType, setLoginType] = React.useState(0);

  function selecionaPeriodo(value){
    setPeriodo(value)
  }

  const handleInputFocus = (event) => {
    setFocus(event.target.name );
  }

  const handleInputCupom = (event) => {
    setCupom(event.target.value)
  }
  
  const handleInputName = (event) => {
    setName(event.target.value)
  }
  const handleInputNumber = (event) => {
    setNumber(event.target.value)
  }
  const handleInputValidade = (event) => {
    setExpiry(event.target.value)
  }
  const handleInputCvv = (event) => {
    setCvc(event.target.value)
  }
  const handleInputPhone = (event) => {
    setPhone(event.target.value)
  }

  const capturaCep= (prop) => (event) => {
    setCEP(event.target.value );
    let cepSm = event.target.value.replace(/\D/g, '');
    setCepSm(cepSm);
  };

  const capturaDocument= (prop) => (event) => {
    setDocument(event.target.value );
    let documentSm = event.target.value.replace(/\D/g, '');
    setDocumentSm(documentSm);
  };
  const capturaCidade= (prop) => (event) => {
    setCidade(event.target.value );
  };
  const capturaEndereco= (prop) => (event) => {
    setEndereco(event.target.value );
  };
  const capturaNumeroCasa= (prop) => (event) => {
    setNumeroCasa(event.target.value );
  };
  const capturaBairro= (prop) => (event) => {
    setBairro(event.target.value );
  };
  const capturaEstado= (prop) => (event) => {
    setEstado(event.target.value );
  };

  function voltar(){
    History.push('/planos'); 
  }

  const mascaraNumber = (prop) =>  (object) => {
    var number = object.target;
    if(number.value.length == 4)
        number.value = number.value + ' '; 
    if(number.value.length == 9)
        number.value = number.value + ' ';
    if(number.value.length == 14)
        number.value = number.value + ' ';
}
  const mascara = (prop) =>  (object) => {
    var validade = object.target; 
    if(validade.value.length == 2)
        validade.value = validade.value + '/'; 
  }

  useEffect(() => {
    var recurrence = window.location.search.split('=')[1].split('-')[0];
    var planId = window.location.search.split('=')[1].split('-')[1];

    setPlanId(planId);
    setRecurrence(recurrence);
    // setPlanType(urlType);
    setLoginType(localStorage.getItem('loginType'));

    conexao.get('plan/'+planId).then((res) => {
      console.log('PLANO')
      console.log(res.data.data)

      setSelectedPlan(res.data.data[0])
    })

  }, []);

  useEffect(() => {
    verificaBandeira();
  },[number]);

  async function enviaDadosPag(){
      let card_number = number.replace(/[^\d]+/g, '');
      let data = expiry.replace(/[^\d]+/g, '');
      let payload = {};
      let cupon = null;
      let url = '';
      
      if (cupom !== '') {
        cupon = cupom
      }

      if (loginType == 'CLINIC') {
        let clinicId = localStorage.getItem('clinicId')
        payload = {
          "clinic_id": clinicId,
          "plan_id": planId,
          "recurrence": recurrence,
          "card_cvv": cvc,
          "card_expiration_date": data,
          "card_number": card_number,
          "card_holder_name": name,
          "cupom": cupon,
          "document_number" : documentSm,
          "address" : endereco,
          "number" : numeroCasa,
          "neighborhood" : bairro,
          "zipcode" : cepfsm,
          "phone": phone
        } 
        url = '/clinic/plan/signature';
      } else {
        let doctorId = localStorage.getItem('selectedDoctor')
        payload = {
          "doctor_id": doctorId,
          "plan_id": planId,
          "recurrence": recurrence,
          "card_cvv": cvc,
          "card_expiration_date": data,
          "card_number": card_number,
          "card_holder_name": name,
          "cupom": cupon,
          "document_number" : documentSm,
          "address" : endereco,
          "number" : numeroCasa,
          "neighborhood" : bairro,
          "zipcode" : cepfsm,
          "phone": phone
        }
        url = '/doctor/plan/signature';
      }

        if(phone !== '' && number !=='' &&  name !=='' && expiry !=='' && cvc !==''
          && document !== '' && endereco !== '' && CEP !== '' && cidade !== '' && bairro !== '' && estado !== '' && numeroCasa !== '' && validaCPF) {
            await api.post(url, payload)
          .then((response) => {
            setOpenModal(true)
          })
          .catch((error) => {
            if (error.response.status==404) {
              setMessage('Cupom expirado!');
            }else{
            setMessage('Ocorreu um erro ao concluir o pagamento, por favor verifique os dados do cartão');
          }
            setTypeAlert('error')
            setTimeout(() => {
                setMessage('');
          }, 3000);
          })
        }else{
          setMessage('Dados inválidos! Por favor, verifique os campos preenchidos e tente novamente.');
          setTypeAlert('error')
          setTimeout(() => {
            setMessage('');
          }, 3000);
          if(number  ==='' ){
              setVerificaVazioNumber(true);
          } else{
            setVerificaVazioNumber(false);
          }
          if(CEP  ==='' ){
            setVerificaVazioCEP(true);
          } else{
            setVerificaVazioCEP(false);
          }
          if(cidade  ==='' ){
            setVerificaVazioCidade(true);
          } else{
            setVerificaVazioCidade(false);
          }
          if(endereco  ==='' ){
            setVerificaVazioEndereco(true);
          } else{
            setVerificaVazioEndereco(false);
          }
          if(bairro  ==='' ){
            setVerificaVazioBairro(true);
          } else{
            setVerificaVazioBairro(false);
          }
          if(numeroCasa  ==='' ){
            setVerificaVazioNumeroCasa(true);
          } else{
            setVerificaVazioNumeroCasa(false);
          }
          if(estado  ==='' ){
            setVerificaVazioEstado(true);
          } else{
            setVerificaVazioEstado(false);
          }
          if(document  ==='' ){
            setVerificaVazioDocument(true);
          } else{
            setVerificaVazioDocument(false);
          }
          if(name ==='' || name ==='NOME DO TÍTULAR'){
              setVerificaVazioName(true);
          }
          if(expiry ===''){
              setVerificaVazioExpiry(true);
          }
          if(cvc ===''){
            setVerificaVazioCvc(true);
          }
          if(phone === ''){
            setVerificaVazioPhone(true);
          }
          return true;
        }
}


function verificaBandeira() {
  var cartoes = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      master: /^5[1-5][0-9]{14}/,
      amex: /^3[47][0-9]{13}/,
      dinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/
  };

  function testarCC(nr, cartoes) {
    for (var cartao in cartoes) if (nr.match(cartoes[cartao])) return cartao;
    return false;
  }


  var valido = number.replace(/[^\d]+/g, '');
  var invalido = '1234567890';

  [valido, invalido].forEach(function(teste) {
    var cartao = (testarCC(teste,cartoes));
    if(cartao !== false){
      setBandeiraCard(cartao);
    }
  });
}


// via Cep
function limpa_formulário_cep() {
  //Limpa valores do formulário de cep.
  setEndereco('');
  setBairro('');
  setEstado('');
  setCidade('');
}

function meu_callback(conteudo) {
  setVerificaVazioCEP(false)
  //Atualiza os campos com os valores.
  setEndereco(conteudo.data.logradouro);
  setBairro(conteudo.data.bairro);
  setEstado(conteudo.data.uf);
  setCidade(conteudo.data.localidade);
} 
const pesquisacep= (prop) => (event) => {

//Nova variável "cep" somente com dígitos.
var cep = event.target.value.replace(/\D/g, '');

//Verifica se campo cep possui valor informado.
if (cep != "") {

  //Expressão regular para validar o CEP.
  var validacep = /^[0-9]{8}$/;

  //Valida o formato do CEP.
  if(validacep.test(cep)) {
      //Preenche os campos com "..." enquanto consulta webservice.
      setEndereco('...');
      setBairro('...');
      setEstado('...');
      setCidade('...');

      //chama a api do via cep
      dadosViaCep();

  } //end if.
  else {
      //cep é inválido.
      limpa_formulário_cep();
     setVerificaVazioCEP(true)
  }
} //end if.
else {
  //cep sem valor, limpa formulário.
  limpa_formulário_cep();
  setVerificaVazioCEP(true)
}
};

async function dadosViaCep(){
 await axios.get('https://viacep.com.br/ws/'+ cepfsm + '/json')
    .then((response) => {
    meu_callback(response)
    })
    .catch((error) => {
      //CEP não Encontrado.
      limpa_formulário_cep();
      setVerificaVazioCEP(true)
    })
}
  
  return (
    <div className={localStorage.getItem('containerClass')}>
      <ThemeProvider theme={theme}>
      {message !== ''?
          <Alert className="messagensFixed contrataPlano" variant="filled" severity={typeAlert}>
             {message}
          </Alert>
        :''}
        <Grid container>
        <Grid item lg={8}>
          <Paper className={classes.paper}>
            <div className="contratarPlano dados-cartao-contratar-plano">
              <div className={classes.divEnvolveForm}>
                <h1>Finalize sua compra</h1>
                <div>
                  <form noValidate autoComplete="off" className={classes.form}>
                  <FormControl className={classes.formControl} variant="outlined">
                    <h2>1. Cupom de desconto</h2>
                    <OutlinedInput
                      className={classes.formInputD}
                      color="primary"
                      id="filled-adornment-password"
                      onChange={handleInputCupom}
                      // endAdornment={
                      //   <InputAdornment className="usar-cupon-btn" position="end">
                      //      <Button variant="contained" color="primary">
                      //         Usar cupom
                      //     </Button>
                      //   </InputAdornment>
                      // }
                    />
                  </FormControl>
                    <FormControl component="fieldset" className={classes.formControl}>
                      {/* <div>
                        <h2 className="p-contratar-plano">1. Regime do plano</h2>
                        <div className="envolve-periodo-card">
                          <div className="envolve-periodo-card-plan">
                            <div onClick={()=>setPlanType(2)} className={planType == 3 ?"envolve-periodo-card-plan-desactive":"envolve-periodo-card-plan-active"}>
                              <p>Mensal</p>
                            </div>
                            <div onClick={()=>setPlanType(3)} className={planType == 3 ?"envolve-periodo-card-plan-active":"envolve-periodo-card-plan-desactive"}>
                              <p>Anual</p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <h2 className={classes.h2SubTitle}>2. Preencha seus dados pessoais</h2>
                         <TextField
                          className={classes.formInput}
                          id="cep" 
                          label="CEP" 
                          required
                          color="primary"
                          variant="outlined"
                          type="tel"
                          name="number"
                          value={CEP}
                          onChange={capturaCep()} 
                          onBlur={pesquisacep()}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            }}
                          error={verificaVazioCEP  ? true:false}
                          helperText={verificaVazioCEP  ?"*CEP inválido":''}
                         />
                        <div className={classes.divEndereco}>
                            <TextField
                              className={classes.formInputR}
                              id="Cidade" 
                              label="Cidade" 
                              required
                              color="primary"
                              value={cidade}
                              onChange={capturaCidade()} 
                              disabled={cidade?true:false}
                              variant="outlined"
                              type="text"
                              error={verificaVazioCidade  ? true:false}
                              helperText={verificaVazioCidade ?"*Campo obrigatório":''}
                            />
                            <TextField
                              className={classes.formInputN}
                              id="estado" 
                              onChange={capturaEstado()} 
                              required
                              color="primary"
                              value={estado}
                              disabled={estado?true:false}
                              label="Estado" 
                              variant="outlined"
                              type="text"
                              error={verificaVazioEstado  ? true:false}
                              helperText={verificaVazioEstado ?"*Campo obrigatório":''}
                            />
                        </div>
                        <TextField
                          className={classes.formInput}
                          id="bairro" 
                          label="Bairro" 
                          value={bairro}
                          color="primary"
                          required
                          onChange={capturaBairro()} 
                          variant="outlined"
                          type="text"
                          error={verificaVazioBairro  ? true:false}
                          helperText={verificaVazioBairro  ?"*Campo obrigatório":''}
                         />
                        <div className={classes.divEndereco}>
                            <TextField
                              className={classes.formInputR}
                              id="rua" 
                              label="Rua" 
                              value={endereco}
                              color="primary"
                              required
                              onChange={capturaEndereco()} 
                              variant="outlined"
                              type="text"
                              error={verificaVazioEndereco  ? true:false}
                              helperText={verificaVazioEndereco ?"*Campo obrigatório":''}
                            />
                            <TextField
                              className={classes.formInputN}
                              id="numero" 
                              label="Número" 
                              required
                              color="primary"
                              value={numeroCasa}
                              onChange={capturaNumeroCasa()} 
                              variant="outlined"
                              type="number"
                              error={verificaVazioNumeroCasa  ? true:false}
                              helperText={verificaVazioNumeroCasa ?"*Campo obrigatório":''}
                            />
                        </div>
                      <h2 className={classes.h2SubTitle}>3. Insira os dados de pagamento</h2>
                      <div id="PaymentForm">
                        <div>
                          <Cards
                            cvc={cvc}
                            expiry={expiry}
                            focused={focus}
                            name={name}
                            number={number}
                          />
                        </div>
                        <TextField
                          className={classes.formInput}
                          id="document" 
                          label="CPF ou CNPJ do títular" 
                          value={mask(unMask(document), ['999.999.999-99', '99.999.999/9999-99'])}
                          onChange={capturaDocument()} 
                          onKeyUp={(event) => { setValidaCPF(validaCpfCnpj(event.target.value)) }}
                          variant="outlined"
                          type="tel"
                          color="primary"
                          required
                          name="cpf"
                          error={verificaVazioDocument || !validaCPF ? true:false}
                          helperText={ verificaVazioDocument || !validaCPF ? "*Número de documento inválido" : '' }
                         />
                         <TextField
                            className={classes.formInputMm}
                            id="phone" 
                            label="Celular" 
                            variant="outlined"
                            type="tel"
                            color="primary" 
                            required
                            name="phone"
                            onChange={handleInputPhone}
                            onFocus={handleInputFocus}
                            InputProps={{
                              shrink: true,
                              inputComponent: FormataCelular,
                            }}
                            error={verificaVazioPhone && phone ==='' ? true:false}
                            helperText={verificaVazioPhone && phone ==='' ?"*Campo obrigatório":''}
                          />
                         <TextField
                          className={classes.formInput}
                          id="cardNumber" 
                          label="Número do cartão" 
                          variant="outlined"
                          type="tel"
                          name="number"
                          color="primary"
                          required
                          onChange={handleInputNumber}
                          onFocus={handleInputFocus}
                          maxLength = "19"
                          inputProps ={{maxLength:22}}
                          onKeyPress={mascaraNumber()}
                          error={verificaVazioNumber && number ==='' ? true:false}
                          helperText={verificaVazioNumber && number ==='' ?"*Campo obrigatório":''}
                         />
                        <TextField
                          className={classes.formInput}
                          id="cardName" 
                          label="Nome do títular" 
                          required
                          variant="outlined"
                          color="primary"
                          type="text"
                          name="name"
                          onChange={handleInputName}
                          onFocus={handleInputFocus}
                          error={verificaVazioName && name ==='NOME DO TÍTULAR' ? true:false}
                          helperText={verificaVazioName && name ==='NOME DO TÍTULAR' ?"*Campo obrigatório":''}
                         />
                         <div className={classes.formRow}>
                          <TextField
                            className={classes.formInputM}
                            id="dataValidade" 
                            label="Data de validade" 
                            variant="outlined"
                            type="tel"
                            name="dataValidade"
                            onChange={handleInputValidade}
                            required
                            color="primary"
                            onFocus={handleInputFocus}
                            maxLength = "5"
                            inputProps ={{maxLength:5}}
                            onKeyPress={mascara()}
                            error={verificaVazioExpiry && expiry ==='' ? true:false}
                            helperText={verificaVazioExpiry && expiry ==='' ?"*Campo obrigatório":''}
                          />
                          <TextField
                            className={classes.formInputMm}
                            id="cvc" 
                            label="CVV" 
                            variant="outlined"
                            type="tel"
                            color="primary"
                            required
                            name="cvc"
                            onChange={handleInputCvv}
                            onFocus={handleInputFocus}
                            maxLength = "4"
                            inputProps ={{maxLength:4}}
                            error={verificaVazioCvc && cvc ==='' ? true:false}
                            helperText={verificaVazioCvc && cvc ==='' ?"*Campo obrigatório":''}
                          />
                         </div>
                      </div>
                    </FormControl>
                    <div className="alert-pagamento">
                      {message !== ''?
                        <Alert className="messagensFixed contrataPlano" variant="filled" severity={typeAlert}>
                          {message}
                        </Alert>
                      :''}
                    </div>
                    <div className="div-btn-pagar">
                        <Button onClick={() => {
                            History.push('/planos')

                        }} color="default">
                            Cancelar
                        </Button>
                        <Button onClick={enviaDadosPag} variant="contained" color="primary" className="color-primary" autoFocus>
                            {!load ? 'Finalizar pagamento' : <CircularLoad/>}
                        </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
          <Grid item xs={4}>
            <Paper className={clsx(classes.paper, classes.colorPaper)}>
              <div className="envolve-todos-cards">
                <div className="widt-card-plan-escolhido">
                    <div className="planos">
                      <div className="card-header plano-escolhido-contratar">
                        <p>Plano escolhido</p>
                      </div>
                      <div class="card-body">
                        <div className="envolve-titulo-card-planos">
                            <h2>{selectedPlan ? selectedPlan.name : ''}</h2>
                            <p>
                                <span className="preco-moeda">R$</span>
                                <span className="preco-plano">{ selectedPlan ? selectedPlan.value.toFixed(2) : ''}</span>
                                <span className="preco-periodo">{ selectedPlan ? (selectedPlan.period == 30 ? '/mês' : '/ano') : '' }</span>
                            </p>
                            <Button className="btn-selecionar-plano contratar-plano-btn" variant="contained" color="secondary">
                                Selecionado
                            </Button>
                        </div>
                        <div className="div-ul-list-descricao">
                            <ul className="ul-list-descricao">
                              <li>Sistema disponível na versão web e mobile (android e IOS)</li>
                              <li>Agenda inteligente on-line</li>
                              <li>Gestão Financeira</li>
                              <li>Prontuários Especializados</li>
                              <li>Relatórios Especiais</li>
                              <li>Confirmação das consultas on-line</li>
                              <li>Palestras com especialistas exclusivo para usuários Clinic Plus</li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
                <Dialog
                    open={openModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Pagamento realizado com sucesso</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        O pagamento do plano escolhido foi realizado com sucesso!
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>History.push('/home')} variant="contained" color="primary">
                        Voltar para a página inicial
                    </Button>
                    </DialogActions>
                </Dialog>
      </ThemeProvider>
  </div>
  );
}