import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import CardMeusModelos from './CardMeusModelos';
import CardModelosProntos from './CardModelosProntos';
import CardModelosCompartilhados from './CardModelosCompartilhados';
import { StateGlobal } from '../../../ContextAPI/context'
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import './css/Listagem.css';
import '../../../App.css';

import conexao from '../../../Services/api'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },

}));

const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function tabPesquisa(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


 

export default function ListagemModelosSalvos() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [message, setMessage] = React.useState('');
  const [type, setType] = React.useState('');
  const history = useHistory();


  const {
    idEditProntuario,
    setIdEditProntuario,
    setTituloSessaoAtual,
    setMontagem,
    setSessaostate,  
    setViewProntuario
  } = StateGlobal();

  useEffect(() => {
    // if (!parseInt(localStorage.getItem('selectedDoctor')))
    //   alert('Para visualizar os prontuários é necessário selecionar um profissional', 'error')
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function alert(message, type) {
    setMessage(message)
    setType(type)
  }

  const novoModelo = () => {

    setIdEditProntuario(false)
    setMontagem([])
    setSessaostate([])
    setTituloSessaoAtual('Título da seção prontuário')
    setViewProntuario(false)

    history.push('/novo-modelo');
  }
  const [openModal, setOpenModal] = useState(false);

  function closeModal(){
    conexao.post(`/statusModalUpdate/${localStorage.getItem('userIdModalUpdate')}`,
      {
        show_prontuarios: "false", 
      }
    )
  }

  
  function GetModal(){
    conexao.get(`/getStatusPainel/${localStorage.getItem('userIdModalUpdate')}`).then((res) => {
      console.log(res.data.data.show_prontuarios)
      setOpenModal(res.data.data.show_prontuarios == "false" ? false : true)
    })
  }

  useEffect(() => {
    GetModal()
  }, [])

  return (
    <div className={localStorage.getItem('containerClass')}>

      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='header-tutorial'> 
          <div>
            <span className='header-tutorial-title'>Tutorial - Gerenciador de Prontuarios</span>
          </div>
          <CloseIcon className='header-close-icon' onClick={() => {
            closeModal()
            setOpenModal(false)
          }}/>
        </div>

        <div className='header-body'>
          <iframe
            className='video-container'
            width="560"
            height="315"
            src="https://www.youtube.com/embed/_ELOVt2pDUk?si=eTpsXnP-ANTTOMJV?autoplay=1&controls=1"
            frameBorder="0"
            allow="acceleallowFullScreenrometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
     
        </div>
        <div className='desc-tutorial'>
          <span>Aprenda a criar seu próprio prontuário personalizado e usá-lo em seus atendimentos!</span>
        </div>
      </Dialog>

      <div className="card">
        {message ? 
          <Alert variant="filled" severity={type}>
            {message}
          </Alert>
        :
          <>
            <div className="ListagemModelo-prontuario-name-button">
              <h1>Prontuários</h1>
              <Button onClick={novoModelo} variant="contained" className="Color-btn-addModelo">
                <AddIcon/> Novo modelo
              </Button>
            </div>

            <div className="padding-lateral app-bar-modelos-pront">
                <AppBar position="static">
                    <Tabs  indicatorColor="primary" textColor="primary" value={value} onChange={handleChange} aria-label="tab-pesquisa">
                    <Tab label="Meus modelos" {...tabPesquisa(0)} />
                    <Tab label="Modelos prontos" {...tabPesquisa(1)} />
                    <Tab label="Modelos compartilhados" {...tabPesquisa(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel className="retirar-padding-lateral" value={value} index={0}>
                    <CardMeusModelos/>
                </TabPanel>
                <TabPanel className="retirar-padding-lateral" value={value} index={1}>
                  <CardModelosProntos/>
                </TabPanel>
                <TabPanel className="retirar-padding-lateral" value={value} index={2}>
                  <CardModelosCompartilhados/>
                </TabPanel>
            </div>
          </>
        }
      </div>
  </div>
  );
}