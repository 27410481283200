import React, {useState, useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularLoad from '../Load'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from '@material-ui/core/Select'
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import conexao from '../../Services/api'
import Alert from '@material-ui/lab/Alert';

const theme = createTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    }

});

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: "1rem",
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        
        background: "#F9F9F9",
    },
    col3: {
        width: '30%!important',
        margin: '2%!important'
    },
    row: {
        width: '100%',
        border: 'none',
        marginBottom: '2rem'
    },
    modal: {
        padding: '2rem'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        width: "100%",
        backgroundColor: "white",
    },
    input: {
        width: "100%",
        marginTop: "1rem",
        display: "flex",
        backgroundColor: "white"
    }
  }))

const initialState = {
    tipoCompartilhamento: 'TODOS',
    selectedDoctors: []
}


export default function ModalShare(props) { 
    const classes = useStyles()
    const [load, setLoad] = useState(props.load);
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [openModalDelete, setOpenModalDelete] = React.useState(props.open);
    const [titulo, setTitulo] = React.useState(props.titulo);
    const [descricao, setDescricao] = React.useState(props.descricao);
    const [doctorsList, setDoctorsList] = React.useState([]);
    const [selectedDoctors, setSelectedDoctors] = React.useState([]);
    // const [tipoCompartilhamento, setTipoCompartilhamento] = React.useState('TODOS');
    const [values, setValues] = useState(
        props.edit === true ? props.values : initialState
    )

    useEffect(() => {
        setLoad(false);
        setOpenModalDelete(props.open);
        setTitulo(props.titulo);
        setDescricao(props.descricao);
        getDoctors()
        getFormShares()
    }, [props])

    function onchange(e) {
        const { name, value } = e.target
        console.log(e.target)
        setValues({ ...values, [name]: value })
    }

    function updateSelectedDoctors(e) {
        console.log('SELECTED_DOCTORS')
        console.log(e.target.value)
        setValues({ ...values, 'selectedDoctors': e.target.value })
    }

    function close() {
        props.handleModal(false)
    }

    async function getFormShares() {
        conexao.get('/form/shares/' + props.modeloId).then((res) => {
            if (res.data) {
                var doctors = res.data.data.map((value) => (
                    value.id
                ))

                console.log('SELECTED_DOCTORS')
                console.log(doctors)
                setValues({ ...values, 'selectedDoctors': doctors })
            }
        }).catch((error) => {
    
        })      
    }

    async function share() {
        // selectedDoctors contém os ids dos profissionais, mas precisamos do user_id deles pra enviar pra API
        var usersIds = values.selectedDoctors.map((value) => (
            doctorsList.find(element => element['id'] == value).user_id
        ))

        const data = {
            users_ids: usersIds,
            form_id: props.modeloId,
            share_level: (values.tipoCompartilhamento === 'CLINICA') ? 3 : 1
        }
        
        setLoad(true)

        await conexao.post(`/form/share/${props.modeloId}`, data)
        .then((response) => {
            
            alert('Modelo compartilhado com sucesso!', 'success');
            setValues(initialState)
            setTimeout(() => {
                props.refresh()
                alert('', '');
                close();
                // setLoad(false)
            }, 3000);
        })
    }

    async function disshare() {
        setLoad(true)

        await conexao.post(`/form/disshare/${props.modeloId}`, [])
        .then((response) => {
            
            alert('Modelo descompartilhado com sucesso!', 'success');
            setTimeout(() => {
                props.refresh()
                setValues(initialState)
                alert('', '');
                close();
                // setLoad(false)
            }, 3000);
        })
    }

    async function getDoctors() {
        let clinicId = localStorage.getItem('clinicId')

        await conexao.get(`/clinic/byid/${clinicId}`)
        .then((res) => {
            var result = res.data.data;

            if (result[0])
                setDoctorsList(result[0].doctors)
        })
        .catch((e) => {

        })
    }

    function alert(message, type)
    {
      setMessage(message)
      setAlertType(type)
    }

    return(
            <div>
                <ThemeProvider theme={theme}>
                <Dialog
                    className={classes.modal}
                    open={openModalDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                    <DialogContent>
                    {message ?
                        <Alert variant="filled" severity={alertType}>{message}</Alert>
                    : ''}
                    <DialogContentText id="alert-dialog-description">
                        {!props.disshare ?
                            <>
                                {parseInt(localStorage.getItem('clinicId')) ?
                                <>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            name="tipoCompartilhamento"
                                            required
                                            onChange={onchange}
                                        >
                                            <FormControlLabel
                                            checked={values.tipoCompartilhamento === "TODOS" ? true : false}
                                            value="TODOS"
                                            control={<Radio color="primary" />}
                                            label="Todos os usuários"
                                            />
                                            <FormControlLabel
                                            checked={values.tipoCompartilhamento === "CLINICA" ? true : false}
                                            value="CLINICA"
                                            control={<Radio color="primary" />}
                                            label="Profissionais da clínica"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    {values.tipoCompartilhamento === 'CLINICA' ?
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                        >
                                            <InputLabel className={classes.label} id="demo-simple-select-outlined-label">
                                            Profissionais da clínica
                                            </InputLabel>
                                            <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={values.selectedDoctors}
                                            onChange={updateSelectedDoctors}
                                            name="selectedDoctors"
                                            label="Profissionais"
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected ? selected.map((value) => (
                                                    <Chip key={value} label={doctorsList.find(element => element['id'] == value).user.name} />
                                                    )) : ''}
                                                </Box>
                                            )}

                                            className={classes.select}
                                            multiple
                                            >
                                                <MenuItem value="">
                                                    <em>Todos os profissionais da clínica</em>
                                                </MenuItem>
                                                {doctorsList ? doctorsList.map((item) => {
                                                    if (item.user) {
                                                        return (
                                                        <MenuItem value={item.id} key={item.id}>
                                                            <Checkbox checked={(values.selectedDoctors) ? values.selectedDoctors.includes(item.id) : false} />
                                                            <ListItemText primary={item.user.name} />
                                                        </MenuItem>
                                                        )
                                                    }
                                                }) : ''}
                                            </Select>
                                        </FormControl>
                                    : ''}
                                </>
                                : 
                                    <div className={classes.row}>
                                        <h4>Tem certeza que deseja compartilhar este prontuário?</h4>
                                    </div>
                                }
                            </>
                        :
                            <div className={classes.row}>
                                <h4>Tem certeza que deseja descompartilhar este prontuário?</h4>
                            </div>
                        }

                    </DialogContentText>
                    </DialogContent>
                        <DialogActions>
                        {!load ?
                            <Button onClick={close} color="default">
                            {parseInt(localStorage.getItem('clinicId')) ? <span>Cancelar</span> : <span>Não, cancelar</span> }
                            </Button>
                        :
                            ''
                        }
                        <Button onClick={props.disshare ? disshare : share} className="color-primary" variant="contained" color="primary" autoFocus>
                            {!load ? (props.disshare ? 'Descompartilhar' : 'Compartilhar') : <CircularLoad/>}
                        </Button>
                    </DialogActions>
                </Dialog>
                </ThemeProvider>
            </div>
     );
}