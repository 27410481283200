import React, { useContext, useState, useEffect } from 'react'

import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';

import { useStyles } from './style'

export default function Psqi({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView,
  } = useContext(SportsPhysiotherapyContext);

  useEffect(() => {
    let comp5Value = 0
    let score = 0
  
    Object.values(sportsPhysiotherapyChartAnswers.comp5Score).forEach(value => {
      if (value) {
        comp5Value += value
      } else {
        comp5Value += 0
      }
    })

    if (comp5Value === 0) {
      score = 0
    } else if ( comp5Value >= 1 && comp5Value <= 9) {
      score = 1
    } else if (comp5Value >= 10 && comp5Value <= 18) {
      score = 2
    } else if (comp5Value >= 19 && comp5Value <= 27) {
      score = 3
    }

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      component5: score
    }))
  }, [sportsPhysiotherapyChartAnswers.comp5Score, setSportsPhysiotherapyChartAnswers])
  
  useEffect(() => {
    let comp7Value = 0
    let score = 0
  
    Object.values(sportsPhysiotherapyChartAnswers.comp7Score).forEach(value => {
      if (value) {
        comp7Value += value
      } else {
        comp7Value += 0
      }
    })

    if (comp7Value === 0) {
      score = 0
    } else if ( comp7Value >= 1 && comp7Value <= 2) {
      score = 1
    } else if (comp7Value >= 3 && comp7Value <= 4) {
      score = 2
    } else if (comp7Value >= 5 && comp7Value <= 7) {
      score = 3
    }

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      component7: score
    }))
  }, [sportsPhysiotherapyChartAnswers.comp7Score, setSportsPhysiotherapyChartAnswers])

  useEffect(() => {
    let comp2Value = 0
    let score = 0
  
    Object.values(sportsPhysiotherapyChartAnswers.comp2Score).forEach(value => {
      if (value) {
        comp2Value += value
      } else {
        comp2Value += 0
      }
    })

    if (comp2Value === 0) {
      score = 0
    } else if ( comp2Value >= 1 && comp2Value <= 2) {
      score = 1
    } else if (comp2Value >= 3 && comp2Value <= 4) {
      score = 2
    } else if (comp2Value >= 5 && comp2Value <= 6) {
      score = 3
    }

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      component2: score
    }))
  }, [sportsPhysiotherapyChartAnswers.comp2Score, setSportsPhysiotherapyChartAnswers])

  function calcHoursDiff( wakeupTime, sleepTime ) {
    const  [ wakeupHour, wakeupMinute ] = wakeupTime.split(':').map( v => parseInt(v) );
    const [ sleepHour, sleepMinutes ] = sleepTime.split(':').map( v => parseInt(v) );

    let wakeup = 0, sleep = 0, rest = 0, diff = 0;

    if ( wakeupTime < sleepTime ) {
      wakeup = wakeupHour + 24
    }

    wakeup = (wakeup * 60) + wakeupMinute

    sleep = (sleepHour * 60) + sleepMinutes

    diff = wakeup - sleep

    rest = diff % 60
    diff = (diff / 60) + rest

    return diff
  }

  function getPsqiScore() {
    let value = (
      sportsPhysiotherapyChartAnswers.component1
      + sportsPhysiotherapyChartAnswers.component2
      + sportsPhysiotherapyChartAnswers.component3
      + sportsPhysiotherapyChartAnswers.component4
      + sportsPhysiotherapyChartAnswers.component5
      + sportsPhysiotherapyChartAnswers.component6
      + sportsPhysiotherapyChartAnswers.component7
    )
    
    return value || 0
  }

  return (
    <div className={classes.psqi}>
      <p style={{ fontWeight: '500' }}>
        Instruções: As seguintes perguntas são relativas aos seus hábitos de sono durante o último mês somente. Suas respostas devem indicar a lembrança mais exata da maioria dos dias e noites do último mês. Por favor, responda a todas as perguntas
      </p>

      <article>
        <p className='titulo-form'>1. Durante o último mês, quando você geralmente foi para a cama à noite? Hora usual de deitar</p>
        <TextField
          id="select"
          fullWidth
          variant="outlined"
          type='time'
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.sleepTime}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                sleepTime: e.target.value
              }
            }))
          }}
        />   
      </article>

      <article>
        <p className='titulo-form'>2. Durante o último mês, quanto tempo (em minutos) você geralmente levou para dormir à noite? Número de minutos</p>
        <TextField
          id="select"
          fullWidth
          name="minutesToSleep"
          variant="outlined"
          type='number'
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.minutesToSleep}
          onChange={e => {
            const value = parseInt(e.target.value)
            let score = 0 

            if (value <= 15) {
              score = 0
            } else if (value > 15 && value < 31) {
              score = 1
            } else if (value > 30 && value < 61) {
              score = 2
            } else if (value > 60) {
              score = 3
            }
 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp2Score: {
                ...state.comp2Score,
                [e.target.name]: score
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                minutesToSleep: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
          }}
        />
      </article>

      <article>
        <p className='titulo-form'>3. Durante o último mês, quando você geralmente levantou de manhã? Hora usual de levantar </p>
        <TextField
          id="select"
          fullWidth
          variant="outlined"
          type='time'
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.wakeUpTime}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                wakeUpTime: e.target.value
              }
            }))
          }}
        />
      </article>

      <article>
        <p className='titulo-form'>4. Durante o último mês, quantas horas de sono você teve por noite? (Este pode ser diferente do número de horas que você ficou na cama).  Horas de sono por noite</p>
        <TextField
          id="select"
          fullWidth
          type="number"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.hoursOfSleep}
          onChange={e => {
            const value = parseInt(e.target.value)
            if (!value) return

            const psqi = sportsPhysiotherapyChartAnswers.psqi
            let hours = 0 
            let bedtime = 0 
            let sleepEfficiency = 0   

            if (value >= 7) {
              hours = 0
            } else if (value >= 6 && value < 7) {
              hours = 1
            } else if (value >= 5 && value < 6) {
              hours = 2
            } else if (value < 5) {
              hours = 3
            }

            bedtime = calcHoursDiff(psqi.wakeUpTime, psqi.sleepTime) 
            sleepEfficiency = (value / bedtime * 100)

            if (sleepEfficiency > 85) {
              sleepEfficiency = 0
            } else if (sleepEfficiency > 74 && sleepEfficiency < 85) {
              sleepEfficiency = 1
            } else if (sleepEfficiency > 64 && sleepEfficiency < 75) {
              sleepEfficiency = 2
            } else {
              sleepEfficiency = 3
            }
            
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              component3: hours
            }))
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              component4: sleepEfficiency
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                hoursOfSleep: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">horas</InputAdornment>,
          }}
        />  
      </article>

      <p 
        className='titulo-form'
        style={{ fontSize: '1.3em', marginTop: '15px' }}
      >
        5. Durante o último mês, com que freqüência você teve dificuldade de dormir porque você... 
      </p>

      <article>
        <p className='titulo-form'>(A) Não conseguiu adormecer em até 30 minutos </p>
        <TextField
          id="select"
          select
          fullWidth
          name="sleepWithin30Minutes"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.sleepWithin30Minutes}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp2Score: {
                ...state.comp2Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                sleepWithin30Minutes: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(B) Acordou no meio da noite ou de manhã cedo </p>
        <TextField
          id="select"
          select
          fullWidth
          name="middleOfTheNight"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.middleOfTheNight}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                middleOfTheNight: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(C) Precisou levantar para ir ao banheiro </p>
        <TextField
          id="select"
          select
          fullWidth
          name="goToTheBathroom"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.goToTheBathroom}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                goToTheBathroom: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(D) Não conseguiu respirar confortavelmente </p>
        <TextField
          id="select"
          select
          fullWidth
          name="notBreatheComfortably"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.notBreatheComfortably}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                notBreatheComfortably: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(E) Tossiu ou roncou forte </p>
        <TextField
          id="select"
          select
          fullWidth
          name="coughedOrSnoredLoudly"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.coughedOrSnoredLoudly}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                coughedOrSnoredLoudly: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(F) Sentiu muito frio</p>
        <TextField
          id="select"
          select
          fullWidth
          name="feltVeryCold"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.feltVeryCold}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                feltVeryCold: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(G) Sentiu muito calor</p>
        <TextField
          id="select"
          select
          fullWidth
          name="itFeltVeryHot"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.itFeltVeryHot}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                itFeltVeryHot: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(H) Teve sonhos ruins</p>
        <TextField
          id="select"
          select
          fullWidth
          name="hadBadDreams"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.hadBadDreams}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                hadBadDreams: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(I) Teve dor</p>
        <TextField
          id="select"
          select
          fullWidth
          name="hadPain"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.hadPain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                hadPain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(J) Outra(s) razão(ões)?, por favor descreva:</p>
        <TextField
          id="select"
          fullWidth
          variant="outlined"
          placeholder='...'
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.otherReason}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                otherReason: e.target.value
              }
            }))
          }}
        /> 
      </article>

      <article>
        <p className='titulo-form'>Com que freqüência, durante o último mês, você teve dificuldade para dormir devido a essa razão? </p>
        <TextField
          id="select"
          select
          fullWidth
          name="dueToThisReason"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.dueToThisReason}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp5Score: {
                ...state.comp5Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                dueToThisReason: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6. Durante o último mês, como você classificaria a qualidade do seu sono de uma maneira geral? </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.sleepQuality}
          onChange={e => {
            const value = parseInt(e.target.value)

            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              component1: value ? value : 0
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                sleepQuality: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Muito Boa</option>
          <option value="1">Boa</option>
          <option value="2">Ruim</option>
          <option value="3">Muito ruim</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7. Durante o último mês, com que freqüência você tomou medicamento (prescrito ou “por conta própria”) para lhe ajudar a dormir? </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.howOftenDidYouTakeMedicine}
          onChange={e => {
            const value = parseInt(e.target.value)

            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              component6: value ? value : 0
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                howOftenDidYouTakeMedicine: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8. No último mês, com que freqüência você teve dificuldade de ficar acordado enquanto dirigia, comia ou participava de uma atividade social (festa, reunião de amigos, trabalho, estudo)? </p>
        <TextField
          id="select"
          select
          fullWidth
          name="stayAwake"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.stayAwake}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp7Score: {
                ...state.comp7Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                stayAwake: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma no último mês</option>
          <option value="1">Menos de 1 vez/ semana</option>
          <option value="2">1 ou 2 vezes/ semana</option>
          <option value="3">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>9. Durante o último mês, quão problemático foi para você manter o entusiasmo (ânimo) para fazer as coisas (suas atividades habituais)?</p>
        <TextField
          id="select"
          select
          fullWidth
          name="keepYourSpiritsUp"
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.keepYourSpiritsUp}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              comp7Score: {
                ...state.comp7Score,
                [e.target.name]: parseInt(e.target.value)
              }
            }))

            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                keepYourSpiritsUp: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Nenhuma Dificuldade</option>
          <option value="1">Um problema muito leve</option>
          <option value="2">Um problema razoável</option>
          <option value="3">Um problema muito grande</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>10. Você tem um(a) parceiro [esposo(a)] ou colega de quarto?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.roommate}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                roommate: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="no">Não</option>
          <option value="anotherRoom">Parceiro ou colega, mas em outro quarto</option>
          <option value="sameRoom">Parceiro no mesmo quarto, mas não na mesma cama </option>
          <option value="sameBed">Parceiro na mesma cama</option>
        </TextField>
      </article>

      <p 
        className='titulo-form'
        style={{ fontSize: '1.3em', marginTop: '15px' }}
      >
        Se você tem um parceiro ou colega de quarto, pergunte a ele/ela com que freqüência no último mês você teve ...
      </p>

      <article>
        <p className='titulo-form'>(a)  Ronco forte</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.loudSnoring}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                loudSnoring: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="none">Nenhuma no último mês</option>
          <option value="lessOneTime">Menos de 1 vez/ semana</option>
          <option value="oneTwoTimes">1 ou 2 vezes/ semana</option>
          <option value="threeOrMoreTimes">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(b)  Longas paradas na respiração enquanto dormia </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.longStopsInBreathing}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                longStopsInBreathing: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="none">Nenhuma no último mês</option>
          <option value="lessOneTime">Menos de 1 vez/ semana</option>
          <option value="oneTwoTimes">1 ou 2 vezes/ semana</option>
          <option value="threeOrMoreTimes">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(c) Contrações ou puxões nas pernas enquanto você dormia </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.twitchingYourLegs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                twitchingYourLegs: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="none">Nenhuma no último mês</option>
          <option value="lessOneTime">Menos de 1 vez/ semana</option>
          <option value="oneTwoTimes">1 ou 2 vezes/ semana</option>
          <option value="threeOrMoreTimes">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(d)  Episódios de desorientação ou confusão durante o sono </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.confusionDuringSleep}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                confusionDuringSleep: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="none">Nenhuma no último mês</option>
          <option value="lessOneTime">Menos de 1 vez/ semana</option>
          <option value="oneTwoTimes">1 ou 2 vezes/ semana</option>
          <option value="threeOrMoreTimes">3 ou mais vezes/ semana </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>(e)  Outras alterações (inquietações) enquanto você dorme; por favor, descreva</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.psqi.restlessnessWhileYouSleep}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              psqi: {
                ...state.psqi,
                restlessnessWhileYouSleep: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true, 
          }}
        >
          <option value="" selected >...</option>   
          <option value="none">Nenhuma no último mês</option>
          <option value="lessOneTime">Menos de 1 vez/ semana</option>
          <option value="oneTwoTimes">1 ou 2 vezes/ semana</option>
          <option value="threeOrMoreTimes">3 ou mais vezes/ semana </option>
        </TextField>

        <p className={classes.score}>Escore: {getPsqiScore()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      } 
    </div>
  )
}