import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {celular, telefonefixo} from '../../../Agenda/Modais/Validate'
import { queryByDisplayValue } from '@testing-library/react';

export default function Aniversariantes(props) {
  const [aniversario, setAniversario] = useState(props.dados);
  const [open, setOpen] = React.useState(false);


  useEffect(() => {
    setAniversario(props.dados)
  }, [props])

  function handleModal(value){
    setOpen(value)
}

  return (
    <div className="aniversariantes-dashboard">
      {aniversario.length>0 ?
          <div className="aniversariantes-dashboard">
            <div  >
            <AvatarGroup 
              className='AvatarGroup' 
              max={4} 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'scroll',
                  maxHeight: '170px',
                  marginTop: '5px'}}>
              {aniversario.map((item,idex) => (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '250px',
                    height: '50px',
                    borderRadius: '10px',
                    marginTop: '5px',
                    marginBottom: '5px'
                  }}>
                      <div style={{marginLeft:'7%'}}>
                        <Avatar alt={item.name} src={item.avatar} style={{width: '35px', height: '35px'}} />
                      </div>
                      <div style={{marginRight:'5%',marginLeft:'7%'}}>
                        <p>{aniversario[idex].name}</p>
                      </div>
                      <div>
                        <p>Data: {item.birthday.substring(8, 10) + "/" + item.birthday.substring(5, 7)}</p>
                      </div>
                  </div>
              ))}
            </AvatarGroup>
              <div style={{    display: 'flex', justifyContent: 'center'}}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="color-secondary"
                    onClick={()=>handleModal(true)}
                    color="secondary">
                    Ver a lista completa
                  </Button>
              </div>
            </div>
          
              <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Aniversariantes do mês</DialogTitle>
                    <DialogContent>
                      <div>
                      {aniversario.map((item) => (
                        <div className="modal-aniversariante">
                          <div className="modal-aniversariante-img-p">
                            {/* <Avatar className="margin-rigth-p" alt={item.name} src={item.avatar} /> */}
                            <p className="margin-rigth-p"><strong>{item.name}</strong></p>
                          </div>
                            <p className="margin-rigth-p"><strong>Celular:</strong> {item.cellphone?celular(item.cellphone):'Não possui'}</p>
                            <p><strong>Telefone:</strong> {item.phone?telefonefixo(item.phone):'Não possui'}</p>
                            <p><strong>Data:</strong> {item.birthday.substring(8, 10) + "/" + item.birthday.substring(5, 7)}</p>
                        </div>
                      ))}
                      </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleModal(false)} color="default">
                        Fechar
                    </Button>
                    </DialogActions>
                </Dialog>
          </div>
          // <p>Não possui aniversriantes</p>
      : 
        <p>Não possui aniversariantes</p>
      }
    </div>
  );
}