import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Sono({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function handleChange(e) {
    const { name, value } = e.target

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      aofa: {
        ...state.aofa,
        [name]: value
      }
    }))
  }

  function getAofaScore() {
    let score = 0

    Object.values(sportsPhysiotherapyChartAnswers.aofa).forEach(value => { 
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return (score).toFixed(2) || 0;
  }

  return (
    <div>
      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Dor
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="pain"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.pain}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">Nenhuma</option>
          <option value="30">Leve ocasional</option>
          <option value="20">Moderada, Diária</option>
          <option value="10">Intensa, Quase sempre presente</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Funcional (Limitação nas atividades de suporte)
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="functional"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.functional}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Sem limitação, sem suporte</option>
          <option value="7">Sem limitação nas atividades diárias, limitação nas atividades recreacionais, sem suporte</option>
          <option value="4">Limitação nas atividades diárias e recreacionais, bengala</option>
          <option value="0">Limitação, intensa nas atividades diárias e recreacionais, andador, muletas, cadeira de rodas, 
          órteses (tornozeleira, imobilizador de tornozelo)
          </option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Distância Máxima de caminhada, quarteirões
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="maxDistance"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.maxDistance}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="5">Mais que 6</option>
          <option value="4">De 4 a 6</option>
          <option value="2">De 1 a 3</option>
          <option value="0">Menos que 1</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Superfícies de caminhada
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="walkSurface"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.walkSurface}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="5">Sem dificuldades em qualquer superfície</option>
          <option value="3">Alguma dificuldade em terrenos irregulares, escadas inclinações e ladeiras</option>
          <option value="0">Dificuldade intensa em terrenos irregulares, escadas inclinações e ladeiras</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Anormalidade na marcha
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="gaitAbnormality"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.gaitAbnormality}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="8">Nenhuma, leve</option>
          <option value="4">Evidente</option>
          <option value="0">Acentuada</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Mobilidade sagital (Flexão + Extensão).
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="mobility"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.mobility}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="8">Nenhuma, leve</option>
          <option value="4">Evidente</option>
          <option value="0">Acentuada</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Mobilidade de Retro pé (Inversão + Eversão).
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="mobility2"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.mobility2}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="8">Normal ou levemente restrita (30º ou mais)</option>
          <option value="4">Restrição moderada (15º a 29º)</option>
          <option value="0">Restrição intensa (menor que 15º)</option>
        </TextField>
      </article>
      
      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Estabilidade do Tornozelo e retro pé (Anteroposterior, varo-valgo).
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="stability"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.stability}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="8">Estável</option>
          <option value="0">Instável</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          Alinhamento (10 pontos)
        </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          name="alignment"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.aofa.alignment}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Bom, pé plantígrado, ante pé e retro pé bem alinhado</option>
          <option value="5">Regular, pé plantígrado, algum grau de desalinhamento do tornozelo e retro pé sem sintomas</option>
          <option value="0">Ruim, pé não plantígrado, desalinhamento intenso e sintomático</option>
        </TextField>

        <p className={classes.score}>Escore: {getAofaScore()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      } 
    </div>
  )
}