import React, { useState } from 'react'
// import { render } from 'react-dom'
import { useDispatch } from 'react-redux'
import { list } from '../../Store/Teste/teste.actions'
import { StateGlobal } from '../../ContextAPI/context';
// import ModalAgendamento from '../../Pages/Agenda/Modais/ModalAgendamento'
// import ModalProcedimentos from '../../Pages/Agenda/Modais/ModalProcedimentos'
// import ModalAddAgendamento from '../../Pages/Agenda/Modais/ModalDetalhesAgendamento'
// import ModalResult from '../../Pages/Agenda/Modais/ModalResultado'
// import ModalLancarPagamento from '../../Pages/Agenda/Modais/ModalLancarPagamento'
import conexao from '../../Services/api'
// import axios from 'axios'
import { cnpj, cpf, currency, celular, data, hora, teste, teste2 } from './Validate'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



//import { StateGlobal } from '../../ContextAPI/context';

//const {count ,setCount} = StateGlobal();





export default function TesteRedux() {

    // const array = [
    //     <ModalAgendamento open={true} />,
    //     <ModalProcedimentos open={true} />,
    //     <ModalAddAgendamento open={true} />,
    //     <ModalResult open={true} />,
    //     <ModalLancarPagamento open={true} />
    // ]




    const { count, setCount } = StateGlobal();
    const [text, setText] = useState();

    const [validatesx, setvalidatesx] = useState([
        { name: 'name', funcao: teste, error: false }
        , { name: 'email', funcao: teste2, error: false }]);



    // const result = useSelector(state => state.teste) //redux

    const dispatch = useDispatch()   //redux


    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
    ]





    function onchange(e) {
        const { name, value } = e.target
        validatesx.map((item) => {
            if (item.name === name) {
                item.error = item.funcao(value)
            }

            return item.error;
        })
        const arr = validatesx.slice()
        setvalidatesx(arr)
    }






    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            Teste   ID user {localStorage.getItem('selectedUser')}<br />
        Teste   ID doctor {localStorage.getItem('selectedDoctor')}<br />
        Teste   TOKEN {localStorage.getItem('token')}

            <button onClick={() => {
                dispatch(list({
                    nome: 'Mauro Viera da Cruz Junior',
                    endereço: 'teste234999',
                    idade: '28',
                    data: '11-04-1992'
                }))
            }}>teste redux</button>

            <button onClick={() => {
                setCount('contextAPIfuncionando')
            }}>ContextAPI</button>

            <button onClick={() => {

                //conexao.get('/usuario').then((res)=>{
                conexao.post('/doctor/create', {
                    "name": "Sr osvaldo San",
                    "email": "walter@walter1.com",
                    "password": "123456",
                    "phone": "15999999999",
                    "cellphone": "15999999999",
                    "is_whatsapp": 1,
                    "cpf": "20292695063",
                    "rg": "555555555",
                    "birthday": "1992-08-21",
                    "avatar": "image.jpg",
                    "street": "Rua dos bobos",
                    "neighborhood": "Longe demais",
                    "number": 22,
                    "city": "Gotham",
                    "state": "SP",
                    "complement": "Bloco 8",
                    "zip_code": "18110-255",
                    "speciality_id": 1
                }).then((res) => {

                }).catch((error) => {
          
                })

            }}>cria usuario</button>

            <button onClick={() => {

                //conexao.get('/usuario').then((res)=>{
                conexao.post('/patient/create', {
                    "name": "Sr Osvaldo San",
                    "email": "walter2@walter.com",
                    "cpf": "44455577788",
                    "rg": "555555555",
                    "phone": "15998787070",
                    "is_whatsapp": 1,
                    "birthday": "2020-03-01",
                    "mothers_name": "Josimara da Silva",
                    "fathers_name": "Josilei da Silva",
                    "how_met": "Amigo do primo do irmão da minha tia recomendou",
                    "notification": 1,
                    "street": "Rua dos bobos",
                    "neighborhood": "Longe demais",
                    "number": 22,
                    "city": "Gotham",
                    "state": "SP",
                    "complement": "Bloco 8",
                    "zip_code": "18110-255",
                    "doctor_id": 13

                }).then((res) => {

                }).catch((error) => {

                })

            }}>cria paciente</button>

            {count}


    mask<input type='text' onChange={(e) => { setText(e.target.value) }}></input>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button onClick={() => { setText(currency(text)) }}>currency</button>
                <button onClick={() => { setText(cpf(text)) }} >cpf</button>
                <button onClick={() => { setText(cnpj(text)) }} >cnpj</button>
                <button onClick={() => { setText(celular(text)) }} >celular</button>
 
                <button onClick={() => { setText(data(text)) }} >data</button>padrao 2021-04-14 08:13:00
                <button onClick={() => { setText(hora(text)) }} >hora</button>padrao 2021-04-14 08:13:00
</div>

mask aplicada<em style={{ fontWeight: 'bold', fontSize: '30px' }}>{text}</em>





            <div style={{ width: 300 }}>



                <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={top100Films.map((option) => option.title)}
                    renderInput={(params) => (
                        <TextField {...params} label="freeSolo" margin="normal" variant="outlined" />
                    )}
                />



                {/* <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={top100Films.map((option) => option.title)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search input"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      /> */}
            </div>




            <input type='text' on placeholder='digite aqui' />
            <div>
                teste
    </div>
           


            <TextField
                variant='outlined'
                error={validatesx[0].error}
                label='Nome'
                name='name'
                onChange={(e) => { onchange(e) }}
                helperText={validatesx[0].error ? validatesx[0].error : ''}
                style={{
                    width: '30%',
                    marginBottom: '30px'
                }}>

            </TextField>
            <TextField
                variant='outlined'
                error={validatesx[1].error}
                helperText={validatesx[1].error ? 'maior que 5' : ''}
                name='email'
                onChange={(e) => { onchange(e) }}
                label='Email'
                style={{
                    width: '30%',
                    marginBottom: '30px'
                }}>

            </TextField>
            <TextField
                variant='outlined'
                name='cpf'
                label='Cpf'
                style={{
                    width: '30%',
                    marginBottom: '30px'
                }}>

            </TextField>




        </div>
    )






}




