import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import '../css/inputs.css';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(id, nome, telefone, cpf ) {
  return { id, nome, telefone, cpf };
}

const rows = [
  createData(1, 'Ana', '(15) 9 9654-3424', '324.532.756-32'),
  createData(2, 'Lucia', '(15) 9 9754-3222', '125.539.796-55'),
];

export default function InputTabela() {
  const classes = useStyles();
    
  return (
      <div className="envolve-inputTabela">
        <p className="titulo-form">Tabela</p>
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">Nome</TableCell>
                  <TableCell align="left">Telefone</TableCell>
                  <TableCell align="left">CPF</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.nome}</TableCell>
                    <TableCell align="left">{row.telefone}</TableCell>
                    <TableCell align="left">{row.cpf}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>  
  );
}
