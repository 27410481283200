import React, { useContext, useRef, useEffect } from 'react'

import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../../../ContextAPI/SportsPhysiotherapyContext'
import { algometryMuscles } from '../../../../utils'

export default function LimiarDeDorPorPressao() {
  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  const painPoint1 = useRef()
  const xMuscleRight1 = useRef()
  const xMuscleLeft1 = useRef()
  const average = useRef()
  const painPoint2 = useRef()
  const xMuscleRight2 = useRef()
  const xMuscleLeft2 = useRef()
  const average2 = useRef()

  useEffect(() => {
    let painPoint1El = painPoint1.current
    let xMuscleRight1El = xMuscleRight1.current
    let xMuscleLeft1El = xMuscleLeft1.current
    let averageEl = average.current
    let painPoint2El = painPoint2.current
    let xMuscleRight2El = xMuscleRight2.current
    let xMuscleLeft2El = xMuscleLeft2.current
    let average2El = average2.current

    return () => {
      setSportsPhysiotherapyChartAnswers(state => ({
        ...state,
        algometry: {
          ...state.algometry,
          painPoint1: painPoint1El.value,
          xMuscleRight1: xMuscleRight1El.value,
          xMuscleLeft1: xMuscleLeft1El.value,
          average: averageEl.value,
          painPoint2: painPoint2El.value,
          xMuscleRight2: xMuscleRight2El.value,
          xMuscleLeft2: xMuscleLeft2El.value,
          average2: average2El.value,
        }
      }))
    }
  }, [setSportsPhysiotherapyChartAnswers])

  return (
    <div>
      <p className="titulo-form">Ponto de dor:</p>
      <TextField
        id="select"
        variant="outlined"
        type='number'
        fullWidth
        disabled={sportsPhysiotherapyView}
        inputRef={painPoint1}
        defaultValue={sportsPhysiotherapyChartAnswers.algometry.painPoint1}
        InputProps={{
          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
        }}
      /> 

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">X (Direita)</p>
          <TextField
            id="select"
            variant="outlined"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            inputRef={xMuscleRight1}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.xMuscleRight1}
            SelectProps={{
              native: true,
            }}
          > 
            <option selected>...</option>

            {algometryMuscles.map(muscle => (
              <option value={muscle}>{muscle}</option>
            ))}
          </TextField>
        </Grid> 

        <Grid item xs={6}>
          <p className="titulo-form">X (Esquerda)</p>
          <TextField
            id="select"
            variant="outlined"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            inputRef={xMuscleLeft1}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.xMuscleLeft1}
            SelectProps={{
              native: true,
            }}
          > 
            <option selected>...</option>

            {algometryMuscles.map(muscle => (
              <option value={muscle}>{muscle}</option>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <p className="titulo-form">Media</p>
      <TextField
        id="select"
        variant="outlined"
        type='number'
        disabled={sportsPhysiotherapyView}
        inputRef={average}
        defaultValue={sportsPhysiotherapyChartAnswers.algometry.average}
      /> 

      <p style={{ marginTop: '25px'}} className="titulo-form">Ponto de dor:</p>
      <TextField
        id="select"
        variant="outlined"
        type='number'
        fullWidth
        disabled={sportsPhysiotherapyView}
        inputRef={painPoint2}
        defaultValue={sportsPhysiotherapyChartAnswers.algometry.painPoint2}
        InputProps={{
          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
        }}
      /> 

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">X (Direita)</p>
          <TextField
            id="select"
            variant="outlined"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            inputRef={xMuscleRight2}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.xMuscleRight2}
            SelectProps={{
              native: true,
            }}
          > 
            <option selected>...</option>

            {algometryMuscles.map(muscle => (
              <option value={muscle}>{muscle}</option>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">X (Esquerda)</p>
          <TextField
            id="select"
            variant="outlined"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            inputRef={xMuscleLeft2}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.xMuscleLeft2}
            SelectProps={{
              native: true,
            }}
          > 
            <option selected>...</option>

            {algometryMuscles.map(muscle => (
              <option value={muscle}>{muscle}</option>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <p className="titulo-form">Media</p>
      <TextField
        id="select"
        variant="outlined"
        type='number'
        disabled={sportsPhysiotherapyView}
        inputRef={average2}
        defaultValue={sportsPhysiotherapyChartAnswers.algometry.average2}
      /> 
    </div>
  )
}