import { Button, CircularProgress, createMuiTheme, Grid, IconButton, InputBase, makeStyles, Paper, ThemeProvider } from "@material-ui/core"
import clsx from 'clsx';
import React, { useEffect, useState } from "react"
import { ptBR } from "@material-ui/core/locale";
import useStyles from './style'
import Grafico from './Grafico/grafico'
import { useParams } from "react-router";
import conexao from "../../../../Services/api";


export default function DesenvolvimentoMotor() {
  const classes = useStyles();
  const [dataInfoGrafico, setDataInfoGrafico] = useState([]);
  const [dataError, setDataError] = useState('');

  const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);

  const Params = useParams()

  const [Load,setLoad] = useState(true)

  let arr = Params.date.split("&")

  useEffect(() => {
    if (arr[0]) {
      getDataAll({
        date: arr[0].replaceAll("-", "/"),
        patient_id: Number(arr[1]),
      });
    }
  }, []);
 

  async function getDataAll(body) {
    
try {
  const { data } = await conexao.post(`/form/desenvolvimentomotor`, body);
    setDataInfoGrafico(data.data);
} catch (error) {
  setDataError(error.response.data.message)
}

    

    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }

  return(
    <>
      {Load ? 
        <div className='loadingPaciente'>
          <CircularProgress style={{color: '#27B8D2'}}/>
        </div> 

        : 
        
        <div className='ListagemModelo-container'>
          <Grid item xs={12}>
            <Paper className={clsx(classes.paper, classes.colorPaper)}>
              <div className={arr[1] ? "ListgemModelo-prontuarios-4" : "ListgemModelo-prontuarios-3" }>
                <ThemeProvider theme={theme}>
                  <Grafico dataInfoGrafico={dataInfoGrafico} dataError={dataError} /> 
                </ThemeProvider>
              </div>
            </Paper>
          </Grid>
        </div>
      }
    </>
  )
}