import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import InfoProntuarioLateralEsquerda from './InfoProntuarioLateralEsquerda';
import FerramentasLateralDireita from './FerramentasLateralDireita';
import SecaoInput from './SecaoInput';
import { useHistory } from "react-router-dom";
import { StateGlobal } from '../../../ContextAPI/context'

import './css/NovoModelo.css';
import '../../../App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },

}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);

export default function NovoModelo() {
  const classes = useStyles();
  const history = useHistory();

  const {
    idEditProntuario,
    setIdEditProntuario,
    viewProntuario
      
  } = StateGlobal();


  useEffect(()=>{

    if(idEditProntuario){

    }else{

    }

  },[])


  return (
    <div className={localStorage.getItem('containerClass')}>
      <div className="d-flex">
        <Grid item xs={3}>
          <Paper className={clsx(classes.paper, classes.colorPaper)}>
              <div className="NovoModelo-envolve-laterais">
            
                <InfoProntuarioLateralEsquerda/>
                  
              </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={clsx(classes.paper, classes.colorPaper)}>
              <SecaoInput/>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={clsx(classes.paper, classes.colorPaper)}>
              <div className="NovoModelo-envolve-laterais">
                  <FerramentasLateralDireita/>
              </div>
          </Paper>
        </Grid>
      </div>
    </div>
  );
}