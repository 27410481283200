import LimiarDeDorPorPressao from './components/LimiarDedorPorPressao';
import Adm from './components/Adm';
import Dinamometro from './components/Dinamometro';
import TesteClinico from './components/TesteClinico';

import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';

export default function Algometria({ path }){
  return (
    <div>
      <LimiarDeDorPorPressao />

      <hr 
        style={{
          width:'100%',
          borderColor: 'rgb(212 212 212 / 22%)',
          marginTop: '20px' 
        }}
      />

      <Adm />  
      <Dinamometro />
      <TesteClinico />

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}
