import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './style'
import { sportsModalities } from '../../utils'
import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonNext from '../StepButtonNext'

export default function Anamnese({ path }) {
  const classes = useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  return (
    <div className={classes.informations}>
      <Grid container className={classes.informations__basics}>
        <Grid item xs={3}>
          <p className="titulo-form">Data de nascimento:</p>
          <TextField
            id="select"
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.birthDate}
            onChange={e => {
              console.log('setSportsPhysiotherapyChartAnswers')
              console.log(sportsPhysiotherapyChartAnswers.informations)
              console.log(e.target.value)
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                informations: {
                  ...state.informations,
                  birthDate: e.target.value
                }
              }))
            }}
            // required={required}
            // onBlur={handleChange}
            // disabled={respView}
            // error={error}
            placeholder='Preencha o campo...'
            type="date"
            InputLabelProps={{
            shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <p className="titulo-form">Idade:</p>
          <TextField
            id="select"
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.age}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  age: e.target.value
                }
              }))
            }}
            // required={required}
            // onBlur={handleChange}
            // disabled={respView}
            // error={error}
            type="number"
            placeholder='Preencha o campo...'
            // disabled = {respEdit}
            InputLabelProps={{
            shrink: true,
            }}
          />
        </Grid>    

        <Grid item xs={2}>
          <p className="titulo-form">Peso:</p>
          <TextField
            id="data"
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.weight}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  weight: e.target.value
                }
              }))
            }}
            // required={required}
            // onBlur={handleChange}
            // disabled={respView}
            // error={error}
            placeholder='Preencha o campo...'
            InputLabelProps={{
            shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <p className="titulo-form">Altura:</p>
          <TextField
            id="data"
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.height}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  height: e.target.value
                }
              }))
            }}
            // required={required}
            // onBlur={handleChange}
            // disabled={respView}
            // error={error}
            placeholder='Preencha o campo...'
            InputLabelProps={{
            shrink: true,
            }}
          />
        </Grid>
        
        <Grid item xs={2}>
          <p className="titulo-form">Sexo:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.gender}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  gender: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            SelectProps={{
                native: true,
            }}
            // error={error}
          >   

            <option value="" disabled selected>...</option>
            <option value="men">Masculino</option>
            <option value="woman">Feminino</option>
          </TextField>
        </Grid>
      </Grid>

      <article className={classes.informations__members}>
        <div style={{ marginTop: '45px'}}>
          <p className="titulo-form">Membro Inferior Dominante:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.lowerDominantMember}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  lowerDominantMember: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            SelectProps={{
                native: true,
            }}
            // error={error}
          >   
            <option value="" disabled selected>...</option>
            <option value="right">Direito</option>
            <option value="left">Esquerdo</option>
          </TextField>
        </div>

        <div>
          <p className="titulo-form">Membro Superior Dominante:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.upperDominantMember}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  upperDominantMember: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            SelectProps={{
                native: true,
            }}
            // error={error}
          >   
            <option value="" disabled selected>...</option>
            <option value="right">Direito</option>
            <option value="left">Esquerdo</option>
            
          </TextField>
        </div>

        <div>
          <p className="titulo-form">Membro Inferior acometido:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.affectedLowerMember}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  affectedLowerMember: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            SelectProps={{
                native: true,
            }}
            // error={error}
          >   
            <option value="" disabled selected>...</option>
            <option value="right">Direito</option>
            <option value="left">Esquerdo</option>
          </TextField>
        </div>

        <div>
          <p className="titulo-form">Membro Superior acometido:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.informations.affectedUpperMember}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                informations: {
                  ...state.informations,
                  affectedUpperMember: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            SelectProps={{
                native: true,
            }}
            // error={error}
          >   
            <option value="" disabled selected>...</option>
            <option value="right">Direito</option>
            <option value="left">Esquerdo</option>
          </TextField>
        </div>
      </article>

      <div style={{ marginTop: '45px'}}>
        <p className="titulo-form">Modalidade Esportiva:</p>
        <Autocomplete
          multiple
          disabled={sportsPhysiotherapyView}
          id="tags-outlined"
          options={sportsModalities}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.name === value.name}
          defaultValue={sportsPhysiotherapyChartAnswers.informations.sportModalidy || []}
          onChange={(e, newValue) => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              informations: {
                ...state.informations,
                sportModalidy: newValue
              }
            }))
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Modalidades..."
            />
          )}
        />
      </div>
      {path !== '/pdf-esportivo' && 
        <StepButtonNext />
      }
    </div>
  )
}