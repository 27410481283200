import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  informations__basics: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  informations__members: {
    '& p': {
      marginTop: '17px'
    }
  }
}));