

import React, { useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import { BsPlusCircleFill } from 'react-icons/bs'

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './style.css'


import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';

import osteopatiapediatrica from '../../../../../Assets/Images/ImagensProntuario/osteopatia_pediatrica.png';

import { TrainRounded } from '@material-ui/icons';




const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  inputEscala: {
    width: '70%',
  },

}));



export default function CardTextoCurto({ title, id, required, edit, options, answer }) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();

  const [checked, setcheck] = useState(false)
  const [titulocampo, setTituloCampo] = useState();
  const [tipo, setTipo] = useState({ id: 595, text: "Molares", value: "18" });
  const [img, setImg] = useState();
  const [escala, setEscala] = useState('')
  const [listagem, setListagem] = useState([])
  const [OptionValues, setOptionValues] = useState(options)
  const [error, setError] = useState(false)
  const [req, setReq] = useState(required);





  const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, respView, validaProntuario, setvalidaProntuario,  validation, setValidation,
    attValidation, setAttValidation, attEncerraError } = StateGlobal();




  useEffect(() => {

    var arr = []



    if (respEdit) {

      const dados = Answer.find(item => item.question_id === id)
      dados.answer.map((item) => {
        if (item.form_step_question_option_id) {

          arr.push({ id: item.form_step_question_option_id, value: item.text, text: ChangeOption(item.form_step_question_option_id) })
          UpdateAnswer(arr)




        }
      })
    } else {

      // console.log('ESTA CHEGANDO ASSIM A', Answer)

      setOptionValues(options)

    }

    var opa = ReturnAnswer()

    const eae = opa && opa.slice()

    if (respEdit) {

      setOptionValues(eae)

    }





  }, [id])




  function handlerChange(e) {
    const value = e.target.value

    const obj = ChangeOption(value)

    setTipo(obj)

  }
  function handlerChange2(e) {
    const { name, value } = e.target




    OptionValues.map((item) => {
      if (item.text === name) {
        item.value = value
      }


    })

    const arr = OptionValues.slice()
  
    setOptionValues(arr)

    UpdateAnswer(arr)

    let err = []
    
    if(required){
            
      OptionValues.forEach( element => {

        err.push(element.value)
          
      });

    }
    console.log(err)

    let val = err.indexOf('')
    console.log(val)

    if(val < 0){
        setError(false)
    }else{
        setError(true)
    }


  }


  function ChangeOption(tipo) {
    var data = ''
    options.map((item) => {

      if (item.id === Number(tipo)) {
        data = item
      }
    })

    return data.text
  }


  function UpdateAnswer(arr) {
    Answer.map((item) => {
      if (item.question_id === id) {
        item.answer = arr
      }
    })

    setAnswer(Answer)
  }


  function ReturnAnswer() {
    var teste
    Answer.map((item) => {
      if (item.question_id === id) {
        teste = item.answer
      }
    })

    return teste
  }

  useEffect(() => {
    if(validation) {
      
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
         
          if(comp.id == id){
   
            setError(validation[index].components[i].error)
              
          }
        })
      })
    }

  }, [attEncerraError])

  useEffect(()=>{
    if(validation) {
      
      validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
          
            if(comp.id == id){
    
              validation[index].components[i].error = error 
              
                
            }
          })
      })
      setAttValidation(!attValidation)
    }

  }, [error])




  //codigo para validação***************************************
  // useEffect(() => {

  //   validacaoOnchange()


  // }, [OptionValues, id])


  // function validacaoOnchange() {

  //   var error = false

  //   if (required !== false)
  //     Answer.map((item) => {
  //       if (item.question_id === id) {
  //         if (item.answer.length > 0) {
  //           //  console.log('VALIDACAO VALUR',item.answer.value)
  //           item.answer.map((i) => {
  //             //  console.log('ITEM ===>',i)
  //             if (i.value === '' || i.value === undefined) {
  //               error = true

  //             }
  //           })
  //           validaProntuario[id].error = error ? true : false
  //           validaProntuario[id].value = error ? '' : 'ok'
  //           setvalidaProntuario({ ...validaProntuario })

  //         }
  //       }
  //     })

  // }
  //codigo para validação***************************************

  //   <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
  //   {validaProntuario[id].error === true ? validaProntuario[id].message : ''}
  // </div>


  return (
    <div className="CardTextoCurto-container" >
      {/* {props.tempId} */}
      {/* <ListIcon className="icon-list-card-input" /> */}
      <div className="container-card-input">

        <div className="titleEscale">
          <h3 className="h3-title-card-input">Craniometria</h3>

        </div>
        {/* {id} */}

        <div className="img-class">
          <img src={osteopatiapediatrica} alt="" />
        </div>


        <div>
          {respEdit ?
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              {options.map((item, index) => {
                return (
                  <TextField
                    disabled={respView}
                    label={item.text}
                    style={{ width: '24%', marginTop: '50px' }}
                    name={item.text}
                    className='inputdm'
                    onChange={handlerChange2}
                    value={OptionValues[index]?.value}
                    variant="outlined"
                    // disabled={respEdit}
                  />
                )
              })}
            </div>

            : <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              {options.map((item, index) => {
                return (
                  <TextField
                    disabled={respView}
                    label={item.text}
                    style={{ width: '24%', marginTop: '25px' }}
                    name={item.text}
                    className='inputdm'
                    onChange={handlerChange2}
                    value={OptionValues[index]?.value}
                    variant="outlined"
                    // disabled={respEdit}
                  />
                )
              })}
            </div>}

        </div>

        <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
        { error === true ? '* Preencha o campo' : ''}  
        </div>
      </div>
    </div>
  );
}