import React, {useState, useEffect} from 'react';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputLabel({id ,title , required, edit}) {


    const [label, setLabel] = useState('');
    const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario , respView , validaProntuario , setvalidaProntuario , NameProntuario, setNameProntuario, validation, setValidation,
    attValidation, setAttValidation, attEncerraError } = StateGlobal();
    const [req, setReq] = useState(required);
    const [error, setError] = useState(false)


  const handleChangeInput = event => {
    const { value } = event.target;

    Answer.map((item, index) => {
      if (item.question_id === id) {
        setLabel(value)
        Answer[index].answer = value

        if(req){
          if( !Answer[index].answer ){
            setError(true)
          }
          else{
            setError(false)
          }
        }

      }
    })
    const array = MontagemProntuario

    array[PosProntuario].montagem.map((item, index) => {
      if (item.tempId === id) {

        item.answer = value
      }

    })

    setMontagemProntuario(array)

  };

  
  useEffect(() => {
    if(validation) {
      
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
         
          if(comp.id === id){
   
            setError(validation[index].components[i].error)
              
          }
        })
      })

    }
  }, [attEncerraError])

  useEffect(()=>{
    if(validation) {
      
      validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
           
            if(comp.id === id){
     
              validation[index].components[i].error = error 
              
                
            }
          })
      })
      setAttValidation(!attValidation)
    }

  }, [error])

  useEffect(() => {
    setLabel(edit)

    
  }, [id])




    useEffect(() => {
        Answer.map((item, index) => {
            if (item.question_id === id) {
              
       
                Answer[index].answer = title
            }
        })
    }, [id])
    
  return (
    <div>
      <h1 className='label-title'>{title}</h1>
    </div>
  );
}
