import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { StateGlobal } from '../../../ContextAPI/context';
import DadosPessoais from '../Components/DadosPessoais';
import conexao from '../../../Services/api';
import './style.css'
import Modal from '../Components/Modal/index';
import CircularLoad from '../../../Components/Load';
import { SportsPhysiotherapyContext } from '../../../ContextAPI/SportsPhysiotherapyContext' 
import { row } from '@syncfusion/ej2-react-grids';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo: {
    marginTop: '0rem',
    height: '87vh',
    padding: '2rem'
  },
  container: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  form: {
    width: '100%'
  },
  divEnvolveFotoDados: {
    display: 'flex'
  },
  divEnvolveTextos: {
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      color: '#8a8a8a'
    }
  },
  formControl: {
    minWidth: 50,
    width: '180px',
    marginRight: '20px'

  },
  avatar: {
    width: '60px',
    height: '60px',
    fontSize: '2rem'
  },
  tituloName: {
    fontSize: '1.3em',
    color: '#27b8d2 !important',
    marginBottom: '.3rem !important',
    fontWeight: '600'
  },
  tituloEtapa: {
    fontSize: '1.3em',
    color: '#757575 !important',
    marginBottom: '1rem !important',
    fontWeight: '600'
  },
  filtroData: {
    display: 'flex',
  },
  filtroResulo: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '2rem',
    height: 'fit-content',
    overflow: 'auto'
  },
  pSelecionarData: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  textField: {
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  table: {
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '2rem',
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(data, hora, prontuario) {
  return { data, hora, prontuario };
}


export default function ResumoPaciente(props) {
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [erro, setErro] = useState();
  const [dataInit, setDataInit] = useState();
  const [dataFim, setDataFim] = useState();
  const [attState, setAttState] = useState(false);
  const [iddelete, setIddelete] = useState();
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [formID, setFormID] = useState('');
  const [patientID, setPatientID] = useState('');

  const { 
    setSportsPhysiotherapyView,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView,
    setLoadSportsPhysiotherapyComponent 
  } = useContext(SportsPhysiotherapyContext)

  const { 
    pos, 
    setPos, 
    setMontagemProntuario, 
    respEdit, 
    setRespEdit, 
    setAnswer, 
    setRespView, 
    setPosProntuario, 
    Answer ,
    validaProntuario , 
    setvalidaProntuario ,
    NameProntuario, 
    setNameProntuario, 
    attResumo,
    setFormPatientID
  } = StateGlobal();

  // const patientID = localStorage.getItem('patientID')

  useEffect(() => {
    // localStorage.setItem('idEdicaoRP', '')
  }, [])

  useEffect(() => {
    // console.log('RESUMO')
    // console.log(props)
    if (!props.patientID) {
      // redireciona para a página anterior se não houver parâmetros
      history.goBack();
    }

    setPatientID(props.patientID)
  }, [props, history]);
  
   // obtém os valores dos parâmetros
  //  const { patientID } = props.location.state;

  useEffect(() => {
    if (patientID) {
      getHistorico()
    }
    
  }, [attResumo, patientID])

  function handleSportiveViewButton(row) {
    // localStorage.setItem('idEdicaoRP', row.id)
    props.selectForm(row.id)
    setSportsPhysiotherapyChartAnswers(JSON.parse(row.data))
    setSportsPhysiotherapyView(true)
    setLoadSportsPhysiotherapyComponent(true)
  }

  function handleSportiveEditButton(row) {
    // localStorage.setItem('idEdicaoRP', row.id)
    props.selectForm(row.id)
    setSportsPhysiotherapyChartAnswers(JSON.parse(row.data))
    setSportsPhysiotherapyView(false)
    setLoadSportsPhysiotherapyComponent(true)
  }

  function deleteItem() {
    // Aqui eu vejo se apago um prontuário ou um info
    const row = rows.find(element => element['id'] == iddelete)

    if (row) {
      if(row.form) {
        deleteProntuario()
      } else {
        deleteInfo()
      }
    }

  }

  function deleteProntuario() {
    setLoad(true)
    // var url = '/form/patient/' : '/form/info/'
    conexao.delete('/form/patient/' + iddelete).then((res) => {      
      setTypeAlert('success')
      setMessage('Atendimento excluído com sucesso!')
      setTimeout(() => {
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setLoad(false)
        setMessage('')
        closemodal()
        window.location.reload() 
      }, 3000);
    }).catch((error) => {
      setTypeAlert('error')
      setMessage('Erro ao deletar!')
      setTimeout(() => {
        setLoad(false)
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setMessage('')
      }, 3000);
    })
  }

  function deleteInfo() {
    conexao.delete('/form/info/' + iddelete).then((res) => {
      setTypeAlert('success')
      setMessage('Atendimento excluído com sucesso!')
      setTimeout(() => {
        setLoad(false)
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setMessage('')
        closemodal()
        window.location.reload() 
      }, 3000);
    }).catch((error) => {
      setTypeAlert('error')
      setMessage('Erro ao deletar!')
      setTimeout(() => {
        setLoad(false)
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setMessage('')
      }, 3000);
    })
  }

  async function getHistorico() {
    
    conexao.post('/form/patient/' + patientID).then((res) => {
      if (res.data.data) {
        setRows(res.data.data)
        setIsLoading(false)
      }
    }).catch((error) => {
      setIsLoading(false)
    })

  }

  function closemodal() {
    setOpen(false)
  }

  function ArvoreComponents(id, edit) {

    conexao.get('/form/patient/answered/' + id).then((res) => {
      const arredit = []
      // console.log('DADOS_FORMULARIO')
      // console.log(res.data.data)

      res.data.data.steps.map((item, index) => {
        const idsessao = Math.floor(Math.random() * 256)

        const components = []
        item.questions.map((item) => {
          
          const typesImage = ['image']

          const typesOsteopatia = ['osteopatiapediatrica']
          const typesCurvadecrescimento = ['curvadecrescimento']
          //osteopatiapediatrica
          const typesMotor = ['desenvolvimentomotor']
          //desenvolvimento motor
          const typesOdonto = ['odontologico']
          //odontologico
          const typesEvas = ['escaladedor', 'musculoesqueletico', 'visceral', 'cranianovistainferior', 'cranianovistaanterior', 'cranianovistaposterior', 'cranianovistalateraldireita']
          //componentes mapas eva
          const types = ['textocurto', 'textolongo', 'data', 'numerico', 'telefone', 'email', 'label']
          //componentes basicos de texto


          typesOsteopatia.map((typeM) => {
           
            if (item.type === typeM) {
      
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          typesCurvadecrescimento.map((typeM) => {
           
            if (item.type === typeM) {
      
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          //COMPONENTES ESPECIAIS ODONTOLOGICO  EDITAR 
          typesMotor.map((typeM) => {

            if (item.type === typeM) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })


          typesOdonto.map((typeOdontologico) => {
           
            if (item.type === typeOdontologico) {
    
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          //COMPONENTES ESPECIAIS MAPA EVA  EDITAR 
          typesEvas.map((typeEva) => {
           
            if (item.type === typeEva) {
       
              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers ? item.answers : [], id_answer: item.answers.id, options: item.options })
            }

          })

          //COMPONENTES SIMPLES EDITAR
          types.map((itemtypes) => {
            if (item.type === itemtypes) {

              components.push({ type: item.type, component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: item.answers[0] ? item.answers[0].text : '' , id_answer: item.answers[0] ? item.answers[0].id : ''  })
            }
          })

          //componente selecao
          if (item.type === 'selecao') {
            const arrop = []
            if (item.options) {
              item.options.map((item) => {
                arrop.push({ text: item.text, value: item.id })
              })
            }
            components.push({ type: "selecao", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop, answers: item.answers })

          }
       
          //componente check
          if (item.type === 'check') {
     
            const arrop = []
            if (item.options) {
              // if (item.text) {
                item.options.map((item) => {
                  arrop.push({ text: item.text, value: item.id })
                })
              // }
            }
            components.push({ type: "check", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, opcoes: arrop, answers: item.answers})

          }
   
          if (item.type === 'image') {
            console.log("!!!")
            console.log(item.answers)
            var images = '';
            if  (Array.isArray(item.answers)) {
              if (item.answers[0])
                images = item.answers[0].image;
            }
            components.push({ type: "image", component: item.type, tempId: item.id, title: item.description, required: item.role, idsessao: idsessao, answer: images})
          }

        })

        //gera sessoes
        arredit.push({ componentsessao: item.title, id: idsessao, montagem: components, titulosessao: item.description })

        criaAnswer(arredit, edit)
        createValidate(arredit)
     
      })


      setNameProntuario(res.data.data.title)
      setMontagemProntuario(arredit)

      setPosProntuario(0)
      setPos(2)
      // IMPORTATE AVANCA PARA PROXIMA TELA
    })

    function createValidate(e) {//CRIAR ARRAY PARAR VALIDACAO DOS COMPONENTES DINAMICOS    

    
      // var valida =  {}
      // e.map((item) => {
      //   item.montagem.map((obj , index) => {

      //       valida = {...valida  , ... {[obj.tempId]:{question_id: obj.tempId , value: '', required: obj.required === '1' ? true : false, sessao: obj.idsessao, error: false, message: '* Reposta Obrigatória' }} }
          
        
      //     //console.log('OBJETO ===>', obj)
      //   })
      // })
    
  
      // setvalidaProntuario(valida)
  
    }



  }

  function criaAnswer(arredit, edit) { //MUITO IMPORTANTE PEGA VALORES DO BACK E CONVERTE PARA PRENCHER OS COMPONENETES DINAMICOS E ARMAZENAR SUAS UPDADTE NO ARRAY ANSWER DO CONTEXT API
    //OBS TALVEZ CRIAR ESSA FUNCAO NO RESUMO    PARA NAO PERDER O ESTADO
    const answers = []
    arredit.map((item) => {
      // if (item.montagem) {
      item.montagem.map((create) => {

        // if (edit) {
          
          if (create.type === 'check') {
            create.opcoes.map((opcao) => {
              answers.push({
                question_id: create.tempId,
                question_type: create.type,
                id_check: opcao.value,
                answer: create.answer !== undefined ? create.answer : '',
              })
            })  

            // create.answers.map((answer)=>{
            //   answers.push({
            //     question_id: create.tempId,
            //     question_type: create.type,
            //     answer: answer.form_step_question_option_id !== undefined ? answer.form_step_question_option_id : '',
            //     answer_id: answer.id,
            //   })
            // })
          }

          if (create.type === 'osteopatiapediatrica') {

            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              answer: create.answer !== undefined ? create.answer : [],
              form_step_question_option_id: ''
            })
          }

          if (create.type === 'curvadecrescimento') {

            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              answer: create.answer !== undefined ? create.answer : [],
              form_step_question_option_id: ''
            })
          }



          if (create.type === 'desenvolvimentomotor') {

            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              answer: create.answer !== undefined ? create.answer : [],
              form_step_question_option_id: ''
            })
          }


          if (create.type === 'odontologico') {

            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              answer: create.answer !== undefined ? create.answer : [],
              form_step_question_option_id: ''
            })
          }

          if (create.type === 'image') {
            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              answer: create.answer !== undefined ? create.answer : [],
            })
          }


          if (create.type === 'escaladedor' || create.type === 'cranianovistainferior' || create.type === 'musculoesqueletico' || create.type === 'visceral' || create.type === 'cranianovistaanterior' || create.type === 'cranianovistaposterior' || create.type === 'cranianovistalateraldireita') {

            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              answer: create.answer !== undefined ? create.answer : '',
              form_step_question_option_id: ''
            })
          }

          if (create.type === 'textocurto' || create.type === 'textolongo' || create.type === 'data' || create.type === 'numerico' || create.type === 'telefone' || create.type === 'email' || create.type === 'label') {
            answers.push({
              question_id: create.tempId,
              question_type: create.type,
              answer: create.answer !== undefined ? create.answer : '',
            })
          }

          if (create.type === 'selecao') {
            // console.log('CREATE')
            // console.log(create)
            // console.log(create.answers)
            create.answers.map((answer)=>{
              answers.push({
                question_id: create.tempId,
                question_type: create.type,
                answer: answer.form_step_question_option_id !== undefined ? answer.form_step_question_option_id : '',
                answer_id: answer.id,
              })
            })
          }

        // }
      })
    })
    setAnswer(answers.slice())
  }





  // async function getHistorico(){
  //   // const pacientID = localStorage.getItem('userId')
  //   console.log('USEEFECT RESUMO',)

  //   await conexao.post(`/form/patient/`+localStorage.getItem('patientID'))
  //   .then((resp) => {
  //     console.log('REPONSE FORM PATIENT' ,resp)

  //     const arr = resp.data.data

  //     const array = []

  //     arr.map((item)=>{

  //       const data = alterarData(item.created_at)

  //       let position =  createData(data[0], data[1], item.form.title)

  //       array.push(position)  

  //     })
  //     setRows(array)
  //     setIsLoading(false)

  //   }).catch((error) => {
  //    // console.log(error.response.data.message)
  //     //setErro(error.response.data.message)
  //    // setIsLoading(false)

  //   })
  // }

  function alterarData(data) {

    const arr = data.split(" ")

    const date = arr[0].split('-')
    const dateRev = date.reverse()

    const formatDate = `${dateRev[0]}/${dateRev[1]}/${dateRev[2]}`

    const hora = arr[1].split(":")
    const formatHora = `${hora[0]}:${hora[1]}`

    return [formatDate, formatHora]

  }


  async function filtro() {


    // const pacientID = localStorage.getItem('userId')
    if (dataInit && dataFim) {

      await conexao.post('/form/patient/' + patientID, {
        "started_at": dataInit,
        "finished_at": dataFim
      })
        .then((resp) => {

          // const arr = resp.data.data
          // const array = []
          // arr.map((item)=>{
          //   const data = alterarData(item.created_at)
          //   let position =  createData(data[0], data[1], item.form.title)
          //   array.push(position)
          // })
          // setRows(array)

          setIsLoading(false)
          setErro(false)

          setRows(resp.data.data)

        }).catch((error) => {

          setErro(true)
          setRows('')
          setIsLoading(false)


        })
    } else {
      getHistorico()
    }

  }


  return (
    <div className={classes.envolveResumo}>
      <ThemeProvider theme={theme}>
        <h3 className={classes.tituloEtapa}>Resumo</h3>
        <div>
          <DadosPessoais patientID={patientID}/>
          <div className={classes.filtroResulo}>
            <div className={classes.filtroData}>
              {/* <p className={classes.pSelecionarData}>Selecionar data:</p> */}
              <form className={classes.form} noValidate>
                <div className={classes.container}>
                  <div>
                    <TextField
                      id="date"
                      label="Data inicial"
                      type="date"
                      value={dataInit}
                      className={classes.formControl}
                      onChange={(e) => { setDataInit(e.target.value) }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="date"
                      label="Data final"
                      type="date"
                      value={dataFim}
                      className={classes.formControl}
                      onChange={(e) => { setDataFim(e.target.value) }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <Button style={{ margin: '0.5rem'}} variant="contained" className="color-primary" color="primary" onClick={() => { filtro() }}>
                      Filtrar
                                </Button>
                    <Button style={{ margin: '0.5rem' }} variant="contained" className="color-secondary" color="secondary" onClick={() => {
                      // getHistorico()
                      setDataInit('')
                      setDataFim('')
                    }}>
                      Limpar
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div>
            {isLoading ? <div className="div-load-lista-modelos div-load"><CircularLoad /></div> : 
              <TableContainer className={classes.table} component={Paper}>
                <Table size="small" aria-label="tabela resumo">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell align="center">Hora</TableCell>
                      <TableCell align="center">Prontuário Selecionado</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {
                      rows ?
                        rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {alterarData(row.created_at)[0]}
                            </TableCell>
                            <TableCell align="center">{alterarData(row.created_at)[1]}</TableCell>
                            {row.form ?
                              <TableCell align="center">{row.form !== null ? row.form.title: ''}</TableCell>
                            : 
                              <TableCell align="center">FISIOTERAPIA ESPORTIVA</TableCell>
                            }
                            <TableCell align="center">
                              <IconButton onClick={() => {
                                // Fisioterapia esportiva
                                if (!row.form) {
                                  handleSportiveViewButton(row)
                                  return
                                }

                                // localStorage.setItem('idEdicaoRP', row.id)
                                props.selectForm(row.id)
                                setRespView(true)
                                setRespEdit(true)  //importante  grava array com respostas no prontuario                        
                                // localStorage.setItem('answeredFormId', row.id)
                                setFormID(row.id)
                                ArvoreComponents(row.id, true)
                              }}>
                                <VisibilityIcon />
                              </IconButton>
                              
                              <IconButton onClick={() => {
                                // Fisioterapia esportiva
                                if (!row.form) {
                                  handleSportiveEditButton(row)
                                  return
                                }

                                // localStorage.setItem('idEdicaoRP', row.id)
                                props.selectForm(row.id)
                                setRespEdit(true)
                                setFormPatientID(row.form_id)
                                // localStorage.setItem('answeredFormId', row.id)
                                setFormID(row.id)
                                ArvoreComponents(row.id, true)
                              }}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => {
                                setIddelete(row.id)
                                setOpen(true)

                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableCell colSpan={5} className="erroDados">Registros não encontrados</TableCell>
                    }
                  </TableBody>

                </Table>
              </TableContainer>
            }
            </div>

          </div>
        </div>
      </ThemeProvider>
      <Modal desc={'Deseja deletar o atendimento?'} modalType="delete" titulo={'Clinic Plus'} delete={deleteItem} typeAlert={typeAlert} message={message} load={load} open={open} close={closemodal} />
    </div>

  );
}