import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import UndoIcon from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from "@material-ui/core/MenuItem"
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { StateGlobal } from '../../../../ContextAPI/context'
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularLoad from '../../../../Components/Load';
import conexao from '../../../../Services/api';
import ModalExcluir from '../../../../Components/Modais/ModalExclusao';
import ModalShare from '../../../../Components/Modais/ModalCompartilhar';
import '../css/Listagem.css';


const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '350px',

  },
  iconSearch: {
    color: '#8F8F8F'
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosProntos() {
  const classes = useStyles();
  const History = useHistory();
  const [modelosCriados, setModelosCriados] = useState([]);
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalShare, setOpenModalShare] = React.useState(false);
  const [nameModelo, setNameModelo] = useState('');
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [modeloId, setModeloId] = React.useState('');
  const [disshare, setDisShare] = useState(false);
  const [optionDelete, setOptionDelete] = React.useState('');
  const [basemodelos, setBaseModelos] = React.useState();



  const {
    idEditProntuario,
    setIdEditProntuario,
    viewProntuario, 
    setViewProntuario 
  } = StateGlobal();

  async function listaModeloCriado() {
    setLoad(true)
      
    var userId = !isNaN(localStorage.getItem('selectedUser')) ? localStorage.getItem('selectedUser') : localStorage.getItem('userId');

    await conexao.post(`/form/list/${userId}`, {
      "search": nameModelo,
    })
      .then((response) => {
        setLoad(false)
        setModelosCriados(response.data.data)
        setBaseModelos(response.data.data)
        if (response.data.data.length < 1) {
          setModelosCriados('')
          setTypeAlert('error');
          setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
          setTimeout(() => {
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
            setMessage('');
          }, 3000);
        }
      }).catch((error) => {
        setLoad(false)
        setModelosCriados('')
        setTypeAlert('error');
        setMessage('Você ainda não possui um modelo próprio. Clique em novo modelo para criar seu próprio modelo de prontuário!');
        setTimeout(() => {
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
          setMessage('');
        }, 5000);
      })
  }

  useEffect(() => {
    setNameModelo('')
    listaModeloCriado();
  }, [])

  useEffect(() => {
    if(nameModelo===''){
      setModelosCriados([])
      setBaseModelos([])
      listaModeloCriado()
    }

  },[nameModelo]);


  const handleModal = (value) => {
    setOptionDelete(value);
    if (value) {
      deletaModelo()
    } else {
      handleClose()
    }
  };

  const closeModalShare = (value) => {
    setOpenModalShare(false);
  };

  const handleClose = () => { setOpenModalDelete(false); }

  function compartilhaModelo(id) {
    setTitulo('Compartilhar Modelo');
    setDescricao('Tem certeza que deseja compartilhar esse prontuário?');
    setDisShare(false)
    setOpenModalShare(true);
    setModeloId(id)
  }

  function descompartilhaModelo(id) {
    setTitulo('Descompartilhar Modelo');
    setDescricao('Tem certeza que deseja descompartilhar esse prontuário?');
    setDisShare(true)
    setOpenModalShare(true);
    setModeloId(id)
  }

  function deleteModelo(id) {
    setTitulo('Exclusão Modelo');
    setDescricao('Deseja excluir esse modelo de prontuário?');
    setOpenModalDelete(true);
    setModeloId(id)
  }

  function editeModelo(e) {
    conexao.get('/form/' + e).then((res) => {
      setIdEditProntuario({ id: e, data: res.data.data })
      setViewProntuario(false)
      History.push('/novo-modelo')

    }).catch((error) => {

    })

  }

  function visualizarModelo(e){
    conexao.get('/form/' + e).then((res) => {

      setIdEditProntuario({ id: e, data: res.data.data })
      setViewProntuario(true)
      History.push('/novo-modelo')

    }).catch((error) => {

    })
  }


  async function shareModelo() {
    setLoad(true)
    await conexao.post(`/form/share/${modeloId}`)
      .then((response) => {
        setLoad(false)
        setOpenModalShare(false);
        setTypeAlert('success');
        setMessage('Modelo compartilhado com sucesso');
        setTimeout(() => {
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
          setMessage('');
        }, 1500);
        listaModeloCriado();
      })
  }

  async function deletaModelo() {
    setLoad(true)
    await conexao.delete(`/form/${modeloId}`)
      .then((response) => {
        setLoad(false)
        setOpenModalDelete(false);
        setTypeAlert('success');
        setMessage('Modelo excluído com sucesso');
        setTimeout(() => {
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
          setMessage('');
        }, 1500);
        listaModeloCriado();
      }).catch((error) => {
        setLoad(false)
        setOpenModalDelete(false);
        setTypeAlert('error');
        setMessage('Esse Prontuário não pode ser excluído pois está em uso!');
        listaModeloCriado();
        setTimeout(() => {
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
          setMessage('');
        }, 1500);
      })
  }



  function busca(event) {
    if (event.key === 'Enter') {
      setModelosCriados([])
      setBaseModelos([])
      listaModeloCriado()
    } if (event._reactName === 'onClick') {
      setModelosCriados([])
      setBaseModelos([])
      listaModeloCriado()
    }
    return true
  }


  return (
    <div className="">
        <FormControl className={clsx(classes.form)}>
          <InputBase
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch} onClick={(event) => busca(event)} /></IconButton>}</InputAdornment>}
            //labelWidth={0}
            disabled={load ? true : false}
            onKeyDown={busca}
            onChange={(e) => {setNameModelo(e.target.value)}}
            placeholder="Busque por um modelo"
            className={clsx(classes.search)}
          />
        </FormControl>
      <div>
        {load ?
          <div className="div-load-lista-modelos"><CircularLoad /></div>
          : ''}
          
        {modelosCriados ?
          modelosCriados.map((modeloCriado) => (
            <div className="CardModelos-envolve">
              <div className="CardModelos-envolve-text-icon">
                <AssignmentIcon className="CardModelos-icon-pront" />
                <p>{modeloCriado.title}</p>
              </div>
              <div>
                {/* <IconButton>
                  <VisibilityIcon onClick={() => { visualizarModelo((modeloCriado.id)) }}/>
                </IconButton> */}
                {modeloCriado.share_level != 0 ?
                
                  <IconButton onClick={() => { descompartilhaModelo(modeloCriado.id) }}>
                    <UndoIcon />
                  </IconButton>
                :
                  <IconButton onClick={() => { compartilhaModelo(modeloCriado.id) }}>
                    <ShareIcon />
                  </IconButton>
                }
                <IconButton onClick={() => { editeModelo(modeloCriado.id) }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => deleteModelo(modeloCriado.id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )) :
          <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''}
          </div>
        }
      </div>
      <ModalExcluir
        handleModal={handleModal}
        open={openModalDelete}
        load={load}
        titulo={titulo}
        descricao={descricao}
      />

      <ModalShare
        handleModal={closeModalShare}
        open={openModalShare}
        disshare={disshare}
        load={load}
        titulo={titulo}
        descricao={descricao}
        modeloId={modeloId}
        refresh={listaModeloCriado}
      />
    </div>
  );
}