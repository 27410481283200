import React, {useState, useEffect} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputCheckbox({ data ,id , title, answers, required}) {
    const [state, setState] = React.useState({});
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState('1');
    const [error, setError] = useState(false)
    const { Answer , setAnswer, respView, respEdit, validation, setValidation,
        attValidation, setAttValidation, attEncerraError } = StateGlobal();


      const handleChange = (event) => {
    
        setState({...state, [event.target.name]: event.target.checked  })
   
        let data = []
        options.map((item, index)=>{
            if (item.text) {
                if(event.target.name == item.value){

                    data = options
                    data[index].checked = event.target.checked 
                                        
                }
            }
        })

        Answer.map((item, index) => {
            if (item.question_id === id) {
               options.map((opcao)=>{
                if (opcao.text) {
                    if(opcao.checked){
                        if(opcao.value === item.id_check){
    
                            Answer[index].answer = opcao.value
                            
                        }
                    }else{
                        if(opcao.value === item.id_check){
    
                            Answer[index].answer = ''
                            
                        }
                    }
                }
                  
               })        

              
            }
       
        })

        setOptions(data)

        let err = []
        
        if(required){
            
            options.forEach( element => {

               err.push(element.checked)
                
            });

        }

        let val = err.indexOf(true)

        if(val >= 0){
            setError(false)
        }else{
            setError(true)
        }
          
       
      };

    
    useEffect(() => {
        
         
            let valor = []

                    
            data.opcoes.map((item)=>{
                
                let dat 
                dat = {...item, checked: false}
        
                if(data.answers){
                    if(data.answers[0]){
                        data.answers.map((ans)=>{
                          
                            if(ans.form_step_question_option_id == item.value){
                                
                                dat = {...item, checked: true}
                            
                            }
                            Answer.map((answer, index)=>{
                                
                                if(answer.id_check === ans.form_step_question_option_id){
                                 
                                    Answer[index].answer = ans.form_step_question_option_id
                                }
                            })
                        })
                        
                    }
                }
                valor.push(dat) 
            

            })

           
            setOptions(valor)
            
         
            
    }, [id])

    useEffect(()=>{
     
        let valor = []
        
            data.opcoes.map((item)=>{

                let dat 

                dat = {...item, checked: false}
       
                          
                Answer.forEach((ans)=>{
                  
                    if(ans.question_type == 'check'){
                    
                       if(ans.answer === item.value){
                            dat = {...item, checked: true}
                       }
                    }
                })

                valor.push(dat)

            })
            
            
           setOptions(valor)          
            
        
    },[])

    useEffect(()=>{
        if(validation) {
      
            validation.forEach((step, index)=>{
                step.components.forEach((comp, i)=>{        
                 
                  if(comp.id == id){
           
                    validation[index].components[i].error = error 
                    
                      
                  }
                })
            })
            setAttValidation(!attValidation)
        }
  
    }, [error])

    useEffect(() => {
        if(validation) {
      
            validation.forEach((step, index)=>{
              step.components.forEach((comp, i)=>{        
               
                if(comp.id == id){
         
                  setError(validation[index].components[i].error)
                    
                }
              })
            })
        }
  
    }, [attEncerraError])
    
  return (
        <div>
            <p className="titulo-form">{title}</p>
            <FormControl component="fieldset">
                <FormGroup aria-label="position" >
                    {options.map((item, index) => {
                        if (item.text) {
                            return(
                             <FormControlLabel
                                value={item.value}
                                name={item.value}
                                onChange={(e)=>{handleChange(e)}}
                                required={required}
                                control ={<Checkbox  color="primary" 
                                    // checked={state[item.value]}
                                    checked={options[index].checked}
                                />}
                                label={item.text}
                                labelPlacement="end"
                                disabled={respView}
                            />
                            )
                        }
                    })}
                                       
                </FormGroup>
            </FormControl>
            
            <div style={{ color: 'red', width: '100%', marginTop: '0px', fontSize: '15px' }}>
            {required && error === true ? '* Preencha o campo' : ''}  
            </div>
        </div>
  );
}
