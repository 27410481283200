import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textArea: {
    width: '100%',
    height: '1.1876em',
    padding: '18.5px 14px',
    borderRadius: '4px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '1rem',
    borderColor: 'rgba(0, 0, 0, 0.23)'
  }  
}));