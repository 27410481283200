import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import { TextField, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core/';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RightIcon from '@material-ui/icons/ArrowRight';
import LeftIcon from '@material-ui/icons/ArrowLeft';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { StateGlobal } from '../../../ContextAPI/context';
// import DadosPessoais from '../Components/DadosPessoais';
import conexao from '../../../Services/api';
import './style.css'
import Modal from './Modal'
import ModalCategoria from './ModalCategoria'
import ModalLancamento from './ModalLancamento'
import { currency, currencyNegative } from '../../../Components/ReduxTeste/Validate'
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import '../../../App.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  envolveResumo: {
    marginTop: '6rem',
    height: '87vh',
    padding: '2rem',
  },
  envolveDadosPaciente: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  divEnvolveFotoDados: {
    display: 'flex'
  },
  divEnvolveTextos: {
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '1rem',
      color: '#8a8a8a'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    fontSize: '2rem'
  },
  tituloName: {
    fontSize: '1.3em',
    color: '#27b8d2 !important',
    marginBottom: '.3rem !important',
    fontWeight: '600'
  },
  tituloEtapa: {
    fontSize: '1.3em',
    color: '#757575 !important',
    marginBottom: '1rem !important',
    fontWeight: '600'
  },
  filtroData: {
    display: 'flex',
    marginTop: '20px'
  },
  filtroResulo: {
    backgroundColor: 'white',
    marginTop: '2rem',
    padding: '2rem',
    height: '100%',
  },
  marginTop: {
    marginTop: '5rem'
  },
  pSelecionarData: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500'
  },
  pate: {
    marginRight: '1rem',
    color: '#757575',
    fontWeight: '500',
    marginLeft: '10px'
  },
  textField: {
    marginRight: '2rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  table: {
    boxShadow: '0px 0px 0px 0px grey',
    marginTop: '2rem',
  }
  ,
  topo: {
    display: 'flex',
    padding: '2rem',
    justifyContent: 'space-between',


  },
  buttonstopdireito: {
    marginRight: '10px'

  },
  select: {
    //  marginLeft:'10px'

  },
  formControl: {
    minWidth: 50,
    width: '180px',
    marginRight: '20px'

  },
  wrap: {
    whiteSpace: 'nowrap'
  },
  valor: {
    color: '#6c6c6c',
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '1.15rem'
  },
  alignRight: {
    textAlign: 'right'
  },
  paginator: {
    fontSize: 13,
    borderRadius: '8px',
    margin: '0.5rem'
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#27b8d2',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(data, hora, prontuario) {
  return { data, hora, prontuario };
}


export default function ResumoFinanceiro() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [dataInit, setDataInit] = useState();
  const [dataFim, setDataFim] = useState();
  const [iddelete, setIddelete] = useState();
  const [open, setOpen] = useState(false);
  const [dataarr, setDataarr] = useState();
  const [categoryId, setCategoryId] = useState('');

  const [status, setStatus] = useState('');
  const [procedureType, setProcedureType] = useState('');
  const [paymentTypeList, setPaymentTypeList] = useState();
  const [paymentType, setPaymentType] = useState('');
  const [paymentTypeId, setPaymentTypeId] = useState('');
  const [creator, setCreator] = useState('');

  const [opencategoria, setOpencategoria] = useState(false);
  const [openrecebimento, setOpenrecebimento] = useState(false);
  const [edit, setEdit] = useState({ open: false, data: '' });
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false)
  const [valorTotal, setValorTotal] = useState(0)
  const [valorTipo, setValorTipo] = useState('Total Geral')
  const [selectedItem, setSelectedItem] = useState('')
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [actualPage, setActualPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  useEffect(() => {
    setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
    // getHistorico()
    filter()
    getPaymentTypes()
  }, [])

  function getPaymentTypes()
  {
    conexao.get('finance/paymenttype').then((res) => {
      console.log('paymentTypeList')
      console.log(res.data.data)

      setPaymentTypeList(res.data.data)

    }).catch((error) => {
    })
  }

  function cleanEdit() {
    setEdit({ open: false, data: '' })
  }

  function getHistorico() {
    var userId = (parseInt(localStorage.getItem('selectedUser'))) ? localStorage.getItem('selectedUser') : 0;

    conexao.post('finance/filter/' + userId).then((res) => {
      if (res.data.data) {
        console.log('ROW')
        console.log(res.data.data.data.total)
        setLastPage(res.data.data.data.last_page)
        setRows(res.data.data.data.data)
        setDataarr(res.data.data.data.data)
        // calculaTotal(res.data.data.data.data)
        setValorTotal(res.data.data.data.total)
      }
    })
  }

  function calculaTotal(data) {
    setValorTotal(0)
    var tot = 0;
    data.forEach((item) => {
      console.log(item.type)
      if (item.type === 'Pagamento')
        tot -= item.value
      else
        tot += item.value
    })
    setValorTotal(tot)
  }

  function closemodal() {
    setOpen(false)
    filter()
  }

  function nextPage() {
    filter(actualPage + 1)
    setActualPage(actualPage + 1)
  }

  function previousPage() {
    if (actualPage > 1) {
      filter(actualPage - 1)
      setActualPage(actualPage - 1)
    }
  }

  function closemodalcategoria() {
    setOpencategoria(false)
  }

  function closemodalrecebimento() {
    console.log('closemodalrecebimento')
    // getHistorico()
    setSelectedItem('')
    filter()
    setOpenrecebimento(false)
  }

  function deleteFinanceiro() {
    setLoad(true)
    conexao.delete('/finance/' + iddelete).then((res) => {

      setLoad(false)
      setTypeAlert('success')
      setMessage('Lançamento excluído com sucesso!')
      setTimeout(() => {
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setMessage('')
        closemodal()
        setRows([])
        // getHistorico()
        filter()
      }, 3000);

    }).catch((error) => {
      setLoad(false)
      setTypeAlert('error')
      setMessage('Erro ao deletar!')
      setTimeout(() => {
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        setMessage('')
      }, 3000);
    })

  }

  function alterarData(data) {
    const arr = data.split(" ")
    const date = arr[0].split('-')
    const dateRev = date.reverse()
    const formatDate = `${dateRev[0]}/${dateRev[1]}/${dateRev[2]}`
    const hora = arr[1].split(":")
    const formatHora = `${hora[0]}:${hora[1]}`

    return [formatDate, formatHora]
  }

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  function filter(page) {
    const userId = localStorage.getItem('selectedUser')
    const payload = {
      "started_at": dataInit,
      "finished_at": dataFim,
      "finance_category_id": categoryId,
      "status": status,
      "creator": creator,
      "payment_type": paymentType,
      "payment_type_id": paymentTypeId,
      "page": page
    }

    conexao.post(`/finance/filter/${userId}`, payload)
    .then((res) => {
      setValorTipo(
        paymentType === '' 
          ? 'Total Geral' 
          : `Total ${capitalize(paymentType == 'C' ? 'Recebimentos' : 'Pagamentos')}`
      )

      console.log(res.data.data)
      setLastPage(res.data.data.data.last_page)
      setRows(res.data.data.data.data)
      setDataarr(res.data.data.data.data)
      // calculaTotal(res.data.data.data)
      console.log('TOTAL', res.data.data.total)
      setValorTotal(res.data.data.total)

      // tableRowArr.forEach((item) => {
      //   setValorTotal(prevState => (
      //     item.payment_type === 'D'
      //       ? prevState - item.value
      //       : prevState + item.value
      //   ))
      // })
    })
    .catch(() => {
      setValorTotal(0)
      setRows('')
    })
  }

  function cleanFilter() {
    const userId = localStorage.getItem('selectedUser')
    const payload = {
      "started_at": '',
      "finished_at": '',
      "finance_category_id": '',
      "status": '',
      "creator": '',
      "payment_type": '',
      "payment_type_id": '',
      "page": 1
    }

    conexao.post(`/finance/filter/${userId}`, payload)
    .then((res) => {
      setValorTipo('Total Geral')

      console.log(res.data.data)
      setLastPage(res.data.data.data.last_page)
      setRows(res.data.data.data.data)
      setDataarr(res.data.data.data.data)
      // calculaTotal(res.data.data.data)
      console.log('TOTAL', res.data.data.total)
      setValorTotal(res.data.data.total)
    })
    .catch(() => {
      setValorTotal(0)
      setRows('')
    })
  }
  const [openModal, setOpenModal] = useState(false);

  function closeModal(){
    conexao.post(`/statusModalUpdate/${localStorage.getItem('userIdModalUpdate')}`,
      {
        show_financeiro: "false", 
      }
    )
  }

  
  function GetModal(){
    conexao.get(`/getStatusPainel/${localStorage.getItem('userIdModalUpdate')}`).then((res) => {
      console.log(res.data.data.show_financeiro)
      setOpenModal(res.data.data.show_financeiro == "false" ? false : true)
    })
  }

  useEffect(() => {
    GetModal()
  }, [])

  return (
    <div className={localStorage.getItem('containerClass')}>


    <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='header-tutorial'> 
          <div>
            <span className='header-tutorial-title'>Tutorial - Financeiro</span>
          </div>
          <CloseIcon className='header-close-icon' onClick={() => {
            closeModal()
            setOpenModal(false)
          }}/>
        </div>

        <div className='header-body'>
          <iframe
            className='video-container'
            width="560"
            height="315"
            src="https://www.youtube.com/embed/wB-B94oqWgk?autoplay=1&controls=1"
            frameBorder="0"
            allow="acceleallowFullScreenrometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
     
        </div>
        <div className='desc-tutorial'>
          <span>No Financeiro do Clinic Plus você pode cadastrar categorias financeiras, consultar lançamentos por período, utilizar os filtros para visualizar a lista de pagamentos ou recebimentos e lançamentos pagos ou pendentes e também lançar os valores dos atendimentos direto pelo agendamento do paciente.</span>
        </div>
      </Dialog>

      <div className="card">

        <div className="ListagemModelo-prontuario-name-button">
          <div>
            <h1>Financeiro</h1>
            <h3 className={classes.valor}>{valorTipo}: R$ {currencyNegative(valorTotal)}</h3>
          </div>
          <div>          
            <Button disabled={localStorage.getItem('loginType') == 'EMPLOYEE' && (isNaN(selectedDoctor) || parseInt(selectedDoctor) == 0)} className={clsx(classes.buttonstopdireito, "color-primary")} variant="contained" color="primary" onClick={() => {
              setOpencategoria(true)

            }}>Cadastrar categoria</Button>
            <Button disabled={localStorage.getItem('loginType') == 'EMPLOYEE' && (isNaN(selectedDoctor) || parseInt(selectedDoctor) == 0)} onClick={() => {
              setOpenrecebimento(true)
              // setEdit({ open: false, data: '' })
            }} variant="contained" className="color-primary" color="primary">Novo Lançamento</Button>
          </div>
        </div>
        <div>
          {/* <DadosPessoais /> */}
          <div className={classes.filtroResulo}>
            <label>Filtrar por:</label>
            <div className={classes.filtroData}>
              <form className={classes.container} noValidate>
                <div>
                  <FormControl>
                  <TextField
                    id="date"
                    label="Data inicial"
                    type="date"
                    onChange={(e) => { setDataInit(e.target.value) }}
                    value={dataInit}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{marginRight: 25}}
                  />
                  </FormControl>

                  <FormControl>
                  <TextField
                    id="date"
                    label="Data final"
                    type="date"
                    value={dataFim}
                    className={classes.textField}
                    onChange={(e) => { setDataFim(e.target.value) }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </FormControl>
                 
                  <FormControl>
                    <InputLabel className={classes.select} id="demo-simple-select-helper-label">TIPO</InputLabel>

                    {/* {categoria} */}

                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.formControl}
                      value={paymentType}
                      onChange={(e) => { setPaymentType(e.target.value) }}
                    >
                      <MenuItem key={'vazio'} value={''}>...</MenuItem>
                      <MenuItem key={'1'} value={'D'}>PAGAMENTO</MenuItem>
                      <MenuItem key={'2'} value={'C'}>RECEBIMENTO</MenuItem>
                    </Select>
                  </FormControl>

                  {localStorage.getItem('loginType') == 'CLINIC' ? 
                    <FormControl>
                      <InputLabel className={classes.select} id="demo-simple-select-helper-label">TP. PROCEDIMENTO</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className={classes.formControl}
                        value={creator}
                        onChange={(e) => { setCreator(e.target.value) }}
                      >
                        <MenuItem key={'vazio'} value={''}>...</MenuItem>
                        <MenuItem key={'1'} value={'DOCTOR'}>CRIADO PELO PROFISSIONAL</MenuItem>
                        <MenuItem key={'2'} value={'CLINIC'}>CRIADO PELA CLÍNICA</MenuItem>
                      </Select>
                    </FormControl>
                  : ''}

                  <FormControl>
                    <InputLabel className={classes.select} id="demo-simple-select-helper-label">FRM. PAGAMENTO</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.formControl}
                      value={paymentTypeId}
                      onChange={(e) => { setPaymentTypeId(e.target.value) }}
                    >
                      <MenuItem key={'vazio'} value={''}>...</MenuItem>
                      {paymentTypeList ? paymentTypeList.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id} onClick={() => { setPaymentTypeId(item.id) }}>{item.name} </MenuItem>
                        )
                      }) : ''}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <InputLabel className={classes.select} id="demo-simple-select-helper-label">STATUS</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.formControl}
                      value={status}
                      onChange={(e) => { setStatus(e.target.value) }}
                    >
                      <MenuItem key={'vazio'} value={''}>...</MenuItem>
                      <MenuItem key={'1'} value={'PAGO'}>PAGO</MenuItem>
                      <MenuItem key={'2'} value={'PENDENTE'}>PENDENTE</MenuItem>
                    </Select>
                  </FormControl>

                </div>
                <div>
                  <Button
                    style={{ margin: '0.5rem', width: '100%' }}
                    className="color-primary"
                    variant="contained"
                    color="primary"
                    onClick={() => { filter() }}
                  >
                    Filtrar
                  </Button>

                  <Button
                    style={{ margin: '0.5rem', width: '100%' }}
                    className="color-secondary"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setDataInit('')
                      setDataFim('')
                      // getHistorico()

                      setPaymentType('')
                      setPaymentTypeId('')
                      setCreator('')
                      setStatus('')
                      setValorTipo('Total Geral')
                      
                      cleanFilter()
                    }}
                  >
                    Limpar
                  </Button>
                </div>
              </form>
            </div>
            <div>
            <div className={classes.alignRight}>
                <IconButton className={classes.paginator} onClick={previousPage} disabled={actualPage == 1}>
                  <LeftIcon /> <span>Anterior</span>
                </IconButton>
                <IconButton className={classes.paginator} onClick={nextPage} disabled={actualPage >= lastPage}>
                <span>Próxima</span> <RightIcon />
                </IconButton>
              </div>
              <TableContainer className={classes.table} component={Paper}>
                <Table size="small" aria-label="tabela resumo">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Vencimento</TableCell>
                      {localStorage.getItem('loginType') == 'CLINIC' ? 
                        <TableCell>Usuário</TableCell>
                      : ''}
                      <TableCell align="center">Tipo</TableCell>
                      <TableCell align="center">Descrição</TableCell>
                      <TableCell align="center">Valor</TableCell>
                      <TableCell align="center">Categoria</TableCell>
                      <TableCell align="center">Forma de Pagamento</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>


                  <TableBody>
                    {
                      rows ?
                        rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell component="th" scope="row">{alterarData(row.due_date)[0]}</TableCell>
                            {localStorage.getItem('loginType') == 'CLINIC' ? 
                              <TableCell>{row.user_name}</TableCell>
                            : ''}
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">{row.description}</TableCell>
                            <TableCell align="center" className={classes.wrap}>R$ {currency(row.value)} </TableCell>
                            <TableCell align="center">{row.category_name}</TableCell>
                            <TableCell align="center">{row.payment_type_name}</TableCell>
                            <TableCell align="center" >{row.status}</TableCell>
                            <TableCell className={classes.wrap} align="center">

                              <IconButton onClick={() => {
                                setOpenrecebimento(true)
                                setSelectedItem(row.id)
                              }}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => {

                                setOpen(true)
                                setIddelete(row.id)

                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableCell colSpan={5} className="erroDados">Registros não encontrados</TableCell>
                    }
                  </TableBody>

                </Table>
              </TableContainer>
              <div className={classes.alignRight}>
                <IconButton className={classes.paginator} onClick={previousPage} disabled={actualPage == 1}>
                  <LeftIcon /> <span>Anterior</span>
                </IconButton>
                <IconButton className={classes.paginator} onClick={nextPage} disabled={actualPage >= lastPage}>
                <span>Próxima</span> <RightIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <Modal desc={'Tem certeza que deseja deletar?'} typeAlert={typeAlert} message={message} load={load} titulo={'Financeiro'} delete={deleteFinanceiro} open={open} close={closemodal} />

        <ModalCategoria opencategoria={opencategoria} close={closemodalcategoria} />
        <ModalLancamento openrecebimento={openrecebimento} close={closemodalrecebimento} item={selectedItem} edit={edit} cleanedit={cleanEdit} />
      </div>
    </div>

  );
}