import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularLoad from '../../../Components/Load';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    
    // teste: {
    //     '& .MuiDialog-scrollPaper':{
    //       display: 'flex !important',
    //       justifyContent: 'center !important',
    //         flexDirection: 'column !important',
          
    // }
    // },
    
}));


const theme = createTheme({
    palette: {
      secondary: {
        light: '#E7366A',
        main: '#E7366A',
        dark: '#D93364',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
    },
  }, ptBR);

export default function Modal(props) {

    const [load, setLoad] = useState(props.load)
    const [open, setOpen] = useState(props.open)
    const classes = useStyles();
    const [typeAlert, setTypeAlert] = useState(props.typeAlert);
    const [message, setMessage] = useState(props.message);

    useEffect(() => {
        setLoad(props.load)
        setOpen(props.open)
        setTypeAlert(props.typeAlert);
        setMessage(props.message);
    }, [props])

    return (
        <div style={{width:'100%'}}>
            <ThemeProvider theme={theme} >
                <Dialog 
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{props.titulo}</DialogTitle>
                    <DialogContent >
                    {message ?
                        <div className="Modal-agendamento-alert">
                            <Alert variant="filled" severity={typeAlert}>
                            {message}
                            </Alert>
                        </div>
                    : ''}
                        <DialogContentText id="alert-dialog-description">
                            {props.desc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            props.close()
                            setOpen(false)

                        }} color="default">
                            Cancelar
                        </Button>
                        <Button onClick={() => {                
                                props.delete()
                        }} variant="contained" color="primary" className="color-primary" autoFocus>
                            {!load ? 'Sim' : <CircularLoad/>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>

        </div>
    );
}