
    export async function translate(){
        
    let TRANSLATIONS = {
        'en': {
          'Homepage': 'Homepage',
          'Monday': 'Monday',
          'Tuesday': 'Tuesday',
          'Wednesday': 'Wednesday',
          'Thursday': 'Thursday',
          'Friday': 'Friday',
          'Saturday': 'Saturday',
          'Sunday': 'Sunday',
          'Mon': 'Mon',
          'Tue': 'Tue',
          'Wed': 'Wed',
          'Thu': 'Thu',
          'Fri': 'Fri',
          'Sat': 'Sat',
          'Sun': 'Sun',
          'Mo': 'Mo',
          'Tu': 'Tu',
          'We': 'We',
          'Th': 'Th',
          'Fr': 'Fr',
          'Sa': 'Sa',
          'Su': 'Su',
          'Today': 'Hoje',
          'January': 'January',
          'February': 'February',
          'March': 'March',
          'April': 'April',
          // 'May': 'May',
          'June': 'June',
          'July': 'July',
          'August' : 'August',
          'September' : 'September',
          'October' : 'October',
          'November' : 'November',
          'December': 'December',
          'December 2021': 'Dezembro',
          'Jan': 'Jan',
          'Feb': 'Feb',
          'Mar': 'Mar',
          'Apr': 'Apr',
          // 'May': 'May',
          'Jun': 'Jun',
          'Jul': 'Jul',
          'Aug' : 'Aug',
          'Sep' : 'Sep',
          'Oct' : 'Oct',
          'Nov' : 'Nov',
          'Dec': 'Dec',
          
          'Jan 1': 'Jan 1',
          'Feb 1': 'Feb 1',
          'Mar 1': 'Mar 1',
          'Apr 1': 'Apr 1',
          'May 1': 'May 1',
          'Jun 1': 'Jun 1',
          'Jul 1': 'Jul 1',
          'Aug 1' : 'Aug 1',
          'Sep 1' : 'Sep 1',
          'Oct 1' : 'Oct 1',
          'Nov 1' : 'Nov 1',
          'Dec 1': 'Dec 1',
          'No events' : 'No events',

          '6:00 AM': '6:00 AM',
          '6:30 AM': '6:30 AM',
          '7:00 AM': '7:00 AM',
          '7:30 AM': '7:30 AM',
          '8:00 AM': '8:00 AM',
          '8:30 AM': '8:30 AM',
          '9:00 AM': '9:00 AM',
          '9:30 AM': '9:30 AM',
          '10:00 AM': '10:00 AM',
          '10:30 AM': '10:30 AM',
          '11:00 AM': '11:00 AM',
          '11:30 AM': '11:30 AM',
          '12:00 AM': '12:00 AM',
          
          '1:00 PM': '1:00 PM',
          '1:30 PM': '1:30 PM',
          '2:00 PM': '2:00 PM',
          '2:30 PM': '2:30 PM',
          '3:00 PM': '3:00 PM',
          '3:30 PM': '3:30 PM',
          '4:00 PM': '4:00 PM',
          '4:30 PM': '4:30 PM',
          '5:00 PM': '5:00 PM',
          '5:30 PM': '5:30 PM',
          '6:00 PM': '6:00 PM',
          '6:30 PM': '6:30 PM',
          '7:00 PM': '7:00 PM',
          '7:30 PM': '7:30 PM',
          '8:00 PM': '8:00 PM',
          '8:30 PM': '8:30 PM',
          '9:00 PM': '9:00 PM',
          '9:30 PM': '9:30 PM',
          '10:00 PM': '10:00 PM',
          '10:30 PM': '10:30 PM',
          '11:00 PM': '11:00 PM',
          '11:30 PM': '11:30 PM',

          'quantity': 'quantity',
          'answer' : 'answer'
          
        },
        'pt-br': {
          'Homepage': 'Pagina inicial',
          'Monday': 'Segunda',
          'Tuesday': 'Terça',
          'Wednesday': 'Quarta',
          'Thursday': 'Quinta',
          'Friday': 'Sexta',
          'Saturday': 'Sábado',
          'Sunday': 'Domingo',
          'Mon':'Segunda',
          'Tue': 'Terça',
          'Wed':'Quarta',
          'Thu': 'Quinta',
          'Fri': 'Sexta',
          'Sat': 'Sábado',
          'Sun': 'Domingo',
          'Mo': 'Seg',
          'Tu': 'Ter',
          'We': 'Qua',
          'Th': 'Qui',
          'Fr': 'Sex',
          'Sa': 'Sab',
          'Su': 'Dom',
          'Today': 'Hoje',
          'January': 'Janeiro',
          'February': 'Fevereiro',
          'March': 'Março',
          'April': 'Abril',
          'May': 'Maio',
          'June': 'Junho',
          'July': 'Julho',
          'August' : 'Agosto',
          'September' : 'Setembro',
          'October' : 'Outubro',
          'November' : 'Novembro',
          'December': 'Dezembro',

          'January 2011': 'Janeiro',
          'February 2011': 'Fevereiro',
          'March 2011': 'Março',
          'April 2011': 'Abril',
          'May 2011': 'Maio',
          'June 2011': 'Junho',
          'July 2011': 'Julho',
          'August 2011': 'Agosto',
          'September 2011': 'Setembro',
          'October 2011': 'Outubro',
          'November 2011': 'Novembro',
          'December 2011': 'Dezembro',

          'January 2012': 'Janeiro',
          'February 2012': 'Fevereiro',
          'March 2012': 'Março',
          'April 2012': 'Abril',
          'May 2012': 'Maio',
          'June 2012': 'Junho',
          'July 2012': 'Julho',
          'August 2012': 'Agosto',
          'September 2012': 'Setembro',
          'October 2012': 'Outubro',
          'November 2012': 'Novembro',
          'December 2012': 'Dezembro',

          'January 2013': 'Janeiro',
          'February 2013': 'Fevereiro',
          'March 2013': 'Março',
          'April 2013': 'Abril',
          'May 2013': 'Maio',
          'June 2013': 'Junho',
          'July 2013': 'Julho',
          'August 2013': 'Agosto',
          'September 2013': 'Setembro',
          'October 2013': 'Outubro',
          'November 2013': 'Novembro',
          'December 2013': 'Dezembro',

          'January 2014': 'Janeiro',
          'February 2014': 'Fevereiro',
          'March 2014': 'Março',
          'April 2014': 'Abril',
          'May 2014': 'Maio',
          'June 2014': 'Junho',
          'July 2014': 'Julho',
          'August 2014': 'Agosto',
          'September 2014': 'Setembro',
          'October 2014': 'Outubro',
          'November 2014': 'Novembro',
          'December 2014': 'Dezembro',

          'January 2015': 'Janeiro',
          'February 2015': 'Fevereiro',
          'March 2015': 'Março',
          'April 2015': 'Abril',
          'May 2015': 'Maio',
          'June 2015': 'Junho',
          'July 2015': 'Julho',
          'August 2015': 'Agosto',
          'September 2015': 'Setembro',
          'October 2015': 'Outubro',
          'November 2015': 'Novembro',
          'December 2015': 'Dezembro',

          'January 2016': 'Janeiro',
          'February 2016': 'Fevereiro',
          'March 2016': 'Março',
          'April 2016': 'Abril',
          'May 2016': 'Maio',
          'June 2016': 'Junho',
          'July 2016': 'Julho',
          'August 2016': 'Agosto',
          'September 2016': 'Setembro',
          'October 2016': 'Outubro',
          'November 2016': 'Novembro',
          'December 2016': 'Dezembro',

          'January 2017': 'Janeiro',
          'February 2017': 'Fevereiro',
          'March 2017': 'Março',
          'April 2017': 'Abril',
          'May 2017': 'Maio',
          'June 2017': 'Junho',
          'July 2017': 'Julho',
          'August 2017': 'Agosto',
          'September 2017': 'Setembro',
          'October 2017': 'Outubro',
          'November 2017': 'Novembro',
          'December 2017': 'Dezembro',

          'January 2018': 'Janeiro',
          'February 2018': 'Fevereiro',
          'March 2018': 'Março',
          'April 2018': 'Abril',
          'May 2018': 'Maio',
          'June 2018': 'Junho',
          'July 2018': 'Julho',
          'August 2018': 'Agosto',
          'September 2018': 'Setembro',
          'October 2018': 'Outubro',
          'November 2018': 'Novembro',
          'December 2018': 'Dezembro',

          'January 2019': 'Janeiro',
          'February 2019': 'Fevereiro',
          'March 2019': 'Março',
          'April 2019': 'Abril',
          'May 2019': 'Maio',
          'June 2019': 'Junho',
          'July 2019': 'Julho',
          'August 2019': 'Agosto',
          'September 2019': 'Setembro',
          'October 2019': 'Outubro',
          'November 2019': 'Novembro',
          'December 2019': 'Dezembro',

          'January 2020': 'Janeiro',
          'February 2020': 'Fevereiro',
          'March 2020': 'Março',
          'April 2020': 'Abril',
          'May 2020': 'Maio',
          'June 2020': 'Junho',
          'July 2020': 'Julho',
          'August 2020': 'Agosto',
          'September 2020': 'Setembro',
          'October 2020': 'Outubro',
          'November 2020': 'Novembro',
          'December 2020': 'Dezembro',
          
          'January 2021': 'Janeiro',
          'February 2021': 'Fevereiro',
          'March 2021': 'Março',
          'April 2021': 'Abril',
          'May 2021': 'Maio',
          'June 2021': 'Junho',
          'July 2021': 'Julho',
          'August 2021': 'Agosto',
          'September 2021': 'Setembro',
          'October 2021': 'Outubro',
          'November 2021': 'Novembro',
          'December 2021': 'Dezembro',

          'January 2022': 'Janeiro',
          'February 2022': 'Fevereiro',
          'March 2022': 'Março',
          'April 2022': 'Abril',
          'May 2022': 'Maio',
          'June 2022': 'Junho',
          'July 2022': 'Julho',
          'August 2022': 'Agosto',
          'September 2022': 'Setembro',
          'October 2022': 'Outubro',
          'November 2022': 'Novembro',
          'December 2022': 'Dezembro',

          'January 2023': 'Janeiro',
          'February 2023': 'Fevereiro',
          'March 2023': 'Março',
          'April 2023': 'Abril',
          'May 2023': 'Maio',
          'June 2023': 'Junho',
          'July 2023': 'Julho',
          'August 2023': 'Agosto',
          'September 2023': 'Setembro',
          'October 2023': 'Outubro',
          'November 2023': 'Novembro',
          'December 2023': 'Dezembro',

          'January 2024': 'Janeiro',
          'February 2024': 'Fevereiro',
          'March 2024': 'Março',
          'April 2024': 'Abril',
          'May 2024': 'Maio',
          'June 2024': 'Junho',
          'July 2024': 'Julho',
          'August 2024': 'Agosto',
          'September 2024': 'Setembro',
          'October 2024': 'Outubro',
          'November 2024': 'Novembro',
          'December 2024': 'Dezembro',
 
          'January 2025': 'Janeiro',
          'February 2025': 'Fevereiro',
          'March 2025': 'Março',
          'April 2025': 'Abril',
          'May 2025': 'Maio',
          'June 2025': 'Junho',
          'July 2025': 'Julho',
          'August 2025': 'Agosto',
          'September 2025': 'Setembro',
          'October 2025': 'Outubro',
          'November 2025': 'Novembro',
          'December 2025': 'Dezembro',

          'January 2026': 'Janeiro',
          'February 2026': 'Fevereiro',
          'March 2026': 'Março',
          'April 2026': 'Abril',
          'May 2026': 'Maio',
          'June 2026': 'Junho',
          'July 2026': 'Julho',
          'August 2026': 'Agosto',
          'September 2026': 'Setembro',
          'October 2026': 'Outubro',
          'November 2026': 'Novembro',
          'December 2026': 'Dezembro',

          'January 2027': 'Janeiro',
          'February 2027': 'Fevereiro',
          'March 2027': 'Março',
          'April 2027': 'Abril',
          'May 2027': 'Maio',
          'June 2027': 'Junho',
          'July 2027': 'Julho',
          'August 2027': 'Agosto',
          'September 2027': 'Setembro',
          'October 2027': 'Outubro',
          'November 2027': 'Novembro',
          'December 2027': 'Dezembro',

          'January 2028': 'Janeiro',
          'February 2028': 'Fevereiro',
          'March 2028': 'Março',
          'April 2028': 'Abril',
          'May 2028': 'Maio',
          'June 2028': 'Junho',
          'July 2028': 'Julho',
          'August 2028': 'Agosto',
          'September 2028': 'Setembro',
          'October 2028': 'Outubro',
          'November 2028': 'Novembro',
          'December 2028': 'Dezembro',

          'January 2029': 'Janeiro',
          'February 2029': 'Fevereiro',
          'March 2029': 'Março',
          'April 2029': 'Abril',
          'May 2029': 'Maio',
          'June 2029': 'Junho',
          'July 2029': 'Julho',
          'August 2029': 'Agosto',
          'September 2029': 'Setembro',
          'October 2029': 'Outubro',
          'November 2029': 'Novembro',
          'December 2029': 'Dezembro',

          'January 2030': 'Janeiro',
          'February 2030': 'Fevereiro',
          'March 2030': 'Março',
          'April 2030': 'Abril',
          'May 2030': 'Maio',
          'June 2030': 'Junho',
          'July 2030': 'Julho',
          'August 2030': 'Agosto',
          'September 2030': 'Setembro',
          'October 2030': 'Outubro',
          'November 2030': 'Novembro',
          'December 2030': 'Dezembro',

          'January 2031': 'Janeiro',
          'February 2031': 'Fevereiro',
          'March 2031': 'Março',
          'April 2031': 'Abril',
          'May 2031': 'Maio',
          'June 2031': 'Junho',
          'July 2031': 'Julho',
          'August 2031': 'Agosto',
          'September 2031': 'Setembro',
          'October 2031': 'Outubro',
          'November 2031': 'Novembro',
          'December 2031': 'Dezembro',


          'Jan': 'Jan',
          'Feb': 'Fev',
          'Mar': 'Mar',
          'Apr': 'Abr',
          // 'May': 'Maio',
          'Jun': 'Jun',
          'Jul': 'Jul',
          'Aug' : 'Ago',
          'Sep' : 'Set',
          'Oct' : 'Out',
          'Nov' : 'Nov',
          'Dec': 'Dez',

          'Jan 1': 'Jan 1',
          'Feb 1': 'Fev 1',
          'Mar 1': 'Mar 1',
          'Apr 1': 'Abr 1',
          'May 1': 'Maio 1',
          'Jun 1': 'Jun 1',
          'Jul 1': 'Jul 1',
          'Aug 1' : 'Ago 1',
          'Sep 1' : 'Set 1',
          'Oct 1' : 'Out 1',
          'Nov 1' : 'Nov 1',
          'Dec 1': 'Dez 1',
          'No events' : 'Sem eventos',

          '6:00 AM': '6:00',
          '6:30 AM': '6:30',
          '7:00 AM': '7:00',
          '7:30 AM': '7:30',
          '8:00 AM': '8:00',
          '8:30 AM': '8:30',
          '9:00 AM': '9:00',
          '9:30 AM': '9:30',
          '10:00 AM': '10:00',
          '10:30 AM': '10:30',
          '11:00 AM': '11:00',
          '11:30 AM': '11:30',
          '12:00 AM': '12:00',
          
          '1:00 PM': '13:00',
          '1:30 PM': '13:30',
          '2:00 PM': '14:00',
          '2:30 PM': '14:30',
          '3:00 PM': '15:00',
          '3:30 PM': '15:30',
          '4:00 PM': '16:00',
          '4:30 PM': '16:30',
          '5:00 PM': '17:00',
          '5:30 PM': '17:30',
          '6:00 PM': '18:00',
          '6:30 PM': '18:30',
          '7:00 PM': '19:00',
          '7:30 PM': '19:30',
          '8:00 PM': '20:00',
          '8:30 PM': '20:30',
          '9:00 PM': '21:00',
          '9:30 PM': '21:30',
          '10:00 PM': '22:00',
          '10:30 PM': '22:30',
          '11:00 PM': '23:00',
          '11:30 PM': '23:30',

          'quantity': 'quantidade',

          'answer' : 'resposta'


        }
    }

    var today = document.querySelectorAll('.e-tbar-btn-text')
   
    for(let i=0;  i < today.length; i++){

      if(today[i].innerHTML === 'Today'){
        today[i].style.display = 'none'
      }
    }

    let DEFAULT_LANG = 'en';
    let DATA_LANG_ELEMENTS = await document.querySelectorAll('[data-lang-str]');
    let DATA_SEMANA = await document.querySelectorAll('.e-header-day')
    let DATA_SEMANA_MES = await document.querySelectorAll('.e-header-cells')
    let SEMANA_CALENDARIO = await document.querySelectorAll('th')
    let TODAY = await document.querySelectorAll('.e-today')
    let MES_ANO =await document.querySelectorAll('.e-title')
    let MES_ANO_CALENDARIO =await document.querySelectorAll('.e-tbar-btn-text')
    let MES_GRID =await document.querySelectorAll('e-day')
    let DATA_AGENDA =await document.querySelectorAll('.e-m-day')
    let DATA_EVENTS = await document.querySelectorAll('.e-empty-event')
    let DATA_HEADER = await document.querySelectorAll('.e-date-header')
    let DATA_HOUR = await document.querySelectorAll('.e-time-slots')
    let GRAFIC_QUANTITY = await document.querySelectorAll('.recharts-tooltip-item-name')
    let GRAFIC_ANSWER = await document.querySelectorAll('.recharts-tooltip-item-name')
    let MES_SUB_CALENDARIO = await document.querySelectorAll('.e-day')

    await setProp()

  
    function setProp(){
    DATA_SEMANA.forEach((item)=>{
  
      let valores = item.innerHTML
      valores = valores.split(' ')
      item.setAttribute('data-lang-str', valores[0])
      if(valores[1]){
      item.setAttribute('data-lang-str-1', valores[1])
      }
        
    })
    DATA_SEMANA_MES.forEach((item)=>{
     
      item.setAttribute('data-lang-str', item.textContent)
      
    })
    SEMANA_CALENDARIO.forEach((item)=>{
        
      let valores = item.innerHTML
      valores = valores.split(' ')
      item.setAttribute('data-lang-str', valores[0])
      if(valores[1]){
      item.setAttribute('data-lang-str-1', valores[1])
      }
      
    })
    TODAY.forEach((item)=>{
        
      let valores = item.innerHTML
      valores = valores.split(' ')
      item.setAttribute('data-lang-str', valores[0])
      if(valores[1]){
      item.setAttribute('data-lang-str-1', valores[1])
      }
      
    })
    MES_ANO.forEach((item)=>{
      let valores = item.innerHTML
      valores = valores.split(' ')
      item.setAttribute('data-lang-str', valores[0])
      if(valores[1]){
      item.setAttribute('data-lang-str-1', valores[1])
      }
    })
    MES_ANO_CALENDARIO.forEach((item)=>{
      let valores = item.innerHTML
      valores = valores.split(' ')
      item.setAttribute('data-lang-str', valores[0])
      if(valores[1]){
        item.setAttribute('data-lang-str-1', valores[1])
      }
    })
    MES_GRID.forEach((item)=>{
        
      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    DATA_AGENDA.forEach((item)=>{

      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    DATA_EVENTS.forEach((item)=>{

      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    DATA_HEADER.forEach((item)=>{

      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    DATA_HOUR.forEach((item)=>{
      
      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    GRAFIC_QUANTITY.forEach((item)=>{
      
      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    GRAFIC_ANSWER.forEach((item)=>{
      
      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    MES_SUB_CALENDARIO.forEach((item)=>{
      
      item.setAttribute('data-lang-str', item.innerHTML)
      
    })
    }
    
    
    const translate = (lang = null) => {
        if (lang === null) {
          lang = DEFAULT_LANG;
        }
        // let DEFAULT_LANG_LENGTH = Object.keys(TRANSLATIONS[DEFAULT_LANG]).length;
   
        if (TRANSLATIONS[lang]) {

          DATA_LANG_ELEMENTS.forEach((el) => {
            
            let STR = el.getAttribute('data-lang-str');
            let STR1 = el.getAttribute('data-lang-str-1');

            if(STR1){
              if (TRANSLATIONS[lang][STR]) {
         
                el.innerText = (`${TRANSLATIONS[lang][STR]} ${[STR1]}`);
              }
            }else{
            if (TRANSLATIONS[lang][STR]) {
              el.innerText = (TRANSLATIONS[lang][STR]);
            }
            }
          });
        } else {
          // Não existe, então exibe a mensagem
        //   ALERT_TRANSLATION_INCOMPLETE.style.display = 'none';
        //   ALERT_TRANSLATION_UNAVAILABLE.style.display = 'block';
        }
      }

      let BTNS_TRANSLATE = document.querySelectorAll('[name=btn-translate]');
      BTNS_TRANSLATE.forEach((btn) => {
        btn.addEventListener('click', (ev) => {
          translate(btn.getAttribute('data-lang'));
        });
      });
      await translate("pt-br");

    }

 











