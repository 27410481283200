import React, { useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import '../../css/NovoModelo.css';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { FormatColorResetOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
       width: '97%',
    },
  },
}));



export default function CardTextoCurto(props) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();
  const [required, setRequirede] = useState(false);
  const [checked, setcheck] = useState(false)
  const [titulocampo , setTituloCampo] =useState();
  const [view, setView] = useState();



  function onchange(e) {
    setTituloCampo(e.target.value)
    const { name, value } = e.target;



    montagemstate.map((item, index) => {
      if (props.tempId === item.tempId) {
        montagemstate[index].title = value

        setMontagem([...montagemstate])

      }
    })

  }

  const {
    montagemstate,
    setMontagem,
    setIdcomponentAtual,
    setAtt,
    att,
    idComponentAtual,
    sessaostate,
    viewProntuario
  } = StateGlobal();

  useEffect(() => {

   montagemstate.map((item) => {
    if(item.tempId === props.tempId){
      
      setTituloCampo(item.title)
      if(item.required == 0){
        setcheck(false)
      }else{
        setcheck(true)
      }
      


      if(viewProntuario){
        setView(true)
      }
   
    }
    })



  }, [idComponentAtual])




 useEffect(()=>{
 

 },[montagemstate])



  return (
    <div className="CardTextoCurto-container" >
      {/* {props.tempId} */}
      <ListIcon className="icon-list-card-input" />
      <div className="container-card-input">
        <h3 className="h3-title-card-input">TEXTO CURTO</h3>

     
        <form className={classes.root} onSubmit={(e) => e.preventDefault()} noValidate autoComplete="off">
          <TextField 
          id="tituloDoCampo"
           name={props.tempId} value={titulocampo} 
           onChange={onchange}
            label="Título do campo"
             variant="outlined" 
             error={ montagemstate.filter(item => item.tempId === props.tempId && item.title === "").length !== 0 ? true: false}
             helperText={montagemstate.filter(item => item.tempId === props.tempId && item.title === "").length !== 0 ? 'Titulo Obrigatório': ''}
             disabled={view ? true : false}
             />
             
        </form>


        <div className="Card-option">
          <IconButton name={props.tempId} 
          disabled={view ? true : false}
          onClick={() => {
            montagemstate.map((item, index) => {
              if (props.tempId === item.tempId) {
                const array = montagemstate
                array.splice(index, 1)

                setMontagem(array)
                setAtt(!att)

              }
            })
          }}>
            <DeleteIcon />
          </IconButton>
          <div className="divisor"></div>
          {/* {checked ? 'true ' : 'false'} */}
          <FormControlLabel
            className="label-color"
            name='switch'
            onChange={() => {
              setcheck(!checked)
              
              montagemstate.map((item, index) => {
                if (props.tempId === item.tempId) {

                  item.required = !checked

                }
              })

            }}
            checked={checked}
           
            control={<Switch color="primary" />}
            label="Obrigatório"
            labelPlacement="start"
            disabled={view ? true : false}

          />
          

        </div>
      </div>
    </div>
  );
}