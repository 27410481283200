import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  steps: {
    margin: 0,
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: 'white'
  },

  steps__content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0px 6px 10px 0px #0000000F',
  },

  content: {
    width: '100%',
    borderBottom: '1px solid #adacac1f',

    '& h2': {
      color: '#27b8d2',
      fontSize: '1.1em',
      padding: '.7rem',
    }
  }
}));