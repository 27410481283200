import React, {useState} from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Logo from '../Assets/Images/LogoCP.svg';
import Btngoogle from '../Components/Google';
import './first-page.css';

import { FaPlus } from "react-icons/fa6";

const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function FirstLogin() {
  const [openEsqueci, setOpenEsqueci] = useState(false);

  const closeModalEsqueci = () => {
    setOpenEsqueci(false);
  }


  return (
       <ThemeProvider theme={theme}>
        <div className="login-background-image">
            <div className="login-card-form-login-first-page">
                <img src={Logo} className="Login-logo"/>
                <span className='title'>Experimente GRATUITAMENTE a plataforma Clinic Plus!</span>
                <span className='span-sub-title'>7 dias para você provar uma revolução tecnológica no seu negócio.</span>

                <div className='list-beneficio-container'>
                    <div className='list-beneficio-item'>
                        <FaPlus className='icon-list-beneficio'/>
                        <span>Saúde financeira em dia! Controle entradas e saídas</span>
                    </div>
                    <div className='list-beneficio-item'>
                        <FaPlus className='icon-list-beneficio'/>
                        <span>Visualize a evolução dos pacientes em gráficos</span>
                    </div>
                    <div className='list-beneficio-item'>
                        <FaPlus className='icon-list-beneficio'/>
                        <span>Crie prontuários 100% personalizados</span>
                    </div>
                    <div className='list-beneficio-item'>
                        <FaPlus className='icon-list-beneficio'/>
                        <span>Automatize cálculos de avaliações funcionais</span>
                    </div>
                    <div className='list-beneficio-item'>
                        <FaPlus className='icon-list-beneficio'/>
                        <span>Agendamentos recorrentes customizáveis</span>
                    </div>
                    <div className='list-beneficio-item'>
                        <FaPlus className='icon-list-beneficio'/>
                        <span>Compartilhe prontuários com outros profissionais</span>
                    </div>
                </div>

                <div className="trial">
                    <p>Escolha uma das duas opções abaixo que se encaixa no seu modelo de atendimento atual</p>
                    <div class="Login-div-row">
                        <Button 
                            href="/cadastro" 
                            className="signup-button" >
                            Consultório
                        </Button>

                        <Button 
                            href="/cadastro-clinica" 
                            className="signup-button">
                            Clínica
                        </Button>
                    </div>
                </div>
                <a href="/login" className='redirecionar-login'>Clique aqui para fazer Login!</a>
            </div>
        </div>
      </ThemeProvider>
  );
}