import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  lysholm: {
    '& article': {
      marginTop: '10px'
    }
  },
  
  score: {
    marginTop: '5px',
    fontWeight: '500'
  }
}));