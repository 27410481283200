import * as React from 'react';
import "./css/TabelaAgendamento.css"
import { translate } from '../../../../Components/Tradutor' 

import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, ViewsDirective, ViewDirective} from '@syncfusion/ej2-react-schedule';

import { extend } from '@syncfusion/ej2-base';
import conexao from '../../../../Services/api'

// import { loadCldr} from '@syncfusion/ej2-base';

// loadCldr(
//     require('cldr-data/supplemental/numberingSystems.json'),
//     require('cldr-data/main/pt/ca-gregorian.json'),
//     require('cldr-data/main/pt/numbers.json'),
//     require('cldr-data/main/pt/timeZoneNames.json')
//     );
//
    
class TabelaAgenda extends React.Component {
    constructor(props) {
        super(...arguments, props);
        this.state = {
            listando: '',          
          
        };

      
          this.data =  this.props.agenda     
             
        
    
          function getData(){
              const now = new Date()
              const date = `${now.getFullYear()},${now.getMonth()},${now.getDate()}`   
              this.dataAtual = date

        }

        setInterval(() => {
            translate()
        }, 100);      
       
                    
    }
      
   
    onEventRendered(args) {
        if(args.data.type == "AGENDADA" || 'AGENDADO'){     
          
            args.element.classList.add('agendamento');
            var att = document.createAttribute("data-id-agenda"); 
            att.value = args.data.id
            args.element.setAttributeNode(att);

        }
        if(args.data.type == 'BLOQUEADO' || args.data.type == 'BLOQUEADA'){
            args.element.classList.add('agendamento-bloqueado');
            var att = document.createAttribute("data-id-agenda-bloqueada"); 
            att.value = args.data.id
            args.element.setAttributeNode(att);
        }

        if(args.data.type == 'REALIZADO' || args.data.type == 'REALIZADA'){
            args.element.classList.add('agendamento-realizado');
            var att = document.createAttribute("data-id-agenda-realizada"); 
            att.value = args.data.id
            args.element.setAttributeNode(att);
        }

        if(args.data.type == 'CANCELADO' || args.data.type == 'CANCELADA'){
            args.element.classList.add('agendamento-cancelado');
            var att = document.createAttribute("data-id-agenda-cancelado"); 
            att.value = args.data.id
            args.element.setAttributeNode(att);
        }

        
    }

    componentDidUpdate(){
        this.data =  this.props.agenda

        
       
    }
     
 

    render() {  
               
        // locale='pt' 
        return <ScheduleComponent 
        // aria-hidden="true"
        height='100%'
        // onfocus={this.dataAtual}
        selectedDate={this.dataAtual} 
        eventSettings={{dataSource: this.props.agenda}} 
        eventRendered={this.onEventRendered.bind(this)} 
        popupOpen={this.props.modalteste}
        >
            
            
            <ViewsDirective >
                 <ViewDirective option='Week'  dateFormat='dd-MM-yyyy' timeFormat="HH:mm" startHour='06:00' endHour='24:00'   timeScale={{ enable: true, slotCount: 2 }} displayName='SEMANA' />
                    <ViewDirective option='Month' displayName='MÊS' />
                    <ViewDirective option='Agenda' displayName='AGENDAMENTOS'/>
             </ViewsDirective>
             
     
   < Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
  
   </ScheduleComponent>
     
    }
}
;
export default TabelaAgenda;