import React, { useContext, useRef, useEffect } from 'react'

import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../../../ContextAPI/SportsPhysiotherapyContext'

export default function LimiarDeDorPorPressao() {
  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  const passiveShoulderFlexorsDom = useRef()
  const passiveShoulderFlexorsNdom = useRef()
  const activeShoulderFlexorsDom = useRef()
  const activeShoulderFlexorsNdom = useRef()
  const passiveElbowFlexorsDom = useRef()
  const passiveElbowFlexorsNdom = useRef()
  const activeElbowFlexorsDom = useRef()
  const activeElbowFlexorsNdom = useRef()
  const passiveWristFlexorsDom = useRef()
  const passiveWristFlexorsNdom = useRef()
  const activeWristFlexorsDom = useRef()
  const activeWristFlexorsNdom = useRef()
  const passiveHipFlexorsDom = useRef()
  const passiveHipFlexorsNdom = useRef()
  const activeHipFlexorsDom = useRef()
  const activeHipFlexorsNdom = useRef()
  const passiveKneeFlexorsDom = useRef()
  const passiveKneeFlexorsNdom = useRef()
  const activeKneeFlexorsDom = useRef()
  const activeKneeFlexorsNdom = useRef()
  const passiveShoulderExtendersDom = useRef()
  const passiveShoulderExtendersNdom = useRef()
  const activeShoulderExtendersDom = useRef()
  const activeShoulderExtendersNdom = useRef()
  const passiveElbowExtendersDom = useRef()
  const passiveElbowExtendersNdom = useRef()
  const activeElbowExtendersDom = useRef()
  const activeElbowExtendersNdom = useRef()
  const passiveWristExtendersDom = useRef()
  const passiveWristExtendersNdom = useRef()
  const activeWristExtendersDom = useRef()
  const activeWristExtendersNdom = useRef()
  const passiveHipExtendersDom = useRef()
  const passiveHipExtendersNdom = useRef()
  const activeHipExtendersDom = useRef()
  const activeHipExtendersNdom = useRef()
  const passiveKneeExtendersDom = useRef()
  const passiveKneeExtendersNdom = useRef()
  const activeKneeExtendersDom = useRef()
  const activeKneeExtendersNdom = useRef()
  const passiveShoulderAbductorsDom = useRef()
  const passiveShoulderAbductorsNdom = useRef()
  const activeShoulderAbductorsDom = useRef()
  const activeShoulderAbductorsNdom = useRef()
  const passiveShoulderAdductorsDom = useRef()
  const passiveShoulderAdductorsNdom = useRef()
  const activeShoulderAdductorsDom = useRef()
  const activeShoulderAdductorsNdom = useRef()
  const passiveHipAbductorsDom = useRef()
  const passiveHipAbductorsNdom = useRef()
  const activeHipAbductorsDom = useRef()
  const activeHipAbductorsNdom = useRef()
  const passiveHipAdductorsDom = useRef()
  const passiveHipAdductorsNdom = useRef()
  const activeHipAdductorsDom = useRef()
  const activeHipAdductorsNdom = useRef()
  const passiveMedialRotatorShoulderDom = useRef()
  const passiveMedialRotatorShoulderNdom = useRef()
  const activeMedialRotatorShoulderDom = useRef()
  const activeMedialRotatorShoulderNdom = useRef()
  const passiveShoulderLateralRotatorDom = useRef()
  const passiveShoulderLateralRotatorNdom = useRef()
  const activeShoulderLateralRotatorDom = useRef()
  const activeShoulderLateralRotatorNdom = useRef()
  const passiveMedialHipRotatorDom = useRef()
  const passiveMedialHipRotatorNdom = useRef()
  const activeMedialHipRotatorDom = useRef()
  const activeMedialHipRotatorNdom = useRef()
  const passiveLateralHipRotatorDom = useRef()
  const passiveLateralHipRotatorNdom = useRef()
  const activeLateralHipRotatorDom = useRef()
  const activeLateralHipRotatorNdom = useRef()
  const passiveAnkleDorsiflexionDom = useRef()
  const passiveAnkleDorsiflexionNdom = useRef()
  const activeAnkleDorsiflexionDom = useRef()
  const activeAnkleDorsiflexionNdom = useRef()
  const passiveAnklePlantarFlexionDom = useRef()
  const passiveAnklePlantarFlexionNdom = useRef()
  const activeAnklePlantarFlexionDom = useRef()
  const activeAnklePlantarFlexionNdom = useRef()

  useEffect(() => {
    let passiveShoulderFlexorsDomEl = passiveShoulderFlexorsDom.current
    let passiveShoulderFlexorsNdomEl = passiveShoulderFlexorsNdom.current
    let activeShoulderFlexorsDomEl = activeShoulderFlexorsDom.current
    let activeShoulderFlexorsNdomEl = activeShoulderFlexorsNdom.current
    let passiveElbowFlexorsDomEl = passiveElbowFlexorsDom.current
    let passiveElbowFlexorsNdomEl = passiveElbowFlexorsNdom.current
    let activeElbowFlexorsDomEl = activeElbowFlexorsDom.current
    let activeElbowFlexorsNdomEl = activeElbowFlexorsNdom.current
    let passiveWristFlexorsDomEl = passiveWristFlexorsDom.current
    let passiveWristFlexorsNdomEl = passiveWristFlexorsNdom.current
    let activeWristFlexorsDomEl = activeWristFlexorsDom.current
    let activeWristFlexorsNdomEl = activeWristFlexorsNdom.current
    let passiveHipFlexorsDomEl = passiveHipFlexorsDom.current
    let passiveHipFlexorsNdomEl = passiveHipFlexorsNdom.current
    let activeHipFlexorsDomEl = activeHipFlexorsDom.current
    let activeHipFlexorsNdomEl = activeHipFlexorsNdom.current
    let passiveKneeFlexorsDomEl = passiveKneeFlexorsDom.current
    let passiveKneeFlexorsNdomEl = passiveKneeFlexorsNdom.current
    let activeKneeFlexorsDomEl = activeKneeFlexorsDom.current
    let activeKneeFlexorsNdomEl = activeKneeFlexorsNdom.current
    let passiveShoulderExtendersDomEl = passiveShoulderExtendersDom.current
    let passiveShoulderExtendersNdomEl = passiveShoulderExtendersNdom.current
    let activeShoulderExtendersDomEl = activeShoulderExtendersDom.current
    let activeShoulderExtendersNdomEl = activeShoulderExtendersNdom.current
    let passiveElbowExtendersDomEl = passiveElbowExtendersDom.current
    let passiveElbowExtendersNdomEl = passiveElbowExtendersNdom.current
    let activeElbowExtendersDomEl = activeElbowExtendersDom.current
    let activeElbowExtendersNdomEl = activeElbowExtendersNdom.current
    let passiveWristExtendersDomEl = passiveWristExtendersDom.current
    let passiveWristExtendersNdomEl = passiveWristExtendersNdom.current
    let activeWristExtendersDomEl = activeWristExtendersDom.current
    let activeWristExtendersNdomEl = activeWristExtendersNdom.current
    let passiveHipExtendersDomEl = passiveHipExtendersDom.current
    let passiveHipExtendersNdomEl = passiveHipExtendersNdom.current
    let activeHipExtendersDomEl = activeHipExtendersDom.current
    let activeHipExtendersNdomEl = activeHipExtendersNdom.current
    let passiveKneeExtendersDomEl = passiveKneeExtendersDom.current
    let passiveKneeExtendersNdomEl = passiveKneeExtendersNdom.current
    let activeKneeExtendersDomEl = activeKneeExtendersDom.current
    let activeKneeExtendersNdomEl = activeKneeExtendersNdom.current
    let passiveShoulderAbductorsDomEl = passiveShoulderAbductorsDom.current
    let passiveShoulderAbductorsNdomEl = passiveShoulderAbductorsNdom.current
    let activeShoulderAbductorsDomEl = activeShoulderAbductorsDom.current
    let activeShoulderAbductorsNdomEl = activeShoulderAbductorsNdom.current
    let passiveShoulderAdductorsDomEl = passiveShoulderAdductorsDom.current
    let passiveShoulderAdductorsNdomEl = passiveShoulderAdductorsNdom.current
    let activeShoulderAdductorsDomEl = activeShoulderAdductorsDom.current
    let activeShoulderAdductorsNdomEl = activeShoulderAdductorsNdom.current
    let passiveHipAbductorsDomEl = passiveHipAbductorsDom.current
    let passiveHipAbductorsNdomEl = passiveHipAbductorsNdom.current
    let activeHipAbductorsDomEl = activeHipAbductorsDom.current
    let activeHipAbductorsNdomEl = activeHipAbductorsNdom.current
    let passiveHipAdductorsDomEl = passiveHipAdductorsDom.current
    let passiveHipAdductorsNdomEl = passiveHipAdductorsNdom.current
    let activeHipAdductorsDomEl = activeHipAdductorsDom.current
    let activeHipAdductorsNdomEl = activeHipAdductorsNdom.current
    let passiveMedialRotatorShoulderDomEl = passiveMedialRotatorShoulderDom.current
    let passiveMedialRotatorShoulderNdomEl = passiveMedialRotatorShoulderNdom.current
    let activeMedialRotatorShoulderDomEl = activeMedialRotatorShoulderDom.current
    let activeMedialRotatorShoulderNdomEl = activeMedialRotatorShoulderNdom.current
    let passiveShoulderLateralRotatorDomEl = passiveShoulderLateralRotatorDom.current
    let passiveShoulderLateralRotatorNdomEl = passiveShoulderLateralRotatorNdom.current
    let activeShoulderLateralRotatorDomEl = activeShoulderLateralRotatorDom.current
    let activeShoulderLateralRotatorNdomEl = activeShoulderLateralRotatorNdom.current
    let passiveMedialHipRotatorDomEl = passiveMedialHipRotatorDom.current
    let passiveMedialHipRotatorNdomEl = passiveMedialHipRotatorNdom.current
    let activeMedialHipRotatorDomEl = activeMedialHipRotatorDom.current
    let activeMedialHipRotatorNdomEl = activeMedialHipRotatorNdom.current
    let passiveLateralHipRotatorDomEl = passiveLateralHipRotatorDom.current
    let passiveLateralHipRotatorNdomEl = passiveLateralHipRotatorNdom.current
    let activeLateralHipRotatorDomEl = activeLateralHipRotatorDom.current
    let activeLateralHipRotatorNdomEl = activeLateralHipRotatorNdom.current
    let passiveAnkleDorsiflexionDomEl = passiveAnkleDorsiflexionDom.current
    let passiveAnkleDorsiflexionNdomEl = passiveAnkleDorsiflexionNdom.current
    let activeAnkleDorsiflexionDomEl = activeAnkleDorsiflexionDom.current
    let activeAnkleDorsiflexionNdomEl = activeAnkleDorsiflexionNdom.current
    let passiveAnklePlantarFlexionDomEl = passiveAnklePlantarFlexionDom.current
    let passiveAnklePlantarFlexionNdomEl = passiveAnklePlantarFlexionNdom.current
    let activeAnklePlantarFlexionDomEl = activeAnklePlantarFlexionDom.current
    let activeAnklePlantarFlexionNdomEl = activeAnklePlantarFlexionNdom.current

    return () => {
      setSportsPhysiotherapyChartAnswers(state => ({
        ...state,
        algometry: {
          ...state.algometry,
          passiveShoulderFlexorsDom: passiveShoulderFlexorsDomEl.value,
          passiveShoulderFlexorsNdom: passiveShoulderFlexorsNdomEl.value,
          activeShoulderFlexorsDom: activeShoulderFlexorsDomEl.value,
          activeShoulderFlexorsNdom: activeShoulderFlexorsNdomEl.value,
          passiveElbowFlexorsDom: passiveElbowFlexorsDomEl.value,
          passiveElbowFlexorsNdom: passiveElbowFlexorsNdomEl.value,
          activeElbowFlexorsDom: activeElbowFlexorsDomEl.value,
          activeElbowFlexorsNdom: activeElbowFlexorsNdomEl.value,
          passiveWristFlexorsDom: passiveWristFlexorsDomEl.value,
          passiveWristFlexorsNdom: passiveWristFlexorsNdomEl.value,
          activeWristFlexorsDom: activeWristFlexorsDomEl.value,
          activeWristFlexorsNdom: activeWristFlexorsNdomEl.value,
          passiveHipFlexorsDom: passiveHipFlexorsDomEl.value,
          passiveHipFlexorsNdom: passiveHipFlexorsNdomEl.value,
          activeHipFlexorsDom: activeHipFlexorsDomEl.value,
          activeHipFlexorsNdom: activeHipFlexorsNdomEl.value,
          passiveKneeFlexorsDom: passiveKneeFlexorsDomEl.value,
          passiveKneeFlexorsNdom: passiveKneeFlexorsNdomEl.value,
          activeKneeFlexorsDom: activeKneeFlexorsDomEl.value,
          activeKneeFlexorsNdom: activeKneeFlexorsNdomEl.value,
          passiveShoulderExtendersDom: passiveShoulderExtendersDomEl.value,
          passiveShoulderExtendersNdom: passiveShoulderExtendersNdomEl.value,
          activeShoulderExtendersDom: activeShoulderExtendersDomEl.value,
          activeShoulderExtendersNdom: activeShoulderExtendersNdomEl.value,
          passiveElbowExtendersDom: passiveElbowExtendersDomEl.value,
          passiveElbowExtendersNdom: passiveElbowExtendersNdomEl.value,
          activeElbowExtendersDom: activeElbowExtendersDomEl.value,
          activeElbowExtendersNdom: activeElbowExtendersNdomEl.value,
          passiveWristExtendersDom: passiveWristExtendersDomEl.value,
          passiveWristExtendersNdom: passiveWristExtendersNdomEl.value,
          activeWristExtendersDom: activeWristExtendersDomEl.value,
          activeWristExtendersNdom: activeWristExtendersNdomEl.value,
          passiveHipExtendersDom: passiveHipExtendersDomEl.value,
          passiveHipExtendersNdom: passiveHipExtendersNdomEl.value,
          activeHipExtendersDom: activeHipExtendersDomEl.value,
          activeHipExtendersNdom: activeHipExtendersNdomEl.value,
          passiveKneeExtendersDom: passiveKneeExtendersDomEl.value,
          passiveKneeExtendersNdom: passiveKneeExtendersNdomEl.value,
          activeKneeExtendersDom: activeKneeExtendersDomEl.value,
          activeKneeExtendersNdom: activeKneeExtendersNdomEl.value,
          passiveShoulderAbductorsDom: passiveShoulderAbductorsDomEl.value,
          passiveShoulderAbductorsNdom: passiveShoulderAbductorsNdomEl.value,
          activeShoulderAbductorsDom: activeShoulderAbductorsDomEl.value,
          activeShoulderAbductorsNdom: activeShoulderAbductorsNdomEl.value,
          passiveShoulderAdductorsDom: passiveShoulderAdductorsDomEl.value,
          passiveShoulderAdductorsNdom: passiveShoulderAdductorsNdomEl.value,
          activeShoulderAdductorsDom: activeShoulderAdductorsDomEl.value,
          activeShoulderAdductorsNdom: activeShoulderAdductorsNdomEl.value,
          passiveHipAbductorsDom: passiveHipAbductorsDomEl.value,
          passiveHipAbductorsNdom: passiveHipAbductorsNdomEl.value,
          activeHipAbductorsDom: activeHipAbductorsDomEl.value,
          activeHipAbductorsNdom: activeHipAbductorsNdomEl.value,
          passiveHipAdductorsDom: passiveHipAdductorsDomEl.value,
          passiveHipAdductorsNdom: passiveHipAdductorsNdomEl.value,
          activeHipAdductorsDom: activeHipAdductorsDomEl.value,
          activeHipAdductorsNdom: activeHipAdductorsNdomEl.value,
          passiveMedialRotatorShoulderDom: passiveMedialRotatorShoulderDomEl.value,
          passiveMedialRotatorShoulderNdom: passiveMedialRotatorShoulderNdomEl.value,
          activeMedialRotatorShoulderDom: activeMedialRotatorShoulderDomEl.value,
          activeMedialRotatorShoulderNdom: activeMedialRotatorShoulderNdomEl.value,
          passiveShoulderLateralRotatorDom: passiveShoulderLateralRotatorDomEl.value,
          passiveShoulderLateralRotatorNdom: passiveShoulderLateralRotatorNdomEl.value,
          activeShoulderLateralRotatorDom: activeShoulderLateralRotatorDomEl.value,
          activeShoulderLateralRotatorNdom: activeShoulderLateralRotatorNdomEl.value,
          passiveMedialHipRotatorDom: passiveMedialHipRotatorDomEl.value,
          passiveMedialHipRotatorNdom: passiveMedialHipRotatorNdomEl.value,
          activeMedialHipRotatorDom: activeMedialHipRotatorDomEl.value,
          activeMedialHipRotatorNdom: activeMedialHipRotatorNdomEl.value,
          passiveLateralHipRotatorDom: passiveLateralHipRotatorDomEl.value,
          passiveLateralHipRotatorNdom: passiveLateralHipRotatorNdomEl.value,
          activeLateralHipRotatorDom: activeLateralHipRotatorDomEl.value,
          activeLateralHipRotatorNdom: activeLateralHipRotatorNdomEl.value,
          passiveAnkleDorsiflexionDom: passiveAnkleDorsiflexionDomEl.value,
          passiveAnkleDorsiflexionNdom: passiveAnkleDorsiflexionNdomEl.value,
          activeAnkleDorsiflexionDom: activeAnkleDorsiflexionDomEl.value,
          activeAnkleDorsiflexionNdom: activeAnkleDorsiflexionNdomEl.value,
          passiveAnklePlantarFlexionDom: passiveAnklePlantarFlexionDomEl.value,
          passiveAnklePlantarFlexionNdom: passiveAnklePlantarFlexionNdomEl.value,
          activeAnklePlantarFlexionDom: activeAnklePlantarFlexionDomEl.value,
          activeAnklePlantarFlexionNdom: activeAnklePlantarFlexionNdomEl.value,
        }
      }))
    }
  }, [])

  return (
    <div>
      <h3 className="custom-title">Flexores de ombro: </h3>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />   

          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='NDOM'
            className='mt-2'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />   
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />   

          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />   
          
        </Grid>
      </Grid>

      <h3 className="custom-title">Flexores de Cotovelo: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveElbowFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveElbowFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          /> 

          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveElbowFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveElbowFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeElbowFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeElbowFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />

          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeElbowFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeElbowFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Flexores de Punho: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveWristFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveWristFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />

          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveWristFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveWristFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeWristFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeWristFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />

          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeWristFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeWristFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Flexores de Quadril: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Flexores de Joelho: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveKneeFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveKneeFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveKneeFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveKneeFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeKneeFlexorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeKneeFlexorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeKneeFlexorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeKneeFlexorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Extensores de Ombro: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Extensores de Cotovelo: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveElbowExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveElbowExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveElbowExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveElbowExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeElbowExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeElbowExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeElbowExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeElbowExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Extensores de Punho: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveWristExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveWristExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveWristExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveWristExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeWristExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeWristExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeWristExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeWristExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Extensores de Quadril: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Extensores de Joelho: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveKneeExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveKneeExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveKneeExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveKneeExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeKneeExtendersDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeKneeExtendersDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeKneeExtendersNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeKneeExtendersNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      
      <h3 className="custom-title">Abdutores de Ombro: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderAbductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderAbductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderAbductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderAbductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderAbductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderAbductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderAbductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderAbductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      
      <h3 className="custom-title">Adutores de Ombro: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderAdductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderAdductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
         <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderAdductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderAdductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderAdductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderAdductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderAdductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderAdductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Abdutores de Quadril: </h3>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipAbductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipAbductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipAbductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipAbductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipAbductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipAbductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipAbductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipAbductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Adutores de Quadril: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipAdductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipAdductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveHipAdductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveHipAdductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipAdductorsDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipAdductorsDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
         <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeHipAdductorsNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeHipAdductorsNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Rotador medial de ombro: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveMedialRotatorShoulderDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveMedialRotatorShoulderDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveMedialRotatorShoulderNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveMedialRotatorShoulderNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeMedialRotatorShoulderDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeMedialRotatorShoulderDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
           <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeMedialRotatorShoulderNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeMedialRotatorShoulderNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Rotador lateral de ombro: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderLateralRotatorDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderLateralRotatorDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveShoulderLateralRotatorNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveShoulderLateralRotatorNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderLateralRotatorDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderLateralRotatorDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeShoulderLateralRotatorNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeShoulderLateralRotatorNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Rotador medial de Quadril: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveMedialHipRotatorDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveMedialHipRotatorDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveMedialHipRotatorNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveMedialHipRotatorNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeMedialHipRotatorDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeMedialHipRotatorDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeMedialHipRotatorNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeMedialHipRotatorNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Rotador lateral de Quadril: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveLateralHipRotatorDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveLateralHipRotatorDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveLateralHipRotatorNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveLateralHipRotatorNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeLateralHipRotatorDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeLateralHipRotatorDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeLateralHipRotatorNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeLateralHipRotatorNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Dorsiflexão de Tornozelo: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveAnkleDorsiflexionDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveAnkleDorsiflexionDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveAnkleDorsiflexionNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveAnkleDorsiflexionNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeAnkleDorsiflexionDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeAnkleDorsiflexionDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeAnkleDorsiflexionNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeAnkleDorsiflexionNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>

      <h3 className="custom-title">Flexão plantar de Tornozelo: </h3>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Passivo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveAnklePlantarFlexionDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveAnklePlantarFlexionDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={passiveAnklePlantarFlexionNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.passiveAnklePlantarFlexionNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <p className="titulo-form">Ativo</p>
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            placeholder='DOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeAnklePlantarFlexionDom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeAnklePlantarFlexionDom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
          <TextField
            id="select"
            type="number"
            variant="outlined"
            fullWidth
            className='mt-2'
            placeholder='NDOM'
            disabled={sportsPhysiotherapyView}
            inputRef={activeAnklePlantarFlexionNdom}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.activeAnklePlantarFlexionNdom}
            InputProps={{
              endAdornment: <InputAdornment position="end">Grausº</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}