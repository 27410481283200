import React, { useContext } from 'react'

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';

import { useStyles } from './style'

export default function DiagnosticoFisioterapeutico({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  return (
    <div>
      <TextareaAutosize
        className={classes.textArea}
        aria-label="empty textarea"
        placeholder="..."
        disabled={sportsPhysiotherapyView}
        value={sportsPhysiotherapyChartAnswers.physiotherapeuticDiagnosis.diagnosis}
        onChange={e => setSportsPhysiotherapyChartAnswers(state => ({
          ...state,
          physiotherapeuticDiagnosis: {
            ...state.physiotherapeuticDiagnosis,
            diagnosis: e.target.value
          }
        }))}
      />

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
        </div>
      }
    </div>
  )
}