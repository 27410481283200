import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  muscleContainer: {
    marginTop: '10px'
  },

  relationshipResults: {
    display: 'flex',

    '& p': {
      fontWeight: 500,
      marginTop: '5px',
      marginRight: '10px'
    }
  }
})); 