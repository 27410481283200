import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StateGlobal } from '../../../../ContextAPI/context'
import clsx from 'clsx';
import { FiAlertCircle } from 'react-icons/fi'
import Badge from '@material-ui/core/Badge';


const useStyles = makeStyles((theme) => ({
  divSecao: {
    margin: 0,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  active: {
    backgroundColor: 'white'
  },
  titleSecao: {
    color: '#27b8d2',
    fontSize: '1.1em',
    padding: '.7rem',
  },
  divsessao: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 6px 10px 0px #0000000F'
  }
}));


export default function SecoesLateralEsquerda({ data, error }) {

  const classes = useStyles();

  const [valueError, setValueError] = useState()

  const { attValidation, setAttValidation, validaProntuario, validation  } = StateGlobal();

  useEffect(()=>{
    if(validation) {
      
      validation.forEach((item)=>{
  
        var arr = []
        if(item.id === data.id){
  
          item.components.forEach(element => {
            
            if(element.error === true){
              arr.push(element)
            }
          });
          
          setValueError(arr.length)
   
        }
      })
    }

  },[attValidation])

  function badge(){
    if(validation) {
      
      validation.forEach((item)=>{
        console.log(item)
        var arr = []
        if(item.id === data.id){
  
          item.components.forEach(element => {
            if(element.error === true){
              arr.push(element)
            }
          });
  
          return (arr.length)
          
        }
      })
    }
  }


  return (
    <div className={clsx(classes.divSecao, classes.active)}>


      <div className={classes.divsessao}>
        <div style={{ width: '100%' }}>
          <h2 className={classes.titleSecao}>{data.titulosessao}</h2>
        </div>

       {valueError > 0 ?
        <Badge color="secondary" badgeContent={valueError} >
          <FiAlertCircle style={{ fontSize: '30px', color: 'red' }} /> 
        </Badge>
        :''}


        {/* {Object.values(validaProntuario).find(item => item.sessao === data.id && item.error === true)? 

        <Badge color="secondary" badgeContent={Object.values(validaProntuario).filter(item => item.sessao === data.id && item.error === true).length > 0 ? Object.values(validaProntuario).filter(item => item.sessao === data.id && item.error === true).length : ''} >
          {Object.values(validaProntuario).find(item => item.sessao === data.id && item.error === true) ? <FiAlertCircle style={{ fontSize: '30px', color: 'red' }} /> : ""}
        </Badge>
        :''} */}


      </div>
    </div>
  );
}