import React, {useState, useEffect} from 'react';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { StateGlobal } from '../../../../../ContextAPI/context'
import Button from '@material-ui/core/Button';

import './css/style.css'
import { ContactlessOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
}));



export default function CardCheck(props) {
  const classes = useStyles();
  const history = useHistory();
  const [checked , setcheck] = useState(true);
  const [items, setItems] = useState([]);
  const [descricao, setDescricao] =  useState('');
  const [index, setIndex] = useState('');
  const [status, setStatus] = useState(false);
  const [titulocampo, setTituloCampo] = useState();

  const { 
    montagemstate,
    setMontagem,
    setIdcomponentAtual,
    setAtt ,
    att,
    viewProntuario,
    idComponentAtual,
 } = StateGlobal();
  
 
 function onchange(e) {
  setTituloCampo(e.target.value)
  const { name, value } = e.target;



  montagemstate.map((item, index) => {
    if (props.tempId === item.tempId) {
      montagemstate[index].title = value


      setMontagem([...montagemstate])

    }
  })

}

  useEffect(() => {

    setItems(items)
    setDescricao(descricao)

    montagemstate.map((item , index)=>{

      if(props.tempId === item.tempId){


        if(item.opcoes){
          setItems(item.opcoes)
        }
          montagemstate[index].options = items
          // montagemstate[index].opcoes = items

      
        
        setTituloCampo(item.title)
        if(item.required == 0){
          setcheck(false)
        }else{
          setcheck(true)
        }
      
      }
     
    })
 
  }, [status,props.id,items]);



  function onchangeOptions(e){
    setDescricao(e.target.value)
    
  }


  function handlerClick(e, index){
     
      setIndex(index)
      const lista = e.target.parentNode.children


      for(let i = 0; lista.length > i; i++){
        
        const id =  lista[i].id.slice(7)
      
       if(id == index){
        e.target.classList.add("option-selected")
       }else{
        lista[i].classList.remove("option-selected")
       }
      }
  }  
 
  function addOption(){
      const arr = items
      arr.push({ text: descricao, value: '' })

      setItems(arr)

      setDescricao('')

      montagemstate.map((item , index)=>{
  
        if(props.tempId === item.tempId){
         
          montagemstate[index].options = items
          montagemstate[index].opcoes = items
      
        }
           
      })

      
  }

  function deleteOption(){
     
      const arr = items
      arr.splice(index, 1 )
      setItems(arr)
      setDescricao('')
      document.getElementById(`option-${index}`).classList.remove("option-selected")
      setStatus(!status)

      montagemstate.map((item , index)=>{
  
        if(props.tempId === item.tempId){
         
          montagemstate[index].options = items
          montagemstate[index].opcoes = items
      
        }
           
      })
     
  }

  function editOption(){

      if(descricao){
        const arr = items
        const option = arr[index]
        setDescricao(option)
        arr[index] = { text: descricao, value: '' }
        setItems(arr)
        setDescricao('')
        setStatus(!status)

      }
      

  }


  
  
  return (
    <div className="CardTextoCurto-container" >

        <ListIcon className="icon-list-card-input"/>
        <div className="container-card-input">
                     
            <h3 className="h3-title-card-input">MÚLTIPLA SELEÇÃO</h3>
            <form className={classes.root} onSubmit={(e) => e.preventDefault()} noValidate autoComplete="off">
            <TextField id="tituloDoCampo"
              name={props.tempId}
              value={titulocampo}
              onChange={onchange}
              label="Título do campo"
              variant="outlined"
              error={montagemstate.filter(item => item.tempId === props.tempId && item.title === "").length !== 0 ? true : false}
              helperText={montagemstate.filter(item => item.tempId === props.tempId && item.title === "").length !== 0 ? 'Titulo Obrigatório' : ''}
              disabled={viewProntuario}
    
            />
            </form>             
            <h3 className="h3-title-card-input">OPÇÕES</h3>        
            <div className="options">
              <div className="options-inputs">
                <TextField 
                  id="tituloDoCampo" 
                  name="tituloOpcoes" 
                  value={descricao} 
                  onChange={onchangeOptions} 
                  label="Descrição" 
                  variant="outlined" 
                  className="option-title" 
                  disabled={viewProntuario}
                  inputProps={{ maxLength: 50 }}
                />
                <div className="options-buttons">
                <Button variant="contained" className="options-buttons-incluir" onClick={addOption} disabled={viewProntuario}>
                          INCLUIR
                </Button>
        
                <Button variant="contained" className="options-buttons-excluir" onClick={deleteOption} disabled={viewProntuario}>
                          EXCLUIR
                </Button>
                </div>
              </div>
            
                <div className={items[0] ? 'options-items' : 'options-items optionsError'}>
                  <ul>

                    {items ? 
                      items.map((dado, index) => {
                        const id = `option-${index}`  
                        return <li id={id} onClick={(e)=> {handlerClick(e, index)}}>{dado.text}</li> 
                                        
                      })               
                      

                    : ''}
                  
                  </ul>
                </div>
              
            </div>              
            <div className="Card-option">
                <IconButton name={props.tempId} 
                      disabled={viewProntuario}
                      onClick={()=>{
                      montagemstate.map((item , index)=>{
                        if(props.tempId === item.tempId){
                          const array = montagemstate
                          array.splice(index,1)

                          setMontagem(array)
                          setAtt(!att)
                  
                        }
                      })
                  
                  }}>
                    <DeleteIcon/>
                </IconButton>
                <div className="divisor"></div>
                <FormControlLabel
                    className="label-color"
                    name='switch'
                    onChange={(ev)=>{setcheck(ev.target.checked) 
               
                      montagemstate.map((item , index)=>{
                        if(props.tempId === item.tempId){
                      
                          montagemstate[index].required = ev.target.checked

                        }
                      })
                  
                    }}                  
                    value={checked}
                    checked={checked}
                    control={<Switch color="primary" />}
                    label="Obrigatório"
                    labelPlacement="start"
                    disabled={viewProntuario}
                    
                />
                
                
            </div>
        </div>
    </div>
  );
}