import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useHistory } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Logo from '../../Assets/Images/logoBranco.svg';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TodayIcon from '@material-ui/icons/Today';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BackupIcon from "@material-ui/icons/Backup";
import ModalSair from '../Modais/ModalSair';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { History } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ModalNotificacao from '../Modais/ModalNotificacao';
import ModalSuporte from '../Modais/ModalSuporte';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import './css/menuTopo.css';
import conexao from '../../Services/api'
// import { StateGlobal } from '../../ContextAPI/context';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SubHeader from './SubHeader';
import AdminAlert from '../AdminAlert';
const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      color: "#fff",
    }),
    backgroundColor: "#27b8d2",
    color: "#fff",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  subIcon: {
    marginLeft: "1rem",
  },
  iconVoltar: {
    color: "#fff",
  },
  text: {
    color: "#fff",
    textDecoration: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#27b8d2",
    color: "#fff",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  envolveNot: {
    height: '14vh',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: "#f9f9f9",
    },
  },
  pSN:{
    margin: '1rem',
    color: '#8f8f8f',
    fontSize: '1.1em'
  },
  envolveNotActive: {
    backgroundColor: '#27b8d217',
  },
  colorLateralNot: {
    width: '5px',
    height: '100%',
  },
  circuloNot: {
    backgroundColor: '#27b8d2',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
  envolveNotEData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '1rem',
    height: '100%',
    justifyContent: 'flex-end',
  },
  envolveCorTitle: {
    width: '75%',
    display: 'flex',
    height: '100%',
  },
  envolveTitle: {
    marginLeft: '1rem',
    width: '100%',
    '& h5': {
      fontSize: '1em',
      marginBottom: 0,
    },
    '& p': {
      fontSize: '.9em',
      marginTop: '0.7rem',
      marginBottom: '1rem',
    },
  },
  dataNot: {
    '& p': {
      marginBottom: '1rem',
      fontSize: '.8em',
      color: '#8e8e8e',
      marginTop: '1rem',
    },
  },

  titleModal: {
    fontSize: '1.4em',
    margin: '0',
    marginTop: '.5rem',
    marginBottom: '.5rem',
    color: '#4a4a4a',
  },

  divTitleModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #ececec',
  },

  colorAgendada: {
    backgroundColor: '#e13567'
  },
  colorSistema: {
    backgroundColor: '#b1b1b1'
  },
  colorCancelada: {
    backgroundColor: '#3f51b5'
  },
  envolveNotificacoes: {
    overflow: 'auto',
    height: '50vh',
  }
}));

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const [openGestao, setOpenGestao] = React.useState(false);
  // const [openGestaoTopo, setOpenGestaoTopo] = React.useState(false);
  const [openOutros, setOpenOutros] = React.useState(false);
  const [openOutrosTopo, setOpenOutrosTopo] = React.useState(false);
  // const [openPacientesTopo, setOpenPacientesTopo] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNot, setAnchorElNot] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMenuOpenNot = Boolean(anchorElNot);
  const [openModalNotificacao, setOpenModalNotificacao] = React.useState(false);
  const [openModalSuporte, setOpenModalSuporte] = React.useState(false);
  const [openModalSair, setOpenModalSair] = React.useState(false);
  const [load, setLoad] = useState(false)
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const History = useHistory();
  const [fotoPerfil, setFotoPerfil] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [clinicId, setClinicId] = React.useState(0);
  const [loginType, setLoginType] = React.useState(0);
  // const [optionNotificacao, setOptionNotificacao] = React.useState('');
  const [badge, setbadge] = useState();
  // const [controle, setControle] = useState(false)
  const [not, setNot] = useState()
  const [hasExpired, setHasExpired] = useState(false)
  const [showSubheader, setShowSubheader] = useState(true)
  const [adminAlert, setAdminAlert] = useState(true)

  // const { setIsEditPatient, notification, setNotification, notfyatt, setNotatt, attAgenda, setAttAgenda  } = StateGlobal()

  useEffect(() => {
    AttdadosSininho()

    setInterval(() => {
      AttdadosSininho()
    }, 180000);

  }, [])
  // }, [controle])

  useEffect(() => {
    setClinicId(parseInt(localStorage.getItem('clinicId')));
    setLoginType(localStorage.getItem('loginType'));
    setFotoPerfil(localStorage.getItem('avatar'));
    setNome(localStorage.getItem('nome'));
    setHasExpired(localStorage.getItem('planExpired'));
    setAdminAlert(localStorage.getItem('adminAlert'));

    var pagesWithoutSubheader = [
      '/planos',
      '/profissionais'
    ]
    
    if (pagesWithoutSubheader.find(page => page === window.location.pathname))
      setShowSubheader(false)

  })

  async function backup() {
    try {
      var userId = "";

      if (localStorage.getItem("userId")) {
        userId = localStorage.getItem("userId");
        const response = await conexao.get(`/upload/backup/${userId}`);
        console.log(response)
        window.open(`${process.env.REACT_APP_API_URL}/upload/backup/${userId}`, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  }

  function AttdadosSininho() {
    var qtd = 0;
    var userId = '';
    if (localStorage.getItem('userId')) {
      userId = localStorage.getItem('userId');

      conexao.get(`/notification/${userId}`).then((res) => {
        res.data.data.map((item) => {
          if (item.read === 0) {
            qtd = qtd + 1
          }

          return qtd;
        })

        setbadge(qtd)
        setNot(res.data.data)

      }).catch(function (error) {
        console.log(error)
      });
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const mudaOutros = () => {
    setOpenOutros(!openOutros);
    handleDrawerOpen();
  };

  const mudaOutrosTopo = () => {
    setOpenOutrosTopo(!openOutrosTopo);
  };


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuNotOpen = (event) => {
    setAnchorElNot(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMeuPerfil = () => {
    setAnchorEl(null);
    History.push('/editar-cadastro')
  };

  const handlePerfilClinica = () => {
    setAnchorEl(null);
    History.push('/editar-cadastro-clinica')
  };

  const handleMenuNotClose = () => {
    setAnchorElNot(null);
  };

  const handleModal = (value) => {
    // setOptionNotificacao(value);
    if (value) {
      exibeMensagem()
    } else {
      handleClose()
    }
  };

  const handleModalSuporte = (value) => {
    if (value) {
      exibeMensagem()
    } else {
      handleCloseSuporte()
    }
  };

  const handleModalSair = (value) => {
    if (value) {
      sair()
    } else {
      handleCloseSair()
    }
  };

  function exibeMensagem(title, message, id) {

    conexao.put('/notification/update/' + id, { read: 1 }).then((res) => {
      AttdadosSininho()
    }).catch((error) => {

    })

    setAnchorElNot(null);
    setTitulo(title ? title : '');
    setDescricao(message ? message : '');
    setOpenModalNotificacao(true);
  }

  function modalSuporte() {
    // setOpenModalSuporte(true);
    let a= document.createElement('a');
    a.target= '_blank';
    a.href= 'https://api.whatsapp.com/send?phone=5515981120505&text=Estou%20utilizando%20o%20Clinicplus%20e%20preciso%20de%20suporte%20t%C3%A9cnico%20!';
    a.click();
  }

  function modalSair() {
    setOpenModalSair(true);
  }

  const handleClose = () => {
    setOpenModalNotificacao(false);
  }

  const handleCloseSuporte = () => {
    setOpenModalSuporte(false);
  }
  const handleCloseSair = () => {
    setOpenModalSair(false);
  }
  const sair = () => {

    setLoad(true);
    conexao.post('/logout').then((res) => {
      setLoad(false)     
      localStorage.clear();
      History.push('/login');
     
    }).catch((error) => {
      setLoad(false)
      localStorage.clear();
      History.push('/login');

    })
  }

  const menuId = 'primary-search-account-menu';
  const menuIdNot = 'primary-search-not-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {localStorage.getItem('/perfil') === 'true' && localStorage.getItem('profileId') === '3' /* DOCTOR */ ?
        <MenuItem onClick={handleMeuPerfil}>Meu perfil</MenuItem>
      :''}
      {localStorage.getItem('/perfil') === 'true' && localStorage.getItem('profileId') === '2' /* CLINIC */ ?
        <MenuItem onClick={handlePerfilClinica}>Perfil da clínica</MenuItem>
      :''}
      <MenuItem onClick={modalSair}>Sair</MenuItem>
    </Menu>
  );

  const renderMenuNot = (
    <Menu
      PaperProps={{
        style: {
          height: '60vh',
          width: '62.9ch',
        },
      }}
      anchorEl={anchorElNot}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuIdNot}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpenNot}
      onClose={handleMenuNotClose}
    >
      <div className={classes.divTitleModal}>
        <h1 className={classes.titleModal}>Notificações</h1>
      </div>
      <div className={classes.envolveNotificacoes}>
        {not ?
          not.map((item) => {
            let color = 'colorCancelada'
            if (item.message) {
              if (item.message.type.id === 4) {
                color = 'colorSistema'
              }
              if (item.message.type.id === 3) {
                color = 'colorCancelada'
              }
              if (item.message.type.id === 2) {
                color = 'colorAgendada'
              }
              if (item.message.type.id === 1) {
                color = 'colorAgendada'
              }
            }
            return (
              <div
                className={item.read === 0 ? clsx(classes.envolveNot, classes.envolveNotActive) : classes.envolveNot}
                onClick={() =>
                  item.message ?
                    exibeMensagem(item.message.title, item.message.message, item.id) : ''}>
                <div className={classes.envolveCorTitle}>
                  <div className={clsx(classes.colorLateralNot, classes[color])}></div>
                  <div className={classes.envolveTitle}>
                    <h5>{item.message ? item.message.type.name : ''} </h5>
                    <p>{item.message ? item.message.title : ''} </p>
                  </div>
                </div>
                <div className={classes.envolveNotEData}>
                  <div ></div>
                  {item.read === 0 ?
                    <div className={classes.circuloNot}></div> : ""}
                  <div className={classes.dataNot}><p>{item ? item.send_at : ''}</p></div>
                </div>
              </div>)
          })
          :
          <div>
            <p className={classes.pSN}>Você ainda não possui notificações!</p>
          </div> 
          }
      </div>
    </Menu>
  );


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div className="menuTopo-envolve-logo-botoes">
            <div className="MenuTopo-logo-login">
              <Link to={localStorage.getItem('/painel') === 'true'?"/home":"agenda"}>
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="menuTopo-menu">
                {/* botar if aqui */}
                {!hasExpired ? 
                  <List className={"menuTopo-menu-lateral"}>
                    {localStorage.getItem('/painel') === 'true'?
                      <ListItem button className={classes.text}>
                        <Link to="/home" className={classes.textPrinc}><ListItemText primary="Painel" /></Link>
                      </ListItem>
                    :''}
                    {localStorage.getItem('/agenda') === 'true'?
                      <ListItem button className={classes.text}  >
                        <Link to="/agenda" className={classes.textPrinc}><ListItemText primary="Agenda" /></Link>
                      </ListItem>
                    :''}
                    {localStorage.getItem('/pacientes') === 'true'?
                      <ListItem button className={classes.text}>
                        <Link to="/pacientes" className={classes.textPrinc} onClick={() => {localStorage.setItem('iniciarAtendimento', 'null')}}><ListItemText primary="Pacientes" /></Link>
                      </ListItem>
                    :''}
                    {localStorage.getItem('/financeiro') === 'true'
                      ?
                      <ListItem button className={classes.text}>
                        <Link to="/financeiro" className={classes.textPrinc}><ListItemText primary="Financeiro" /></Link>
                      </ListItem>
                    : ''}
                    {localStorage.getItem('/profissionais') === 'true'
                      ?
                      <ListItem button className={classes.text}>
                        <Link to="/profissionais" className={classes.textPrinc}><ListItemText primary="Profissionais" /></Link>
                      </ListItem>
                    : ''}
                    {localStorage.getItem('/relatorios') === 'true'?
                      <ListItem button className={classes.text}>
                        <Link to="/relatorios" className={classes.textPrinc}><ListItemText primary="Relatórios" /></Link>
                      </ListItem>
                    :''}

                    {localStorage.getItem('trialExpired') ?
                      <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                        <Link to="/planos" className={classes.text}><ListItemText primary="Planos" /></Link>
                      </ListItem>
                    :
                      <div>
                        <ListItem button onClick={mudaOutrosTopo} className={classes.text}>
                          <ListItemText primary="Outros" />
                          {openOutrosTopo ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse className="menuTopo-collapse" in={openOutrosTopo} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                          {localStorage.getItem('/novo-modelo') === 'true'
                              ?
                            <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                              <Link to="/listagem-modelos" className={classes.text}><ListItemText primary="Gerenciador de Prontuários" /></Link>
                            </ListItem>
                            :''}
                            {localStorage.getItem('/atendente') === 'true'?
                            <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                              <Link to="/atendentes" className={classes.text}><ListItemText primary="Atendentes" /></Link>
                            </ListItem>
                            :''}
                            <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                              <Link to="#" onClick={modalSuporte} className={classes.text}><ListItemText primary="Suporte Técnico" /></Link>
                            </ListItem>
                            {localStorage.getItem('/plano') || localStorage.getItem('/planos') === 'true'?
                            <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                              <Link to="/planos" className={classes.text}><ListItemText primary="Planos" /></Link>
                            </ListItem>
                            :''}
                            {/* {localStorage.getItem('/relatorios') || localStorage.getItem('/relatorios') === 'true'?
                            <ListItem button className={classes.text, open ? classes.submenu : classes.submenuColapse}>
                              <Link to="/relatorios" className={classes.text}><ListItemText primary="Relatórios" /></Link>
                            </ListItem>
                            :''} */}
                          </List>
                        </Collapse>
                      </div>
                    }
                  </List>

                  :

                  <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                    <Link to="/planos" className={classes.text}><ListItemText primary="Planos" /></Link>
                  </ListItem>
                }
            </div>
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label="show 10 new notifications"
                aria-controls={menuIdNot}
                onClick={handleProfileMenuNotOpen}
                color="inherit">
                <Badge badgeContent={badge} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                className="menuTopo-img-perfil"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar alt={nome} src={fotoPerfil} />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide, "menuTopo-icon-hamburguer")}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMenuNot}
      { (loginType == 'CLINIC' || (loginType == 'EMPLOYEE' && clinicId)) && showSubheader ? 
          <SubHeader></SubHeader>
      : ''}
      {adminAlert ? 
        <AdminAlert />
        : ''
      }
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton className={classes.iconVoltar} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={[classes.lista, "menuScroll"].join(',')}>
          {localStorage.getItem('/painel') === 'true'?
            <ListItem button className={classes.text}>
              <ListItemIcon>
                <Link to="/home">
                  {open ?
                    <TrendingUpIcon className={classes.iconVoltar} /> :
                    <Tooltip title="Painel" placement="right">
                      <TrendingUpIcon className={classes.iconVoltar} />
                    </Tooltip>
                  }
                </Link>
              </ListItemIcon>
              <Link to="/home" className={classes.textPrinc}><ListItemText primary="Painel" /></Link>
            </ListItem>
          :''}
          {localStorage.getItem('/agenda') === 'true'?
            <ListItem button className={classes.text} >
              <ListItemIcon>
                <Link to="/agenda">
                  {open ?
                    <TodayIcon className={classes.iconVoltar} /> :
                    <Tooltip title="Agenda" placement="right">
                      <TodayIcon className={classes.iconVoltar} />
                    </Tooltip>
                  }
                </Link>
              </ListItemIcon>
              <Link  to="/agenda" className={classes.textPrinc}><ListItemText primary="Agenda" /></Link>
            </ListItem>
          :''}
          {localStorage.getItem('/pacientes') === 'true'?
            <ListItem button className={classes.text}>
              <ListItemIcon>
                <Link to="/pacientes">
                  {open ?
                    <PeopleIcon className={classes.iconVoltar} /> :
                    <Tooltip title="Pacientes" placement="right">
                      <PeopleIcon className={classes.iconVoltar} />
                    </Tooltip>
                  }
                </Link>
              </ListItemIcon>
              <Link to="/pacientes" className={classes.textPrinc}><ListItemText primary="Pacientes" /></Link>
            </ListItem>
          :''}
          {localStorage.getItem('/financeiro') === 'true'?
            <ListItem button className={classes.text}>
              <ListItemIcon>
                <Link to="/financeiro">
                  {open ?
                    <AttachMoneyIcon className={classes.iconVoltar} /> :
                    <Tooltip title="Financeiro" placement="right">
                      <AttachMoneyIcon className={classes.iconVoltar} />
                    </Tooltip>
                  }
                </Link>
              </ListItemIcon>
              <Link to="/financeiro" className={classes.textPrinc}><ListItemText primary="Financeiro" /></Link>
            </ListItem>
          :''}
          {/* {localStorage.getItem('/relatorios') === 'true'?
          <ListItem button className={classes.text}>
            <ListItemIcon>
              <Link to="/relatorios">
                {open ?
                  <PlaylistAddCheckIcon className={classes.iconVoltar} /> :
                  <Tooltip title="Relatórios" placement="right">
                    <PlaylistAddCheckIcon className={classes.iconVoltar} />
                  </Tooltip>
                }
              </Link>
            </ListItemIcon>
            <Link to="/relatorios" className={classes.textPrinc}><ListItemText primary="Relatórios" /></Link>
          </ListItem>
          :''} */}
          <ListItem button onClick={mudaOutros} className={classes.text}>
            <ListItemIcon onClick={handleDrawerOpen}>
              {open ?
                <MoreVertIcon className={classes.iconVoltar} /> :

                <Tooltip title="Outros" placement="right">
                  <MoreVertIcon className={classes.iconVoltar} />
                </Tooltip>
              }
            </ListItemIcon>
            <ListItemText primary="Outros" />
            {openOutros ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openOutros} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            {localStorage.getItem('/novo-modelo') === 'true'?
              <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="/listagem-modelos">
                    {open ?
                      <AssignmentIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Prontuários" placement="right">
                        <AssignmentIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="/listagem-modelos" className={classes.text}><ListItemText primary="Gerenciador de Prontuários" /></Link>
              </ListItem>
            :''}
            {localStorage.getItem('/atendente') === 'true'?
              <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="/atendentes">
                    {open ?
                      <AssignmentIndIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Atendentes" placement="right">
                        <AssignmentIndIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="/atendentes" className={classes.text}><ListItemText primary="Atendentes" /></Link>
              </ListItem>
            :''}
              <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="#" onClick={modalSuporte}>
                    {open ?
                      <HelpIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Suporte técnico" placement="right">
                        <HelpIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="#" onClick={modalSuporte} className={classes.text}><ListItemText primary="Suporte técnico" /></Link>
              </ListItem>
            {localStorage.getItem('/plano') || localStorage.getItem('/planos')?
              <ListItem button className={[classes.text, open ? classes.submenu : classes.submenuColapse].join(',')}>
                <ListItemIcon className={classes.submenuIcon}>
                  <Link to="/planos">
                    {open ?
                      <ViewCarouselIcon className={clsx(classes.iconVoltar, classes.subIcon)} /> :

                      <Tooltip title="Planos" placement="right">
                        <ViewCarouselIcon className={clsx(classes.iconVoltar, classes.subIcon)} />
                      </Tooltip>
                    }
                  </Link>
                </ListItemIcon>
                <Link to="/planos" className={classes.text}><ListItemText primary="Planos" /></Link>
              </ListItem>
            :''}
            </List>
          </Collapse>
          <ListItem button onClick={()=>{modalSair()}} className={classes.text}>
            <ListItemIcon>
              <Link to="#" onClick={()=>{modalSair()}}>
                {open ?
                  <ExitToAppIcon className={classes.iconVoltar} /> :
                  <Tooltip title="Sair" placement="right">
                    <ExitToAppIcon className={classes.iconVoltar} />
                  </Tooltip>
                }
              </Link>
            </ListItemIcon>
            <Link to="#" onClick={()=>{modalSair()}} className={classes.textPrinc}><ListItemText primary="Sair" /></Link>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              backup()
            }}
            className={classes.text}
          >
            <ListItemIcon>
              <Link
                onClick={() => {
                }}
              >
                <BackupIcon className={classes.iconVoltar} />
              </Link>
            </ListItemIcon>
            <Link onClick={() => { }} className={classes.textPrinc}>
              <ListItemText primary="Backup" />
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <ModalNotificacao
        handleModal={handleModal}
        open={openModalNotificacao}
        load={load}
        titulo={titulo}
        descricao={descricao}
      />
      <ModalSuporte
        handleModal={handleModalSuporte}
        open={openModalSuporte}
        load={load}
      />
      <ModalSair
        handleModal={handleModalSair}
        open={openModalSair}
        load={load}
      />
    </div>
  );
}