import { ContactsOutlined } from "@material-ui/icons";
import { Component } from "react";
import Autosuggest from 'react-autosuggest';
import './styles.css'
import {cpf} from '../Validate'
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}



function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  );
}

function renderSectionTitle(section) {
  return (
    <strong>{section.title}</strong>
  );
}

function getSectionSuggestions(section) {
  return section.languages;
}

export default  class App extends Component {
  constructor(props) {
    super();
    this.state = {
      value: '',
      suggestions: [],
      valorAtual:''
    };    
  }

  onChange = (event, { newValue, method }) => {
    this.props.get(newValue)

    this.setState({
      value: newValue
    });
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestions(value) {
    var escapedValue = escapeRegexCharacters(value.trim());
    
    if (escapedValue === '') {
      return [];
    }
  
    const regex = new RegExp('^' + escapedValue, 'i');
    
    if(this.props.list) {
      console.log('DEBUG')
      console.log(this.props.list)
      var options = this.props.list.map(item => {
        return item
      })
      
      return options.filter(patient => regex.test(patient.name));
    }

    return [];
  }

  getSuggestionValue(suggestion) {
    return suggestion.id;
  }
  
  renderSuggestion(suggestion) {
    return (
      <span>{suggestion.name}</span>
    );
  }

  getvalor = (e)=>{
        this.props.get(e)
        return e.name;
      }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Paciente *",
      value,
      onChange: this.onChange,
      //onblur:(e)=>{this.props.get(e.target)}
    };

    return (
      <Autosuggest 
        multiSection={false}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        // getSuggestionValue={getSuggestionValue}
        getSuggestionValue={this.getvalor}
        renderSuggestion={renderSuggestion}
        // renderSectionTitle={renderSectionTitle}
        // getSectionSuggestions={getSectionSuggestions}
        inputProps={inputProps} />
    );
  }
}
