import React, { useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import '../../css/NovoModelo.css';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'; 
import './css/style.css'

import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import VistaAnterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_anterior.png';
import VistaInferior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_inferior.png';
import VistaLateral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_lateral.png';
import VistaPosterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_posterior.png';
import SistemaMuscular from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_muscular.png';
import SistemaViceral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_visceral.png';



const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  inputEscala:{
    width: '100%',
  },
  optEscala:{
    
    textTransform: "uppercase",
    '& select ':{
       textTransform: "uppercase",
    }
  }
 
}));



export default function CardTextoCurto(props) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();
  const [required, setRequirede] = useState(false);
  const [checked, setcheck] = useState(false)
  const [titulocampo , setTituloCampo] =useState();
  const [tipo , setTipo] =useState('cranianovistaanterior');
  const [img , setImg] =useState();

  // console.log("tipo aqui",tipo)

  const {
    montagemstate,
    setMontagem,
    setIdcomponentAtual,
    setAtt,
    att,
    idComponentAtual,
    sessaostate,
    viewProntuario
  } = StateGlobal();

  const tipos = [
    {tipo : 'Crânio - Sistema craniano' , img:VistaAnterior   ,type:'cranianovistaanterior'},  
    {tipo: 'Crânio - Vista Inferior'  , img:VistaInferior   ,type:'cranianovistainferior'},
    {tipo: 'Crânio - Vista Lateral'   , img:VistaLateral    ,type:'cranianovistalateraldireita'},
    {tipo :'Crânio - Vista Posterior'  ,img:VistaPosterior  ,type:'cranianovistaposterior'},
    {tipo :'Sistema musculoesquelético' ,img:SistemaMuscular ,type:'musculoesqueletico'},
    {tipo :'Sistema Visceral' ,img:SistemaViceral   ,type:'visceral'} ]

  useEffect(() => {
    montagemstate.map((item) => {
      if(item.tempId === props.tempId){
        setTituloCampo(item.title)
        setcheck(item.required === '1' ? true : false)
      
        tipos.forEach((i ,indice)=>{
          if(item.type === 'escaladedor'){
            setImg(tipos[0].img)
            setTipo(tipos[0].type)
          }

          if(item.type == i.type){     
            setImg(tipos[indice].img)
            setTipo(tipos[indice].type)
          }
        })
      }
    })
  }, [idComponentAtual ])

  useEffect(() => {
    console.log('ESCALADOR: ', montagemstate)
    montagemstate.map((item, index) => {
      if (props.tempId === item.tempId) {
        if(item.type === "escaladedor"){
        item.type = 'cranianovistaanterior'
        item.question = 'cranianovistaanterior'}
      }
    })
    setMontagem(montagemstate)
  },[])

//  useEffect(()=>{
//    if(!tipo){
//      setTipo(tipos[0].tipo)
//    } 
   
//  })

 useEffect(() =>{
  tipos.forEach((item,index)=>{
    if(tipo == item.type){
     
      setImg(tipos[index].img)
    }
  })
    
 }, [tipo])

  function handlerChange(e){
    const value =  e.target.value
    console.log('value', value)
    setTipo(value)


    montagemstate.map((item, index) => {
      if (props.tempId === item.tempId) {
        item.type = value
        item.question = value
      }
    })
    setMontagem(montagemstate)
   
  }

  
  

  return (
    <div className="CardTextoCurto-container" >
      {/* {props.tempId} */}
      <ListIcon className="icon-list-card-input" />
      <div className="container-card-input">

        <div className="titleEscale">
          <h3 className="h3-title-card-input">ESCALA DE DOR</h3>

          <FormControl variant="outlined" className={classes.formControl, classes.inputEscala}>
          <InputLabel htmlFor="outlined-age-native-simple" >Intensidade</InputLabel>
          <Select
            native
            label="Intensidade"
            onChange={handlerChange}
            value = {tipo}
            disabled={viewProntuario} 
            className={classes.optEscala}
          >
            {tipos.map((item) => {
              
              return <option className={classes.optEscala} value={item.type}>{item.tipo}</option>
           
           })}

            
           
          </Select>
        </FormControl>
      </div>
      
      <div className="img-class">
            <img src={img} alt=""/>
      </div>

        <div className="Card-option">
          <IconButton name={props.tempId} 
          disabled={viewProntuario}
          onClick={() => {
            montagemstate.map((item, index) => {
              if (props.tempId === item.tempId) {
                const array = montagemstate
                array.splice(index, 1)

                setMontagem(array)
                setAtt(!att)

              }
            })


          }}>
            <DeleteIcon />
          </IconButton>
          <div className="divisor"></div>
          {/* {checked ? 'true ' : 'false'} */}
          <FormControlLabel
            className="label-color"
            name='switch'
            onChange={() => {
              setcheck(!checked)



              montagemstate.map((item, index) => {
                if (props.tempId === item.tempId) {

                  item.required = !checked

                }
              })

            }}
            checked={checked}
           
            control={<Switch color="primary" />}
            label="Obrigatório"
            labelPlacement="start"
            disabled={viewProntuario}

          />


        </div>
      </div>
    </div>
  );
}