


import React, { useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import { BsPlusCircleFill } from 'react-icons/bs'

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './style.css'


import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import VistaAnterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_anterior.png';
import VistaInferior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_inferior.png';
import VistaLateral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_lateral.png';
import VistaPosterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_posterior.png';
import SistemaMuscular from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_muscular.png';
import SistemaViceral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_visceral.png';
import OdontologicoImg from '../../../../../Assets/Images/ImagensProntuario/mapa_odontologia.png';
import { TrainRounded } from '@material-ui/icons';




const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  inputEscala: {
    width: '70%',
  },

}));



export default function CardTextoCurto({ title, id, required, edit, options, answer }) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();

  const [checked, setcheck] = useState(false)
  const [titulocampo, setTituloCampo] = useState();
  const [tipo, setTipo] = useState({ id: 595, text: "Molares", value: "18" });
  const [img, setImg] = useState();
  const [escala, setEscala] = useState('')
  const [listagem, setListagem] = useState([])
  const [error, setError] = useState(false)
  const [req, setReq] = useState(required);



  const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, respView, validaProntuario ,setvalidaProntuario, validation, setValidation,
    attValidation, setAttValidation, attEncerraError} = StateGlobal();




  useEffect(() => {

    var arr = []

    tipos.forEach((i, indece) => {
      if (title == i.type) {
        // setImg(tipos[indece].img)
        setTipo({ id: options[0].id })
        //setTituloCampo(tipos[indece].tipo)
      }
    })


    if (respEdit) {

      const dados = Answer.find(item => item.question_id === id)
      dados.answer.map((item) => {
        if (item.form_step_question_option_id) {

          arr.push({ ...ChangeOption(item.form_step_question_option_id), answer: item.text })
          UpdateAnswer(arr)
        }



      })
    } else {



    }


    const opa = ReturnAnswer()



    setListagem(opa)



    // console.log('ANSWERS DENTRO DO ESCALA DE DOR', dados)
    // console.log('OPTIONS', options)


    // if (data?.answer.length > 0) {
    //   if (data.answer.length > 0) {
    //     console.log(id,'EDIT', data.answer)
    //     setListagem(data.answer)
    //   }
    // } 
    //   console.log('EDIT', edit ? true : false)
    //   const arr = []
    // if (edit && listagem.length <= 0) {

    //   edit.map((item) => {
    //     console.log('CHANGE OPTION', ChangeOption(item.tipo))
    //     console.log('CHANGE OPTION', ChangeColor(item.text))

    //     arr.push({ question_id: item.id, tipo: ChangeOption(item.form_step_question_option_id), escala: item.text, id: item.form_step_question_option_id, cor: ChangeColor(item.text) })


    //   })
    //   console.log('ARR', arr)


    //   setListagem(edit ? arr : [])

    // }





  }, [id])

//codigo para validação***************************************
  // useEffect(()=>{

  //   validacaoOnchange()
  // },[listagem])


  // function validacaoOnchange() {

  //   if(required !== false)
  //   Answer.map((item) => {
  //     if (item.question_id === id) {        
  //       if(item.answer.length > 0){
  //         validaProntuario[id].error = false
  //         validaProntuario[id].value = 'ok'
  //         setvalidaProntuario({...validaProntuario})

  //       }else{
  //         validaProntuario[id].error = true
  //         validaProntuario[id].value = ''
  //         setvalidaProntuario({...validaProntuario})

  //       }
  //     }
  //   })

  // }
  //codigo para validação***************************************

//   <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '20px' }}>
//   {validaProntuario[id].error === true ? validaProntuario[id].message : ''}
// </div>





  const tipos = [
    { tipo: 'Mapa EVA - Crânio - Sistema craniano', img: VistaAnterior, type: 'cranianovistaanterior' },
    { tipo: 'Mapa EVA - Crânio - Vista inferior', img: VistaInferior, type: 'cranianovistainferior' },
    { tipo: 'Mapa EVA - Crânio - Vista lateral Direita', img: VistaLateral, type: 'cranianovistalateraldireita' },
    { tipo: 'Mapa EVA - Crânio - Vista posterior', img: VistaPosterior, type: 'cranianovistaposterior' },
    { tipo: 'Mapa EVA - Sistema musculoesquelético', img: SistemaMuscular, type: 'musculoesqueletico' },
    { tipo: 'Mapa EVA - Sistema visceral', img: SistemaViceral, type: 'visceral' }]


  const escaladedor = [
    { escala: 0, cor: '#7DB3DC' },
    { escala: 1, cor: '#036EA2' },
    { escala: 2, cor: '#485779' },
    { escala: 3, cor: '#018064' },
    { escala: 4, cor: '#34AD3A' },
    { escala: 5, cor: '#E7E92B' },
    { escala: 6, cor: '#F8B302' },
    { escala: 7, cor: '#E47617' },
    { escala: 8, cor: '#E05414' },
    { escala: 9, cor: '#E02617' },
    { escala: 10, cor: '#9D2725' }]

  function handlerChange(e) {
    const value = e.target.value

    const obj = ChangeOption(value)


    setTipo(obj)

  }

  function handlerChange2(e) {
    const value = e.target.value
    setEscala(value)
  }





  function ChangeColor(escala) {
    var cor = ''
    escaladedor.map((item) => {
      //console.log('ESCALA cor',item.escala.toString() , item.cor , 'ESCALA ATUAL' , escala)            
      if (item.escala.toString() === escala) {
        //console.log('ESCALA cor', item)
        cor = item.cor
      }
    })
    return cor
  }

  function ChangeOption(tipo) {
    var data = ''
    options.map((item) => {

      if (item.id === Number(tipo)) {
        data = item
      }
    })
    //  console.log('Dentro changeOption', tipo, data)
    return data
  }


  function UpdateAnswer(arr) {
    Answer.map((item) => {
      if (item.question_id === id) {
        item.answer = arr

        if(req){
          if( !arr[0] ){
            setError(true)
          }
          else{
            setError(false)
          }
        }
      }
    })

    setAnswer(Answer)
  }


  function ReturnAnswer() {
    var teste
    Answer.map((item) => {
      if (item.question_id === id) {
        teste = item.answer
      }
    })
    return teste
  }
  

  useEffect(() => {
    if(validation) {
      
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
         
          if(comp.id == id){
   
            setError(validation[index].components[i].error)
              
          }
        })
      })
    }

  }, [attEncerraError])

  useEffect(()=>{
    if(validation) {
      
      validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
          
            if(comp.id == id){
    
              validation[index].components[i].error = error 
              
                
            }
          })
      })
      setAttValidation(!attValidation)
    }

  }, [error])


  console.log('LISTAGEMM ODONTO:', listagem)
  return (
    <div className="CardTextoCurto-container" >
      {/* {props.tempId} */}
      {/* <ListIcon className="icon-list-card-input" /> */}
      <div className="container-card-input">

        <div className="titleEscale">
          <h3 className="h3-title-card-input">Odontograma</h3>


        </div>
        {/* {id} */}

        <div className="img-class">
          <img src={OdontologicoImg} alt="" />
        </div>

        {respView ? '' :
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>

            <FormControl variant="outlined" style={{ width: '20%' }}>
              <InputLabel htmlFor="outlined-age-native-simple" >Tipo</InputLabel>
              <Select
                native
                label="Tipo"
                onChange={handlerChange}
                value={tipo.id}
                // disabled={respEdit}
              >
                {options ? options.map((item) => {

                  return <option value={item.id}>{item.value} - {item.text}</option>

                }) : ''}
              </Select>
            </FormControl>

            {/* <FormControl variant="outlined" >
              <InputLabel htmlFor="outlined-age-native-simple" >Tipo</InputLabel>
              <Select
                native
                label="Tipo"
                onChange={handlerChange2}
                value={escala}

              >
                {escaladedor.map((item) => {

                  return <option value={item.escala}>{item.escala}</option>

                })}
              </Select>
            </FormControl> */}

            <TextField
              style={{ width: '60%', marginRight: '10px' }}
              onChange={handlerChange2}
              value={escala}
              // disabled={respEdit}
              variant="outlined"
            > </TextField>

            <BsPlusCircleFill style={{ color: '#27b8d2', fontSize: '30px' }} onClick={() => {

              // var cor = ''
              var data = ''
              // console.log('ESCALA', escala)

              // escaladedor.map((item) => {
              //   //console.log('ESCALA cor',item.escala.toString() , item.cor , 'ESCALA ATUAL' , escala)            
              //   if (item.escala.toString() === escala) {
              //     console.log('ESCALA cor', item)
              //     cor = item.cor
              //   }
              // })

              // options.map((item) => {
              //   console.log(item)
              //   if (item.text === tipo) {
              //     data = item
              //   }

              // })
              // const arr = []

              //listagem.push({ cor: ChangeColor(escala), tipo: tipo, escala: escala, ...ChangeOption(tipo) })

              Answer.map((item, index) => {
                console.log('itemm: ', item)
                if (item.question_id === id) {
                  item.answer.push({ ...tipo, answer: escala })

                  if(req){
                    if( !Answer[index].answer){
                      setError(true)
                    }
                    else{
                      setError(false)
                    }
                  }

                }
              })


              const dados = Answer.find(item => item.question_id === id)

              const opa = dados?.answer.slice()

              setListagem(opa)

              setAnswer(Answer)

             


            }} />



          </div>}
        <div style={{ display: 'flex', flexDirection: 'column' }} >

          {listagem ? listagem.map((item, index) => {

            return (
              <div style={{ width: '100%', borderRadius: "5px", border: '1px solid gray', flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: "space-between" }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '500px' }}>
                  <div style={{ borderRadius: '50%', backgroundColor: item.cor, height: '20px', width: '20px', margin: '15px' }}></div>
                  <div>{item.value}-{item.text}</div></div>
                <div style={{ marginRight: '50px' }}>Dor/Disfunção: {item.answer}</div> <div>
                  {respView ? ' ' :
                    <IconButton onClick={() => {
                      listagem.splice(index, 1)

                      const arr = listagem.slice()

                      setListagem(arr)
                      UpdateAnswer(arr)

                    }}>
                      <DeleteIcon />
                    </IconButton>}
                </div>
              </div>
            )
          }) : ''}

        <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
        { error === true ? '* Preencha o campo' : ''}  
        </div>




        </div>

        <div className="Card-option">

        </div>
      </div>
    </div>
  );
}