import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Faam({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function setFaamTotal(){
    let total = 0

    Object.values(sportsPhysiotherapyChartAnswers.faam.activities).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        total += int
      }
    })

    return total || 0;
  }

  return (
    <div className={classes.faam}>
      <p style={{ fontWeight: '500' }}>
        Por favor, responda todas as questões, indicando uma única alternativa que melhor descreva sua condição na última semana. Por causa do seu problema no tornozelo ou pé, quanta dificuldade você tem em: 
      </p>

      <article>
        <p className='titulo-form'>1. Ficar em pé</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.standUp}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  standUp: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2. Caminhar no plano, em superfície regular </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.walkFlatSurface}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  walkFlatSurface: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3. Caminhar no plano, em superfície regular, descalço.</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.walkBarefootFlatSurface}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  walkBarefootFlatSurface: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4. Subir morro</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.goUpHill}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  goUpHill: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5. Descer morro</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.goDownHill}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  goDownHill: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6. Subir escada</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.climbStairs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  climbStairs: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7. Descer escada</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.downStairs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  downStairs: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8. Caminhar no plano, em superfície irregular </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.walkUnevenSurface}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  walkUnevenSurface: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>9. Subir e descer meio-fio</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.goUpAndDownCurb}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  goUpAndDownCurb: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>10. Agachar</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.squat}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  squat: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>11. Ficar na ponta dos pés</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.standOnTiptoe}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  standOnTiptoe: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>12. Começar a caminhar </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.startWalking}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  startWalking: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>13. Caminhar 5 minutos ou menos</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.walk5MinutesOrLess}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  walk5MinutesOrLess: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>14. Caminhar aproximadamente 10 minutos </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.walkApproximately10Minutes}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  walkApproximately10Minutes: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>15. Caminhar 15 minutos ou mais </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.walking15MinutesOrMore}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  walking15MinutesOrMore: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>16. Atividades domésticas </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.domesticActivities}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  domesticActivities: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>17. Atividades de vida diária </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.activitiesOfDailyLiving}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  activitiesOfDailyLiving: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>18. Cuidado pessoal </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.personalCare}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  personalCare: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>19. Trabalho leve a moderado que exija caminhar ou ficar em pé </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.workThatRequiresStanding}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  workThatRequiresStanding: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>20. Trabalho pesado (empurrar/puxar, subir/descer escada, carregar) </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.heavyWork}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  heavyWork: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>21. Atividades recreativas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.recreationalActivities}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  recreationalActivities: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <p 
        className="titulo-form"
        style={{ margin: '15px 0 10px 0', fontSize: '1.3em' }}
      >
        FAAM - ESPORTE <br/>
        Por causa do seu problema no tornozelo ou pé, quanta dificuldade você tem em: 
      </p>

      <article>
        <p className='titulo-form'>1. Correr</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.run}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  run: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2. Pular</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.jump}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  jump: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3. Amortecer o salto </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.cushionTheJump}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  cushionTheJump: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4. Arrancar e parar bruscamente </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.startAndStopAbruptly}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  startAndStopAbruptly: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5. Realizar passadas laterais rápidas, com mudança brusca de direção </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.makeQuickSidePasses}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  makeQuickSidePasses: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6. Atividades de baixo impacto </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.lowImpactActivities}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  lowImpactActivities: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7. Capacidade em desempenhar a atividade com sua técnica normal</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.activityWithNormalTechnique}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  activityWithNormalTechnique: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8. Capacidade em praticar o seu esporte desejado pelo tempo que você gostaria </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.activities.practiceDesiredSport}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                activities: {
                  ...state.faam.activities,
                  practiceDesiredSport: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="4">Nenhuma Dificuldade</option>
          <option value="3">Pouca / Leve Dificuldade</option>
          <option value="2">Moderada Dificuldade</option>
          <option value="1">Extrema Dificuldade</option>
          <option value="0">Incapaz de fazer</option>
        </TextField>
      </article>

      <article style={{ marginTop: '25px'}}>
        <p className='titulo-form'>9. De um modo geral, como você classificaria seu nível funcional atual?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.faam.functionalLevel}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              faam: {
                ...state.faam,
                functionalLevel: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="normal">Normal</option>
          <option value="mostNormal">Quase Normal</option>
          <option value="anormal">Anormal</option>
          <option value="extremelyNormal">Extremamente Anormal</option>
        </TextField>

        <p className={classes.score}>Escore: {setFaamTotal()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}