import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Lefs({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function setLefsTotal(){
    let total = 0

    Object.values(sportsPhysiotherapyChartAnswers.lefs).forEach(value => { 
      let int = parseInt(value)
      
      if (int) {
        total += int
      }
    })

    return total ? (80 / total).toFixed(2) : 0;
  }

  return (
    <div className={classes.lefs}>
      <p style={{ fontWeight: '500' }}>
        Estamos interessados em saber se você tem tido alguma dificuldade para realizar as atividades listadas abaixo, devido aos problemas com os seus membros inferiores. Por favor, marque uma resposta para cada atividade. Hoje, você tem ou teria alguma dificuldade com: 
      </p>

      <article>
        <p className='titulo-form'>1. Qualquer uma do seu trabalho normal, tarefas domésticas ou nas atividades escolares</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.normalWork}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                normalWork: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>
      
      <article>
        <p className='titulo-form'>2. Seu passatempo predileto, atividades recreacionais ou esportivas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.hobby}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                hobby: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3. Entrar ou sair do banho </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.gettingInOrOutOfTheBath}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                gettingInOrOutOfTheBath: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4. Caminhar entre os quartos </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.walkBetweenRooms}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                walkBetweenRooms: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5. Calçar seus sapatos ou meias </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.putOnYourShoesOrSocks}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                putOnYourShoesOrSocks: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6. Agachar-se </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.crouch}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                crouch: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7. Levantar um objeto, como uma sacola de compra do chão </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.liftAnObject}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                liftAnObject: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8. Realizar atividades domésticas leves</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.doLightHousework}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                doLightHousework: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>9. Realizar atividades domésticas pesadas </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.doHeavyHousework}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                doHeavyHousework: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>10. Entrar ou sair do carro </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.gettingInOrOutOfTheCar}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                gettingInOrOutOfTheCar: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>11. Andar dois quarteirões </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.walkTwoBlocks}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                walkTwoBlocks: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>12. Andar aproximadamente 1,5 Km </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.walkApproximately1Km}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                walkApproximately1Km: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>13. Subir ou descer 10 degraus (aproximadamente um lance de escadas) </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.goUpOrDown10Steps}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                goUpOrDown10Steps: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>14. Ficar em pé durante uma hora </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.standForAnHour}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                standForAnHour: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>15. Ficar sentado durante uma hora </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.sittingForAnHour}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                sittingForAnHour: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>16. Correr em terreno plano </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.runOnFlatGround}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                runOnFlatGround: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        > 
          <option value="" selected >...</option>  
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>17. Correr em terreno irregular </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.runOnUnevenGround}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                runOnUnevenGround: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>18. Mudar de direção enquanto corre rapidamente</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.changeDirection}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                changeDirection: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>19. Pular </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.jump}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                jump: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>20. Rolar na cama </p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lefs.rollOverInBed}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lefs: {
                ...state.lefs,
                rollOverInBed: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected >...</option>   
          <option value="0">Extrema dificuldade ou incapacidade de realizar a atividade</option>
          <option value="1">Bastante dificuldade</option>
          <option value="2">Moderada dificuldade</option>
          <option value="3">Pouca dificuldade</option>
          <option value="4">Nenhuma dificuldade</option>
        </TextField>

        <p className={classes.score}>Escore: {setLefsTotal()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}