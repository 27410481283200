import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'

import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Lysholm({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function getLysholmScore(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.lysholm).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })
    
    return score ? (score).toFixed(2) : 0;
  }

  return (
    <div className={classes.lysholm}>
      <article>
        <p className='titulo-form'>Dor</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.pain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                pain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="25">Nenhuma</option>
          <option value="20">Inconstante ou leve durante exercícios pesados </option>
          <option value="15">Marcada durante exercícios pesados </option>
          <option value="10">Marcada durante ou após caminhar mais de 2 km </option>
          <option value="5">Marcada durante ou após caminhar menos de 2 km</option>
          <option value="0">Constante</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>Inchaço</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.swelling}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                swelling: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Nenhum</option>
          <option value="6">Com exercícios pesados </option>
          <option value="2">Com exercícios comuns </option>
          <option value="0">Constante</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>Subindo escadas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.goingUpStairs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                goingUpStairs: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        > 
          <option value="" selected>...</option>  
          <option value="10">Nenhum</option>
          <option value="6">Levemente prejudicado </option>
          <option value="2">Um degrau cada vez </option>
          <option value="0">Impossível</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>Agachamento</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.squat}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                squat: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        > 
          <option value="" selected>...</option>  
          <option value="5">Nenhum</option>
          <option value="4">Levemente prejudicado</option>
          <option value="2">Não além de 90 graus </option>
          <option value="0">Impossível</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>Mancar</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.limp}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                limp: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >  
          <option value="" selected>...</option> 
          <option value="5">Nenhum</option>
          <option value="3">Leve ou periódica </option>
          <option value="0">Intenso ou constante </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>Apoio</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.support}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                support: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >  
          <option value="" selected>...</option> 
          <option value="5">Nenhum</option>
          <option value="2">Bengala ou muleta </option>
          <option value="0">Apoio impossível </option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>Bloqueio</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.block}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                block: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >  
          <option value="" selected>...</option> 
          <option value="15">Ausência de bloqueio e sem sensação de trancamento </option>
          <option value="10">Sensação de trancamento, mas s/ bloqueio... </option>
          <option value="6">Bloqueio ocasionalmente</option>
          <option value="2">Frequentemente</option>
          <option value="0">Articulação bloqueada ao exame</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>Instabilidade</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.lysholm.instability}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              lysholm: {
                ...state.lysholm,
                instability: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >  
          <option value="" selected>...</option> 
          <option value="25">Nunca falseia </option>
          <option value="20">Raramente, durante atividades atléticas ou outros exercícios pesados</option>
          <option value="15">Frequente durante atividades atléticas ou outros exercícios pesados (ou incapaz de participação)</option>
          <option value="10">Ocasionalmente em atividades diárias </option>
          <option value="5">Frequentemente em atividades diárias </option>
          <option value="0">Em cada passo</option>
        </TextField>

        <p className={classes.score}>
          Escore: {getLysholmScore()} {'->'} (Excelente: 95 - 100 / Bom: 84 - 94 / Regular: 65 - 83 / Ruim: {'<'} 64) 
        </p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}