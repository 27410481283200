import React, {useEffect, useState} from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AlertDashboard from '../../../Components/Alert';
import InfoPaacientes from './InfoPacientes';
import GraficoPacientes from './GraficoPacientes';
import GraficoPeriodo from './GraficoPeriodo';
import Aniversariantes from './Aniversariantes';
import {TextField, Button} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import conexao from '../../../Services/api'
import { Select, FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import '../css/dashboard.css';


const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function Graficos() {
  const [search, setSearch] = useState(7);
  const [aniversario, setAniversario] = useState([]);
  const [graficoPeriodo, setGraficoPeriodo] = useState([]);
  const [graficoPacientes, setGraficoPacientes] = useState([]);
  const [convenio, setConvenio] = useState(0);
  const [privado, setPrivado] = useState(0);
  const doctor_id = localStorage.getItem('selectedDoctor')
  const user_id = localStorage.getItem('selectedUser')
  const [ Blocked, setBlocked ] = useState()



function buscaDadosGraficos(value){
  var per;
  if(search == 7){
    per = "7"
  }else{
    per = search
  }
  const dados = {
    "user_id": user_id,
    "doctor_id": doctor_id,
    "clinic_id": localStorage.getItem('clinicId'),
    "days": per
  }

  // var url = (localStorage.getItem('profileId') == 3) ? '/dashboard/report' : '/dashboard/report-clinic'
  var url = '/dashboard/report'

  conexao.post(url,dados).then((res)=>{
    setAniversario(res.data.data.birthday);
    setGraficoPeriodo(res.data.data.schedule_day);
    setGraficoPacientes(res.data.data);
    setConvenio(res.data.data.plan)
    setPrivado(res.data.data.private)
    setBlocked(res.data.data.blocked)
  }).catch((error)=>{
    console.log(error)
  })
}

useEffect(() => {
  buscaDadosGraficos()
}, [search])

// function limparDadosGraficos(){
//   setSearch('');
//     buscaDadosGraficos(7)
// }



  return (
    <div>
      { localStorage.getItem('loginType') == 'EMPLOYEE' && !parseInt(localStorage.getItem('selectedDoctor')) ?
        <Alert style={{marginTop: '20px'}} variant="filled" severity="warning">Para visualizar o Painel, um profissional precisa ser selecionado.</Alert>
      :
        <>
          {/* <AlertDashboard/> */}
          <div className="Dashboard-Graficos-selects">
            <div className="Dashboard-Graficos-p-select">
              <p>Filtro: </p>
              <FormControl variant="outlined" size='small'>
                <Select
                className='select-Filtro'
                  id="demo-simple-select-outlined"
                  value={search}
                  onChange={(event)=>setSearch(event.target.value)}
                >
                  <MenuItem value="7">Últimos 7 dias</MenuItem>
                  <MenuItem value="30">Últimos 30 dias</MenuItem>
                  
                </Select> 
              </FormControl>
              {/* <TextField 
                id="outlined-basic" 
                label="Período desejado" 
                className="Dashboard-Graficos-select"
                value={search}
                onChange={(event)=>setSearch(event.target.value)}
                variant="outlined"/>
              <Button variant="contained" onClick={buscaDadosGraficos} className="color-primary btn-margin-rigth" color="primary"><SearchIcon/> Buscar </Button>
              <Button variant="contained" onClick={limparDadosGraficos} className="color-secondary" color="secondary">Limpar filtro</Button> */}
            </div>
          </div>
          <div className="Dashboard-Graficos-div-scroll">
            <div className="d-flex-dash">
              <div className="Dashboard-Graficos-cards">
                <InfoPaacientes dados={graficoPacientes}/>
                <div className="Dashboard-Graficos-cards-graficos">
                  <p className="Dashboard-Graficos-cards-graficos-title">Particular x Convênio</p>
                  {convenio!=0 || privado!=0?
                    <div>
                      <div className="graf-pac">
                        <GraficoPacientes dados={graficoPacientes} />
                      </div>
                      <div className="Dashboard-Graficos-cards-graficos-legendas">
                        <div className="Dashboard-Graficos-cards-graficos-legendas"> 
                          <div className="Dashboard-Graficos-cards-graficos-legendas-circle recorrentes"></div>
                          <p className="Dashboard-Graficos-cards-graficos-legendas-title">Particular</p>
                        </div>
                        <div className="Dashboard-Graficos-cards-graficos-legendas"> 
                          <div className="Dashboard-Graficos-cards-graficos-legendas-circle novos"></div>
                          <p className="Dashboard-Graficos-cards-graficos-legendas-title">Convênio</p>
                        </div>
                      </div>
                    </div>
                  :
                  <div className="nao-possui-dados">
                    <p>Não possui pacientes para esse período</p>
                  </div>
                  }
                </div>
                <div className="Dashboard-Graficos-cards-graficos aniversariantes">
                  <p className="Dashboard-Graficos-cards-graficos-title">Aniversariante(s) do mês</p>
                  {console.log(aniversario)}
                  <Aniversariantes dados={aniversario}/>
                </div>
              </div>

              <div className="Dashboard-Graficos-cards-graficos atendimento-periodo">
                <p className="Dashboard-Graficos-cards-graficos-title">Atendimentos no período:</p>
                {convenio!=0 || privado!=0?
                  <GraficoPeriodo dados={graficoPeriodo}/>
                  :
                  <div className="nao-possui-dados-perio">
                    <p>Não possui atendimentos para esse período</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}