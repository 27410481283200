import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputNumerico({id ,title , required, edit}) {
    const [numerico, setNumerico] = useState('');
    const [error, setError] = useState(false)

    const { Answer ,setAnswer ,respEdit , setMontagemProntuario ,MontagemProntuario , setNameProntuario, NameProntuario, PosProntuario ,setpos , respView, validation, setValidation,
      attValidation, setAttValidation, attEncerraError} = StateGlobal();

    const handleChangeInput = event => {
        const { value } = event.target;
     
        Answer.map((item, index) => {
            if (item.question_id === id) {
                setNumerico(value.replace(/([^\d])+/gim, ''))
                Answer[index].answer = value.replace(/([^\d])+/gim, '')

                if(required){
                    if( !Answer[index].answer ){
                      setError(true)
                    }
                    else{
                    setError(false)
                  }
                }
                

            }
        })
        const array  = MontagemProntuario
  
        array[PosProntuario].montagem.map((item , index)=>{
            if(item.tempId === id){

                item.answer = value
            }

        })

        setMontagemProntuario(array)
        


        
    };

    useEffect(() => {
      if(validation) {
      
        validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
           
            if(comp.id == id){
     
              setError(validation[index].components[i].error)
                
            }
          })
        })
      }
      if(respEdit){
        setError(false)
      }
  
    }, [attEncerraError])
  
  
    useEffect(()=>{
      if(validation) {
      
        validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
           
            if(comp.id == id){
     
              validation[index].components[i].error = error 
              
                
            }
            })
        })
        setAttValidation(!attValidation)
      }
  
    }, [error])

    useEffect(()=>{
      setNumerico(edit)
      },[id])

    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setNumerico(item.answer)
    //         }
    //     })
    // }, [id])



    // useEffect(()=>{

    //     Answer.map((item,index)=>{
    //       if(item.question_id === id){  
    //         if(item.answer ===  ''){                 
    //        setNumerico(edit)
    //         }else{
    //           setNumerico(item.answer)

    //         }
    //       }
    //     })      

    //   },[edit])
    
  return (
        <div>
            <p className="titulo-form">{title}</p>
            <TextField 
                className="mb-4"
                value={numerico}
                onChange={handleChangeInput}
                onBlur={handleChangeInput}
                type="tel"
                error={error}
                disabled={respView}
                required={required}
                variant="outlined"
                //disabled = {respEdit}
            />
            <div style={{ color: 'red', width: '100%', marginTop: '-15px', fontSize: '15px' }}>
              { error === true ? '* Preencha o campo' : ''}  
            </div>
        </div>
  );
}
