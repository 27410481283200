import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { StateGlobal } from '../../ContextAPI/context'
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularLoad from '../../Components/Load';
import conexao from '../../Services/api';
// import ModalExcluir from '../../Components/Modais/ModalExclusao';
import PersonIcon from '@material-ui/icons/Person';
import './Listagem.css';
import { cpf, data } from '../../Components/ReduxTeste/Validate';
import ModalExcluir from './Modal/ModalExcluir';
import ModalPaciente from './Modal/ModalPaciente';
import Paginacao from './Paginacao';
import DescriptionIcon from '@material-ui/icons/Description';

import '../../App.css';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  row: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '350px',

  },
  iconSearch: {
    color: '#8F8F8F'
  },
  pag:{
    display: 'flex',
    justifyContent:'center',
    marginTop: '2rem'
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function Pacientes() {
  const classes = useStyles();
  const history = useHistory();
  const [pacientes, setPacientes] = useState([]);
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(true);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [buscacpf, setBuscaCpf] = useState('');
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [modeloId, setModeloId] = React.useState('');
  const [optionDelete, setOptionDelete] = React.useState('');
  const [dataarr, setDataarr] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false);
  const [iddelete, setIddelete] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [atualPage, setAtualPage] = useState(1);
  const [search, setSearch] = useState('')
  const [selectedPatient, setSelectedPatient] = useState('')

  const { setLocation } = StateGlobal()

  const [control, setControl] = useState()

  const {

    isEditPatient,
    setIsEditPatient,
    ViewPatient,
    setViewPatient,
    setPacienteName 
  } = StateGlobal();

  useEffect(() => {
    getallPacients();
    setViewPatient(false)
  }, [])

  useEffect(()=>{
    buscaApi()
  },[atualPage])

  useEffect(() => {
    if(search==='') {
      buscaApi()
    }

  },[search]);


  function closeConfirm() {
    setOpenDelete(false)
  }

  function closeEdit() {
    setSelectedPatient('')
    setOpenCreateUpdate(false)
  }

  function getallPacients() {
    setLoad(true)
    var url = '';
    if (parseInt(localStorage.getItem('selectedDoctor')))
    {
      var doctorId = localStorage.getItem('selectedDoctor');
      url = `patient/filter/${doctorId}?page=${atualPage}`
    } else {
      var clinicId = localStorage.getItem('clinicId');
      url = `patient/filter/clinic/${clinicId}?page=${atualPage}`
    }

    conexao.post(url)
    .then((res) => {
      setPacientes(res.data.data)
      setDataarr(res.data.data.data)
      setTotalPages(res.data.data.last_page)
      setLoad(false)
    
    }).catch((error) => {

      setLoad(false)
    })
  }

  function busca(event) {
    if (event.key === 'Enter') {
      buscaApi()
    } if (event._reactName === 'onClick') {
      buscaApi()
    }
    return true
  }

  function buscaApi() {
    setLoad(true)
    var url = '';
    if (parseInt(localStorage.getItem('selectedDoctor')))
    {
      var doctorId = localStorage.getItem('selectedDoctor');
      url = `patient/filter/${doctorId}?page=${atualPage}`
    } else {
      var clinicId = localStorage.getItem('clinicId');
      url = `patient/filter/clinic/${clinicId}?page=${atualPage}`
    }

    conexao.post(url, {
      "name": search,
     })
    .then((res)=>{
      setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
      setPacientes(res.data.data.data)
      setDataarr(res.data.data.data)
      setTotalPages(res.data.data.last_page)
      setLoad(false)
        if(res.data.data.data.length<1){
          setMessage('Nenhum resultado encontrado')
          setTypeAlert('error')
        }
    }).catch((error)=>{
      setMessage('Nenhum resultado encontrado')
      setTypeAlert('error')
      setLoad(false)
    })
    
  }

  function editar(id, name){
    setPacienteName(name)
    // localStorage.setItem('patientID', id)
    setIsEditPatient(true)
    setLocation('pacientes')
    localStorage.setItem('Location', 'pacientes')
    // history.push('/cadastro-paciente')
    history.push(`/cadastro-paciente/${id}`);
  }
  function visualizar(id, name){
    setPacienteName(name)
    // localStorage.setItem('patientID', id)
    setIsEditPatient(true)
    setViewPatient(true)
    setLocation('pacientes')
    localStorage.setItem('Location', 'pacientes')
    // history.push('/cadastro-paciente')
    history.push(`/cadastro-paciente/${id}`);
  }
  function prontuario(id, name){
    setPacienteName(name)
    localStorage.setItem('patientID', id)
    setLocation('pacientes')
    localStorage.setItem('Location', 'pacientes')
    console.log(id)
    // history.push('/atendimento')
    history.push(`/atendimento/${id}`);
  }
  
  function deletePatient() {
    setOpenDelete(true)
    if (iddelete) {
      conexao.delete('/patient/' + iddelete).then((res) => {

        getallPacients()
  
      }).catch((error) => {
  
      })
    }
  }

  function handlePage(value){
    setAtualPage(value)
   
  }

  return (
    <div className="">

      <div className={clsx(classes.row)}>
        {parseInt(localStorage.getItem('selectedDoctor')) ? 
          <Button variant="contained" className="Color-btn-addModelo" onClick={() => {
            setOpenCreateUpdate(true)
          }}>
            <AddIcon /> Novo Paciente
          </Button>
        : ''}
      </div>

      <FormControl className={clsx(classes.form)}>
        <InputBase
          id="outlined-adornment-weight"
          endAdornment={<InputAdornment position="end">{<IconButton onClick={(event) => busca(event)}><SearchIcon className={classes.iconSearch} /></IconButton>}</InputAdornment>}
          onKeyDown={busca}
          onChange={(e) => {setSearch(e.target.value)}}
          placeholder="Busque por um paciente"
          className={clsx(classes.search)}
        />
      </FormControl>
      <div>
          {message ?
            <div className="Modal-agendamento-alert">
              <Alert variant="filled" severity={typeAlert}>
                {message}
              </Alert>
            </div>
            : ''}
        </div>
      <div>
        {load ?
          <div className="div-load-lista-modelos"><CircularLoad /></div>
          :         
        dataarr ?
          dataarr.map((patient, index) => (
            <div className="CardModelos-envolve" key={index}>
              <div className="CardModelos-envolve-text-icon">
                <PersonIcon className="CardModelos-icon-pront" />
                <p>{patient.name}</p>
              </div>
              <div className="CardModelos-envolve-text-icon">
                <div className="CardModelos-icon-pront">CPF:</div>
                <p>{patient.cpf ? cpf(patient.cpf) : 'Não cadastrado' }</p>
              </div>
              <div>
                <IconButton onClick={()=>{
                    prontuario(patient.id, patient.name)
                }}>
                  <DescriptionIcon />
                </IconButton>
                <IconButton onClick={() => {
                  localStorage.setItem('patientID', patient.id)
                  setSelectedPatient(patient.id)
                  setOpenCreateUpdate(true)
                }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => {
                  setOpenDelete(true)
                  setIddelete(patient.id)
                }}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )) :
          <div>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''}
          </div>
        
         }
      </div>
      <div className={classes.pag}>
      {!load ? 
        <Paginacao handlePage={handlePage} totalPages={totalPages} atualPage={atualPage} /> 
        : ''
      }  
     
      </div>
      <ModalExcluir open={openDelete} titulo={'Excluir Paciente'} desc={'Tem certeza que deseja excluir este paciente ?'} close={closeConfirm} delete={deletePatient} />
      <ModalPaciente open={openCreateUpdate} patientId={selectedPatient} close={closeEdit} refresh={getallPacients} />
    </div>
  );
}