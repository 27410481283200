import React, { useState, useEffect } from "react"
import { ptBR } from "@material-ui/core/locale"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import AddIcon from "@material-ui/icons/Add"
import MenuItem from "@material-ui/core/MenuItem"
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"
import "date-fns"
import clsx from "clsx"
import deLocale from "date-fns/locale/pt-BR"
import Grid from "@material-ui/core/Grid"
import DateFnsUtils from "@date-io/date-fns"
import ModalDetalheAgendamento from "./ModalDetalhesAgendamento"
import CloseIcon from "@material-ui/icons/Close"
import CircularLoad from "../../../Components/Load"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import "../css/Agenda.css"
import conexao from "../../../Services/api"
import Alert from "@material-ui/lab/Alert"
import { StateGlobal } from "../../../ContextAPI/context"
import SelectProcedimento from "./SelectProcedimentos"
import { materialDate, hora } from "./Validate"
import SelectPacienteCreate from "./SelectPacienteAgendamento"
import { cpf } from "./Validate"
import ModalExcluir from "../../../Components/Modais/ModalExclusao"
import { campovazio } from "../../../Components/ReduxTeste/ValidateForm"
import { browserName, CustomView } from "react-device-detect"

// import { DatePickerComponent } from "@syncfusion/ej2-react-calendars"

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "1rem",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    
    background: "#F9F9F9",
  },
  col3: {
    width: '30%!important',
    margin: '2%!important'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  SelectProcedimento: {
    width: "100%",
  },
  QuantProce: {
    width: "30%",
    marginLeft: "1rem",
    backgroundColor: "white",
    marginTop: "1rem",
    marginBottom: theme.spacing(1),
  },
  selectTipoAtendimento: {
    width: "100%",
    backgroundColor: "white",
  },
  input: {
    width: "100%",
    marginTop: "1rem",
    display: "flex",
    backgroundColor: "white",
  },
  inputTel: {
    width: "50%",
    marginRight: "1rem",
    backgroundColor: "white",
  },
  paddingButton: {
    paddingRight: "1.5rem",
  },
  inputData: {
    width: "30%",
  },
  label: {
    background: "white",
    paddingRight: ".4rem",
  },
  modalColor: {
    backgroundColor: "#f9f9f9",
  },
  pConfig: {
    color: "#8F8F8F",
    minWidth: "30%",
    maxWidth: "30%",
    textAlign: "left",
    wordWrap: "break-word",
  },
  envolveConfig: {
    borderBottom: "1px solid #8F8F8F",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  envolveConfigs: {
    height: "auto",
    overflow: "auto",
    backgroundColor: "white",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginTop: "1rem",
  },
  errorselectinput: {
    borderColor: "red",
  },
}))

const theme = createTheme(
  {
    palette: {
      secondary: {
        light: "#E7366A",
        main: "#E7366A",
        dark: "#D93364",
        contrastText: "#fff",
      },
      primary: {
        light: "#27b8d2",
        main: "#27b8d2",
        dark: "#229fb5",
        contrastText: "#fff",
      },
    },
  },
  ptBR
)

function FormataCelular(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={true}
      isNumericString
      format="(##) # ####-####"
    />
  )
}

FormataCelular.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

function FormataTelefone(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={true}
      isNumericString
      format="(##) ####-####"
    />
  )
}
FormataTelefone.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}


let data = new Date()
const Hora = data.getHours();
const min = data.getMinutes();


const initHora = `${Hora}:${min}`
const endHora = `${Hora+1}:${min}`

let dd = String(data.getDate()).padStart(2, '0');
let mm = String(data.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = data.getFullYear();

data = yyyy + '-' + mm + '-' + dd;

const Data_atual = data

// console.log('Data_atual:', Data_atual)
// console.log('Mes:', mes)

const initialstate = {
  //obejto principal do component
  radio: "AGENDADO",
  procedimento: "",
  quant: "",
  paciente: "",
  celular: "",
  telefone: "",
  email: "",
  atendimento: "",
  recorrencia: "",
  recorrenciaSemana: "",
  dataconsulta: Data_atual,
  datafinal: Data_atual,
  timeini: initHora,
  timeend: endHora,
  observacoes: "",
  typepatient: false, //verifica se pacient e cadastrado ou nao true cadastrado false sem cadastro
  idpatient: "",
  deschorarioblock: "",
  doctorid: localStorage.getItem("doctorId"),
  idschedule: "",
  selected_week_days: []
}

export default function ModalAddAgendamento(props) {
  const initialarr = ["selecione um paciente"]

  const classes = useStyles()
  const [procedimento, setProcedimento] = React.useState("")
  const [tipoAtendimento, setTipoAtendimento] = React.useState("")
  const [frequenciaAtendimento, setFrequenciaAtendimento] = React.useState('');
  const [open, setOpen] = useState(false)
  const [modalDetalhesAgendamento, setModalDetalhesAgendamento] =
    useState(false)

  const [patient, setPatient] = useState([])
  const [proc, setProc] = useState([])
  const [controle, setControle] = useState(false)
  const [load, setLoad] = useState(false)
  const [items, setItems] = useState()
  const [dataedit, setDataedit] = useState()
  const [arrfinal, setArrfinal] = useState(initialarr)
  const {agendadoData, setAgendadoData } = StateGlobal()
  const {statusTabela, setStatusTabela } = StateGlobal()
  const {attAgenda, setAttAgenda } = StateGlobal()
  const [arrayPacientes, setArrayPacientes] = useState([])
  const [arraypatient, setArraypatient] = useState()
  const [dataremoveprocedimento, setDataremoveprocedimento] = useState()

  //modal nat
  const [openModalDelete, setOpenModalDelete] = React.useState(false)
  const [loadmodaldelete] = useState(false)
  const [titulo, setTitulo] = React.useState("")
  const [descricao] = React.useState("")
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [weekdaysList, setWeekdaysList] = useState([
    {
      'id': 0,
      'name': 'Domingo'
    },
    {
      'id': 1,
      'name': 'Segunda-feira'
    },
    {
      'id': 2,
      'name':'Terça-feira'
    },
    {
      'id': 3,
      'name':'Quarta-feira'
    },
    {
      'id': 4,
      'name':'Quinta-feira'
    },
    {
      'id': 5,
      'name':'Sexta-feira'
    },
    {
      'id': 6,
      'name':'Sábado'
    }
  ])
 

  const editstate = {
    radio: "AGENDADO",
    procedimento: "",
    quant: "",
    userId: agendadoData && agendadoData[0] ? agendadoData[0].user.id : "",
    doctorId: agendadoData && agendadoData[0] ? agendadoData[0].doctor_id : "",
    paciente: agendadoData && agendadoData[0] ? agendadoData[0].patient.id : "",
    pacientename: agendadoData && agendadoData[0] ? agendadoData[0].patient.name : "",
    celular: agendadoData && agendadoData[0] ? agendadoData[0].patient.cellphone : "",
    telefone: agendadoData && agendadoData[0] ? agendadoData[0].patient.phone : "",
    email: agendadoData && agendadoData[0] ? agendadoData[0].patient.email : "",
    atendimento: agendadoData && agendadoData[0] ? agendadoData[0].type : "",
    recorrencia: agendadoData && agendadoData[0] ? agendadoData[0].recorrencia : "",
    recorrenciaSemana: agendadoData && agendadoData[0] ? agendadoData[0].recorrenciaSemana : "",
    dataconsulta: agendadoData && agendadoData[0] ? materialDate(agendadoData[0].started_at) : "",
    datafinal: agendadoData && agendadoData[0] ? materialDate(agendadoData[0].datafinal) : "",
    timeini: agendadoData && agendadoData[0] ? hora(agendadoData[0].started_at) : "",
    timeend: agendadoData && agendadoData[0] ? hora(agendadoData[0].finished_at) : "",
    observacoes: agendadoData && agendadoData[0] ? agendadoData[0].description : "",
  }

  const [values, setValues] = useState(
    props.edit === true ? editstate : initialstate
  )

  const listagem = []
  let data = new Date()
  const Hora = data.getHours();
  const min = data.getMinutes();


  const initHora = `${Hora}:${min}`
  const endHora = `${Hora+1}:${min}`

  let dd = String(data.getDate()).padStart(2, '0');
  let mm = String(data.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = data.getFullYear();

  data = yyyy + '-' + mm + '-' + dd;

  const Data_atual = data
  const initialvalidate = {
    dataconsulta: {
      name: "dataconsulta",
      funcao: campovazio,
      error: false,
      value: Data_atual,
    },
    datafinal: {
      name: "datafinal",
      funcao: campovazio,
      error: false,
      value: Data_atual,
    },
    timeini: { name: "timeini", funcao: campovazio, error: false, value: initHora },/////////
    timeend: { name: "timeend", funcao: campovazio, error: false, value: endHora },
    paciente: { name: "paciente", funcao: campovazio, error: false, value: "" },
    celular: { name: "celular", funcao: campovazio, error: false, value: "" },
    recorrencia: {
      name: "recorrencia",
      funcao: campovazio,
      error: false,
      value: "",
    },
    atendimento: {
      name: "atendimento",
      funcao: campovazio,
      error: false,
      value: "",
    },
    deschorarioblock: {
      name: "deschorarioblock",
      funcao: campovazio,
      error: false,
      value: "",
    },
  }

  const obj = {
    //obejto pré preparado para cricação schendule , montagem  finalizada na chamada da api array dinamico  , usario com cadastro e sem
    user_id: parseInt(localStorage.getItem('selectedUser')),
    started_at: "" + values.dataconsulta + " " + values.timeini + ":00",
    finished_at: "" + values.dataconsulta + " " + values.timeend + ":00",
    datafinal: values.datafinal,
    description: values.observacoes,
    status: values.radio,
    type: values.atendimento,
    recurrence: values.recorrencia,
    weekly_recurrence: values.recorrenciaSemana,
    payment_type: "CRÉDITO",
    payment_status: "PENDENTE",
    patient: "",
    items: arrfinal,
    selected_week_days: []
  }

  const [validateTest, setValidateTest] = useState(initialvalidate)
  
  const alerts = [
    <Alert variant="filled" severity="error">
      Campos inválidos ou horário bloqueado
    </Alert>,
  ]

  useEffect(() => {
    setOpen(props.open)
    setStatusTabela(true)
    setAttAgenda(false)
    setValidateTest(initialvalidate)
    
    if (props.blockedit === true) {
      values.radio = "BLOQUEADO"
      values.deschorarioblock = props.dadosbloqueio.description
      values.timeini = hora(props.dadosbloqueio.started_at)
      values.timeend = hora(props.dadosbloqueio.finished_at)
      values.dataconsulta = materialDate(props.dadosbloqueio.started_at)
      values.datafinal = materialDate(props.dadosbloqueio.datafinal)
      values.idschedule = props.dadosbloqueio.id   
      initialvalidate.deschorarioblock.value = props.dadosbloqueio.description
      // const newarray = [...arrfinal]
      // setArrfinal(newarray)
      const arrteste = values
      setValues(arrteste)
    }

    if (props.edit === false && props.blockedit === false) {
      setValues({
        ...values,
        procedimento: "",
        quant: "",
        paciente: "",
        celular: "",
        telefone: "",
        email: "",
        atendimento: "",
        recorrencia: "",
        recorrenciaSemana: "",
        dataconsulta: Data_atual,
        datafinal: Data_atual,
        timeini: initHora,
        timeend: endHora,
        observacoes: "",
        typepatient: false, //verifica se pacient e cadastrado ou nao true cadastrado false sem cadastro
        idpatient: "",
        idschedule: "",
        selected_week_days: []
      })
    }

    setArrfinal(["1"])
    if (props.edit === true) {
      console.log('AGENDAMENTO_DATA')
      console.log(agendadoData)
      setDataedit(agendadoData[0])
      setItems(agendadoData[0].items)
    }
    
    // if (props.blockedit === true) {
      setSelectedUser(parseInt(localStorage.getItem('selectedUser')));
    // }
  }, [props])

  useEffect(() => {
    if (arrayPacientes.length == 0) {
      attcomplet()
    }
    setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
  }, [])

  function onchangeValidateTeste(e) {
    const { name, value } = e.target

    const obj = validateTest[name]

    const er = validateTest[name].funcao(value)
    let att = { ...obj, error: er, value: value }

    setValidateTest({ ...validateTest, [name]: att })
  }

  function updateSelectedWeekdays(e) {
    console.log('SELECTED_WEEKDAYS')
    console.log(e.target.value)
    setValues({ ...values, 'selected_week_days': e.target.value })
  }

  function formataData(item) {
    var days = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];        
    var months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    var date = new Date(item.started_at);
    item.date = days[date.getDay()] + ', ' + date.getDate() + ' de ' + months[date.getMonth()] + ' de ' + date.getFullYear();

    return item;
  }

  //schedule/type/4
  function attcomplet() {
    if (localStorage.getItem('selectedDoctor')) {
      conexao.post("/patient/get/" + localStorage.getItem('selectedDoctor'))
        // .post("/patient/filter/" + props.doctorId)
        .then((res) => {
          setArrayPacientes(res.data.data)
      })
      .catch((error) => {})
    }
  }

  // function listaProcedimentos() {
  //   console.log('LISTA_PROCEDIMENTOS')
  //   var userId = localStorage.getItem("selectedUser")
  //   conexao
  //   .get("/schedule/type/" + userId)
  //   .then((res) => {
  //       console.log(res)
  //       setProc(res.data)
  //     })
  //     .catch((error) => {})
  // }

  const openModalDetalhesAgendamento = () => {
    setModalDetalhesAgendamento(true)
  }

  const alteraEstadoModal = (modalDetalhesAgendamento) => {
    setModalDetalhesAgendamento(modalDetalhesAgendamento)
  }
  useEffect(() => {
    
  }, [props])

  const closeModal = () => {
    setStatusTabela(!statusTabela)
    setArrfinal(["1"]) //reseta  array procedimentos

    values.radio = "AGENDADO" //adiconado para sempre comecar com o modal recorrencia

    setValues({
      ...values,
      procedimento: "",
      quant: "",
      paciente: "",
      celular: "",
      telefone: "",
      email: "",
      atendimento: "",
      recorrencia: "",
      recorrenciaSemana: "",
      dataconsulta: Data_atual,
      datafinal: Data_atual,
      timeini: initHora,
      timeend: endHora,
      observacoes: "",
      typepatient: false, //verifica se pacient e cadastrado ou nao true cadastrado false sem cadastro
      idpatient: "",
      idschedule: "",
    })

    setOpen(false)
    props.onChange(false)

    // window.location.reload()
  }

  const handleChange = (event) => {
    setProcedimento(event.target.value)
  }

  const handleChangeAtendimento = (event) => {
    setTipoAtendimento(event.target.value)
  }

  const handleChangeFreqAtendimento = (event) => {
    setFrequenciaAtendimento(event.target.value);
  };

  function itemSchedule(e) {
    if (e) {
      conexao
        .delete("/schedule/item/" + e)
        .then((res) => {})
        .catch((error) => {})
    }
  }
  
  function save(event) {
    event.preventDefault()

    const obj = {
      //obejto pré preparado para cricação schendule , montagem  finalizada na chamada da api array dinamico  , usario com cadastro e sem
      patient_id: values.idpatient,
      user_id: selectedUser,
      started_at: "" + values.dataconsulta + " " + values.timeini + ":00",
      finished_at: "" + values.dataconsulta + " " + values.timeend + ":00",
      datafinal: values.datafinal,
      description: values.observacoes,
      status: props.edit === true ? props.dataShedule.status : values.radio,
      type: values.atendimento,
      recurrence: values.recorrencia,
      weekly_recurrence: values.recorrenciaSemana,
      payment_type: "CRÉDITO",
      payment_status: "PENDENTE",
      patient: "",
      items: arrfinal,
      selected_week_days: values.selected_week_days
    }

    console.log(obj)

    const arr = Object.values(validateTest)
    arr.map((item) => {
      if (item.value === "") {
        validateTest[item.name].error = true
      }
      setValidateTest({ ...validateTest })
    })

    const novo = []
    arrfinal.map((item, index) => {
      if (item.name !== undefined) {
        novo[index] = {
          schedule_type_id: item.id,
          name: item.name,
          quantity: item.quantity,
          value: item.value,
        }
      }
    })

    obj.items = novo

    if (values.radio === "AGENDADO") {
      if (props.edit === true) {
        conexao
          .put("/schedule/update/" + dataedit.id, obj)
          .then((res) => {
            setStatusTabela(!statusTabela)
            setAgendadoData(res.data.data)
            closeModal()
            openModalDetalhesAgendamento()
            props.refreshTable()
          })
          .catch((error) => {})
      } else {
        if (values.typepatient === true) {
          obj.patient = {
            name: values.paciente,
            cellphone: values.celular,
            phone: values.telefone,
            email: values.email,
            patient_id: values.idpatient,
          }
          // obj.user_id = values.idpatient

          conexao
            .post("/schedule/create", obj)
            .then((res) => {
              
              setAgendadoData(res.data.data)
              closeModal()
              openModalDetalhesAgendamento()
              props.refreshTable()
            })
            .catch((error) => {
              setControle(true)
              setTimeout(() => {
                setControle(false)
              }, 2000)
            })
        } else {
          //array  com doctor id

          obj.patient = {
            name: values.paciente,
            cellphone: values.celular,
            phone: values.telefone,
            email: values.email,
            doctor_id: selectedDoctor,
          }

          conexao
            .post("/schedule/create", obj)
            .then((res) => {
              setAttAgenda(!attAgenda)
              setAgendadoData(res.data.data)
              closeModal()
              openModalDetalhesAgendamento()
              props.refreshTable()
            })
            .catch((error) => {
              setControle(true)
              setTimeout(() => {
                setControle(false)
              }, 2000)
            })
        }
      }
    } else {
      //else de item nao agendado
      if (props.blockedit === true) {
        //cai aqui se estiver vindo  no modal editar horario bloqueado
        let payload = {
          user_id: selectedUser,
          started_at: "" + values.dataconsulta + " " + values.timeini + ":00",
          finished_at: "" + values.dataconsulta + " " + values.timeend + ":00",
          status: "BLOQUEADO",
          description: values.deschorarioblock,
        }

        conexao
          .put("/schedule/update/reserve/" + values.idschedule, payload)
          .then((res) => {
            closeModal()
            props.refreshTable()
          })
          .catch((error) => {

            setControle(true)
            setTimeout(() => {
              setControle(false)
            }, 3000)
          })
      } else {

        conexao
          .post("/schedule/create/reserve", {
            user_id: props.userId,
            started_at: "" + values.dataconsulta + " " + values.timeini + ":00",
            finished_at:
              "" + values.dataconsulta + " " + values.timeend + ":00",
            status: values.radio,
            description: values.deschorarioblock,
          })
          .then((res) => {
            setAttAgenda(!attAgenda)
            closeModal()
            setStatusTabela(!statusTabela)
            props.refreshTable()
          })
          .catch((error) => {
            setControle(true)
            setTimeout(() => {
              setControle(false)
            }, 2000)
          })
      }
    }
  }

  function onchange(e) {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const radio = true

  function GeraJsonSelectProcedimentos(e) {
    arrfinal[e.index] = e

    const newarray = [...arrfinal]
    setArrfinal(newarray)
  }

  function InsertQtdJson(qtd, index) {
    arrfinal[index] = { ...arrfinal[index], ...qtd }
  }

  function ExcluirItemjson(e, ind) {
    arrfinal.map((item, index) => {
      if (item.id) {
        if (item.id === e.id) {
          arrfinal.splice(index, 1)
          const newarray = [...arrfinal]
          setArrfinal(newarray)
        }
      } else {
        arrfinal.splice(ind, 1)
        const newarray = [...arrfinal]
        setArrfinal(newarray)
      }

      return true;
    })
  }

  var name = ""

  function SelectPaciente(e) {
    var obj = { target: { name: "paciente", value: e } }
    onchangeValidateTeste(obj)

    if (e.name && e.id) {
      name = e.name
      
console.log('SELECT_PATIENT')
console.log(e)

      setValues({
        ...values,
        celular: e.cellphone,
        telefone: e.phone,
        email: e.email,
        paciente: e.name,
        idpatient: e.id,
        typepatient: true,
      })
    } else {
      if (name !== e) {
        setValues({
          ...values,
          celular: "",
          telefone: "",
          email: "",
          typepatient: false,
          paciente: e,
        })
      }
    }
  }

  const modaldelelereturn = (value) => {
    //setOptionDelete(value);

    if (value) {
      const { itemid, items, indexatul } = dataremoveprocedimento
      itemSchedule(itemid)
      // items.splice(indexatul, 1)
      // const newarray = [...items]

      // setItems(newarray)
      const newarray = [...items]

      newarray.map((item, index) => {
        if (item.id === itemid) {
          newarray.splice(index, 1)
        }
      })

      setItems(newarray)
      props.refreshTable()
      setOpenModalDelete(false)
    } else {
      setOpenModalDelete(false)
    }
  }

  function SelectAlterado(e) {}

  return (
    <div className="Modal-agendamento">
      <ThemeProvider theme={theme}>
        <Dialog open={open} aria-labelledby="Modal-title-agendamento">
          <form
            id=""
            className={classes.modalColor}
            onSubmit={save}
            autoComplete="off"
          >
            <DialogTitle id="Modal-title-agendamento">
              {props.edit === true ? (
                <div className="title-modal-close">
                  Editar agendamento
                  <CloseIcon onClick={closeModal} />
                </div>
              ) : (
                <div className="title-modal-close">
                  Adicionar agendamento
                  <CloseIcon onClick={closeModal} />
                </div>
              )}
            </DialogTitle>

            <div style={{ margin: "1rem" }}>{controle ? alerts[0] : ""}</div>
            <DialogContent>
              {
                selectedDoctor === 0 ?
                <Alert variant="filled" severity="error">Por favor, selecione um profissional para poder adicionar um agendamento.</Alert>
                : ''
              }
              <FormControl component="fieldset">
                {props.edit !== true && props.blockedit !== true ? (
                  <RadioGroup
                    row
                    aria-label="position"
                    name="radio"
                    required
                    onChange={onchange}
                  >
                    <FormControlLabel
                      checked={values.radio === "AGENDADO" ? true : false}
                      value="AGENDADO"
                      control={<Radio color="primary" />}
                      label="Agendar"
                    />
                    <FormControlLabel
                      checked={values.radio === "BLOQUEADO" ? true : false}
                      value="BLOQUEADO"
                      control={<Radio color="primary" />}
                      label="Bloquear horário"
                    />
                  </RadioGroup>
                ) : (
                  ""
                )}
              </FormControl>
              <div>
                {values.radio === "BLOQUEADO" ? (
                  <TextField
                    className={classes.input}
                    name="deschorarioblock"
                    InputLabelProps={{ shrink: true }}
                    id=""
                    //required
                    onChange={(e) => {
                      onchange(e)
                      onchangeValidateTeste(e)
                    }}
                    error={validateTest.deschorarioblock.error}
                    
                    helperText={
                      validateTest.deschorarioblock.error
                        ? validateTest.deschorarioblock.error
                          ? validateTest.deschorarioblock.error
                          : ""
                        : ""
                    }
                    label="Descrição horario bloqueado"
                    variant="outlined"
                    //value={props.edit === true ? agendadoData[0].patient.email  : '' }
                    value={values.deschorarioblock}
                  />
                ) : (
                  ""
                )}

                <div className="Modal-horario-agendamento">
                  <MuiPickersUtilsProvider
                    locale={deLocale}
                    utils={DateFnsUtils}
                  >
                    <Grid container justify="space-around">
                      <div>
                        <label style={{ color: "#0000008a", fontSize: "12px" }}>
                          Data da consulta
                        </label>
                        <input
                          type="date"
                          className={classes.TextField}
                          id="date"
                          defaultValue={values.dataconsulta}
                          
                          name="dataconsulta"
                          onChange={(e) => {
                            console.log(e.target.value)
                            onchange(e)
                            onchangeValidateTeste(e)
                            }}
                          error={
                            validateTest.dataconsulta
                              ? validateTest.dataconsulta.error
                                ? true
                                : false
                              : ""
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          format="dd-MMM-yy"
                          style={{
                            borderBottom: "1px solid #000",
                            outline: "0",
                            borderRight: "0",
                            borderLeft: "0",
                            borderTop: "0",
                            font: "inherit",
                            color: "currentcolor",
                            height: "1.1876rem",
                            margin: "0",
                            display: "block",
                            padding: "6px 0 7px",
                            minWidth: "0",
                            background: "none",
                            boxSizing: "content-box",
                            letterSpacing: "inherit",
                          }}
                        />
                      </div>

                      <TextField
                        id="time"
                        label="Início da consulta"
                        type="time"
                        defaultValue={values.timeini}
                        //required
                        name="timeini"
                        onChange={(e) => {
                          onchange(e)
                          onchangeValidateTeste(e)
                        }}
                        error={
                          validateTest.timeini
                            ? validateTest.timeini.error
                              ? true
                              : false
                            : ""
                        }
                        className={clsx(classes.inputData, classes.inputData)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <p className="Modal-p-as-hora">às</p>
                      <TextField
                        id="time"
                        className={clsx(classes.inputData, classes.inputData)}
                        defaultValue={values.timeend}
                        label="Fim da consulta"
                        type="time"
                        name="timeend"
                        onChange={(e) => {
                          onchange(e)
                          onchangeValidateTeste(e)
                        }}
                        error={
                          validateTest.timeend
                            ? validateTest.timeend.error
                              ? true
                              : false
                            : ""
                        }
                   
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
                {values.radio !== "BLOQUEADO" ? (
                  <div>

                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel
                      className={classes.label}
                      id="demo-simple-select-outlined-label"
                    >
                      Recorrência
                    </InputLabel>
                    <Select
                      disabled = {props.edit}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.recorrencia}
                      onChange={(e) => {
                        onchange(e)
                        onchangeValidateTeste(e)
                      }}
                      error={validateTest.recorrencia.error}
                      helperText={
                        validateTest.celular.error
                          ? "Esse campo precisa ser preenchido"
                          : ""
                      }
                      name="recorrencia"
                      label="Atendimento"
                      className={classes.selectTipoAtendimento}
                    >
                      <MenuItem value="">
                        <em>Selecione o tipo de recorrência</em>
                      </MenuItem>
                      <MenuItem key="0" value={"nao_repete"}>Não se repete</MenuItem>
                      <MenuItem key="1" value={"diariamente"}>Repetir todo dia</MenuItem>
                      <MenuItem key="2" value={"semanalmente"}>Repetir toda semana</MenuItem>
                      <MenuItem key="3" value={"quinzenalmente"}>Repetir a cada 14 dias</MenuItem>
                      <MenuItem key="4" value={"mensalmente"}>Repetir todo mês</MenuItem>
                      <MenuItem key="5" value={"anualmente"}>Repetir todo ano</MenuItem>
                    </Select>
                  </FormControl>

                  { values.recorrencia == 'semanalmente' ?
                    <FormControl variant="outlined" style={{width: '100%' }}>
                      <InputLabel htmlFor="outlined-adornment-password">Dias da semana</InputLabel>
                        <Select
                          labelId="selected-weekdays"
                          id="selected-weekdays"
                          value={values.selected_week_days}
                          name="selected-weekdays"
                          label="Dias sa semana"
                          onChange={updateSelectedWeekdays}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected ? selected.map((value) => (
                                <Chip key={value} label={weekdaysList.find(element => element['id'] == value).name} />
                              )) : ''}
                            </Box>
                          )}
                          className={classes.input}
                          multiple
                        >
                          {weekdaysList &&
                            weekdaysList.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.id}>
                                  <Checkbox checked={(values.selected_week_days) ? values.selected_week_days.includes(item.id) : false} />
                                  <ListItemText primary={item.name} />
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                    </FormControl>
                  : ''}
                  {values.recorrencia && values.recorrencia != 'nao_repete' ? 
                    <div style={{width: '30%', margin: '1rem 0'}}>
                      <label style={{ color: "#0000008a", fontSize: "12px" }}>
                        Repetir até
                      </label>

                      <input
                        type="date"
                        className={clsx(classes.input)}
                        id="date"
                        defaultValue={values.datafinal}
                        
                        name="datafinal"
                        onChange={(e) => {
                          console.log(e.target.value)
                          onchange(e)
                          onchangeValidateTeste(e)
                          }}
                        error={
                          validateTest.datafinal
                            ? validateTest.datafinal.error
                              ? true
                              : false
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        format="dd-MMM-yy"
                        style={{
                          borderBottom: "1px solid #000",
                          outline: "0",
                          borderRight: "0",
                          borderLeft: "0",
                          borderTop: "0",
                          font: "inherit",
                          color: "currentcolor",
                          height: "1.1876rem",
                          margin: "0",
                          display: "block",
                          padding: "6px 0 7px",
                          minWidth: "0",
                          background: "none",
                          boxSizing: "content-box",
                          letterSpacing: "inherit",
                        }}
                      />
                    </div> : null }

                    {props.edit === true ? (
                      <TextField
                        className={clsx(classes.input)}
                        type="name"
                        id=""
                        disabled
                        label="Paciente"
                        variant="outlined"
                        value={values.pacientename}
                        name="name"
                        onChange={onchange}
                      />
                    ) : (
                      <div>
                        <div
                          className={
                            validateTest.paciente.error
                              ? "errorselectinput"
                              : ""
                          }
                        >
                          <SelectPacienteCreate
                            get={SelectPaciente}
                            list={arrayPacientes}
                            alt={SelectAlterado}
                          />
                        </div>
                      </div>
                    )}
                    <div className={classes.input}>
                      <TextField
                        className={classes.inputTel}
                        type="tel"
                        id=""
                        label="Telefone celular"
                        name="celular"
                        disabled={props.edit}
                        variant="outlined"
                        onChange={(e) => {
                          onchange(e)
                          onchangeValidateTeste(e)
                        }}
                        error={props.edit ? false : validateTest.celular.error}
                        helperText={
                          props.edit
                            ? false
                            : validateTest.celular.error
                            ? "Esse campo precisa ser preenchido"
                            : ""
                        }
                        value={values.celular}
                        //required
                        InputProps={{
                          inputComponent: FormataCelular,
                        }}
                      />
                      <TextField
                        className={clsx(classes.inputTel, "mr-0")}
                        type="tel"
                        id=""
                        disabled={props.edit}
                        label="Telefone residencial (opcional)"
                        variant="outlined"
                        value={values.telefone}
                        name="telefone"
                        onChange={onchange}
                        InputProps={{
                          inputComponent: FormataTelefone,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        className={classes.input}
                        type="email"
                        name="email"
                        onChange={onchange}
                        InputLabelProps={{ shrink: true }}
                        id=""
                        disabled={props.edit}
                        label="E-mail (opcional)"
                        variant="outlined"
                        //value={props.edit === true ? agendadoData[0].patient.email  : '' }
                        value={values.email}
                      />

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          className={classes.label}
                          id="demo-simple-select-outlined-label"
                        >
                          Tipo de atendimento
                        </InputLabel>
                        <Select
                          //required
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          //value={props.edit === true ? agendadoData[0].type  : values.atendimento }
                          value={values.atendimento}
                          // props.edit === true ? agendadoData[0].patient.phone  : values.atendimento
                          onChange={(e) => {
                            onchange(e)
                            onchangeValidateTeste(e)
                          }}
                          error={validateTest.atendimento.error}
                          helperText={
                            validateTest.celular.error
                              ? "Esse campo precisa ser preenchido"
                              : ""
                          }
                          name="atendimento"
                          label="Atendimento"
                          className={classes.selectTipoAtendimento}
                        >
                          <MenuItem value="">
                            <em>Selecione o tipo de atendimento</em>
                          </MenuItem>
                          <MenuItem key="0" value={"convenio"}>Convênio</MenuItem>
                          <MenuItem key="1" value={"particular"}>Particular</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {values.radio !== "BLOQUEADO" ? (
                  <div>
                    <TextField
                      className={classes.input}
                      type="text"
                      name="observacoes"
                      value={values.observacoes}
                      onChange={onchange}
                      id=""
                      label="Observações (opcional)"
                      variant="outlined"
                    />
                    {props.edit === true ? (
                      <div className={classes.envolveConfigs}>
                        {items
                          ? items.map((item, index) => (
                              <div className={classes.envolveConfig}>
                                <p className={classes.pConfig}>{item.name}</p>
                                <p className={classes.pConfig}>
                                  {item.quantity}
                                </p>
                                <div>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      setOpenModalDelete(true) //comecar apartir daqui 20/04 implementar modal
                                      setDataremoveprocedimento({
                                        itemid: item.id,
                                        items: items,
                                        indexatual: index,
                                      })
                                    }}
                                    className={classes.margin}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                    <Button
                      onClick={() => {
                        arrfinal.push("item")

                        const newarray = [...arrfinal]
                        setArrfinal(newarray)
                      }}
                      color="primary"
                      className="Modal-form-add-agendamento-btn-add-proc"
                    >
                      <AddIcon /> Adicionar
                    </Button>
                    <div className="Modal-form-add-agendamento">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        {/* <InputLabel id="demo-simple-select-outlined-label"  >Procedimentos</InputLabel> */}
                        <div className="Modal-form-add-agendamento-div-proc">
                          <p>Procedimentos</p>
                          {arrfinal &&
                            arrfinal.map((item, index) => {
                              return (
                                <div>
                                  <SelectProcedimento
                                    key={index}
                                    index={index}
                                    json={GeraJsonSelectProcedimentos}
                                    excluir={ExcluirItemjson}
                                    arrayfinal={arrfinal}
                                    value={item} //importante passa quantidade e name para os selects
                                    insertQtd={InsertQtdJson}
                                  ></SelectProcedimento>
                                </div>
                              )
                            })}
                        </div>
                      </FormControl>
                      {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Procedimentos</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-outlined-label"
                    name='procedimento'
                    id="demo-simple-select-outlined"
                    value={values.procedimento}
                    onChange={onchange}
                    onClick={()=>{listaProcedimentos()}}
                    label="Procedimentos"
                    onClick={() => {listaProcedimentos()}}
                    className={classes.P}
                  >
                    <MenuItem value="">
                      <em>Selecione um procedimento</em>
                    </MenuItem>
                    {proc.map((item) => {
                      return (
                        <MenuItem value={item.name} onClick={()=>{setvalproc(item.value)}}>{item.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <TextField className={classes.QuantProce} type="number" id="" label="Quant." name='quant' onChange={onchange} variant="outlined" required /> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </DialogContent>

            <DialogActions className={classes.paddingButton}>
              <Button onClick={closeModal} type="button">
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="color-primary"
              >
                Salvar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <ModalDetalheAgendamento
          open={modalDetalhesAgendamento}
          onChange={alteraEstadoModal}
        />
        <ModalExcluir
          handleModal={modaldelelereturn}
          open={openModalDelete}
          load={loadmodaldelete}
          titulo={"Deseja  excluir procedimento ?"}
          descricao={descricao}
        />
      </ThemeProvider>
    </div>
  )
}
