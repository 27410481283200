import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto",

    '&::-webkit-scrollbar': {
      width: '6px'
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#ebebeb',
      borderRadius: '100px'
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff'
    }
  },

  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },

  colorPaperWhite:{
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },

  chart: {
    width: '100%',
    background: '#F8F8F8',
    marginTop: '20px',
    minHeight: '87vh',
    borderRadius: '4px',
    paddingBottom: '30px',
  },

  chart__title: {
    display: 'flex',
    padding: '2rem',
    boxShadow: 'rgb(100 100 111 / 20%) 0px 6px 10px 0px',
    alignItems: 'center',
    paddingTop: 0,
    justifyContent: 'space-between',
    backgroundColor: '#F8F8F8',

    '& h3': {
      fontSize: '2em',
      color: '#757575',
      marginBottom: '.6rem',
      fontWeight: '600',
      marginTop: '2rem'
    },

    '& h5': {
      fontSize: '1.2em',
      color: '#757575',
      marginBottom: '1rem',
      fontWeight: '500',
      marginTop: '0rem'
    },

    '& button': {
      color: '#27B8D2'
    }
  },

  chart__content: {
    padding: '1rem 4rem 2rem 4rem',
    boxShadow: 'rgb(100 100 111 / 20%) 0px 6px 10px 0px',
    borderRadius: '0px 0px 4px 4px',
    backgroundColor: '#F8F8F8',
  },

  observationCard: {
    padding: '1.5rem',
    marginTop: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0px 6px 10px 0px #0000000F'

  },
  observationData: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: '#27B8D2',
    margin: '0 0 20px 0'
  },
  observationText: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
    textAlign: 'justify'
  },
  observationAnnex: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: '#27B8D2',
    marginBottom: '0'
  },
  observationFile: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: '#E7366A',
    textDecoration: 'underline',
    cursor: 'pointer',

    '& * + span': {
      marginLeft: '13px'
    },
  },
  divEnvolveForm:{
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: '1.5rem',
    boxShadow: '0px 6px 10px 0px #0000000f',
    marginTop: '50px',

    '& p': {
      margin: '0 0 10px 0',
      fontWeight: 500,
      fontSize: '22px',
      color: '#27B8D2'
    },

    '& > *': {
      marginBottom: theme.spacing(3),
      width: '100%',
    },

    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F8F8F8',
      boxShadow: '0px 6px 10px 0px #0000000f',
    }
  },
  annexDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& input#file-input + label': {
      padding: '6px 16px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#27B8D2',
      color: '#FFFFFF',
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.75',
      display: 'block',
      textAlign: 'center',
      marginTop: '15px',
      marginBottom: '15px',
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

      '&:hover': {
        cursor: 'pointer'
      }
    },

    '& p':{
      padding: '6px 16px',
      margin: '0',
      marginRight: '13px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#F8F8F8',
      color: '#CCCCCC',
      fontSize: '19px',
      boxShadow: '0px 6px 10px 0px #0000000f',
      width: '420px',
      overflow: 'hidden',
      height: '37px'
    },
  },
}));