// import { FormatColorReset } from "@material-ui/icons";
import React, { createContext, useState, useContext } from "react";

const CountContext = createContext();

export default function CountProvider({ children }) {
    const [count, setCount] = useState('tste');//teste
    const [agendadoData, setAgendadoData] = useState();
    const [secaoFormulario, setSecaoFormulario] = useState(1);
    const [procedimentos, setProcedimentos] = useState();
    const [statusTabela, setStatusTabela] = useState(false);
    const [listaBloqueado, setListaBloqueado] = useState(false);
    const [att, setAtt] = useState(false);

    //prontuarios criação de components
    const [sessaostate, setSessaostate] = useState([]);
    const [montagemstate, setMontagem] = useState([]);
    const [components, setComponents] = useState([]);

    const [idComponentAtual, setIdcomponentAtual] = useState();

    const [tituloSessaoAtual, setTituloSessaoAtual] = useState('Título da seção prontuário');//importante prontuario

    //edicao prontuario
    const [idEditProntuario, setIdEditProntuario] = useState(false);
    const [viewProntuario, setViewProntuario] = useState();
    const [MontagemProntuario, setMontagemProntuario] = useState();
    const [NameProntuario, setNameProntuario] = useState('');
    const [PosProntuario, setPosProntuario] = useState(0);//edita posicao do array de montagem do prontuario atual
    const [Answer, setAnswer] = useState([])   //array resposta prontuarios end point sendAnswer
    const [respEdit, setRespEdit] = useState(false)   //edit respostas prontuarios
    const [AnswerEdit, setAnswerEdit] = useState([])   //ARRAY EDICAO verificar  teste
    const [respView, setRespView] = useState(false) //HABILITA  tela visualizacao prontuarios
    const [validaProntuario, setvalidaProntuario] = useState() //CONTROLE VALIDAÇÃO PRONTUARIOS IMPORTANTE EX. DE OBJ question_id:{question_id: id, value: atual , error: false}
    const [idProntuario, setIdProntuario] = useState()

    // Ativar componente de Observações
    const [observationStep, setObservationStep] = useState(false)

    const [validation, setValidation] = useState([]);
    const [attValidation, setAttValidation] = useState(false);
    const [attEncerraError, setAttEncerraError] = useState(false)

    //Cadastro
    const [  isEditPatient , setIsEditPatient ] = useState();
    const [  ViewPatient , setViewPatient ] = useState();
    const [  Location , setLocation ] = useState();
    const [  pacienteName , setPacienteName ] = useState();

    //VARIAVEL DE CONTROLE ATENDENTE ATT
    const [attatendente, setAttatendente] = useState();
    

    //iniciar prontuario
    const [pos, setPos] = useState(0);
    const [attResumo, setAttResumo] = useState(false);
    const [formPatientID, setFormPatientID] = useState();


    //notificações sininhos
    const [notification, setNotification] = useState([])
    const [notfyatt, setNotatt] = useState(false)


    //Controle de Rotas
    const [dataRoute, setDataRoute] = useState([{ route: '/agenda', autorization: false },
    { route: '/financeiro', autorization: false },
    { route: '/pacientes', autorization: false },
    { route: '/novo-modelo', autorization: false },
    { route: '/relatorios', autorization: false }])

      //agenda
      const [attAgenda, setAttAgenda] = useState()

    //Prontuarios controle de ID por row.id no edit 
    const [ RowIdProntuario, setRowIdProntuario] = useState()
    const [ listaImgBase64, setListaImgBase64 ] = useState([])

    const [chartComponentsIndex, setChartComponentsIndex] = useState(0)
    const [sportsPhysiotherapyChartAnswers, setSportsPhysiotherapyChartAnswers] = useState({})

    return (

        <CountContext.Provider
            value={{
                count,//usando para teste
                setCount, //usando para teste
                agendadoData,
                setAgendadoData,
                secaoFormulario,
                setSecaoFormulario,
                procedimentos,
                setProcedimentos,
                statusTabela,
                setStatusTabela,
                listaBloqueado,
                setListaBloqueado,
                sessaostate,
                setSessaostate,
                montagemstate,
                setMontagem,
                components,
                setComponents,
                att,
                setAtt,
                idComponentAtual,
                setIdcomponentAtual,
                tituloSessaoAtual,
                setTituloSessaoAtual,
                idEditProntuario,
                setIdEditProntuario,
                pos,
                setPos,
                MontagemProntuario,
                setMontagemProntuario,
                setNameProntuario,
                NameProntuario,
                PosProntuario,
                setPosProntuario,
                Answer,
                setAnswer,
                respEdit,
                setRespEdit,
                AnswerEdit,
                setAnswerEdit,
                respView,
                setRespView,
                isEditPatient,
                setIsEditPatient,
                ViewPatient,
                setViewPatient,
                attatendente,
                setAttatendente,
                notification,
                setNotification,
                notfyatt,
                setNotatt,
                dataRoute,
                setDataRoute,
                validaProntuario,
                setvalidaProntuario,
                Location, 
                setLocation ,
                pacienteName , 
                setPacienteName,
                attAgenda, 
                setAttAgenda,
                attResumo, 
                setAttResumo,
                viewProntuario, 
                setViewProntuario,
                RowIdProntuario,
                setRowIdProntuario,
                idProntuario, 
                setIdProntuario,
                validation, 
                setValidation,
                attValidation, 
                setAttValidation,
                attEncerraError,
                setAttEncerraError,
                observationStep,
                setObservationStep,
                formPatientID,
                setFormPatientID,
                listaImgBase64,
                setListaImgBase64,
                chartComponentsIndex,
                setChartComponentsIndex,
                sportsPhysiotherapyChartAnswers,
                setSportsPhysiotherapyChartAnswers
            }}
        >

            {children}
        </CountContext.Provider>
    );
}

export function StateGlobal() {
    const context = useContext(CountContext);

    const { count,
        setCount,
        agendadoData,
        setAgendadoData,
        procedimentos,
        setProcedimentos,
        statusTabela,
        setStatusTabela,
        listaBloqueado,
        setListaBloqueado,
        sessaostate,
        setSessaostate,
        montagemstate,
        setMontagem,
        components,
        setComponents,
        att,
        setAtt,
        idComponentAtual,
        setIdcomponentAtual,
        tituloSessaoAtual,
        setTituloSessaoAtual,
        idEditProntuario,
        setIdEditProntuario,
        pos,
        setPos,
        MontagemProntuario,
        setMontagemProntuario,
        setNameProntuario,
        NameProntuario,
        PosProntuario,
        setPosProntuario,
        Answer,
        setAnswer,
        respEdit,
        setRespEdit,
        AnswerEdit,
        setAnswerEdit,
        respView,
        setRespView,
        secaoFormulario,
        setSecaoFormulario,
        isEditPatient,
        setIsEditPatient,
        ViewPatient,
        setViewPatient,
        attatendente,
        setAttatendente,
        notification,
        setNotification,
        notfyatt,
        setNotatt,
        dataRoute,
        setDataRoute,
        validaProntuario,
        setvalidaProntuario,
        Location, 
        setLocation ,
        pacienteName , 
        setPacienteName,
        attAgenda, 
        setAttAgenda,
        attResumo, 
        setAttResumo,
        viewProntuario, 
        setViewProntuario, 
        RowIdProntuario,
        setRowIdProntuario,
        idProntuario, 
        setIdProntuario,
        validation, 
        setValidation,
        attValidation, 
        setAttValidation,
        attEncerraError, 
        setAttEncerraError,
        observationStep,
        setObservationStep,
        formPatientID,
        setFormPatientID,
        listaImgBase64, 
        setListaImgBase64,
        chartComponentsIndex,
        setChartComponentsIndex,
        sportsPhysiotherapyChartAnswers,
        setSportsPhysiotherapyChartAnswers

    } = context;

    return {
        count,
        setCount,
        agendadoData,
        secaoFormulario,
        setAgendadoData,
        setSecaoFormulario,
        procedimentos,
        setProcedimentos,
        statusTabela,
        setStatusTabela,
        listaBloqueado,
        setListaBloqueado,
        sessaostate,
        setSessaostate,
        montagemstate,
        setMontagem,
        components,
        setComponents,
        att,
        setAtt,
        idComponentAtual,
        setIdcomponentAtual,
        tituloSessaoAtual,
        setTituloSessaoAtual,
        idEditProntuario,
        setIdEditProntuario,
        pos,
        setPos,
        MontagemProntuario,
        setMontagemProntuario,
        setNameProntuario,
        NameProntuario,
        PosProntuario,
        setPosProntuario,
        Answer,
        setAnswer,
        respEdit,
        setRespEdit,
        AnswerEdit,
        setAnswerEdit,
        respView,
        setRespView,
        isEditPatient,
        setIsEditPatient,
        ViewPatient,
        setViewPatient,
        attatendente,
        setAttatendente,
        notification,
        setNotification,
        notfyatt,
        setNotatt,
        dataRoute,
        setDataRoute,
        validaProntuario,
        setvalidaProntuario,
        Location, 
        setLocation ,
        pacienteName , 
        setPacienteName,
        attAgenda, 
        setAttAgenda,
        attResumo, 
        setAttResumo,
        viewProntuario, 
        setViewProntuario,
        RowIdProntuario,
        setRowIdProntuario,
        idProntuario, 
        setIdProntuario,
        validation, 
        setValidation,
        attValidation, 
        setAttValidation,
        attEncerraError, 
        setAttEncerraError,
        observationStep,
        setObservationStep,
        formPatientID,
        setFormPatientID,
        listaImgBase64, 
        setListaImgBase64,
        chartComponentsIndex,
        setChartComponentsIndex,
        sportsPhysiotherapyChartAnswers,
        setSportsPhysiotherapyChartAnswers
    };
}
