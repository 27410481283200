import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonNext from '../StepButtonNext';
import StepButtonBack from '../StepButtonBack';
import { useStyles } from './style'

export default function Medicamentos({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  return (
    <div className={classes.medicines}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <p className="titulo-form">Usa medicamentos?:</p>
          <TextField
            id="select"
            select
            fullWidth
            variant="outlined"
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.medicines.useMedication}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                medicines: {
                  ...state.medicines,
                  useMedication: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
          >   
            <option value="" disabled selected>...</option>
            <option value="Yes">Sim</option>
            <option value="No">Não</option>
          </TextField>
        </Grid>

        <Grid item xs={4}>
          <p className="titulo-form">Controlado?:</p>
          <TextField
            id="select"
            fullWidth
            variant="outlined"
            placeholder='Nomes...'
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.medicines.controlledRemedies}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                medicines: {
                  ...state.medicines,
                  controlledRemedies: e.target.value
                }
              }))
            }}
          />   
        </Grid>
      </Grid>

      <div>
        <p className="titulo-form">Uso contínuo?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.medicines.continuousUsage}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              medicines: {
                ...state.medicines,
                continuousUsage: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          // error={error}
          // required={required}
          // disabled = {respEdit}
        >   
          <option value="" disabled selected>...</option>
          <option value="Yes">Sim</option>
          <option value="No">Não</option>
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Uso de antíconcepcional?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.medicines.contraceptiveUsage}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              medicines: {
                ...state.medicines,
                contraceptiveUsage: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          // error={error}
          // required={required}
          // disabled = {respEdit}
        >   
          <option value="" disabled selected>...</option>
          <option value="Yes">Sim</option>
          <option value="No">Não</option>
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Tem histórico de infiltração?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.medicines.infiltrationHistory}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              medicines: {
                ...state.medicines,
                infiltrationHistory: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          // error={error}
          // required={required}
          // disabled = {respEdit}
        >   
          <option value="" disabled selected>...</option>
          <option value="Yes">Sim</option>
          <option value="No">Não</option>
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Tomou antibiótico nos ultimos 6 meses?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.medicines.antibioticUsege}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              medicines: {
                ...state.medicines,
                antibioticUsege: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          // required={required}
          // error={error}
          // disabled = {respEdit}
        >   
          <option value="" disabled selected>...</option>
          <option value="Yes">Sim</option>
          <option value="No">Não</option>
        </TextField>
      </div>
      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}