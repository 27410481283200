import React, { useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { StateGlobal } from '../../../../ContextAPI/context'
import '../../css/style.css';

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function InfoProntuarioLateralEsquerdo() {
  const { secaoFormulario, setSecaoFormulario } = StateGlobal();

  const alteraSecao = (secaoFormulario) => {
    setSecaoFormulario(secaoFormulario);
  };

  return (
    <div className="lateralSecaoStep">
      <ThemeProvider theme={theme}>
        <div className="lateralSecaoStep-FormPront">
            <p className="lateralSecaoStep-nomePront">Cadastros</p>
            <div className="envolve-section-lateral-cadastro">
                <div onClick={()=>alteraSecao(1)} className={secaoFormulario===3?"section-lateral-cadastro active":"section-lateral-cadastro"}>
                    <p>Dados da Empresa</p>
                </div>
                <div onClick={()=>alteraSecao(2)} className={secaoFormulario===4?"section-lateral-cadastro active":"section-lateral-cadastro"}>
                    <p>Edição de senha</p>
                </div>
            </div>
        </div>
      </ThemeProvider>
    </div>
  );
}

