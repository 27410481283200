import React, { useState, useContext } from 'react';

import { IconButton } from '@material-ui/core';
import { BsPlusCircleFill } from 'react-icons/bs'
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { skeletalMuscleTypes } from '../../../../utils';
import { SportsPhysiotherapyContext } from '../../../../../../ContextAPI/SportsPhysiotherapyContext'
import SistemaMuscular from '../../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_muscular.png';
import './style.css'

export default function MusculoEsqueleticoFisioterapiaEsportiva() {
  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  const [tipo, setTipo] = useState('...');
  const [escala, setEscala] = useState('...')

  const type = 'musculoesqueletico'

  const escaladedor = [
    { escala: 0, cor: '#7DB3DC' },
    { escala: 1, cor: '#036EA2' },
    { escala: 2, cor: '#485779' },
    { escala: 3, cor: '#018064' },
    { escala: 4, cor: '#34AD3A' },
    { escala: 5, cor: '#E7E92B' },
    { escala: 6, cor: '#F8B302' },
    { escala: 7, cor: '#E47617' },
    { escala: 8, cor: '#E05414' },
    { escala: 9, cor: '#E02617' },
    { escala: 10, cor: '#9D2725' }
  ]
  
  function ChangeColor(escala) {
    let cor = ''

    escaladedor.map((item) => {
      if (item.escala.toString() === escala) {
        cor = item.cor
      }
    })

    return cor
  }

  return (
    <div className="CardTextoCurto-container" >
      <div className="container-card-input">
   
        <div className="titleEscale">
          <h3 className="h3-title-card-input">Mapa EVA - Sistema Muscular Esquelético</h3>
        </div>

        <div className="img-class">
          <img src={SistemaMuscular} alt="" />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>

          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple" >Tipo</InputLabel>
            <Select
              native
              label="Tipo"
              onChange={e => setTipo(e.target.value)}
              value={tipo}
              disabled = {sportsPhysiotherapyView}
            >
              <option>...</option>

              { skeletalMuscleTypes.map(muscle => (
                <option value={muscle.text}>
                  {muscle.value} - {muscle.text}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" >
            <InputLabel htmlFor="outlined-age-native-simple" >Intensidade</InputLabel>
            <Select
              native
              label="Intensidade"
              onChange={e => setEscala(e.target.value)}
              value={escala}
              disabled = {sportsPhysiotherapyView}
            >
              <option>...</option>

              { escaladedor.map( item => (
                <option value={item.escala}>
                  {item.escala}
                </option>
              ))}
            </Select>
          </FormControl>

          { !sportsPhysiotherapyView &&     
            <BsPlusCircleFill 
              style={{ 
                color: tipo && escala !== '...' ? '#27b8d2' : '#5d5d5d7a',
                fontSize: '30px', 
                cursor: tipo && escala !== '...' ? 'pointer' : ''
              }} 
              onClick={() => {
                if (tipo && escala !== '...') {
                  setSportsPhysiotherapyChartAnswers(state => ({
                    ...state,
                    training: {
                      ...state.training,
                      musclePain: [
                        ...state.training.musclePain,
                        { 
                          cor: ChangeColor(escala),
                          tipo: tipo,
                          escala: escala,
                          id: Math.random()
                        }
                      ]
                    }
                  }))
                  setEscala('...')
                  setTipo('...')
                }
              }} 
            />
          }
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }} >

          { sportsPhysiotherapyChartAnswers.training.musclePain.map((item, index) => {

            return (
              <div style={{ width: '100%', borderRadius: "5px", border: '1px solid gray', flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: "space-between" }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '500px' }}>
                  <div style={{ borderRadius: '50%', backgroundColor: item.cor, height: '20px', width: '20px', margin: '15px' }}></div>
                  <div>{item.tipo}</div></div>
                <div style={{ marginRight: '50px' }}>Dor: {item.escala}</div> <div>
                  { !sportsPhysiotherapyView &&
                    <IconButton
                      onClick={() => {
                        setSportsPhysiotherapyChartAnswers(state => ({
                          ...state,
                          training: {
                            ...state.training,
                            musclePain: [
                              ...state.training.musclePain.filter(muscle => (
                                muscle.id !== sportsPhysiotherapyChartAnswers.training.musclePain[index].id
                              ))
                            ]
                          }
                        }))
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}