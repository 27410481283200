import React, { useContext } from 'react'

import { InputAdornment, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import MusculoEsqueleticoFisioterapiaEsportiva from './components/MusculoEsqueleticoFisioterapiaEsportiva';
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { trainingVolume } from '../../utils';
import { useStyles } from './style'

function Training({ path }) {
  const classes = useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);
console.log(sportsPhysiotherapyChartAnswers)
  return(
    <div className={classes.training}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <p className="titulo-form">Tempo de treinamento?:</p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.trainingTime}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  trainingTime: e.target.value
                }
              }))
            }}
            placeholder="2 anos, 6 meses ..."
          /> 
        </Grid>

        <Grid item xs={4}>
          <p className="titulo-form">Volume de treino:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.trainingVolume}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  trainingVolume: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            
          >  
            <option value="" selected>horas</option> 
            <option value="1hr">1hr</option> 
            <option value="2hrs">2hrs</option> 
            <option value="3hrs">3hrs</option> 
            <option value="4hrs">4hrs</option> 
            <option value="5hrs">5hrs</option> 
            <option value="6hrs">6hrs</option> 
            <option value="7hrs">7hrs</option> 
            <option value="8hrs">8hrs</option> 
            <option value="9hrs">9hrs</option> 
            <option value="10hrs">10hrs</option>
          </TextField>
        </Grid>

        <Grid item xs={4}>
          <p className="titulo-form">Frequência de treinamento:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.trainingFrequency}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  trainingFrequency: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >  
            <option value=""  selected>...</option> 
            <option value="1">1 X/semana</option> 
            <option value="2">2 X/semana</option> 
            <option value="3">3 X/semana</option> 
            <option value="4">4 X/semana</option> 
            <option value="5">5 X/semana</option> 
            <option value="6">6 X/semana</option> 
            <option value="7">7 X/semana</option> 
            <option value="8">8 X/semana</option> 
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p className="titulo-form">Exercício físico extra atividade esportiva?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.extraTraining}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  extraTraining: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >  
            <option value=""  selected>...</option> 
            <option value="yes">Sim</option> 
            <option value="no">Não</option> 
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <p title='Supervisionado?:' className="titulo-form">Supervisionado?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.supervisedTraining}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  supervisedTraining: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >  
            <option value=""  selected>...</option> 
            <option value="yes">Sim</option> 
            <option value="no">Não</option> 
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <p title='Vezes na semana:' className="titulo-form">Vezes na semana:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.extraTrainingTimesAWeek}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  extraTrainingTimesAWeek: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >  
            <option value="" selected>...</option> 
            <option value="1">1 X/semana</option> 
            <option value="2">2 X/semana</option> 
            <option value="3">3 X/semana</option> 
            <option value="4">4 X/semana</option> 
            <option value="5">5 X/semana</option> 
            <option value="6">6 X/semana</option> 
            <option value="7">7 X/semana</option> 
            <option value="8">8 X/semana</option> 
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <p title='Horas por dia?:' className="titulo-form">Horas por dia?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.extraTrainingHoursPerDay}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  extraTrainingHoursPerDay: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >  
            <option value="" selected>...</option> 
            <option value="1hr">1hr</option> 
            <option value="2hrs">2hrs</option> 
            <option value="3hrs">3hrs</option> 
            <option value="4hrs">4hrs</option> 
            <option value="5hrs">5hrs</option> 
            <option value="6hrs">6hrs</option> 
            <option value="7hrs">7hrs</option> 
            <option value="8hrs">8hrs</option> 
            <option value="9hrs">9hrs</option> 
            <option value="10hrs">10hrs</option> 
          </TextField>
        </Grid>
      </Grid>

      <h3
        style={{
          color: '#27b8d2',
          marginTop: '40px',
          marginBottom: '20px'
        }}
      >
        Afecção
      </h3>

      <div>
        <p className="titulo-form">Diabetes?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.diabetes}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                diabetes: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
          <option value="familyHistory">Histórico Família, SIM</option> 
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Pressão Alta?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.highPressure}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                highPressure: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
          <option value="familyHistory">Histórico Família, SIM</option> 
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Tonturas?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.dizziness}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                dizziness: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
          <option value="familyHistory">Histórico Família, SIM</option> 
        </TextField>
      </div>
      
      <div>
        <p className="titulo-form">Câncer?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.cancer}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                cancer: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
          <option value="familyHistory">Histórico Família, SIM</option> 
        </TextField>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={8}>
          <p className="titulo-form">Histórico de cirurgia?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.surgeryHistory}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  surgeryHistory: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >  
            <option value=""  selected>...</option> 
            <option value="yes">Sim</option> 
            <option value="no">Não</option> 
          </TextField>
        </Grid>

        <Grid item xs={4}>
          <p className="titulo-form">Quais cirurgias?:</p>
          <TextField
            fullWidth
            variant='outlined'
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.training.whichSurgeries}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                training: {
                  ...state.training,
                  whichSurgeries: e.target.value
                }
              }))
            }}
          />
        </Grid>
      </Grid>

      <h3
        style={{
          color: '#27b8d2',
          marginTop: '40px',
          marginBottom: '40px'
        }}
      >
        Sintomas de Dor
      </h3>

      <div>
        <p className="titulo-form">Você sente dor em repouso?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.painAtRest}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                painAtRest: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Você sente dor durante o movimento comum?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.jointMovementPain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                jointMovementPain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Você sente dor durante sua prática esportiva?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.sportsPracticePain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                sportsPracticePain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
        </TextField>
      </div>

      <div>
        <p className="titulo-form">Você está com dor neste momento?:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.hasPainRightNow}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                hasPainRightNow: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >  
          <option value=""  selected>...</option> 
          <option value="yes">Sim</option> 
          <option value="no">Não</option> 
        </TextField>
      </div>

      <div style={{ marginTop: '40px' }}>
        <p className="titulo-form">Sintoma:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.symptom}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                symptom: e.target.value
              }
            }))
          }}
        /> 
      </div>

      <div >
        <p className="titulo-form">Diagnóstico Clínico:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.clinicalDiagnosis}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                clinicalDiagnosis: e.target.value
              }
            }))
          }}
        /> 
      </div>

      <div >
        <p className="titulo-form">Diagnóstico Fisioterapêutico:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.physiotherapeuticDiagnosis}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                physiotherapeuticDiagnosis: e.target.value
              }
            }))
          }}
        /> 
      </div>

      <div >
        <p className="titulo-form">Queixa Principal:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.chiefComplaint}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                chiefComplaint: e.target.value
              }
            }))
          }}
        /> 
      </div>

      <div >
        <p className="titulo-form">HMA:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.hma}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                hma: e.target.value
              }
            }))
          }}
        /> 
      </div>

      <div>
        <p className="titulo-form">HMP:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.training.hmp}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              training: {
                ...state.training,
                hmp: e.target.value
              }
            }))
          }}
        /> 
      </div>
      
      <div>
        <p className="titulo-form">Aponte o local e/ou trajeto e tamanho da dor ?:</p>
        {/* musclePain here */}
        <MusculoEsqueleticoFisioterapiaEsportiva />
      </div>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}

export default Training;