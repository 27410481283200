import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularLoad from '../../../Components/Load';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import conexao from '../../../Services/api'
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import '../css/Agenda.css'
import { currency, currencyinput } from './Validate'
import ModalExcluir from '../../../Components/Modais/ModalExclusao';
import { campovazio } from '../../../Components/ReduxTeste/ValidateForm'
import { calendarFormat } from 'moment';



const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  pConfig: {
    color: '#8F8F8F',
    minWidth: '30%',
    maxWidth: '30%',
    textAlign: 'left',
    wordWrap: 'break-word'
  },
  envolveConfig: {
    borderBottom: '1px solid #8F8F8F',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  envolveConfigs: {
    height: '35vh',
    overflow: 'auto',
    backgroundColor: 'white',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    marginTop: '1rem',
    marginBottom: '1.5rem'
  },
  dFlex: {
    display: 'flex',
  },
  dialog: {
    width: '100%',
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);


export default function ModalProcedimentos(props) {
  const classes = useStyles();
  const History = useHistory();

  const initialvalues = {
    procedimento: '',
    valor: '',
    clinic_commission: 0,
  }

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(initialvalues)
  const [procedimento, setProcedimento] = useState();
  const [valor, setValor] = useState('')
  const [comissao, setComissao] = useState('')
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [itens, setItens] = useState();
  const [procId, setProcId] = useState();
  const [editar, setEditar] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [load, setLoad] = useState(false)
  const [titulo, setTitulo] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [optionDelete, setOptionDelete] = React.useState('');

  const [validatesx, setvalidatesx] = useState([
    { name: 'procedimento', funcao: campovazio, error: false, value: '' }
    , { name: 'valor', funcao: campovazio, error: false, value: '' },

  ]);

  const initialvalidate = [
    { name: 'procedimento', funcao: campovazio, error: false, value: '' }
    , { name: 'valor', funcao: campovazio, error: false, value: '' },
  ]

  function onchangeform(e) {
    const { name, value } = e.target
    validatesx.map((item) => {
      if (item.name === name) {
        item.error = item.funcao(value)
        item.value = value
      }
    })
    const arr = validatesx.slice()
    setvalidatesx(arr)
  }


  useEffect(() => {
    listaProcedimentos();
  }, [])
  
  useEffect(() => {
    if (props.open)
      listaProcedimentos()

    setOpen(props.open);    
  }, [props])

  useEffect(()=>{
    setvalidatesx(initialvalidate)
  },[props.open])
  
  const closeModal = () => {
    setOpen(false);
    props.onChange(false);
    setEditar(false);
    clearModal()
  }

  const cancel = () => {
    setEditar(false);
    clearModal()
  }

  // usar essa função para o modal delete
  const handleModal = (value) => {
    setOptionDelete(value);
    if (value) {
      deletaProcedimento()
    } else {
      handleClose()
    }
  };

  // usar essa função para o modal delete
  const handleClose = () => {
    setOpenModalDelete(false);
    setProcId('');
    setEditar(false);
  }

  async function listaProcedimentos() {
    var userId = '';

    if (localStorage.getItem('selectedUser')) {
      userId = localStorage.getItem('selectedUser');
      await conexao.get(`/schedule/type/list/${userId}`)
        .then((response) => {
          setItens(response.data.data)
          localStorage.setItem('doctorScheduleTypes', JSON.stringify(response.data.data))
        })
    }
  }

  function updateList(item) {
    var array = JSON.parse(localStorage.getItem('doctorScheduleTypes'));

    // Edição
    if (procId) {
      array.forEach(function (proc, index) {
        if (proc.id == item.id)  array[index] = item;
      })
    }
    else {
      array.push (item);
    }

    localStorage.setItem('doctorScheduleTypes', JSON.stringify(array))
    setItens(array);
  }

  async function save(e) {  
    const elementoProcedimento = document.getElementsByName('procedimento')[0]
    const elementoValor = document.getElementsByName('valor')[0]
 
    validatesx.map((item) => {
      if(item.name === elementoProcedimento.name ){
        if (elementoProcedimento.value === '') {
            item.error = true   
        }
      }
      if(item.name === elementoValor.name ){
        if (elementoValor.value === '') {
          item.error = true   
        }
      }
    })

    const arr = validatesx.slice()
    setvalidatesx(arr)

    setLoad(true)
    e.preventDefault();

    if (procedimento && valor) {
      var userId = '';
      var value = 0;
      value = valor.toString().replace('.', '');
      value = value.toString().replace(',', '.');
      // if (valor) {
      //   value = valor.toString().replace(/\D/g, "");
      //   value = value.replace(/(\d)(\d{2})$/, "$1.$2"); 
      // }

      var commission = comissao || 0;

      console.log('valor')
      console.log(parseFloat(value))

      if (!editar) {
        
        if (localStorage.getItem('selectedUser')) {
          userId = localStorage.getItem('selectedUser');

          await conexao.post('/schedule/type/create',
            {
              "name": procedimento,
              "value": parseFloat(value),
              "clinic_commission": parseFloat(commission),
              "user_id": userId
            })
            .then((response) => {
              setLoad(false)
              alert('Procedimento salvo com sucesso', 'success');
              
              clearModal();
              updateList(response.data.data);
              
              setTimeout(() => {
                alert('', '');
              }, 1500);

            }).catch((error) => {
              setLoad(false)
              alert('Erro ao criar procedimento, tente novamente', 'error');

              setTimeout(() => {
                alert('', '');
              }, 1500);
            })

        } else {
          alert('Algo errado aconteceu. Selecione novamente o profissional para criar o procedimento.', 'error');
        }

      } else {

        setLoad(true)
        await conexao.put(`/schedule/type/update/${procId}`,
        {
          "name": procedimento,
          "value": parseFloat(value),
          "clinic_commission": parseFloat(commission),
        })
        .then((response) => {
          console.log('debug_0')
          setLoad(false)
          alert('Procedimento alterado com sucesso', 'success');
          
          console.log('debug_1')
          clearModal();
          console.log('debug_2')
          updateList(response.data.data);
          console.log('debug_3')
          setTimeout(() => {
            alert('', '');
            setEditar(false);
          }, 1500);
          
        }).catch((error) => {
          console.log(error)
          setLoad(false)
          alert('Erro ao editar procedimento, tente novamente', 'error');

          setTimeout(() => {
            alert('', '');
          }, 1500);
        })
      }
    } else {
      setLoad(false)
      alert('Preencha os campos corretamente', 'error');

      setTimeout(() => {
        alert('', '');
      }, 1500);
    }
  }

  // usar essa função para o modal delete
  function deletaProcedimentos(id) {
    setTitulo('Exclusão de procedimento');
    setDescricao('Deseja excluir esse procedimento?');
    setProcId(id);
    setOpenModalDelete(true);
  }

  async function deletaProcedimento() {
    setLoad(true)
    await conexao.delete(`/schedule/type/${procId}`)
      .then((response) => {
        setLoad(false)
        setOpenModalDelete(false);
        alert('Procedimento excluído com sucesso', 'success');
        listaProcedimentos();

        setTimeout(() => {
          alert('', '');
        }, 1500);

      }).catch((error) => {
        setLoad(false)
        setOpenModalDelete(false);
        alert('Erro ao deletar procedimento, tente novamente', 'error');
        listaProcedimentos();

        setTimeout(() => {
          alert('', '');
        }, 1500);
      })
  }

  function editarProcedimento(id) {
    setProcId(id);
    conexao.get(`/schedule/type/${id}`)
      .then((response) => {
        var proc = response.data[0]
        setValor(proc.value)
        setComissao(proc.clinic_commission)
        setProcedimento(proc.name)
        setEditar(true);
      })
  }

  function clearModal()
  {
    setProcId('');
    setComissao('')
    setValor('')
    setProcedimento('')
  }

  function alert(message, type)
  {
    setMessage(message)
    setTypeAlert(type)
  }

  return (
    <div className="Modal-agendamento">
      <ThemeProvider theme={theme}>
          <Dialog open={open} aria-labelledby="Modal-title-agendamento" maxWidth="lg" className={classes.dialog}>
            <form id="" onSubmit={save} className={classes.modalColor} autoComplete="off">
              <DialogTitle id="Modal-title-agendamento">
                <div className="title-modal-close">
                  Procedimentos
                  <CloseIcon onClick={closeModal} />
                </div>
              </DialogTitle>
              <DialogContent>

                {message ?
                  <div className="Modal-agendamento-alert">
                    <Alert variant="filled" severity={typeAlert}>
                      {message}
                    </Alert>
                  </div>
                  : ''}
                  <div>
                    <TextField
                      InputLabelProps={procedimento ? { shrink: true } : ''}
                      className={classes.input}
                      name='procedimento'
                      value={procedimento}
                      onChange={(e) => {
                        setProcedimento(e.target.value)
                        onchangeform(e)
                      }}
                      error={validatesx[0].error}
                      helperText={validatesx[0].error ? 'Este campo precisa ser preenchido' : ''}
                      type="text"
                      readonly={localStorage.getItem('profileId') === '2' ? 'readonly ' : 'none'}
                      id=""
                      label="Nome do procedimento"
                      variant="outlined"
                    />
                    <TextField
                      className={classes.input}
                      name='valor'
                      value={valor}
                      onChange={(e) => {
                        setValor(currencyinput(e.target.value))
                        console.log(currencyinput(e.target.value))
                        onchangeform(e)
                      }}
                      error={validatesx[1].error}
                      helperText={validatesx[1].error ? 'Este campo precisa ser preenchido' : ''}

                      type="text"
                      readonly={localStorage.getItem('profileId') === '2' ? 'readonly ' : 'none'}
                      id=""
                      label="Valor do procedimento"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                    />
                  </div>
                  <div>
                    {localStorage.getItem('profileId') === '2' ?
                      <TextField
                        className={classes.input}
                        name='comissao'
                        value={comissao}
                        onChange={(e) => {
                          setComissao(currencyinput(e.target.value))
                          onchangeform(e)
                        }}
                        error={validatesx[1].error}
                        type="text"
                        //required
                        id=""
                        label="Comissão da clínica"
                        variant="outlined"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    : ''}
                  </div>
                <DialogActions>
                  <Button onClick={cancel} type="button"  className="color-secondary">
                    Cancelar
                  </Button>
                  {editar ?
                    <Button type="submit" variant="contained" className="color-primary">
                      {!load ? 'Salvar alteração' : <CircularLoad />}
                    </Button>
                    :
                    <Button type="submit" variant="contained" className="color-primary">
                      {!load ? 'Incluir' : <CircularLoad />}
                    </Button>
                  }
                </DialogActions>

                {editar ? '' :
                  <div className={classes.envolveConfigs}>
                    
                        {itens ?
                          itens.length > 0 ?
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Procedimento</TableCell>
                                  <TableCell>Valor</TableCell>
                                  {localStorage.getItem('profileId') === '2' ? 
                                    <TableCell>Comissão da clínica</TableCell>
                                  : ''}
                                  <TableCell>&nbsp;</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {itens.map((item) => (
                                  <TableRow key={item.id}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>R$ {item.value}</TableCell>
                                    {localStorage.getItem('profileId') === '2' ? 
                                      <TableCell>{item.clinic_commission } %</TableCell>
                                    : ''}
                                    <TableCell>
                                      <IconButton size="small" onClick={() => editarProcedimento(item.id)} aria-label="edit" className={classes.margin}>
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton size="small" onClick={() => deletaProcedimentos(item.id)} aria-label="delete" className={classes.margin}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                                }
                              </TableBody>
                            </Table>
                          : <Alert severity="warning">Nenhum procedimento cadastrado por este profissional</Alert>
                        : ''
                      }
                  </div>
                }
              </DialogContent>
            </form>
          </Dialog>
        <ModalExcluir
          handleModal={handleModal}
          open={openModalDelete}
          load={load}
          titulo={titulo}
          descricao={descricao}
        />
      </ThemeProvider>
    </div>
  );
}