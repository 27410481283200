import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Graficos from './Graficos';
import './css/dashboard.css';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import conexao from '../../Services/api';
const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [showModalTutorial, setModalTutorial] = useState()
  const [openModal, setOpenModal] = useState(false);

  function closeModal(){
    conexao.post(`/statusModalUpdate/${localStorage.getItem('userIdModalUpdate')}`,
      {
        show_painel: "false", 
      }
    )
  }

  
  function GetModal(){
    conexao.get(`/getStatusPainel/${localStorage.getItem('userIdModalUpdate')}`).then((res) => {
      console.log(res.data.data.show_painel)
      setOpenModal(res.data.data.show_painel == "false" ? false : true)
    })
  }

  useEffect(() => {
    GetModal()
  }, [])

  return (
    <div className={localStorage.getItem('containerClass')}>
    <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='header-tutorial'> 
          <div>
           <span className='header-tutorial-title'>Tutorial - Meu Perfil, Gerar Guia e Pacientes</span>
          </div>
          <CloseIcon className='header-close-icon' onClick={() => {
            closeModal()
            setOpenModal(false)
          }}/>
        </div>

        <div className='header-body'>
          <iframe
            className='video-container'
            width="560"
            height="315"
            src="https://www.youtube.com/embed/7v6Cv_9HHdI?si=eTpsXnP-ANTTOMJV?autoplay=1&controls=1"
            frameBorder="0"
            allow="acceleallowFullScreenrometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
     
        </div>
        <div className='desc-tutorial'>
          <span>No Clinic Plus você pode preencher seu perfil profissional, e assim gerar documentos personalizados com seus dados, para imprimir ou compartilhar. 
          Nesse vídeo você também verá que pode visualizar um painel com o seu fluxo de atendimentos e como fazer o cadastro de seus pacientes.</span>
        </div>


        {
          localStorage.getItem('loginType') == "CLINIC" &&
          <>
              <div className='header-tutorial'> 
                <div>
                  <span className='header-tutorial-title'>Tutorial - Plano Clinica</span>

                </div>
              </div>

              <div className='header-body'>
                <iframe
                  className='video-container'
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Ec9r9FEhLwI?si=eTpsXnP-ANTTOMJV?autoplay=1&controls=1"
                  frameBorder="0"
                  allow="acceleallowFullScreenrometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
          
              </div>
              <div className='desc-tutorial'>
                <span>No Plano Clínica você pode adicionar até 5 profissionais, e assim ter uma agenda integrada. Pode também visualizar o fluxo de atendimentos da clínica e relatório financeiro, com o valor de comissão selecionado para cada profissional.</span>
              </div>
          </>
          
        }
    
      </Dialog>
      <Graficos/>
    </div>
  );
}