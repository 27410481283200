import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';

import { useStyles } from './style'

export default function VisaA({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function getVisaAScore() {
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.visaA).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return score ? (score).toFixed(2) : 0;
  }

  return (
    <div className={classes.visaA}>
      <p style={{ fontWeight: '500' }}>
        NESTE QUESTIONARIO, O TERMO DOR SE REFERE ESPECIFICAMENTE À DOR NA REGIÃO DO TENDÃO DE AQUILES. 
      </p>

      <article>
        <p className='titulo-form'>
          1.Quando você se levanta, pela manhã, por quantos minutos sente rígida a região do tendão de Aquiles?
        </p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          variant="outlined"
          value={sportsPhysiotherapyChartAnswers.visaA.stiffAchillesTendon}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaA: {
                ...state.visaA,
                stiffAchillesTendon: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0 min</option>
          <option value="1">10 min</option>
          <option value="2">20 min</option>
          <option value="3">30 min</option>
          <option value="4">40 min</option>
          <option value="5">50 min</option>
          <option value="6">60 min</option>
          <option value="7">70 min</option>
          <option value="8">80 min</option>
          <option value="9">90 min</option>
          <option value="10">100 min</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>
          2.Quando você está preparado/aquecido para o dia, sente dor quando alonga o tendão de Aquiles ao máximo na borda de um degrau, mantendo os joelhos bem esticados?  
        </p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          className={classes.textField}
          variant="outlined"
          value={sportsPhysiotherapyChartAnswers.visaA.painWhenStretching}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaA: {
                ...state.visaA,
                painWhenStretching: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>
          3. Após andar em uma superfície plana por 30 minutos, você sente dor no tendão de Aquiles nas próximas duas horas? (Se a dor impedir você de andar em uma superfície plana por 30 minutos, marque 0 nessa questão).  
        </p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          className={classes.textField}
          variant="outlined"
          value={sportsPhysiotherapyChartAnswers.visaA.flatSurface30min}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaA: {
                ...state.visaA,
                flatSurface30min: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>
          4. Você sente dor descendo escadas em um ritmo normal?  
        </p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          className={classes.textField}
          variant="outlined"
          value={sportsPhysiotherapyChartAnswers.visaA.normalRhythmStairs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaA: {
                ...state.visaA,
                normalRhythmStairs: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>
          5.Você sente dor durante ou imediatamente após ficar nas pontas dos pés, com apenas uma perna, por 10 vezes?  
        </p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          className={classes.textField}
          variant="outlined"
          value={sportsPhysiotherapyChartAnswers.visaA.tiptoePain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaA: {
                ...state.visaA,
                tiptoePain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>
          6. Quantos pulos, com uma perna só, você consegue fazer sem sentir dor?  
        </p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          className={classes.textField}
          variant="outlined"
          value={sportsPhysiotherapyChartAnswers.visaA.jumpingWithOneLeg}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaA: {
                ...state.visaA,
                jumpingWithOneLeg: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </TextField>
      </article>

      <p className='titulo-form'>
        7. Você está praticando algum esporte ou outra atividade física atualmente?
      </p>
      <TextField
        id="select"
        select
        fullWidth
        variant="outlined"
        disabled={sportsPhysiotherapyView}
        value={sportsPhysiotherapyChartAnswers.visaA.playingSports}
        onChange={e => {
          setSportsPhysiotherapyChartAnswers(state =>({
            ...state,
            visaA: {
              ...state.visaA,
              playingSports: e.target.value
            }
          }))
        }}
        SelectProps={{
          native: true,
        }}
      >   
        <option value="" selected>...</option>
        <option value="0">Não</option>
        <option value="4">Treinamento e/ou competição com restrição</option>
        <option value="7">Treinamento sem restrição mas não competindo no mesmo nível anterior ao início dos sintomas</option>
        <option value="10">Competindo no mesmo nível oi nível mais alto do que quando os sintomas começaram</option>
      </TextField>

      <article>
        <div>
          <p className='titulo-form'>8.Por favor, complete somente uma das questões A, B ou C, conforme a explicação abaixo:</p>
        </div>
        
        <div style={{ marginTop: '10px' }}>
          <p className='titulo-form'>
            8A. Se você não sente dor enquanto pratica esportes que exigem carga no tendão de 
            Aquiles, por quanto tempo você consegue treinar/praticar? 
          </p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            className={classes.textField}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.visaA.timeTraining}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                visaA: {
                  ...state.visaA,
                  timeTraining: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
          >   
            <option value="" selected>...</option>
            <option value="0">Não consigo treinar/Praticar</option>
            <option value="7">1-10min</option>
            <option value="14">11-20min</option>
            <option value="21">21-30min</option>
            <option value="30">+ 30min</option>
          </TextField>
        </div>

        <div style={{ marginTop: '20px' }}>
          <p className='titulo-form'>
            8B. Se você sente alguma dor enquanto pratica esportes que exigem carga no tendão de Aquiles, mas isso não te impede de completar seu treinamento/prática, por quanto tempo você consegue treinar/praticar? 
          </p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            className={classes.textField}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.visaA.painTraining}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                visaA: {
                  ...state.visaA,
                  painTraining: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
          >   
            <option value="" selected>...</option>
            <option value="0">Não consigo treinar/Praticar</option>
            <option value="4">1-10min</option>
            <option value="10">11-20min</option>
            <option value="14">21-30min</option>
            <option value="20">+ 30min</option>
          </TextField>
        </div>

        <div style={{ marginTop: '20px' }}>
          <p className='titulo-form'>
            8C. Se você sente dor que te impede de completar seu treinamento/prática em esportes que exigem carga no tendão de Aquiles, por quanto tempo você consegue treinar/praticar? 
          </p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            className={classes.textField}
            variant="outlined"
            value={sportsPhysiotherapyChartAnswers.visaA.painHindersMe}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state =>({
                ...state,
                visaA: {
                  ...state.visaA,
                  painHindersMe: e.target.value
                }
              }))
            }}
            SelectProps={{
              native: true,
            }}
          >   
            <option value="" selected>...</option>
            <option value="0">Não consigo treinar/Praticar</option>
            <option value="2">1-10min</option>
            <option value="5">11-20min</option>
            <option value="7">21-30min</option>
            <option value="10">+ 30min</option>
          </TextField>
        </div>

        <p className={classes.score}>Escore: {getVisaAScore()} {'->'} ({'< 80'} ALTA SEVERIDADE / {'> 80'} BAIXA SEVERIDADE)</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}