import React, { useContext } from 'react'

import { InputAdornment, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Alimentacao({ path }){
  const classes = useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  return (
    <div className={classes.alimentation}>
      <div>
        <p className="titulo-form">Alimentação:</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          // required={required}
          // disabled={respView}
          value={sportsPhysiotherapyChartAnswers.alimentation.alimentation}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              alimentation: {
                ...state.alimentation,
                alimentation: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          // error={error}
          variant="outlined"
          // disabled = {respEdit}
        >   
          <option value="" disabled selected>...</option>
          <option value="great">Ótima</option>
          <option value="good">Boa</option>
          <option value="moderate">Moderada</option>
          <option value="regular">Regular</option>
          <option value="bad">Ruim</option>
        </TextField>
      </div>

      <Grid 
        container 
        spacing={1} 
      >
        <Grid item xs={9}>
          <p className="titulo-form">Suplementação:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            // required={required}
            // disabled={respView}
            value={sportsPhysiotherapyChartAnswers.alimentation.hasSuplementation}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  hasSuplementation: e.target.value
                }
              }))
            }}
            SelectProps={{
                native: true,
            }}
            // error={error}
            variant="outlined"
            // disabled = {respEdit}
          >   
            <option value="" disabled selected>...</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <p className="titulo-form">Orientada:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            // value={value}
            // required={required}
            // disabled={respView}
            value={sportsPhysiotherapyChartAnswers.alimentation.oriented}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  oriented: e.target.value
                }
              }))
            }}
            SelectProps={{
                native: true,
            }}
            // error={error}
            variant="outlined"
            // disabled = {respEdit}
          >   
            <option value="" disabled selected>...</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </TextField>
        </Grid>     
      </Grid>

      <Grid 
        container 
        spacing={2}
      >
        <Grid item xs={6}>
          <p className="titulo-form">Ingere bebida alcoólica?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            // value={value}
            // required={required}
            // disabled={respView}
            value={sportsPhysiotherapyChartAnswers.alimentation.drinkAlcohol}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  drinkAlcohol: e.target.value
                }
              }))
            }}
            SelectProps={{
                native: true,
            }}
            // error={error}
            variant="outlined"
            // disabled = {respEdit}
          >   
            <option value="" disabled selected>...</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <p className="titulo-form">Vezes por semana?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            // value={value}
            // required={required}
            // disabled={respView}
            value={sportsPhysiotherapyChartAnswers.alimentation.drinkAlcoholTimesAWeek}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  drinkAlcoholTimesAWeek: e.target.value
                }
              }))
            }}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
            // error={error}
            // disabled = {respEdit}
          > 
            <option value="" disabled selected>...</option>  
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <p className="titulo-form">Quantidade?:</p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.alimentation.drinkAlcoholAmount}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  drinkAlcoholAmount: e.target.value
                }
              }))
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">Litros</InputAdornment>,
            }}
          /> 
        </Grid>
      </Grid>

      <Grid 
        container
        spacing={1}
      >
        <Grid item xs={6}>
          <p className="titulo-form">Tabagista?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.alimentation.isSmoker}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  isSmoker: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            // onChange={(e) => handleChangeInput(e)}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
            // error={error}
            // disabled = {respEdit}
          > 
            <option value="" disabled selected>...</option>  
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <p className="titulo-form">Vezes por semana?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.alimentation.smokerTimesAWeek}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  smokerTimesAWeek: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            // onChange={(e) => handleChangeInput(e)}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
            // error={error}
            // disabled = {respEdit}
          > 
            <option value="" disabled selected>...</option>  
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <p className="titulo-form">Quantidade?:</p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.alimentation.smokerAmount}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  smokerAmount: e.target.value
                }
              }))
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">Maços</InputAdornment>,
            }}
          /> 
        </Grid>
      </Grid>

      <Grid 
        container 
        spacing={1} 
      >
        <Grid item xs={9}>
          <p className="titulo-form">Fez ou faz uso de substâncias ilícitas?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.alimentation.drugsUsage}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  drugsUsage: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            // onChange={(e) => handleChangeInput(e)}
            SelectProps={{
                native: true,
            }}
            // error={error}
            variant="outlined"
            // disabled = {respEdit}
          >   
            <option value="" disabled selected>...</option>
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <p className="titulo-form">Qual?:</p>
          <TextField
            id="select"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            value={sportsPhysiotherapyChartAnswers.alimentation.whichDrug}
            onChange={e => {
              setSportsPhysiotherapyChartAnswers(state => ({
                ...state,
                alimentation: {
                  ...state.alimentation,
                  whichDrug: e.target.value
                }
              }))
            }}
            // required={required}
            // disabled={respView}
            // onChange={(e) => handleChangeInput(e)}
            SelectProps={{
                native: true,
            }}
            // error={error}
            variant="outlined"
            // disabled  -> se o value do select de cima for 'yes' ativa 
          >   
            <option value="" disabled selected>...</option>
            <option value="cannabis">Maconha</option>
            <option value="cocaine">Cocaína</option>
            <option value="crack">Crack</option>
            <option value="ecstasy">Ecstasy</option>
            <option value="mdma">MDMA</option>
            <option value="heroine">Heroína</option>
            <option value="amphetamines">Anfetaminas</option>
            <option value="lsd">LSD</option>
            <option value="others">Outras</option>
          </TextField>
        </Grid>     
      </Grid>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}