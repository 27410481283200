import React, { useState, useEffect } from 'react';

import './css/header.css';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
// import FilterIcon from '@material-ui/icons/Filter';
import FilterIcon from '@material-ui/icons/FilterList';
import InputBase from '@material-ui/core/InputBase';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import LocalPrintshopSharpIcon from '@material-ui/icons/LocalPrintshopSharp';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ModalAddAgendamento from '../../Modais/ModalAgendamento';
import conexao from '../../../../Services/api'
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
//import ModalGerarGuia from '../../Modais/ModalGerarguia';
import ModalResultadoPesquisa from '../../Modais/ModalResultado';
import ModalProcedimentos from '../../Modais/ModalProcedimentos';
import { saveAs } from 'file-saver';


import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"


const useStyles = makeStyles((theme) => ({
  headerAgenda: {
    paddingTop: '0rem',
    paddingBottom: '1rem'
  },
  form: {
    width: '35%',
    border: 'none'
  },
  search: {
    background: '#FFFFFF',
    boxShadow: '0px 6px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '350px',

  },
  iconSearch: {
    color: '#8F8F8F'
  },
  legend: {
    width: '130px'
  }
}));

export default function HeaderTabela(props) {

  const classes = useStyles();
  const [modalAddAgendamento, setModalAddAgendamento] = useState(false);

  const [modalResultado, setModalResultado] = useState(false);
  const [modalProcedimentos, setModalProcedimentos] = useState(false);
  const [modalResultadoPesquisa, setModalResultadoPesquisa] = useState('');
  const [resultadoPesqAgen, setResultadoPesqAgen] = useState('');
  const [resultadoPesqRea, setResultadoPesqRea] = useState('');
  const [resultadoPesqCan, setResultadoPesqCan] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [patientName, setPatientName] = useState('');
  const History = useHistory();
  const [proc, setProc] = useState();
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [MessageError, setMessageError] = useState('')

  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [doctorsList, setDoctorsList] = useState([])
  const [showDoctors, setShowDoctors] = useState(false)

  const [ load2 , setLoad2] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('profileId') == 2 || localStorage.getItem('profileId') == 4)
    {
      setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
      setSelectedUser(parseInt(localStorage.getItem('selectedUser')));

      // listaDadosMedico();
      setDoctorsList(JSON.parse(localStorage.getItem('clinicDoctors')));
      setShowDoctors(true);
      setDoctorName(localStorage.getItem('doctorName'))
    }
    else
    {
      setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
      setSelectedUser(localStorage.getItem('userId'));
      setDoctorName(localStorage.getItem('doctorName'))
    }

    setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
    setSelectedUser(parseInt(localStorage.getItem('selectedUser')));

    // if (selectedUser)
    // {
    //   conexao.get(`/schedule/type/${selectedUser}`).then((res) => {
    //     setProc(res.data)
    //   }).catch((error) => {
  
    //   })
    // }
  }, [])

  const busca = (event) => {
    console.log(patientName)
    if (event.key === 'Enter' || event._reactName === 'onClick') {
        setLoad(true)
        buscarConsAge();
        buscarConsRea();
        buscarConsCan();
        setModalResultadoPesquisa(true)
    }
  }

  async function buscarConsAge() {      
    // await conexao.post('/schedule/patient',
    await conexao.post('/schedule/events/filter',
      {
        "user_id": selectedUser,
        "patient": patientName,
        "status": "AGENDADO"
      })
      .then((response) => {
    
        setResultadoPesqAgen(response.data)
        setMessage('')
        setTypeAlert('success')
        if (resultadoPesqAgen.data !== '' || resultadoPesqRea.data !== '' || resultadoPesqCan.data !== '') {
          setLoad(false)
        }
      }).catch((error) => {
        setLoad(false)
        if (resultadoPesqAgen.data !== '' && resultadoPesqRea.data !== '' && resultadoPesqCan.data !== '') {
          // setMessage('Paciente não encontrado, verifique o nome e tente novamente.')
          // setTypeAlert('error')
          // setTimeout(() => {
          //   setMessage('')
          //   //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          // }, 3000);
        }
      }
    )
  }

  async function buscarConsRea() {
    // await conexao.post('/schedule/patient',
    await conexao.post('/schedule/events/filter',
      {
        "user_id": selectedUser,
        "patient": patientName,
        "status": "REALIZADO"
      })
      .then((response) => {
        setResultadoPesqRea(response.data)
        setMessage('')
        setTypeAlert('success')
        if (resultadoPesqAgen.data !== '' || resultadoPesqRea.data !== '' || resultadoPesqCan.data !== '') {
          // setModalResultadoPesquisa(true)
          setLoad(false)
        }

      }).catch((error) => {
        setLoad(false)
        if (resultadoPesqAgen.data !== '' && resultadoPesqRea.data !== '' && resultadoPesqCan.data !== '') {
          // setMessage('Paciente não encontrado, verifique o nome e tente novamente.')
          // setTypeAlert('error')
          // setTimeout(() => {
          //   setMessage('')
          //   //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          // }, 3000);
        }
      }
    )
  }

  async function buscarConsCan() {
    // await conexao.post('/schedule/patient',
    await conexao.post('/schedule/events/filter',
      {
        "user_id": selectedUser,
        "patient": patientName,
        "status": "CANCELADO"
      })
      .then((response) => {
        setResultadoPesqCan(response.data)
        setMessage('')
        setTypeAlert('success')
        if (resultadoPesqAgen.data !== '' || resultadoPesqRea.data !== '' || resultadoPesqCan.data !== '') {
          // setModalResultadoPesquisa(true)
          setLoad(false)
        }

      }).catch((error) => {
        setLoad(false)
        if (resultadoPesqAgen.data !== '' && resultadoPesqRea.data !== '' && resultadoPesqCan.data !== '') {
          // setMessage('Paciente não encontrado, verifique o nome e tente novamente.')
          // setTypeAlert('error')
          // setTimeout(() => {
          //   setMessage('')
          //   //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          // }, 3000);
        }
      }
    )
  }

  const openModalAddAgendamento = () => {
    setModalAddAgendamento(true)
  }

  const openModalResultadoPesquisa = () => {
    console.log('CLICK')
    console.log('openModalResultadoPesquisa')
    setModalResultadoPesquisa(true)
  }

  const openModalProcedimentos = () => {
    setModalProcedimentos(true)
  }
  const alteraEstadoModalProcedimentos = (modalProcedimentos) => {
    setModalProcedimentos(modalProcedimentos);
  };

  const alteraEstadoModal = (modalAddAgendamento) => {
    setModalAddAgendamento(modalAddAgendamento);

  };

  const alteraEstadoModalResultadoPesquisa = (modalResultado) => {

    if (!modalResultado) {
      setResultadoPesqAgen('')
      setResultadoPesqRea('')
      setResultadoPesqCan('')
    }
    setModalResultadoPesquisa(modalResultado);
  };

  function getPdf() {
    setLoad(true)
    
    // let Doctor_ID = localStorage.getItem('selectedDoctor')
    conexao.get(`/schedule/print/${selectedUser}`, {responseType: 'arraybuffer'}).then((res) => {

        var FileSaver = require('file-saver');
        var file = new Blob([res.data], {type: 'application/pdf'});
        var fileURL = URL.createObjectURL(file);

        FileSaver.saveAs(fileURL)

        setLoad(false)
      
    }).catch((error) => {

      setTimeout(() => {
        setMessageError("Sem agendamentos para o dia de hoje!")

      }, 1000);

      setTimeout(() => {
        setMessageError("")

      }, 5000);

    })
  }

  return (
    <div className={classes.headerAgenda}>
      {message ?
        <div className="Modal-agendamento-alert">
          <Alert variant="filled" severity={typeAlert}>
            {message}
          </Alert>
        </div>
        : ''}
      
      <div className="inputs-headers">
        <div>
          <h1 className="header-name">{doctorName !== 'null' ? doctorName : ''}</h1>
            <FormControl className={clsx(classes.form)}>
              <InputBase
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end">{!load ? <IconButton><SearchIcon className={classes.iconSearch} onClick={(event) => busca(event)} /></IconButton> : <div className="load-detalhe-agendamento"><CircularLoad /></div>}</InputAdornment>}
                //labelWidth={0}
                disabled={load ? true : false}
                onKeyDown={busca}
                onChange={(e) => setPatientName(e.target.value)}
                placeholder="Busque por um paciente"
                className={clsx(classes.search)}
              />

            </FormControl>
        </div>

        <div className="button-group-table">
          <div className="div-group-btn-alert">
            <div className="div-group-btn">
                <div>
                  <button onClick={openModalAddAgendamento} className="buttonHeader" disabled={isNaN(selectedDoctor)} data_id={selectedUser}><ControlPointIcon /><span> Novo agendamento</span></button>
                </div>
                <div>
                  <button onClick={() => {getPdf()}} className="buttonHeader">{load === true ? <div style={{width: "100%",display: "flex",justifyContent: 'center'}}> <CircularLoad/> </div> :<><LocalPrintshopSharpIcon /><span> Imprimir</span></>}</button>
                </div>
                <div>
                  <button onClick={openModalProcedimentos} disabled={isNaN(selectedDoctor)} className="buttonHeader mr-0"><SettingsIcon /><span> Procedimentos</span></button>
                </div>
            </div>
          
            <div className="div-group-alert">
                <p>{MessageError}</p>
            </div>
          </div>
        </div>
      </div>
      <ModalAddAgendamento edit={false} open={modalAddAgendamento} onChange={alteraEstadoModal} userId={selectedUser} doctorId={selectedDoctor} refreshTable={props.refreshTable}/>

      <ModalResultadoPesquisa
        // namePac={patientName}
        resultadosPesquisasCan={resultadoPesqCan}
        resultadosPesquisasAgen={resultadoPesqAgen}
        resultadosPesquisasRea={resultadoPesqRea}
        open={modalResultadoPesquisa}
        onChange={alteraEstadoModalResultadoPesquisa}
        />

      <ModalProcedimentos open={modalProcedimentos} onChange={alteraEstadoModalProcedimentos} />
    </div>
  );

}