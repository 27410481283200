import React from "react"
import './style.css'
import Logo from '../../../../Assets/Images/logoBranco.svg';
import { Link, useHistory } from 'react-router-dom';



export default function TopoRelatorios() {

  return(

    <div className='Topo-Relatorios'>
      <div  className='Topo-Logo-position'> 
        <Link to={localStorage.getItem('/painel') === 'true'?"/home":"agenda"}>
          <img src={Logo} alt="logo"  />
        </Link>
      </div>
    </div>

  )
  
}