import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputTextoLongo({ title, id, required, edit }) {
  const [textoLongo, setTextoLongo] = useState();
  //const [IDcomp, setIDcomp] = useState(id);
  const { Answer, setAnswer, respEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, setpos, respView, validaProntuario, setvalidaProntuario, validation, setValidation,attValidation, setAttValidation, attEncerraError } = StateGlobal();
  const [req, setReq] = useState(required);
  const [error, setError] = useState(false)

  const handleChangeInput = event => {
    const { value } = event.target;
    Answer.map((item, index) => {
      if (item.question_id === id) {

        setTextoLongo(value)
        Answer[index].answer = value

        if(req){
          if( !Answer[index].answer ){
            setError(true)
          }
          else{
            setError(false)
          }
        }

      }

    })
    const array = MontagemProntuario

    array[PosProntuario].montagem.map((item, index) => {
      if (item.tempId === id) {
        item.answer = value
      }

    })

    setMontagemProntuario(array)

  };

  useEffect(() => {
    if(validation) {
      
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
         
          if(comp.id == id){
   
            setError(validation[index].components[i].error)
              
          }
        })
      })
    }
  
  }, [attEncerraError])

  useEffect(()=>{
    if(validation) {
      
      validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
           
            if(comp.id == id){
     
              validation[index].components[i].error = error 
              
                
            }
          })
      })
      setAttValidation(!attValidation)
    }

  }, [error])

  
  useEffect(() => {
    setTextoLongo(edit)
  }, [id])


  return (



    <div>
      <p className="titulo-form">{title}</p>
      {/* {id}{edit} */}
      <TextField
        className="mb-4"
        value={textoLongo}
        onChange={handleChangeInput}
        onBlur={handleChangeInput}
        type="text"
        error={error}
        required={req}
        variant="outlined"
        disabled={respView}
        // error={validaProntuario[id].error}
        multiline
        rows={5}
        inputProps={{ maxLength: 800 }}
        //disabled = {respEdit}
      />
      <div style={{ color: 'red', width: '100%', marginTop: '-15px', fontSize: '15px' }}>
        { error === true ? '* Preencha o campo' : ''}  
      </div>
    </div>




  );
}
