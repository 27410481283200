import React, {useEffect, useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import conexao from '../../Services/api'
import { data } from '../../Pages/Agenda/Modais/Validate';

const useStyles = makeStyles((theme) => ({
  btn:{
    backgroundColor:' #f50057 !important',
    color:'#fff',
    "&:hover": {
      backgroundColor: '#c51162 !important;'
    }

  }
}));

const theme = createTheme({
  palette: {
    secondaryAlert: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#E7366A',
      contrastText: '#E7366A',
    }
  },
}, ptBR);

export default function AlertDashboard() {
  const classes = useStyles();
  const history = useHistory()

  const [dataAlert, setDataAlert] = useState('')
  const [message, setMessage] = useState('')
  const [plan, setPlan] = useState('')

  useEffect(() => {
    const expired = localStorage.getItem('expired');

    // if (expired) {

    // }

    // let dataExpired = new Date((dataAlert.expired_at).replace(/\s/, 'T')+'Z')
    // console.log('dataExpired', dataExpired)
    // let dataAtual = new Date()

    // let expired = dataExpired - dataAtual
    // console.log('expired1', expired)

    // expired = expired / 1000 / 60 / 60 /24
    // console.log('expired2', expired)

    // expired = expired.toFixed(0)
    // console.log('expired:', expired)
    // if(expired <= 0){
    //   setMessage(`Bem vindo ao Clinic Plus! Seu período de avaliação expirou`)
    // }else{
    //   setMessage(`Bem vindo ao Clinic Plus! Seu período de avaliação termina em ${expired} dias`)
    // }

  },[])

  // useEffect(()=>{
   
  //   if(dataAlert){
  //     let dataExpired = new Date((dataAlert.expired_at).replace(/\s/, 'T')+'Z')
  //     console.log('dataExpired', dataExpired)
  //     let dataAtual = new Date()

  //     let expired = dataExpired - dataAtual
  //     console.log('expired1', expired)

  //     expired = expired / 1000 / 60 / 60 /24
  //     console.log('expired2', expired)

  //     expired = expired.toFixed(0)
  //     console.log('expired:', expired)
  //     if(expired <= 0){
  //       setMessage(`Bem vindo ao Clinic Plus! Seu período de avaliação expirou`)
  //     }else{
  //       setMessage(`Bem vindo ao Clinic Plus! Seu período de avaliação termina em ${expired} dias`)
  //     }
  //   }
  // },[dataAlert])

  async function call(){
    // try{
    //   var url = '';
    //   if (localStorage.getItem('clinicId') != null) {
    //     url = `/clinic/plan/get/${localStorage.getItem('clinicId')}`
    //   }
    //   else {
    //     url = `/doctor/plan/get/${localStorage.getItem('selectedDoctor')}`
    //   }

    //   const response = await conexao.get(url).then((res) => {
    //     console.log('PLAN')
    //     console.log(response)
    
    //     setDataAlert(response.data.data[0])
    //     setPlan(response.data.data[0].plan.name)
    //   }).catch((error) => {
  
    //   })
    // }catch(error){
    //   console.log(error)
    // }
  }

  return (
    <div>
        {plan === 'Free' ? 
          <Alert className="Dashboard-Graficos-alert" variant="filled" color="info">

              {message}

            <Button variant="contained" className={classes.btn} color="secondaryAlert" 
            onClick={()=>{ history.push('/planos') }}>
              ASSINE AGORA
            </Button>
          </Alert>
          : ''
        }
    </div>
  );
}