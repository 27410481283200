import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LateralStepCadastro from './lateralStepCadastro';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import CircularLoad from '../../../Components/Load'
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { StateGlobal } from '../../../ContextAPI/context';
import { validaCpfCnpj } from '../../Agenda/Modais/Validate';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import conexao from '../../../Services/api';
import '../css/style.css';
import { campovazio} from '../../../Components/ReduxTeste/ValidateForm'
import Load from '../../../Components/Load'

import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem"

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  btnEditLogo:{
    maxWidth: '2rem',
    height: '2rem',
    minWidth: '2rem',
    borderRadius: '50%',
    position: 'relative',
    marginLeft: '-1.1rem',
    marginTop: '1.3rem'
  },
  iconEditLogo:{
    width: '.8em',
    height: '.8em',
  },
  avatarLogo:{
    width: '3rem',
    height: '3rem'
  },
  envolveLogo:{
    display: 'flex'
  },
  avatar:{
    width: '100%',
    height: '100%',
    backgroundColor: '#ccc',
    padding: '5px',
    borderRadius: '50%'
  },
  

}));

const theme = createTheme({
    palette: {
        secondary: {
          light: '#E7366A',
          main: '#E7366A',
          dark: '#D93364',
          contrastText: '#fff',
        },
        primary: {
          light: '#27b8d2',
          main: '#27b8d2',
          dark: '#229fb5',
          contrastText: '#fff',
        }
      },
}, ptBR);






function FormataCEP(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='#####-###'
      />
    );
  }
  FormataCEP.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  function FormataCPF(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='###.###.###-##'
      />
    );
  }
  FormataCPF.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  FormataCEP.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  function FormataRG(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='##.###.###-##'
      />
    );
  }
  FormataRG.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }


  function FormataCelular(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format='(##) # ####-####'
      />
    );
  }
  
  FormataCelular.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }
  
  function FormataTelefone(props) {
    const { inputRef, onChange, ...other } = props;

  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format={props.value.length > 10 ? '(##) # ####-####' : '(##) ####-#####'}
      />
    );
  }
  FormataTelefone.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

export default function NovoModelo() {
  const classes = useStyles();
  const history = useHistory();
  const { secaoFormulario, setSecaoFormulario } = StateGlobal();
  const [ validaCPF, setValidaCPF ] = useState();
  const [load, setLoad] = useState(false);
  const [loadFoto, setLoadFoto] = useState(false);
  const [profissao, setProfissao] = useState([]);
  const [especialidade, setEspecialidade] = useState([]);
  const [idProfissao, setIdProfissao] = useState('');
  const [idEspecialidade, setIdEspecialidade] = useState();
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState();
  const [profissaoSelecionada, setProfissaoSelecionada] = useState();
  const [especialidadeSelecionadaIndex, setEspecialidadeSelecionadaIndex] = useState();
  const [profissaoSelecionadaIndex, setProfissaoSelecionadaIndex] = useState();
  const [name, setName] = useState('');
  const [validaSenha, setValidaSenha] = useState('');
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const [loadingImg, setLoadingImg] = useState(true)

  const state = {
    name: { name: 'name', funcao: campovazio, value: '', error: false },
    description: { name: 'description', funcao: campovazio, value: '', error: false },
    price: { name: 'price', funcao: campovazio, value: '', error: false },
    promotional_price: { name: 'promotional_price', funcao: campovazio, value: '', error: false },
    quantity: { name: 'quantity', funcao: campovazio, value: '', error: false },
    highlight: { name: 'highlight', funcao: campovazio, value: '', error: false },
    price: { name: 'price', funcao: campovazio, value: '', error: false },

}

  const [form ,setForm] = useState(state);

  const [dados, setDados] = useState({
    "name": "",
    "email": "",
    "cpf": "",
    "rg": "",
    "phone": "",
    "cellphone":"",
    "is_whatsapp": true,
    "birthday": "",
    "street": "",
    "neighborhood": "",
    "number": '',
    "city": "",
    "state": "",
    "complement": "",
    "zip_code": "",
    "avatar": "",
    "speciality_id": 1,
    "nomeclatura": ""
})

const [validaDados, setValidaDados] = useState({
  "name": false,
  "email": false,
  "cpf": false,
  "rg": false,
  "phone": false,
  "cellphone":false,
  "birthday": false,
  "street": false,
  "neighborhood": false,
  "number": '',
  "city": false,
  "state": false,
  "complement": false,
  "zip_code": false,
  "avatar": false,
  "speciality": false,
  "profissao": false,
})

function pegadados(e) {
  const { name, value } = e
  const obj = form[name]
  const er = form[name].funcao(value)
  let att = { ...obj, error: er, value: value }
  setForm({ ...form, [name]: att })

}

useEffect(() => {
  setSecaoFormulario(1)
  getDadosCadastro();
}, [])

async function envia(e){
    e.preventDefault();
    setLoad(true)
    var userId = '';
    if(localStorage.getItem('selectedUser')){
      userId = localStorage.getItem('selectedUser');
      if(!dados.name || !dados.email || !dados.cpf || !dados.rg || !dados.phone || !dados.cellphone || !dados.birthday){
        setValidaDados({...validaDados, 
          'rg': !dados.rg ? true : false,
          'cpf': !dados.cpf ? true : false,
          'name': !dados.name ? true : false,
          'email': !dados.email ? true : false,
          'phone': !dados.phone ? true : false,
          'cellphone': !dados.cellphone ? true : false,
          'birthday': !dados.birthday || dados.birthday =='' ? true : false,
        })
        setLoad(false)
        setMessage('Preencha todos os campos para prosseguir!')
        setTypeAlert('error')
        setSecaoFormulario(1)
        setTimeout(() => {
          setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        }, 1500);
      } else if(!idEspecialidade || !idProfissao){
        setValidaDados({...validaDados, 
          'speciality': !idEspecialidade ? true : false,
          'profissao': !idProfissao ? true : false,
        })
        setLoad(false)
        setMessage('Preencha todos os campos para prosseguir!')
        setTypeAlert('error')
        setSecaoFormulario(2)
        setTimeout(() => {
          setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        }, 1500);
      }else if(!dados.zip_code || !dados.state || !dados.city || !dados.neighborhood || !dados.number || !dados.street){
        setLoad(false)
        setValidaDados({...validaDados, 
          'zip_code': !dados.zip_code ? true : false,
          'state': !dados.state ? true : false,
          'city': !dados.city ? true : false,
          'neighborhood': !dados.neighborhood ? true : false,
          'number': !dados.number ? true : false,
          'street': !dados.street ? true : false,
        })
        setMessage('Preencha todos os campos para prosseguir!')
        setTypeAlert('error')
        setSecaoFormulario(3)
        setTimeout(() => {
          setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        }, 1500);
      }
      else {
        
        setValidaDados({...validaDados, 
          'cpf': false 
        })
        await conexao.put(`/doctor/update/${localStorage.getItem('selectedDoctor')}`, dados)
        .then((response) => {
            console.log('SALVO!')
            console.log(dados)
            console.log(response)
            localStorage.setItem('avatar', dados.avatar)  
            localStorage.setItem('doctorName', dados.name)
            setLoad(false)
            setValidaDados({...validaDados, 
              'zip_code':  false,
              'state':  false,
              'city':  false,
              'neighborhood':  false,
              'number': false,
              'street': false,
              'complement': false,
              'rg': false,
              'cpf': false,
              'name': false,
              'email': false,
              'phone': false,
              'cellphone': false,
              'birthday': false,
              'speciality': false,
              'profissao': false,
            })
            setMessage('Dados atualizados com sucesso!')
            setTypeAlert('success')
            setTimeout(() => {
              setMessage('')
              //setTypeAlert('') => Alterado por Elton. Propriedade inválida
              window.location.reload();
            }, 1500);
        }).catch((error)=>{
          setValidaDados({...validaDados, 
            'cpf': true 
          })
          setLoad(false)
          setMessage('Ocorreu um erro ao atualizar os dados. Por favor verifique os dados preenchidos!')
          setTypeAlert('error')
          setTimeout(() => {
            setMessage('')
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          }, 1500);
        })
      }
    }
}


async function getCep(cep){
    await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
    .then((resp) => {
      setDados({...dados, 
                'street': resp.data.logradouro,
                'state': resp.data.uf,
                'city': resp.data.localidade,
                'neighborhood': resp.data.bairro,
                'complement': resp.data.complemento
              })
              setValidaDados({...validaDados, 
                'zip_code': !dados.zip_code ? true : false,
                'state': !resp.data.uf ? true : false,
                'city': !resp.data.localidade ? true : false,
              })

    }).catch((error)=>{

    })

  }


  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function getDadosCadastro(){
    const doctorId = localStorage.getItem('selectedDoctor');
    await conexao.get('/doctor/byid/' + doctorId)
    .then((resp) => {
      var userId = '';
      
        
      var cadastro = resp.data.data;
      var data = '2000-01-01';
      if(cadastro.birthday){
        data = cadastro.birthday.split(' ')[0];
      }

      console.log('DADOS DO MÉDICO')
      console.log(cadastro)
      setDados({...dados, 
        'street': cadastro.street,
        'state': cadastro.state,
        'city': cadastro.city,
        'neighborhood': cadastro.neighborhood,
        'complement': cadastro.complement,
        'name': cadastro.user.name,
        'email': cadastro.user.email,
        'cpf': cadastro.cpf,
        'rg': cadastro.rg,
        'phone': cadastro.phone,
        'cellphone': cadastro.cellphone,
        'birthday': data,
        'zip_code': cadastro.zip_code,
        'number': cadastro.number,
        'avatar': cadastro.avatar,
        'nomeclatura': cadastro.nomeclatura
      });
      console.log('setIdEspecialidade')
      setIdEspecialidade(cadastro.speciality.id);
      setName(cadastro.user.name);
      setIdProfissao(cadastro.speciality.speciality_category.id);
      setEspecialidadeSelecionada(cadastro.speciality.name);
      setProfissaoSelecionada(cadastro.speciality.speciality_category.name);
      setLoadingImg(false)
      if(cadastro.cpf != null){
        setValidaCPF(validaCpfCnpj(cadastro.cpf))
      }

    }).catch((error)=>{

    })
  }
  // async function getDadosCadastro(){
  //   await conexao.get(`/doctor`)
  //   .then((resp) => {
  //     var userId = '';
  //     if(localStorage.getItem('selectedUser')){
  //       userId = localStorage.getItem('selectedUser');
  //       var cadastro = resp.data.data;
  //       for(let i = 0; i < cadastro.length; i++){
  //         if(userId == cadastro[i].user_id){
  //             var data = '2000-01-01';
  //             if(cadastro[i].birthday){
  //               data = cadastro[i].birthday.split(' ')[0];
  //             }

  //           console.log('DADOS DO MÉDICO')
  //           console.log(cadastro[i])
  //           setDados({...dados, 
  //             'street': cadastro[i].street,
  //             'state': cadastro[i].state,
  //             'city': cadastro[i].city,
  //             'neighborhood': cadastro[i].neighborhood,
  //             'complement': cadastro[i].complement,
  //             'name': cadastro[i].user.name,
  //             'email': cadastro[i].user.email,
  //             'cpf': cadastro[i].cpf,
  //             'rg': cadastro[i].rg,
  //             'phone': cadastro[i].phone,
  //             'cellphone': cadastro[i].cellphone,
  //             'birthday': data,
  //             'zip_code': cadastro[i].zip_code,
  //             'number': cadastro[i].number,
  //             'avatar': cadastro[i].avatar
  //           });

  //           console.log('setIdEspecialidade')
  //           setIdEspecialidade(cadastro[i].speciality.id);
  //           setName(cadastro[i].user.name);
  //           setIdProfissao(cadastro[i].speciality.speciality_category.id);
  //           setEspecialidadeSelecionada(cadastro[i].speciality.name);
  //           setProfissaoSelecionada(cadastro[i].speciality.speciality_category.name);
  //           setLoadingImg(false)
  //           if(cadastro[i].cpf != null){
  //             setValidaCPF(validaCpfCnpj(cadastro[i].cpf))
  //           }

  //         }
  //       }
  //     }

  //   }).catch((error)=>{

  //   })
  // }

  useEffect(() => {
    getProfissaoCadastro();
  }, [profissaoSelecionada,especialidadeSelecionada])

  async function getProfissaoCadastro(){
    await conexao.get(`/speciality/category`)
    .then((resp) => {
      setProfissao(resp.data.data)
      resp.data.data.map((i, index)=>{
        if(i.name===profissaoSelecionada){
          setProfissaoSelecionadaIndex(index);
        }
      })
      })
if(idProfissao){
    conexao.get(`/speciality/${idProfissao}`)
    .then((resp) => {
      setEspecialidade(resp.data.data)
      resp.data.data.map((i, index)=>{
        if(i.name==especialidadeSelecionada){
          setEspecialidadeSelecionadaIndex(index);
        }
      })
    }).catch((error)=>{
    })
  }
  }

  function getBase64(file) {

    if(file.size > 10000000){
      alert("Documento maior que 10MB, por favor verifique o tamanho do arquivo.");
      return;
    };

    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      putFotoPerfil(reader.result)
    };
  }
 
 async function putFotoPerfil(image){
   setLoadingImg(true)
   setLoadFoto(true)
  await conexao.post('image/upload',
    {
      "path": 'doctor',
      "photo": image,
    })
  .then((resp) => {
    if(!resp.data.error){
      setDados({...dados, 
        'avatar': resp.data.data
      });
      setLoadFoto(false)
      setLoadingImg(false)
    }
  }).catch((error)=>{
      setLoadingImg(false)
  })

}

  async function getEspecializacaoCadastro(idP){
    console.log('getEspecializacaoCadastro')
    await conexao.get(`/speciality/${idP}`)
    .then((resp) => {
      setEspecialidade(resp.data.data)
    }).catch((error)=>{

    })

  }

  function handleInputChange(event,value){
    console.log('handleInputChange')
    setIdProfissao('');
    for(let i = 0; i < profissao.length; i++){
      if(profissao[i].name==value){
        setIdProfissao(profissao[i].id);
        getEspecializacaoCadastro(profissao[i].id);
      }
    }
  }

  
  function handleInputChangeEspecialidade(event,value){
    console.log('handleInputChangeEspecialidade')
    setIdEspecialidade('');
    setDados({...dados, 
      'speciality_id': ''
    })
    for(let i = 0; i < especialidade.length; i++){
      if(especialidade[i].name==value){
        setIdEspecialidade(especialidade[i].id);
        setDados({...dados, 
          'speciality_id': especialidade[i].id
        })
      }
    }
  }

  async function resetSenha(){
    var userId = '';
    if(localStorage.getItem('selectedUser')){
      userId = localStorage.getItem('selectedUser');
    setLoad(true)
    if(!values.password || values.password.length<6){
      setValidaSenha(true)
      setLoad(false)
      setMessage('Ocorreu um erro ao editar a senha. Por favor verifique os dados preenchidos e tente novamente!')
      setTypeAlert('error')
      setTimeout(() => {
        setMessage('')
        //setTypeAlert('') => Alterado por Elton. Propriedade inválida
      }, 3500);
    }else{
      setValidaSenha(false)
      await conexao.put(`/doctor/update/password/${localStorage.getItem('selectedDoctor')}`, {"password":values.password})
      .then((response) => {
          setLoad(false)
          setMessage('Senha atualizada com sucesso!')
          setTypeAlert('success')
          setTimeout(() => {
            setMessage('')
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          }, 3500);
      }).catch((error)=>{
        setLoad(false)
        setMessage('Ocorreu um erro ao editar a senha. Por favor verifique os dados preenchidos e tente novamente!')
        setTypeAlert('error')
        setTimeout(() => {
          setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        }, 3500);
      })
    }
  }
  }

  return (
    <div className={localStorage.getItem('containerClass')}>
    <ThemeProvider theme={theme}>
        <Grid container>
        <Grid item xs={3}>
            <Paper className={clsx(classes.paper, classes.colorPaper)}>
                <div className="lateralStepCadastro">
                    <LateralStepCadastro/>
                </div>
            </Paper>
        </Grid>
        <Grid item xs={9}>
            <Paper className={clsx(classes.paper, classes.colorPaper)}>
                <div className="form-cadastro-editar">
                    <p>{ secaoFormulario === 1 ? 'Dados Pessoais' : secaoFormulario === 2 ? 'Dados Profissionais' : secaoFormulario === 3 ? 'Dados da Empresa' : ''}</p>
                    <form onSubmit={(e)=>envia(e)} noValidate className={classes.root} autoComplete="off">
                    {message ?
                      <div className="Modal-agendamento-alert">
                        <Alert variant="filled" severity={typeAlert}>
                          {message}
                        </Alert>
                      </div>
                      : ''}
                        {secaoFormulario === 1 ?
                            <div className="divCadastrarEditar">
                             
                                <TextField
                                    id="date"
                                    label="Data de nascimento"
                                    type="date"
                                    variant="outlined"
                                    color="primary"
                                    value={dados.birthday}
                                    defaultValue={dados.birthday}
                                    error={validaDados.birthday ? true:false}
                                    helperText={validaDados.birthday ? 'Campo obrigatório' : ''}
                                    onChange={(event)=>setDados({...dados, 'birthday': event.target.value})}
                                    InputLabelProps={{
                                    shrink: "true",
                                    }}
                                />
                             
                                <TextField 
                                name='name'
                                value={dados.name} 
                                error={validaDados.name ? true:false}
                                helperText={validaDados.name ? 'Campo obrigatório' : ''}
                                onChange={(event)=>{
                                  setDados({...dados, 'name': event.target.value.replace(/[0-9]/g, "")}) ;
                                  pegadados(event.target)

                                }} 
                                type="text" id="nome" label="Nome completo" variant="outlined" color="primary" required
                                />

                                <TextField 
                                    type="text" 
                                    id="rg" 
                                    name="rg" 
                                    label="RG" 
                                    value={dados.rg}
                                    onChange={(event)=>setDados({...dados, 'rg': event.target.value})}
                                    variant="outlined" 
                                    color="primary" 
                                    error={validaDados.rg ? true:false}
                                    helperText={validaDados.rg ? 'Campo obrigatório' : ''}
                                    required
                                    InputProps={{
                                        inputComponent: FormataRG,
                                    }}
                                />
                                <TextField 
                                    type="text" 
                                    id="cpf" 
                                    name="cpf" 
                                    label="CPF" 
                                    variant="outlined" 
                                    value={dados.cpf}
                                    onChange={(event)=>setDados({...dados, 'cpf': event.target.value})}
                                    onKeyUp={(event) => { setValidaCPF(validaCpfCnpj(event.target.value.replace(/[^\d]+/g, ''))) }}
                                    color="primary" 
                                    required
                                    error={validaDados.cpf ? true:false}
                                    helperText={validaDados.cpf ? 'Documento inválido' : ''}
                                    InputProps={{
                                        shrink: "true",
                                        inputComponent: FormataCPF,
                                    }}
                                />
                                <div className="div-envolve-telefones">
                                    <TextField 
                                        className="div-envolve-telefones-phone"
                                        type="tel" 
                                        id="phone" 
                                        name="phone" 
                                        onChange={(event)=>setDados({...dados, 'phone': event.target.value})} 
                                        value={dados.phone} 
                                        label="Telefone" 
                                        variant="outlined" 
                                        error={validaDados.phone ? true:false}
                                        helperText={validaDados.phone ? 'Campo obrigatório' : ''}
                                        color="primary" 
                                        InputProps={{
                                            shrink: "true",
                                            inputComponent: FormataTelefone,
                                        }}
                                        required
                                    />
                                    <TextField 
                                        className="div-envolve-telefones-cellphone"
                                        type="tel" 
                                        id="cellphone" 
                                        name="cellphone" 
                                        onChange={(event)=>setDados({...dados, 'cellphone': event.target.value})} 
                                        value={dados.cellphone} 
                                        label="Celular" 
                                        error={validaDados.cellphone ? true:false}
                                        helperText={validaDados.cellphone ? 'Campo obrigatório' : ''}
                                        variant="outlined" 
                                        color="primary" 
                                        InputProps={{
                                            shrink: "true",
                                            inputComponent: FormataCelular,
                                        }}
                                        required
                                    />
                                </div>
                                <TextField 
                                    type="email" 
                                    id="email" 
                                    onChange={(event)=>setDados({...dados, 'email': event.target.value})} 
                                    value={dados.email} 
                                    error={validaDados.email ? true:false}
                                    helperText={validaDados.email ? 'Campo obrigatório' : ''}
                                    label="E-mail" 
                                    variant="outlined" 
                                    color="primary" 
                                    InputProps={{
                                        shrink: "true",
                                    }}
                                    required
                                />
                                <FormControl variant="outlined" className={classes.formControl} style={{ width: "100%" }}>
                                  <InputLabel style={{ width: 'auto', backgroundColor: '#fafafa' }}>
                                    Como deseja ser chamado? 
                                  </InputLabel>
                                  <Select
                                    name='nomeclatura'
                                    placeholder="Como deseja ser chamado? "
                                    id="nomeclatura-outlined"
                                    value={dados.nomeclatura}
                                    label="Especialidade"
                                    onChange={(event) => setDados({...dados, 'nomeclatura': event.target.value})}
                                    className={classes.input}
                                    variant="outlined"
                                  >
                                    <MenuItem value="-">-</MenuItem>
                                    <MenuItem value="Dra">Dra</MenuItem>
                                    <MenuItem value="Dr">Dr</MenuItem>
                                  </Select>
                                </FormControl>
                            </div>
                        :
                        secaoFormulario === 2 ?
                            <div className="divCadastrarEditar">
                              {profissaoSelecionadaIndex !=='' ?
                                <Autocomplete
                                    id="profissao"
                                    options={profissao}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={profissao[profissaoSelecionadaIndex]}
                                    onInputChange={ handleInputChange }
                                    renderInput={(params) => <TextField {...params} error={validaDados.profissao ? true:false}
                                    helperText={validaDados.profissao ? 'Campo obrigatório' : ''} label="Profissão" variant="outlined" />}
                                />
                                :''}
                                {especialidadeSelecionadaIndex !=='' ?
                                <Autocomplete
                                    id="especialidade"
                                    options={especialidade}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={especialidade[especialidadeSelecionadaIndex]}
                                    onInputChange={ handleInputChangeEspecialidade }
                                    renderInput={(params) => <TextField {...params} error={validaDados.speciality ? true:false}
                                    helperText={validaDados.speciality ? 'Campo obrigatório' : ''} label="Especialidade" variant="outlined" />}
                                />
                                :''}
                            </div>
                        :
                        secaoFormulario === 3 ?
                            <div className="divCadastrarEditar">
                                <div className="div-logo-empresa">
                                    <p className="p-logo-empresa">Logo da empresa:</p>
                                    <div className={classes.envolveLogo}>
                                      {loadingImg ? 
                                      <div className={classes.avatar}>
                                        <Load/>
                                      </div>
                                      :
                                      <Avatar id="avatar" className={classes.avatarLogo} alt={name} src={dados.avatar} />  
                                      }
                                      
                                      <Button
                                          variant="contained"
                                          component="label"
                                          color="primary"
                                          className={classes.btnEditLogo}
                                          >
                                          {loadFoto?<CircularLoad/>:<EditIcon className={classes.iconEditLogo}/>}
                                          <input
                                              id="inputFileToLoad"
                                              type="file"
                                              hidden
                                              onChange={(event)=>getBase64(event.target.files[0])}
                                          />
                                      </Button>
                                    </div>
                                </div>
                                <div className="div-envolve-input-flex">
                                    <TextField 
                                        className="input-br" 
                                        type="tel" 
                                        id="cep" 
                                        onChange={(event)=>setDados({...dados, 'zip_code': event.target.value})}
                                        label="CEP" 
                                        onKeyUp={(event)=>getCep(event.target.value)}
                                        variant="outlined" 
                                        color="primary" 
                                        required
                                        value={dados.zip_code}
                                        error={validaDados.zip_code ? true:false}
                                        helperText={validaDados.zip_code ? 'Campo obrigatório' : ''}
                                        InputProps={{
                                            inputComponent: FormataCEP,
                                            shrink: "true"
                                        }}
                                    />
                                    <TextField 
                                      className="input-uf" 
                                      disabled={dados.state?true:false} 
                                      InputLabelProps={{ shrink: "true" }} 
                                      value={dados.state} 
                                      onChange={(event)=>setDados({...dados, 'state': event.target.value})}
                                      type="text" 
                                      id="uf" 
                                      label="Estado" 
                                      variant="outlined" 
                                      color="primary" 
                                      error={validaDados.state ? true:false}
                                      helperText={validaDados.state ? 'Campo obrigatório' : ''}
                                      required/>
                                </div>
                                <TextField
                                  type="text" 
                                  id="cidade" 
                                  disabled={dados.city?true:false} 
                                  InputLabelProps={{ shrink: "true" }} 
                                  onChange={(event)=>setDados({...dados, 'city': event.target.value})} 
                                  value={dados.city} 
                                  label="Cidade" 
                                  variant="outlined" 
                                  color="primary" 
                                  error={validaDados.city ? true:false}
                                  helperText={validaDados.city ? 'Campo obrigatório' : ''}
                                  required/>
                                  <TextField 
                                       
                                      InputLabelProps={{ shrink: "true" }} 
                                      value={dados.neighborhood}  
                                      onChange={(event)=>setDados({...dados, 'neighborhood': event.target.value.replace(/[0-9]/g, "")})} 
                                      type="text" 
                                      id="bairro" 
                                      label="Bairro"
                                      variant="outlined" 
                                      color="primary" 
                                      error={validaDados.neighborhood ? true:false}
                                      helperText={validaDados.neighborhood ? 'Campo obrigatório' : ''}
                                      required/>
                                
                                <div className="div-envolve-input-flex">
                                <TextField 
                                  className="input-br"
                                  type="text" 
                                  id="Rua" 
                                  InputLabelProps={{ shrink: "true" }}  
                                  value={dados.street} 
                                  onChange={(event)=>setDados({...dados, 'street': event.target.value.replace(/[0-9]/g, "")})} 
                                  label="Rua" 
                                  variant="outlined" 
                                  color="primary" 
                                  error={validaDados.street ? true:false}
                                  helperText={validaDados.street ? 'Campo obrigatório' : ''}
                                  required/>
                                    <TextField 
                                        className="input-uf" 
                                        type="number" 
                                        id="numero" 
                                        label="Número" 
                                        onChange={(event)=>setDados({...dados, 'number': event.target.value})}
                                        InputLabelProps={{ shrink: "true" }}
                                        variant="outlined" 
                                        error={validaDados.number ? true:false}
                                        helperText={validaDados.number ? 'Campo obrigatório' : ''}
                                        color="primary" 
                                        value={dados.number}
                                        required
                                    />
                                </div>
                                <TextField 
                                  type="text" 
                                  id="complemento" 
                                  value={dados.complement}  
                                  onChange={(event)=>setDados({...dados, 'complement': event.target.value})} 
                                  InputLabelProps={{ shrink: "true" }} 
                                  label="Complemento" 
                                  variant="outlined" 
                                  color="primary"/>
                            </div>
                            :
                             secaoFormulario === 4 ?
                              <div className="divCadastrarEditar">
                                <FormControl
                                  error={!validaSenha?false:true}
                                  className="Login-senha-input" variant="outlined">
                                  <InputLabel htmlFor="senha">Nova senha</InputLabel>
                                  <OutlinedInput
                                    className="Login-input-color"
                                    id="senha"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    aria-describedby="component-error-text"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    labelWidth={85}
                                  />
                                  <FormHelperText id="component-error-text">{validaSenha ? 'Senha inválida' : ''}</FormHelperText>
                                </FormControl>
                             </div>
                        :''}
                        {secaoFormulario !== 4 ?
                        <div className="div-btn-cadastrar">
                            <Button onClick={()=>history.push('/home')}>
                                Cancelar
                            </Button>
                            <Button variant="contained" type="submit" className="btn-salvar-editar-cadastro color-primary" color="primary">
                                {load?<CircularLoad/>:'Salvar'}
                            </Button>
                        </div>
                      :
                      <div className="div-btn-cadastrar">
                        <Button onClick={()=>history.push('/home')}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={resetSenha} type="button" className="btn-salvar-editar-cadastro color-primary" color="primary">
                            {load?<CircularLoad/>:'Salvar'}
                        </Button>
                      </div>
                      }
                    </form>
                </div>
            </Paper>
        </Grid>
        </Grid>
    </ThemeProvider>
  </div>
  );
}
