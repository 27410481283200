import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  chart: {
    backgroundColor: '#f9f9f9',
    padding: '1rem',
    // marginTop: '4rem',
    borderRadius: '6px',
  },

  chart__header: {
    '& h1': {
      color: '#777777',
      fontSize: '1.5em'
    },

    '& button': {
      width: '100%',
      marginBottom: '.6rem'
    }
  },

  chart__durationTimer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 0px 5px 0px'
  },
  divSecao: {
    backgroundColor: '#ffffff',
    margin: 0,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  titleSecao: {
    color: '#27b8d2',
    fontSize: '1.1em',
    padding: '.7rem'
  },
}));