import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontWeight: '500'
  },

  score: {
    marginTop: '5px',
    fontWeight: '500'
  },

}));