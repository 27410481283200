import React, { useState, useEffect} from "react"
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { StateGlobal } from '../../../../ContextAPI/context';
import { useHistory } from 'react-router-dom';
import conexao from '../../../../Services/api';
import CircularLoad from '../../../../Components/Load';
import {teste, teste2} from '../../../../Components/ReduxTeste/ValidateForm'
import ModalPaciente from '../../../Pacientes/Modal/ModalPaciente';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    paper: {
      boxShadow: "0px 0px 0px 0px gray",
      borderRadius: "0px",
      height: "100vh !important",
      overflow: "auto"
    },
    colorPaper: {
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 0px 0px 0px gray",
    },
    colorPaperWhite:{
      backgroundColor: "#fff",
      boxShadow: "0px 0px 0px 0px gray",
      padding: '2rem'
    },
    envolveResumo:{
      height: '87vh',
      padding: '2rem'
    },
    envolveDadosPaciente:{
      backgroundColor: 'white',
      padding: '1rem 2rem',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: 'rgb(100 100 111 / 20%) 0px 6px 10px 0px',
      width: '100%'
    },
    divEnvolveFotoDados:{
        display: 'flex'
    },
    divEnvolveTextos:{
      '& p': {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: '1rem',
        color: '#8a8a8a'
      }
    },
    avatar:{
        width: '60px',
        height: '60px',
        fontSize: '2rem'
    },
    tituloName:{
        fontSize: '1.3em',
        color: '#27b8d2 !important',
        marginBottom: '.3rem !important',
        fontWeight: '600'
    },
    tituloEtapa:{
          fontSize: '1.3em',
          color: '#757575 !important',
          marginBottom: '1rem !important',
          fontWeight: '600'
    },
    filtroData:{
        display: 'flex',
    },
    filtroResulo:{
      backgroundColor: 'white',
      marginTop: '2rem',
      padding: '2rem',
      height: '48vh',
      overflow: 'auto'
    },
    pSelecionarData:{
      marginRight: '1rem',
      color: '#757575',
      fontWeight: '500'
    },
    textField:{
        marginLeft: '2rem',
        marginRight: '2rem'
    },
    container:{
      display: 'flex',
      alignItems: 'center'
    },
    table:{
      boxShadow: '0px 0px 0px 0px grey',
      marginTop: '2rem',
    },
    flex:{
      display: 'flex',
    },
    atendimento:{
      marginLeft: '5rem'
    }   
   
  }));

  const theme = createTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#27b8d2',
        contrastText: '#fff',
      }
    },
  }, ptBR);

export default function DadosPessoais(props){
  const history = useHistory()
  const classes = useStyles();
  
  const[patientData, setPatientData] = useState('');
  const[avatar, setAvatar] = useState('');
  const[isLoading, setIsLoading] = useState(true)

  const { agendadoData, pacienteName, Location } = StateGlobal()
  const { setIsEditPatient, setLocation } = StateGlobal()
  const [patientID, setPatientID] = useState('')
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false);
  const [scheduleID, setScheduleID] = useState('')
  

  // useEffect(() => {
  //   if(localStorage.getItem('Location') === 'pacientes'){
  //     getPacient()
  //     setPatientID(parseInt(localStorage.getItem('patientID')))
  //   }else{
  //     chamada()
  //   }
  // },[
  //   localStorage.getItem('Location'),
  //   localStorage.getItem('agendamentoId'),
  //   localStorage.getItem('patientID')
  // ])

  useEffect(() => {
    setPatientID(props.patientID)
  },[props.patientID])

  useEffect(() => {
    if (patientID) {
      getPatient()
    }
  },[patientID])
  
  async function chamada(){ 
    // const agendamentoId = localStorage.getItem('agendamentoId')
    // await conexao.get(`/form/patient/schedule/${scheduleID}`)
    // .then((resp) => {

    //   // setPatientData(resp.data.data)
    //   var item = resp.data.data;
    //   console.log(item)
    //   var idade = item.birthday ? age(item.birthday) : 'Não informada';
    //   var data = {
    //     age: idade,
    //     attendance: item.attendance,
    //     health_insurance: "-",
    //     name: item.name,
    //   }
    //   setPatientData(data)

    //   // localStorage.setItem('patientID' ,resp.data.data.patient_id)
    //   setPatientID(resp.data.data.patient_id)
    // })
  }

  function age(data) {
    var birth = new Date(data);
    var today = moment();
    var year = today.diff(birth, 'years');
    var newDate = moment(birth).add(year, 'years').toDate()
    var month = today.diff(newDate, 'months');

    if (month > 0)
      newDate = moment(newDate).add(month, 'months').toDate()

    var day = today.diff(newDate, 'days');
    
    var age = [];
    if (year > 0)
      age.push(year + " anos")

    if (month > 0)
      age.push(month + ((month > 1) ? " meses" : " mes"))

    if (day > 0)
      age.push(day + ((day > 1) ? " dias" : " dia"))

    return age.join(', ');
  }

  function refresh() {
    getPatient()
  }

  function getPatient() {
    
    conexao.get(`patient/${patientID}`)
    .then((res) => {
      setPatientData(res.data.data)
      setIsLoading(false)
    })
  }
    
  function closeEdit() {
    setPatientID('')
    setOpenCreateUpdate(false)
  }

  function vizualizar(){
    setOpenCreateUpdate(true)
  }    

  return(
    <div className={classes.envolveDadosPaciente}>
      {isLoading ? 
        <div className="div-load-lista-modelos">
          <CircularLoad />
        </div> 
        : 
      
        patientData ? 
        <div className={classes.divEnvolveFotoDados}>
          <Avatar className={classes.avatar} alt={patientData.name} src={patientData.avatar ? patientData.avatar : '/static/images/avatar/1.jpg'} />
          <div className={classes.divEnvolveTextos}>
            <div>
              <p className={classes.tituloName}>{patientData.name}</p>
              {localStorage.getItem('Location') === 'pacientes' ? (
                <div className={classes.flex}>
                  <div>
                    { patientData.age !== 0 && 
                      <p>
                        Idade: <strong>{patientData.age ? `${patientData.age}` : '-'}</strong>
                      </p>
                    }
                  </div>
                </div>
              ) : (
                <div className={classes.flex}>
                  <div>
                    { patientData.age !== 0 && 
                    <p>Idade: <strong>{patientData.age ? `${patientData.age}` : '-'}</strong></p>
                    }
                    <p>Convênio: <strong> {patientData.health_insurance} </strong></p>
                  </div>
                  <div className={classes.atendimento}>
                    <p>Atendimentos: <strong> {patientData.attendance} </strong></p>
                  </div>
                </div>
              )}
            </div>  
          </div>
        </div> 
        : 
        " " 
      }
        
      {isLoading ? '' :
        <div>
          <Button
            variant="contained"  
            className="color-primary" 
            color="primary" 
            onClick={vizualizar}
          >
            Visualizar cadastro
          </Button>
        </div>
      }
      <ModalPaciente open={openCreateUpdate} patientId={patientID} close={closeEdit} refresh={refresh}/>
    </div>
  )
}