import React, { useEffect, useCallback, useRef } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { toPng } from "html-to-image";
import { StateGlobal } from "../../../../../ContextAPI/context";
import desenvolvimentoImg from "../../../../../Assets/Images/ImagensProntuario/desenvolvimento_motor.png";

export default function Grafico({ title, value, dataInfoGrafico, dataError }) {
  const { listaImgBase64, setListaImgBase64 } = StateGlobal();
  const ref = useRef({ HTMLDivElement });

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        setListaImgBase64((oldstate) => [
          ...oldstate,
          { link: link.href, title: title, value: value },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  useEffect(() => {
    setTimeout(() => {
      onButtonClick();
    }, [1500]);
  }, []);

  return (
    <div
      className="Grade-Container-Graficos"
      ref={ref}
      style={{ paddingBottom: "50px" }}
    >
      {dataError === "" ? (
        <>
          <img
            src={desenvolvimentoImg}
            alt="desenvolvimento motor"
            style={{ width: "500px" }}
          />

          <div
            style={{
              marginLeft: "5px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <b>
                Caminhando com assistência:{""}{" "}
                {dataInfoGrafico["Caminhando com assistência"]}
                {""}
              </b>
              <b>
                Caminhando sozinho:{""} {dataInfoGrafico["Caminhando sozinho"]}
                {""}
              </b>
              <b>
                De pé com assistência:{""}{" "}
                {dataInfoGrafico["De pé com assistência"]}
                {""}
              </b>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "30px",
                marginTop: "10px",
              }}
            >
              <b>
                De pé sem assistência:{""}{" "}
                {dataInfoGrafico["De pé sem assistência"]}
                {""}
              </b>
              <b>
                Engatinhando:{""} {dataInfoGrafico["Engatinhando"]}
                {""}
              </b>
              <b>
                Sentado sem suporte:{""}{" "}
                {dataInfoGrafico["Sentado sem suporte"]}
                {""}
              </b>
            </div>
          </div>
        </>
      ) : (
        <b
          style={{
            marginLeft: "15px",
            marginTop: "15px",
          }}
        >
          Não existe resposta no dia selecionado!
        </b>
      )}
    </div>
  );
}
