import Button from '@material-ui/core/Button';
import './applePayNotification.css'

function  ApplePayNotification(){
  const handleTechnicalSupport = () => {
    let a= document.createElement('a');
    a.target= '_blank';
    a.href= 'https://api.whatsapp.com/send?phone=5515981120505&text=Estou%20utilizando%20o%20Clinicplus%20e%20preciso%20de%20suporte%20t%C3%A9cnico%20!';
    a.click();
  }

  return (
    <div className='applepay-notification'>
      <div className='applepay-notification__content'>
        <h2>
          Devido as políticas de pagamento da Apple, pedimos a gentileza de efetuar o pagamento do plano acessando o sistema através de um computador ou navegador web. Pedimos desculpas pelo transtorno.
          <br />  
          <br />  
          Em caso de dúvidas por favor entre com o nosso suporte técnico:
        </h2>

        <Button
          onClick={handleTechnicalSupport}
          variant="contained"
        >
          Suporte técnico
        </Button>
      </div>
    </div>
  )
}

export default ApplePayNotification;