import React, { PureComponent } from 'react';
import { PieChart,  Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#E7366A', '#27b8d2'];

export default class Example extends PureComponent {
   renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  

    return (
      <g>
        <text className="text-dash-graphic" x='50%' y='50%' dy={8} textAnchor="middle" fill="#8F8F8F">
          {this.state.totalPacientes }
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  constructor(props) {
    
    super(props);
    this.state = { 
      totalPacientes: this.props.dados.total - this.props.dados.blocked,
      dados:[
        { name: 'Particular', value: parseInt(this.props.dados.private) },
        { name: 'Convenio', value: parseInt(this.props.dados.plan) },
      ],
      activeIndex: 0,
    };
}

componentDidMount() {

    this.interval = setInterval(() => this.setState({ 
    totalPacientes: this.props.dados.total - this.props.dados.blocked,
    dados:[
      { name: 'Particular', value: parseInt(this.props.dados.private) },
      { name: 'Convenio', value: parseInt(this.props.dados.plan) },
    ],
  }), 1000);
}

componentWillUnmount() {
  clearInterval(this.interval);
}
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={100} height={100}>
          <Pie
              activeIndex={this.state.activeIndex}
              data={this.state.dados}
              cx='50%'
              cy='50%'
              startAngle={360}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={1}
              activeShape={this.renderActiveShape}
              dataKey="value"
            >
              {this.state.dados.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
              ))}
            </Pie>
          </PieChart>
      </ResponsiveContainer>
    );
  }
}
