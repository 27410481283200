import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  sleep: {
    '&  p': {
      marginTop: '15px'
    }
  },

  score: {
    marginTop: '5px',
    fontWeight: '500'
  }
}));