import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({

    ModalRelatorio: {
      display: 'flex',
      justifyContent: 'center',
  
      '& .MuiDialog-paperScrollPaper': {
        alignItems: 'center',
      },

      "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper": {
        minWidth: '500px',
        minHeight: '250px'
      },

      "& .MuiDialogContent-root": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },

      '& .MuiDialogActions-root': {
        width: '100%',
        display: 'flex',
        padding: '8px',
        height: '60px',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
      },
  
    },
  
    startEndDate: {
      display: 'flex',
      // marginBottom: '30px',
    },
  
    Start: {
      display: 'flex',
      justifyContent: 'center',
      width: '180px',
      margin: '10px',
    },
  
    End: {
      display: 'flex',
      justifyContent: 'center',
      width: '180px',
      margin: '10px',
    },
 
}));
  
