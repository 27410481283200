import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function TestesFuncionais2({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  const normalizedDistanceDomScore = () => {
    let functionalTests = sportsPhysiotherapyChartAnswers.funcionalTests2
    let scoreMED = 0
    let scoreLS = 0
    let scoreLI = 0
    let proposedScoreDOM = 0

    Object.values(functionalTests.medial.dom).forEach(value => {
      if (value !== '') {
        scoreMED += parseInt(value)
      }
    })

    Object.values(functionalTests.upperSide.dom).forEach(value => {
      if (value !== '') {
        scoreLS += parseInt(value)
      }
    })

    Object.values(functionalTests.lowerSide.dom).forEach(value => {
      if (value !== '') {
        scoreLI += parseInt(value)
      }
    })

    scoreMED = scoreMED / 3
    scoreLS = scoreLS / 3
    scoreLI = scoreLI / 3
    
    scoreMED = scoreMED ? ((functionalTests.mmssDom * 100) / scoreMED).toFixed(2) : '' 
    scoreLS = scoreLS ? ((functionalTests.mmssDom * 100) / scoreLS).toFixed(2) : '' 
    scoreLI = scoreLI ? ((functionalTests.mmssDom * 100) / scoreLI).toFixed(2) : ''
    
    proposedScoreDOM = scoreMED && scoreLS && scoreLI 
      ? (((functionalTests.mmssDom * 100) * 3) / (parseInt(scoreMED) + parseInt(scoreLS) + parseInt(scoreLI))).toFixed(2) 
      : 0

    return { scoreMED, scoreLS, scoreLI, proposedScoreDOM } 
  }

  const normalizedDistanceNdomScore = () => {
    let functionalTests = sportsPhysiotherapyChartAnswers.funcionalTests2
    let scoreMED = 0
    let scoreLS = 0
    let scoreLI = 0
    let proposedScoreNDOM = 0

    Object.values(functionalTests.medial.ndom).forEach(value => {
      if (value !== '') {
        scoreMED += parseInt(value)
      }
    })

    Object.values(functionalTests.upperSide.ndom).forEach(value => {
      if (value !== '') {
        scoreLS += parseInt(value)
      }
    })

    Object.values(functionalTests.lowerSide.ndom).forEach(value => {
      if (value !== '') {
        scoreLI += parseInt(value)
      }
    })

    scoreMED = scoreMED / 3
    scoreLS = scoreLS / 3
    scoreLI = scoreLI / 3
    
    scoreMED = scoreMED ? ((functionalTests.mmssNdom * 100) / scoreMED).toFixed(2) : '' 
    scoreLS = scoreLS ? ((functionalTests.mmssNdom * 100) / scoreLS).toFixed(2) : '' 
    scoreLI = scoreLI ? ((functionalTests.mmssNdom * 100) / scoreLI).toFixed(2) : ''
    
    proposedScoreNDOM = scoreMED && scoreLS && scoreLI 
      ? (((functionalTests.mmssNdom * 100) * 3) / (parseInt(scoreMED) + parseInt(scoreLS) + parseInt(scoreLI))).toFixed(2) 
      : 0

    return { scoreMED, scoreLS, scoreLI, proposedScoreNDOM } 
  }

  return (
    <div>
      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          CKC:
        </p>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='1º'
              type='number'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.ckcTouches1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    ckcTouches1: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">toques</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='2º'
              type='number'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.ckcTouches2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    ckcTouches2: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">toques</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.ckcTouches3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    ckcTouches3: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">toques</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {
          (
            parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.ckcTouches1)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.ckcTouches2)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.ckcTouches3)
          ) / 3 || 0
        }</p>
      </article>

      <article>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={4}>
            <p className="titulo-form">MMSS - DISTÂNCIA C7 - DEDO MÉDIO:</p>
          </Grid>
          <Grid item xs={4}>
            <p className="titulo-form">DOM:</p>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.mmssDom}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    mmssDom: e.target.value
                  }
                }))
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <p className="titulo-form">NDOM:</p>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.mmssNdom}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    mmssNdom: e.target.value
                  }
                }))
              }}
            />
          </Grid>
        </Grid>
      </article> 

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '5px 0 5px 0' }}
        >
          UQYBT:
        </p>

        <p className='titulo-form'>Braço DOM</p>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='MEDIAL (MED) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.medial.dom.uqybtMedialDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    medial: {
                      ...state.funcionalTests2.medial,
                      dom: {
                        ...state.funcionalTests2.medial.dom,
                        uqybtMedialDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />            
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='MEDIAL (MED) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.medial.dom.uqybtMedialDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    medial: {
                      ...state.funcionalTests2.medial,
                      dom: {
                        ...state.funcionalTests2.medial.dom,
                        uqybtMedialDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>            
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='MEDIAL (MED) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.medial.dom.uqybtMedialDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    medial: {
                      ...state.funcionalTests2.medial,
                      dom: {
                        ...state.funcionalTests2.medial.dom,
                        uqybtMedialDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROSUPERIOR (LS) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.upperSide.dom.uqybtUppersideDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    upperSide: {
                      ...state.funcionalTests2.upperSide,
                      dom: {
                        ...state.funcionalTests2.upperSide.dom,
                        uqybtUppersideDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROSUPERIOR (LS) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.upperSide.dom.uqybtUppersideDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    upperSide: {
                      ...state.funcionalTests2.upperSide,
                      dom: {
                        ...state.funcionalTests2.upperSide.dom,
                        uqybtUppersideDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROSUPERIOR (LS) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.upperSide.dom.uqybtUppersideDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    upperSide: {
                      ...state.funcionalTests2.upperSide,
                      dom: {
                        ...state.funcionalTests2.upperSide.dom,
                        uqybtUppersideDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROINFERIOR (LI) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.lowerSide.dom.uqybtLowersidelDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    lowerSide: {
                      ...state.funcionalTests2.lowerSide,
                      dom: {
                        ...state.funcionalTests2.lowerSide.dom,
                        uqybtLowersidelDom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>           
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROINFERIOR (LI) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.lowerSide.dom.uqybtLowersidelDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    lowerSide: {
                      ...state.funcionalTests2.lowerSide,
                      dom: {
                        ...state.funcionalTests2.lowerSide.dom,
                        uqybtLowersidelDom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>            
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROINFERIOR (LI) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.lowerSide.dom.uqybtLowersidelDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    lowerSide: {
                      ...state.funcionalTests2.lowerSide,
                      dom: {
                        ...state.funcionalTests2.lowerSide.dom,
                        uqybtLowersidelDom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <p className='titulo-form' style={{ marginTop: '10px' }}>Braço NDOM</p>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='MEDIAL (MED) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.medial.ndom.uqybtMedialNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    medial: {
                      ...state.funcionalTests2.medial,
                      ndom: {
                        ...state.funcionalTests2.medial.ndom,
                        uqybtMedialNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />            
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='MEDIAL (MED) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.medial.ndom.uqybtMedialNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    medial: {
                      ...state.funcionalTests2.medial,
                      ndom: {
                        ...state.funcionalTests2.medial.ndom,
                        uqybtMedialNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>            
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='MEDIAL (MED) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.medial.ndom.uqybtMedialNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    medial: {
                      ...state.funcionalTests2.medial,
                      ndom: {
                        ...state.funcionalTests2.medial.ndom,
                        uqybtMedialNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROSUPERIOR (LS) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.upperSide.ndom.uqybtUppersideNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    upperSide: {
                      ...state.funcionalTests2.upperSide,
                      ndom: {
                        ...state.funcionalTests2.upperSide.ndom,
                        uqybtUppersideNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROSUPERIOR (LS) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.upperSide.ndom.uqybtUppersideNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    upperSide: {
                      ...state.funcionalTests2.upperSide,
                      ndom: {
                        ...state.funcionalTests2.upperSide.ndom,
                        uqybtUppersideNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROSUPERIOR (LS) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.upperSide.ndom.uqybtUppersideNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    upperSide: {
                      ...state.funcionalTests2.upperSide,
                      ndom: {
                        ...state.funcionalTests2.upperSide.ndom,
                        uqybtUppersideNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROINFERIOR (LI) 1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.lowerSide.ndom.uqybtLowersidelNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    lowerSide: {
                      ...state.funcionalTests2.lowerSide,
                      ndom: {
                        ...state.funcionalTests2.lowerSide.ndom,
                        uqybtLowersidelNdom1: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>           
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROINFERIOR (LI) 2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.lowerSide.ndom.uqybtLowersidelNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    lowerSide: {
                      ...state.funcionalTests2.lowerSide,
                      ndom: {
                        ...state.funcionalTests2.lowerSide.ndom,
                        uqybtLowersidelNdom2: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>

          <Grid item xs={4}>            
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type="number"
              placeholder='LÁTEROINFERIOR (LI) 3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.lowerSide.ndom.uqybtLowersidelNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    lowerSide: {
                      ...state.funcionalTests2.lowerSide,
                      ndom: {
                        ...state.funcionalTests2.lowerSide.ndom,
                        uqybtLowersidelNdom3: e.target.value
                      }
                    }
                  }
                }))
              }}
            />
          </Grid>
        </Grid>

        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          Distância Normalizada DOM
        </p>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='MED'
              value={normalizedDistanceDomScore().scoreMED}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='LS'
              value={normalizedDistanceDomScore().scoreLS}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='LI'
              value={normalizedDistanceDomScore().scoreLI}
              disabled
            />
          </Grid>
        </Grid>

        <p className='titulo-form' style={{ marginTop: '10px' }}>Distância Normalizada NDOM</p>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='MED'
              value={normalizedDistanceNdomScore().scoreMED}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='LS'
              value={normalizedDistanceNdomScore().scoreLS}
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              placeholder='LI'
              value={normalizedDistanceNdomScore().scoreLI}
              disabled
            />
          </Grid>
        </Grid>
      </article> 

      <article>
        <Grid container spacing={6}>
          <Grid item>
          <p style={{ marginBottom: 0 }} className={classes.score}>Pontuação Proposta</p>
            <p style={{ fontWeight: '500', marginTop: 0}}>
              LSI - Escore: DOM: {normalizedDistanceDomScore().proposedScoreDOM} - NDOM: {normalizedDistanceNdomScore().proposedScoreNDOM}
            </p>
          </Grid>
          <Grid item>
          <p style={{ marginBottom: 0 }} className={classes.score}>Assimetria Absoluta</p>
            <p style={{ fontWeight: '500', margin: 0}}>
              LSI - Escore: 
              MED: {
                normalizedDistanceDomScore().scoreMED - normalizedDistanceNdomScore().scoreMED
              }&nbsp;
              LS: {
                normalizedDistanceDomScore().scoreLS - normalizedDistanceNdomScore().scoreLS
              }&nbsp;
              LI: {
                normalizedDistanceDomScore().scoreLI - normalizedDistanceNdomScore().scoreLI
              }
            </p>
          </Grid>
        </Grid>
      </article> 

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          SMB-T
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.smbt1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    smbt1: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.smbt2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    smbt2: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3º'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.smbt3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    smbt3: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {
          (
            parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.smbt1)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.smbt2)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.smbt3)
          ) / 3 || 0
        }</p>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          USSP-TEST
        </p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1ºDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestDom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    usspTestDom1: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2ºDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestDom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    usspTestDom2: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3ºDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestDom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    usspTestDom3: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {
          (
            parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestDom1)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestDom2)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestDom3)
          ) || 0
        }</p>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='1ºNDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestNdom1}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    usspTestNdom1: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='2ºNDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestNdom2}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    usspTestNdom2: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="select"
              variant="outlined"
              fullWidth
              type='number'
              placeholder='3ºNDOM'
              value={sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestNdom3}
              disabled={sportsPhysiotherapyView}
              onChange={e => {
                setSportsPhysiotherapyChartAnswers( state => ({
                  ...state,
                  funcionalTests2: {
                    ...state.funcionalTests2,
                    usspTestNdom3: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <p className={classes.score}>LSI - Escore: {
          (
            parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestNdom1)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestNdom2)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTests2.usspTestNdom3)
          ) || 0
        }</p>
      </article>


      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}