import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CircularLoad from '..';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ptBR } from '@material-ui/core/locale';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from "@material-ui/icons/Add"
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { StateGlobal } from '../../../ContextAPI/context'

import DoctorSelect from "./DoctorSelect"
import conexao from '../../../Services/api'
import FormHelperText from '@material-ui/core/FormHelperText';
import { validateEmail, validatePasswordLogin, validateName } from '../../Login/Form/validate';
import Alert from '@material-ui/lab/Alert';
import {campovazio} from '../../../Components/ReduxTeste/ValidateForm'


import './style.css'
import CircularLoad from '../../../Components/Load';
import { data } from '../../Agenda/Modais/Validate';

const useStyles = makeStyles((theme) => ({
    input: {
      width: '100%',
      marginTop: '0.5rem',
      marginBottom: '0.8rem',
      display: 'flex',
      backgroundColor: 'white',
    },
    IconButton: {
        '& .MuiDialogActions-root-1334':{
         justifyContent: 'center !important',
    },
  },
    
}));

const theme = createTheme({
    palette: {
      secondary: {
        light: '#E7366A',
        main: '#E7366A',
        dark: '#D93364',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
    },
  }, ptBR);

export default function ModalAtendente(props) {
  const initialState = {
    name: '',
    email: '',
    password: '',
    clinic_id: 0,
    doctor_id: 0,
    selectedDoctors: []
  }

  const [validatesx, setvalidatesx] = useState([
    { name: 'name', funcao: campovazio, error: false , value:'' },
    { name: 'email', funcao: campovazio, error: false ,value:''},
    { name: 'password', funcao: campovazio, error: false ,value:''}
  ]);

  const initialvalidate =[
    { name: 'name', funcao: campovazio, error: false , value:'' },
    { name: 'email', funcao: campovazio, error: false ,value:''},
    { name: 'password', funcao: campovazio, error: false ,value:''}
  ]

  const classes = useStyles();
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(props.open)
  const [name, setNome] = useState(undefined);
  const [email, setEmail] = useState("");
  const [data, setData] = useState(initialState);
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const[changepassword,setChangepassword] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [doctorsList, setDoctorsList] = useState('');
  const {attatendente, setAttatendente} = StateGlobal();

  // function onchangeform(e) {
  //   const { name, value } = e.target
  //   validatesx.map((item) => {
  //     if (item.name === name) {
  //       item.error = item.funcao(value)
  //       item.value = value    
  //     }
  //   })

  //   const arr = validatesx.slice()
  //   setvalidatesx(arr)
  // }

  function onChange(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });

    validatesx.map((item) => {
      if (item.name === name) {
        item.error = item.funcao(value)
      }
    })
  }


  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });


  function closeModal(){
    setOpen(false);
    // localStorage.setItem('selectedUser', '');
    console.log('closeModal')
    props.close()
  }

  useEffect(() => {
    setData(initialState)
    setDoctorsList(JSON.parse(localStorage.getItem('clinicDoctors')))
  }, [])

// Quando abre para editar
  useEffect(() => {
    console.log('useEffect (ModalAtendente)')
    if (props.item)
    {
      console.log(props.item)
      conexao.get('/employee/byid/' + props.item).then((res) => {  
        console.log(res)
        // localStorage.setItem('selectedUser', res.data.data[0].user_id)
      
        var aux = res.data.data[0].user;
        aux['selectedDoctors'] = [];
        
        var doctors = res.data.data[0].doctors;
        doctors.forEach(function(doctor) {
          aux['selectedDoctors'].push(doctor.id)
        })

        console.log(aux)

        setData(aux);
        
      }).catch((error) => {
      })
    }
    else
    {
      if (localStorage.getItem('clinicId') != null)
        setData({ ...data, ['clinic_id']: parseInt(localStorage.getItem('clinicId')) });

      if (localStorage.getItem('loginType') == 'DOCTOR') // DOCTOR
        setData({ ...data, ['doctor_id']: parseInt(localStorage.getItem('selectedDoctor')) });

    }

    setOpen(props.open)
  }, [props.item, props.open])

  function save() 
  {
    validatesx.map((item) => {
        item.error = item.funcao(data[name])
    })

    // Edit
    if(props.item) {
      if(changepassword && !validatePasswordLogin(values.password)) {
        setMessage('O campo senha precisa ter ao menos 6 dígitos.')
      }
      else
      {
        setLoad(true)
        setData({ ...data, ['password']: values.password !== '' ? values.password : '' });
        // setData({ ...data, ['selectedDoctors']: selectedDoctors });
  
        conexao.put('/employee/update/'+props.item, data).then((res)=>{
          
          setLoad(false)
          alert ('Atendente editado(a) com sucesso!', 'success')

          setTimeout(() => {
            alert ('', '')
            closeModal();
          }, 3000);

        }).catch((error)=>{
          
          setLoad(false)
          alert('Este e-mail já está cadastrado.', 'error')

          setTimeout(() => {
            alert ('', '')
          }, 3000);

        })
      }
    } else {
      setLoad(true)    
      setData({ ...data, ['password']: values.password !== '' ? values.password : '' });

      conexao.post('/employee/create', data).then((res)=>{
  
      setLoad(false)
      alert ('Atendente cadastrado(a) com sucesso!', 'success')

      setTimeout(() => {
        alert ('', '')
        closeModal();
      }, 3000);

      }).catch((error)=>{
        setLoad(false)
        alert ('Erro ao cadastrar!', 'error')

        setTimeout(() => {
          alert ('', '')
        }, 3000);

      })
    }
  }

  function alert(message, type)
  {
    setMessage(message)
    setTypeAlert(type)
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Atyualiza o json como profissional selecionado dentro do componente SeleciDoctor
  function updateSelectedDoctors(e) {
    setData({ ...data, 'selectedDoctors': e.target.value })
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className='tamanho-modal'>
          <DialogTitle id="alert-dialog-title">
            <div className='title-atendente'> Cadastro de Atendente
              <div className='title-fechar'><CloseIcon onClick={()=>{closeModal()}}/></div>
            </div>
          </DialogTitle>

          <DialogContent>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
            : ''}

            <DialogContentText id="alert-dialog-description">
              <TextField
                className={classes.input} 
                name='name'
                value={data.name} 
                placeholder="Nome"
                onChange={(event) => {
                  // setNome(event.target.value);
                  onChange(event)
                }}           
                error={validatesx[0].error}
                helperText={validatesx[0].error ? 'este campo precisa ser preenchido': ''}
                type="text" 
                required
                id="" 
                label="Nome" 
                variant="outlined"
              />

              <TextField            
                className={classes.input} 
                name='email' 
                value={data.email} 
                placeholder="Digite o seu e-mail"
                onChange={(event) => {
                  // setEmail(event.target.value);
                  onChange(event)
                }} 
                error={validatesx[1].error}
                helperText={validatesx[1].error ? 'este campo precisa ser preenchido': ''}
                type="text" 
                required
                id="" 
                label="E-mail" 
                variant="outlined"
              />
            
              {(localStorage.getItem('profileId') == 2) ?
                <FormControl variant="outlined" style={{width: '100%' }}>
                  <InputLabel htmlFor="outlined-adornment-password">Profissionais</InputLabel>
                    <Select
                      labelId="selected-doctors"
                      id="selected-doctors"
                      value={data.selectedDoctors}
                      name="selected-doctors"
                      label="Profissionais"
                      onChange={updateSelectedDoctors}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected ? selected.map((value) => (
                            <Chip key={value} label={doctorsList.find(element => element['id'] == value).user.name} />
                          )) : ''}
                        </Box>
                      )}
                      className={classes.input}
                      multiple
                    >
                      {doctorsList &&
                        doctorsList.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              <Checkbox checked={(data.selectedDoctors) ? data.selectedDoctors.includes(item.id) : false} />
                              <ListItemText primary={item.user.name} />
                            </MenuItem>
                          )
                        })
                      }
                    </Select>
                </FormControl>
              : ''}

              {props.item ?  
                <Button color="primary" onClick={()=>{
                  setChangepassword(!changepassword)
                }}>Deseja redefinir sua  senha  de atendente clique aqui</Button>
              : ''}

              {changepassword || !props.item ?
                <FormControl  variant="outlined" style={{width: '100%' }} error={validatesx[2].error}>
                  <InputLabel htmlFor="outlined-adornment-password" required>Senha</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={data.password}
                    required
                    name='password'
                    onChange={ (e)=>{
                      onChange(e)
                      setValues({ ...data, 'password': e.target.value })
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <div className='teste'>
                        <IconButton
                        className={classes.IconButton}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </div>
                      </InputAdornment>
                    }
                    labelWidth={55}
                  />
                  <FormHelperText id="component-error-text">{validatesx[2].error ? 'este campo precisa ser preenchido': ''}</FormHelperText>
                </FormControl>
              :""}
            </DialogContentText>
            <DialogActions>
              <Button onClick={()=>{closeModal()}} color="default">
                Cancelar  
              </Button>

              {!load ?
                <Button onClick={save}
                  variant="contained" color="primary" className="color-primary" autoFocus>
                  {props.item ? 'Salvar' : 'Inserir'}
                </Button>
              :
                <Button variant="contained" className="color-primary" color="primary">
                  <CircularLoad/>
                </Button>
              }
            </DialogActions>
          </DialogContent>

        </div>
      </Dialog>
    </ThemeProvider>  
  );
}