import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import ModalDetalheAgendamento from './ModalDetalhesAgendamento';
import CloseIcon from '@material-ui/icons/Close';
import '../css/Agenda.css'
import '../Tabela/header/css/header.css'
import { TextareaAutosize } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { StateGlobal } from '../../../ContextAPI/context';
import { currency, currencyinput } from './Validate';
import conexao from '../../../Services/api'
import Alert from '@material-ui/lab/Alert'
import { campovazio } from '../../../Components/ReduxTeste/ValidateForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    // backgroundColor: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  SelectProcedimento: {
    width: '100%'
  },
  QuantProce: {
    width: '30%',
    marginLeft: '1rem',
    backgroundColor: 'white',
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
  },
  selectTipoAtendimento: {
    width: '100%'
  },
  input: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  selectInput: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  selectLabel: {
    transform: 'translate(10px, 10px) scale(0.75)',
    background: 'linear-gradient(0deg, #fff 58%, transparent 50%)',
    padding: '0px 5px'
  },
  inputTel: {
    width: '50%',
    marginRight: '1rem',
    backgroundColor: 'white',
  },
  paddingButton: {
    paddingRight: '1.5rem'
  },
  inputData: {
    width: '30%',
  },
  label: {
    background: 'white',
    paddingRight: '.4rem'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },

  DadosPaciente: {
    color: '#8F8F8F',
    fontSize: '14px',
    marginTop: "15px",
    display: 'flex',
    flexDirection: 'row'
  },
  ResultGet: {
    color: '#8F8F8F',
    fontSize: '14px',
    fontWeight: 'bold'
  },

  inputquant: {

    width: '60px',
    '& input': {
      padding: '5px',

    }
  }, total: {

    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px',
    marginLeft: '30px',
    color: '#8F8F8F',
    marginRight: '30px',
    '& span': {
      marginRight: '10px'
    }
  },

  select: {
    width: '200px',
    '& div': {
      padding: '15px',


    },

    margin: '10px',
    backgroundColor: '#FFFF'

  },
  tabelaAgendamento: {
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
    color: '#8F8F8F',
  },
  divselect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    margin: '10px',
    fontWeight: 'bold',
    color: '#8F8F8F',
    width: '100%'
  },
  opt:{
    cursor: 'pointer'
  }



}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },


  },
}, ptBR);



export default function ModalAddAgendamento(props) {



  const initialvalues = {
    payment_type: '',
    payment_status: '',
    payment_value: 99,
    payment_type_id: 0
  }

  const classes = useStyles();
  // const [procedimento, setProcedimento] = React.useState('');
  // const [tipoAtendimento, setTipoAtendimento] = React.useState('');
  // const [frequenciaAtendimento, setFrequenciaAtendimento] = React.useState('');
  const [open, setOpen] = useState(false);
  const [modalDetalhesAgendamento, setModalDetalhesAgendamento] = useState(false);
  const [values, setValues] = useState(initialvalues);
  // const [valor, setValor] = useState(1);
  // const [qtd, setQtd] = useState();
  const [valorTotal, setValorTotal] = useState(0);
  const { agendadoData, setAgendadoData } = StateGlobal();
  const { statusTabela, setStatusTabela } = StateGlobal();
  const [error, setError] = useState();
  const [openAlert, setOpenAlert] = useState(false)
  var dados = false
  const [arr, setArr] = useState(dados)
  const [formValid, setFormValid] = useState(false)
  const [paymentTypeList, setPaymentTypeList] = useState(false)
  // const [paymentTypeId, setPaymentTypeId] = useState()
  

  const initialvalidate = {
    payment_type: { name: 'payment_type', funcao: campovazio, error: false, value: '' },
    payment_status: { name: 'payment_status', funcao: campovazio, error: false, value: '' },
    payment_value: { name: 'payment_value', funcao: currency, error: false, value: 'R$ ' },
    payment_type_id: { name: 'payment_type_id', funcao: campovazio, error: false, value: 0 },
    
  }


  const [validateTest, setValidateTest] = useState(initialvalidate)
  

  function onchangeValidateTeste(e) {
    const { name, value } = e.target   
    const obj = validateTest[name]
    const er = validateTest[name].funcao(value)
    let att = { ...obj, error: er, value: value }   
    setValidateTest({ ...validateTest, [name]: att })

  }

  const openModalDetalhesAgendamento = () => {
    setModalDetalhesAgendamento(true)
  }

  const alteraEstadoModal = (modalDetalhesAgendamento) => {
    setModalDetalhesAgendamento(modalDetalhesAgendamento);
  };

  const closeModal = () => {
    setStatusTabela(!statusTabela)
    setOpen(false);
    props.onChange(false)

  }

  useEffect(() => {
    setOpen(props.open);
    setValidateTest(initialvalidate) 

  }, [props])

  useEffect(() => {
    getPaymentTypes();
    console.log('useEffect - LancaPagamento')
    console.log(agendadoData)
    let total = 0

    if (agendadoData[0] !== undefined) {
      dados = agendadoData[0]
      setArr(dados)
    } else {
      dados = agendadoData
      setArr(dados)
    }

    dados.items.map((item) => {
      total = (total + (item.value * item.quantity))
    })

    total = currency(total.toFixed(2))
    setValorTotal(total)
    setValues({payment_value: total });

  }, [])

  useEffect(() => {
    console.log('VALUES_CHANGED')
    console.log(values.payment_type_id)

    if( values.payment_status == 'PENDENTE' && values.payment_value)
      setFormValid(true)
    else if( values.payment_status == 'PAGO' && values.payment_type && values.payment_value)
      setFormValid(true)
    else
      setFormValid(false)

  }, [values])


  function createData(procedimento, quant, valor) {
    return { procedimento, quant, valor };
  }

  function getPaymentTypes()
  {
    console.log('getPaymentTypes')
    conexao.get('finance/paymenttype').then((res) => {
      console.log(res.data.data)
      setPaymentTypeList(res.data.data)
    }).catch((error) => {
    })
  }

  function enviaDadosAgendamento(event) {
    event.preventDefault();
    closeModal();
    openModalDetalhesAgendamento();
  }

  function onchange(e) {
    console.log('target:', e.target.value)
    const { name, value } = e.target;
    setValues({ ...values, [name]: value});
  }


  function save() {
    const arrvalidate =  Object.values(validateTest)

    arrvalidate.map((item) => {   
      if(item.value === ''){       
        validateTest[item.name].error = true   
      }
      setValidateTest({...validateTest})    
    })

    var payload = {
      payment_type: values.payment_type,
      payment_status: values.payment_status,
      payment_value: parseFloat(values.payment_value.toString().replace('R$ ', '').replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1.$2")),
      payment_type_id: values.payment_type_id
    }

    console.log('PAYLOAD')
    console.log(payload)

    conexao.put(`/schedule/payment/${arr.id}`, payload).then((res) => {
      setOpenAlert(true)
      setError('success')
      setTimeout(() => {
        closeModal();
      }, 1500);
      setTimeout(() => {
        setOpenAlert(false)
      }, 1500);

    }).catch((error) => {
      setOpenAlert(true)
      setError('error')
      setTimeout(() => {
        setOpenAlert(false)
      }, 3000);
    })
  }

  function updatePaymentTypeId(id)
  {
    console.log('updatePaymentTypeId')
    console.log(id)
    // () => { setValues({ ...values, ['payment_type_id']: item.id})}
    var aux = values;
    aux.payment_type_id = id;
    setValues(aux);
  }

  return (

    <div className="Modal-agendamento">
      <ThemeProvider theme={theme} style={{ border: '6px solid red' }}>
        <Dialog open={open} aria-labelledby="Modal-title-agendamento" className={'dialogteste'} >
          <form id="" className={classes.modalColor} onSubmit={enviaDadosAgendamento} autoComplete="off" >
            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
                Lançar Recebimento
            <CloseIcon onClick={closeModal} />
              </div>
              <label style={{ fontSize: '14px' }}>
                {dados.date}
              </label>
              <div>
              </div>
              <div style={{ fontSize: '12px', color: '#8F8F8F' }}>
                Convênio : {arr.type}
              </div>

              {openAlert ?
                <div>
                  {error == 'error' ?
                    <Alert variant="filled" severity="error">Não foi possível processar o pagamento!</Alert>
                    :
                    <Alert variant="filled" severity="success">Recebimento feito com sucesso!</Alert>
                  }

                </div> : " "
              }
            </DialogTitle>
            <DialogContent>
              <TableContainer className={classes.tabelaAgendamento} component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead className={classes.tabelaAgendamento}>
                    <TableRow className={classes.tabelaAgendamento}>
                      <TableCell className={classes.tabelaAgendamento}>Procedimento</TableCell>
                      <TableCell className={classes.tabelaAgendamento} align="center">Quantidade</TableCell>
                      <TableCell className={classes.tabelaAgendamento} align="center">Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {arr ?

                      arr.items.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell className={classes.tabelaAgendamento} component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell className={classes.tabelaAgendamento} align="center">{row.quantity}</TableCell>
                          <TableCell className={classes.tabelaAgendamento} align="center">R$ {(row.value * row.quantity).toFixed(2)}</TableCell>
                        </TableRow>
                      ))
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>

              <div>
                <div className={classes.total}>
                  <span>Total</span>  <span>R${valorTotal}</span>
                </div>
              </div>


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                <div className={classes.divselect} style={{width: '30%;'}}>
                {paymentTypeList ? 
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel} id="demo-simple-select-outlined-label">Tipo de pagamento</InputLabel>
                    <Select
                      className={classes.selectInput}
                      value={values.payment_type}
                      name='payment_type'
                      onChange={(e) => { onchange(e)}}
                      label="Tipo de pagamento"
                      // variant="outlined"
                    >
                        <MenuItem key={'0'} value={''} selected>Selecione</MenuItem>
                        {paymentTypeList.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.name} onClick={() => { updatePaymentTypeId(item.id) }}>{item.name} </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                  : ''}
                </div>

                <div className={classes.divselect} style={{width: '30%;'}}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                      className={classes.input}
                      value={'R$ ' + currency(values.payment_value)}
                      onChange={(e) => { onchange(e) }}
                      type="text"
                      name='payment_value'
                      label="Novo Valor"
                      variant="outlined"
                    />
                  </FormControl>
                </div>
               
                <div className={classes.divselect} style={{width: '30%;'}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel} id="demo-simple-select-outlined-label">Status</InputLabel>
                    <Select
                      className={classes.selectInput}
                      value={values.payment_status}
                      name='payment_status'
                      onChange={(e) => { onchange(e)}}
                      label="Status"
                      variant="outlined"
                    >
                        <MenuItem key={'0'} value={''} selected>Selecione</MenuItem>
                        <MenuItem key={'1'} value={'PAGO'}>Pago</MenuItem>
                        <MenuItem key={'2'} value={'PENDENTE'}>Pendente</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

            </DialogContent>
            <DialogActions className={classes.paddingButton}>
              <Button onClick={closeModal} type="button">
                VOLTAR
              </Button>
              <Button variant="contained" color="primary" className="color-primary"  onClick={save}  disabled={!formValid ? 'disabled' : '' }>
                LANÇAR PAGAMENTO
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <ModalDetalheAgendamento open={modalDetalhesAgendamento} onChange={alteraEstadoModal} />
      </ThemeProvider>

    </div>
  );
}
