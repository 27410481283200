import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from './Store/store'
import ContextAPI from './ContextAPI/context'
import { SportsPhysiotherapyProvider } from './ContextAPI/SportsPhysiotherapyContext'

ReactDOM.render(
  <React.StrictMode>
    <ContextAPI>
      <Provider store={store}>
        {/* <React.StrictMode><App /></React.StrictMode> */}
        <SportsPhysiotherapyProvider>
          <App />
        </SportsPhysiotherapyProvider>
      </Provider>
    </ContextAPI>
  </React.StrictMode>,
  document.getElementById('root')
);
