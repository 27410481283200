import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function NeckDisabilityIndex({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);
  
  function handleChange(e) {
    const { name, value } = e.target

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      ndi: {
        ...state.ndi,
        [name]: value
      }
    }))
  }

  function getNdiScore(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.ndi).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })
    
    return score ? (score).toFixed(2) : 0;
  }


  return (
    <div>
      <p>
        Este questionário foi criado para dar informações como a sua dor no pescoço tem afetado a sua habilidade para fazer atividades diárias. Por favor responda a cada uma das perguntas e marque em cada seção apenas uma alternativa que melhor se aplique a você. 
      </p>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          1. Intensidade da dor:
        </p>

        <TextField
          name='painIntensity'
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.painIntensity}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu não tenho dor nesse momento</option>
          <option value="1">A dor é muito leve nesse momento</option>
          <option value="2">A dor é moderada nesse momento</option>
          <option value="3">A dor é razoavelmente grande nesse momento</option>
          <option value="4">A dor é muito grande nesse momento</option>
          <option value="5">A dor é a pior que se possa imaginar nesse momento</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          2- Cuidado pessoal (se lavar, se vestir, etc.):
        </p>

        <TextField
          name="personalCare"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.personalCare}
          onChange={e => handleChange(e)}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu posso cuidar de mim mesmo(a) sem aumentar a dor</option>
          <option value="1">Eu posso cuidar de mim mesmo(a) normalmente, mas isso faz aumentar a dor</option>
          <option value="2">É doloroso ter que cuidar de mim mesmo e eu faço isso lentamente e com cuidado</option>
          <option value="3">Eu preciso de ajuda, mas consigo fazer a maior parte do meu cuidado pessoal</option>
          <option value="4">Eu preciso de ajuda todos os dias na maioria dos aspectos relacionados a cuidar de mim mesmo(a)</option>
          <option value="5">Eu não me visto, me lavo com dificuldade e fico na cama</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          3- Levantar coisas:
        </p>

        <TextField
          name="liftThings"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.liftThings}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu posso levantar objetos pesados sem aumentar a dor</option>
          <option value="1">Eu posso levantar objetos pesados mas isso faz aumentar a dor</option>
          <option value="2">A dor me impede de levantar objetos pesados do chão, mas eu consigo se eles estiverem colocados em uma boa posição, por exemplo em uma mesa</option>
          <option value="3">A dor me impede de levantar objetos pesados, mas eu consigo levantar objetos com peso entre leve e médio se eles estiverem colocados em uma boa posição.</option>
          <option value="4">Eu posso levantar objetos muito leves.</option>
          <option value="5">Eu não posso levantar nem carregar absolutamente nada.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          4- Leitura:
        </p>

        <TextField
          name="reading"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.reading}
          onChange={e => handleChange(e)}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu posso ler tanto quanto eu queira sem dor no meu pescoço</option>
          <option value="1">Eu posso ler tanto quanto eu queira com uma dor leve no meu pescoço</option>
          <option value="2">Eu posso ler tanto quanto eu queira com uma dor moderada no meu pescoço</option>
          <option value="3">Eu não posso ler tanto quanto eu queira por causa de uma dor moderada no meu pescoço</option>
          <option value="4">Eu mal posso ler por causa de uma grande dor no meu pescoço.</option>
          <option value="5">Eu não posso ler nada.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          5- Dores de cabeça:
        </p>

        <TextField
          name="headaches"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.headaches}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu não tenho nenhuma dor de cabeça</option>
          <option value="1">Eu tenho pequenas dores de cabeça com pouca frequência</option>
          <option value="2">Eu tenho dores de cabeça moderadas com pouca frequência</option>
          <option value="3">Eu tenho dores de cabeça moderadas muito frequentemente</option>
          <option value="4">Eu tenho dores de cabeça fortes frequentemente.</option>
          <option value="5">Eu tenho dores de cabeça quase o tempo inteiro.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          6- Prestar Atenção:
        </p>

        <TextField
          name="payAttention"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.payAttention}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu consigo prestar atenção quando eu quero sem dificuldade</option>
          <option value="1">Eu consigo prestar atenção quando eu quero com uma dificuldade leve</option>
          <option value="2">Eu tenho uma dificuldade moderada em prestar atenção quando eu quero</option>
          <option value="3">Eu tenho muita dificuldade em prestar atenção quando eu quero</option>
          <option value="4">Eu tenho muitíssima dificuldade em prestar atenção quando eu quero</option>
          <option value="5">Eu não consigo prestar atenção</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          7- Trabalho:
        </p>

        <TextField
          name="work"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.work}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu posso trabalhar tanto quanto eu quiser</option>
          <option value="1">Eu só consigo fazer o trabalho que estou acostumado(a) a fazer, mas nada além disso</option>
          <option value="2">Eu consigo fazer a maior parte do trabalho que estou acostumado(a) a fazer, mas nada além disso</option>
          <option value="3">Eu não consigo fazer o trabalho que estou acostumado(a) a fazer</option>
          <option value="4">Eu mal consigo fazer qualquer tipo de trabalho</option>
          <option value="5">Eu não consigo fazer nenhum tipo de trabalho</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          8- Dirigir automóveis:
        </p>

        <TextField
          name="driveCars"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.driveCars}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu posso dirigir meu carro sem nenhuma dor no pescoço</option>
          <option value="1">Eu posso dirigir meu carro tanto quanto eu queira com uma dor leve no meu pescoço</option>
          <option value="2">Eu posso dirigir meu carro tanto quanto eu queira com uma dor moderada no meu pescoço</option>
          <option value="3">Eu não posso dirigir o meu carro tanto quanto eu queira por causa de uma dor moderada no meu pescoço</option>
          <option value="4">Eu mal posso dirigir por causa de uma dor forte no meu pescoço</option>
          <option value="5">Eu não posso dirigir meu carro de maneira nenhuma</option>
          <option value="0">Pergunta não se aplica por não saber dirigir ou não dirigir muitas vezes</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          9- Dormir:
        </p>

        <TextField
          name="sleep"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.sleep}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu não tenho problemas para dormir</option>
          <option value="1">Meu sono é um pouco perturbado (menos de uma hora sem conseguir dormir).</option>
          <option value="2">Meu sono é levemente perturbado (1-2 horas sem conseguir dormir).</option>
          <option value="3">Meu sono é moderadamente perturbado (2-3 horas sem conseguir dormir).</option>
          <option value="4">Meu sono é muito perturbado (3-5 horas sem conseguir dormir).</option>
          <option value="5">Meu sono é completamente perturbado (1-2 horas sem sono).</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          10- Diversão:
        </p>

        <TextField
          name="fun"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.ndi.fun}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu consigo fazer todas as minhas atividades de diversão sem nenhuma dor no pescoço</option>
          <option value="1">Eu consigo fazer todas as minhas atividades de diversão com alguma dor no pescoço</option>
          <option value="2">Eu consigo fazer a maioria, mas não todas as minhas atividades de diversão por causa da dor no meu pescoço</option>
          <option value="3">Eu consigo fazer poucas das minhas atividades de diversão por causa da dor no meu pescoço</option>
          <option value="4">Eu mal consigo fazer quaisquer atividades de diversão por causa da dor no meu pescoço</option>
          <option value="5">Eu não consigo fazer nenhuma atividade de diversão</option>
        </TextField>

        <p className={classes.score}>
          Escore: {getNdiScore()} {'->'} (Incapacidade leve: 5 - 14 / Incapacidade moderada: 15 - 24 / Incapacidade severa: 25 - 35 / Incapacidade completa: +36) 
        </p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}