import React, { useContext, useRef, useEffect, useState } from 'react'

import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style'

import { SportsPhysiotherapyContext } from '../../../../../../ContextAPI/SportsPhysiotherapyContext'

export default function LimiarDeDorPorPressao() {
  const classes = useStyles()

  const [dynamometerType, setDynamometerType] = useState('all')
  
  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  const dynamometer = useRef();
  const othersDynamometer = useRef();
  const unitOfMeasurement = useRef();
  
  useEffect(() => {
    let dynamometerEl = dynamometer.current
    let othersDynamometerEl = othersDynamometer.current
    let unitOfMeasurementEl = unitOfMeasurement.current

    return () => {
      setSportsPhysiotherapyChartAnswers(state => ({
        ...state,
        algometry: {
          ...state.algometry,
          dynamometer: dynamometerEl.value,
          othersDynamometer: othersDynamometerEl.value,
          unitOfMeasurement: unitOfMeasurementEl.value,
        }
      }))
    }
  }, [setSportsPhysiotherapyChartAnswers])

  return (
    <div style={{ marginTop: '15px'}}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <p className="titulo-form">Escolha o seu Dinamômetro</p>
          <TextField
            id="select"
            variant="outlined"
            select
            fullWidth
            disabled={sportsPhysiotherapyView}
            inputRef={dynamometer}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.dynamometer}
            onChange={(e) => setDynamometerType(e.target.value)}
            SelectProps={{
              native: true,
            }}
          > 
            <option value="all" selected>...</option>
            <option value="MicroFet">MicroFet 2®</option>
            <option value="Lafayette">Lafayette®</option>
            <option value="MedTech">Med EOR - MedTech (SPTech) ®</option>
            <option value="E-Lastic">E-Lastic®</option>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <p className="titulo-form">Outros:</p>
          <TextField
            id="select"
            variant="outlined"
            fullWidth
            disabled={sportsPhysiotherapyView}
            inputRef={othersDynamometer}
            defaultValue={sportsPhysiotherapyChartAnswers.algometry.othersDynamometer}
          />
        </Grid>
      </Grid>

      <p className="titulo-form">Unidade de medida</p>
      <TextField
        id="select"
        variant="outlined"
        select
        fullWidth
        disabled={sportsPhysiotherapyView}
        inputRef={unitOfMeasurement}
        defaultValue={sportsPhysiotherapyChartAnswers.algometry.unitOfMeasurement}
        onChange={(e) => {
          setSportsPhysiotherapyChartAnswers(state => ({
            ...state,
            unitType: e.target.value
          }))
        }}
        SelectProps={{
          native: true,
        }}
      > 
        <option selected>...</option>

        {dynamometerType === 'MicroFet' && (
          <>
            <option value="lbs">Libras (lbs)</option>
            <option value="N">Newtons (N)</option>
            <option value="Kg/F">Quilograma/Força (Kg/F)</option>
          </>
        )}
        {dynamometerType === 'Lafayette' && (
          <>
            <option value="N">Newtons (N)</option>
            <option value="Kg">Quilograma (Kg)</option>
          </>
        )}
        {dynamometerType === 'MedTech' && (
          <>
            <option value="Kg/F">Quilograma/Força (Kg/F)</option>
            <option value="N">Newtons (N)</option>
          </>
        )}
        {dynamometerType === 'E-Lastic' && (
          <>
            <option value="Kg">Quilograma (Kg)</option>
          </>
        )}
        {dynamometerType === 'all' && (
          <>
            <option value="lbs">Libras (lbs)</option>
            <option value="N">Newtons (N)</option>
            <option value="Kg">Quilograma (Kg)</option>
            <option value="Kg/F">Quilograma/Força (Kg/F)</option>
            <option value="N/Kg">N/Kg</option>
            <option value="N.m">N.m</option>
          </>
        )}
      </TextField>

      <div>
      <h3 
        className='titulo-form'
        style={{ fontWeight: 500, fontSize: '1.3em', marginTop: '15px' }}
      > 
        Força muscular Dinamometria isométrica
      </h3>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Flexores de Ombro:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderFlexorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderFlexorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderFlexorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderFlexorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.shoulderFlexorsDom - sportsPhysiotherapyChartAnswers.algometry.shoulderFlexorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Extensores de Ombro:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderExtendersDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderExtendersDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderExtendersNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderExtendersNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.shoulderExtendersDom - sportsPhysiotherapyChartAnswers.algometry.shoulderExtendersNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.shoulderFlexorsDom / sportsPhysiotherapyChartAnswers.algometry.shoulderExtendersDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.shoulderFlexorsNDom / sportsPhysiotherapyChartAnswers.algometry.shoulderExtendersNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Flexores de Cotovelo:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.elbowFlexorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    elbowFlexorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.elbowFlexorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    elbowFlexorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.elbowFlexorsDom - sportsPhysiotherapyChartAnswers.algometry.elbowFlexorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Extensores de Cotovelo:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.elbowExtendersDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    elbowExtendersDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.elbowExtendersNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    elbowExtendersNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.elbowExtendersDom - sportsPhysiotherapyChartAnswers.algometry.elbowExtendersNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.elbowFlexorsDom / sportsPhysiotherapyChartAnswers.algometry.elbowExtendersDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.elbowFlexorsNDom / sportsPhysiotherapyChartAnswers.algometry.elbowExtendersNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Flexores de Punho:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.wristFlexorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    wristFlexorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.wristFlexorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    wristFlexorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.wristFlexorsDom - sportsPhysiotherapyChartAnswers.algometry.wristFlexorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Extensores de Punho:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.wristExtensorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    wristExtensorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.wristExtensorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    wristExtensorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.wristExtensorsDom - sportsPhysiotherapyChartAnswers.algometry.wristExtensorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.wristFlexorsDom / sportsPhysiotherapyChartAnswers.algometry.wristExtensorsDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.wristFlexorsNDom / sportsPhysiotherapyChartAnswers.algometry.wristExtensorsNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Flexores de Quadril:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipFlexorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipFlexorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipFlexorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipFlexorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.hipFlexorsDom - sportsPhysiotherapyChartAnswers.algometry.hipFlexorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Extensores de Quadril:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipExtendersDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipExtendersDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipExtendersNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipExtendersNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.hipExtendersDom - sportsPhysiotherapyChartAnswers.algometry.hipExtendersNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.hipFlexorsDom / sportsPhysiotherapyChartAnswers.algometry.hipExtendersDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.hipFlexorsNDom / sportsPhysiotherapyChartAnswers.algometry.hipExtendersNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Flexores de Joelho:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.kneeFlexorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    kneeFlexorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.kneeFlexorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    kneeFlexorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.kneeFlexorsDom - sportsPhysiotherapyChartAnswers.algometry.kneeFlexorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Extensores de Joelho:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.kneeExtendersDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    kneeExtendersDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.kneeExtendersNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    kneeExtendersNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.kneeExtendersDom - sportsPhysiotherapyChartAnswers.algometry.kneeExtendersNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.kneeFlexorsDom / sportsPhysiotherapyChartAnswers.algometry.kneeExtendersDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.kneeFlexorsNDom / sportsPhysiotherapyChartAnswers.algometry.kneeExtendersNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Abdutores de Ombro:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderAbductorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderAbductorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderAbductorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderAbductorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.shoulderAbductorsDom - sportsPhysiotherapyChartAnswers.algometry.shoulderAbductorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Adutores de Ombro:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderAdductorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderAdductorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderAdductorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderAdductorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.shoulderAdductorsDom - sportsPhysiotherapyChartAnswers.algometry.shoulderAdductorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.shoulderAbductorsDom / sportsPhysiotherapyChartAnswers.algometry.shoulderAdductorsDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.shoulderAbductorsNDom / sportsPhysiotherapyChartAnswers.algometry.shoulderAdductorsNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Abdutores de Quadril:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipAbductorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipAbductorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipAbductorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipAbductorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.hipAbductorsDom - sportsPhysiotherapyChartAnswers.algometry.hipAbductorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Adutores de Quadril:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipAdductorsDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipAdductorsDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipAdductorsNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipAdductorsNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.hipAdductorsDom - sportsPhysiotherapyChartAnswers.algometry.hipAdductorsNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.hipAbductorsDom / sportsPhysiotherapyChartAnswers.algometry.hipAdductorsDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.hipAbductorsNDom / sportsPhysiotherapyChartAnswers.algometry.hipAdductorsNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Dorsiflexão de Tornozelo:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.ankleDorsiflexionDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    ankleDorsiflexionDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.ankleDorsiflexionNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    ankleDorsiflexionNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.ankleDorsiflexionDom - sportsPhysiotherapyChartAnswers.algometry.ankleDorsiflexionNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Flexão plantar de Tornozelo:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.anklePlantarFlexionDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    anklePlantarFlexionDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.anklePlantarFlexionNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    anklePlantarFlexionNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.anklePlantarFlexionDom - sportsPhysiotherapyChartAnswers.algometry.anklePlantarFlexionNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.ankleDorsiflexionDom / sportsPhysiotherapyChartAnswers.algometry.anklePlantarFlexionDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.ankleDorsiflexionNDom / sportsPhysiotherapyChartAnswers.algometry.anklePlantarFlexionNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Rotador medial de ombro:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.medialRotatorShoulderDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    medialRotatorShoulderDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.medialRotatorShoulderNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    medialRotatorShoulderNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.medialRotatorShoulderDom - sportsPhysiotherapyChartAnswers.algometry.medialRotatorShoulderNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Rotador lateral de ombro:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderLateralRotatorDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderLateralRotatorDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.shoulderLateralRotatorNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    shoulderLateralRotatorNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.shoulderLateralRotatorDom - sportsPhysiotherapyChartAnswers.algometry.shoulderLateralRotatorNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.medialRotatorShoulderDom / sportsPhysiotherapyChartAnswers.algometry.shoulderLateralRotatorDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.medialRotatorShoulderNDom / sportsPhysiotherapyChartAnswers.algometry.shoulderLateralRotatorNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Rotador medial de quadril:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.medialHipRotatorDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    medialHipRotatorDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.medialHipRotatorNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    medialHipRotatorNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.medialHipRotatorDom - sportsPhysiotherapyChartAnswers.algometry.medialHipRotatorNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p className='titulo-form'>Rotador lateral de quadril:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipLateralRotatorDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipLateralRotatorDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.hipLateralRotatorNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    hipLateralRotatorNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.hipLateralRotatorDom - sportsPhysiotherapyChartAnswers.algometry.hipLateralRotatorNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>

        <p style={{ marginTop: '10px' }} className='titulo-form'>Relação Agonista/Antagonista</p>
        <div className={classes.relationshipResults}>
          <p>
            DOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.medialHipRotatorDom / sportsPhysiotherapyChartAnswers.algometry.hipLateralRotatorDom) * 100) || 0
            }
          </p>
          <p>
            NDOM: {
              parseInt((sportsPhysiotherapyChartAnswers.algometry.medialHipRotatorNDom / sportsPhysiotherapyChartAnswers.algometry.hipLateralRotatorNDom) * 100) || 0
            }
          </p>
        </div>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Protação escapular:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.scapularProtractionDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    scapularProtractionDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.scapularProtractionNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    scapularProtractionNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.scapularProtractionDom - sportsPhysiotherapyChartAnswers.algometry.scapularProtractionNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>
      </div>

      <div className={classes.muscleContainer}>
        <p className='titulo-form'>Trapézio inferior:</p>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='DOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.lowerTrapeziusDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    lowerTrapeziusDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='NDOM'
              disabled={sportsPhysiotherapyView}
              value={sportsPhysiotherapyChartAnswers.algometry.lowerTrapeziusNDom}
              onChange={(e) => {
                setSportsPhysiotherapyChartAnswers(state => ({
                  ...state,
                  algometry: {
                    ...state.algometry,
                    lowerTrapeziusNDom: e.target.value
                  }
                }))
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="select"
              type="number"
              variant="outlined"
              fullWidth
              placeholder='Déficit'
              disabled
              value={(sportsPhysiotherapyChartAnswers.algometry.lowerTrapeziusDom - sportsPhysiotherapyChartAnswers.algometry.lowerTrapeziusNDom)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{sportsPhysiotherapyChartAnswers.unitType}</InputAdornment>,
              }}
            />  
          </Grid>
        </Grid>
      </div>
    </div>
    </div>
  )
}