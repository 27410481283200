import {
  Button,
  createMuiTheme,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import useStyles from "./style/index";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import conexao from "../../../../Services/api";

export default function ModalCurvaDeCrescimento(props) {

  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: "#27b8d2",
        main: "#27b8d2",
        dark: "#229fb5",
        contrastText: "#fff",
      },

      secondary: {
        light: "#fff",
        main: "#fff",
        dark: "#fff",
        contrastText: "#fff",
      },
    },
  });

  const [Patients, setPatients] = useState([]);
  const [value, setValue] = useState();
  const [Types, setTypes] = useState([]);
  const [value2, setValue2] = useState();
  const [DataInit, setDataInit] = useState();
  const [DataEnd, setDataEnd] = useState();
  const [SelectedPatient, setSelectedPatient] = useState();
  const [SelectedTypes, setSelectedTypes] = useState(null);
  const [SelectedTypeValue, setSelectedTypeValue] = useState({
    score: "score",
  });
  const [SelectedGenderValue, setSelectedGenderValue] = useState({
    gender: "M",
  });

  const TypeValue = [
    {
      label: "Z-Score",
      value: "score",
    },
    {
      label: "Percentil",
      value: "perc",
    },
  ];

  const TypeGeder = [
    {
      label: "Masculino",
      value: "M",
    },
    {
      label: "Feminino",
      value: "F",
    },
  ];


  function getPatients() {
    let Doctor_ID = localStorage.getItem("doctorId");
    conexao
      .post(`/patient/get/${Doctor_ID}`)
      .then((res) => {
        setPatients(res.data.data);
      })
      .catch((error) => {});
  }

  function getFormStepQuestion() {
    conexao.post(`/form/curvadecrescimentoselect`).then((res) => {
      setTypes(res.data.data);
    });
  }

  useEffect(() => {
    getPatients();
    getFormStepQuestion();
  }, []);


  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          className={classes.ModalRelatorio}
          open={props.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Filtro de paciente por relatório
          </DialogTitle>
          <DialogContent>
            <div className={classes.startEndDate}>
              <div className={classes.Start}>
                <TextField
                  id="date"
                  label="Data Inicial"
                  type="date"
                  // defaultValue={props.edit === true ? materialDate(agendadoData[0].started_at): '' }
                  defaultValue={""}
                  name="dataconsulta"
                  //required
                  onChange={(e) => {
                    setDataInit(e.target.value);
                  }}
                  value={DataInit}
                  error=""
                  //helperText={validateTest.dataconsulta.error ? validateTest.dataconsulta.error ? validateTest.dataconsulta.error : '':''}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className={classes.End}>
                <TextField
                  id="date"
                  label="Data Final"
                  type="date"
                  // defaultValue={props.edit === true ? materialDate(agendadoData[0].started_at): '' }
                  defaultValue={""}
                  name="dataconsulta"
                  //required
                  onChange={(e) => {
                    setDataEnd(e.target.value);
                  }}
                  value={DataEnd}
                  error=""
                  //helperText={validateTest.dataconsulta.error ? validateTest.dataconsulta.error ? validateTest.dataconsulta.error : '':''}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div>
                <Autocomplete
                  id="combo-box-demo"
                  options={Patients}
                  value={value}
                  getOptionLabel={(options) => options.name}
                  style={{ width: 200, padding: "5px" }}
                  onChange={(e, newValue) => {
                    setSelectedPatient(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Paciente"
                      variant="standard"
                    />
                  )}
                />
              </div>

              <div>
                <Autocomplete
                  id="combo-box-demo"
                  options={Types}
                  value={value}
                  getOptionLabel={(options) => options.name}
                  style={{ width: 200, padding: "5px" }}
                  onChange={(e, newValue) => {
                    setSelectedTypes(newValue);
                    localStorage.setItem("Name_Scale_type", newValue.name);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setValue2(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione a Escala"
                      variant="standard"
                    />
                  )}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <FormControl style={{ width: "48%" }}>
                <InputLabel id="demo-simple-select">
                  Selecionar Tipo
                </InputLabel>
                <Select
                  labelId="demo-simple-select"
                  id="demo-simple-select"
                  value={SelectedTypeValue.score}
                  onChange={(e) => {
                    setSelectedTypeValue({
                      ...SelectedTypeValue,
                      score: e.target.value,
                    });
                  }}
                >
                  {TypeValue.map((TypeValue) => (
                    <MenuItem key={TypeValue.value} value={TypeValue.value}>
                      {TypeValue.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl style={{ width: "48%" }}>
                <InputLabel id="demo-simple-select-label">
                  Selecionar Sexo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-label"
                  value={SelectedGenderValue.gender}
                  onChange={(e) => {
                    setSelectedGenderValue({
                      ...SelectedGenderValue,
                      gender: e.target.value,
                    });
                  }}
                >
                  {TypeGeder.map((TypeGeder) => (
                    <MenuItem key={TypeGeder.value} value={TypeGeder.value}>
                      {TypeGeder.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              onClick={() => {
                props.close();
              }}
            >
              Voltar
            </Button>
            <Button
              className={
                DataInit !== undefined &&
                DataInit !== "" &&
                DataEnd !== undefined &&
                DataEnd !== "" &&
                SelectedTypes !== null
                  ? "color-primary"
                  : "color-disable"
              }
              variant="contained"
              color="primary"
              autoFocus
              onClick={() => {
                let rota;
                localStorage.setItem("title_form", props.name);
                localStorage.setItem("scale_type_id", SelectedTypes.id);
                localStorage.setItem("SelectedTypeValue", SelectedTypeValue.score);
                localStorage.setItem("SelectedGenderValue", SelectedGenderValue.gender);

                if (
                  DataInit !== undefined &&
                  DataEnd !== undefined &&
                  SelectedPatient !== undefined &&
                  SelectedTypeValue !== undefined &&
                  SelectedGenderValue !== undefined &&
                  DataInit !== null &&
                  DataEnd !== null &&
                  SelectedTypeValue !== null &&
                  SelectedGenderValue !== null &&
                  SelectedPatient !== null
                  
                ) {
                  // localStorage.setItem("form_id",SelectedTypes.form_id)
                  rota = `${DataInit}&${DataEnd}&${SelectedPatient.id}`;
                  localStorage.setItem("SelectedPatient", SelectedPatient.name);
                  props.close();
                  window.open(`${props.url}${rota}`);
                }
              }}
            >
              visualizar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
