import {
  Button,
  CircularProgress,
  createMuiTheme,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  ThemeProvider,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { ptBR } from "@material-ui/core/locale";
import useStyles from "./style";
import Grafico from "./Grafico/grafico";
import { useParams } from "react-router";
import conexao from "../../../../Services/api";

export default function CurvaDeCrescimento() {
  const classes = useStyles();
  const theme = createMuiTheme(
    {
      palette: {
        secondary: {
          light: "#27b8d2",
          main: "#27b8d2",
          dark: "#229fb5",
          contrastText: "#fff",
        },
        primary: {
          light: "#27b8d2",
          main: "#27b8d2",
          dark: "#229fb5",
          contrastText: "#fff",
        },
      },
    },
    ptBR
  );

  const Params = useParams();

  const SelectedTypes = localStorage.getItem("scale_type_id");
  const doctor_id = localStorage.getItem("doctorId");
  const SelectedGenderValue = localStorage.getItem("SelectedGenderValue");
  const SelectedTypeValue = localStorage.getItem("SelectedTypeValue");

  const [Load, setLoad] = useState(true);

  const [dataInfoGrafico, setDataInfoGrafico] = useState([]);

  let arr = Params.date.split("&");

  useEffect(() => {
    if (arr[2]) {
      getDataAll({
        started_at: arr[0].replaceAll("-", "/"),
        finished_at: arr[1].replaceAll("-", "/"),
        patient_id: arr[2],
        doctor_id: doctor_id,
        scale_type_id: SelectedTypes,
        type: SelectedTypeValue,
        gender: SelectedGenderValue,
      });
    }
  }, []);

  async function getDataAll(body) {
    const { data } = await conexao.post(`/form/curvadecrescimento`, body);
    //   data.data.map((item,index) => {
    //     if(formtInfoGrafico[item.local]){
    //       return formtInfoGrafico[item.local].push(item)
    //     }else{
    //       return formtInfoGrafico[item.local] = [item]
    //     }
    //   })
    setDataInfoGrafico([data.data]);

    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }

  return (
    <>
      {Load ? (
        <div className="loadingPaciente">
          <CircularProgress style={{ color: "#27B8D2" }} />
        </div>
      ) : (
        <div className="ListagemModelo-container">
          <Grid item xs={12}>
            <Paper className={clsx(classes.paper, classes.colorPaper)}>
              <div
                className={
                  arr[2]
                    ? "ListgemModelo-prontuarios-4"
                    : "ListgemModelo-prontuarios-3"
                }
              >
                <ThemeProvider theme={theme}>
                  <>
                    {dataInfoGrafico &&
                      dataInfoGrafico.map((item, index) => {
                        return (
                          <>
                            <Grafico
                              title={"Curva de Crescimento"}
                              value={item}
                            />
                          </>
                        );
                      })}
                  </>
                </ThemeProvider>
              </div>
            </Paper>
          </Grid>
        </div>
      )}
    </>
  );
}
