import React, {useEffect, useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor:' #f50057 !important',
    color:'#fff',
    "&:hover": {
      backgroundColor: '#c51162 !important;'
    }
  },
  adminAlert: {
    marginTop: '80px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'column'
  },
  adminAlertClinic: {
    marginTop: '160px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    flexShrink: '0',
    flexDirection: 'column',
    position: 'absolute'
  }
}));

const theme = createTheme({
  palette: {
    secondaryAlert: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#E7366A',
      contrastText: '#E7366A',
    }
  },
}, ptBR);

export default function AdminAlert() {
    const classes = useStyles();
    const history = useHistory()

    const [trial, setTrial] = useState()
    const [message, setMessage] = useState()
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        setTrial(localStorage.getItem('expired'));
        setMessage (localStorage.getItem('adminAlert'))

        // Médicos de clínica e atendentes não podemo ver essa mensagem
        if (localStorage.getItem('loginType') == 'CLINIC' || (localStorage.getItem('loginType') == 'DOCTOR' && !parseInt(localStorage.getItem('clinicId')))) {
            setShowAlert(true);
        }
    },[])

    return (
        <>
        {showAlert ?
            <div className={ localStorage.getItem('loginType') == 'CLINIC' ? classes.adminAlertClinic : classes.adminAlert}>
                <Alert className="Dashboard-Graficos-alert" variant="filled" color="info">
                    {message}
                {trial ?
                    <Button variant="contained" className={classes.btn} color="secondaryAlert" 
                    onClick={()=>{ history.push('/planos') }}>
                        ASSINE AGORA
                    </Button>
                : ''
                }
                </Alert>
            </div>
        : ''
        }
        </>
    );
}