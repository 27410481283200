import { Button, CircularProgress, createMuiTheme, Grid, IconButton, InputBase, makeStyles, Paper, ThemeProvider } from "@material-ui/core"
import clsx from 'clsx';
import React, { useEffect, useState } from "react"
import { ptBR } from "@material-ui/core/locale";
import useStyles from './style'
import Grafico from './Grafico/grafico'
import { useParams } from "react-router";
import conexao from "../../../../Services/api";
import GraficoInterpaciente from "./Grafico/graficoInterpaciente";


export default function EscalaDeDor() {
  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);

  const Params = useParams()

  const form_id = localStorage.getItem("form_id")
  const doctor_id = localStorage.getItem("doctorId")
  const [Load,setLoad] = useState(true)

  const [dataInfoGrafico, setDataInfoGrafico] = useState([])

let arr = Params.date.split("&")

  useEffect(() => {

    
      if(arr[2]){
        getDataAll({
          "start_date": arr[0],
          "end_date": arr[1],
          "type": form_id,
          "doctor_id": doctor_id,
          "patient_id": arr[2]
        })
      }
      else {
        getDataAll({
          "start_date": arr[0],
          "end_date": arr[1],
          "type": form_id,
          "doctor_id": doctor_id,
        })
      }

  }, [])

let formtInfoGrafico = []
async function getDataAll(body) {

  const {data} = await conexao.post(body.patient_id ? `/form/questionanswer`: `/form/circulargraphic`, body)
//   data.data.map((item,index) => {
// console.log()
//     if(formtInfoGrafico[item.local]){
//       return formtInfoGrafico[item.local].push(item)
//     }else{
//       return formtInfoGrafico[item.local] = [item]
//     }
//   })
  setDataInfoGrafico(data.data)
  
  setTimeout(() => {
     setLoad(false)
  }, 1500);
 
}

  return(
    <>
    {Load ? <div className='loadingPaciente'>
        <CircularProgress style={{color: '#27B8D2'}}/>
      </div> : 
    <div className='ListagemModelo-container'>
        <Grid item xs={12}>
          <Paper className={clsx(classes.paper, classes.colorPaper)}>
            <div className={arr[2] ? "ListgemModelo-prontuarios-2" : "ListgemModelo-prontuarios-3" }>
              <ThemeProvider theme={theme}>
                <>
                 {dataInfoGrafico && dataInfoGrafico.map((item,index) => {
                  return(
                    <>
                    {/* {item.local} */}
                    {arr[2] ? 
                    <Grafico title={item.local} value={item.answer}/> 
                    :
                    <GraficoInterpaciente dataInfoGrafico={dataInfoGrafico[0]}/>}
                     
                    </>
                  )
                    })}  
                  </>
              </ThemeProvider>
            </div>
          </Paper>
        </Grid>
        </div>
      }
      </>
  )

}