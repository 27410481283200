import React, {useState, useEffect} from 'react';
import AddIcon from '@material-ui/icons/Add';
import ShortTextIcon from '@material-ui/icons/ShortText';
import IconButton from '@material-ui/core/IconButton';
import '../../../css/NovoModelo.css';
import { StateGlobal } from '../../../../../../ContextAPI/context'
import CardNumerico from '../../../SecaoInput/Components/CardNumerico'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { TiSortNumerically } from 'react-icons/ti';

export default function ButtonsFerramentas({index ,id}) {

    const {    
        montagemstate,
        setMontagem,
        att  ,
        setAtt,
        idComponentAtual,
        viewProntuario
} = StateGlobal();


      
        var array = []


        function getdata(e) {
   
        }
        
        const teste = 'CardTextoCurto'

        //array.push({ component: <CardTextoCurto getData={getdata} testid={testid} tempId={id}/> })

  return (
            <div className="NovoModelo-Ferramentas">
               <div className="circle-icon">
                   <TiSortNumerically className="number-icon"/>
               </div>
                <p className="titulo-ferramenta-btn">Numérico</p>
                {!viewProntuario ? 
               <div onClick={()=>{

                array  = montagemstate

                array.push({ type: "numerico", component: <CardNumerico getData={getdata}  tempId={id}/> , tempId : id , title : ''  , required : false, idsessao:idComponentAtual })               
               
                setMontagem(array)
  
                setAtt(!att)


               }} className="envolve-btn-add-ferramentas">
                   <IconButton className="btn-add-ferramenta">
                       <AddIcon/>
                   </IconButton>
               </div>
               : ''}
            </div>
  );
}