import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  training: {
    '&  p': {
      marginTop: '15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}));