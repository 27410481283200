import React, { useState, useEffect, useLayoutEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'
import { validate } from '@material-ui/pickers';
import { setDayWithOptions } from 'date-fns/fp';

export default function InputTextoCurto({ title, id, required, edit }) {

  const [textoCurto, setTextoCurto] = useState(edit);
  const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario , respView , validaProntuario , setvalidaProntuario , NameProntuario, setNameProntuario, validation, setValidation,
  attValidation, setAttValidation, attEncerraError } = StateGlobal();
  const [req, setReq] = useState(required);
  const [error, setError] = useState(false)


  const handleChangeInput = event => {
    const { value } = event.target;

    Answer.map((item, index) => {
      if (item.question_id === id) {
        setTextoCurto(value)
        Answer[index].answer = value

        if(req){
          if( !Answer[index].answer ){
            setError(true)
          }
          else{
            setError(false)
          }
        }

      }
    })
    const array = MontagemProntuario

    array[PosProntuario].montagem.map((item, index) => {
      if (item.tempId === id) {

        item.answer = value
      }

    })

    setMontagemProntuario(array)

  };

  
  useEffect(() => {
    if(validation) {
      
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
         
          if(comp.id == id){
   
            setError(validation[index].components[i].error)
              
          }
        })
      })
    }
  
  }, [attEncerraError])

  useEffect(()=>{
    if(validation) {
      
      validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
           
            if(comp.id == id){
     
              validation[index].components[i].error = error 
              
                
            }
          })
      })
      setAttValidation(!attValidation)
    }

  }, [error])

  useEffect(() => {
    setTextoCurto(edit)

    
  }, [id])



  return (
    <div>

      <p className="titulo-form">{title}</p>
      {/* {id}{edit}{textoCurto} */}
     {/* {id} */}
      <TextField
        id={id}
        name={id}      
        className="mb-4"
        value={textoCurto}
        onChange={handleChangeInput}
        onBlur = {handleChangeInput}
        type="text"
        // error={validaProntuario[id].error}
        error={error}
        required={req}
        disabled={respView}
        variant="outlined"
        //disabled = {respEdit}
      
        inputProps={{ maxLength: 150 }}
      />

 <div style={{ color: 'red', width: '100%', marginTop: '-15px', fontSize: '15px' }}>
  { error === true ? '* Preencha o campo' : ''}  
 </div>
       
    </div>
  );
}
