import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Alert from  '../../Components/Alert';
import Tabela from './Tabela'
import './css/Agenda.css';
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

import conexao from '../../Services/api'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  },
  container: {
    // margin: "4rem 0 6rem 0",
    // paddingTop: "1.5rem"
    marginTop: '4rem'
  },
  containerClinic: {
    margin: "0rem 0 6rem 0",
    paddingTop: "1.5rem"
  }

}));

export default function SpacingGrid() {
  const classes = useStyles();
  

const date = new Date()
const newDate = moment(date).format('DD/MM/YYYY, h:mm:ss a')


  useEffect(() => {
    // window.alert(newDate)
    localStorage.removeItem('patientID')
    localStorage.removeItem('agendamentoId')
  }, [])
  const [openModal, setOpenModal] = useState(false);

  function closeModal(){
    conexao.post(`/statusModalUpdate/${localStorage.getItem('userIdModalUpdate')}`,
      {
        show_agenda: "false", 
      }
    )
  }

  
  function GetModal(){
    conexao.get(`/getStatusPainel/${localStorage.getItem('userIdModalUpdate')}`).then((res) => {
      console.log(res.data.data.show_agenda)
      setOpenModal(res.data.data.show_agenda == "false" ? false : true)
    })
  }

  useEffect(() => {
    GetModal()
  }, [])

  return (
    <div className={localStorage.getItem('containerClass')}>

    <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='header-tutorial'> 
          <div>
            <span className='header-tutorial-title'>Tutorial - Agenda</span>
          </div>
          <CloseIcon className='header-close-icon' onClick={() => {
            closeModal()
            setOpenModal(false)
          }}/>
        </div>

        <div className='header-body'>
          <iframe
            className='video-container'
            width="560"
            height="315"
            src="https://www.youtube.com/embed/w1CkL7EkTPU?si=eTpsXnP-ANTTOMJV?controls=1"
            frameBorder="0"
            allow="acceleallowFullScreenrometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
     
        </div>
        <div className='desc-tutorial'>
          <span>No Clinic Plus você pode fazer agendamentos únicos ou recorrentes, selecionando a frequência em que o paciente irá realizar seus atendimentos, controlar o status dos agendamentos, adicionar valores para cada procedimento realizado, enviar mensagem de confirmação de consulta para os pacientes e bloquear horários na agenda.</span>
        </div>
        <div className='header-body'>
          <iframe
            className='video-container'
            width="560"
            height="315"
            src="https://www.youtube.com/embed/XMMEOr3uV9g?si=eTpsXnP-ANTTOMJV?controls=1"
            frameBorder="0"
            allow="acceleallowFullScreenrometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
     
        </div>
        <div className='desc-tutorial'>
          <span>Nesse vídeo você vai aprender como iniciar seu atendimento no Clinic Plus e como gerar relatórios compartilháveis de Escala de Dor, Craniometria, Curva de Crescimento e Desenvolvimento Motor, e assim acompanhar a evolução de seu paciente após cada consulta.</span>
        </div>
      </Dialog>
      <Alert/>
      <Tabela/>
  </div>
  );
}