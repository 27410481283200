import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import '../css/inputs.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '',
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: '',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: '',
  },
  {
    value: 6,
    label: '',
  },
  {
    value: 7,
    label: '',
  },
  {
    value: 8,
    label: '',
  },
  {
    value: 9,
    label: '',
  },
    {
    value: 8,
    label: '',
  },
  {
    value: 10,
    label: '10',
  },
];

function valueLabelFormat(value) {
 return value
}


export default function InputEscala() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    
  return (
      <div className="envolve-inputEscala">
        <p className="titulo-form">Escala</p>
        <div className={classes.root}>
          <Slider
            defaultValue={0}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            step={1}
            value={value}
            min={0}
            max={10}
            marks={marks}
            onChange={handleChange}
            valueLabelDisplay="auto"
            
          />
        </div>
      </div>  
  );
}
