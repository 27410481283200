import React, {useState, useEffect} from 'react';
import AddIcon from '@material-ui/icons/Add';
import ShortTextIcon from '@material-ui/icons/ShortText';
import IconButton from '@material-ui/core/IconButton';
import '../../css/NovoModelo.css';
import { StateGlobal } from '../../../../../ContextAPI/context'
import CardTextoCurto from '../../SecaoInput/CardTextoCurto'
import TextoLongo from './components/textolongo'
import TextoCurto from './components/textocurto'
import Telefone from './components/telefone'
import Data from './components/data'
import Selecao from './components/selecao'
import Email from './components/email'
import Numerico from './components/numerico'
import Check from './components/check'
import Escala from './components/escala'
import Imagem from './components/imagem'
import Tabela from './components/tabela'
import EscalaDeDor from './components/escaladedor'
import Label from './components/label'
import Odontologico from './components/odontologico'
import OsteopatiaPediatrica from './components/osteopatiapediatrica'
import DesenvolvimentoMotor from './components/desenvolvimentomotor'
import CurvadeCrescimento from  './components/curvadecrescimento'

export default function ButtonsFerramentas({item ,id}) {

    const {    
        montagemstate,
        setMontagem,
        att  ,
        setAtt,
    idComponentAtual} = StateGlobal();

        var array = []


        function getdata(e) {
      
        }
        
        function listagem(){
            switch(item.component){
                case 'textocurto':
                    return <TextoCurto index={item} id={id}/>
                case 'textolongo':
                    return <TextoLongo index={item} id={id}/>
                case 'telefone':
                    return <Telefone index={item} id={id}/>
                case 'data':
                    return <Data index={item} id={id}/>
                case 'selecao':
                    return <Selecao index={item} id={id}/>
                case 'email':
                    return <Email index={item} id={id}/>
                case 'numerico':
                    return <Numerico index={item} id={id}/>
                case 'check':
                    return <Check index={item} id={id}/>
                // case 'escala':
                //     return <Escala index={item} id={id}/>
                // break;
                case 'image':
                    return <Imagem index={item} id={id}/>
                // case 'tabela':
                //     return <Tabela index={item} id={id}/>
                // break;
                case 'escaladedor':
                    return <EscalaDeDor index={item} id={id}/>
                case 'label':
                    return <Label index={item} id={id}/>
                case 'odontologico':
                    return <Odontologico index={item} id={id}/>
                case 'osteopatiapediatrica':
                    return <OsteopatiaPediatrica index={item} id={id}/>
                case 'desenvolvimentomotor':
                    return <DesenvolvimentoMotor index={item} id={id}/>
                case 'curvadecrescimento':
                    return <CurvadeCrescimento index={item} id={id}/>
            }
        }
        


        //array.push({ component: <CardTextoCurto getData={getdata} testid={testid} tempId={id}/> })

     

  return (
      <>
        {listagem()}
        </>    
  );
}