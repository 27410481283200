import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  envolveProntuario: {
    backgroundColor: '#f9f9f9',
    padding: '1rem',
    marginTop: '6rem',
    borderRadius: '6px',
  },
  titleProntuario: {
    color: '#777777',
    fontSize: '1.5em'
  },
  btnInicarAtendimento: {
    width: '100%',
    marginBottom: '.6rem'
  },
  btnDuracao: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  divSecao: {
    backgroundColor: 'white',
    margin: 0,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  titleSecao: {
    color: '#27b8d2',
    fontSize: '1.1em',
    padding: '.7rem'
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

export default function ProntuarioLateralEsquerda() {
  const classes = useStyles();

  return (
    <div className="">
      <ThemeProvider theme={theme}>
        <div className={classes.envolveProntuario}>
          <div>
            <h1 className={classes.titleProntuario}>Cadastros</h1>
            {/* <Button className={clsx(classes.btnInicarAtendimento,"color-primary")} variant="contained" color='primary' >
              Dados Pessoais
            </Button> */}
          </div>
         
          {/* <div>
            <div className={classes.divSecao} >
              <h2 className={classes.titleSecao}>Dados complementares</h2>

            </div>
          </div> */}
        </div>
      </ThemeProvider>
    </div>
  );
}