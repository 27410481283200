import React, { useState, useEffect } from 'react'
import { Select, MenuItem, TextField, InputLabel, Button, IconButton, Badge } from '@material-ui/core'
import conexao from '../../../Services/api'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

export default function SelectProcedimento ({ value, index, json, excluir, insertQtd, arrayfinal }) {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            marginTop: '1rem',
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: '#F9F9F9',
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-start'

        },

        SelectProcedimento: {
            width: '80%',
            backgroundColor: 'white',
        },
        QuantProce: {
            width: '20%',
            marginLeft: '1rem',
            backgroundColor: 'white',       
        },
    }));

    const classes = useStyles();
    const [proc, setProc] = useState([])
    const [array, setArray] = useState()
    const [valueselect, setValueSelect] = useState('')
    const [quantity, setQtd] = useState()
    const valor = arrayfinal[index].name

    // function listaProcedimentos2() {
    //     console.log('listaProcedimentos2')
    //     var userId = localStorage.getItem('selectedUser');
        
    //     conexao.get(`/schedule/type/list/${userId}`).then((res) => {
    //         console.log(res)
    //         setProc(res.data)
    //     }).catch((error) => {
      
    //     })
    // }

    useEffect(() => {
        // listaProcedimentos2()
        let procedimentos = JSON.parse(localStorage.getItem('doctorScheduleTypes'));
// console.log('PROCEDIMENTOS')
// console.log(procedimentos)
        if (procedimentos) {
            setProc(procedimentos)
            setValueSelect(value.id)
        } else {
            console.log('listaProcedimentos2')
            var userId = localStorage.getItem('selectedUser');
            
            conexao.get(`/schedule/type/list/${userId}`).then((res) => {
                console.log(res)
                setProc(res.data.data)
                setValueSelect(value.id)
                localStorage.setItem('doctorScheduleTypes', JSON.stringify(res.data.data))
            }).catch((error) => {})
        }
        
        if (value.quantity) {
            setQtd(value.quantity)
        }

    }, [value])

    return (
        <div className={classes.formControl} >
            <Select
                name='procedimento'
                id="demo-simple-select-outlined"
                //value={values.procedimento}
                // onClick={() => {(e)=>{setValueSelect(e.target.value)} }}
                //label="Procedimentos"
                value={valueselect}
                className={classes.SelectProcedimento}
            >
                <MenuItem ><em>Selecione um procedimento</em></MenuItem>

                {proc ?
                    proc.map((item) => {
                        return (
                            <MenuItem value={item.id} onClick={() => {
                                json({ name: item.name, value: item.value, index: index, id: item.id  ,quantity : 1})
                                // setArray({ name: item.name, value: item.value, index: index, id: item.id })
                            }}>{item.name}</MenuItem>
                        )
                    })
                    : ''
                }
            </Select>

            <TextField className={classes.QuantProce} type="number" id=""
                label="Quant." name='quant'
                onChange={(e) => {
                    insertQtd({ quantity: parseInt(e.target.value) }, index)
                    setQtd(e.target.value)
                }}
                variant="outlined" required value={quantity ? quantity : 1}
            />

            {index === 0 ? ''
            :
                <IconButton onClick={() => { excluir(value ,index) }} aria-label="show 10 new notifications" color="inherit">
                    <CloseIcon />
                </IconButton>
            }
        </div>
    )
}

