import React, { useEffect, useLayoutEffect, useState , useCallback , useRef } from "react";
import { BarChart, Bar, XAxis, Label, LabelList, YAxis } from "recharts";
import { toPng } from 'html-to-image';
import { StateGlobal } from "../../../../../ContextAPI/context"
import ReguadorDeDor from "../../../../../Assets/Images/ImagensProntuario/reguador_de_dor.png"


export default function Grafico({title,value}){
  const [data, setData] = useState([])
  // const [listImgBase64, setListImgBase64] = useState([])
  const { listaImgBase64, setListaImgBase64 } = StateGlobal()


const teste = [0,1,2,3,4,5,6,7,8,9,10,]

const ref = useRef({HTMLDivElement})

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return 
    }
    let arr = []
    
    toPng(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        // link.download = 'my-image-name.png'
        link.href = dataUrl
        setListaImgBase64(oldstate => [...oldstate,{link: link.href , title: title, value: value}])
        
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref])


function transform () {
      let data = value.map((item,index)=> {
        return(
          {name: item.created_at, value: Number(item.nivel_de_dor)}
        )
      })
      setData(data)
    }

    useEffect(() => {
      transform()
      setTimeout(() => {
        onButtonClick()
      }, [1000]);
      
    }, [])



  return (
    <div className='Grade-Container-Graficos' ref={ref} > 
   <div className="Title-Grafico2"> <p >{title}</p></div> 
     <div className="Grade-width">
       <div className="Container-Informações-Grafico">
        <div style={{marginTop: "10px", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <BarChart 
          barGap={1}
          width={400}
          height={380} 
          data={data} 
          
          margin={{ "top": 0, "right": 0, "bottom": 0, "left": 0}}
          >
            <XAxis dataKey="name"  fontSize="14px" minTickGap={1} />
            <YAxis allowDecimals={false} type="number" ticks={teste} domain={[0, teste]} fontSize="14px"  />
           <Bar dataKey='value' fill="#F50057" maxBarSize={30}>
              
          </Bar> 
          
        </BarChart >
        <img src={ReguadorDeDor} width='400px' height='70x' style={{marginLeft: "65px"}}/>
        </div>
        
     </div>
   </div>
 </div>
  );
}
