

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function CardTextoCurto({ title, id, required, edit, options, answer }) {
  const [OptionValues, setOptionValues] = useState(options)
  const [error, setError] = useState(false)
  const [req, setReq] = useState(required);
  console.log('PROPS:', options)
  const {
    Answer,
    setAnswer,
    respEdit,
    respView,
    validation,
    attValidation,
    setAttValidation,
    attEncerraError
  } = StateGlobal();

  useEffect(() => {

    var arr = []

    if (respEdit) {
      const dados = Answer.find(item => item.question_id === id)

      dados.answer.map((item) => {
        if (item.form_step_question_option_id) {

          arr.push({
            id: item.form_step_question_option_id,
            value: item.text,
            text: ChangeOption(item.form_step_question_option_id)
          })

          UpdateAnswer(arr)
        }
      })
    } else {
      setOptionValues(options)
    }

    var opa = ReturnAnswer()

    const eae = opa && opa.slice()

    if (respEdit) {
      setOptionValues(eae)
    }

  }, [id])

  function handlerChange2(e) {
    const { name, value } = e.target
    console.log('event name:', name)
    console.log('event value:', value)

    OptionValues.map((item) => {
      if (item.text === name) {
        item.value = value
      }
    })
  
    setOptionValues(OptionValues.slice())
    UpdateAnswer(OptionValues.slice())

    let err = []
    
    if(required){
      OptionValues.forEach( element => {
        err.push(element.value)
      });
    }

    let val = err.indexOf('')

    if(val < 0){
      setError(false)
    }else{
      setError(true)
    }
  }

  function ChangeOption(tipo) {
    var data = ''
    options.map((item) => {
      if (item.id === Number(tipo)) {
        data = item
      }
    })

    return data.text
  }


  function UpdateAnswer(arr) {
    Answer.map((item) => {
      if (item.question_id === id) {
        item.answer = arr
      }
    })

    setAnswer(Answer)
    console.log('Answerr', Answer)
  }


  function ReturnAnswer() {
    var teste
    Answer.map((item) => {
      if (item.question_id === id) {
        teste = item.answer
      }
    })

    return teste
  }

  useEffect(() => {
    if(validation) {
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
          if(comp.id == id){
            setError(validation[index].components[i].error)
          }
        })
      })
    }
  }, [attEncerraError])

  useEffect(()=>{
    if(validation) {
      validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
            if(comp.id == id){
              validation[index].components[i].error = error 
            }
          })
      })

      setAttValidation(!attValidation)
    }
  }, [error])

  return (
    <div className="CardTextoCurto-container" >
      <div className="container-card-input">

        <div className="titleEscale">
          <h3 className="h3-title-card-input">Curva De Crescimento</h3>
        </div>

        <div>
          {respEdit ?
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}
            >
              {options.map((item, index) => {
                return (
                  <TextField
                    disabled={respView}
                    label={item.text}
                    style={{ width: '24%', marginTop: '50px' }}
                    name={item.text}
                    className='inputdm'
                    onChange={handlerChange2}
                    value={OptionValues[index]?.value}
                    variant="outlined"
                    // disabled={respEdit}
                  />
                )
              })}
            </div>

            :

            <div 
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}
            >
              {options.map((item, index) => {
                return (
                  <TextField
                    disabled={respView}
                    label={item.text}
                    style={{ width: '24%', marginTop: '25px' }}
                    name={item.text}
                    className='inputdm'
                    onChange={handlerChange2}
                    value={OptionValues[index]?.value}
                    variant="outlined"
                    // disabled={respEdit}
                  />
                )
              })}
            </div>
          }
        </div>

        <div
          style={{ 
            color: 'red',
            width: '100%',
            marginTop: '10px',
            fontSize: '15px'
          }}
        >
          { error === true ? '* Preencha o campo' : ''}  
        </div>
      </div>
    </div>
  );
}