import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CircularLoad from '../Load';
import DialogContentText from '@material-ui/core/DialogContentText';
// import { StateGlobal } from '../../ContextAPI/context';

export default function Modalnotificacao(props) { 
    // const [load, setLoad] = useState(props.load);
    const [openModalNotificacao, setOpenModalNotificacao] = React.useState(props.open);
    const [titulo, setTitulo] = React.useState(props.titulo);
    const [descricao, setDescricao] = React.useState(props.descricao);
    // const {notification , setNotification} = StateGlobal()
    
    useEffect(() => {
        // setLoad(props.load);
        setOpenModalNotificacao(props.open);
        setTitulo(props.titulo);
        setDescricao(props.descricao);
    }, [props])


    function handleExclusao(value){
        props.handleModal(value)
    }

    return(
            <div>
                <Dialog
                    open={openModalNotificacao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {descricao}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>handleExclusao(false)} color="default">
                        Fechar
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
     );
}