import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputData({id , title , required ,edit}) {

    const [value, setValue] = React.useState('');
    const [error, setError] = useState(false)

    const { Answer ,setAnswer ,respEdit , setMontagemProntuario , setNameProntuario, NameProntuario, MontagemProntuario , PosProntuario ,setpos , respView, validation, setValidation,
    attValidation, setAttValidation, attEncerraError} = StateGlobal();

    const handleChange = event => {
        const { value } = event.target;
        Answer.map((item, index) => {
            if (item.question_id === id) {
                setValue(value)
                Answer[index].answer = value

                if(required){
                    if( !Answer[index].answer ){
                      setError(true)
                    }
                    else{
                        setError(false)
                  }
                }
           
            }
        })
        const array  = MontagemProntuario
  
        array[PosProntuario].montagem.map((item , index)=>{
            if(item.tempId === id){
             
                item.answer = value
            }

        })

        setMontagemProntuario(array)
        
        
    };

    useEffect(()=>{
      setValue(edit)
      },[id])

      useEffect(() => {
        if(validation) {
      
          validation.forEach((step, index)=>{
            step.components.forEach((comp, i)=>{        
             
              if(comp.id == id){
       
                setError(validation[index].components[i].error)
                  
              }
            })
          })
        }
    
      }, [attEncerraError])
    
    
      useEffect(()=>{
        if(validation) {
      
          validation.forEach((step, index)=>{
            step.components.forEach((comp, i)=>{        
             
              if(comp.id == id){
       
                validation[index].components[i].error = error 
                
                  
              }
              })
          })
          setAttValidation(!attValidation)
        }
    
      }, [error])


    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setValue(item.answer)
    //         }
    //     })
    // }, [id])

    // useEffect(()=>{

    //     Answer.map((item,index)=>{
    //       if(item.question_id === id){  
    //         if(item.answer ===  ''){                 
    //        setValue(edit)
    //         }else{
    //           setValue(item.answer)

    //         }
    //       }
    //     })      

    //   },[edit])
  return (
        <div>
            <p className="titulo-form">{title}</p>
            <TextField
                id="data"
                value={value}
                required={required}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleChange}
                disabled={respView}
                error={error}
                type="date"
                //disabled = {respEdit}
                InputLabelProps={{
                shrink: true,
                }}
            />
            <div style={{ color: 'red', width: '100%', marginTop: '5px', fontSize: '15px' }}>
              { error === true ? '* Preencha o campo' : ''}  
            </div>
        </div>
        
  );
}
