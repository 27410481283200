import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { data,hora } from './Validate'
import Box from '@material-ui/core/Box';
import ModalDetalheAgendamento from './ModalDetalhesAgendamento';
import { StateGlobal } from '../../../ContextAPI/context'
import '../css/Agenda.css'
import { id } from 'date-fns/locale';
import conexao from '../../../Services/api'
import Alert from '@material-ui/lab/Alert'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function tabPesquisa(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
      },
    pResultado: {
        color: '#8F8F8F'
    },
    envolveResultado: {
        borderBottom: '1px solid #8F8F8F',
        cursor: 'pointer',
    },
    envolveResultados: {
        height: '50vh',
        overflow: 'auto',
        backgroundColor: 'white',
    },
    modalColor:{
        backgroundColor: '#f9f9f9',
    }
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#f9f9f9',
      main: '#f9f9f9',
      dark: '#f9f9f9',
      contrastText: '#27b8d2',
    }
  },
}, ptBR);



export default function ModalResultadoPesquisa(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [modalDetalhesAgendamento, setModalDetalhesAgendamento] = useState(false);
  const [resultadoCan, setresultadoCan] = useState([]);
  const [resultadoAge, setresultadoAge] = useState([]);
  const [resultadoRea, setresultadoRea] = useState([]);
  const [namePaciente, setNamePaciente] = useState('');
  const { agendadoData, setAgendadoData } = StateGlobal()

  useEffect(() => {
    setOpen(props.open);
    setNamePaciente(props.namePac);
    if(props.resultadosPesquisasAgen ){
      setresultadoAge(Object.values(props.resultadosPesquisasAgen.data));
    }
    if(props.resultadosPesquisasCan) {
      setresultadoCan(Object.values(props.resultadosPesquisasCan.data));
    }
    if(props.resultadosPesquisasRea) {
      setresultadoRea(Object.values(props.resultadosPesquisasRea.data));
    }
  }, [props])

  const closeModal = () => {
    // setOpen(false);
    setresultadoCan([]);
    setresultadoAge([]);
    setresultadoRea([]);
    props.onChange(false)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openModalDetalhesAgendamento = (item) => {
    setModalDetalhesAgendamento(true)

    listaDetalhes(item.id)

  }

  function listaDetalhes(id){
          
    conexao.get(`/schedule/${id}`).then((resp) =>{
          
    setAgendadoData(resp.data.data)

    }).catch((error)=>{

    })
  }

  const alteraEstadoModal = (modalDetalhesAgendamento) => {
    setModalDetalhesAgendamento(modalDetalhesAgendamento); 
  };

  return (
    <div className="Modal-agendamento">
    <ThemeProvider theme={theme}>
      <Dialog  id="modalResultado" maxWidth='lg' open={open}  aria-labelledby="Modal-title-agendamento">
        <div className={classes.modalColor}>
            <DialogTitle id="Modal-title-agendamento">
            <div className="title-modal-close">
                Resultado da pesquisa: {namePaciente}
                <CloseIcon onClick={closeModal}/>
            </div>
            </DialogTitle>
            <DialogContent>
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="tab-pesquisa">
                    <Tab label="Agendadas" {...tabPesquisa(0)} />
                    <Tab label="Realizadas" {...tabPesquisa(1)} />
                    <Tab label="Canceladas" {...tabPesquisa(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <div className={classes.envolveResultados}>
                        {resultadoAge.length !== 0 ?
                          resultadoAge.map((item) => (
                          <div onClick={()=>openModalDetalhesAgendamento(item)} className={classes.envolveResultado}>
                              <p className={classes.pResultado}>{data(item.started_at)} - {hora(item.started_at)} - {item.patient.name} </p>
                          </div>
                          ))
                        :
                        <Alert variant="filled" severity="error">
                          Esse paciente não possui consultas agendadas
                        </Alert>
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className={classes.envolveResultados}>
                    {resultadoRea.length !== 0?
                        resultadoRea.map((item) => (
                        <div onClick={()=>openModalDetalhesAgendamento(item)} className={classes.envolveResultado}>
                            <p className={classes.pResultado}>{data(item.started_at)} - {hora(item.started_at)} - {item.patient.name} </p>
                        </div>
                        ))
                        :
                        <Alert variant="filled" severity="error">
                          Esse paciente não possui consultas agendadas
                        </Alert>
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div className={classes.envolveResultados}>
                    {resultadoCan.length !== 0 ?
                        resultadoCan.map((item) => (
                        <div onClick={()=>openModalDetalhesAgendamento(item)} className={classes.envolveResultado}>
                            <p className={classes.pResultado}>{data(item.started_at)} - {hora(item.started_at)} - {item.patient.name} </p>
                        </div>
                        ))
                        :
                        <Alert variant="filled" severity="error">
                          Esse paciente não possui consultas agendadas
                        </Alert>
                        }
                    </div>
                </TabPanel>
            </div>
            </DialogContent>
        </div>
      </Dialog>
      <ModalDetalheAgendamento open={modalDetalhesAgendamento} onChange={alteraEstadoModal}/>
      </ThemeProvider>
    </div>
  );
}