import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { TextField, Button, Grid } from '@material-ui/core';
import TextFildClinic from './itens/TextFild'
import { currency } from '../../Pages/Agenda/Modais/Validate';

const components = [
  {id : 1 ,name: 'text1', component: <TextFildClinic   title='text1' /> },
  {id : 2 , name: 'text2', component: <TextFildClinic title='text2' /> },
  {id : 3 , name: 'text3', component: <TextFildClinic title='text3' /> },
  {id : 4 , name: 'text4', component: <TextFildClinic title='text4' /> },
  { id :5 ,name: 'text5', component: <TextFildClinic title='text5' /> },
  {id : 6 , name: 'text6', component: <TextFildClinic title='text6' /> },
  {id : 7 , name: 'text7', component: <TextFildClinic title='text7' /> },

]






const arraypronto = [

]

const arraymontagem = [

]


const arraycomponents = [

  {
    id: '1',
    type: 'card1',
    name: <div><input type='text' placeholder='digite aqui' label='Nome' name='txtfild'/></div>
  },
  {
    id: '2',
    name: <div><input type='file' placeholder='digite aqui' label='Nome' name='txtfild'/></div>,
    type: 'card2',
  },
  {
    id: '3',
    name:<div><textarea type='text' placeholder='digite aqui' label='Nome' name='txtfild'/></div>,
    type: 'card3',
  },
  {
    id: '4',
    name: <div><table border="1">
    <tr>
        <td>Nome</td>
        <td>Idade</td>
        <td>Profissão</td>
    </tr>
    <tr>
        <td>Ted</td>
        <td>22</td>
        <td>Estudante</td>
    </tr>
    <tr>
        <td>Ralf</td>
        <td>26</td>
        <td>Designer</td>
    </tr>
</table></div>,
    type: 'card4',
  },
  {
    id: '5',
    name: <div><button>teste</button></div>,
    type: 'card5',
  }

]




export default function Drag() {


  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      border:'1px solid red'
 
    },
    formulario: {
      display:'flex',
      flexDirection:'column',
      border:'1px solid blue',
      width:'100%'
 
    }

  }));


  const classes = useStyles();


  const [characters, updateCharacters] = useState(arraypronto);
  const [montagemstate, setMontagemState] = useState(arraymontagem);
  const [componentstate, setComponentState] = useState(arraycomponents);
  const [step  , setStep] = useState([])
  const [opensave, setOpensave] = useState(false);



  function formpronto(result) {//comtrole array 1
    if (!result.destination) return;

    const items = Array.from(characters); // importante
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }


  function montagem(result) {//controle array 2
    if (!result.destination) return;

    const items = Array.from(montagemstate); // importante
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setMontagemState(items);
  }



  function components(result) {//controle array 3
    if (!result.destination) return;

    const items = Array.from(componentstate); // importante
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setComponentState(items);
  }

  function editar(index){



    
    const array = [...characters[index]]


    // array.map((item)=>{
    //   item.id_formulario = id
    // })


    
    setMontagemState(array)


  }

  return (
    <div className={classes.root}>
      <div>
        
      </div>
      <div className={classes.formulario}>

        <h1>formularios pronto</h1>
        <DragDropContext onDragEnd={characters}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {characters.map(({ id, name, type }, index) => {
                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}  >
                          <div style={{display:'flex' , flexDirection:'row' ,background:'green' , border:'1px solid red' , margin:'30px'}}>
                            <div >
                            fomulario {index}
                            <button 
                            onClick={()=>{editar(index)
                              
                            }}>editar</button>
                          </div>
                      
                            
                          </div>
                       
                          
                          
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={classes.formulario}>

<h1>montagem</h1> 

{opensave ?


<button onClick={()=>{

  const  id = montagemstate[0].id_formulario

  characters.map((item,index)=>{
   if( item[0].id_formulario === id){
characters[index] = montagemstate
     
  }
  })




}}>Editar formulario</button>



:


<button onClick={()=>{//CRIAR FORMULARIO
  const array =[...characters]

  
  const id = Math.floor(Math.random() * 256);


  montagemstate.map((item)=>{
    item.id_formulario = id
  })
  array.push(montagemstate)
 
  updateCharacters(array)
  setMontagemState([])



}}>gravar formulario</button>

}



<DragDropContext onDragEnd={montagem}>
  <Droppable droppableId="characters">
    {(provided) => (
      <ul {...provided.droppableProps} ref={provided.innerRef}>
        {montagemstate && montagemstate.map(({ id, name, type ,idteste}, index) => {
          return (
            <Draggable key={idteste} draggableId={idteste} index={index}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}  >
                  <div style={{border:'1px solid red' , margin:'30px'}}>
                  {name}
                  {montagemstate[0].id_formulario ? setOpensave(true): setOpensave(false)  }
                
                    
                  </div>
               
                  
                  
                </div>
                
              )}
            </Draggable>
          
          );
        })}
       
        

        {provided.placeholder}

        
      </ul>
    )}
  </Droppable>
</DragDropContext>
</div>
<div className={classes.formulario}>

<h1>componentes</h1>
<button onClick={()=>{
  setOpensave(false)
  setMontagemState([])
}}> Novo</button>
<DragDropContext onDragEnd={components}>
  <Droppable droppableId="characters">
    {(provided) => (
      <ul {...provided.droppableProps} ref={provided.innerRef}>
        {componentstate && componentstate.map(({ id, name, type }, index) => {
          return (
            <Draggable key={id} draggableId={id} index={index}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}  >
                  <div style={{border:'1px solid red' , margin:'30px' , backgroundColor:'#FF4777'}}>
                    <div>
                  {name}
                  </div>
                  <div>
                  <button onClick={()=>{

                    var idteste = Math.floor(Math.random() * 256);


                    const array = [...montagemstate]
                    array.push({ 
                      id: id,
                      idteste: idteste.toString(),
                      name: name,
                      type: type,
                    })
                    setMontagemState(array)
                    
                  }
                    

                  }>add component</button>
                    </div>
                    
                  </div>
               
                  
                  
                </div>
              )}
            </Draggable>
          );
        })}
        {provided.placeholder}
      </ul>
    )}
  </Droppable>
</DragDropContext>
</div>
      
    </div>
  );
}

