import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import conexao from '../../../../Services/api';
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
import { useHistory } from 'react-router-dom';
import '../css/Listagem.css';
import { StateGlobal } from '../../../../ContextAPI/context'

const useStyles = makeStyles((theme) => ({
    form: {
       width:'100%',
        border: 'none',
        marginBottom: '2rem'
    },
    search: {
        background: '#f1f1f1',
        boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
        borderRadius: '3px',
        height:'45px',
        fontSize:'15px',
        border: 'none',
        padding: '15px',
        minWidth: '350px',
        
     },
     iconSearch:{
         color: '#8F8F8F'
     }
  }));


const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosCompartilhados() {
    const classes = useStyles();
    const History = useHistory();
    const [modelosCompartilhados, setModelosCompartilhados] = useState('');
    const [alertType, setAlertType] = useState();
    const [message, setMessage] = useState();
    const [load, setLoad] = useState(false);
    const [nameModelo, setNameModelo] = useState('');
    const[modelosCompartilhadosbase ,setModelosCompartilhadosbase]= useState();
    const {
      idEditProntuario,
      setIdEditProntuario,
      setIdViewProntuario,
      viewProntuario, 
      setViewProntuario 
    } = StateGlobal();
    
  async function listaModeloCompartilhado() {
      
      await conexao.post(`/form/share/list`,{
        'search': nameModelo,
        'user_id': !isNaN(localStorage.getItem('selectedUser')) ? localStorage.getItem('selectedUser') : localStorage.getItem('userId')
      })
      .then((response) => {
        setLoad(false)
        setModelosCompartilhados(response.data.data);
        setModelosCompartilhadosbase(response.data.data)
        if(response.data.data.length<1){
          setModelosCompartilhados('')
          setAlertType('error');
          setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
          setTimeout(() => {
            setAlertType('');
            setMessage('');
          }, 3000);
        }
      }).catch((error) => {
          setLoad(false)
          setModelosCompartilhados('')
          setAlertType('error');
          setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
          setTimeout(() => {
            setAlertType('');
            setMessage('');
          }, 3000);
      })
  }

  useEffect(() => {
    setLoad(true)
    listaModeloCompartilhado();
  }, [])

  useEffect(() => {
    if(nameModelo===''){
      setModelosCompartilhados([])
      setModelosCompartilhadosbase([])
      listaModeloCompartilhado()
    }

  },[nameModelo]);


  function busca(event) {
    if (event.key === 'Enter') {
      setModelosCompartilhados([])
      setModelosCompartilhadosbase([])
      listaModeloCompartilhado()
    } if (event._reactName === 'onClick') {
      setModelosCompartilhados([])
      setModelosCompartilhadosbase([])
      listaModeloCompartilhado()
    }
    return true
  }

  function visualizarModelo(e) {
    conexao.get('/form/' + e).then((res) => {

      setIdEditProntuario({ id: e, data: res.data.data })
      setViewProntuario({ id: e, data: res.data.data })
      History.push('/novo-modelo')

    }).catch((error) => {

    })
  }

  async function baixarModelo(id) {
    console.log('baixarModelo')
    setLoad(true)

    const data = {
      'user_id': localStorage.getItem('selectedUser'),
      'form_id': id
    }

    await conexao.post(`/form/clone`, data)
    .then((response) => {
      setLoad(false)
      alert('Parabéns! O modelo baixado aparecerá em MEUS MODELOS.', 'success');
      
      setTimeout(() => {
          alert('', '');
      }, 3500);

    }).catch((error) => {
      setAlertType('error');
      setMessage('Ops! Algo errado aconteceu quando tentamos baixar o modelo.');
    })
  }

  function alert(message, type)
  {
    setMessage(message)
    setAlertType(type)
  }

  function fixData(data) {
    if (!data) return '';

    var fixDt = data.split('T');
    fixDt = fixDt[0].split('-');
    return fixDt[2] + '/' + fixDt[1] + '/' + fixDt[0];
  }


  return (
    <div className="">
            <FormControl className={clsx(classes.form)}>
            <InputBase
                id="outlined-adornment-weight"              
                endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch}  onClick={(event) => busca(event)} /></IconButton>}</InputAdornment>}
                //labelWidth={0}
                disabled={load ? true : false}
                onKeyDown={busca}
                onChange={(e) => {setNameModelo(e.target.value)}}
                placeholder="Busque por um modelo"
                className={clsx(classes.search)}
            />
            </FormControl>
            <div>
              {load?
                <div className="div-load-lista-modelos"><CircularLoad/></div>
              :''}
              { message ?
                <div className="Modal-agendamento-alert">
                  <Alert variant="filled" severity={alertType}>
                    {message}
                  </Alert>
                </div>
              :''}

              {modelosCompartilhados ?
                modelosCompartilhados.map((modeloCompartilhado) => (
                  <div className="CardModelos-envolve">
                      <div className="CardModelos-envolve-text-icon">
                          <AssignmentIcon className="CardModelos-icon-pront"/>
                          <p>{modeloCompartilhado.title} - {modeloCompartilhado.user.name} - {fixData(modeloCompartilhado.created_at)}</p>
                      </div>
                      <div>
                          <IconButton>
                              <GetAppIcon onClick={() => {baixarModelo(modeloCompartilhado.id)}}/>
                          </IconButton>
                          <IconButton>
                              <VisibilityIcon onClick={() => {visualizarModelo(modeloCompartilhado.id)}}/>
                          </IconButton>
                      </div>
                  </div>
                )): ''}                  
            </div>
    </div>
  );
}