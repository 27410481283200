import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'
import { useEffect } from 'react';

export default function VisaP({ path }) {
  const classes =  useStyles();
   
  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  useEffect(() => {
    getVisaPScore()
  }, [])

  function getVisaPScore() {
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.visaP).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return score ? (score).toFixed(2) : 0;
  }

  return (
    <div className={classes.visaP}>
      <article>
        <p className='titulo-form'>1.Por quantos minutos você consegue ficar sentado sem dor?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.sittingWithoutPain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                sittingWithoutPain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0 min</option>
          <option value="1">10 min</option>
          <option value="2">20 min</option>
          <option value="3">30 min</option>
          <option value="4">40 min</option>
          <option value="5">50 min</option>
          <option value="6">60 min</option>
          <option value="7">70 min</option>
          <option value="8">80 min</option>
          <option value="9">90 min</option>
          <option value="10">100 min</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2.Você sente dor ao descer escadas em um ritmo normal?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.painWhenDescending}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                painWhenDescending: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0 - Dor forte ou severa</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10 - Sem dor</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3.Você sente dor no joelho quando o estende totalmente de forma ativa e com apoio de peso?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.extendedKneePain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                extendedKneePain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0 - Dor forte ou severa</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10 - Sem dor</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4.Você sente dor quando faz o exercício afundo com apoio total do peso?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.painExerciseLunge}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                painExerciseLunge: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0 - Dor forte ou severa</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10 - Sem dor</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5.Você tem problemas ao agachar?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.squattingProblems}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                squattingProblems: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0 - Incapaz</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10 - Sem problemas</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6.Você sente dor durante ou imediatamente após saltitar 10 vezes em uma perna só?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.painAfterJumping10Times}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                painAfterJumping10Times: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">0 - Dor forte ou severa</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10 - Sem dor</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7.Atualmente, você está praticando algum esporte ou outro tipo de atividade física?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.playingSports}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                playingSports: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">Não</option>
          <option value="4">Treinamento e/ou competição com restrição</option>
          <option value="7">Treinamento sem restrição mas não competindo no mesmo nível anterior ao início dos sintomas</option>
          <option value="10">Competindo no mesmo nível oi nível mais alto do que quando os sintomas começaram</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8.Por favor, complete somente uma das questões A, B ou C, conforme a explicação abaixo:</p>
      </article>

      <article style={{ marginTop: '20px' }}>
        <p className='titulo-form'>8A. Se você não sente dor ao praticar esporte, por quanto tempo você consegue treinar/praticar.</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.noPainplayingSports}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                noPainplayingSports: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">Não consigo treinar/praticar</option>
          <option value="7">0 - 5 min</option>
          <option value="14">6 - 10 min</option>
          <option value="21">11 - 15 min</option>
          <option value="30">Mais de 15 min</option>  
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8B. Se você sente dor ao praticar esporte, mas essa dor não o impede de completar/praticar a atividade esportiva, por quanto tempo você consegue treinar/praticar.</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.painPlayingSports}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                painPlayingSports: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">Não consigo treinar/praticar</option>
          <option value="4">0 - 5 min</option>
          <option value="10">6 - 10 min</option>
          <option value="14">11 - 15 min</option>
          <option value="20">Mais de 15 min</option>  
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8C. Se você sente dor que o impede de completar seu treinamento/prática esportiva, por quanto tempo você consegue treinar/praticar?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.visaP.painPreventsPlayingSports}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              visaP: {
                ...state.visaP,
                painPreventsPlayingSports: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="0">Não consigo treinar/praticar</option>
          <option value="2">0 - 5 min</option>
          <option value="5">6 - 10 min</option>
          <option value="7">11 - 15 min</option>
          <option value="10">Mais de 15 min</option>  
        </TextField>

        <p className={classes.score}>Escore: {getVisaPScore()} {'->'} ({'< 80'} RUIM / {'> 80'} BOM )</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}