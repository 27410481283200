import React, {useState, useContext} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import ProntuarioLateralEsquerda from './ProntuariosLateralEsquerda';
import Resumo from './Resumo';
import SelecionarProntuario from './SelecaoProntuario';
import { StateGlobal } from '../../ContextAPI/context'
import Prontuario from './Prontuario';
import FisioterapiaEsportiva from '../FisioterapiaEsportiva';
import { SportsPhysiotherapyContext } from '../../ContextAPI/SportsPhysiotherapyContext' 


const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh !important",
    overflow: "auto"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  colorPaperWhite: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 0px 0px gray",
    padding: '2rem'
  },
  container: {
    margin: "4rem 0 6rem 0",
    paddingTop: "1.5rem"
  },
  containerClinic: {
    margin: "0rem 0 6rem 0",
    paddingTop: "1.5rem"
  }

}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);

export default function ResumoPaciente(props) {
  const classes = useStyles();

  const { pos, setPos, Location, setLocation} = StateGlobal();
  const { loadSportsPhysiotherapyComponent } = useContext(SportsPhysiotherapyContext)
  const [ selectedForm, setSelectedForm] = useState('');

  const telas = [<Resumo patientID={props.id} selectForm={selectForm}/>,<SelecionarProntuario patientID={props.id} selectForm={selectForm}/>,<Prontuario patientID={props.id} formID={selectedForm}/>]

  function selectForm(id) {
    console.log('!!! FORM SELECTED !!!')
    console.log(id)
    setSelectedForm(id)
  }

  return (
    <>
      <div className={localStorage.getItem('containerClass')}>
        {loadSportsPhysiotherapyComponent && <FisioterapiaEsportiva  patientID={props.id} formID={selectedForm}/>}
        
        {!loadSportsPhysiotherapyComponent && (
          <div className="d-flex">

            <Grid item xs={3}>
              <Paper className={clsx(classes.paper, classes.colorPaperWhite)}>
                  <ProntuarioLateralEsquerda patientID={props.id} formID={selectedForm}/>
              </Paper>
            </Grid>
            <Grid item xs={9}>
              <Paper className={clsx(classes.paper, classes.colorPaper)}>

                  {/* <Resumo/> */}
                  {/* <SelecionarProntuario/> */}             
                  
                  {telas[pos]}
              </Paper>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}
