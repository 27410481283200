import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import { TextField, MenuItem, Select, InputLabel, FormControl, ListItemText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CircularLoad from '../../../Components/Load';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import conexao from '../../../../Services/api'
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import './categoria.css'
import { currency, currencyinput, data as datreplace } from '../../../../Components/ReduxTeste/Validate'
// import ModalExcluir from '../../../Components/Modais/ModalExclusao';
import Modal from '../Modal'
import { campovazio, teste2  } from '../../../../Components/ReduxTeste/ValidateForm'
import CircularLoad from '../../../../Components/Load';
import { sortComplete } from '@syncfusion/ej2-react-grids';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  input: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  pConfig: {
    color: '#8F8F8F',
    minWidth: '30%',
    maxWidth: '30%',
    textAlign: 'left',
    wordWrap: 'break-word'
  },
  envolveConfig: {
    borderBottom: '1px solid #8F8F8F',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  envolveConfigs: {
    height: '35vh',
    overflow: 'auto',
    backgroundColor: 'white',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    marginTop: '1rem',
    marginBottom: '1.5rem'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  selectInput: {
    width: '100%',
    backgroundColor: 'white'
  },
  label: {
    background: 'white',
    paddingRight: '.4rem'
  },
  
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },


  },
}, ptBR);


export default function ModalLancamento(props) {
  const classes = useStyles();
  const History = useHistory();

  const initialvalues = {
    categoria: '',
    valor: '',
  }

  let initData = ''

  if(props.edit.data.finance_category){
    initData = props.edit.data.finance_category.name
  }
  
  const initialState = {
    description: '',
    due_date: '',
    value: '',
    payment_type: '',
    payment_type_id: '',
    status: '',
    user_id: (localStorage.getItem('selectedUser') !== 'null' && localStorage.getItem('selectedUser') !== '') ? localStorage.getItem('selectedUser') : localStorage.getItem('userId'),
    finance_category_id: '',
  }

  const [open, setOpen] = useState(props.openrecebimento)
  const [values, setValues] = useState(initialvalues)
  const [valor, setValor] = useState()
  const [categoria, setCategoria] = useState();
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [itens, setItens] = useState();
  const [financeType, setFinanceType] = useState();
  const [categoryList, setCategoryList] = useState();
  const [selectedCategoryList, setSelectedCategoryList] = useState();
  const [paymentTypeList, setPaymentTypeList] = useState();
  const [procId, setProcId] = useState();
  const [editar, setEditar] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [load, setLoad] = useState(false)
  const [idEdit, setIdedit] = React.useState('');
  const [idExcluir, setIdexcluir] = React.useState();
  const [openmodalexcluir, setOpenmodalexcluir] = useState(false);
  const [openalert, setOpenalert] = useState({ open: false })
  const [categoryId, setCategoryId] = useState(0)
  const [paymentTypeId, setPaymentTypeId] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)
  const [data, setData] = useState(initialState)

  const validatevalues= [
    { name: 'due_date', funcao: campovazio, error: false , value:'' },
    { name: 'finance_category_id', funcao: campovazio, error: false , value:''},
    { name: 'description', funcao: campovazio, error: false , value:''},
    { name: 'value', funcao: campovazio, error: false , value:''},
    { name: 'payment_type', funcao: campovazio, error: false , value:''},
    { name: 'status', funcao: campovazio, error: false , value:''}]
  
  
  const [validatesx, setvalidatesx] = useState([
    { name: 'due_date', funcao: campovazio, error: false , value:'' },
    { name: 'finance_category_id', funcao: campovazio, error: false , value:''},
    { name: 'description', funcao: campovazio, error: false , value:''},
    { name: 'value', funcao: campovazio, error: false , value:''},
    { name: 'payment_type', funcao: campovazio, error: false , value:''},
    { name: 'status', funcao: campovazio, error: false , value:''}]);

  // Quando instanciado pela primeira vez
  useEffect(() => {
    // getFinanceCategories();
    getPaymentTypes()
  }, [])
    
  // Quando abre para editar
  useEffect(() => {
    if (props.item)
    {
      conexao.get('/finance/get/' + props.item).then((res) => {
        console.log(res)
        var result = res.data.data[0];
        setData(res.data.data[0])
      }).catch((error) => {
  
        console.log(error)
      })
    } else {
      setData(initialState)
    }

    setOpen(props.openrecebimento)
  }, [props.item, props.openrecebimento])

  // Quando carrega o tipo de pagamento
  useEffect(() => {
    getFinanceCategories();
  }, [data.payment_type])

  useEffect(() => {
    if( data.status == 'PENDENTE' && data.value)
      setIsDisabled(false)
    else if( data.status == 'PAGO' && data.payment_type_id && data.value)
      setIsDisabled(false)
    else
      setIsDisabled(true)
  }, [data])

  function onchangeform(e) {
    const { name, value } = e.target
    validatesx.map((item) => {
      if (item.name === name) {
        item.error = item.funcao(value)
        item.value = value

      }
    })
    const arr = validatesx.slice()
    setvalidatesx(arr)
  }

  function datamask(e) {
    var arr = e.split("")
    return arr[0]
  }

  function onchange(e) {
    const { name, value } = e.target;
    if (name === 'value') {
      var replace = value.replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1.$2");
      setData({ ...data, [name]: replace });
    }
    else {
      setData({ ...data, [name]: value });
    }
  }

  const closeModal = () => {
    setData(initialState)
    setOpen(false)
    props.close()
  }

  function close() {
    setData(initialState)
    setOpenmodalexcluir(false)
  }

  function changeFinanceType (e)
  {
    const { name, value } = e.target
    setFinanceType(value);
    updateSelectedCategoryList(value);
  }

  function updateSelectedCategoryList(value)
  {
    var type = (value == 'PAGAMENTO') ? 'D' : 'C';
    var arr = [];

    arr = categoryList.filter((item)=>{
      console.log(item)
      return item.type == type;
    })

    console.log('updateSelectedCategoryList')
    console.log(value)
    console.log(arr)
    setSelectedCategoryList(arr);
  }

  function getPaymentTypes()
  {
    conexao.get('finance/paymenttype').then((res) => {
      setPaymentTypeList(res.data.data)
    }).catch((error) => {
    })
  }

  function getFinanceCategories() {
    console.log('getFinanceCategories')
    console.log(data)
    let userId = data.user_id

    conexao.get(`/finance/category/get/${userId}`)
      .then((res) => {
        setCategoryList(res.data.data)
        setIsDisabled(false)

        // if (props.item)
        updateSelectedCategoryList(data.payment_type);
      })
      .catch(() => {})
  }

  async function save() {
    var oldnamefinace = data.finance_category_id

    data.due_date = data.due_date.substring(0, 10)

    let dados = {...data}

    if (isNaN(data.value))
    {
      var  valor =  data.value.replace(/\D/g, "")
      if(valor.length > 2){
        valor = valor/100 ; 
      }

      dados = {...dados, value: valor} 
    }

    if (dados.payment_type_id == '')
      dados = {...dados, payment_type_id: 0} 
      

    if(props.item){
      setLoad(true)

      conexao.put('/finance/update/'+props.item, dados).then((res)=>{
        setLoad(false)
        Alertshow('Editado com sucesso !', 'success')
        data.finance_category_id = oldnamefinace
        closeModal()

      }).catch((error)=>{
        setLoad(false)
        data.finance_category_id = oldnamefinace
        setData({...data})

        Alertshow('Campos inválidos !', 'error')
      
      })

    } else {
      setLoad(true)
      validatesx.map((item)=>{
        if(item.value === ''){
          item.error = true
        }

        const arr = validatesx.slice()
        setvalidatesx(arr)
      })

      conexao.post('finance/create', dados).then((res) => {
        setLoad(false)
        data.finance_category_id = oldnamefinace
        Alertshow('Salvo com sucesso !', 'success')
        setData(initialState)
        setvalidatesx(validatevalues)
        closeModal()
      }).catch((error) => {
        setLoad(false)
        data.finance_category_id = oldnamefinace
        setData({...data})
        Alertshow('Campos inválidos !', 'error')
      })
    }

    // setOpen(false)
    // props.close()
  }

  function Alertshow(msg, type) {
    setOpenalert({ open: true, msg: msg, type: type })

    setTimeout(() => {
      setOpenalert({ open: false })
    }, 5000);
  }

  function modaldeletaProcedimentos(id) {
    setOpenmodalexcluir(true)
    setIdexcluir(id)
  }

  function deletaProcedimento() {
    conexao.delete('/finance/category/' + idExcluir).then((res) => {
      getFinanceCategories()

    }).catch((error) => {
    })

  }

  function selecionaProcedimento(id) {
    setEditar(true);
    itens.map((item) => {
      if (item.id === id) {
        setValor(item.type)
        setCategoria(item.name)
      }
      setIdedit(id)
    })
  }

  return (
    <div className="Modal-agendamento">
      <ThemeProvider theme={theme}>
        <Dialog open={open} aria-labelledby="Modal-title-agendamento">
          <form id="" className={classes.modalColor} autoComplete="off">
            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
                {!props.edit.open? 'Novo lançamento' : 'Editar lançamento'}
                
                  <CloseIcon onClick={closeModal} />
              </div>
           
            </DialogTitle>
            <DialogContent>
              {openalert.open ?
                <div className="Modal-agendamento-alert">
                  <Alert variant="filled" severity={openalert.type}>
                    {openalert.msg}
                  </Alert>
                </div>
                : ''}
              <div>
                <TextField
                  id="date"
                  label="Vencimento"
                  name='due_date'
                  type="date"
                  variant='outlined'
                  className={classes.input}
                  maxWidth
                  onChange={(e) => {
                    onchange(e) 
                    onchangeform(e)
                  }}
                  error={validatesx[0].error}
                  helperText={validatesx[0].error ? validatesx[0].error: ''}
                  value={data.due_date.substring(0, 10)}
                 

                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.label} id="demo-simple-select-outlined-label">Tipo</InputLabel>
                    <Select
                      className={classes.selectInput}
                      value={data.payment_type}
                      name='payment_type'
                      onChange={(e) => { 
                        onchange(e)
                      }}
                      error={validatesx[5].error}
                      helperText={validatesx[5].error ? validatesx[5].error: ''}
                      label="Tipo"
                      variant="outlined"
                    >
                      <MenuItem key={'0'} value={''}>...</MenuItem>
                      <MenuItem key={'C'} value={'RECEBIMENTO'}>RECEBIMENTO</MenuItem>
                      <MenuItem key={'D'} value={'PAGAMENTO'}>PAGAMENTO</MenuItem>
                  </Select>
                </FormControl>
                {selectedCategoryList ? 
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.label} id="demo-simple-select-outlined-label">Categoria</InputLabel>
                    <Select
                      className={classes.selectInput}
                      value={data.finance_category_id}
                      name='finance_category_id'
                      onChange={(e) => { onchange(e) 
                        onchangeform(e)}}
                      label="Categoria"
                      variant="outlined"
                      error={validatesx[1].error}
                      helperText={validatesx[1].error ? validatesx[1].error: ''}
                    >
                      {selectedCategoryList.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id} onClick={() => { setCategoryId(item.id) }}>{item.name} </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                : ''}

                <TextField
                  InputLabelProps={categoria ? { shrink: true } : ''}
                  className={classes.input}
                  value={data.description}
                  onChange={(e) => { onchange(e)
                  onchangeform(e) }}
                  type="text"
                  name='description'
                  error={validatesx[2].error}
                  helperText={validatesx[2].error ? 'valor precisa ser preenchido': ''}
                  required
                  id=""
                  label="Descrição"
                  variant="outlined"
                />

                <TextField
                  InputLabelProps={categoria ? { shrink: true } : ''}
                  className={classes.input}
                  value={'R$ ' + data.value}
                  onChange={(e) => { onchange(e)
                  onchangeform(e) }}
                  type="text"
                  required
                  error={validatesx[3].error}
                  helperText={validatesx[3].error ? 'valor precisa ser preenchido': ''}
                  name='value'
                  label="Valor"
                  variant="outlined"
                />
           
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel className={classes.label} id="demo-simple-select-outlined-label">Status</InputLabel>
                  <Select
                    className={classes.selectInput}
                    value={data.status}
                    name='status'
                    onChange={(e) => { onchange(e)
                    onchangeform(e) }}
                    error={validatesx[5].error}
                    helperText={validatesx[5].error ? validatesx[5].error: ''}
                    label="Status"
                    variant="outlined"

                  >
                    <MenuItem key={1} value={'PAGO'}>PAGO</MenuItem>
                    <MenuItem key={2} value={'PENDENTE'}>PENDENTE</MenuItem>
                  </Select>
                </FormControl>

                {data.status == 'PAGO' && <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.label} id="demo-simple-select-outlined-label">Tipo de pagamento</InputLabel>
                    <Select
                      className={classes.selectInput}
                      value={data.payment_type_id}
                      name='payment_type_id'
                      onChange={(e) => { onchange(e)
                        onchangeform(e) }
                      }
                      error={validatesx[5].error}
                      helperText={validatesx[5].error ? validatesx[5].error: ''}
                      label="Tipo de pagamento"
                      variant="outlined"
                    >
                      <MenuItem key={'0'} value={''}>...</MenuItem>
                      {paymentTypeList ? paymentTypeList.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id} onClick={() => { setPaymentTypeId(item.id) }}>{item.name} </MenuItem>
                        )
                      }) : ''}
                  </Select>
                </FormControl>}

              </div>
              <DialogActions className='dialog-content'>
                <Button onClick={closeModal} type="button">
                  Cancelar
                </Button>
                {props.item ?
                  <Button onClick={save} variant="contained" color="primary" className="color-primary" disabled={isDisabled}>
                    {!load ? 'Salvar' : <CircularLoad/>}
                  </Button>
                  :
                  !load?
                  <Button 
                    onClick={save}
                    variant="contained"
                    color="primary"
                    className="color-primary"
                    disabled={isDisabled}
                  >
                    {props.edit.open ? 'Salvar' : 'incluir'}
                  </Button>
                  :
                  <Button variant="contained" color="primary" className="color-primary">
                   <CircularLoad/>
                  </Button>
                }

              </DialogActions>
           
            </DialogContent>
          </form>
        </Dialog>
        <Modal open={openmodalexcluir} close={close} titulo={'Excluir categoria'} desc={'Tem certeza que deseja excluir essa categoria ?'} delete={deletaProcedimento} />

      </ThemeProvider>
    </div>
  );
}