import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import CardMeusModelos from './SCardMeusModelos';
import CardModelosProntos from './SCardModelosProntos';
import CardModelosCompartilhados from './SCardModelosCompartilhados';
import { StateGlobal } from '../../../ContextAPI/context'

import './css/ListagemP.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  tituloEtapa:{
    fontSize: '1.3em',
    color: '#757575 !important',
    marginBottom: '1rem !important',
    fontWeight: '600',
    marginLeft: '2rem',
    marginTop: '1.7rem'
},
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function tabPesquisa(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function SelecaoProntuarios(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  useEffect(() => {
    // console.log('SELECT FORM')
    // console.log(props)
  }, [props]);

  const {
    idEditProntuario,
    setIdEditProntuario  
  } = StateGlobal();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  
  };

  const novoModelo = () => {
    setIdEditProntuario(false)

    history.push('/novo-modelo');
  }

  return (
    <div className="ListagemModelo-container">
      <Grid container>
      <Grid item xs={12}>
        <Paper className={clsx(classes.paper, classes.colorPaper)}>
            <h3 className={classes.tituloEtapa}>Selecionar prontuário</h3>
          <div className="ListgemModelo-prontuarios atendimento">
          <ThemeProvider theme={theme}>
          <div className="padding-lateral app-bar-modelos-pront">
                <AppBar position="static">
                    <Tabs  indicatorColor="secondary" textColor="secondary" value={value} onChange={handleChange} aria-label="tab-pesquisa">
                      <Tab label="Meus modelos" {...tabPesquisa(0)} />
                      <Tab label="Modelos prontos" {...tabPesquisa(1)} />
                      {/* <Tab label="Modelos compartilhados" {...tabPesquisa(2)} /> */}
                    </Tabs>
                </AppBar>
                <TabPanel className="retirar-padding-lateral" value={value} index={0}>
                    <CardMeusModelos patientID={props.patientID} selectForm={props.selectForm}/>
                </TabPanel>
                <TabPanel className="retirar-padding-lateral" value={value} index={1}>
                  <CardModelosProntos patientID={props.patientID} selectForm={props.selectForm}/>
                </TabPanel>
            </div>
          </ThemeProvider>
          </div>
        </Paper>
      </Grid>
    </Grid>
  </div>
  );
}