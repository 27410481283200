import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  faam: {
    '&  article': {
      marginTop: '15px'
    }
  },

  score: {
    marginTop: '10px',
    fontWeight: '500'
  }
}));