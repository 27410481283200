// import React, { useState, useEffect, useLayoutEffect } from 'react';
// import TextField from '@material-ui/core/TextField';
// import '../css/inputs.css';
// import { StateGlobal } from '../../../../../ContextAPI/context'

// export default function InputTextoCurto({ title, id, required, edit ,options }) {

//   const [textoCurto, setTextoCurto] = useState(edit);
//   const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario , respView } = StateGlobal();
//   const [req, setReq] = useState(required);

//   const handleChangeInput = event => {
//     const { value } = event.target;

//     Answer.map((item, index) => {
//       if (item.question_id === id) {
//         setTextoCurto(value)
//         Answer[index].answer = value
//         console.log('ansewer onchange', Answer[index])

//       }

//     })
//     const array = MontagemProntuario

//     console.log('texto curto',array)

//     array[PosProntuario].montagem.map((item, index) => {
//       if (item.tempId === id) {
//         console.log(item.answer)
//         item.answer = value
//       }

//     })

//     setMontagemProntuario(array)

//     console.log(array)

//   };


//   useEffect(() => {
//     setTextoCurto(edit)
//   }, [id])



//   return (
//     <div>

//       <p className="titulo-form">{title}</p>
//       {/* {id}{edit}{textoCurto} */}
//      {/* {id} */}
//       <TextField
//         id={id}
//         name={id}      
//         className="mb-4"
//         value={textoCurto}
//         onChange={handleChangeInput}
//         label={title}
//         type="text"
//         required={req}
//         disabled={respView}
//         variant="outlined"
//         inputProps={{ maxLength: 150 }}
//       />

//     </div>
//   );
// }



import React, { useState, useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import ListIcon from '@material-ui/icons/List';
import { BsPlusCircleFill } from 'react-icons/bs'

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './style.css'


import { StateGlobal } from '../../../../../ContextAPI/context'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import VistaAnterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_anterior.png';
import VistaInferior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_inferior.png';
import VistaLateral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_lateral.png';
import VistaPosterior from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_cranio_vista_posterior.png';
import SistemaMuscular from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_muscular.png';
import SistemaViceral from '../../../../../Assets/Images/ImagensProntuario/mapa_eva_sistema_visceral.png';
import OdontologicoImg from '../../../../../Assets/Images/ImagensProntuario/mapa_odontologia.png';
import DesenvolvimentoMotor from '../../../../../Assets/Images/ImagensProntuario/desenvolvimento_motor.png';

import { TrainRounded } from '@material-ui/icons';




const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100vh"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '97%',
    },
  },
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    background: '#F9F9F9'
  },
  inputEscala: {
    width: '70%',
  },

}));



export default function CardTextoCurto({ title, id, required, edit, options, answer }) {
  const classes = useStyles();
  const history = useHistory();
  const [newname, setNewname] = useState();

  const [checked, setcheck] = useState(false)
  const [titulocampo, setTituloCampo] = useState();
  const [tipo, setTipo] = useState({ id: 595, text: "Molares", value: "18" });
  const [img, setImg] = useState();
  const [escala, setEscala] = useState('')
  const [listagem, setListagem] = useState([])
  const [OptionValues, setOptionValues] = useState(options)
  const [error, setError] = useState(false)
  const [req, setReq] = useState(required);




  const { Answer, setAnswer, respEdit, AnswerEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, respView, validaProntuario, setvalidaProntuario,  validation, setValidation,
    attValidation, setAttValidation, attEncerraError  } = StateGlobal();




  useEffect(() => {

    var arr = []



    if (respEdit) {

      const dados = Answer.find(item => item.question_id === id)
      dados.answer.map((item) => {
        if (item.form_step_question_option_id) {
          arr.push({ id: item.form_step_question_option_id, value: item.text, text: ChangeOption(item.form_step_question_option_id) })
          UpdateAnswer(arr)


        }
      })
    } else {

      setOptionValues(options)

    }

    var opa = ReturnAnswer()

    const eae = opa && opa.slice()

    if (respEdit) {
      setOptionValues(eae)

    }





  }, [id])





  // //codigo para validação***************************************
  // useEffect(() => {
  //   validacaoOnchange()
  // }, [OptionValues, id])


  // function validacaoOnchange() {

  //   var error = false

  //   if (required !== false)
  //     Answer.map((item) => {

  //       if (item.question_id === id) {
  //         if (item.answer.length > 0) {

  //           item.answer.map((i) => {
  //             //  console.log('ITEM ===>',i)
  //             if (i.value === '' || i.value === undefined) {
  //               error = true
  //             }
  //           })
  //           validaProntuario[id].error = error ? true : false
  //           validaProntuario[id].value = error ? '' : 'ok'
  //           setvalidaProntuario({ ...validaProntuario })

  //         }
  //       }
  //     })

  // }
  //codigo para validação***************************************

  //   <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
  //   {validaProntuario[id].error === true ? validaProntuario[id].message : ''}
  // </div>





  function handlerChange(e) {
    const value = e.target.value

    const obj = ChangeOption(value)


    setTipo(obj)

  }
  function handlerChange2(e) {
    const { name, value } = e.target




    OptionValues.map((item) => {
      if (item.text === name) {
        item.value = value
      }


    })

    const arr = OptionValues.slice()
    setOptionValues(arr)

    UpdateAnswer(arr)


    let err = []
    
    if(required){
            
      OptionValues.forEach( element => {

        err.push(element.value)
          
      });

    }
    console.log(err)

    let val = err.indexOf('')
    console.log(val)

    if(val < 0){
        setError(false)
    }else{
        setError(true)
    }



  }


  function ChangeOption(tipo) {
    var data = ''
    options.map((item) => {

      if (item.id === Number(tipo)) {
        data = item
      }
    })

    return data.text
  }


  function UpdateAnswer(arr) {
    Answer.map((item) => {
      if (item.question_id === id) {
        item.answer = arr
      }
    })

    setAnswer(Answer)
  }


  function ReturnAnswer() {
    var teste
    Answer.map((item) => {
      if (item.question_id === id) {
        teste = item.answer
      }
    })

    return teste
  }

  useEffect(() => {
    if(validation) {
      
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
         
          if(comp.id == id){
   
            setError(validation[index].components[i].error)
              
          }
        })
      })
    }

  }, [attEncerraError])

  useEffect(()=>{
    if(validation) {
      
      validation.forEach((step, index)=>{
          step.components.forEach((comp, i)=>{        
          
            if(comp.id == id){
    
              validation[index].components[i].error = error 
              
                
            }
          })
      })
      setAttValidation(!attValidation)
    }

  }, [error])


  return (
    <div className="CardTextoCurto-container" >
      {/* {props.tempId} */}
      {/* <ListIcon className="icon-list-card-input" /> */}
      <div className="container-card-input">

        <div className="titleEscale">
          <h3 className="h3-title-card-input">Desenvolvimento Motor</h3>

        </div>
        {/* {id} */}

        <div className="img-class">
          <img src={DesenvolvimentoMotor} alt="" />
        </div>


        <div>


          {respEdit ?
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              {options.map((item, index) => {
                return (
                  <TextField
                    disabled={respView}
                    label={item.text}
                    style={{ width: '30%', marginTop: '25px' }}
                    name={item.text}
                    className='inputdm'
                    onChange={handlerChange2}
                    value={OptionValues[index]?.value}
                    variant="outlined"
                    //disabled = {respEdit}
                  ></TextField>
                )
              })}
            </div>

            : <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              {options.map((item, index) => {
                return (
                  <TextField
                    disabled={respView}
                    label={item.text}
                    style={{ width: '30%', marginTop: '25px' }}
                    name={item.text}
                    className='inputdm'
                    onChange={handlerChange2}
                    value={OptionValues[index]?.value}
                    variant="outlined"
                    //disabled = {respEdit}
                  ></TextField>
                )
              })}
            </div>}

            <div style={{ color: 'red', width: '100%', marginTop: '10px', fontSize: '15px' }}>
            { error === true ? '* Preencha o campo' : ''}  
            </div>

          {/* 

   {validaProntuario[id].error === true ? 'true': 'false'}
   {id} */}




        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }} >

          {/* {listagem ? listagem.map((item, index) => {

            return (
              <div style={{ width: '100%', borderRadius: "5px", border: '1px solid gray', flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: "space-between" }}>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '500px' }}>
                  <div style={{ borderRadius: '50%', backgroundColor: item.cor, height: '20px', width: '20px', margin: '15px' }}></div>
                  <div>{item.value}-{item.text}</div></div>
                <div style={{ marginRight: '50px' }}>Dor: {item.answer}</div> <div>
                  {respView ? ' ' :
                    <IconButton onClick={() => {
                      listagem.splice(index, 1)
                      console.log('LISTAGEM', listagem)
                      const arr = listagem.slice()

                      setListagem(arr)
                      UpdateAnswer(arr)

                    }}>
                      <DeleteIcon />
                    </IconButton>}
                </div>
              </div>
            )
          }) : 'vazio'} */}


          { }


        </div>

        <div className="Card-option">
        </div>
      </div>
    </div>
  );
}