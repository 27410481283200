import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularLoad from '../../../Components/Load'
import CloseIcon from '@material-ui/icons/Close';
import '../css/Agenda.css'
import { TextField } from '@material-ui/core';
import conexao from '../../../Services/api'
import { campovazio } from '../../../Components/ReduxTeste/ValidateForm'
import  GeraPdf  from './PdfByModalGerarguia'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  SelectProcedimento: {
    width: '100%'
  },
  QuantProce: {
    width: '30%',
    marginLeft: '1rem',
    backgroundColor: 'white',
    marginTop: '1rem',
    marginBottom: theme.spacing(1),
  },
  selectTipoAtendimento: {
    width: '100%'
  },
  input: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  inputTel: {
    width: '50%',
    marginRight: '1rem',
    backgroundColor: 'white',
  },
  paddingButton: {
    paddingRight: '1.5rem'
  },
  inputData: {
    width: '30%',
  },
  label: {
    background: 'white',
    paddingRight: '.4rem'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  },
  textareagerargui: {

    minWidth: '760px',
    maxWidth: '760px',
    minHeight: '200px',
    maxHeight: '200px'
  },
  DadosPaciente: {
    color: '#8F8F8F',
    fontSize: '14px',
    marginTop: "15px",
    display: 'flex',
    flexDirection: 'row'
  },
  ResultGet: {
    color: '#8F8F8F',
    fontSize: '14px',
    fontWeight: 'bold'
  },
  dialogfiderato:{

  

  }
}));

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },

  },
}, ptBR);


const initialvalues = {
  idade:'',
  convenio:'',
  atendimento:'',
  descGuia:''
}


export default function ModalAddAgendamento(props) {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [values, setValues] = useState(initialvalues);
  const [id, setId] = useState(props.id);
  const [load, setLoad] = useState(false);
  const [editar, setEditar] = useState(false);
  const [nomePac, setNomePac] = useState('');

const [DataGuia, setDataGuia] = useState({})
const doctorId = localStorage.getItem('doctorId')

  const initialvalidate = {
    gerarguia: { name: 'gerarguia', funcao: campovazio, error: false, value: '' },
  }

  const [validateTest, setValidateTest] = useState(initialvalidate)

  function onchangeValidateTeste(e) {
    const { name, value } = e.target   
    const obj = validateTest[name]
    const er = validateTest[name].funcao(value)
    let att = { ...obj, error: er, value: value }   
    setValidateTest({ ...validateTest, [name]: att })
  }

  useEffect(()=>{
    if(values.descGuia !== "")
    setValidateTest({ gerarguia:{...validateTest.gerarguia,value: values.descGuia}})
  },[values])

  useEffect(() => {
    setOpen(props.open);
    setId(props.id)
    setNomePac(props.nomePac)
      buscaDadosGuide();
  }, [props])


  const closeModal = () => {
    setOpen(false);
    props.onChange(false)
  }

  async function buscaDadosGuide() {
    if(id){
    await conexao.get(`/schedule/guide/${id}`)
      .then((response) => {
        
        if(response.data.data===''){
          setEditar(true)
        }else{
          setEditar(false)
        }

let valueFinal = ''

response.data.data.map((item,index) => {
// console.log("itemFinal",item)
valueFinal = item.text
})
  
        setValues({ ...values, ['descGuia']: valueFinal})
        setId(response.data.data[0].schedule_id)


      }).catch((error)=>{

      })
    }
  }


  async function enviaDadosAgendamento(event) {
    setLoad(true)
    event.preventDefault();

    const arrvalidate =  Object.values(validateTest)
    arrvalidate.map((item) => {   
      if(item.value === '') {
          validateTest[item.name].error = true   
      }      
      setValidateTest({...validateTest})
    })

    console.log('editar', editar)
    if(!editar) {
      await conexao.post('/schedule/guide/create',
      {
        "text": values.descGuia,
        "schedule_id": id,
      })
      .then((response) => {
        console.log('create OK')
        console.log(response)
        setLoad(false)
        setTypeAlert('success');
        setMessage('Guia gerada com sucesso');
        setTimeout(() => {
          // closeModal();
          GeraPdf(DataGuia,props,values.descGuia);
          setMessage('');
        }, 1500);
      }).catch((error) => {
          console.log('create ERRO')
          console.log(error.response)
          setLoad(false)
          setTypeAlert('error');
          setMessage('Erro ao imprimir guia, tente novamente');
          setTimeout(() => {
            setMessage('');
          }, 1500);
      })
  } else {
    await conexao.put(`/schedule/guide/${id}`,
    {
      "text": values.descGuia,
      "schedule_id": id,
    })
    .then((response) => {
      console.log('create OK')
      console.log(response)
      setLoad(false)
      setTypeAlert('success');
      setMessage('Guia gerada com sucesso');
      setTimeout(() => {
        // closeModal();
        GeraPdf(DataGuia,props,values.descGuia);
        setMessage('');
      }, 1500);
    }).catch((error) => {
      console.log('update ERRO')
      console.log(error.response)
      setLoad(false)
      setTypeAlert('error');
      setMessage('Erro ao imprimir guia, tente novamente');
      setTimeout(() => {
        setMessage('');
      }, 1500);
    })
  }
}

async function getData() {
  const { data } = await conexao.get(`/doctor/byid/${doctorId}`)
  setDataGuia(data.data)
}
// console.log("dataguia", DataGuia)
useEffect(()=>{
  getData()
},[])


  return (
    <>
    
    <div >
      <ThemeProvider theme={theme}>
        <Dialog open={open} className={classes.dialogfiderato}   aria-labelledby="Modal-title-agendamento" maxWidth='xl' minWidth='xl'  >
          <form id="" style={{ minWidth:'800px' ,minHeight:'500px'}} onSubmit={enviaDadosAgendamento} autoComplete="off">
            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
              Gerar declaração
            <CloseIcon onClick={closeModal} />
              </div>
            {nomePac}
          <div>
              </div>
              {/* <div className={classes.DadosPaciente}>
                <div >
                  <div>
                    Idade: <label className={classes.ResultGet}>25 anos, 3 meses e 21 </label>
                  </div>
                  <div>
                    Convênio: <label className={classes.ResultGet}>0</label>
                  </div>
                </div>
                <div style={{ marginLeft: '200px' }}>
                  <div>
                    Atendimentos: <label className={classes.ResultGet}>0</label>
                  </div>
                  <div>
                    Privado: <label className={classes.ResultGet}>0</label>
                  </div>
                </div>
              </div> */}
            </DialogTitle>
            
            <DialogContent>
            
            {message ?
                <div className="Modal-agendamento-alert">
                  <Alert variant="filled" severity={typeAlert}>
                      {message}
                  </Alert>
                </div>
              :''}
              <TextField id="outlined-multiline-static"
                multiline
                rows={8}
                variant="outlined" 
                placeholder='Digite aqui' 
                className={classes.textareagerargui} 
                name='gerarguia'
                value={values.descGuia}     
                style={{margin:"10px"}}   
                onChange={(e)=>{setValues({ ...values, ['descGuia']: e.target.value}); onchangeValidateTeste(e)}}
                error={validateTest.gerarguia.error}  
                helperText={validateTest.gerarguia.error ? 'Esse campo precisa ser preenchido' :''}  
               
               // required
                />
                
            </DialogContent>
            
            <DialogActions className={classes.paddingButton}>
              <Button type="submit" variant="contained" color="primary" className="color-primary">
              {load?
                <CircularLoad/>
              :'IMPRIMIR'}
          </Button>
            </DialogActions>
          </form>
     
        </Dialog>

      </ThemeProvider>
    </div>
    
    </>
  );
}
