import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Eroe({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function handleChange(e) {
    const { name, value } = e.target

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      eroe: {
        ...state.eroe,
        [name]: value
      }
    }))
  }
  
  function getEroeScore() {
    let score = 0

    Object.values(sportsPhysiotherapyChartAnswers.eroe).forEach(value => { 
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return (score).toFixed(2) || 0;
  }

  return (
    <div>
      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          I DOR
        </p>
        <TextField
          id="select"
          variant="outlined"
          select
          fullWidth
          name="pain"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.eroe.pain}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Ausência de dor durante a competição</option>
          <option value="8">Dor somente após competir</option>
          <option value="6">Dor durante a competição</option>
          <option value="4">Dor que impede competir</option>
          <option value="2">Dor durante as AVD’S</option>
          <option value="0">Dor em repouso</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          II FORÇA E RESISTÊNCIA
        </p>
        <TextField
          id="select"
          variant="outlined"
          select
          fullWidth
          name="powerAndResistence"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.eroe.powerAndResistence}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Sem fraqueza, cansaço normal de competição</option>
          <option value="8">Fraqueza após competição, cansaço precoce</option>
          <option value="6">Fraqueza durante a competição, cansaço anormal de competição </option>
          <option value="4">Fraqueza impedindo a competição</option>
          <option value="2">Fraqueza ou cansaço nas AVD’s </option>
          <option value="0">Fraqueza ou cansaço impedindo as AVD’s</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          III ESTABILIDADE
        </p>
        <TextField
          id="select"
          variant="outlined"
          select
          fullWidth
          name="stability"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.eroe.stability}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Sem frouxidão durante a competição</option>
          <option value="8">Subluxações recorrentes durante a competição</option>
          <option value="6">“Síndrome do Braço Morto” durante a competição</option>
          <option value="4">Subluxações recorrentes impedindo a competição</option>
          <option value="2">Subluxações recorrentes durante as AVD’S</option>
          <option value="0">Luxação</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          IV INTENSIDADE
        </p>
        <TextField
          id="select"
          variant="outlined"
          select
          fullWidth
          name="intensity"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.eroe.intensity}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Horas de competição pré lesão versus pós lesão (100%)</option>
          <option value="8">Horas de competição pré lesão versus pós lesão (75%)</option>
          <option value="6">Horas de competição pré lesão versus pós lesão (50%)</option>
          <option value="4">Horas de competição pré lesão versus pós lesão (menos que 50%)</option>
          <option value="2">Horas de AVD’S pré lesão e pós lesão (100%)</option>
          <option value="0">Horas de AVD’S pré lesão e pós lesão (Menos de 50%)</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          V DESEMPENHO ESPORTIVO
        </p>
        <TextField
          id="select"
          variant="outlined"
          select
          fullWidth
          name="sportsPerformance"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.eroe.sportsPerformance}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="50">No mesmo nível, com mesmo desempenho</option>
          <option value="40">No mesmo nível, com mesmo desempenho diminuído</option>
          <option value="30">No mesmo nível, com mesmo desempenho diminuído, inaceitável pelo atleta</option>
          <option value="20">Nível diminuído, com mesmo desempenho aceitável pelo atleta</option>
          <option value="10">Nível diminuído, com mesmo desempenho inaceitável pelo atleta</option>
          <option value="0">Não pode competir, necessidade de mudança de esporte</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 10px 0' }}
        >
          VI OBJETIVO (Amplitude de movimento)
        </p>

        <TextField
          id="select"
          variant="outlined"
          select
          fullWidth
          name="objective"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.eroe.objective}
          onChange={e => handleChange(e)}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="" selected>...</option>
          <option value="10">Rotação Lateral normal (90º - 90º), elevação normal</option>
          <option value="8">Menos de 5º de rotação lateral normal (90º - 90º), elevação normal</option>
          <option value="6">Menos de 10º de rotação lateral normal (90º - 90º), elevação normal</option>
          <option value="4">Menos de 15º de perda de rotação lateral normal (90º - 90º), elevação normal</option>
          <option value="2">Menos de 20º de perda de rotação lateral normal (90º - 90º), elevação normal</option>
          <option value="0">Mais de 20º de perda de rotação lateral normal (90º - 90º), ou qualquer perda de elevação</option>
        </TextField>

        <p className={classes.score}>Escore: {getEroeScore()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      } 
    </div>
  )
}