import React, { useContext } from 'react'

import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import { useStyles } from './style';

export default function StepButtonNext() {
  const classes = useStyles();

  const { handleNextButton } = useContext(SportsPhysiotherapyContext)

  return (
    <div className={classes.stepButton}>
      <Button 
        onClick={handleNextButton}
        variant="contained"
        className='color-primary'
      >
        Próximo 
        <ArrowForwardIcon />
      </Button>
    </div>
  )
}