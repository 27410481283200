import React, {useEffect, useState} from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Logo from '../../Assets/Images/LogoCP.svg';
import Btngoogle from '../../Components/Google';
import FormLogin from './Form';
import './css/login.css';
import ModalEsqueciSenha from './ResetResenha';


const theme = createTheme({
  palette: {
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
});

export default function NewPage() {
  const [openEsqueci, setOpenEsqueci] = useState(false);

  const closeModalEsqueci = () => {
    setOpenEsqueci(false);
  }

  useEffect( () => {
    localStorage.setItem('promocao-black-friday', 'ok')
  })


  return (
    <ThemeProvider theme={theme}>
      <div className="login-background-image">


        <div className='card-promocao'>
          <h3>Atenção</h3>
          <div className='content-promocao'>
            <span>Para aproveitar a promoção primeiro crie uma conta ou faça login no nosso sistema!</span>
          </div>
        </div>
        <div className="login-card-form-login">

          <img src={Logo} className="Login-logo"/>
          <FormLogin/>
          <div>
            <Button 
              href="#text-buttons" 
              onClick={()=>setOpenEsqueci(true)}
              className="lost-password" 
              color="default">
              Esqueci minha senha
            </Button>
          </div>
          <div className="trial">
            <p>Teste GRATUITAMENTE por 10 dias</p>
            <div class="Login-div-row">
              <Button 
                href="/black-friday-cadastro" 
                className="signup-button" >
                Consultório
              </Button>

              <Button 
                href="/black-friday-cadastro-clinic" 
                className="signup-button">
                Clínica
              </Button>
            </div>
          </div>
        </div>

        <ModalEsqueciSenha open={openEsqueci} handleModal={closeModalEsqueci}/>
      </div>
    </ThemeProvider>
  );
}