import { Button, createMuiTheme, Grid, IconButton, InputBase, makeStyles, Paper, ThemeProvider } from "@material-ui/core"
import clsx from 'clsx';
import React, { useState } from "react"
import { ptBR } from "@material-ui/core/locale";
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import ModalRelatorioDeDor from './Components/Modal/ModalRelatorioDeDor';
import ModalCraniometria from './Components/Modal/ModalCraniometria';
import ModalCurvaDeCrescimento from "./Components/Modal/ModalCurvaDeCrescimento";
import ModalDesenvolvimentoMotor from "./Components/Modal/ModalDesenvolvimentoMotor";

import useStyles from './style'
import '../../App.css';

export default function Relatorio() {

  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      secondary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      },
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#229fb5',
        contrastText: '#fff',
      }
    },
  }, ptBR);

  const datas =[
    {id: 1 , name: 'Escala De Dor', path: '/escala-de-dor/'},
    {id: 2 , name: 'Craniometria', path: '/craniometria/'},
    {id: 3 , name: 'Curva de Crescimento', path: '/curva-de-crescimento/'},
    {id: 3 , name: 'Desenvolvimento Motor', path: '/desenvolvimento-motor/'},


    // {id: 2 , name: 'Dermato Funcional Corporal', path: '/dermato-funcional-corporal/',},
    // {id: 3 , name: 'Nutrição', path: '/nutrição/',},
    // {id: 4 , name: 'Nutricional Gestante', path: '/nutricional-gestante/',},
    // {id: 5 , name: 'Introdução Alimentar', path: '/introdução-alimentar/',},
    // {id: 6 , name: 'Pediatra', path: '/pediatra/',},
  ]

  const [Search , setSearch] = useState('')
  const [OpenModal, setOpenModal] = useState(false)
  const [TypeName, setTypeName ] = useState('')
  const [Url , setUrl] = useState()

  const dataFiltrado = datas.filter(({name}) => name.toLowerCase().includes(Search.toLowerCase()))


  function Open(){
    setOpenModal(true)
  }
  function Close(){
    setOpenModal(false)
  }

  return(
    <div className={localStorage.getItem('containerClass')}>
      <div className="card">
          <>
          <div className="card_header">
            <h1>Relatórios</h1>
          </div>
          
          <div className="padding-lateral">
            <FormControl className={clsx(classes.form)}>
              <InputBase
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch} 
                // onClick={(event) => busca(event)} 
                /></IconButton>}</InputAdornment>}
                labelWidth={0}
                // onKeyDown={busca}
                onChange={(e) => {setSearch(e.target.value)}}
                placeholder="Buscar Relatório"
                className={clsx(classes.search)}
              />
            </FormControl>

            {dataFiltrado.map((item, index ) => {
              return (
                <div className="CardModelos-envolve">
                  <div className="CardModelos-envolve-text-icon">
                    <DescriptionIcon className="CardModelos-icon-pront" />
                    <p>{item.name}</p>
                    
                  </div>

                  <div className="CardModelos-envolve-text-icon-2">
                    <Button  variant="contained" className="Color-btn-addModelo" 
                    onClick={() =>{
                      setUrl(item.path)
                      localStorage.setItem("NameReport",item.name);
                      setTypeName(item.name)
                      
                      Open()
                    }}>
                      Visualizar
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
          </>
      </div>

      {OpenModal ?
       TypeName === 'Escala De Dor' ? <ModalRelatorioDeDor open={Open} close={Close} url={Url}/> :
       TypeName === 'Craniometria' ? <ModalCraniometria open={Open} close={Close} url={Url} name={TypeName}/> :
       TypeName === 'Curva de Crescimento'  ? <ModalCurvaDeCrescimento open={Open} close={Close} url={Url} name={TypeName}/> :
       TypeName === 'Desenvolvimento Motor'  ? <ModalDesenvolvimentoMotor open={Open} close={Close} url={Url} name={TypeName}/> :
       ''
      : ""}
    </div>
  )
}