import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import conexao from '../../../../Services/api';
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
import { useHistory } from 'react-router-dom';
import '../css/Listagem.css';
import { StateGlobal } from '../../../../ContextAPI/context'

const useStyles = makeStyles((theme) => ({
    form: {
       width:'100%',
        border: 'none',
        marginBottom: '2rem'
    },
    search: {
        background: '#f1f1f1',
        boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
        borderRadius: '3px',
        height:'45px',
        fontSize:'15px',
        border: 'none',
        padding: '15px',
        minWidth: '350px',
        
     },
     iconSearch:{
         color: '#8F8F8F'
     }
  }));


const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function CardModelosProntos() {
    const classes = useStyles();
    const History = useHistory();
    const [modelosProntos, setModelosProntos] = useState('');
    const [typeAlert, setTypeAlert] = useState();
    const [message, setMessage] = useState();
    const [load, setLoad] = useState(false);
    const [nameModelo, setNameModelo] = useState('');
    const[modelosProntosbase ,setModelosProntosbase]= useState();
    const {
      idEditProntuario,
      setIdEditProntuario,
      setIdViewProntuario,
      viewProntuario, 
      setViewProntuario 
    } = StateGlobal();
    
  async function listaModeloPronto() {
      
      await conexao.post(`/form/default/list`,{
        "search": nameModelo,
      })
        .then((response) => {
          setLoad(false)
          setModelosProntos(response.data.data);
          setModelosProntosbase(response.data.data)
          if(response.data.data.length<1){
            setModelosProntos('')
            setTypeAlert('error');
            setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
            setTimeout(() => {
              //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
              setMessage('');
            }, 3000);
          }
        }).catch((error) => {
            setLoad(false)
            setModelosProntos('')
            setTypeAlert('error');
            setMessage('Prontuário não encontrado, verifique o nome do modelo buscado.');
            setTimeout(() => {
              //setTypeAlert('') => Alterado por Elton. Propriedade inválida;
              setMessage('');
            }, 3000);
        })
  }

  useEffect(() => {
    setLoad(true)
    listaModeloPronto();
  }, [])

  useEffect(() => {
    if(nameModelo===''){
      setModelosProntos([])
      setModelosProntosbase([])
      listaModeloPronto()
    }

  },[nameModelo]);


  function busca(event) {
    if (event.key === 'Enter') {
      setModelosProntos([])
      setModelosProntosbase([])
      listaModeloPronto()
    } if (event._reactName === 'onClick') {
      setModelosProntos([])
      setModelosProntosbase([])
      listaModeloPronto()
    }
    return true
  }

  function visualizarModelo(e){
    conexao.get('/form/' + e).then((res) => {

      setIdEditProntuario({ id: e, data: res.data.data })
      setViewProntuario({ id: e, data: res.data.data })
      History.push('/novo-modelo')

    }).catch((error) => {

    })
  }


  return (
    <div className="">
            <FormControl className={clsx(classes.form)}>
            <InputBase
                id="outlined-adornment-weight"              
                endAdornment={<InputAdornment position="end">{<IconButton><SearchIcon className={classes.iconSearch}  onClick={(event) => busca(event)} /></IconButton>}</InputAdornment>}
                //labelWidth={0}
                disabled={load ? true : false}
                onKeyDown={busca}
                onChange={(e) => {setNameModelo(e.target.value)}}
                placeholder="Busque por um modelo"
                className={clsx(classes.search)}
            />
            </FormControl>
            <div>
              {load?
                <div className="div-load-lista-modelos"><CircularLoad/></div>
              :''}
            {modelosProntos ?
            modelosProntos.map((modeloPronto) => (
              <div className="CardModelos-envolve">
                  <div className="CardModelos-envolve-text-icon">
                      <AssignmentIcon className="CardModelos-icon-pront"/>
                      <p>{modeloPronto.title}</p>
                  </div>
                  <div>
                      <IconButton>
                          <VisibilityIcon onClick={() => {visualizarModelo(modeloPronto.id)}}/>
                      </IconButton>
                  </div>
              </div>
               )):
               <div>
                  {message ?
                    <div className="Modal-agendamento-alert">
                      <Alert variant="filled" severity={typeAlert}>
                        {message}
                      </Alert>
                    </div>
                  :''}
              </div>
               }
            </div>
    </div>
  );
}