export const sportsModalities = [
  {name: 'Academia/ Musculação '},
  {name: 'Airsoft '},
  {name: 'Atletismo 100 metros '},
  {name: 'Atletismo 200 metros '},
  {name: 'Atletismo 400 metros '},
  {name: 'Atletismo 800 metros '},
  {name: 'Atletismo 110 metros c/barreiras '},
  {name: 'Asa Delta '},
  {name: 'Automobilismo / F1 / Kart '},
  {name: 'Base jump '},
  {name: 'Badminton '},
  {name: 'Balé / Dança '},
  {name: 'Balonismo '},
  {name: 'Basquete de cadeira de rodas '},
  {name: 'Basquete '},
  {name: 'Basquete 3x3 '},
  {name: 'Beach Tennis '},
  {name: 'Beisebol  '},
  {name: 'Biatlo '},
  {name: 'Bobsleigh '},
  {name: 'Bocha '},
  {name: 'Boxe '},
  {name: 'Canoagem '},
  {name: 'Canoagem Slalom  '},
  {name: 'Canoagem Velocidade '},
  {name: 'Caratê '},
  {name: 'Ciclismo Estrada '},
  {name: 'Ciclismo Pista '},
  {name: 'Ciclismo BMX '},
  {name: 'Ciclismo Mountain bike '},
  {name: 'Ciclismo Freestyle '},
  {name: 'Corrida 3k '},
  {name: 'Corrida 3k '},
  {name: 'Corrida 5k '},
  {name: 'Corrida 10k '},
  {name: 'Corrida 15k '},
  {name: 'Corrida 21k '},
  {name: 'Corrida 42k '},
  {name: 'Críquete '},
  {name: 'Crossfit / Crosstrainig '},
  {name: 'Curling '},
  {name: 'Curling em cadeira de rodas '},
  {name: 'Esgrima '},
  {name: 'Esgrima de cadeira de rodas'},
  {name: 'Escalada '},
  {name: 'Esqui '},
  {name: 'Esqui Alpino '},
  {name: 'Esqui Cross Country '},
  {name: 'E-Sports '},
  {name: 'Futebol de campo '},
  {name: 'Futebol de 5 '},
  {name: 'Futebol de 7 '},
  {name: 'Futsal '},
  {name: 'Futebol society '},
  {name: 'Futebol Americano '},
  {name: 'Ginástica Acrobática '},
  {name: 'Ginástica Artística '},
  {name: 'Ginástica Rítmica '},
  {name: 'Ginástica Trampolim '},
  {name: 'Golfe '},
  {name: 'Goalball '},
  {name: 'Halterofilismo'},
  {name: 'Handebol'},
  {name: 'Handebol de praia '},
  {name: 'Hipismo'},
  {name: 'Hóquei no gelo '},
  {name: 'Hóquei sobre a grama '},
  {name: 'Jiu-itsu '},
  {name: 'Judô '},
  {name: 'Karatê'},
  {name: 'Kravmaga '},
  {name: 'Kitesurf '},
  {name: 'Lutas Livre / MMA '},
  {name: 'Lutas Greco-romana '},
  {name: 'Luge '},
  {name: 'Maratona Aquática '},
  {name: 'Mergulho '},
  {name: 'Natação Artística / Nado Sincronizado '},
  {name: 'Natação '},
  {name: 'Saltos ornamentais '},
  {name: 'Paddle '},
  {name: 'Paintball '},
  {name: 'Parapente '},
  {name: 'Paraquedismo '},
  {name: 'Parkour '},
  {name: 'Patinação Artística '},
  {name: 'Patinação de velocidade in line '},
  {name: 'Patinação no gelo '},
  {name: 'Pentatlo moderno '},
  {name: 'Polo aquático'},
  {name: 'Rapel'},
  {name: 'Rafting '},
  {name: 'Remo'},
  {name: 'Rugby de cadeira de rodas'},
  {name: 'Rugby sevens'},
  {name: 'Slackline '},
  {name: 'Skate Street '},
  {name: 'Skate Park '},
  {name: 'Softball '},
  {name: 'Snowboard '},
  {name: 'Skeleton '},
  {name: 'Surfe'},
  {name: 'Taekwondo '},
  {name: 'Tênis '},
  {name: 'Tênis de mes '},
  {name: 'Trekking'},
  {name: 'Tiro esportivo '},
  {name: 'Tiro com arco'},
  {name: 'Triatlo '},
  {name: 'Ultramaratona '},
  {name: 'Vela'},
  {name: 'Vôleibol '},
  {name: 'Vôleibol sentado '},
  {name: 'Vôleibol de praia '},
  {name: 'Xadrez '},
  {name: 'Windsurf '},
  {name: 'Wingsuit '},
]

let trainingVolumeArr = ['1hr']

for (var i = 2; i < 11; i++) {
  trainingVolumeArr.push(`${i}hrs `);
}
export const trainingVolume = trainingVolumeArr;

export const skeletalMuscleTypes = [
  {text: "Cabeça", value: "1"},
  {text: "Glenoumeral direita", value: "2"},
  {text: "Glenoumeral esquerda", value: "3"},
  {text: "Acromioclavicular direita", value: "4"},
  {text: "Acromioclavicular esquerda", value: "5"},
  {text: "Esternoclavicular direita", value: "6"},
  {text: "Esternoclavicular esquerda", value: "7"},
  {text: "Esterno", value: "8"},
  {text: "Costelas direita", value: "9"},
  {text: "Costelas esquerda", value: "10"},
  {text: "Abdômen", value: "11"},
  {text: "Cotovelo direito", value: "12"},
  {text: "Cotovelo esquerdo", value: "13"},
  {text: "Punho Direito", value: "14"},
  {text: "Punho Esquerdo", value: "15"},
  {text: "Mão direita", value: "16"},
  {text: "Mão esquerda", value: "17"},
  {text: "Púbis", value: "18"},
  {text: "Quadril direito (anterior)", value: "19"},
  {text: "Quadril esquerdo (anterior)", value: "20"},
  {text: "Coxa direita (anterior)", value: "21"},
  {text: "Coxa esquerda (anterior)", value: "22"},
  {text: "Joelho direito (anterior)", value: "23"},
  {text: "Joelho esquerdo (anterior)", value: "24"},
  {text: "Tíbia direita", value: "25"},
  {text: "Tíbia esquerda", value: "26"},
  {text: "Tornozelo direito", value: "27"},
  {text: "Tornozelo esquerdo", value: "28"},
  {text: "Pé direito ", value: "29"},
  {text: "Pé esquerdo", value: "30"},
  {text: "Escápula direita", value: "31"},
  {text: "Escápula esquerdo", value: "32"},
  {text: "Cervical ", value: "33"},
  {text: "Torácica", value: "34"},
  {text: "Lombar", value: "35"},
  {text: "Sacro", value: "36"},
  {text: "Cóccix", value: "37"},
  {text: "Sacroiliaca esquerda", value: "38"},
  {text: "Sacroiliaca direita", value: "39"},
  {text: "Quadril esquerdo (posterior)", value: "40"},
  {text: " Quadril direito (posterior)", value: "41"},
  {text: "Coxa esquerda (posterior)", value: "42"},
  {text: "Coxa direita (posterior)", value: "43"},
  {text: "Joelho esquerdo (posterior)", value: "44"},
  {text: "Joelho direito (posterior)", value: "45"},
  {text: "Panturrilha esquerda", value: "46"},
  {text: "Panturrilha direita", value: "47"},
  {text: "Calcâneo esquerdo", value: "48"},
  {text: "Calcâneo direito", value: "49"},
]

let hoursSleepArr = ['1hr']

for (var index = 2; index < 25; index++) {
  hoursSleepArr.push(`${index}hrs `);
}
export const hoursSleep = hoursSleepArr;

export const algometryMuscles = [
  'M. Masséter',
  'M. Temporal',
  'M. Suboccipitais',
  'M. Esternocleidomastóide',
  'M. Trapézio Superior',
  'M. Trapézio Médio',
  'M. Trapézio Inferior',
  'M. Supraespinhoso',
  'M. Rombóide maior',
  'M. Deltóide Anterior',
  'M. Deltóide Medio',
  'M. Deltóide Posterior',
  'M. Peitoral Maior',
  'M. Bíceps',
  'M. Braquiorradial',
  'M. Extensor radial curto do carpo',
  'M. Extensor radial longo do carpo',
  'M. Glúteo Médio',
  'M. Tibial Anterior',
  'M. Gastrocnêmios medial',
  'M. Gastrocnêmios lateral',
  'Tendão do calcâneo',
  'Tendão Patelar',
  'Fáscia Plantar',
]