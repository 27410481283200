import React, { useContext } from 'react'

import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import { useStyles } from './style';

export default function StepButtonBack({ name }) {
  const classes = useStyles();

  const { handleBackButton } = useContext(SportsPhysiotherapyContext)

  return (
    <div className={classes.stepButton}>
      <Button 
        onClick={handleBackButton} 
        variant="contained"
      >
        <ArrowBackIcon />
        {name || 'Anterior'}
      </Button>
    </div>
  )
}