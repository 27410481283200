import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Select from '@material-ui/core/Select';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditIcon from '@material-ui/icons/Edit';
import { Block } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import ReactWhatsapp from 'react-whatsapp';
import conexao from '../../../Services/api'
import '../css/Agenda.css'
import ModalGerarGuia from './ModalGerarguia'
import Alert from '@material-ui/lab/Alert';
import ModalLancarPagamento from './ModalLancarPagamento'
import CircularLoad from '../../../Components/Load'
import ModalAgendamento from './ModalAgendamento'
import { StateGlobal } from '../../../ContextAPI/context'
import { celular, currency } from './Validate'
import ModalExcluir from './ModalConfirmExcluir'
import { useHistory } from "react-router-dom"


const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '4rem',
    height: '4rem',
    marginRight: '1rem',
    textTransform: 'uppercase'
  },
  namePaciente: {
    margin: 0,
    color: '#27b8d2',
    fontSize: '1.5em',
    textTransform: 'capitalize'
  },
  telefonePaciente: {
    margin: '0rem',
    marginTop: '.25rem',
    color: '#8F8F8F',
    marginBottom: '1em',
  },
  divDadosPaciente: {
    display: 'flex',
    marginBottom: '1rem',
    borderBottom: '1px solid #e4e4e4',
    paddingBottom: '1rem',
  },
  btnReactWhats: {
    backgroundColor: '#f9f9f9',
    border: 0
  },
  btnWhats: {
    backgroundColor: '#f9f9f9',
    color: '#4bc557',
    border: '1px solid #4bc557c7',
  },
  btnsFooter: {
    color: '#8F8F8F',
    backgroundColor: 'white',
  },
  btnFooter: {
    color: '#8F8F8F',
    cursor: 'pointer'
  },
  iconBtn: {
    marginRight: '.25rem'
  },
  dadosConsultaH2: {
    color: '#27b8d2',
    fontSize: '1.1em',
    textTransform: 'uppercase'
  },
  dadosConsulta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectAgendamento: {
    marginLeft: '1.5rem',
    backgroundColor: 'white',
  },
  tabelaAgendamento: {
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
    color: '#8F8F8F',
    backgroundColor: '#f9f9f9',
  },
  ptabelaAgendamento: {
    color: '#8F8F8F',
    marginBottom: '1rem'
  },
  divTable: {
    marginBottom: '3rem'
  },
  modalColor: {
    backgroundColor: '#f9f9f9',
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

function createData(procedimento, quant, valor) {
  return { procedimento, quant, valor };
}

const rows = [
  createData('Retorno', 1, 'R$ 0,00'),
  createData('', 'Total', 'R$ 0,00'),
];


export default function ModalDetalhesAgendamento(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [agendamento, setAgendamento] = useState('AGENDADO');
  const [openGerarguia, setOpenGerarguia] = useState(false);
  const [openLancarPagamento, setLancarPagamento] = useState(false);
  const [openEditarAgedamento, setEditarAgendamento] = useState(false);
  const [openExcluir, setOpenExcluir] = useState(false);
  const { statusTabela, setStatusTabela } = StateGlobal();
  const [tipoAgendamento, setTipoAgendamento] = useState('agendada')
  const [repeticao, setRepeticao] = useState(0)
  const [typeAlert, setTypeAlert] = useState();
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [id, setId] = useState('');
  const [nomePac, setNomePac] = useState('');
  const [isLoading, setIsLoading] = useState(true)
  const [avatar, setAvatar] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [loginType] = useState(localStorage.getItem('loginType'))

  const [canEditShedule, setCanEditShedule] = useState(localStorage.getItem('doctorId') == 'null' ? false : true);
  const { agendadoData, setAgendadoData, setPos, attResumo, setAttResumo, setLocation } = StateGlobal()

  const history = useHistory()

  function AlterarEstadoGerarguia(state) {
    setOpenGerarguia(state)
  }

  function AlterarLancarPagamento(state) {
    setLancarPagamento(state)
  }

  function AlterarEditarAgendamento(state) {
    setEditarAgendamento(state)
  }

  useEffect(() => {
    if (agendamento === 'AGENDADO') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [agendamento])


  useEffect(() => {

    if (agendadoData) {

      if (agendadoData[0]) {
        setRepeticao(agendadoData[0].schedule_group);
        setAgendamento(agendadoData[0].status);
        setId(agendadoData[0].id)
        setNomePac(agendadoData[0].patient.name);
        setAvatar(agendadoData[0].patient.avatar)




      } else {
        setRepeticao(agendadoData.schedule_group);
        setAgendamento(agendadoData.status);
        setId(agendadoData.id);
        setNomePac(agendadoData.patient.name);
        setAvatar(agendadoData.patient.avatar)

      }
    }
    setIsLoading(false)
    // setOpen(props.open);
    setStatusTabela(true)



  }, [agendadoData])

  useEffect(() => {
    setTimeout(() => {
      setOpen(props.open);
    }, 1000);

  }, [props])



  const closeModal = () => {
    setOpen(false);
    props.onChange(false)
    setStatusTabela(!statusTabela)

  }

  const handleChange = (event) => {
    setAgendamento(event.target.value);
    alterarStatusAgendamento(event.target.value);
  };

  const closeModalExcluir = () => {
    setOpenExcluir(false);
    props.onChange(false)

  }

  const openModalConfirm = () => {
    setOpen(false);
    props.onChange(false)
    setOpenExcluir(true);


  }
  async function alterarStatusAgendamento(value) {
    setLoad(true)
    if (agendadoData) {
      if (agendadoData[0]) {
        setId(agendadoData[0].id)

      } else {
        setId(agendadoData.id)

      }
    }
    await conexao.put(`schedule/status/${id}`,
      {
        "status": value
      })
      .then((response) => {
        setMessage('O status do agendamento foi alterado com sucesso!')
        setTypeAlert('success')
        setLoad(false)
        setTimeout(() => {
          setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          props.refreshTable()
        }, 1500);
      }).catch((error) => {
        setMessage('Erro ao alterar o status do agendamento. Por favor, tente novamente!')
        setTypeAlert('error')
        setLoad(false)
        setTimeout(() => {
          setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        }, 1500);
      })
  }

  function irAtendimento() {
    if (agendadoData[0]) {
      //   const agendamentoId = agendadoData[0].id

      //   conexao.get(`/form/patient/schedule/${agendamentoId}`)
      //   .then((resp) => {
      //     localStorage.setItem('agendamentoId', agendadoData[0].id)
      //     localStorage.setItem('patientID' ,resp.data.data.patient_id)
      //   })
      // } else {
      //   const agendamentoId = agendadoData.id

      //   conexao.get(`/form/patient/schedule/${agendamentoId}`)
      //   .then((resp) => {
      //     localStorage.setItem('agendamentoId', agendadoData.id)
      //     localStorage.setItem('patientID' ,resp.data.data.patient_id)
      //   })
      localStorage.setItem('iniciarAtendimento', agendadoData[0].id)
      history.push(`/atendimento/${agendadoData[0].patient.id}`);
    }

    // setPos(0)
    // setAttResumo(!attResumo)
    // setLocation('agenda')
    // localStorage.setItem('Location', 'agenda')
    // history.push('/atendimento')

  }

  function getTextNomeclatura(nomeclatura){
    let textNomeclatura = {
      'Dr': "o Dr.",
      'Dra': "a Dra.",
      '-': ''
    }
    return textNomeclatura[nomeclatura] ?? ''
  }

  function GoMedicalRecord(){
    window.location.href = `/atendimento/${agendadoData[0].patient.id}`
  }

  return (


    <div className="Modal-agendamento">
      <ThemeProvider theme={theme}>
        <Dialog maxWidth='xl' minWidth='xl' open={open} aria-labelledby="Modal-title-agendamento">

          <div className={classes.modalColor}>

            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
                Detalhes do agendamento
                <CloseIcon onClick={closeModal} />
              </div>
            </DialogTitle>

            {isLoading ?
              <div className="div-load-lista-modelos loading-detalhes"><CircularLoad /></div> :

              agendadoData && agendadoData[0] ?
                <DialogContent>
                  <div className={classes.divDadosPaciente}>
                    <Avatar className={classes.avatar} alt={agendadoData ? agendadoData[0].patient.name : ''}
                      src={avatar ? avatar : "/static/images/avatar/1.jpg"} />
                    <div>
                      <h1 className={classes.namePaciente}>{agendadoData ? agendadoData[0].patient.name : ''}</h1>
                      <p className={classes.telefonePaciente}>{agendadoData ? celular(agendadoData[0].patient.cellphone) : ''}</p>
                      <ReactWhatsapp className={classes.btnReactWhats} number={`+55${agendadoData ? agendadoData[0].patient.cellphone : ''}`} message={`Olá ${agendadoData ? agendadoData[0].patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData[0].date : ''} com ${getTextNomeclatura(agendadoData[0].user.doctor.nomeclatura)}${agendadoData ? agendadoData[0].user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`}>
                        <Button className={classes.btnWhats} type="button">
                          <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta
                        </Button>
                      </ReactWhatsapp>
                    </div>
                  </div>
                  {message ?
                    <div className="Modal-agendamento-alert">
                      <Alert variant="filled" severity={typeAlert}>
                        {message}
                      </Alert>
                    </div>
                    : ''}
                  <div className={classes.divTable}>
                    <div className={classes.dadosConsulta}>
                      <div>
                        <h2 className={classes.dadosConsultaH2}>{agendadoData ? agendadoData[0].date : ''}</h2>
                        {/* // .replace('?','ç') : '' */}
                        {agendadoData && agendadoData[0].description ?
                          <p className={classes.ptabelaAgendamento}>Observação: {agendadoData[0].description}</p>
                          : ''}
                      </div>
                      <div className="load-detalhe-agendamento">
                        <FormControl variant="outlined" className={classes.selectAgendamento}>
                          <Select
                            id="demo-simple-select-outlined"
                            value={agendamento}
                            onChange={handleChange}
                            disabled={load ? true : false}
                          >
                            <MenuItem value="AGENDADO">Agendado</MenuItem>
                            <MenuItem value="CANCELADO">Cancelado</MenuItem>
                            <MenuItem value="REALIZADO">Realizado</MenuItem>
                          </Select>
                        </FormControl>
                        {load ?
                          <CircularLoad />
                          : ''}
                      </div>
                    </div>
                    <p className={classes.ptabelaAgendamento}>Tipo: <strong>{agendadoData ? agendadoData[0].type === 'convenio' ? 'convênio' : agendadoData[0].type : ''}</strong></p>
                    <TableContainer className={classes.tabelaAgendamento} component={Paper}>
                      <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead className={classes.tabelaAgendamento}>
                          <TableRow className={classes.tabelaAgendamento}>
                            <TableCell className={classes.tabelaAgendamento}>Procedimento</TableCell>
                            <TableCell className={classes.tabelaAgendamento} align="center">Quantidade</TableCell>
                            <TableCell className={classes.tabelaAgendamento} align="center">Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {agendadoData ?

                            agendadoData[0].items.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell className={classes.tabelaAgendamento} component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell className={classes.tabelaAgendamento} align="center">{row.quantity}</TableCell>
                                <TableCell className={classes.tabelaAgendamento} align="center">R$ {(row.value * row.quantity).toFixed(2)}</TableCell>
                              </TableRow>
                            ))
                            : ''}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </DialogContent>
                :
                <DialogContent>
                  <div className={classes.divDadosPaciente}>
                    <Avatar className={classes.avatar} alt={agendadoData ? agendadoData.patient.name : ''} src="/static/images/avatar/1.jpg" />
                    <div>
                      <h1 className={classes.namePaciente}>{agendadoData ? agendadoData.patient.name : ''}</h1>
                      <p className={classes.telefonePaciente}>{agendadoData ? celular(agendadoData.patient.cellphone) : ''}</p>
                      <ReactWhatsapp className={classes.btnReactWhats} number={`+55${agendadoData ? agendadoData.patient.cellphone : ''}`} message={`Olá ${agendadoData ? agendadoData.patient.name : ''}, você tem uma consulta ${agendadoData ? agendadoData.date : ''} com o Dr. ${agendadoData ? agendadoData.user.name : ''}. Responda SIM para confirmar e NÃO para cancelar`}>
                        <Button className={classes.btnWhats} type="button">
                          <WhatsAppIcon className={classes.iconBtn} /> Enviar lembrete de consulta
                        </Button>
                      </ReactWhatsapp>
                    </div>
                  </div>
                  {message ?
                    <div className="Modal-agendamento-alert">
                      <Alert variant="filled" severity={typeAlert}>
                        {message}
                      </Alert>
                    </div>
                    : ''}
                  <div className={classes.divTable}>
                    <div className={classes.dadosConsulta}>
                      <div>
                        <h2 className={classes.dadosConsultaH2}>{agendadoData ? agendadoData.date : ''}</h2>
                        {agendadoData && agendadoData.description ?
                          <p className={classes.ptabelaAgendamento}>Observação: {agendadoData.description}</p>
                          : ''}
                      </div>
                      <div className="load-detalhe-agendamento">
                        <FormControl variant="outlined" className={classes.selectAgendamento}>
                          <Select
                            id="demo-simple-select-outlined"
                            value={agendamento}
                            onChange={handleChange}
                            disabled={load ? true : false}
                          >
                            <MenuItem value="AGENDADO">Agendado</MenuItem>
                            <MenuItem value="CANCELADO">Cancelado</MenuItem>
                            <MenuItem value="REALIZADO">Realizado</MenuItem>
                          </Select>
                        </FormControl>
                        {load ?
                          <CircularLoad />
                          : ''}
                      </div>
                    </div>
                    <p className={classes.ptabelaAgendamento}>Tipo: <strong>{agendadoData ? agendadoData.type == 'convenio' ? 'convênio' : agendadoData.type : ''}</strong></p>
                    <TableContainer className={classes.tabelaAgendamento} component={Paper}>
                      <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead className={classes.tabelaAgendamento}>
                          <TableRow className={classes.tabelaAgendamento}>
                            <TableCell className={classes.tabelaAgendamento}>procedimento</TableCell>
                            <TableCell className={classes.tabelaAgendamento} align="center">Quantidade</TableCell>
                            <TableCell className={classes.tabelaAgendamento} align="center">Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {agendadoData ?

                            agendadoData.items.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell className={classes.tabelaAgendamento} component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell className={classes.tabelaAgendamento} align="center">{row.quantity}</TableCell>
                                <TableCell className={classes.tabelaAgendamento} align="center">R$ {currency(row.value * row.quantity)}</TableCell>
                              </TableRow>
                            ))
                            : ''}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </DialogContent>

            }
            <DialogActions className={classes.paddingButton + ""}>
              <DeleteIcon className={classes.btnFooter} onClick={openModalConfirm} />
              <Button className={classes.btnsFooter} onClick={() => {
                AlterarEstadoGerarguia(true)
              }} type="button">
                <LibraryAddIcon className={classes.iconBtn} /> Gerar declaração
              </Button>
              <Button className={classes.btnsFooter} onClick={() => AlterarLancarPagamento(true)} type="button">
                <AttachMoneyIcon className={classes.iconBtn} /> Lançar recebimento
              </Button>
              
              {canEditShedule == true ? 
                <Button className={classes.btnsFooter} onClick={() => {
                  if (Array.isArray(agendadoData)) {//converte obejto em array
                    setEditarAgendamento(true)
                    closeModal()
                  } else {
                    const arr = [agendadoData]
                    setAgendadoData(arr)
                    setEditarAgendamento(true)
                    closeModal()
                  }
                }} type="button">
                  <EditIcon className={classes.iconBtn} /> Editar agendamento
                </Button>
              : 
                <Button className={classes.btnsFooter} type="button">
                  <Block className={classes.iconBtn} /> Selecione um profissional
                </Button>
              }

              {(loginType === 'DOCTOR' && isDisabled != true) && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className=""
                  onClick={irAtendimento}
                  disabled={isDisabled}
                >
                  Iniciar o atendimento
                </Button>
              )}

              {isDisabled &&
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className=""
                  onClick={GoMedicalRecord}
                >
                  Ver Prontuarios
                </Button>
              }

            </DialogActions>
          </div>
        </Dialog>

      </ThemeProvider>

      {openGerarguia ? <ModalGerarGuia open={openGerarguia} nomePac={nomePac} id={id} onChange={AlterarEstadoGerarguia} /> : ''}
      {openLancarPagamento ? <ModalLancarPagamento open={openLancarPagamento} onChange={AlterarLancarPagamento} /> : ''}
      {openEditarAgedamento ? <ModalAgendamento edit={true} dataShedule={agendadoData[0]} open={openEditarAgedamento} onChange={AlterarEditarAgendamento} refreshTable={props.refreshTable} /> : ''}

      {openExcluir ? <ModalExcluir openExcluir={openExcluir} onChange={closeModalExcluir} tipoAgendamento={tipoAgendamento} tipoRepeticao={repeticao} refreshTable={props.refreshTable} /> : ''}
    </div>
  );
}