import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import CircularLoad from '..';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ptBR } from '@material-ui/core/locale';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from "@material-ui/core/MenuItem"
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { StateGlobal } from '../../../ContextAPI/context'
import Autocomplete from '@material-ui/lab/Autocomplete';

import conexao from '../../../Services/api'
import FormHelperText from '@material-ui/core/FormHelperText';
import { validateEmail, validatePasswordLogin, validateName } from '../../Login/Form/validate';
import Alert from '@material-ui/lab/Alert';
import { campovazio } from '../../../Components/ReduxTeste/ValidateForm'


import './style.css'
import CircularLoad from '../../../Components/Load';
// import { clear } from 'forever/lib/forever/cli';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: '0.5rem',
    marginBottom: '0.8rem',
    display: 'flex',
    backgroundColor: 'white',
  },
  IconButton: {
    '& .MuiDialogActions-root-1334': {
      justifyContent: 'center !important',
    }
  },

}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
  },
}, ptBR);

export default function ModalNovoMedico(props) {
  const classes = useStyles();
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(props.open)

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState("");
  const [profissao, setProfissao] = useState([]);
  const [especialidade, setEspecialidade] = useState([]);
  const [idProfissao, setIdProfissao] = useState(0);
  const [idEspecialidade, setIdEspecialidade] = useState(0);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState();
  const [profissaoSelecionada, setProfissaoSelecionada] = useState();
  const [medicoSelecionado, setMedicoSelecionado] = useState();
  const [especialidadeSelecionadaIndex, setEspecialidadeSelecionadaIndex] = useState();
  const [profissaoSelecionadaIndex, setProfissaoSelecionadaIndex] = useState();
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');

  const [nomeclatura, setNomeclatura] = useState('');

  const [changepassword, setChangepassword] = useState(false);

  const [validaDados, setValidaDados] = useState({
    "name": false,
    "email": false,
    // "cpf": false,
    // "rg": false,
    // "phone": false,
    // "cellphone":false,
    // "birthday": false,
    // "street": false,
    // "neighborhood": false,
    // "number": '',
    // "city": false,
    // "state": false,
    // "complement": false,
    // "zip_code": false,
    // "avatar": false,
    "speciality": false,
    "profissao": false,
  })

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const {
    attatendente,
    setAttatendente
  } = StateGlobal();

  function closeModal() {
    clear();
    props.closeModal()
  }

  useEffect(() => {
    getProfissaoCadastro();
    if (props.selectedId) {
      conexao.get('/doctor/byid/' + props.selectedId).then((res) => {
        var doctor = res.data.data;
        setMedicoSelecionado(doctor)
        setValues({ ...values, 'password': '' })
        console.log('MEDICO_SELECIONADO')
        console.log(doctor)
        setNome(doctor.user.name)
        setEmail(doctor.user.email)
        setNomeclatura(doctor.nomeclatura)

        conexao.get(`/speciality/${doctor.speciality.speciality_category_id}`)
          .then((resp) => {
            setEspecialidade(resp.data.data)

            setIdProfissao(doctor.speciality.speciality_category_id)
            setIdEspecialidade(doctor.speciality_id)
            setProfissaoSelecionada(doctor.speciality.speciality_category)
            console.log('PROFISSAO_SELECIONADA')
            console.log(doctor.speciality.speciality_category)
            setEspecialidadeSelecionada({ id: doctor.speciality_id, name: doctor.speciality.name })
            console.log('PROFISSAO_SELECIONADA')
            console.log({ id: doctor.speciality_id, name: doctor.speciality.name })
          }).catch((error) => {

          })

      }).catch((error) => {
      })
    } else {
      setNomeclatura('')
    }

    setOpen(props.open)
    setChangepassword(false)
    console.log('props')
    console.log(props)
  }, [props])

  useEffect(() => {
    getProfissaoCadastro();
  }, [])

  function clear() {
    setNome('')
    setEmail('')
    setProfissaoSelecionada('')
    setEspecialidadeSelecionada('')
  }

  async function getEspecializacaoCadastro(idP) {
    await conexao.get(`/speciality/${idP}`)
      .then((resp) => {
        setEspecialidade(resp.data.data)
      }).catch((error) => {

      })
  }

  function handleInputChangeProfissao(event, value) {
    console.log('handleInputChangeProfissao')
    console.log(event)
    console.log(value)

    setIdProfissao(event.target.value);
    setProfissaoSelecionada({ id: event.target.value, name: event.target.name });

    getEspecializacaoCadastro(event.target.value);

  }

  function handleInputChangeEspecialidade(event, value) {
    console.log('handleInputChangeEspecialidade')
    console.log(event)
    console.log(value)
    setIdEspecialidade(event.target.value);
    setEspecialidadeSelecionada({ id: event.target.value, name: event.target.name });
  }

  function handleSelectChangeNomeclatura(event, value) {
    setNomeclatura(event.target.value);
  }

  async function getProfissaoCadastro() {
    await conexao.get(`/speciality/category`)
      .then((resp) => {
        setProfissao(resp.data.data)
      })

    if (idProfissao) {
      conexao.get(`/speciality/${idProfissao}`)
        .then((resp) => {
          setEspecialidade(resp.data.data)
        }).catch((error) => {

        })
    }
  }

  function createMedico() {
    // validatesx.map((item)=>{      
    //   if(item.value === ''){
    //   item.error = true
    //   }
    // })

    // const arr = validatesx.slice()
    // setvalidatesx(arr)

    if (props.selectedId) {
      if (changepassword && validatePasswordLogin(values.password !== '')) {
        setLoad(true)
        conexao.put('/doctor/update/' + props.selectedId, { name: nome, email: email, senha: values.password, nomeclatura: nomeclatura }).then((res) => {
          setLoad(false)
          setMessage('Editado com sucesso!')
          setTypeAlert('success')

          setTimeout(() => {
            setMessage('')
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida
            // setAttatendente(res.data.data)
            // props.refresh();
            clear()
            closeModal();
          }, 3000);
        }).catch((error) => {
          console.log(error)
          setLoad(false)
          setMessage(error.message)
          setTypeAlert('error')
          setValidaDados({
            ...validaDados,
            'name': !nome ? true : false,
            'email': !email ? true : false,
            'profissao': !idProfissao ? true : false,
            'speciality': !idEspecialidade ? true : false,
          })

          setTimeout(() => {
            setMessage('')
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          }, 3000);
        })
      } else {
        setLoad(true)
        conexao.put('/doctor/update/' + props.selectedId, { name: nome, email: email, password: values.password, speciality_id: idEspecialidade, nomeclatura: nomeclatura}).then((res) => {
          setLoad(false)
          setMessage('Editado com sucesso!')
          setTypeAlert('success')
          setTimeout(() => {
            setMessage('')
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida
            // setAttatendente(res.data.data)
            // localStorage.setItem('doctorName', nome)
            props.refresh();
            clear()
            closeModal();
          }, 3000);
        }).catch((error) => {
          setLoad(false)
          setMessage('Erro ao salvar o profissional.')
          setTypeAlert('error')
          setValidaDados({
            ...validaDados,
            'name': !nome ? true : false,
            'email': !email ? true : false,
            'profissao': !idProfissao ? true : false,
            'speciality': !idEspecialidade ? true : false,
          })

          setTimeout(() => {
            setMessage('')
            //setTypeAlert('') => Alterado por Elton. Propriedade inválida
          }, 3000);
        })
      }
    } else {
      setLoad(true)
      conexao.post('/doctor/create', { name: nome, email: email, password: values.password, nomeclatura: nomeclatura == '' ? 'Dr' : nomeclatura, speciality_id: idEspecialidade, clinic_id: parseInt(localStorage.getItem('clinicId')) }).then((res) => {
        if (!res.data.error) {
          setLoad(false)
          setMessage('Cadastrado com sucesso!')
          setTypeAlert('success')

          var doctorArray = localStorage.getItem('clinicDoctors') ? JSON.parse(localStorage.getItem('clinicDoctors')) : [];
          doctorArray.push(res.data.data)
          localStorage.setItem('clinicDoctors', JSON.stringify(doctorArray))

        } else {
          setMessage(res.data.data)
          setTypeAlert('error')
        }

        setTimeout(() => {
          setMessage('')
          clear()
          props.refresh();
          closeModal();
        }, 3000);
      }).catch((error) => {
        console.log(error)
        setLoad(false)
        setMessage(error.message)
        setTypeAlert('error')

        setValidaDados({
          ...validaDados,
          'name': !nome ? true : false,
          'email': !email ? true : false,
          'profissao': !idProfissao ? true : false,
          'speciality': !idEspecialidade ? true : false,
        })
        setTimeout(() => {
          setMessage('')
          //setTypeAlert('') => Alterado por Elton. Propriedade inválida
        }, 3000);
      })
    }
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (

    <ThemeProvider theme={theme}>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className='tamanho-modal'>
          <DialogTitle id="alert-dialog-title">
            <div className='title-atendente'> Cadastro de Profissional
              <div className='title-fechar'><CloseIcon onClick={closeModal} /></div>
            </div>
          </DialogTitle>

          <DialogContent>
            {message ?
              <div className="Modal-agendamento-alert">
                <Alert variant="filled" severity={typeAlert}>
                  {message}
                </Alert>
              </div>
              : ''}

            <DialogContentText id="alert-dialog-description">
              <TextField
                className={classes.input}
                name='Name'
                value={nome}
                placeholder="Nome"
                onChange={(event) => {
                  setNome(event.target.value);
                  // onchangeform(event)
                }}
                error={validaDados.name}
                helperText={validaDados.name ? 'este campo precisa ser preenchido' : ''}
                type="text"
                required
                id=""
                label="Nome"
                variant="outlined"
              />

              <div className="divCadastrarEditar">

                {profissaoSelecionadaIndex !== '' ?
                  <FormControl variant="outlined" className={classes.formControl} style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-la bel" style={{ width: 'auto', backgroundColor: '#fafafa' }}>
                      Profissão
                    </InputLabel>
                    <Select
                      name='profissao'
                      placeholder="Profissão"
                      id="profissao-outlined"
                      value={profissaoSelecionada ? profissaoSelecionada.id : ''}
                      label="Profissão"
                      onChange={handleInputChangeProfissao}
                      className={classes.input}
                      variant="outlined"
                    >
                      {profissao ?
                        profissao.map((item) => {
                          return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>

                        })
                        : ''
                      }
                    </Select>
                  </FormControl>
                  // <Autocomplete
                  //     id="profissao"
                  //     className={classes.input} 
                  //     options={profissao}
                  //     getOptionLabel={(option) => option.name}
                  //     // value={profissaoSelecionada}
                  //     inputValue={profissaoSelecionada}
                  //     // onChange={(e, value) => setProfissaoSelecionada({
                  //     //   id: value ? value.id : '',
                  //     //   name: value ? value.name : ''
                  //     // })}
                  //     // defaultValue={profissaoSelecionada}
                  //     // defaultValue={{id: 1, name: "Fisioterapeuta"}}
                  //     onInputChange={ handleInputChangeProfissao }
                  //     renderInput={(params) => <TextField {...params}
                  //     error={validaDados.profissao}
                  //     helperText={validaDados.profissao ? 'Campo obrigatório' : ''} label="Profissão" variant="outlined" />}
                  // />
                  : ''}

                {especialidadeSelecionadaIndex !== '' ?
                  <FormControl variant="outlined" className={classes.formControl} style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-la bel" style={{ width: 'auto', backgroundColor: '#fafafa' }}>
                      Especialidade
                    </InputLabel>
                    <Select
                      name='especialidade'
                      placeholder="Especialidade"
                      id="especialidade-outlined"
                      value={especialidadeSelecionada ? especialidadeSelecionada.id : ''}
                      label="Especialidade"
                      onChange={handleInputChangeEspecialidade}
                      className={classes.input}
                      variant="outlined"
                    >
                      {especialidade ?
                        especialidade.map((item) => {
                          return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        })
                        : ''
                      }
                    </Select>
                  </FormControl>
                  // <Autocomplete
                  //     id="especialidade"
                  //     className={classes.input} 
                  //     options={especialidade}
                  //     getOptionLabel={(option) => option.name}
                  //     value={especialidadeSelecionada}
                  //     // inputValue={especialidadeSelecionada}
                  //     // defaultValue={especialidadeSelecionada}
                  //     onInputChange={ handleInputChangeEspecialidade }
                  //     renderInput={(params) => <TextField {...params}
                  //     error={validaDados.speciality}
                  //     helperText={validaDados.speciality ? 'Campo obrigatório' : ''} label="Especialidade" variant="outlined" />}
                  // />
                  : ''}
              </div>

              <TextField
                className={classes.input}
                name='Email'
                value={email}
                placeholder="Digite o seu e-mail"
                onChange={(event) => {
                  setEmail(event.target.value);
                  // onchangeform(event)
                }}
                error={validaDados.email}
                helperText={validaDados.email ? 'este campo precisa ser preenchido' : ''}
                type="text"
                required
                id=""
                label="E-mail"
                variant="outlined"
              />

              <FormControl variant="outlined" className={classes.formControl} style={{ width: "100%" }}>
                <InputLabel style={{ width: 'auto', backgroundColor: '#fafafa' }}>
                  Como deseja ser chamado? 
                </InputLabel>
                <Select
                  name='nomeclatura'
                  placeholder="Como deseja ser chamado?"
                  id="especialidade-outlined"
                  value={nomeclatura ? nomeclatura : ''}
                  label="nomeclatura"
                  onChange={handleSelectChangeNomeclatura}
                  className={classes.input}
                  variant="outlined"
                >
                  <MenuItem value="-">-</MenuItem>
                  <MenuItem value="Dr">Dr</MenuItem>
                  <MenuItem value="Dra">Dra</MenuItem>
                </Select>
              </FormControl>

              {props.selectedId ?
                <Button className='title-atendente' onClick={() => {
                  setChangepassword(!changepassword)
                }}>Deseja redefinir sua  senha  do profissional clique aqui</Button>
                : ''}

              {props.open !== 'visible' && props.open !== 'edit' ?
                <FormControl variant="outlined" style={{ width: '100%' }} error={validaDados.password ? true : false}>
                  <InputLabel htmlFor="outlined-adornment-password" required>Senha</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    required
                    name='password'
                    onChange={(e) => {
                      // onchangeform(e)
                      setValues({ ...values, 'password': e.target.value })
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <div className='teste'>
                          <IconButton
                            className={classes.IconButton}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </div>
                      </InputAdornment>
                    }
                    labelWidth={55}
                  />
                  <FormHelperText id="component-error-text">{validaDados.password ? 'este campo precisa ser preenchido' : ''}</FormHelperText>
                </FormControl>
                : ""}

              {changepassword === true ?
                <FormControl variant="outlined" style={{ width: '100%' }} error={validaDados.password ? true : false}>
                  <InputLabel htmlFor="outlined-adornment-password" required>Senha</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    name='password'
                    required
                    onChange={(e) => {
                      // onchangeform(e)
                      setValues({ ...values, 'password': e.target.value })
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <div className='teste'>
                          <IconButton
                            className={classes.IconButton}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </div>
                      </InputAdornment>
                    }
                    labelWidth={55}
                  />
                  <FormHelperText id="component-error-text">{validaDados.password ? 'este campo precisa ser preenchido' : ''}</FormHelperText>
                </FormControl>
                : ''}

            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={props.closeModal}
            >
              Cancelar
            </Button>

            {!load ?
              <Button onClick={() => { createMedico() }}
                variant="contained"
                className="color-primary"
                autoFocus
              >
                Salvar
              </Button> :
              <Button variant="contained" className="color-primary">
                <CircularLoad />
              </Button>
            }
          </DialogActions>
        </div>
      </Dialog>
    </ThemeProvider>
  );
}