import React, {useState, useEffect} from 'react';
import { ptBR } from '@material-ui/core/locale';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Select from '@material-ui/core/Select';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ModalConfirmExcluir from './ModalConfirmExcluir'
import ModalAgendamento from './ModalAgendamento'

import '../css/Agenda.css'

import { StateGlobal} from '../../../ContextAPI/context'

const useStyles = makeStyles((theme) => ({

  divDados:{
    display: 'flex',
    alignItems: 'center',

  },
  description:{
      color:'#8F8F8F',
  },
  btnsFooter:{
    color: '#8F8F8F',
    backgroundColor: 'white',
  },
  btnFooter:{
    color: '#8F8F8F',
    cursor: 'pointer'
  },
  iconBtn:{
    marginRight: '.25rem'
  },
  dadosConsulta:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalColor:{
    backgroundColor: '#f9f9f9',
  },
  iconClock: {
      fontSize: '10em',
      color:'#bdbdbd',
      marginRight: '20px'
  },
  dadosConsultaH2:{
    color: '#27b8d2',
    fontSize: '1.1em',
  },

}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#E7366A',
      main: '#E7366A',
      dark: '#D93364',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);


export default function ModalDetalhesAgendamento(props) {
  const classes = useStyles();
  const [openBloqueio, setOpenBloqueio] = useState(false);
  const [dados, setDados] = useState(false);
  const [openExcluir, setOpenExcluir] = useState(false);
  const [tipoAgendamento, setTipoAgendamento] = useState('bloqueado')
  const [modalAddAgendamento, setModalAddAgendamento] = useState(false);
  const [modalBlockHorario, setModalBlockHorario] = useState(false);
  const [repeticao, setRepeticao] = useState(0)
  const [open, setOpen] = useState(false);


  const { listaBloqueado} = StateGlobal();

  const {agendadoData} =StateGlobal()

  

  useEffect(() => {
    setOpenBloqueio(props.openBloqueio);
  
  
  }, [props])

  useEffect(() => {
    if(listaBloqueado){
      setDados(listaBloqueado[0])
      setRepeticao(listaBloqueado[0].schedule_group);
    }

  
  }, [listaBloqueado])



  
  const alteraEstadoModal = (modalAddAgendamento) => {
    setModalAddAgendamento(modalAddAgendamento); 
    setOpen(true)
    
    closeModalBloqueio();
  }
 

  const closeModalBloqueio = () => {
    setOpenBloqueio(false);
    props.onChange(false) 
  }

  const openModalConfirm= () => {
    setOpenBloqueio(false);
    setOpenExcluir(true);
    props.onChange(false)
  }

  const closeModalExcluir = () => {
    setOpenExcluir(false);
    props.onChange(false) 
  }

  // const handleChange = (event) => {
  //   setAgendamento(event.target.value);
  // };

  return (
    <div className="Modal-agendamento">
      <ThemeProvider theme={theme}>
        <Dialog maxWidth='xl' minWidth='xl'  open={openBloqueio}  aria-labelledby="Modal-title-agendamento">
          <div className={classes.modalColor}>
            <DialogTitle id="Modal-title-agendamento">
              <div className="title-modal-close">
                Horário Bloqueado
                <CloseIcon onClick={closeModalBloqueio}/>
              </div>
            </DialogTitle>
            
            <DialogContent>
              <div className={classes.divDados}>
                <QueryBuilderIcon className={classes.iconClock}/>
                <div> 
                    <h1 className={classes.description}>{dados.description ?'Descrição: '+dados.description : 'Horário Bloqueado'}</h1>  
                    <h2 className={classes.dadosConsultaH2}>{dados.date}</h2>
                    {/* <h2 className={classes.dadosConsultaH2}>Descrição: {dados.description  ? dados.description : 'Nada informado'}</h2> */}
                </div> 
              </div>
            </DialogContent>
            
            <DialogActions className={classes.paddingButton}>
              <Button className={classes.btnsFooter} onClick={openModalConfirm} type="button">
                  <DeleteIcon className={classes.btnFooter}/> Excluir Horário
              </Button>

              <Button className={classes.btnsFooter} onClick={alteraEstadoModal} type="button">
                <EditIcon className={classes.iconBtn}/> Editar Horário
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </ThemeProvider>
      
      <ModalConfirmExcluir openExcluir={openExcluir} onChange={closeModalExcluir} tipoAgendamento={tipoAgendamento}  tipoRepeticao={repeticao} refreshTable={props.refreshTable}/>
      <ModalAgendamento edit={false} blockedit={open}  dadosbloqueio={dados} open={modalAddAgendamento} onChange={alteraEstadoModal} refreshTable={props.refreshTable}/>
  
    </div>
  );
}