import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function TestesFuncionaisDeTronco({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  return (
    <div>
      <article>
        <p 
          className="titulo-form"
          style={{ margin: '15px 0 5px 0' }}
        >
          BIERING SORENSEN TEST SCORE - Extensores de Tronco 
        </p>

        <TextField
          id="select"
          variant="outlined"
          fullWidth
          type='number'
          placeholder='Tempo'
          value={sportsPhysiotherapyChartAnswers.funcionalTrunkTests.time}
          disabled={sportsPhysiotherapyView}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers( state => ({
              ...state,
              funcionalTrunkTests: {
                ...state.funcionalTrunkTests,
                time: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">segundos</InputAdornment>,
          }}
        />
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 5px 0' }}
        >
          LEG LATERAL REACH TEST - Mobilidade da Coluna
        </p>

        <p className='titulo-form'>Perna DOM:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          type='number'
          placeholder='1º DOM'
          value={sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legDom1}
          disabled={sportsPhysiotherapyView}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers( state => ({
              ...state,
              funcionalTrunkTests: {
                ...state.funcionalTrunkTests,
                legDom1: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
        />
        
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          type='number'
          placeholder='2º DOM'
          value={sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legDom2}
          disabled={sportsPhysiotherapyView}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers( state => ({
              ...state,
              funcionalTrunkTests: {
                ...state.funcionalTrunkTests,
                legDom2: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          style={{
            marginTop: '4px'
          }}
        />

        <TextField
          id="select"
          variant="outlined"
          fullWidth
          type='number'
          placeholder='3º DOM'
          value={sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legDom3}
          disabled={sportsPhysiotherapyView}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers( state => ({
              ...state,
              funcionalTrunkTests: {
                ...state.funcionalTrunkTests,
                legDom3: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          style={{
            marginTop: '4px'
          }}
        />
        <p className={classes.score}>Média DOM: {
          (
            parseInt(sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legDom1)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legDom2)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legDom3)
          )
          || 0
        } cm</p>

        <p className='titulo-form' style={{ marginTop: '10px' }}>Perna NDOM:</p>
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          type='number'
          placeholder='1º NDOM'
          value={sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legNdom1}
          disabled={sportsPhysiotherapyView}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers( state => ({
              ...state,
              funcionalTrunkTests: {
                ...state.funcionalTrunkTests,
                legNdom1: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
        />
        
        <TextField
          id="select"
          variant="outlined"
          fullWidth
          type='number'
          placeholder='2º NDOM'
          value={sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legNdom2}
          disabled={sportsPhysiotherapyView}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers( state => ({
              ...state,
              funcionalTrunkTests: {
                ...state.funcionalTrunkTests,
                legNdom2: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          style={{
            marginTop: '4px'
          }}
        />

        <TextField
          id="select"
          variant="outlined"
          fullWidth
          type='number'
          placeholder='3º NDOM'
          value={sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legNdom3}
          disabled={sportsPhysiotherapyView}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers( state => ({
              ...state,
              funcionalTrunkTests: {
                ...state.funcionalTrunkTests,
                legNdom3: e.target.value
              }
            }))
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          style={{
            marginTop: '4px'
          }}
        />
        
        <p className={classes.score}>Média NDOM: {
          (
            parseInt(sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legNdom1)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legNdom2)
            + parseInt(sportsPhysiotherapyChartAnswers.funcionalTrunkTests.legNdom3)
          )
          || 0
        } cm</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}