import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'


const date = new Date()
const newDate = moment(date).format('DD/MM/YYYY')

const  NameReport = localStorage.getItem("title_form");
const SelectedPatient = localStorage.getItem("SelectedPatient")

function getTextNomeclatura(nomeclatura){
  let textNomeclatura = {
    'Dr': "Dr.",
    'Dra': "Dra.",
    '-': ''
  }
  return textNomeclatura[nomeclatura] ?? ''
}

function PdfByEscalaDeDor( DataGuia , listaImgBase64 , idPaciente ){

    const url = DataGuia.avatar !== null ? DataGuia.avatar : '' 
    let newUrl = '' 
    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      }

      
    toDataURL(url, function(dataUrl) {
      newUrl = dataUrl ? dataUrl : ''

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

const header = [
     
      {text: `${getTextNomeclatura(DataGuia.nomeclatura)}${DataGuia.user.name ? DataGuia.user.name : ''} `, fontSize: 20},
      { columns: [     
        {         
        text: `${DataGuia.street ? DataGuia.street : '' }, ${DataGuia.number? DataGuia.number : ''} \n `+
                `${DataGuia.neighborhood? DataGuia.neighborhood :'' } - CEP ${DataGuia.zip_code? DataGuia.zip_code : ''}\n`+
                `${DataGuia.city? DataGuia.city : ''}/${DataGuia.state ? DataGuia.state : ''} - Brasil`,
        },
        {
            image: `${newUrl? newUrl: '' }`,
            width: 180,        
        }
      ]
      },

      {text: `Relatório de ${NameReport}${idPaciente !== undefined ? ' de '+ SelectedPatient : ''}`, fontSize: 16 , alignment: 'center',margin: [0, 40,0,20] ,  bold: true}

]

const details = [
    
      listaImgBase64.map((item,index) => {
        return(

            {
              image: `${ item.link }`, width: 350, alignment: 'center' , margin: [0, 20, 0, 20]
            }
            
        ) 
      })

    ];

const detalhes = [

  {
      text: idPaciente !== undefined ? `A Escala Visual Analógica – EVA consiste em auxiliar na aferição da intensidade da dor no paciente. Esta escala é um instrumento importante na mensuração da evolução do paciente durante o tratamento de maneira mais fidedigna. Também é útil para analisar se o tratamento está sendo efetivo, quais procedimentos têm surtido melhores resultados, assim como se há alguma deficiência no tratamento, de acordo com o grau de melhora ou piora da dor.
    A EVA pode ser utilizada no início e no final de cada atendimento, registrando o resultado sempre na evolução. Para utilizar a EVA o atendente deve questionar o paciente quanto ao seu grau de dor sendo que 0 significa ausência total de dor e 10 o nível de dor máxima suportável pelo paciente.
    `: '' , fontSize: 12,alignment: 'justify',margin: [0, 0, 0, 0]
  },

]

    

      
    function Rodape(currentPage, pageCount){
        return [
          
            {text: `Documento gerado por Clinic Plus - Sistema para gestão de clínicas e consultórios. \n ${newDate}`, fontSize: 12,margin: [30, 0, 50,0], alignment: 'justify'},
            {text: currentPage + ' / ' + pageCount,alignment: 'right',fontSize: 9,margin: [0, 10, 40, 0]},
                   
        ]
    }

        const docDefinitios = {
            pageSize: 'A4',
            pageMargins: [50, 50, 50, 40],
            content: [header, details, detalhes],
            footer: Rodape,
        }
        
        pdfMake.createPdf(docDefinitios).download()
    
})
}

export default PdfByEscalaDeDor;