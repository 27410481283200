import axios from 'axios';
const api = axios.create({

    baseURL: process.env.REACT_APP_API_URL,
    //baseURL: 'https://api.clinicplusapp.com:8000/api',
    // baseURL: 'http://18.117.247.171/clinicplus-api/public/index.php/api',
    headers: {
        'Authorization': localStorage.getItem('token')  , //TESTE PARA O ERRO 500 DA API
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Accept, Authorization, Content-Type",
        "Access-Control-Allow-Methods": "PUT, POST, DELETE, GET, OPTIONS"
    },
    // xsrfHeaderName: 'X-CSRF-Token',
    // withCredentials: true
});

// const api = axios.create({

//     baseURL: 'http://127.0.0.1:8000/api',

//     headers: {
//         'Authorization': localStorage.getItem('token'),
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }

// });

export default api ;
