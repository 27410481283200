import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    marginTop: '30px'
  },

  checkbox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '35px',
    '& p': {
      color: '#000000DE',
      fontWeight: '500',
      fontSize: '1rem',
    },

    '& div': {
      display: 'flex',
      gap: '20px'
    }
  },

  cait__formControl: {
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiFormLabel-root': {
      marginRight: '50px',
      fontWeight: '500',
      color: '#000000DE',

      '& .Mui-focused': {
        color: '#000000DE',
      }
    },

    '& .MuiFormGroup-root': {
      flexDirection: 'row',

      '& .MuiIconButton-colorSecondary:hover': {
        backgroundColor: 'rgb(0 239 245 / 4%);'
      },

      '& .Mui-checked': {
        color: '#27B8D2',

        '&:hover': {
          backgroundColor: 'rgb(0 239 245 / 4%);'
        }
      },
    }
  },
  questionValue: {
    alignSelf: 'center',
    fontWeight: '500'
  },

  sessionScore: {
    display: 'flex',
    justifyContent: 'end',
    gap: '35px',
    margin: '5px 35px 0 0'
  },

  score: {
    marginTop: '5px',
    fontWeight: '500'
  }
}));