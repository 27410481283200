import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { StateGlobal } from '../../ContextAPI/context'
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularLoad from '../../Components/Load';
import conexao from '../../Services/api';
import ModalExcluir from '../../Components/Modais/ModalExclusao';
import PersonIcon from '@material-ui/icons/Person';
import './Listagem.css';
import { cpf, data } from '../../Components/ReduxTeste/Validate'
import Modal from './Modal/ModalExcluir'
import ModalAtendente from './Modal/ModalAtendente'
import { PinDropSharp } from '@material-ui/icons';

import '../../App.css';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    border: 'none',
    marginBottom: '2rem'
  },
  search: {
    background: '#f1f1f1',
    boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
    borderRadius: '3px',
    height: '45px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    minWidth: '350px',

  },
  iconSearch: {
    color: '#8F8F8F'
  },
  tabelaLancamento: {
    boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%)',
    color: '#8F8F8F',
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#8F8F8F',
    }
  },
}, ptBR);


export default function Atendentes (props) {
  const classes = useStyles();
  // const History = useHistory();
  const [data, setData] = useState([]);
  const [updateTable, setUpdateTable] = useState(true);
  const [typeAlert, setTypeAlert] = useState('');
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  // const [buscacpf, setBuscaCpf] = useState('');
  // const [titulo, setTitulo] = React.useState('');
  // const [descricao, setDescricao] = React.useState('');
  // const [modeloId, setModeloId] = React.useState('');
  // const [optionDelete, setOptionDelete] = React.useState('');
  // const [dataarr, setDataarr] = useState();
  // const [open, setOpen] = useState(false)
  // const [idDelete, setIddelete] = useState('')
  // const [openModalgeral, setOpenModalgeral] = useState('')
  const [openModal, setOpenModal] = useState(false)
  // const [idEditar, setIdEditar] = useState('')
  const [selectedItem, setSelectedItem] = useState('')
  const [name, setName] = useState('')
  // const [control, setControl] = useState()

  // const {
  //   isEditPatient,
  //   setIsEditPatient,
  //   ViewPatient,
  //   setViewPatient,
  //   setAttatendente,
  // } = StateGlobal();

  // useEffect(() => {
  //   if(name===''){
  //     getEmployees()
  //   }

  // },[name]);
  
  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (props.update == true)
    {
      getEmployees();
    }
  }, [props.update]);

  function close() {
    setSelectedItem('')
    setOpenModalDelete(false)
    setOpenModal(false)
  }

  function closeModalAtendente() {
    console.log('closeModalAtendente')
    getEmployees()
    setOpenModal(false)
  }

  function editEmployee(id) {
    console.log('editEmployee')
    console.log(id)
    setSelectedItem(id)
    setOpenModal(true)
  }

  function askDeleteEmployee(id) {
    setSelectedItem(id)
    setOpenModalDelete(true)
  }

  function deleteEmployee() {
    setLoad(true)
    conexao.delete('/employee/' + selectedItem).then((res) => {
      setLoad(false)
      setTypeAlert('success')
      setMessage('Atendente excluído com sucesso!')
      setTimeout(() => {
        alert('', '')
        getEmployees()
        close()
      }, 3000);

    }).catch((error) => {
      setLoad(false)
      alert('Erro ao deletar!', 'error')
      setTimeout(() => {
        alert('', '')
      }, 3000);
    })
  }

  ///////////////aqui busca todos os employees:
  function getEmployees() {
    console.log('GET_EMPLOYEES')
    var url = ''

    if (localStorage.getItem('profileId') == 2)
      url = '/employee/list/clinic/' + localStorage.getItem('clinicId')
    else
      url = '/employee/list/' + localStorage.getItem('selectedDoctor')

    conexao.post(url,{"search":name}).then((res) => {
      setLoad(false)

      console.log('LISTA_ATENDENTES')
      console.log(res.data.data)
  
      setData(res.data.data)

      // if(res.data.data.data.length < 1)
      //   alert('Nenhum cadastro encontrado', 'error')
        
    }).catch((error) => {
      setLoad(false)
      alert('Nenhum cadastro encontrado', 'error')
    })
  }

  const busca = (event) => {
    if (event.key === 'Enter') {
      setLoad(true)
      // getEmployees()
    }
    
    if (event._reactName === 'onClick') {
      setLoad(true)
      getEmployees()
    }
    
    return true
  }

  function alert(message, type)
  {
    setMessage(message)
    setTypeAlert(type)
  }


  return (
    <div className="">
      <FormControl className={clsx(classes.form)}>
        <InputBase
          id="outlined-adornment-weight"
          endAdornment={<InputAdornment position="end">{<IconButton onClick={(e)=>busca(e)}><SearchIcon className={classes.iconSearch}  /></IconButton>}</InputAdornment>}
          //labelWidth={0}
          disabled={load ? true : false}
          onKeyDown={busca}
          onChange={(event)=>setName(event.target.value)}
          placeholder="Busque por um atendente"
          className={clsx(classes.search)}
        />
      </FormControl>

      {message ?
        <div className="Modal-agendamento-alert">
          <Alert variant="filled" severity={typeAlert}>{message}</Alert>
        </div>
      : ''}
      
      {load ?
        <div className="div-load-lista-modelos"><CircularLoad /></div>
      : ''}

      <div>
        
          <Table size="small" aria-label="tabela resumo">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data ?
                  data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.user.name}</TableCell>
                      <TableCell>{row.user.email}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => {
                          editEmployee(row.id)
                        }}><EditIcon /></IconButton>

                        <IconButton onClick={() => {
                          askDeleteEmployee(row.id)
                        }}><DeleteIcon /></IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                  :
                  <TableCell colSpan={3} className="erroDados">Registros não encontrados</TableCell>
              }
            </TableBody>
          </Table>

      </div>
      <Modal open={openModalDelete} titulo={'Excluir Atendente'} typeAlert={typeAlert} message={message} desc={'Tem certeza que deseja excluir este atendente ?'} load={load} close={close} delete={deleteEmployee} />
      <ModalAtendente className="Color-btn-addModelo" open={openModal} item={selectedItem} close={closeModalAtendente} />
    </div>
  );
}