import React, { useState, useEffect } from 'react';
import Header from './header';
import TabelaAgendameto from './TabelaAgendamento'
import './css/Tabela.css';
import ModalDetalhes from "../Modais/ModalDetalhesAgendamento";
import ModalBloqueado from "../Modais/ModalBloqueado";
import conexao from "../../../Services/api";
import CircularLoad from '../../../Components/Load';
import { StateGlobal } from '../../../ContextAPI/context';
import { browserName } from 'react-device-detect';


export default function Tabela(props) {

    const [open, setOpen] = useState(false);
    const [openBloqueio, setOpenBloqueio] = useState(false);
    const [id, setId] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState();
    const [listaAgenda, setListaAgenda] = useState('');
    const [scheduleDoctor, setScheduleDoctor] = useState('');
    const { setAgendadoData } = StateGlobal();
    const { statusTabela , setStatusTabela } = StateGlobal();
    const { setListaBloqueado } = StateGlobal();
    const [isLoading, setIsLoading] = useState(true)

    function modalteste(e) {
       var bloqueado
       var idBloqueio
      
       e.target.classList.forEach(item => {
            if(item == 'agendamento-bloqueado'){
                bloqueado = true
                idBloqueio = e.target.dataset.idAgendaBloqueada
            }
        });
        e.target.offsetParent.classList.forEach(item => {
            if(item == 'agendamento-bloqueado'){
                bloqueado = true
                idBloqueio = e.target.offsetParent.dataset.idAgendaBloqueada
            }
        });

        if(!bloqueado){
            if (e.target.offsetParent.dataset.idAgenda) {
                setId(e.target.offsetParent.dataset.idAgenda)
                setOpen(true)
                listaDetalhes(e.target.offsetParent.dataset.idAgenda)
            }
            
            var agenda = document.getElementsByClassName('e-appointment-border'); 
     
            if(agenda[0]){
                agenda[0].addEventListener('click', chamaModalId(e));
            }
        }else{          
            setOpenBloqueio(true)
            listaDetalhes(idBloqueio)
        }
    }

    function chamaModalId(e){
        if (e.target.dataset.idAgenda) {
            console.log('chamaModalId')
            console.log(e.target.dataset)
            setId(e.target.dataset.idAgenda)
            setOpen(true)
            listaDetalhes(e.target.dataset.idAgenda)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('selectedDoctor') != 'null')
            setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')))

        filtroApi()
    },[]);

    function editaData(data){
        let espaco = data.indexOf(' ')
        const h = data.slice(espaco+1)
        const t = data.slice(0, espaco)
        const arrT = t.split('-')
        const ano = parseInt(arrT[0])
        const mes = parseInt(arrT[1])
        const dia = parseInt(arrT[2])
        const dataCompleta = `${ano}, ${mes}, ${dia}, ${h}`
        
        return dataCompleta
    }

    function filtroApi(patientName = null) {
        var userId = localStorage.getItem('selectedUser')
        var doctorId = JSON.parse(localStorage.getItem('selectedDoctor'))
        var clinicId = localStorage.getItem('clinicId')

        if (userId || clinicId)
        {
            // var url = doctorId ? `/schedule/events/${userId}` : `/schedule/clinic/${clinicId}`;
            var url = '/schedule/events/filter';

            var data = {
                user_id: parseInt(localStorage.getItem('selectedUser')),
                patient: patientName || null
            };
            
            setIsLoading(false)
            console.log('FILTER')
            conexao.post(url, data).then((resp) => {
                const data = resp.data.data
                console.log(data)
                let arr = []
                data.map((item) => {
                    if(item.status == "BLOQUEADO"){
                        let dataItem = {   
                            id: item.id,
                            type: item.status,
                            Subject: 'Horário bloqueado',
                            Location: item.description,
                            StartTime: browserName === "Safari" || browserName === "Mobile Safari" ? new Date((item.started_at).replace(/ /g,"T")) : new Date((item.started_at)),
                            EndTime: browserName === "Safari" || browserName === "Mobile Safari" ? new Date((item.finished_at).replace(/ /g,"T")) : new Date((item.finished_at)),
                        }                       
                        arr.push(dataItem)
                    } else {
                        let dataItem = {
                            id: item.id,
                            type: item.status,
                            Subject: item.patient.name,
                            StartTime: browserName === "Safari" || browserName === "Mobile Safari" ? new Date((item.started_at).replace(/ /g,"T")) : new Date((item.started_at)),
                            EndTime: browserName === "Safari" || browserName === "Mobile Safari" ? new Date((item.finished_at).replace(/ /g,"T")) : new Date((item.finished_at)),
                        }
                        arr.push(dataItem)
                    }
                }) 
                setListaAgenda(arr)
            }).catch((error) => {
            })
        }
    }

    function listaDetalhes(id){
        conexao.get(`/schedule/${id}`).then((resp) => {
        
        // var days = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];        
        // var months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

        // resp.data.data.forEach(function(item) {
        //     var date = new Date(item.started_at);
        //     item.date = days[date.getDay()] + ', ' + date.getDate() + ' de ' + months[date.getMonth()] + ' de ' + date.getFullYear();
        // })

        if (resp.data.data[0].status !== 'BLOQUEADO') {
                setAgendadoData(resp.data.data)
            } else {
                setListaBloqueado(resp.data.data)
            }        

        }).catch((error)=>{
      
        })
    } 


    function closemodal() {
        setOpen(false)
        setStatusTabela(true)
    }

    function closeModalBloqueio() {
        setOpenBloqueio(false)
    }

    function refreshTable(patientName = null) {
        console.log('REFRESH TABLE')
        filtroApi(patientName)
    }

    const { count, setCount } = StateGlobal();

    return (
        <div>
            <Header refreshTable={refreshTable}/>
            <div className="container-tabela">
                {isLoading ? 
                    <div className="div-load-lista-modelos"><CircularLoad /></div>
                    :
                    <TabelaAgendameto modalteste={modalteste} agenda={listaAgenda}/>
                }
            </div>

            <ModalDetalhes open={open} scheduleDoctor={scheduleDoctor} onChange={closemodal} refreshTable={refreshTable}/>
            <ModalBloqueado openBloqueio={openBloqueio} onChange={closeModalBloqueio} refreshTable={refreshTable}/>
        </div>
    );

}