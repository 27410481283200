import React, { useContext, useState } from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'
import { withStyles } from '@material-ui/core/styles';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#27B8D2',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Cait({ path }) {
  const classes = useStyles();

  const {
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);


  function handleCheckbox(e){
    const { checked, name } = e.target

    let title = name.split('-')[0]
    let legSide = name.split('-')[1]
    let question = name.split('-')[2]
    let questionValue = parseInt(name.split('-')[3])
    let cait = {}

    if ( legSide === 'right' ) {
      cait = {
        ...sportsPhysiotherapyChartAnswers.cait,
        [title]: {
          ...sportsPhysiotherapyChartAnswers.cait[title],
          right: {
            ...sportsPhysiotherapyChartAnswers.cait[title].right,
            [question]: checked
          }
        }
      }
    } else {
      cait = {
        ...sportsPhysiotherapyChartAnswers.cait,
        [title]: {
          ...sportsPhysiotherapyChartAnswers.cait[title],
          left: {
            ...sportsPhysiotherapyChartAnswers.cait[title].left,
            [question]: checked
          }
        }
      }
    }

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      scoreValue: {
        ...state.scoreValue,
        [legSide]:
          checked 
          ? state.scoreValue[legSide] + questionValue 
          : state.scoreValue[legSide] - questionValue
      }
    }))

    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      questionsScore: {
        ...state.questionsScore,
        [`${title}_${legSide}`]: 
          checked 
          ? state.questionsScore[`${title}_${legSide}`] + questionValue 
          : state.questionsScore[`${title}_${legSide}`] - questionValue
      }
    }))
    
    setSportsPhysiotherapyChartAnswers(state => ({
      ...state,
      cait
    }))
  }

  return (
    <div>
      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          1. Sinto dor no tornozelo:
        </p>
        
        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-right-never-5"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-left-never-5"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando pratica esportes</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.right.sportsPractice}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-right-sportsPractice-4"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.left.sportsPractice}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-left-sportsPractice-4"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando corro em superfícies irregulares</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.right.runOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-right-runOnUnevenSurfaces-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.left.runOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-left-runOnUnevenSurfaces-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando corro em superfícies planas</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.right.runOnFlatSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-right-runOnFlatSurfaces-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.left.runOnFlatSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-left-runOnFlatSurfaces-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando ando em superfícies irregulares</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.right.walkOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-right-walkOnUnevenSurfaces-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.left.walkOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-left-walkOnUnevenSurfaces-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando ando em superfícies planas</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.right.walkOnFlatSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-right-walkOnFlatSurfaces-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.anklePain.left.walkOnFlatSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="anklePain-left-walkOnFlatSurfaces-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.anklePain_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.anklePain_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          2. Sinto INSTABILIDADE no tornozelo:
        </p>
        
        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-right-never-4"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-left-never-4"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Às vezes quando pratico esportes</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.right.sometimesSportsPractice}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-right-sometimesSportsPractice-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.left.sometimesSportsPractice}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-left-sometimesSportsPractice-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Frequentemente quando pratico esportes</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.right.frequentlySportsPractice}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-right-frequentlySportsPractice-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.left.frequentlySportsPractice}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-left-frequentlySportsPractice-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Às vezes durante atividades</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.right.sometimesDuringActivities}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-right-sometimesDuringActivities-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.left.sometimesDuringActivities}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-left-sometimesDuringActivities-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Frequentemente durante atividades diárias</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.right.frequentlyDuringDailyActivities}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-right-frequentlyDuringDailyActivities-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstability.left.frequentlyDuringDailyActivities}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstability-left-frequentlyDuringDailyActivities-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ankleInstability_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ankleInstability_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          3. Quando me viro BRUSCAMENTE, sinto INSTABILIDADE no tornozelo:
        </p>
        
        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-right-never-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-left-never-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Às vezes quando corro</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.right.sometimesWhenIRun}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-right-sometimesWhenIRun-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.left.sometimesWhenIRun}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-left-sometimesWhenIRun-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Frequentemente quando corro</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.right.frequentlyWhenIRun}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-right-frequentlyWhenIRun-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.left.frequentlyWhenIRun}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-left-frequentlyWhenIRun-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando ando</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.right.whenIWalk}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-right-whenIWalk-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.suddenAnkleInstability.left.whenIWalk}
                  onChange={e => handleCheckbox(e)}
                  name="suddenAnkleInstability-left-whenIWalk-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.suddenAnkleInstability_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.suddenAnkleInstability_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          4. Quando desço escadas, sinto INSTABILIDADE no tornozelo:
        </p>
        
        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-right-never-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-left-never-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Se for rapidamente</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.right.ifComeDownQuickly}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-right-ifComeDownQuickly-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.left.ifComeDownQuickly}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-left-ifComeDownQuickly-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Ocasionalmente</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.right.occasionally}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-right-occasionally-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.left.occasionally}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-left-occasionally-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Sempre</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.right.always}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-right-always-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ladderAnkleInstability.left.always}
                  onChange={e => handleCheckbox(e)}
                  name="ladderAnkleInstability-left-always-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ladderAnkleInstability_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ladderAnkleInstability_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          5. Sinto INSTABILIDADE no tornozelo quando fico num só pé:
        </p>
        
        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.oneFootAnkleInstability.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="oneFootAnkleInstability-right-never-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.oneFootAnkleInstability.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="oneFootAnkleInstability-left-never-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Na ponta do pé</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.oneFootAnkleInstability.right.onTiptoe}
                  onChange={e => handleCheckbox(e)}
                  name="oneFootAnkleInstability-right-onTiptoe-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.oneFootAnkleInstability.left.onTiptoe}
                  onChange={e => handleCheckbox(e)}
                  name="oneFootAnkleInstability-left-onTiptoe-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Com o pé inteiro no chão</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.oneFootAnkleInstability.right.fullFootOnFloor}
                  onChange={e => handleCheckbox(e)}
                  name="oneFootAnkleInstability-right-fullFootOnFloor-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.oneFootAnkleInstability.left.fullFootOnFloor}
                  onChange={e => handleCheckbox(e)}
                  name="oneFootAnkleInstability-left-fullFootOnFloor-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.oneFootAnkleInstability_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.oneFootAnkleInstability_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          6. Sinto INSTABILIDADE no tornozelo quando
        </p>
        
        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-right-never-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-left-never-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Pulo de um lado para o outro numa só perna</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.right.jumpFromSideToSide}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-right-jumpFromSideToSide-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.left.jumpFromSideToSide}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-left-jumpFromSideToSide-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando pulo no mesmo lugar numa só perna</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.right.jumpInTheSamePlace}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-right-jumpInTheSamePlace-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.left.jumpInTheSamePlace}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-left-jumpInTheSamePlace-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Quando pulo com as duas pernas</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.right.jumpWithBothLegs}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-right-jumpWithBothLegs-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen.left.jumpWithBothLegs}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen-left-jumpWithBothLegs-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ankleInstabilityWhen_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ankleInstabilityWhen_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          7. Sinto INSTABILIDADE no tornozelo quando
        </p>
        
        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-right-never-4"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-left-never-4"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Corro em superfícies irregulares</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.right.runOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-right-runOnUnevenSurfaces-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.left.runOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-left-runOnUnevenSurfaces-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Corro lentamente em superfícies irregulares</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.right.runSlowlyOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-right-runSlowlyOnUnevenSurfaces-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.left.runSlowlyOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-left-runSlowlyOnUnevenSurfaces-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Ando em superfícies irregulares </p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.right.walkOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-right-walkOnUnevenSurfaces-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.left.walkOnUnevenSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-left-walkOnUnevenSurfaces-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Ando em uma superfície plana</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.right.walkOnFlatSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-right-walkOnFlatSurfaces-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.ankleInstabilityWhen2.left.walkOnFlatSurfaces}
                  onChange={e => handleCheckbox(e)}
                  name="ankleInstabilityWhen2-left-walkOnFlatSurfaces-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ankleInstabilityWhen2_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.ankleInstabilityWhen2_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          8. TIPICAMENTE quando começo a torcer o tornozelo, consigo parar
        </p>
        
        <div className={classes.checkbox}>
          <p>Imediatamente</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.right.immediately}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-right-immediately-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.left.immediately}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-left-immediately-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Frequentemente</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.right.frequently}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-right-frequently-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.left.frequently}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-left-frequently-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Às vezes</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.right.sometimes}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-right-sometimes-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.left.sometimes}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-left-sometimes-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Nunca</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.right.never}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-right-never-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.left.never}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-left-never-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Nunca torci o tornozelo</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.right.neverSprainedAnkle}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-right-neverSprainedAnkle-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.tipicallyWhenSprainAnkle.left.neverSprainedAnkle}
                  onChange={e => handleCheckbox(e)}
                  name="tipicallyWhenSprainAnkle-left-neverSprainedAnkle-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.tipicallyWhenSprainAnkle_right}</p>
          <p className={classes.score}>E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.tipicallyWhenSprainAnkle_left}</p>
        </div>
      </article>

      <article className={classes.checkboxContainer}>
        <p
          className="titulo-form"
          style={{ margin: '15px 0 0 0' }}
        >
          9. Após uma entorse TÍPICO, meu tornozelo volta o normal
        </p>
        
        <div className={classes.checkbox}>
          <p>Quase imediatamente</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.right.almostImmediately}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-right-almostImmediately-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.left.almostImmediately}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-left-almostImmediately-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Em menos de um dia</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.right.lessThanADay}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-right-lessThanADay-2"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.left.lessThanADay}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-left-lessThanADay-2"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Em 1 a 2 dias</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.right.oneTwoDays}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-right-oneTwoDays-1"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.left.oneTwoDays}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-left-oneTwoDays-1"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Em mais de 2 dias</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.right.moreThan2Days}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-right-moreThan2Days-0"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.left.moreThan2Days}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-left-moreThan2Days-0"
                />
              }
            />
          </div>
        </div>

        <div className={classes.checkbox}>
          <p>Nunca torci o tornozelo</p>

          <div>
            <FormControlLabel
              label="Direito"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.right.neverSprainedAnkle}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-right-neverSprainedAnkle-3"
                />
              }
            />

            <FormControlLabel
              label="Esquerdo"
              control={
                <GreenCheckbox 
                  disabled={sportsPhysiotherapyView}
                  checked={sportsPhysiotherapyChartAnswers.cait.afterSprainNormalAnkle.left.neverSprainedAnkle}
                  onChange={e => handleCheckbox(e)}
                  name="afterSprainNormalAnkle-left-neverSprainedAnkle-3"
                />
              }
            />
          </div>
        </div>

        <div className={classes.sessionScore}>
          <p className={classes.score}>
            D.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.afterSprainNormalAnkle_right}
          </p>
          <p className={classes.score}>
            E.Escore: {sportsPhysiotherapyChartAnswers.questionsScore.afterSprainNormalAnkle_left}
          </p>
        </div>
      </article>

      <article>
        <p style={{ margin: '20px 0 0 0' }} className={classes.score}>
          Escore: D: {sportsPhysiotherapyChartAnswers.scoreValue.right}pts - E: {sportsPhysiotherapyChartAnswers.scoreValue.left}pts
        </p>
        <p className={classes.score}>(0 = Maior INSTABILIDADE / 30 = Maior ESTABILIDADE)</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}