import React, { useState, useEffect } from 'react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import ListaMedicos from './Medicos'
import ModalAdd from './Modal/ModalNovoMedico'
import conexao from '../../Services/api'

import { StateGlobal } from '../../ContextAPI/context'

import './Listagem.css';
import '../../App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 0px 0px 0px gray",
  },

}));

const theme = createTheme({
  palette: {
    secondary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    },
    primary: {
      light: '#27b8d2',
      main: '#27b8d2',
      dark: '#229fb5',
      contrastText: '#fff',
    }
  },
}, ptBR);

function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabPesquisa(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TelaProfissionais() {

  const [dataarr, setDataarr] = useState();
    const [medicos, setMedicos] = useState([]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedId, setSelectedId] = useState('')
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
 
  const {
    idEditProntuario,
    setIdEditProntuario,
    setTituloSessaoAtual,
    setMontagem,
    setSessaostate,
  } = StateGlobal();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function openModal() {
    setOpen(true)
  }

  function closeModal() {
    setSelectedId('')
    setOpen(false)
  }

  function refresh() {
    window.location.reload()
  }

  function selectId(id) {
    setSelectedId(id)
  }

  const novoModelo = () => {
    setIdEditProntuario(false)
    setMontagem([])
    setSessaostate([])
    setTituloSessaoAtual('Título da seção prontuário')

    history.push('/novo-modelo');
  }

  return (
    <div className={localStorage.getItem('containerClass')}>
      <div class="card">
        <div class="card_header">
          <h1>Profissionais</h1>
          <Button variant="contained" color="primary" className="Color-btn-addModelo color-primary" onClick={()=> setOpen(true)}>
            <AddIcon /> Novo Profissional   
              
          </Button>
        </div>
        <AppBar position="static">
        </AppBar>

        <ListaMedicos openModal={openModal} selectId={selectId}/>
      </div>
      <ModalAdd selectedId={selectedId} open={open} closeModal={closeModal} refresh={refresh}  />  
    </div>
  );
}