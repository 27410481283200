import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import conexao from '../../../../../Services/api';
import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Delete"
import { StateGlobal } from '../../../../../ContextAPI/context'
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
 import '../css/inputs.css';
 

export default function InputImagem({id ,title ,required, answer, imageID}) {

  const [value, setValue] = React.useState('1');
  const [checked, setcheck] = useState(false)
  const [titulocampo , setTituloCampo] =useState();
  const [label, setLabel] = useState("Selecione uma imagem");
  const [imgUrl, setImgUrl] = useState('');
  const [imgName, setImgName] = useState('')
  const [images, setImages] = useState([])
  const [req, setReq] = useState(required);
  const [error, setError] = useState(false)

  const { Answer, setAnswer, respEdit, setMontagemProntuario, MontagemProntuario, PosProntuario, setpos, respView, validaProntuario, setvalidaProntuario, validation, setValidation,attValidation, setAttValidation, attEncerraError } = StateGlobal();

 
  // useEffect(()=>{

  //   Answer.map((item, index)=>{
  //     if(item.question_id === id){
  //       item.answer = imgUrl
  //         if(req){
  //           if( !Answer[index].answer ){
  //             setError(true)
  //           }
  //           else{
  //             setError(false)
  //         }
  //       }        
  //     }
  //   })
  // }, [imgUrl])
  useEffect(() => {
    console.log('USEEFFECT_INICIAL')
    console.log(id)

    Answer.map((item, index) => {
      if(item.question_id === id) {
        console.log('IMAGES ATUALIZADAS')
        console.log(Answer[index])
        if (Array.isArray(Answer[index].answer))
        {
          Answer[index].answer = '';
        } else {
          if (Answer[index].answer)
            setImages(Answer[index].answer.split(','))
        }
      }
    })
    
  }, []) 

  useEffect(() => {
    Answer.map((item, index) => {
      if(item.question_id === id) {
        Answer[index].answer = images.join(',')
        // console.log('ANSWERS ATUALIZADAS')
        // console.log(Answer[index])
        if(req) {
          if( !Answer[index].answer ) {
            setError(true)
          }
          else {
            setError(false)
          }
        }        
      }
    })
  }, [images])

  useEffect(()=>{
    // if(answer) {
    //   if(answer[0]) {
    //     if (answer[0].image) {
    //       console.log('setImages')
    //       setImages(answer[0].image.split(','))
    //     }
    //     // setLabel(answer[0].image)
    //   } else {
    //     setError(false)
    //   }

    //   Answer.map((item)=>{
    //     if(item.question_id === id) {
    //       console.log('ACHOU!')
    //       console.log(item)
    //       console.log(images)
    //       item.answer = (images) ? images.join(',') : ''
    //     }
    //   })
    // }  
  }, [id])

  // useEffect(()=>{
  //   console.log(answer)
  //   if(answer){
  //     if(answer[0]){
  //       setImgUrl(answer[0].image)
  //       setLabel(answer[0].image)
  //     }else{
  //       setError(false)
  //     }
  //     Answer.map((item)=>{
  //       if(item.question_id === id){
  //         item.answer = imgUrl
  //       }
  //     })
  //   }  
  // }, [id])

  function onClickImg(e) {
    var files = e.target.files;

    if (files.length > 0) {      
      Array.from(files).forEach(function(file) {
        
        var reader = new FileReader();
        reader.onloadend = function(e) {
          enviaImagem(e.target.result)
        }
          
        // Verificamos o tamanho de todos
        if(file.size > 10000000) {
          alert("Um ou mais documentos possuem mais de 10MB, por favor verifique o tamanho dos arquivos.");
          return;
        };

        reader.readAsDataURL(file);

      })
    } 

  }

  function enviaImagem(img) {
    conexao.post('/image/upload', {
      path:  "form",
      photo: img
    }).then((res) => {
      setImgUrl(res.data.data)
      setImages(images => [...images, res.data.data])
      console.log(res.data)
      if (res.data.data) {
        Answer.map((item, index) => {
          if(item.question_id === id) {
            item.answer = item.answer + res.data.data.join(',')
          }
        })
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  function removeImg(index) {
    let newArray = images.filter((item, i) => {return i !== index});
    setImages(newArray)
  }

  useEffect(() => {
    if(validation) {
      validation.forEach((step, index)=>{
        step.components.forEach((comp, i)=>{        
          if(comp.id == id){
            setError(validation[index].components[i].error)
          }
        })
      })
    }

}, [attEncerraError])

useEffect(()=>{
  if(validation) {      
    validation.forEach((step, index)=>{
      step.components.forEach((comp, i)=>{        
        if(comp.id == id){
          validation[index].components[i].error = error 
        }
      })
    })
    setAttValidation(!attValidation)
  }
}, [error])

function deletar(){
  setImgUrl(''); 
  setLabel('Selecione uma imagem'); 
  setImgName('')
  document.getElementById(imageID).value = ''
}

     
   return (
       <div className="envolve-inputImagem">
        <p className="titulo-form">{title}</p>
        <div className="img-input img-input-pront">
          {!respView ?
            <>
              <div className="image-content-pront">
                <TextField 
                  disabled 
                  id="imagem" 
                  name='image' 
                  value={imgName} 
                  label={label} 
                  variant="outlined" 
                  className={["img-input-pront", error ? 'errorBorder' : '']}
                />
                <label 
                  className="upload upload-pront" 
                  for={imageID} 
                >
                  Selecionar Imagem
                </label>
                <input id={imageID} type='file' onChange={onClickImg} multiple></input>
              </div>
              <div class="help-text">Tamanho máximo dos arquivos: 10MB</div>
            </>
          : ''}
          <div className="thumb-container">
            {images ? images.map((item, index) => {
                  return (
                    <div className="img-thumb" key={index}>
                      <a href={item} target="_blank" rel="noreferrer">
                        {item.substr(-3) != 'pdf' ?
                          <img src={item} />
                        :
                          <span>PDF</span>
                        }
                      </a>
                      {!respView ?
                        <Button variant="contained"  className="btn-del" onClick={()=>{removeImg(index)}}>
                          <DeleteOutlineOutlinedIcon/>
                        </Button> 
                      : ''
                      }
                    </div>
                  )
            }) : ''}
          </div>
          {/* { imgUrl ? 
          <div className="visualizar-imagem-pront">
              <LaunchIcon/>
              <small><a href={imgUrl} target="_blank" rel="noreferrer">Visualizar documento enviado</a></small>
              {!respEdit ? 
              <Button variant="contained"  className="color-primary btn-delete-img" onClick={()=>{deletar()}}>
                  <DeleteOutlineOutlinedIcon/>
              </Button> 
              : ''}
          </div>
          : ''
          } */}

         </div>
         <div style={{ color: 'red', width: '100%', marginTop: '0px', fontSize: '15px' }}>
          { error === true ? '* Preencha o campo' : ''}  
        </div>
     </div>  
  )
  }