import React, { useCallback, useEffect, useState , useRef} from "react";
import { PieChart, Pie, Cell } from "recharts";
import "../../../Components/style/style.css"
import { StateGlobal } from "../../../../../ContextAPI/context"
import { toPng } from 'html-to-image';



export default function GraficoInterpaciente({dataInfoGrafico}) {
    const { listaImgBase64, setListaImgBase64 } = StateGlobal()

  
    const ref = useRef({HTMLDivElement})

    const onButtonClick = useCallback(() => {
      if (ref.current === null) {
        return 
      }
      let arr = []
      
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          // link.download = 'my-image-name.png'
          link.href = dataUrl
          setListaImgBase64(oldstate => [...oldstate,{link: link.href }])
          
        })
        .catch((err) => {
          console.log(err)
        })
    }, [ref])

    useEffect(() => {
        transform()
        setTimeout(() => {
          onButtonClick()
        }, [1000]);
        
      }, [])

    const [Data, setData] = useState()

    const COLORS = [
        "#7DB3DC",
        "#036EA2",
        "#485779",
        "#018064",
        "#34AD3A",
        "#E7E92B",
        '#F8B302',
        '#E47617',
        '#E05414',
        '#E02617',
        '#9D2725'
    ];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
    }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text style={{fontSize: '16px', fill: '#000000'   }}
        x={x}
        y={y}
        fill="#fff"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="center" 
        >
        {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
    };

  function transform () {

    let data = dataInfoGrafico.map((item,index)=> {

      return(
        {name: item.text, value: Number(item.total)}
      )

    })

    setData(data)

    
  }

  useEffect(() => {

    transform()

  }, [])


  return (

<div className='Grade-Container-Graficos-interpaciente' ref={ref}> 
  <div className="Title-Grafico2"> <p >{"Entre os pacientes"}</p></div> 
    <div className="Grade-width-3">
      <div className="Container-Informações-Grafico">
        
        <div style={{display: 'flex',flexDirection: 'row'}}>

        <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {Data && Data.map((entry, index) => {
                        return(
                                <div style={{width: '20px', height: "20px", background: COLORS[index % COLORS.length], margin: '8px 0px'}}/>
                            )
                        })}
                </div>
                <div className="Container-Informações">
                    {Data && Data.map(( item , index) => {
                    return(
                    <div className="Informações">
                    {item.name} 
                    </div>
                    )
                    })}
                </div>
            </div>

        <div >
          <PieChart width={250} height={300}>
            <Pie
              isAnimationActive={false}
              data={Data && Data}
              cx={120}
              cy={150}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={120}
              fill="#8884d8"
              dataKey="value"
            >
              {Data && Data.map((entry, index) => {
                return(
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                )
                })}
          </Pie>
        </PieChart>
      </div>

            
        </div>
    </div>
  </div>

</div>


)};