import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
} from "react";
// import { BarChart, Bar, XAxis, Label, LabelList, YAxis } from "recharts";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import { translate } from "../../../../../Components/Tradutor";
import { toPng } from "html-to-image";
import { StateGlobal } from "../../../../../ContextAPI/context";
import ReguadorDeDor from "../../../../../Assets/Images/ImagensProntuario/reguador_de_dor.png";

export default function Grafico({ title, value }) {
  // const [listImgBase64, setListImgBase64] = useState([])
  const { listaImgBase64, setListaImgBase64 } = StateGlobal();

  const ref = useRef({ HTMLDivElement });
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    let arr = [];
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        // link.download = 'my-image-name.png'
        link.href = dataUrl;
        setListaImgBase64((oldstate) => [
          ...oldstate,
          { link: link.href, title: title, value: value },
        ]);
      })
      .catch((err) => {});
  }, [ref]);

  useEffect(() => {
    // transform()
    setInterval(() => {
      translate();
    }, 100);
    setTimeout(() => {
      onButtonClick();
    }, [2500]);
  }, []);

  //     const toPercent = (decimal: number, fixed: number = 0) =>
  //     `${(decimal * 100).toFixed(fixed)}%`;

  //     const getPercent = (value: number, total: number) => {
  //     const ratio = total > 0 ? value / total : 0;

  //     return toPercent(ratio, 1);
  // };

  return (
    <div className="Grade-Container-Graficos" ref={ref}>
      <div className="Title-Grafico2">
        {" "}
        <p>{title}</p>
      </div>
      <div className="Grade-width">
        <div className="Container-Informações-Grafico">
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            {title === "Diferença diagonal" ? (
              <div
                style={{ width: "100%", paddingLeft: "40px", fontSize: "14px" }}
              >
                <p>mm</p>
              </div>
            ) : (
              <div
                style={{ width: "100%", paddingLeft: "60px", fontSize: "14px" }}
              >
                <p>%</p>
              </div>
            )}

            <LineChart
              width={500}
              height={450}
              data={value}
              margin={{
                top: 10,
                right: 20,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="data" />
              {/* <YAxis /> */}

              {title === "Diferença diagonal" ? (
                <YAxis type="number" domain={[0, 40]} scale="linear" />
              ) : title === "Índice cranial" ? (
                <YAxis type="number" scale="linear" domain={[60, 100]} />
              ) : title === "Índice assimetria" ? (
                <YAxis type="number" scale="linear" domain={[0, 15]} />
              ) : (
                <YAxis />
              )}

              <Tooltip />
              <Line
                strokeWidth={3}
                dot={{
                  stroke: "black",
                  fill: "black",
                  strokeWidth: 6,
                  r: 1,
                  strokeDasharray: "",
                }}
                // type="monotone"

                dataKey="answer"
                stroke="#27b8d2"
                fill="#27b8d2"
              />
            </LineChart>

            {/* </ResponsiveContainer> */}
          </div>
        </div>
      </div>
    </div>
  );
}
