import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function EscalaDeSonolenciaDeEpworth({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function setEpworthTotal(){
    let total = 0

    Object.values(sportsPhysiotherapyChartAnswers.epworth).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        total += int
      }
    })

    return total || 0;
  }

  return (
    <div className={classes.epworth}>
      <p className='titulo-form' style={{ fontSize: '1.3em' }}>
        Qual possibilidade de você cochilar ou adormecer nas seguintes situações?
      </p>

      <article>
        <p className='titulo-form'>1. Sentado e lendo</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.sittingAndReading}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                sittingAndReading: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2. Vendo televisão</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.watchingTv}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                watchingTv: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3.Sentado em lugar público sem atividades como sala de espera, cinema, teatro, igreja</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.publicPlace}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                publicPlace: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4.Como passageiro de carro, trem ou metro andando por 1 hora sem parar</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.passenger}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                passenger: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5.Deitado para descansar a tarde</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.lyingInTheAfternoon}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                lyingInTheAfternoon: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6.Sentado e conversando com alguém</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.talkingToSomeone}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                talkingToSomeone: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7.Sentado após uma refeição sem álcool</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.afterMealWithNoAlcohol}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                afterMealWithNoAlcohol: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8.No carro parado por alguns minutos no durante trânsito</p>
        <TextField
          id="select"
          select
          fullWidth
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.epworth.duringTransit}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              epworth: {
                ...state.epworth,
                duringTransit: e.target.value
              }
            }))
          }}
          SelectProps={{
              native: true,
          }}
          variant="outlined"
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma chance</option>
          <option value="1">Pequena chance de cochilar</option>
          <option value="2">Moderada chance de cochilar</option>
          <option value="3">Alta chance de cochilar</option>
        </TextField>

        <p className={classes.score}>Escore: {setEpworthTotal()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      } 
    </div>
  )
}