import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function EscalaTampaDeCinesiofobia({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function setCinesiofobiaTotal(){
    let total = 0

    Object.values(sportsPhysiotherapyChartAnswers.cinesiofobia).map(value => (
      total += parseInt(value)
    ))

    return total || 0;
  }

  return (
    <div className={classes.cinesiofobia}>
      <p style={{ fontWeight: '500' }}>
        Aqui estão algumas das coisas que outros pacientes nos contaram sobre sua dor. Para cada afirmativa, por favor, indique um número de 1 a 4, caso você concorde ou discorde da afirmativa. Primeiro você vai pensar se concorda ou discorda e depois, se totalmente ou parcialmente.
      </p>

      <article>
        <p className='titulo-form'>1. Eu tenho medo que eu possa me machucar se eu fizer exercícios</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.fearOfGettingHurt}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                fearOfGettingHurt: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2. Se eu tentasse superar esse medo, minha dor aumentaria</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.fearToOvercome}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                fearToOvercome: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3. Meu corpo está me dizendo que algo muito errado está acontecendo comigo</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.somethingWrongWithMe}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                somethingWrongWithMe: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4. Minha dor provavelmente seria aliviada se eu fizesse exercício</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.exercisePainRelieved}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                exercisePainRelieved: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5. As pessoas não estão levando minha condição médica a serio</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.peopleJudgingMe}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                peopleJudgingMe: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6. Minha lesão colocou o meu corpo em risco para o resto da minha vida</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.myBodyAtRisk}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                myBodyAtRisk: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7. A dor sempre significa que eu machuquei meu corpo</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.painWithMeaning}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                painWithMeaning: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8. Só porque alguma coisa piora minha dor, não significa que é perigoso</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.dangerousPain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                dangerousPain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>9.Eu tenho medo que eu possa me machucar acidentalmente</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.afraidOfHavingAnAccident}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                afraidOfHavingAnAccident: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>10.Simplesmente sendo cuidadoso para não fazer nenhum movimento desnecessário e a atitude mais segura que eu posso tomar para prevenir a piora da minha dor</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.alwaysCareful}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                alwaysCareful: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>11.Eu não teria tanta dor se algo potencialmente perigoso não estivesse acontecendo no meu corpo</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.somethingDangerousHappening}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                somethingDangerousHappening: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>12.Embora minha condição seja dolorosa, eu estaria melhor se estivesse ativo fisicamente</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.wouldBeBetterIfItWereActive}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                wouldBeBetterIfItWereActive: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>13.A dor me avisa quando parar o exercício para que eu não me machuque</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.painLetMeKnow}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                painLetMeKnow: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>14.Não é realmente seguro para uma pessoa com minha condição ser ativo fisicamente.</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.dangerousPhysicallyActive}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                dangerousPhysicallyActive: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>15.Eu não posso fazer todas as coisas que as pessoas normais fazem, porque para mim e muito fácil me machucar.</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.iCantBeNormal}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                iCantBeNormal: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>16.Embora algo esteja me causando muita dor, eu não acho que seja, de fato, perigoso</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.painButNotDangerous}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                painButNotDangerous: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>17. Ninguém deveria fazer exercícios, quando está com dor</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.cinesiofobia.noOneShouldDoExercises}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              cinesiofobia: {
                ...state.cinesiofobia,
                noOneShouldDoExercises: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Discordo totalmente</option>
          <option value="2">Discordo parcialmente</option>
          <option value="3">Concordo parcialmente</option>
          <option value="4">Concordo totalmente</option>
        </TextField>

        <p className={classes.score}>Total: {setCinesiofobiaTotal()}</p>
        <p className={classes.score}>(Portador de sensibilizacão central {'>='} 40pts)</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      }
    </div>
  )
}