import React, {useEffect, useState} from 'react'

export default function Cronometro({op}) {

  const [format , setFormat] = useState('00:00:00')
  const [cron , setCron] = useState()



  useEffect(()=>{

   if(op){
       start();
   }else{
       stop();
   }
   
  },[op])

    var hh = 0;
    var mm = 0;
    var ss = 0;

    var tempo = 1000;//Quantos milésimos valem 1 segundo?
   

    //Inicia o temporizador
     function start() {
        setCron(setInterval(() => { timer(); }, tempo))

    }

    //Para o temporizador mas não limpa as variáveis
    function pause() {
        clearInterval(cron);
    }

    //Para o temporizador e limpa as variáveis
    function stop() {
        clearInterval(cron);
        hh = 0;
        mm = 0;
        ss = 0;

        // document.getElementById('counter').innerText = '00:00:00';
    }

    //Faz a contagem do tempo e exibição
    function timer() {
        ss++; //Incrementa +1 na variável ss

        if (ss == 59) { //Verifica se deu 59 segundos
            ss = 0; //Volta os segundos para 0
            mm++; //Adiciona +1 na variável mm

            if (mm == 59) { //Verifica se deu 59 minutos
                mm = 0;//Volta os minutos para 0
                hh++;//Adiciona +1 na variável hora
            }
        }

        //Cria uma variável com o valor tratado HH:MM:SS
        var formatlocal = (hh < 10 ? '0' + hh : hh) + ':' + (mm < 10 ? '0' + mm : mm) + ':' + (ss < 10 ? '0' + ss : ss);

        setFormat(formatlocal)


        //Insere o valor tratado no elemento counter
        // document.getElementById('counter').innerText = format;

        // //Retorna o valor tratado
        // return format;
    }



    return (
        <div>

            {/* <button onClick={()=>{start()}} class="bg-green">Iniciar</button>
            <button onClick={()=>{pause()}} class="bg-yellow">Pausar</button>
            <button onClick={()=>{stop()}} class="bg-red">Parar</button> */}
            {/* {startcron ? ()=>{start()} : 'false'} */}
      
            <p>{format}</p>

        </div>
    )

}