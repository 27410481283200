import React from 'react'

import { useStyles } from './style'

export default function StepsLeftMenu({ stepName, handleStep }) {
  const classes = useStyles()

  return (
    <div className={classes.steps} onClick={() => handleStep()}>
      <div className={classes.steps__content}>
        <div className={classes.content}>
          <h2>{ stepName }</h2>
        </div>
      </div>
    </div>
  )
}