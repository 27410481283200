import React, {useState, useEffect} from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
// import Alert from '../../../../Components/Alert';
import Alert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField';
// import Avatar from '../../../CadastroPaciente/avatar';
import {telefones, cpf, rg } from '../../../../Components/ReduxTeste/Validate';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import conexao from '../../../../Services/api';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { StateGlobal } from '../../../../ContextAPI/context';
import AlertSave from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import {campovazio, email, CPF, RG, phone, cepValid} from '../../../../Components/ReduxTeste/ValidateForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import CircularLoad from '../../../../Components/Load'
import Load from '../../../../Components/Load'
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';

import axios from 'axios';

// import './style.css'
// import { validate } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    paper: {
      boxShadow: "0px 0px 0px 0px gray",
      borderRadius: "0px",
      height: "100vh !important",
      overflow: "auto"
    },
    btnEditLogo:{
      maxWidth: '2rem',
      height: '2rem',
      minWidth: '2rem',
      borderRadius: '50%',
      position: 'relative',
      marginLeft: '-1.1rem',
      marginTop: '3.5rem'
    },
    iconEditLogo:{
      width: '.8em',
      height: '.8em',
    },
    avatarLogo:{
      width: '3rem',
      height: '3rem'
    },
    envolveLogo:{
      display: 'flex',
      justifyContent: 'right'
    },
    avatar:{
      width: '6rem',
      height: '6rem',
      backgroundColor: '#ccc',
      padding: '0px',
      borderRadius: '50%',
      float: 'right'
    },
    colorPaper: {
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 0px 0px 0px gray",
    },
    colorPaperWhite:{
      backgroundColor: "#fff",
      boxShadow: "0px 0px 0px 0px gray",
      padding: '2rem'
    },
    envolveCadastrar:{
        marginTop: '6rem',
        height: '87vh',
        padding: '2rem',
    },
    titulo:{
        fontSize: '1.3em',
        color: '#219FB6 !important',
        marginBottom: '1rem !important',
        fontWeight: '600'
    },
    containerFields:{
        display:'flex',
        flexDirection:'column',
        marginLeft: '3rem',
        width: '100%',
        marginTop:'30px'
    },
    btnEditAvatar:{
      maxWidth: '2rem',
      height: '2rem',
      minWidth: '2rem',
      borderRadius: '50%',
      position: 'relative',
      marginLeft: '-1.1rem',
      marginTop: '1.3rem'
    },
    col1:{
        width: '100%',
        margin: '2%'
    },
    col2:{
      width: '50%',
      margin: '2%'
    },
    col3:{
      width: '30%',
      margin: '2%'
    },
    container: {
        display:'flex',
        justifyContent:'space-between'  
    },
    genero:{
      display:'flex',
      flexDirection:'row'
    },
    generoDiv:{
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    generoContainer:{
      display:'flex',
      flexDirection:'row',
      alignItems: 'center',
    },
    labelGenero:{
      marginRight: '10px',
      fontSize: '1rem',
      color:'#8F8F8F',
    },
    row:{
      marginTop: '0rem',
      display:'flex',
      justifyContent:'space-between',
      marginBottom:'0.3rem'
    },
    textbox:{
      display:'flex',
      flexDirection:'column',
      marginBottom:'1.5rem'
      
    },
    small:{
      color:'#8F8F8F',
      fontStyle:'italic'
    },
    mb4:{
      marginBottom: '1.5rem'
    },
    tel:{
      width: '45%'
    },
    sms:{
      color: '#0DB662',
    },
    end:{
      width:'70%'
    },
    num:{
      width:'25%'
    },
    comp:{
      width:'50%'
    },
    bairro:{
      width:'45%'
    },
    buttons:{
      marginTop: '3rem',
      display:'flex',
      justifyContent: 'flex-end',
      paddingBottom:'5rem',
      '& > button': {
        marginRight: '1rem',
      },
    },
    alerts:{
      marginTop: '30px',      

    },
    sexError:{
      color:'#f44336',
    },
    smallError:{
      color:'#f44336',
      marginLeft: '14px',
      marginRight: '14px',
      fontSize: '0.75rem',
      marginTop: '3px'
    },
    divAlert:{
      marginBottom: '1rem'
    },
    errorAlert: {
      marginTop: '2rem'
    }
  }));

  const theme = createTheme({
    palette: {
      primary: {
        light: '#27b8d2',
        main: '#27b8d2',
        dark: '#27b8d2',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#FFFFFF',
        main: '#FFFFFF',
        dark: '#FFFFFF',
        contrastText: '#8F8F8F',
      },
    },
    
  }, ptBR);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#E7366A',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#8F8F8F',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  
  function FormataCelular(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format = '(##) # ####-####'
      />
    );
  }
  
  FormataCelular.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  function FormataTelefone(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        format = '(##) ####-####'
      />
    );
  }

  FormataTelefone.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  export default function ModalPaciente(props) {

    const classes = useStyles();
    const [open, setOpen] = useState(props.open)
    const [tel, setTel] = useState("")
    const [cell, setCell] = useState("")
    const [cpfDoc, setCpfDoc] = useState("")
    const [docRg, setDocRg] = useState("")
    const [cep, setCep] = useState("")
    const [uf, setUf] = useState()
    const {isEditPatient, setIsEditPatient , setViewPatient, ViewPatient, Location, pacienteName} = StateGlobal()
    const [patientId, setPatientId] = useState()
    const [isAlert, setIsAlert] = useState()
    const [isError, setIsError] = useState(true)
    const [loading, setLoading] = useState(true)
    const [img, setImg] = useState()
    const [message, setMessage] = useState('')
    const [typeAlert, setTypeAlert] = useState('');
    const [loadFoto, setLoadFoto] = useState(false);
    const [loadingImg, setLoadingImg] = useState(true)
    const [dados, setDados] = useState({
      "name": "",
      "email": "",
      "cpf": "",
      "rg": "",
      "phone": "",
      "cellphone":"",
      "is_whatsapp": true,
      "birthday": "",
      "mothers_name": "",
      "fathers_name": "",
      "profession": "",
      "how_met": "",
      "notification": true,
      "street": "",
      "neighborhood": "",
      "number": '',
      "city": "",
      "state": "",
      "complement": "",
      "zip_code": "",
      "doctor_id": parseInt(localStorage.getItem('selectedDoctor')),
      "avatar":""
    })
    const [validatesx , setvalidatesx] = useState([
      {name:'name' , funcao : campovazio , error:false},
      {name:'birthday' , funcao : campovazio , error:false},
      {name:'email' , funcao : email , error:false},
      {name:'cpf' , funcao : CPF , error:false},
      {name:'rg' , funcao : RG , error:false},
      {name:'mothers_name' , funcao : campovazio , error:false},
      {name:'fathers_name' , funcao : campovazio , error:false},
      {name:'phone' , funcao : campovazio , error:false},
    ]);

    const [validateTest, setValidateTest] =  useState({
      name:{name:'name', funcao: campovazio, error:false},
      email:{name:'email', funcao: email, error:false},
      birthday:{name:'birthday', funcao:campovazio, error:false},
      cpf:{name:'cpf', funcao:CPF, error:false},
      rg:{name:'rg', funcao:RG, error: false},
      mothers_name:{name:'mothers_name', funcao:campovazio, error:false},
      fathers_name:{name:'fathers_name', funcao:campovazio, error:false},
      phone:{name:'phone', funcao:phone, error:false},
      cellphone:{name:'cellphone', funcao:phone, error:false},
      zip_code:{name:'zip_code',funcao: cepValid, error:false},
      street:{name:'street', funcao: campovazio, error:false},
      complement:{name:'complement', funcao: campovazio, error:false},
      number:{name:'number', funcao: campovazio, error:false},
      city:{name:'city', funcao: campovazio, error:false},
      neighborhood:{name:'neighborhood',funcao: campovazio, error:false},
      state:{name:'state',funcao: campovazio, error:false},
      sex:{name:'sex', funcao: campovazio, error:false},
    })
    const [selectedDoctor, setSelectedDoctor] = useState(null)
    // const [selectedUser, setSelectedUser] = useState(null)
    // const [doctorsList, setDoctorsList] = useState([])
    // const [showDoctors, setShowDoctors] = useState(false)
    const history = useHistory()

    useEffect(() => {
      setSelectedDoctor(parseInt(localStorage.getItem('selectedDoctor')));
      getEstados()

    }, [])

    useEffect(() => {   
      if (props.patientId) {
        setPatientId(props.patientId)
        getPatient(props.patientId)
      } else {
        setLoading(false)
      }
    },[props.open, props.prtientId])

    // async function chamada(){     
    //   const agendamentoId = await localStorage.getItem('agendamentoId')
      
    //   await conexao.get(`/form/patient/schedule/${agendamentoId}`)
    //   .then((resp) => {
    //     getPatient(resp.data.data.id)
    //   }).catch((err) => {

    //   })
    // }

    function alert(message, type)
    {
      setMessage(message)
      setTypeAlert(type)
    }

    function getBase64(file) {

      if(file.size > 10000000){
        alert("Documento maior que 10MB, por favor verifique o tamanho do arquivo.");
        return;
      };
  
      var reader = new FileReader();
  
      reader.readAsDataURL(file);
      reader.onload = function () {
        putFotoPerfil(reader.result)
      };
    }

    async function putFotoPerfil(image){
      setLoadingImg(true)
      setLoadFoto(true)
     await conexao.post('image/upload',
       {
         "path": 'patient',
         "photo": image,
       })
     .then((resp) => {
       if(!resp.data.error){
         setDados({...dados, 
           'avatar': resp.data.data
         });
         setLoadFoto(false)
         setLoadingImg(false)
       }
     }).catch((error)=>{
         setLoadingImg(false)
     })
   
   }

    function onchangeValidateTeste(e){
      const{name , value } = e.target
      console.log(value)
      const obj = validateTest[name]
      if (obj) {
        const er =  validateTest[name].funcao(value)
        let att = {...obj, error: er}
        setValidateTest({...validateTest, [name]: att  })  
      }
    }

    async function getPatient(id) {
      
      console.log('getPatient', id)

      setLoading(true)

      await conexao.get('/patient/'+id).then((resp) => {
        
        var birt = null
        if(resp.data.data.birthday) {
          birt = resp.data.data.birthday.split(" ")
        }

        let paciente = resp.data.data
        let info = {
          "id": paciente.id,
          "name": paciente.name,
          "cellphone": paciente.cellphone,
          "email": paciente.email,
          "cpf": paciente.cpf,
          "rg": paciente.rg,
          "phone": paciente.phone,
          "is_whatsapp": paciente.is_whatsapp,
          "birthday": (birt) ? birt[0] : '',
          "mothers_name": paciente.mothers_name,
          "fathers_name": paciente.fathers_name,
          "profession": paciente.profession,
          "how_met": paciente.how_met,
          "notification": paciente.notification,
          "street": paciente.address ? (paciente.address.street ? paciente.address.street : '') : '',
          "neighborhood": paciente.address ? (paciente.address.neighborhood ? paciente.address.neighborhood : '') : '',
          "number": paciente.address ? (paciente.address.number ? paciente.address.number : '') : '',
          "city": paciente.address ? (paciente.address.city ? paciente.address.city : '') : '',
          "state": paciente.address ? (paciente.address.state ? paciente.address.state : '') : '',
          "complement": paciente.address ? (paciente.address.complement ? paciente.address.complement : '') : '',
          "zip_code": paciente.address ? (paciente.address.zip_code ? paciente.address.zip_code : '') : '',
          "sex": paciente.sex,
          "doctor_id": paciente.doctor_id,
          "avatar":paciente.avatar
        };

        setDados(info);
        setCell(paciente.cellphone ? paciente.cellphone : '')
        setTel(paciente.phone)   
        setDocRg(paciente.rg ? rg(paciente.rg) : '')
        setCpfDoc(paciente.cpf ? cpf(paciente.cpf) : '')
        setCep(paciente.address ? paciente.address.zip_code : '') 
        setImg(paciente.avatar)
        setLoading(false)
        setLoadingImg(false)
      })

    }

    function onChangeInput(e){
      const { name, value } = e.target
      setDados({...dados, [name]: value})

      console.log('onChangeInput', name, value)
      
      switch(e.target.name) {
        case 'is_whatsapp':
        case 'notification':
          const { name, checked } = e.target
          setDados({...dados, [name]: checked})
        break;

        case 'cellphone':
          const celular = e.target.value
          setCell(celular)

          if(cell) {
            let noMask = celular.replace(/\D/g, "")     
            const { name, value } = e.target
            setDados({...dados, [name]: noMask})
          } 
        break;
        case 'phone':
          const telefone = e.target.value
          setTel(telefone)

          if(tel) {
            let noMask = telefone.replace(/\D/g, "")
            const { name, value } = e.target
            setDados({...dados, [name]: noMask})
          }
        break;

        case 'cpf':
          setCpfDoc(cpf(e.target.value))

          if(e.target.value) {
            const { name, value } = e.target
            let noMask = value.replace(/\D/g, "")  
            setDados({...dados, [name]: noMask})
          }
        break;

        case 'rg':
          setDocRg(rg(e.target.value))
          if(e.target.value) {
            const { name, value } = e.target
            let noMask = value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"")
            setDados({...dados, [name]: noMask})
          }
        break;

        case 'zip_code':
          let value = e.target.value
          const valor = maskCep(value)
          setCep(valor) 

          if(cep) {
            const noMask = value.replace(/\D/g, "");
            setDados({...dados, [name]: noMask})
            if(noMask.length >= 8 ) {
              getCep(noMask)
            }
          }
        break;
      }
    }

    function maskCep(value){
      if (value !== null && value !== undefined && value !== '' ) {
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d)/, "$1.$2")
        value = value.replace(/(\d{3})(\d)/, "$1-$2")
        value = value.replace(/(-\d{3})\d+?$/, '$1')
      }
      return value
    }

    async function getEstados(){
      await conexao.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((resp) => {
        setUf(resp.data)    
      }).catch((error) => {
        alert('Não foi possível carregar os estados', 'error')
      })
    }

    async function getCep(cep){
      await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((resp) => {
        setDados({...dados, 
          'street': resp.data.logradouro,
          'state': resp.data.uf,
          'city': resp.data.localidade,
          'neighborhood': resp.data.bairro,
          'complement': resp.data.complemento
        })
      }).catch((error) => {
        alert('Não foi possível localizar o CEP', 'error')
      })
    }

    function unMaskZipCode(zipcode) {
      let value = zipcode.replace(/\D/g, "").split('-')[0];
      return value
    }

    const searchPostalCode = async postalCode => {
      let postalCodeUnmasked = postalCode.replace(/\D/g, "").split('-')[0];

      if (postalCodeUnmasked.length === 8) {
        await axios
          .get(`https://viacep.com.br/ws/${postalCodeUnmasked}/json/`)
          .then(res => {
            console.log('BUSCA CEP')
            console.log(res)
            if (!res.data.erro) {
              setDados({
                ...dados, 
                'street': res.data.logradouro,
                'state': res.data.uf,
                'city': res.data.localidade,
                'neighborhood': res.data.bairro,
                'complement': res.data.complemento,
                'zip_code': postalCodeUnmasked
              })
            } else {
              setDados({
                ...dados, 
                'street': ' ',
                'state': ' ',
                'city': ' ',
                'neighborhood': ' ',
                'complement': ' ',
                'number': ' ',
                'zip_code': ' '
              })
            }
            
          }).catch((error) => {
          })
      }
    }

    async function save() {

      if (patientId) {
        await conexao.put(`/patient/update/${patientId}`, dados)
        .then((resp) => {
          alert('Paciente salvo com sucesso', 'success')
          setTimeout(() => {
            console.log('fecha alert')
            alert ('', '')
          }, 3000);
    
          setPatientId('')

          if (props.refresh)
            props.refresh();
            close();
        }).catch((error) => {
          var errorMessage = ""
          let obj = error.response.data.data
          
          if (obj)
          {
            if (obj.name) {
              errorMessage += '<li>Campo nome inválido</li>';
            }
            
            if (obj.birthday) {
              errorMessage += '<li>Campo data de nascimento inválido</li>';
            }
            
            if (obj.cellphone) {
              errorMessage += '<li>Campo celular inválido</li>';
            }

            if (errorMessage)
              alert(errorMessage, 'error')
              setTimeout(() => {
                console.log('fecha alert')
                alert ('', '')
              }, 3000);
        
          }
        })
      } else {
        await conexao.post(`/patient/create`, dados)
        .then((resp) => {
          alert('Paciente criado com sucesso', 'success')
          setTimeout(() => {
            console.log('fecha alert')
            alert ('', '')
          }, 3000);
    
          setPatientId('')
          if (props.refresh)
            props.refresh();

            close()
        }).catch((error) => {
          var errorMessage = ""
          let obj = error.response.data.data
          
          if (obj)
          {
            if (obj.name) {
              errorMessage += '<li>Campo nome inválido</li>';
            }
            
            if (obj.birthday) {
              errorMessage += '<li>Campo data de nascimento inválido</li>';
            }
            
            if (obj.cellphone) {
              errorMessage += '<li>Campo celular inválido</li>';
            }

            if (errorMessage)
              alert(errorMessage, 'error')
              setTimeout(() => {
                console.log('fecha alert')
                alert ('', '')
              }, 3000);
        
          }
        })
      }
    }

    function clearData() {
      setDados({
        "name": "",
        "email": "",
        "cpf": "",
        "rg": "",
        "phone": "",
        "cellphone":"",
        "is_whatsapp": true,
        "birthday": "",
        "mothers_name": "",
        "fathers_name": "",
        "profession": "",
        "how_met": "",
        "notification": true,
        "street": "",
        "neighborhood": "",
        "number": '',
        "city": "",
        "state": "",
        "complement": "",
        "zip_code": "",
        "doctor_id": parseInt(localStorage.getItem('selectedDoctor')),
        "avatar":""
      })

      setDocRg('')
      setCell('')
      setValidateTest({...validateTest, ['rg']: false  }) 
      setValidateTest({...validateTest, ['cpf']: false  }) 
      setValidateTest({...validateTest, ['cellphone']: false  }) 
      setPatientId('')
    }

    function close() {
      clearData()
      alert ('', '')
      props.close();
    }

    function showFormError(obj) {
      let data = validateTest

      Object.keys(obj).forEach(function(name) {
        const obj = validateTest[name]
        const er =  'Preencha o campo corretamente'
        let att = {...obj, error: er}
        
        data = {...data,[name]:att}                
      });

      setValidateTest(data)
    }
    
    const getImage = img  => {
      const obj = {...dados, 'avatar':img}
      setDados(obj)
    }
   
    return (
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} aria-labelledby = "alert-dialog-title" aria-describedby = "alert-dialog-description">
          <DialogTitle id = "alert-dialog-title">
            <div className = 'title-modal-close'>
              Cadastro do paciente
              <CloseIcon onClick={close}/>
            </div>
          </DialogTitle>
                
          <DialogContent>
            {message ?
                <Alert variant = "filled" severity={typeAlert}>
                  <p dangerouslySetInnerHTML={{ __html: message }}></p>
                </Alert>
            : ''}
                        
            <DialogContentText id = "alert-dialog-description">                    
              { loading ?
                <CircularProgress /> 
              :
                <>
                  <div className={classes.envolveLogo}>
                    {loadingImg ? 
                    <div className={classes.avatar} style={{padding: 5}}>
                      <Load/>
                    </div>
                    :
                    <Avatar id="avatar" className={classes.avatar} alt={dados.name} src={dados.avatar} />  
                    }
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        className={classes.btnEditAvatar}
                        >
                        {loadFoto?<CircularLoad/>:<EditIcon className={classes.iconEditLogo}/>}
                        <input
                            id="inputFileToLoad"
                            type="file"
                            hidden
                            onChange={(event)=>getBase64(event.target.files[0])}
                        />
                    </Button>
                  </div>
                  <div  className={classes.row}>
                    <TextField
                      className={classes.col1}
                      label = 'Nome'
                      type = "text"
                      name = 'name'
                      required={true}
                      variant = "outlined"
                      value={dados.name}
                      inputProps = {{maxLength:150}}
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)} }
                      onBlur = {onchangeValidateTeste}
                      error = {validateTest.name ? validateTest.name.error ? true : false :''}
                      helperText={validateTest.name.error ? validateTest.name.error : ''}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col3}
                      type = "date"
                      label = 'Data de Nascimento'
                      required={true}
                      value={dados.birthday}
                      name = 'birthday'
                      variant = "outlined"
                      error = {validateTest.birthday ? validateTest.birthday.error ? true : false :''}
                      helperText={validateTest.birthday.error ? validateTest.birthday.error : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps = {{maxLength:150}}
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                      onBlur = {onchangeValidateTeste}
                    />
                  </div>

                  <div className={classes.row}>
                    <FormControl component = "fieldset" className={classes.col1} error>
                      <RadioGroup aria-label = "gender" name = "sex" className={classes.genero} onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}} >
                        <FormControlLabel value = "H" control={<Radio color = "primary"/>} label = "Masculino" checked={dados.sex === "H" ? true : false} />
                        <FormControlLabel value = "M" control={<Radio color = "primary"/>} label = "Feminino" checked={dados.sex === "M" ? true : false}/>
                      </RadioGroup>
                    </FormControl>
                    <small className={classes.smallError}> {validateTest.sex.error ? validateTest.sex.erro : ''}</small>
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col2}
                      label = 'Email'
                      type = "email"
                      required={false}
                      variant = "outlined"
                      name = "email"
                      value={dados.email}
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                    />
                  </div>

                  <div className={classes.row}>
                    <TextField
                        className={classes.col2}
                        label = 'CPF'
                        type = "text"
                        variant = "outlined"
                        name = 'cpf'
                        inputProps = {{maxLength:14}}
                        value={cpfDoc}
                        onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                        error = {validateTest.cpf ? validateTest.cpf.error ? true : false :''}
                        helperText={validateTest.cpf.error ? validateTest.cpf.error : ''}
                    />
                    
                    <TextField
                      className={classes.col2}
                      label = 'RG'
                      type = "text"
                      variant = "outlined"
                      inputProps = {{maxLength:13}}
                      name = 'rg'
                      value={docRg}
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col1}
                      label = 'Nome da Mãe'
                      type = "text"
                      variant = "outlined"
                      name = "mothers_name"
                      value={dados.mothers_name}
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col1}
                      label = 'Nome do Pai'
                      type = "text"
                      variant = "outlined"
                      name = "fathers_name"
                      value={dados.fathers_name}
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col1}
                      label = 'Profissão'
                      type = "text"
                      variant = "outlined"
                      name = "profession"
                      value={dados.profession}
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                        className={classes.col1}
                        label = 'Como Conheceu?'
                        type = "text"
                        variant = "outlined"
                        inputProps = {{maxLength:255}}
                        name = "how_met"
                        value={dados.how_met}
                        onChange={onChangeInput}
                    />
                  </div>

                  <h3 className={classes.titulo}>TELEFONES</h3>

                  <div className={classes.row}>
                    <TextField
                      className={classes.col2}
                      label = 'Fixo'
                      type = "text"
                      variant = "outlined"
                      name = "phone"
                      onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                      onBlur = {onchangeValidateTeste}
                      value={tel}
                      InputProps={{
                        inputComponent: FormataTelefone,
                      }}
                    />
                      <TextField
                        className={classes.col2}
                        label = 'Celular'
                        type = "text"
                        variant = "outlined"
                        required = {true}
                        name = "cellphone"
                        onChange={(e) => {onChangeInput(e); onchangeValidateTeste(e)}}
                        onBlur = {onchangeValidateTeste}
                        value={cell}
                        error = {validateTest.cellphone ? validateTest.cellphone.error ? true : false :''}
                        helperText={validateTest.cellphone.error ? validateTest.cellphone.error : ''}
                        InputProps={{
                          inputComponent: FormataCelular,
                        }}
                    />
                  </div>
                
                  <h3 className={classes.titulo}>LEMBRETE DE AGENDAMENTO</h3>

                  <FormControlLabel
                    control={<IOSSwitch  onChange={onChangeInput} name = "is_whatsapp" checked={dados.is_whatsapp ? true : false}/>}
                    label = "Paciente aceita receber lembrete de agendamento por SMS"
                  />
                  {dados.is_whatsapp ? 
                    <span className={classes.sms}> Seus pacientes podem responder o SMS</span>
                  : ''} 

                  <h3 className={classes.titulo}>ENDEREÇO</h3>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col3}
                      label = 'CEP'
                      type = "text"
                      variant = "outlined"
                      inputProps = {{maxLength:150}}
                      name = "zip_code"
                      value={maskCep(dados.zip_code)}
                      onChange={(e) => {
                        setDados({
                          ...dados,
                          zip_code: unMaskZipCode(e.target.value)}
                        )
                        searchPostalCode(e.target.value)
                      }}
                      onBlur = {onchangeValidateTeste}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col1}
                      label = 'Endereço'
                      type = "text"
                      variant = "outlined"
                      inputProps = {{maxLength:150}}
                      name = "street"
                      value={dados.street}
                      onChange={(e) => {onChangeInput(e)}}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col3}
                      label = 'Número'
                      type = "text"
                      variant = "outlined"
                      inputProps = {{maxLength:150}}
                      name = "number"
                      value={dados.number}
                      onChange={(e) => {onChangeInput(e)}}
                    />

                    <TextField
                      className={classes.col3}
                      label = 'Complemento'
                      type = "text"
                      variant = "outlined"
                      value={dados.complement}
                      inputProps = {{maxLength:150}}
                      name = "complement"
                      onChange={(e) => {onChangeInput(e)}}
                      InputLabelProps={{ shrink: true }} 
                    />
                    <TextField
                      className={classes.col3}
                      label = 'Bairro'
                      type = "text"
                      variant = "outlined"
                      inputProps = {{maxLength:150}}
                      name = "neighborhood"
                      value={dados.neighborhood}
                      onChange={(e) => {onChangeInput(e)}}
                    />
                  </div>

                  <div  className={classes.row}>
                    <TextField
                      className={classes.col2}
                      label = 'Cidade'
                      type = "text"
                      variant = "outlined"
                      inputProps = {{maxLength:150}}
                      name = "city"
                      disabled={dados.city?true:false}
                      value={dados.city}
                      onChange={(e) => {onChangeInput(e)}}
                    />
                  
                    <FormControl  variant = "outlined" className={classes.col2}>
                      <InputLabel id = "demo-simple-select-outlined-label">Estado</InputLabel>
                      <Select
                        labelId = "demo-simple-select-outlined-label"
                        label = 'Estado'
                        name = "state"
                        disabled={dados.state?true:false}
                        onChange={(e) => {onChangeInput(e)}}
                        value = {dados.state}                          
                      >
                        <MenuItem key={''} value={''}>Selecione</MenuItem>
                        { uf ? 
                          uf.map((estado, index) => {
                            return( <MenuItem key={index} value={estado.sigla}>{estado.sigla}</MenuItem> )
                          })
                        :
                          <MenuItem> Carregando </MenuItem>
                        }
                      </Select>
                    </FormControl>
                  </div>

                  {/* <Avatar component="div" loading={loading} src={dados.avatar}/> */}
              
                  <FormControlLabel
                    control = {
                      <IOSSwitch
                        onChange={onChangeInput}
                        name = "notification"
                        value={dados.notification}
                        checked={dados.notification ? true : false}
                      />
                    }
                    label = "Eu aceito receber as notificações da Clinic Plus"
                  />
              
                  { isAlert ? 
                    <div className={classes.alerts}>   
                      { isError ? 
                        <AlertSave variant = "filled" severity = "error">
                          {message}
                        </AlertSave>
                      :
                        <AlertSave variant = "filled" severity = "success">
                          Salvo com sucesso!
                        </AlertSave>     
                      } 
                    </div>
                  : "" } 
                </>
              }
            {message ?
                <Alert variant="filled" severity={typeAlert}><p dangerouslySetInnerHTML={{ __html: message }}></p></Alert>
            : ''}
            </DialogContentText>
            <DialogActions>
              <Button variant = "contained" color = 'primary' className = "color-primaryD" onClick={save}>
                SALVAR
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    )
}