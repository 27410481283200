import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    paper: {
      boxShadow: "0px 0px 0px 0px gray",
      borderRadius: "0px",
      height: "100%"
    },
    colorPaper: {
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 0px 0px 0px gray",
    },
  
  }));