import { useEffect } from 'react';
import Anamnese from '../Anamnese';
import Medicamentos from '../Medicamentos';
import Alimentacao from '../Alimentacao';
import Sono from '../Sono';
import Algometria from '../Algometria';
import Treino from '../Treino';
import TestesFuncionais from '../TestesFuncionais';
import TestesFuncionais2 from '../TestesFuncionais2';
import TestesFuncionaisDeTronco from '../TestesFuncionaisDeTronco';
import Cait from '../Cait';
import Lysholm from '../Lysholm';
import NeckDisabilityIndex from '../NeckDisabilityIndex';
import IndiceOswestryDeIncapacidade from '../IndiceOswestryDeIncapacidade';
import Spadi from '../Spadi';
import Womac from '../Womac';
import VisaA from '../VisaA';
import VisaP from '../VisaP';
import Eroe from '../Eroe';
import EscalaDeSonolenciaDeEpworth from '../EscalaDeSonolenciaDeEpworth';
import EscalaTampaDeCinesiofobia from '../EscalaTampaDeCinesiofobia';
import IndiceDeSensibilizacaoCentral from '../IndiceDeSensibilizacaoCentral';
import Aofas from '../Aofas';
import Faam from '../Faam';
import Lefs from '../Lefs';
import Psqi from '../Psqi';
import DiagnosticoFisioterapeutico from '../DiagnosticoFisioterapeutico';
import { useStyles } from './style';
import { useLocation, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function PdfEsportivo () {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory();
  
  useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 1200)
  }, [])

  return (
    <div className={classes.container}>
      <div 
        style={{
          textAlignLast: 'start',
          marginBottom: '45px'
        }}
      >
        <Button 
          onClick={() => history.goBack()}
          variant="contained"
        >
          <ArrowBackIcon />
          VOLTAR
        </Button>
      </div>
      
      <Anamnese path={location.pathname}/>
      <Medicamentos path={location.pathname}/>
      <Alimentacao path={location.pathname}/>
      <Sono path={location.pathname}/>
      <Treino path={location.pathname}/>
      <Algometria path={location.pathname}/>
      <TestesFuncionais path={location.pathname}/>
      <TestesFuncionais2 path={location.pathname}/>
      <TestesFuncionaisDeTronco path={location.pathname}/>
      <Cait path={location.pathname}/>
      <Lysholm path={location.pathname}/>
      <NeckDisabilityIndex path={location.pathname}/>
      <IndiceOswestryDeIncapacidade path={location.pathname}/>
      <Spadi path={location.pathname}/>
      <Womac path={location.pathname}/>
      <VisaA path={location.pathname}/>
      <VisaP path={location.pathname}/>
      <Eroe path={location.pathname}/>
      <EscalaDeSonolenciaDeEpworth path={location.pathname}/>
      <EscalaTampaDeCinesiofobia path={location.pathname}/>
      <IndiceDeSensibilizacaoCentral path={location.pathname}/>
      <Aofas path={location.pathname}/>
      <Faam path={location.pathname}/>
      <Lefs path={location.pathname}/>
      <Psqi path={location.pathname}/>
      <DiagnosticoFisioterapeutico path={location.pathname}/>
    </div>
  )
}

export default PdfEsportivo;