import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    form: {
      width: '100%',
      border: 'none',
      marginBottom: '2rem'
    },
    search: {
      background: '#f1f1f1',
      boxShadow: '0px 1px 10px rgba(25, 32, 56, 0.08)',
      borderRadius: '3px',
      height: '45px',
      fontSize: '15px',
      border: 'none',
      padding: '15px',
      minWidth: '350px',
  
    },
    iconSearch: {
      color: '#8F8F8F'
    },
    pag:{
      display: 'flex',
      justifyContent:'center',
      marginTop: '2rem'
    },
    paper: {
      boxShadow: "0px 0px 0px 0px gray",
      borderRadius: "0px",
      height: "100%"
    },
    colorPaper: {
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 0px 0px 0px gray",
    },
  
  }));