import React, { useContext, useRef, useEffect, useState } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function IndiceOswestryDeIncapacidade({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  const getTotalScore = () => {
    let score = 0
    let length = 0 

    Object.values(sportsPhysiotherapyChartAnswers.oswestryIndex).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
        length += 1
      }
    })

    return Math.round(((score / (length * 5)) * 100)) || 0 
  }

  return (
    <div>
      <p>
        Por favor, você poderia completar este questionário? Ele é elaborado para nos dar informações de como seu problema nas costas (ou pernas) têm afetado seu dia-a-dia. Por favor, responda a todas as seções. Marque apenas um quadrado em cada seção, aquele que mais de perto descreve você hoje. 
      </p>

      <p className='titulo-form'>Pós-operatorio ?</p>
      <TextField
        id="select"
        select
        fullWidth
        disabled={sportsPhysiotherapyView}
        value={sportsPhysiotherapyChartAnswers.postoperative}
        onChange={e => {
          setSportsPhysiotherapyChartAnswers(state => ({
            ...state,
            postoperative: e.target.value
          }))
        }}
        SelectProps={{
          native: true,
        }}
        variant="outlined"
      >   
        <option value="" selected>...</option>
        <option value="yes">Sim</option>
        <option value="no">Não</option>
      </TextField>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '60px 0 10px 0' }}
        >
          1. Intensidade da dor:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.painIntensity}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                painIntensity: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Sem dor no momento</option>
          <option value="1">A dor é leve nesse momento</option>
          <option value="2">A dor é moderada nesse momento</option>
          <option value="3">A dor é mais ou menos intensa nesse momento</option>
          <option value="4">A dor é muito forte nesse momento</option>
          <option value="5">A dor é a pior imaginável nesse momento</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          2- Cuidado pessoal (se lavar, se vestir, etc.):
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.personalCare}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                personalCare: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Eu posso cuidar de mim sem provocar dor extra</option>
          <option value="1">Posso me cuidar mas me causa dor</option>
          <option value="2">É doloroso me cuidar e sou lento e cuidadoso</option>
          <option value="3">Preciso de alguma ajuda, mas dou conta de me cuidar</option>
          <option value="4">Preciso de ajuda em todos os aspectos para cuidar de mim</option>
          <option value="5">Eu não me visto, tomo banho com dificuldade e fico na cama</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          3- Pesos:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.weight}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                weight: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Posso levantar coisas pesadas sem causar dor extra</option>
          <option value="1">Se levantar coisas pesadas sinto dor extra</option>
          <option value="2">A dor me impede de levantar coisas pesadas, mas dou um jeito, se estão bem posicionadas, (Ex., numa mesa).</option>
          <option value="3">A dor me impede de levantar coisas pesadas mas dou um jeito de levantar coisas leves ou pouco pesadas se  estiverem bem posicionadas</option>
          <option value="4">Só posso levantar coisas muito leve.</option>
          <option value="5">Não posso levantar nem carregar nada.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          4- Andar:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.walk}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                walk: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">A dor não me impede de andar (qualquer distância) </option>
          <option value="1">A dor me impede de andar mais que 2 Km</option>
          <option value="2">A dor me impede de andar mais que ? Km</option>
          <option value="3">A dor me impede de andar mais que poucos metros </option>
          <option value="4">Só posso andar com bengala ou muleta .</option>
          <option value="5">Fico na cama a maior parte do tempo e tenho que arrastar para o banheiro.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          5- Sentar:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.sit}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                sit: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Posso sentar em qualquer tipo de cadeira pelo tempo que quiser  </option>
          <option value="1">Posso sentar em minha cadeira favorita pelo tempo que quiser </option>
          <option value="2">A dor me impede de sentar por mais de 1 hora</option>
          <option value="3">A dor me impede de sentar por mais de ? hora  </option>
          <option value="4">A dor me impede de sentar por mais que 10 minutos .</option>
          <option value="5">A dor me impede de sentar .</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          6- De pé:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.standing}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                standing: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Posso ficar de pé pelo tempo que quiser sem dor extra</option>
          <option value="1">A dor me impede de ficar de pé por mais de 1 hora</option>
          <option value="2">A dor me impede de ficar de pé por mais ? hora</option>
          <option value="3">A dor me impede de ficar de pé por mais de 10 minutos.</option>
          <option value="4">A dor me impede de ficar de pé .</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          7- Sono:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.sleep}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                sleep: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Meu sono não é perturbado por dor.</option>
          <option value="1">Algumas vezes meu sono é perturbado por dor</option>
          <option value="2">Por causa da dor durmo menos de 6 horas.</option>
          <option value="3">Por causa da dor durmo menos de 4 horas.</option>
          <option value="4">Por causa da dor durmo menos de 2 horas.</option>
          <option value="5">A dor me impede de dormir.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          8- Vida sexual (se aplicável):
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.sexLife}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                sexLife: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Minha vida sexual é normal e não me causa dor extra .</option>
          <option value="1">Minha vida sexual é normal, mas me causa dor extra </option>
          <option value="2">Minha vida sexual é quase normal, mas é muito dolorosa .</option>
          <option value="3">Minha vida sexual é muito restringida devido à dor.</option>
          <option value="4">Minha vida sexual é praticamente inexistente devido à dor.</option>
          <option value="5">A dor me impede de ter atividade sexual.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          9- Vida social:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.socialLife}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                socialLife: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Minha vida social é normal e eu não sinto dor extra.</option>
          <option value="1">Minha vida social é normal, mas aumenta o grau de minha dor</option>
          <option value="2">A dor não altera minha vida social, exceto por impedir que faça atividades de esforço, como esportes, etc.</option>
          <option value="3">A dor restringiu minha vida social e eu não saio muito de casa.</option>
          <option value="4">A dor restringiu minha vida social a minha casa.</option>
          <option value="5">Não tenho vida social devido a minha dor.</option>
        </TextField>
      </article>

      <article>
        <p 
          className="titulo-form"
          style={{ margin: '20px 0 10px 0' }}
        >
          10- Viagens:
        </p>

        <TextField
          id="select"
          select
          fullWidth
          value={sportsPhysiotherapyChartAnswers.oswestryIndex.trips}
          onChange={e => 
            setSportsPhysiotherapyChartAnswers(state => ({
              ...state,
              oswestryIndex: {
                ...state.oswestryIndex,
                trips: e.target.value
              }
            }))
          }
          disabled={sportsPhysiotherapyView}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >   
          <option value="" selected>...</option>
          <option value="0">Posso viajar para qualquer lugar sem dor.</option>
          <option value="1">Posso viajar para qualquer lugar, mas sinto dor extra </option>
          <option value="2">A dor é ruim, mas posso viajar por 2 horas.</option>
          <option value="3">A dor restringe minhas viagens para distâncias menores que 1 hora.</option>
          <option value="4">A dor restringe minhas viagens para as necessárias e menores de 30 minutos.</option>
          <option value="5">A dor me impede de viajar, exceto para ser tratado.</option>
        </TextField>

        <p className={classes.score}>
          Escore: {getTotalScore()}% {'->'} 
          {sportsPhysiotherapyChartAnswers.postoperative === 'yes' 
            ? ' (0% - 20%: excelente / 21% - 40%: bom / 41% - 60%: inalterado / > 60%: piora)'
            : ' (0% - 20%: incapacidade mínima / 21% - 40%: incapacidade moderada / 41% - 60%: incapacidade intensa / 61% - 80%: aleijado / 81% - 100%: inválido)'
          }
        </p> 
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      } 
    </div>
  )
}