import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& p': {
      margin: 0,
      fontWeight: '500'
    },
    
    '& small': {
      fontWeight: '500'
    }
  },

  score: {
    marginTop: '10px',
    fontWeight: '500'
  }
}));