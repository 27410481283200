import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function Womac({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function setPainIntensityTotal(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.womac.painIntensity).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return score ? (score / 5).toFixed(2) : 0;
  }

  function setStiffnessTotal(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.womac.stiffness).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return score ? (score / 2).toFixed(2) : 0;
  }

  function setPhysicalActivityTotal(){
    let score = 0
    
    Object.values(sportsPhysiotherapyChartAnswers.womac.physicalActivity).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return score ? (score / 17).toFixed(2) : 0;
  }

  function setScoreTotal() {
    let score = 0

    Object.values(sportsPhysiotherapyChartAnswers.womac.painIntensity).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    Object.values(sportsPhysiotherapyChartAnswers.womac.stiffness).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    Object.values(sportsPhysiotherapyChartAnswers.womac.physicalActivity).forEach(value => {
      let int = parseInt(value)
      
      if (int) {
        score += int
      }
    })

    return score ? (score / 24).toFixed(2) : 0;
  }

  return (
    <div className={classes.womac}>
      <p style={{ fontWeight: '500' }}>
        As perguntas a seguir se referem à INTENSIDADE DA DOR que você está atualmente sentindo devido a artrite de seu joelho. Para cada situação, por favor, coloque a intensidade da dor que sentiu nas últimas 72 horas (3 dias).
      </p>

      <p className='titulo-form' style={{ fontSize: '1.3em' }}>Qual a intensidade da sua dor?</p>

      <article>
        <p className='titulo-form'>1- Caminhando em um lugar plano.</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.painIntensity.walkingLevelGround}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                painIntensity: {
                  ...state.womac.painIntensity,
                  walkingLevelGround: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2- Subindo ou descendo escadas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.painIntensity.goingUpOrDownStairs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                painIntensity: {
                  ...state.womac.painIntensity,
                  goingUpOrDownStairs: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3- A noite deitado na cama</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.painIntensity.lyingInBed}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                painIntensity: {
                  ...state.womac.painIntensity,
                  lyingInBed: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4- Sentando-se ou deitando-se</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.painIntensity.lyingDownOrSitting}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                painIntensity: {
                  ...state.womac.painIntensity,
                  lyingDownOrSitting: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5- Ficando em pé</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.painIntensity.standing}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                painIntensity: {
                  ...state.womac.painIntensity,
                  standing: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>

        <p className={classes.score}>Escore: {setPainIntensityTotal()}</p>
      </article>

      <p style={{ fontWeight: '500', marginTop: '20px' }}>
        As perguntas a seguir se referem a intensidade de RIGIDEZ nas juntas (não dor), que você está atualmente sentindo devido a artrite em seu joelho nas últimas 72 horas. Rigidez é uma sensação de restrição ou dificuldade para movimentar suas juntas.
      </p>

      <article>
        <p className='titulo-form'>1- Qual é a intensidade de sua rigidez logo após acordar de manhã?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.stiffness.stiffnessUponWaking}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                stiffness: {
                  ...state.womac.stiffness,
                  stiffnessUponWaking: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2- Qual é a intensidade de sua rigidez após se sentar, se deitar ou repousar no decorrer do dia?</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.stiffness.intensityOfStiffness || ''}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                stiffness: {
                  ...state.womac.stiffness,
                  intensityOfStiffness: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>

        <p className={classes.score}>Escore: {setStiffnessTotal()}</p>
      </article>

      <p style={{ fontWeight: '500', marginTop: '20px' }}>
        As perguntas a seguir se referem a sua ATIVIDADE FÍSICA. Nós chamamos atividade física, sua capacidade de se movimentar e cuidar de você mesmo(a). Para cada uma das atividades a seguir, por favor, indique o grau de dificuldade que você está tendo devido à artrite em seu joelho durante as últimas 72 horas.
      </p>

      <p className='titulo-form' style={{ fontSize: '1.3em' }}>Qual o grau de dificuldade que você tem ao:</p>
      
      <article>
        <p className='titulo-form'>1 - Descer escadas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.downStairs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  downStairs: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2- Subir escadas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.climbStairs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  climbStairs: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3- Levantar-se estando sentada</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.getUp}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  getUp: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4- Ficar em pé</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.standUp}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  standUp: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5- Abaixar-se para pegar algo</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.duckDown}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  duckDown: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6- Andar no plano</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.walkOnThePlane}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  walkOnThePlane: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        > 
          <option value="" selected>...</option>  
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7 - Entrar e sair do carro</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.gettingInAndOutOfTheCar}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  gettingInAndOutOfTheCar: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8- Ir fazer compras</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.goShopping}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  goShopping: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>9- Colocar meias</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.putSocks}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  putSocks: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>10- Levantar-se da cama</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.getOutOfBed}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  getOutOfBed: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>11 - Tirar as meias</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.takeOffYourSocks}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  takeOffYourSocks: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>12 - Ficar deitado na cama</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.lyingInBed}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  lyingInBed: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>13 - Entrar e sair do banho</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.gettingInAndOutOfTheBath}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  gettingInAndOutOfTheBath: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>14 - Se sentar</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.sitDown}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  sitDown: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>15- Sentar e levantar do vaso sanitário</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.sittingDownAndGettingUpToilet}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  sittingDownAndGettingUpToilet: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>16- Fazer tarefas domésticas pesadas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.doingHeavyHousework}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  doingHeavyHousework: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>17- Fazer tarefas domésticas leves</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.womac.physicalActivity.doLightHousework}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              womac: {
                ...state.womac,
                physicalActivity: {
                  ...state.womac.physicalActivity,
                  doLightHousework: e.target.value
                }
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" selected>...</option>   
          <option value="0">Nenhuma</option>
          <option value="25">Pouca</option>
          <option value="50">Moderada</option>
          <option value="75">Intensa</option>
          <option value="100">Muito intensa</option>
        </TextField>

        <p className={classes.score}>Escore: {setPhysicalActivityTotal()}</p>
        <p className={classes.score}>TOTAL: {setScoreTotal()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      } 
    </div>
  )
}