import React, { useContext } from 'react'

import { TextField } from '@material-ui/core'

import { SportsPhysiotherapyContext } from '../../../../ContextAPI/SportsPhysiotherapyContext'
import StepButtonBack from '../StepButtonBack';
import StepButtonNext from '../StepButtonNext';
import { useStyles } from './style'

export default function IndiceDeSensibilizacaoCentral({ path }) {
  const classes =  useStyles();

  const { 
    sportsPhysiotherapyChartAnswers,
    setSportsPhysiotherapyChartAnswers,
    sportsPhysiotherapyView
  } = useContext(SportsPhysiotherapyContext);

  function setCsiTotal(){
    let total = 0

    Object.values(sportsPhysiotherapyChartAnswers.csi).map(value => (
      total += parseInt(value)
    ))

    return total || 0;
  }

  return (
    <div className={classes.csi}>
      <article>
        <p className='titulo-form'>1.Não me sinto descansado quando eu acordo de manhã</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.idontFeelRested}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                idontFeelRested: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>2.Sinto meus músculos rígidos e doloridos</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iFeelMyMusclesStiff}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iFeelMyMusclesStiff: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>3.Eu tenho crises de ansiedade</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.anxietyCrisis}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                anxietyCrisis: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>4.Ranger ou cerrar os dentes</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.gritYourTeeth}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                gritYourTeeth: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>5.Tenho problemas com diarreia ou constipação</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.problemsWithConstipation}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                problemsWithConstipation: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>6.Eu preciso de ajuda na execução de minhas atividades diárias</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iNeedHelpWithHomework}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iNeedHelpWithHomework: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>7.Sou sensível a luzes brilhantes</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.lightSensitive}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                lightSensitive: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>8.Eu me canso facilmente quando faço atividades físicas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iGetTiredEasily}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iGetTiredEasily: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>9.Eu sinto dores por todo o meu corpo</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iFeelPainInTheBody}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iFeelPainInTheBody: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>10.Eu tenho dores de cabeça</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.headaches}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                headaches: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>11.Eu me sinto desconforto e / ou ardor na minha bexiga quando urino</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.painWhenIUrinate}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                painWhenIUrinate: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>12.Eu não durmo bem</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iDontSleepWell}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iDontSleepWell: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>13.Eu tenho dificuldade de concentração</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.difficultyConcentrating}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                difficultyConcentrating: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>14.Eu tenho problemas de pele, como ressecamento, coceira ou erupções cutâneas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.skinProblems}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                skinProblems: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>15.O estresse faz com que meus sintomas físicos piorem</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.bodyPainFromStress}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                bodyPainFromStress: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>16.Sinto-me triste ou deprimido</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iFeelSad}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iFeelSad: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>17. Eu tenho baixa energia</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iHaveLowEnergy}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iHaveLowEnergy: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>19.Eu tenho dor na minha mandíbula (ATM)</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.jawPain}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                jawPain: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>20.Certos cheiros, tais como perfumes, me fazem sentir tonturas e náuseas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.perfumesMakeMeDizzy}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                perfumesMakeMeDizzy: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>21. Eu tenho vontade de urinar com frequencia</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iHaveToUrinateOften}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iHaveToUrinateOften: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>22.Minhas pernas se sentem desconfortáveis e inquietas quando estou tentando dormir à noite</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.restlessLegs}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                restlessLegs: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>23.Eu tenho dificuldade em lembrar as coisas</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.troubleRememberingThings}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                troubleRememberingThings: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>24.Sofri um trauma quando criança</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.iSufferedATrauma}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                iSufferedATrauma: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>
      </article>

      <article>
        <p className='titulo-form'>25.Eu tenho dor na minha área pélvica</p>
        <TextField
          id="select"
          select
          fullWidth
          variant="outlined"
          disabled={sportsPhysiotherapyView}
          value={sportsPhysiotherapyChartAnswers.csi.painInThePelvicArea}
          onChange={e => {
            setSportsPhysiotherapyChartAnswers(state =>({
              ...state,
              csi: {
                ...state.csi,
                painInThePelvicArea: e.target.value
              }
            }))
          }}
          SelectProps={{
            native: true,
          }}
        >   
          <option value="0" selected>...</option>
          <option value="1">Nunca</option>
          <option value="2">Raramente</option>
          <option value="3">As vezes</option>
          <option value="4">Frequentemente</option>
          <option value="5">Sempre</option>
        </TextField>

        <p className={classes.score}>Escore: {setCsiTotal()}</p>
      </article>

      {path !== '/pdf-esportivo' && 
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <StepButtonBack />
          <StepButtonNext />
        </div>
      } 
    </div>
  )
}