import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import '../css/inputs.css';
import { StateGlobal } from '../../../../../ContextAPI/context'

export default function InputSelect({ data ,id , title, required}) {
    const [value, setValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [req, setReq] = useState(required);
    const [error, setError] = useState(false)
    const { Answer , setAnswer, respEdit, respView, validation, setValidation,
        attValidation, setAttValidation, attEncerraError  } = StateGlobal();

    const handleChangeInput = event => {
        setValue(event.target.value)
        Answer.map((item, index) => {
            if (item.question_id === id) {
                Answer[index].answer = event.target.value
                
                if(req){
                    if( !Answer[index].answer ){
                      setError(true)
                    }
                    else{
                      setError(false)
                  }
                  }
            }
        })
    };

    useEffect(() => {
        if(validation) {
            validation.forEach((step, index)=>{
              step.components.forEach((comp, i)=>{        
               
                if(comp.id == id){
                  setError(validation[index].components[i].error)
                }
              })
            })
        }
  
    }, [attEncerraError])

    useEffect(()=>{
        if(validation) {
            validation.forEach((step, index)=>{
                step.components.forEach((comp, i)=>{                         
                    if(comp.id == id){
                        validation[index].components[i].error = error 
                    }
                })
            })
            setAttValidation(!attValidation)
        }
  
    }, [error])

    useEffect(() => {
        const id = data.tempId

        // console.log('SELECT_ANSWER')
        // console.log(data)

        if(data.opcoes){
            data.opcoes.forEach((item) => {
                Answer.map((answer, index) => {
                    if (answer.question_id == id) {

                        if(answer.answer) {
                            setValue(answer.answer)   
                        }else{
                            // setValue(data.opcoes[0].value)   
                            // Answer[index].answer = data.opcoes[0].value                       
                        }                                
                    }
                })
            })
        }
       
        setOptions(data.opcoes)

        if(data.answers) {
            data.answers.map((item)=>{
                setValue(item.form_step_question_option_id)
            })
        }else{

        }
        
        }, [data])



    // useEffect(() => {
    //     Answer.map((item, index) => {
    //         if (item.question_id === id) {
    //             setNumerico(item.answer)
    //         }
    //     })
    // }, [id])

    // console.log('INPUT SELECT', value)

    return (
        <div>
            <p className="titulo-form">{title}</p>
            <TextField
                id="select"
                select
                value={value}
                required={required}
                disabled={respView}
                onChange={(e) => handleChangeInput(e)}
                SelectProps={{
                    native: true,
                }}
                error={error}
                variant="outlined"
                //disabled = {respEdit}
            >   
                <option key='0' value=''>Selecione</option>
                {options.map((item , index) => {
                    
                    return (
                        <option key={index+1} value={item.value}>
                            {item.text}
                        </option>
                    )

                })}

            </TextField>
        </div>
    );
}
