import React, { useEffect, useState } from "react";
import "./style.css";
import { useParams } from "react-router";
import conexao from "../../../../Services/api";
import { StateGlobal } from "../../../../ContextAPI/context";
import PdfByEscalaDeDor from "../ModelosPdfs/PdfByEscalaDeDor";
import PdfByCraniometria from "../ModelosPdfs/PdfByCraniometria";
import PdfByCurvaDeCrescimento from "../ModelosPdfs/PdfByCurvaDeCrescimento";
import CircularLoad from "../../../../Components/Load";
import PdfByDesenvolvimentoMotor from "../ModelosPdfs/PdfByDesenvolvimentoMotor";

// fazer aqui um tratamento de qual gerar pdf eu deve trazer

export default function CabeçalhoRelatorio() {
  const Params = useParams(); //aqui pega a url

  const [DataInit, setDataInit] = useState();
  const [DataEnd, setDataEnd] = useState();
  const [NewDataInit, setNewDataInit] = useState();
  const [NewDataEnd, setNewDataEnd] = useState();
  const [SelectedPatient, setSelectedPatient] = useState();
  const [AllPatient, setAllPatient] = useState();
  const [NamePatient, setNamePatient] = useState();
  const [NameVerification, setNameVerification] = useState(false);
  const [Load, setLoad] = useState(true);

  const [LoadPdf, setLoadPdf] = useState(true);

  const { listaImgBase64 } = StateGlobal();

  const [DataGuia, setDataGuia] = useState({});

  const doctorId = localStorage.getItem("doctorId");

  const NameReport = localStorage.getItem("title_form");

  let arr = Params.date && Params.date.split("&");
  let idPaciente =  arr[2] === undefined ? Params.date && arr[1] : Params.date && arr[2];

  useEffect(() => {
    setDataInit(arr[0]);
    setDataEnd(arr[1]);

    if (arr[2]) {
      setSelectedPatient(arr[2]);
    }
  }, []);

  function getPatients() {
    let Doctor_ID = localStorage.getItem("doctorId");
    conexao
      .post(`/patient/get/${Doctor_ID}`)
      .then((res) => {
        setAllPatient(res.data.data);
        setLoad(false);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    getPatients();
  }, []);

  useEffect(() => {
    if (AllPatient) {
      AllPatient.map((item, index) => {
        if (item.id == SelectedPatient) {
          setNamePatient(item.name);
          setNameVerification(true);
        }
      });
    } else {
      setNameVerification(false);
    }
  }, [AllPatient]);


  useEffect(() => {
    let ConvetDateInt = DataInit ? DataInit.split("-") : "";
    let ConvetDateEnd = DataEnd ? DataEnd.split("-") : "";
    ConvetDateInt = `${ConvetDateInt[2]}/${ConvetDateInt[1]}/${ConvetDateInt[0]}`;
    ConvetDateEnd = `${ConvetDateEnd[2]}/${ConvetDateEnd[1]}/${ConvetDateEnd[0]}`;
    setNewDataInit(ConvetDateInt);
    setNewDataEnd(ConvetDateEnd);
  }, [DataInit, DataEnd]);

  async function getData() {
    const { data } = await conexao.get(`/doctor/byid/${doctorId}`);
    setDataGuia(data.data);
  }
  useEffect(() => {
    getData();
    setTimeout(() => {
      setLoadPdf(false);
    }, 6500);
  }, []);

  function filterPdf() {
    let NameReport = localStorage.getItem("NameReport");

    switch (NameReport) {
      case "Craniometria":
        PdfByCraniometria(DataGuia, listaImgBase64, idPaciente);
        break;

      case "Escala De Dor":
        PdfByEscalaDeDor(DataGuia, listaImgBase64, idPaciente);
        break;

      case "Curva de Crescimento":
        PdfByCurvaDeCrescimento(DataGuia, listaImgBase64, idPaciente);
        break;

      case "Desenvolvimento Motor":
        PdfByDesenvolvimentoMotor(DataGuia, listaImgBase64, idPaciente);
        break;

      default:
        console.log(`Não encontrei o formulario de impressão`);
        break;
    }
  }

  return (
    <>
      {Load ? (
        <div className="loadingPaciente"></div>
      ) : (
        <div style={{ backgroundColor: "#F9F9F9", paddingBottom: "40px" }}>
          <div className="Header-Informações-Positions">
            <div className="Header-Informações">
              <p>Relatório: {NameReport}</p>
              <span>
                {arr[2] === undefined
                  ? `Período: ${NewDataInit} `
                  : `Período: ${NewDataInit} à ${NewDataEnd}`}{" "}
              </span>
              {NameVerification ? <span>Paciente: {NamePatient} </span> : ""}
            </div>

            <div className="Header-Btn-Imprimir-Position">
              <button
                className="Header-Btn-Imprimir"
                type="button"
                id="ImprimirRelatorio"
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  LoadPdf === true ? "" : filterPdf();
                }}
              >
                {" "}
                {LoadPdf === true ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <CircularLoad />{" "}
                  </div>
                ) : (
                  "Gerar PDF"
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
