import React, { useState, createContext } from 'react'

export const SportsPhysiotherapyContext = createContext()

export function SportsPhysiotherapyProvider({ children }) {
  const [chartComponentsIndex, setChartComponentsIndex] = useState(0)
  const [sportsPhysiotherapyView, setSportsPhysiotherapyView] = useState(false)
  const [loadSportsPhysiotherapyComponent, setLoadSportsPhysiotherapyComponent] = useState(false)
  const [sportsPhysiotherapyChartAnswers, setSportsPhysiotherapyChartAnswers] = useState({
    informations: {
      birthDate: '',
      age: '',
      weight: '',
      height: '',
      gender: '',
      lowerDominantMember: '',
      upperDominantMember: '',
      affectedLowerMember: '',
      affectedUpperMember: '',
      sportModalidy: []
    },
    medicines: {
      useMedication: '',
      controlledRemedies: '',
      continuousUsage: '',
      contraceptiveUsage: '',
      infiltrationHistory: '',
      antibioticUsege: '',
    },
    alimentation: {
      alimentation: '',
      hasSuplementation: '',
      oriented: '',
      drinkAlcohol: '',
      drinkAlcoholTimesAWeek: '',
      drinkAlcoholAmount: '',
      isSmoker: '',
      smokerTimesAWeek: '',
      smokerAmount: '',
      drugsUsage: '',
      whichDrug: '',
    },
    sleep: {
      sleepHealth: '',
      hoursANight: '',
    },
    training: {
      trainingTime: '',
      trainingVolume: '',
      trainingFrequency: '',
      extraTraining: '',
      supervisedTraining: '',
      extraTrainingTimesAWeek: '',
      diabetes: '',
      highPressure: '',
      dizziness: '',
      cancer: '',
      surgeryHistory: '',
      whichSurgeries: '',
      painAtRest: '',
      jointMovementPain: '',
      sportsPracticePain: '',
      hasPainRightNow: '',
      musclePain: [],
      symptom: '',
      clinicalDiagnosis: '',
      physiotherapeuticDiagnosis: '',
      chiefComplaint: '',
      hma: '',
      hmp: '',
    },
    algometry: {
      painPoint1: '',
      painPoint2: '',
      xMuscleRight1: '',
      xMuscleLeft1: '',
      xMuscleRight2: '',
      xMuscleLeft2: '',
      average: '',
      average2: '',
      passiveShoulderFlexorsDom: '',
      passiveShoulderFlexorsNdom: '',
      activeShoulderFlexorsDom: '',
      activeShoulderFlexorsNdom: '',
      passiveElbowFlexorsDom: '',
      passiveElbowFlexorsNdom: '',
      activeElbowFlexorsDom: '',
      activeElbowFlexorsNdom: '',
      passiveWristFlexorsDom: '',
      passiveWristFlexorsNdom: '',
      activeWristFlexorsDom: '',
      activeWristFlexorsnDom: '',
      passiveHipFlexorsDom: '',
      passiveHipFlexorsNdom: '',
      activeHipFlexorsDom: '',
      activeHipFlexorsNdom: '',
      passiveKneeFlexorsDom: '',
      passiveKneeFlexorsNdom: '',
      activeKneeFlexorsDom: '',
      activeKneeFlexorsNdom: '',
      passiveShoulderExtendersDom: '',
      passiveShoulderExtendersNdom: '',
      activeShoulderExtendersDom: '',
      activeShoulderExtendersNdom: '',
      passiveElbowExtendersDom: '',
      passiveElbowExtendersNdom: '',
      activeElbowExtendersDom: '',
      activeElbowExtendersNdom: '',
      passiveWristExtendersDom: '',
      passiveWristExtendersNdom: '',
      activeWristExtendersDom: '',
      activeWristExtendersNdom: '',
      passiveHipExtendersDom: '',
      passiveHipExtendersNdom: '',
      activeHipExtendersDom: '',
      activeHipExtendersNdom: '',
      passiveKneeExtendersDom: '',
      passiveKneeExtendersNdom: '',
      activeKneeExtendersDom: '',
      activeKneeExtendersNdom: '',
      passiveShoulderAbductorsDom: '',
      passiveShoulderAbductorsNdom: '',
      activeShoulderAbductorsDom: '',
      activeShoulderAbductorsNdom: '',
      passiveShoulderAdductorsDom: '',
      passiveShoulderAdductorsNdom: '',
      activeShoulderAdductorsDom: '',
      activeShoulderAdductorsNdom: '',
      passiveHipAbductorsDom: '',
      passiveHipAbductorsNdom: '',
      activeHipAbductorsDom: '',
      activeHipAbductorsNdom: '',
      passiveHipAdductorsDom: '',
      passiveHipAdductorsNdom: '',
      activeHipAdductorsDom: '',
      activeHipAdductorsNdom: '',
      passiveMedialRotatorShoulderDom: '',
      passiveMedialRotatorShoulderNdom: '',
      activeMedialRotatorShoulderDom: '',
      activeMedialRotatorShoulderNdom: '',
      passiveShoulderLateralRotatorDom: '',
      passiveShoulderLateralRotatorNdom: '',
      activeShoulderLateralRotatorDom: '',
      activeShoulderLateralRotatorNdom: '',
      passiveMedialHipRotatorDom: '',
      passiveMedialHipRotatorNdom: '',
      activeMedialHipRotatorDom: '',
      activeMedialHipRotatorNdom: '',
      passiveLateralHipRotatorDom: '',
      passiveLateralHipRotatorNdom: '',
      activeLateralHipRotatorDom: '',
      activeLateralHipRotatorNdom: '',
      passiveAnkleDorsiflexionDom: '',
      passiveAnkleDorsiflexionNdom: '',
      activeAnkleDorsiflexionDom: '',
      activeAnkleDorsiflexionNdom: '',
      passiveAnklePlantarFlexionDom: '',
      passiveAnklePlantarFlexionNdom: '',
      activeAnklePlantarFlexionDom: '',
      activeAnklePlantarFlexionNdom: '',
      dynamometer: 'all',
      othersDynamometer: '',
      unitOfMeasurement: '',
      neer: '',
    },
    funcionalTests: {
      mmiiDom: '',
      mmiiNdom: '',
      singleHopTest: {
        dom: {
          singleHopTestDom1: '',
          singleHopTestDom2: '',
          singleHopTestDom3: '',
        },
        ndom: {
          singleHopTestNdom1: '',
          singleHopTestNdom2: '',
          singleHopTestNdom3: '',
        }
      },
      tripleHopTest: {
        dom: {
          tripleHopTestDom1: '',
          tripleHopTestDom2: '',
          tripleHopTestDom3: '',
        },
        ndom: {
          tripleHopTestNdom1: '',
          tripleHopTestNdom2: '',
          tripleHopTestNdom3: '',
        }
      },
      timedHopTest: {
        dom: {
          timedHopTestDom1: '',
          timedHopTestDom2: '',
          timedHopTestDom3: '',
        },
        ndom: {
          timedHopTestNdom1: '',
          timedHopTestNdom2: '',
          timedHopTestNdom3: '',
        }
      },
      lungeTest: {
        dom: {
          lungeTestDom1: '',
          lungeTestDom2: '',
          lungeTestDom3: '',
        },
        ndom: {
          lungeTestNdom1: '',
          lungeTestNdom2: '',
          lungeTestNdom3: '',
        }
      },
      hipExtendersRepetitonsDom: '',
      hipExtendersRepetitonsNdom: '',
      previousDistance: {
        dom: {
          lqybtPreviousDistanceDom1: '',
          lqybtPreviousDistanceDom2: '',
          lqybtPreviousDistanceDom3: '',
        },
        ndom: {
          lqybtPreviousDistanceNdom1: '',
          lqybtPreviousDistanceNdom2: '',
          lqybtPreviousDistanceNdom3: '',
        }
      },
      sidePostero: {
        dom: {
          lqybtSidePosteroDom1: '',
          lqybtSidePosteroDom2: '',
          lqybtSidePosteroDom3: '',
        },
        ndom: {
          lqybtSidePosteroNdom1: '',
          lqybtSidePosteroNdom2: '',
          lqybtSidePosteroNdom3: '',
        }
      },
      medialPostero: {
        dom: {
          lqybtMedialPosteroDom1: '',
          lqybtMedialPosteroDom2: '',
          lqybtMedialPosteroDom3: '',
        },
        ndom: {
          lqybtMedialPosteroNdom1: '',
          lqybtMedialPosteroNdom2: '',
          lqybtMedialPosteroNdom3: '',
        }
      },
      normalizedDistanceDADom: '',
      normalizedDistancePLDom: '',
      normalizedDistancePMDom: '',
      normalizedDistanceDANdom: '',
      normalizedDistancePLNdom: '',
      normalizedDistancePMNdom: '',
      dropSeated: {
        dom: {
          dropNavicularSeatedDom1: '',
          dropNavicularSeatedDom2: '',
          dropNavicularSeatedDom3: '',
        },
        ndom: {
          dropNavicularSeatedNdom1: '',
          dropNavicularSeatedNdom2: '',
          dropNavicularSeatedNdom3: '',
        }
      },
      dropFoot: {
        dom: {
          dropNavicularFootDom1: '',
          dropNavicularFootDom2: '',
          dropNavicularFootDom3: '',
        },
        ndom: {
          dropNavicularFootNdom1: '',
          dropNavicularFootNdom2: '',
          dropNavicularFootNdom3: '',
        }
      },
      walkTestTC6: '',
      walkTestPAi: '',
      walkTestPAf: '',
      walkTestBORGi: '',
      tugTest: {
        tugTest2: '',
        tugTest3: '',
        tugTest1: '',
      },
    },
    funcionalTests2: {
      mmssDom: '',
      mmssNdom: '',
      ckcTouches1: '',
      ckcTouches2: '',
      ckcTouches3: '',
      medial: {
        dom: {
          uqybtMedialDom1: '',
          uqybtMedialDom2: '',
          uqybtMedialDom3: '',
        },
        ndom: {
          uqybtMedialNdom1: '',
          uqybtMedialNdom2: '',
          uqybtMedialNdom3: '',
        }
      },
      upperSide: {
        dom: {
          uqybtUppersideDom1: '',
          uqybtUppersideDom2: '',
          uqybtUppersideDom3: '',
        },
        ndom: {
          uqybtUppersideNdom1: '',
          uqybtUppersideNdom2: '',
          uqybtUppersideNdom3: '',
        }
      },
      lowerSide: {
        dom: {
          uqybtLowersidelDom1: '',
          uqybtLowersidelDom2: '',
          uqybtLowersidelDom1: '',
        },
        ndom: {
          uqybtLowersidelNdom: '',
        }
      },
      uqybtNormalizedDistanceMedDom: '',
      uqybtNormalizedDistanceLsDom: '',
      uqybtNormalizedDistanceLiDom: '',
      uqybtNormalizedDistanceMedNdom: '',
      uqybtNormalizedDistanceLsNdom: '',
      uqybtNormalizedDistanceLiNdom: '',
      smbt1: '',
      smbt2: '',
      smbt3: '', 
      usspTestDom1: '', 
      usspTestDom2: '', 
      usspTestDom3: '', 
      usspTestNdom1: '',
      usspTestNdom2: '',
      usspTestNdom3: '',
    },
    funcionalTrunkTests: {
      time: '',
      legDom1: '',
      legDom2: '',
      legDom3: '',
      legNdom1: '',
      legNdom2: '',
      legNdom3: '',
    },
    functionalQuestions: {
      faam: '',
      faos: '',
      lyshom: '',
      eroe: '',
      ndi: '',
      spadi: '',
      oswestry: '',
      csi: '',
      tampa: '',
      cait: '',
      epworth: '',
      womac: '',
      visaA: '',
      visaP: '',
    },
    cait: {
      anklePain: {
        right:{
          never: '',
          sportsPractice: '',
          runOnUnevenSurfaces: '',
          runOnFlatSurfaces: '',
          walkOnUnevenSurfaces: '',
          walkOnFlatSurfaces: '',
        },
        left: {
          never: '',
          sportsPractice: '',
          runOnUnevenSurfaces: '',
          runOnFlatSurfaces: '',
          walkOnUnevenSurfaces: '',
          walkOnFlatSurfaces: '',
        }
      },
      ankleInstability: {
        right: {
          never: '',
          sometimesSportsPractice: '',
          frequentlySportsPractice: '',
          sometimesDuringActivities: '',
          frequentlyDuringDailyActivities: '',
        },
        left: {
          never: '',
          sometimesSportsPractice: '',
          frequentlySportsPractice: '',
          sometimesDuringActivities: '',
          frequentlyDuringDailyActivities: '',
        }
      },
      suddenAnkleInstability: {
        right: {
          never: '',
          sometimesWhenIRun: '',
          frequentlyWhenIRun: '',
          whenIWalk: '',
        },
        left: {
          never: '',
          sometimesWhenIRun: '',
          frequentlyWhenIRun: '',
          whenIWalk: '',
        }
      },
      ladderAnkleInstability: {
        right: {
          never: '',
          ifComeDownQuickly: '',
          occasionally: '',
          always: '',
        },
        left: {
          never: '',
          ifComeDownQuickly: '',
          occasionally: '',
          always: '',
        }
      },
      oneFootAnkleInstability: {
        right: {
          never: '',
          onTiptoe: '',
          fullFootOnFloor: '',
        }, 
        left: {
          never: '',
          onTiptoe: '',
          fullFootOnFloor: '',
        }
      },
      ankleInstabilityWhen: {
        right: {
          never: '',
          jumpFromSideToSide: '',
          jumpInTheSamePlace: '',
          jumpWithBothLegs: '',
        },
        left: {
          never: '',
          jumpFromSideToSide: '',
          jumpInTheSamePlace: '',
          jumpWithBothLegs: '',
        }
      },
      ankleInstabilityWhen2: {
        right: {
          never: '',
          runOnUnevenSurfaces: '',
          runSlowlyOnUnevenSurfaces: '',
          walkOnUnevenSurfaces: '',
          walkOnFlatSurfaces: '',
        },
        left: {
          never: '',
          runOnUnevenSurfaces: '',
          runSlowlyOnUnevenSurfaces: '',
          walkOnUnevenSurfaces: '',
          walkOnFlatSurfaces: '',
        }
      },
      tipicallyWhenSprainAnkle: {
        right: {
          immediately: '',
          frequently: '',
          sometimes: '',
          never: '',
          neverSprainedAnkle: '',
        },
        left: {
          immediately: '',
          frequently: '',
          sometimes: '',
          never: '',
          neverSprainedAnkle: '',
        }
      },
      afterSprainNormalAnkle: {
        right: {
          almostImmediately: '',
          lessThanADay: '',
          oneTwoDays: '',
          moreThan2Days: '',
          neverSprainedAnkle: '',
        },
        left: {
          almostImmediately: '',
          lessThanADay: '',
          oneTwoDays: '',
          moreThan2Days: '',
          neverSprainedAnkle: '',
        }
      }
    },
    lysholm: {
      pain: '',
      swelling: '',
      goingUpStairs: '',
      limp: '',
      support: '',
      block: '',
      instability: '',
      squat: '',
    },
    ndi: {
      painIntensity: '',
      personalCare: '',
      liftThings: '',
      reading: '',
      headaches: '',
      payAttention: '',
      work: '',
      driveCars: '',
      sleep: '',
      fun: '',
    },
    spadi: {
      disabilityScale: {
        intensityOfYourPain1: '',
        severityOfYourPain: '',
        layOnTheArm1: '',
        putSomethingHighDhelf1: '',
        triedToPushSomething1: '',
      },
      painScale: {
        intensityOfYourPain2: '',
        layOnTheArm2: '',
        triedToCatchSomething: '',
        touchTheBackOfTheNeck: '',
        triedToPushSomething2: '',
        putSomethingHighDhelf2: '',
        carryRice: '',
        takeOutOfPocket: '',
      }
    },
    oswestryIndex: {
      painIntensity: '',
      personalCare: '',
      weight: '',
      walk: '',
      sit: '',
      standing: '',
      sleep: '',
      sexLife: '',
      socialLife: '',
      trips: '',
    },
    visaA: {
      stiffAchillesTendon: '',
      painWhenStretching: '',
      flatSurface30min: '',
      normalRhythmStairs: '',
      tiptoePain: '',
      jumpingWithOneLeg: '',
      playingSports: '',
      timeTraining: '',
      painTraining: '',
      painHindersMe: '',
    },
    visaP: {
      sittingWithoutPain: '',
      painWhenDescending: '',
      extendedKneePain: '',
      painExerciseLunge: '',
      squattingProblems: '',
      painAfterJumping10Times: '',
      playingSports: '',
      noPainplayingSports: '',
    },
    eroe: {
      pain: '',
      powerAndResistence:'',
      stability: '',
      intensity: '',
      sportsPerformance: '',
      objective: '',
    },
    womac: {
      painIntensity: {
        walkingLevelGround: '',
        goingUpOrDownStairs: '',
        lyingInBed: '',
        lyingDownOrSitting: '',
        standing: '',
      },
      stiffness: {
        stiffnessUponWaking: '',
        intensityOfStiffness: '',
      },
      physicalActivity: {
        downStairs: '',
        climbStairs: '',
        getUp: '',
        standUp: '',
        duckDown: '',
        walkOnThePlane: '',
        gettingInAndOutOfTheCar: '',
        goShopping: '',
        putSocks: '',
        getOutOfBed: '',
        takeOffYourSocks: '',
        lyingInBed: '',
        gettingInAndOutOfTheBath: '',
        sitDown: '',
        sittingDownAndGettingUpToilet: '',
        doingHeavyHousework: '',
        doLightHousework: '',
      }
    },
    epworth: {
      sittingAndReading: '',
      watchingTv: '',
      publicPlace: '',
      passenger: '',
      lyingInTheAfternoon: '',
      talkingToSomeone: '',
      afterMealWithNoAlcohol: '',
      duringTransit: '',
    },
    cinesiofobia: {
      fearOfGettingHurt: 0,
      fearToOvercome: 0,
      somethingWrongWithMe: 0,
      exercisePainRelieved: 0,
      peopleJudgingMe: 0,
      myBodyAtRisk: 0,
      painWithMeaning: 0,
      dangerousPain: 0,
      afraidOfHavingAnAccident: 0,
      alwaysCareful: 0,
      somethingDangerousHappening: 0,
      wouldBeBetterIfItWereActive: 0,
      painLetMeKnow: 0,
      dangerousPhysicallyActive: 0,
      iCantBeNormal: 0,
      painButNotDangerous: 0,
      noOneShouldDoExercises: 0,
    },
    csi: {
      idontFeelRested: 0,
      iFeelMyMusclesStiff: 0,
      anxietyCrisis: 0,
      gritYourTeeth: 0,
      problemsWithConstipation: 0,
      iNeedHelpWithHomework: 0,
      lightSensitive: 0,
      iGetTiredEasily: 0,
      iFeelPainInTheBody: 0,
      headaches: 0,
      painWhenIUrinate: 0,
      iDontSleepWell: 0,
      difficultyConcentrating: 0,
      skinProblems: 0,
      bodyPainFromStress: 0,
      iFeelSad: 0,
      iHaveLowEnergy: 0,
      muscleTension: 0,
      jawPain: 0,
      perfumesMakeMeDizzy: 0,
      iHaveToUrinateOften: 0,
      restlessLegs: 0,
      troubleRememberingThings: 0,
      iSufferedATrauma: 0,
      painInThePelvicArea: 0,
    },
    aofa: {
      pain: '',
      functional: '',
      maxDistance: '',
      walkSurface: '',
      gaitAbnormality: '',
      mobility: '',
      mobility2: '',
      stability: '',
      alignment: '',
    },
    faam: {
      activities: {
        standUp: '',
        walkFlatSurface: '',
        walkBarefootFlatSurface: '',
        goUpHill: '',
        goDownHill: '',
        climbStairs: '',
        downStairs: '',
        walkUnevenSurface: '',
        goUpAndDownCurb: '',
        squat: '',
        standOnTiptoe: '',
        startWalking: '',
        walk5MinutesOrLess: '',
        walkApproximately10Minutes: '',
        walking15MinutesOrMore: '',
        domesticActivities: '',
        activitiesOfDailyLiving: '',
        personalCare: '',
        workThatRequiresStanding: '',
        heavyWork: '',
        recreationalActivities: '',
        run: '',
        jump: '',
        cushionTheJump: '',
        startAndStopAbruptly: '',
        makeQuickSidePasses: '',
        lowImpactActivities: '',
        activityWithNormalTechnique: '',
        practiceDesiredSport: '',
      },
      
      functionalLevel: ''
    },
    lefs: {
      normalWork: '',
      hobby: '',
      gettingInOrOutOfTheBath: '',
      walkBetweenRooms: '',
      putOnYourShoesOrSocks: '',
      crouch: '',
      liftAnObject: '',
      doLightHousework: '',
      doHeavyHousework: '',
      gettingInOrOutOfTheCar: '',
      walkTwoBlocks: '',
      walkApproximately1Km: '',
      goUpOrDown10Steps: '',
      standForAnHour: '',
      sittingForAnHour: '',
      runOnFlatGround: '',
      runOnUnevenGround: '',
      changeDirection: '',
      jump: '',
      rollOverInBed: '',
    },
    psqi: {
      sleepTime: '',
      minutesToSleep: '',
      wakeUpTime: '',
      hoursOfSleep: '',
      sleepWithin30Minutes: '',
      middleOfTheNight: '',
      goToTheBathroom: '',
      notBreatheComfortably: '',
      coughedOrSnoredLoudly: '',
      feltVeryCold: '',
      itFeltVeryHot: '',
      hadBadDreams: '',
      hadPain: '',
      otherReason: '',
      dueToThisReason: '',
      sleepQuality: '',
      howOftenDidYouTakeMedicine: '',
      stayAwake: '',
      keepYourSpiritsUp: '',
      roommate: '',
      loudSnoring: '',
      longStopsInBreathing: '',
      twitchingYourLegs: '',
      restlessnessWhileYouSleep: '',
      
    },
    physiotherapeuticDiagnosis: {
      diagnosis: ''
    },
    component1: 0,
    component2: 0,
    component3: 0,
    component4: 0,
    component5: 0,
    component6: 0,
    component7: 0,
    comp5Score: {},
    comp7Score: {},
    comp2Score: {},
    scoreValue: {
      right: 0,
      left: 0
    },
    questionsScore: {
      anklePain_right: 0,
      anklePain_left: 0,
      ankleInstability_right: 0,
      ankleInstability_left: 0,
      suddenAnkleInstability_right: 0,
      suddenAnkleInstability_left: 0,
      ladderAnkleInstability_right: 0,
      ladderAnkleInstability_left: 0,
      oneFootAnkleInstability_right: 0,
      oneFootAnkleInstability_left: 0,
      ankleInstabilityWhen_right: 0,
      ankleInstabilityWhen_left: 0,
      ankleInstabilityWhen2_right: 0,
      ankleInstabilityWhen2_left: 0,
      tipicallyWhenSprainAnkle_right: 0,
      tipicallyWhenSprainAnkle_left: 0,
      afterSprainNormalAnkle_right: 0,
      afterSprainNormalAnkle_left: 0,
    },
    postoperative: '',
    unitType: ''
  })

  function handleNextButton() {
    setChartComponentsIndex(state => state + 1)
  }

  function handleBackButton() {
    setChartComponentsIndex(state => state - 1)
  }

  return (
    <SportsPhysiotherapyContext.Provider
      value={{
        chartComponentsIndex,
        setChartComponentsIndex,
        sportsPhysiotherapyChartAnswers,
        setSportsPhysiotherapyChartAnswers,
        handleNextButton,
        handleBackButton,
        sportsPhysiotherapyView,
        setSportsPhysiotherapyView,
        loadSportsPhysiotherapyComponent,
        setLoadSportsPhysiotherapyComponent
      }}
    >
      {children}
    </SportsPhysiotherapyContext.Provider>
  )
}